import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FellohService {

  constructor(private http: HttpClient, private apiService: ApiService) { }


  async getFellohToken(request: any, accountID: any): Promise<object> {
    const headers = { 'Account-ID': accountID, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPI + 'token', body, { headers }).toPromise();
    return output;
  }

  async createFellohPaymentRequest(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPI + 'checkout-payment', body, { headers }).toPromise();
    return output;
  }

  async getFellohTransactionList(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const output = await this.http.get(environment.fellohAPI + 'checkout-payment/transaction/' + request.connectedAccID + '?pageSize=' + request.pageSize + '&pageNumber=' + request.pageNumber +
      '&fromDate=' + request.fromDate + '&toDate=' + request.toDate, { headers }).toPromise();
    return output;
  }

  async getBookingsFellohPayments(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const output = await this.http.get(environment.fellohAPI + 'checkout-payment/status/merchantRequestId/' + request.bookingReference, { headers }).toPromise();
    return output;
  }

  async updateMerchantRequestId(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.put(environment.fellohAPI + 'checkout-payment', body, { headers }).toPromise();
    return output;
  }

  async updateNonTempReference(request: any, transactionId: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPI + 'checkout-payment/' + transactionId, body, { headers }).toPromise();
    return output;
  }

  async deletePaymentRequest(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const output = await this.http.delete(environment.fellohAPI + 'checkout-payment/' + request.connectedAccID + '/' + request.transactionId, { headers }).toPromise();
    return output;
  }

  async getBeneficiaries(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const output = await this.http.get(environment.fellohAPI + 'credittransfer/beneficiary?pageSize=' + request.pageSize + '&pageNumber=' + request.pageNumber, { headers }).toPromise();
    return output;
  }

  async createBeneficiary(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPI + 'credittransfer/beneficiary', body, { headers }).toPromise();
    return output;
  }

  async approveBeneficiary(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request.id);
    const output = await this.http.put(environment.fellohAPI + 'credittransfer/beneficiary/' + request.id, body, { headers }).toPromise();
    return output;
  }

  async getPayoutList(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const output = await this.http.get(environment.fellohAPI + 'disbursement/' + request.connectedAccID + '?pageSize=' + request.pageSize + '&pageNumber=' + request.pageNumber +
      '&fromDate=' + request.fromDate + '&toDate=' + request.toDate, { headers }).toPromise();
    return output;
  }

  async sendPayout(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPI + 'disbursement', body, { headers }).toPromise();
    return output;
  }

  /*
  async getSingsBookingRef(request: any): Promise<object> {
    const output = await this.http.post(environment.apiURL + 'getBookingRef?ref=' + request.ref + '&token=' + request.token, request).toPromise();
    return output;
  }
  */

  async resendFellohReceipt(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'resendFellohReceipt', body, {headers}).toPromise();
    return output;
  }

  // Sings-Felloh authorisation

  async fetchAuthorizationTokenV2(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURLv1 + 'getFellohToken?company=' + request.company + '&operation=' + request.operation +
      '&tradeCode=' + request.tradeCode + '&accountCode=' + request.accountCode + '&token=' + request.token).toPromise();
      return output;
  }

  // New Felloh API stuff below...

  async fetchAuthorizationToken(request: any): Promise<object> {
    const headers = { 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'token', body, { headers }).toPromise();
    return output;
  }

  async createFellohLink(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.put(environment.fellohAPInew + 'agent/payment-links', body, { headers }).toPromise();
    return output;
  }

  async deleteFellohLink(paymentLinkId: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const output = await this.http.delete(environment.fellohAPInew + 'agent/payment-links/' + paymentLinkId, { headers }).toPromise();
    return output;
  }

  async fetchPayments(request: any, tokenIn: any): Promise<object> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' });
    const body = JSON.stringify(request);
    const url = `${environment.fellohAPInew}agent/payments`;
    return this.apiService.makeHttpRequest('post', url, body, headers);
  }

  async fetchOnePayment(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const output = await this.http.get(environment.fellohAPInew + 'agent/payments/' + request.id, { headers }).toPromise();
    return output;
  }

  async fetchDisbursement(request: any, tokenIn: any): Promise<object> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' });
    const body = JSON.stringify(request);
    const url = `${environment.fellohAPInew}ledger/disbursements`;
    return this.apiService.makeHttpRequest('post', url, body, headers);
  }

  async fetchCharges(request: any, tokenIn: any): Promise<object> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' });
    const body = JSON.stringify(request);
    const url = `${environment.fellohAPInew}agent/charges`;
    return this.apiService.makeHttpRequest('post', url, body, headers);
  }

  async fetchRefunds(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/refunds', body, { headers }).toPromise();
    return output;
  }

  async getFellohBookings(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/bookings', body, { headers }).toPromise();
    return output;
  }

  async createFellohBooking(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.put(environment.fellohAPInew + 'agent/bookings', body, { headers }).toPromise();
    return output;
  }

  async updateFellohBooking(request: any, bookingId: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/bookings/' + bookingId, body, { headers }).toPromise();
    return output;
  }

  async updateFellohBookingRef(request: any, bookingId: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/bookings/' + bookingId + '/update-reference', body, { headers }).toPromise();
    return output;
  }

  async fetchPaymentLinks(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/payment-links', body, { headers }).toPromise();
    return output;
  }

  async fetchEcommerce(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/ecommerce', body, { headers }).toPromise();
    return output;
  }

  async assignEcommToBooking(request: any, paymentLinkID: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/ecommerce/' + paymentLinkID + '/assign', body, { headers }).toPromise();
    return output;
  }

  async assignPaymentToBooking(request: any, paymentLinkID: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn.token}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.fellohAPInew + 'agent/payment-links/' + paymentLinkID + '/re-assign', body, { headers }).toPromise();
    return output;
  }
}
