import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Documents {
  static myDocuments: any;

  // Variable which holds default documents available for all branches
  defaultDocs: any = [
    { name: 'Balance Letter', version: '2.3', lastModified: '2024-04-18', tempRef: 'balanceLetterV2', isNewTemplate: false },
    { name: 'Booking Confirmation', version: '2.4', lastModified: '2024-04-18', tempRef: 'bookingConfirmationV2', isNewTemplate: false },
    { name: 'Cancellation Letter', version: '2.1', lastModified: '2024-04-18', tempRef: 'cancellationLetterV2', isNewTemplate: false },
    { name: 'Booking Confirmation - Balance Outstanding', version: '3.2', lastModified: '2024-06-26', tempRef: 'bookingConfirmationBalOutV1', isNewTemplate: true },
    { name: 'Booking Confirmation - Paid In Full', version: '3.2', lastModified: '2024-06-26', tempRef: 'bookingConfirmationV3', isNewTemplate: true },
    { name: 'Balance Overdue', version: '3.0', lastModified: '2024-05-15', tempRef: 'balanceOverdueV1', isNewTemplate: true },
    { name: 'Balance Reminder', version: '3.2', lastModified: '2024-05-15', tempRef: 'balanceReminderV11', isNewTemplate: true },
    { name: 'Ticket Sent To Client', version: '3.0', lastModified: '2024-05-15', tempRef: 'ticketToClientV1', isNewTemplate: true },
    { name: 'Welcome Home', version: '3.0', lastModified: '2024-05-15', tempRef: 'welcomeHomeV1', isNewTemplate: true },
  ];

  atolDocsPkg: any = [
    { name: 'ATOL Certificate - Single Contract', version: '1.0', lastModified: '2023-10-06', tempRef: 'singleContract', isNewTemplate: false },
    { name: 'ATOL Certificate - Multi Contract', version: '1.0', lastModified: '2024-05-31', tempRef: 'multiContract', isNewTemplate: false },
  ];

  atolDocsFlOnl: any = [
    { name: 'ATOL Certificate - Flight Only', version: '1.0', lastModified: '2023-09-12', tempRef: 'flightOnly', isNewTemplate: false }
  ];

  // Variable which holds all CUSTOM documents as per previous requests..
  customDocs: any = [
    {
      tradeCode: 'T0001', documents: [ // Trade Code to be renamed to Q9325 after draft approved
        { name: 'Booking Confirmation - Q9325 (TEST ONLY)', version: '1.0', lastModified: '2022-06-21', tempRef: 'bookingConfirmationV1Q9325', isNewTemplate: false },
      ]
    },
    {
      tradeCode: 'S1234', documents: [ // To be removed after draft done
        { name: 'Booking Confirmation - Q9325 (TEST ONLY)', version: '1.0', lastModified: '2022-06-21', tempRef: 'bookingConfirmationV1Q9325', isNewTemplate: false },
      ]
    }
  ];

  // Variable used in all components which 'saves' current data. Needs to be initialised at the beginning of app.component
  public initialise(): void {
    Documents.myDocuments = this;
  }

  public getAll(branch: any, meetsATOLcriteria: any): any {
    const defaultDocs = []; defaultDocs.unshift(...this.defaultDocs); // Default docs are available - always!
    const customDocs = this.customDocs.find((docs: any) => docs.tradeCode === branch.tradeCode); // Search for custom documents here

    // If the branch is under T-ATOL and is eligible based on booking elements
    // Then we will add ATOL documents to the list in the UI
    if (branch.isTAtol === 'yes' && (meetsATOLcriteria.atolPackage)) {
      defaultDocs.unshift(...this.atolDocsPkg);
    } else if (branch.isTAtol === 'yes' && (meetsATOLcriteria.flightOnly)) {
      defaultDocs.unshift(...this.atolDocsFlOnl)
    }

    if (customDocs !== undefined) {
      return this.unique([...defaultDocs, ...customDocs.documents], (x: any) => x.name);
    } else {
      return defaultDocs;
    }
  }

  public meetsATOLcriteria(elementData: any, bookingPrice: any, fullSystem: boolean): any {
    let meetsATOLcriteriaPackage = false; // Based on this we will either hide or show ATOL certificate generator
    let meetsATOLcriteriaFlightOnly = false; // Variable for Flight Only ATOL certificates..
    
    // We are combining all elements' subElements into one - Rule #1
    // Please note: we are ignoring Package and Cruise elements (package in its own right)
    const combinedNonPackage = elementData.reduce((result: any, element: any) => {
      if (element.packages.length == 0 && element.flights.length > 0) { result.flights.push(...element.flights); }                // GROUP A
      if (element.packages.length == 0 && element.accoms.length > 0) { result.accoms.push(...element.accoms); }                   // GROUP B
      if (element.packages.length == 0 && element.carhires.length > 0) { result.carhires.push(...element.carhires); }             // GROUP C
      if (element.packages.length == 0 && element.carparks.length > 0) { result.otherServices.push(...element.carparks); }        // GROUP D
      if (element.packages.length == 0 && element.attractions.length > 0) { result.otherServices.push(...element.attractions); }  // GROUP D
      if (element.packages.length == 0 && element.trains.length > 0) { result.otherServices.push(...element.trains); }            // GROUP D
      if (element.packages.length == 0 && element.miscs.length > 0) { result.otherServices.push(...element.miscs); }              // GROUP D
      if (element.packages.length == 0 && element.cruises.length > 0) { result.cruises.push(...element.cruises); }                // GROUP E
      if (element.packages.length > 0) { result.packages.push(...element.packages); }                                             // GROUP F
      if (element.packages.length > 0 && element.flights.length > 0) { result.pkgFlights.push(...element.flights); }              // GROUP F (in-package flights)
      return result;
    }, {
      flights: [], accoms: [], carhires: [], otherServices: [], cruises: [], packages: [], pkgFlights: []
    });

    // Below we are determining whether the booking has eligible elementTypes for ATOL protection
    const hasBookingFlights = combinedNonPackage.flights.length > 0 && combinedNonPackage.flights.some((flight:any) => flight.flightStatus !== 'cancelled');          // GROUP A
    const hasBookingAccomms = combinedNonPackage.accoms.length > 0 && combinedNonPackage.accoms.some((accom:any) => accom.accomStatus !== 'cancelled');               // GROUP B
    const hasBookingCarHire = combinedNonPackage.carhires.length > 0 && combinedNonPackage.carhires.some((carhire:any) => carhire.carHireStatus !== 'cancelled');     // GROUP C
    const hasBookingCruises = combinedNonPackage.cruises.length > 0 && combinedNonPackage.cruises.some((cruise:any) => cruise.cruiseStatus !== 'cancelled');          // GROUP E

    // Group D is a combination of services: miscellanous, attractions and car parks
    const hasBookingServices = combinedNonPackage.otherServices.length > 0 && combinedNonPackage.otherServices.some((service: any) => {                               // GROUP D
      const status = service?.attractionStatus || service?.miscStatus || service?.carparkStatus || service?.trainStatus;
      return status !== 'cancelled' && (parseFloat(service.grossCost) >= 0.25 * parseFloat(bookingPrice));
    });
    const hasBookingPackage = combinedNonPackage.packages.length > 0 && combinedNonPackage.packages.some((pckg:any) => {                                              // GROUP F
      const status = pckg.packageStatus; return status !== 'cancelled';
    });
    const hasBookingPkgFlights = combinedNonPackage.pkgFlights.length > 0 && combinedNonPackage.pkgFlights.some((flight:any) => flight.flightStatus !== 'cancelled'); // GROUP F (in-package flights)

    // Below are conditions based on which the booking may or may not be eligeble for ATOL protection. The rules are:
    // Any combination of A + (B, C or D)       <--- Non Cruise / Package booking
    // Combination of E + A                     <--- Cruise / Package booking; with flight
    // Any combination B, C, D, E (at least 3)  <--- Cruise / Package booking; no flight
    const conditions = [hasBookingAccomms, hasBookingCarHire, hasBookingServices, hasBookingCruises];
    const trueCount = conditions.filter(condition => condition).length;
                                // Non-package - flights and other services presents
    meetsATOLcriteriaPackage = (!hasBookingPackage && hasBookingFlights && trueCount >= 1) ||
                                // Package on its own [mini-booking system]
                                (hasBookingPackage && !fullSystem) ||
                                // Package + separate flights [full-booking system]
                                (hasBookingPackage && (hasBookingFlights || hasBookingPkgFlights) && fullSystem) ||
                                // Package or Cruise with no flights, but with 3 other services
                                ((hasBookingPackage || hasBookingCruises) && trueCount >= 3);
    meetsATOLcriteriaFlightOnly = !hasBookingPackage && hasBookingFlights && trueCount == 0;

    return { atolPackage: meetsATOLcriteriaPackage, flightOnly: meetsATOLcriteriaFlightOnly }
  }

  // Method which will return cobination of two arrays but ONLY UNIQUE elements based on the property
  private unique(array: any, keyfunc: any): any {
    return array.reduce((result: any, entry: any) => {
      const key = keyfunc(entry);
      if (key in result.seen) {
        result.array[result.seen[key]] = entry;
      } else {
        result.seen[key] = result.array.length;
        result.array.push(entry);
      }
      return result;
    }, { array: [], seen: {} }).array;
  }
}
