import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BranchService } from '../../services/branch.service';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../../services/reports.service';
import { UserService } from '../../services/user.service';
import { Session } from '../../common/session';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalConstants } from '../../common/global-constants';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from './../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { AsynchRequests } from 'src/app/common/asynch-requests';
import { SupplierService } from 'src/app/services/supplier.service';

@Component({
  selector: 'app-financial-reports',
  templateUrl: './financial-reports.component.html',
  styleUrls: ['./financial-reports.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FinancialReportsComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;
  countries = GlobalConstants.countryList;
  showExtReference = false;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginatorData') paginatorData!: MatPaginator;
  @ViewChild('paginatorFile') paginatorFile!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer!: ElementRef;

  // Table data and table columns below
  salesReportColumns = ['bookingRef', 'tradeCode', 'status', 'bookingDate', 'departDate', 'gross', 'commission', 'vat', 'net', 'discount', 'markup', 'finalTax', 'customerPrice', 'totalInvoiced', 'totalReceipted', 'commissionPaid'];
  bankingReportColumns = ['bookingRef', 'tradeCode', 'leadPax', 'deptDate', 'receiptDate', 'payerName', 'paymentMethod', 'value'];
  paymentReportColumns = ['bookingRef', 'tradeCode', 'deptDate', 'supplierDueDate', 'paymentSupplier', 'paymentDate', 'paymentAmount', 'paymentMethod'];
  custBalDueReportColumns = ['bookingRef', 'tradeCode', 'leadPax', 'custDueDate', 'suppDueDate', 'deptDate', 'returnDate', 'gross', 'discount', 'totalReceipted', 'balanceToOp'];
  customerDetailReportColumns = ['custName', 'custEmail', 'address1', 'address2', 'address3', 'address4', 'postcode', 'county', 'country', 'telNo', 'noOfBookings', 'latestDeptDate', 'latestBookgDate'];
  supplementReportColumns = ['bookingRef', 'tradeCode', 'leadName', 'deptDate', 'supplementName', 'gross', 'discount', 'net', 'status', 'createTS', 'updateTS'];
  commissionReportColumns = ['bookingRef', 'tradeCode', 'bookingDate', 'deptDate', 'returnDate', 'totalCommission', 'commissionPaid', 'commissionToPay', 'comment'];
  destinationReportColumns = ['bookingRef', 'tradeCode', 'leadPax', 'contactNo', 'bookingDate', 'deptDate', 'returnDate', 'supplierName', 'supplierReference', 'elementType', 'country', 'location'];
  bookingReportColumns = ['bookingRef', 'tradeCode', 'status', 'leadName', 'paxNo', 'bookingDate', 'deptDate', 'returnDate', 'custPrice', 'net', 'markup', 'commission', 'supplierName', 'supplierRefs', 'elementType', 'destCountry', 'underATOL'];
  sfcDeclarationColumns = ['bookingRef', 'tradeCode', 'bookingDate', 'deptDate', 'returnDate', 'nights', 'destination', 'leadName', 'paxNo', 'suppsSfc', 'suppsOther', 'insured', 'issued', 'updated'];
  s3FilesColumns = ['fileName', 'reportType', 'lastModified', 'size', 'download'];
  reportData: any = new MatTableDataSource<any>();
  fileData: any = new MatTableDataSource<any>();

  // Boolean deciding whether user has access or not
  userType = '';
  pageLoaded = false;
  haveAccess = false;

  // Variables controlling HTML view file
  generateView = true;
  browseView = false;

  // Custom mat expansion variables
  expansionSearch = true;
  expansionBrowse = true;
  expansionList = true;

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';

  // Report generator variables
  currentRequest: any = {};
  selectedReport: any = {}; // Hold currently selected report
  fromDate: any = ''; // Holds current fromDate date variable
  toDate: any = ''; // Holds current toDate date variable
  dateType: any = ''; // Holds current dateType date variable
  dateTypes: any = []; // Will hold all avaialble date types in it
  suppliersList: any = []; // Variable used to contain list of available Suppliers in the system
  supplierFilteredData: any = []; // Holds data filtered out from variable above

  // Access varaibles
  companies: any = [];
  operations: any = [];
  branches: any = [];
  userGroups: any = [];
  paymentCategoryStr: any = 'all'; // Unique for Payment Report
  commissionFilterStr: any = 'all'; // Unique For Commission Report
  sfcReportType: any = 'summary'; // Unique for SFC report
  leadSupplierFilter: any = ''; // Unique for Sales Report

  selectedCountry: any = ''; // Unique for Destination Report
  filteredBranches: any = []; // Holds data filtered by the company (TTNG/GTG etc..)
  filterInBranches: any = []; // Holds data filtered by user input ('worldchoice..')
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  filterSupplierStr: any = ''; // Jak wyzej..
  bookingGroupStr: any = ''; // String used in filtering booking groups
  branchShowEOD = false;

  // Based on below variable we'll show the report only in dev site..
  disableReports = environment.production;

  // Report variables
  reportTypes: any = [
  { value: 'bankingReport', viewValue: 'Banking Report' },
  { value: 'bookingReport', viewValue: 'Booking Report' },
  { value: 'commissionReport', viewValue: 'Commission Report - BETA' },
  { value: 'custBalDue', viewValue: 'Customer Balance Due Report' },
  { value: 'custDetails', viewValue: 'Customer Details Report' },
  { value: 'destinationReport', viewValue: 'Destination Report' },
  { value: 'paymentsReport', viewValue: 'Payments Report' },
  { value: 'salesReport', viewValue: 'Sales Report' },
  { value: 'supplementReport', viewValue: 'Supplement Report' }
  ];
  salesReportValues: any = { gross: 0, commission: 0, vat: 0, net: 0, discount: 0, markup: 0, finalTax: 0, customerPrice: 0, totalInvoiced: 0, totalReceipted: 0, commissionPaid: 0 };
  custBalDueValues: any = { custPrice: 0, discount: 0, custPaid: 0, custBal: 0 };
  commissionReportValues: any = { totalCommission: 0, commissionPaid: 0, commissionToPay: 0 };
  bankingReportValues: any = { value: 0 };
  supplementReportValues: any = { gross: 0, discount: 0, net: 0 };
  bookingReportValues: any = { gross: 0, net: 0, markup: 0, commission: 0 };
  sfcDeclarationValues: any = { value: 0 };

  constructor(private router: Router, private userService: UserService,
              private branchService: BranchService, private reportsService: ReportsService,
              public dialog: MatDialog, private supplierService: SupplierService) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType and load the page
      this.userType = Session.mySession.getUser().userType;
      this.showExtReference = AppComponent.myapp.showExternalRef;
      this.loadPage();
    }
  }

  loadPage(): void {
    if (['sinGSAdmin', 'sinGSstaff', 'memberManager', 'memberStaff', 'wcManager', 'wcMember'].includes(this.userType)) {
      this.haveAccess = true; // Page available to all non-Felloh users

      this.currentRequest = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode, userGroup: Session.mySession.getUser().group,
        bookingReference: '', pathType: 'reports', token: Session.mySession.get('user').token
      };

      this.companies = this.constants.getCompanies(Session.mySession.getUser()); // Get list based on user type
      this.operations = this.constants.getOperations(Session.mySession.getUser()); // Get list based on user type
      this.reportData.data = []; // Reset reportData data table

      // Allow mini-booking users to see only below reports..
      if (['wcManager', 'wcMember'].includes(this.userType)) {
        this.reportTypes = [
          { value: 'salesReport', viewValue: 'Sales Report' },
          { value: 'supplementReport', viewValue: 'Supplement Report' }
        ];
      }
      // Only admins can see SFC Declaration Report..
      if (this.userType === 'sinGSAdmin') {
        const sfcDeclarationReport = { value: 'sfcDeclarationReport', viewValue: 'SFC Insurance Declaration' };
        // Insert at second-to-last position - we want it to be alphabeticall
        this.reportTypes.splice(this.reportTypes.length - 1, 0, sfcDeclarationReport);
      }

      this.getSystemSupplierList();
      if (Session.mySession.getUsersGroup().length > 0) {
        // Logged in user is in the group and branch list already exists within the session variable..
        const allBranch = { tradeCode: 'groupAll', fullName: Session.mySession.getUser().group };
        // Assign branch list, filter another list to choose from and add 'All' to the group
        this.branches = Session.mySession.getUsersGroup();
        this.filteredBranches = this.branches.filter((branch: any) => branch.tradeCode !== 'Q0000');
        this.filteredBranches.unshift(allBranch); // Add 'All' to beginning of array
        this.filterInBranches = this.filteredBranches; // It needs to be the same list
        this.pageLoaded = true;
      } else if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
        this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
          if (branches.status === 'OK') {
            // Assign sorted to global variable and session varaible (for later use) and call loadBookings()
            const sorted = branches.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1);
            this.branches = sorted;
            Session.mySession.setBranchList(sorted);
            this.addGroupsUp();
          } else {
            // Status was not OK meaning something went wrong with it.. display it to the user
            this.sendMessageToDialog('', branches.status, '', '');
            this.haveAccess = false;
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1201S)', error, Session.mySession.getUser());
        });
      } else {
        // Get branch list from the session varaible - no need to call API
        this.branches = Session.mySession.getBranchList().branchList;
        this.addGroupsUp();
      }

    } else {
      this.pageLoaded = true; // Page is loaded but no access for the user is set
    }
  }

  addGroupsUp(): void {
    if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff') {
      // Get group list below..
      this.branchService.getUserGroupList(Session.mySession.getUser()).then((userGroups: any) => {
        if (userGroups.status === 'OK') { this.userGroups = userGroups.data; } // Assign user groups data to the global array list
        this.filterBranches(Session.mySession.getUser().company, null);
        this.pageLoaded = true;
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1202S)', error, Session.mySession.getUser());
      });
    } else {
      this.pageLoaded = true;
    }
  }

  produceReport(form: NgForm, type: any): void {
    if (!form.valid) {
      // In case ay field was not set - show appropiate message to the user
      this.sendMessageToDialog('', 'You need to fill in required fields first', '', '');
    } else if (type === 'rtnData' && (form.value.selBranch.tradeCode === 'Q0000' ||
                                      form.value.selBranch.tradeCode === 'groupAll')) {
      this.sendMessageToDialog('', 'Preview is not supported for groups', '', '');
    } else if (this.selectedReport.value == 'getSfcDeclarationReport' && type === 'rtnData' && form.value.reportType === 'full') {
      this.sendMessageToDialog('', 'Preview is not supported for SFC Insutance Declaration (Full). Please use Summary', '', '');
    } else {
      // Form to current request variable assign values..
      this.currentRequest.company = form.value.company;
      this.currentRequest.tradeCode = form.value.selBranch.tradeCode;
      this.currentRequest.dateType = form.value.dateType;
      this.currentRequest.branch = form.value.selBranch;

      // Based on selected company, we set the operation in line as it's a one-to-one relationship
      // This removes unnecessary select list field from the form in UI
      if (form.value.company === 'ttng') { this.currentRequest.operation = 'retail'; }
      else if (form.value.company === 'gtg') { this.currentRequest.operation = 'member'; }
      else if (form.value.company === 'tta') { this.currentRequest.operation = 'tta'; }

      // Form contains startDate which does not have to be set up. If it is, we need to convert it so Ruby recognise it
      if (form.value.startDate._i !== null) {
        this.currentRequest.startDate = this.constants.convertDateMoment(form.value.startDate);
      }
      // Form contains endDate which does not have to be set up. If it is, we need to convert it so Ruby recognise it
      if (form.value.endDate._i !== null) {
        this.currentRequest.endDate = this.constants.convertDateMoment(form.value.endDate);
      }
      // Append unique properties to the request if exists. If not, well.. nothing will happen I think?
      this.currentRequest.paymentCategory = form.value.paymentCategory || '';
      this.currentRequest.commissionFilter = form.value.commissionFilter || '';
      this.currentRequest.version = form.value.reportType || 'summary';
      this.currentRequest.leadSupplier = encodeURIComponent(form.value.leadSupplier) || '';
      this.currentRequest.bookingGroup = form.value.bookingGroup ? encodeURIComponent(form.value.bookingGroup) : '';
      this.currentRequest.countryFilter = form.value.country ? encodeURIComponent(form.value.country) : '';

      // Check if the date is in the right format here
      if (moment(this.currentRequest.startDate, 'YYYY-MM-DD', true).isValid() && moment(this.currentRequest.endDate, 'YYYY-MM-DD', true).isValid()) {
        // Set the time and date report was requested at
        const dateTime: any = new Date();

        // Below is done to enable selecting groups..
        if (this.currentRequest.tradeCode === 'groupAll') {
          this.currentRequest.userGroup = form.value.selBranch.fullName;
        } else {
          this.currentRequest.userGroup = null;
        }

        // Apply agentEmail filter if user is not permitted to see others' bookings
        if (Session.mySession.getUser().othersBookingAccess === 'no') {
          this.currentRequest.agentEmail = Session.mySession.getUser().email;
        }
        
        this.currentRequest.pathType = 'reports'; // Reset pathType to 'reports'

        // Call getReport API
        this.pageLoaded = false;
        this.reportsService.getReport(this.selectedReport, this.currentRequest, type).then((output: any) => {
          if (output.status !== 'OK') {
            // Show error message to the user (Date Type error is already written in a 'nice way')
            // Any other errors will be 'taken care' as we go
            if (output.status === 'Error - no such value for dateType, see SinGSerr.log') {
              this.sendMessageToDialog('', 'You need to select Date Type', '', '');
            } else {
              this.sendMessageToDialog('', 'Failed to produce the report (' + output.status + ')', '', '');
            }
          } else {
            if (type === 'rtnData') {
              output.reportData.shift(); // Remove first line from the output array
              output.reportData.pop(); // Remove last line from the output array
              this.reportData.data = output.reportData; // Assign the output to data table
              this.reportData.paginator = this.paginatorData; // Assign the paginator to data table
              this.sumArray(this.reportData.data, this.selectedReport.value); // Sum the reports values here rather than from view
              this.pageLoaded = true;
            } else {
              // Set keywords we'll be looking for in the file name
              const keywords = [form.value.company, this.selectedReport.keyword, form.value.dateType, this.currentRequest.startDate, this.currentRequest.endDate, dateTime];
              Session.mySession.addAwaitingReport(keywords);
              this.switchView('browse'); this.browseReports(null); // Switch our view to 'browse' reports first
              // We then add our jobID to the 'watchlist', which will be called to check whether the report has finished producing
              if (output.jobID) {
                AsynchRequests.myAsynchReq.addJobToWatch(this.currentRequest, output.jobID);
                this.sendMessageToDialog(`${this.selectedReport.viewValue} is being generated, please wait..`, '', '', '');
              }
            }
          }
        }).catch((error: any) => {
          if (error.status === 0 && error.statusText === "Unknown Error") {
            this.sendMessageToDialog('', 'Please choose the CSV or XLSX option as the report is too large', '', '');
          } else {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1204S)', error, this.currentRequest);
          }
        });
      }
    }
  }

  browseReports(form: any): void {
    // Based on selected company, we set the operation in line as it's a one-to-one relationship
    // This removes unnecessary select list field from the form in UI
    if (this.currentRequest.company === 'ttng') { this.currentRequest.operation = 'retail'; }
    else if (this.currentRequest.company === 'gtg') { this.currentRequest.operation = 'member'; }
    else if (this.currentRequest.company === 'tta') { this.currentRequest.operation = 'tta'; }

    if (form != null) {
      // Set the request trade code form form first
      this.currentRequest.tradeCode = form.value.selBranch.tradeCode;
      // Below is done to enable selecting groups..
      if (this.currentRequest.tradeCode === 'groupAll') {
        this.currentRequest.userGroup = form.value.selBranch.fullName;
      } else if (this.currentRequest.tradeCode !== 'groupAll') {
        this.currentRequest.userGroup = null;
      }
      // If borderaux checkbox is ticked, we will change the pathType to show only upload files..
      if (form.value.borderauUpload) { this.currentRequest.pathType = 'bordereau'; }
      else { this.currentRequest.pathType = 'reports'; }
    }

    const awaitingReports = Session.mySession.getAwaitingReports(); // Get list of report keywords user is waiting for

    // Apply agentEmail filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      this.currentRequest.agentEmail = Session.mySession.getUser().email;
    }

    // Call getS3files API
    this.pageLoaded = false;
    this.reportsService.getS3files(this.currentRequest).then((output: any) => {
      if (output.status === 'OK') {

        if (output.data.contents !== undefined) {
          // Sort files by date
          output.data.contents = output.data.contents.sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
          this.fileData.data = output.data.contents; // If contents exist within S3 buckets, put them into data property
          this.fileData.paginator = this.paginatorFile; // Assign the paginator to data table

          this.fileData.data.forEach((file: any) => {
            file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
            file.reportType = file.name.split('_')[0]; // Get the report type from file name

            const lastModified = new Date(file.last_modified); // Create date variable in JS format so we can compare it later on

            // Check if we're looking for any files first..
            if (awaitingReports.length > 0) {
              awaitingReports.forEach((keywords: any, index: any) => {
                // We're waiting for at least one report - loop through the list and check if the keywords match..
                if (file.name.includes(keywords[0]) && file.name.includes(keywords[1]) && file.name.includes(keywords[2]) &&
                  file.name.includes(keywords[3]) && file.name.includes(keywords[4]) && lastModified > keywords[5]) {
                  // File name will contain all of the keywords set in the produce form earlier on..
                  this.sendMessageToDialog('One of your requested reports is ready!', '', '', ''); // Pop-up the message informing about the report
                  Session.mySession.removeAwaitingReport(index); // Remove the keyword array so we're not 'waiting' for it anymore..
                  file.new = true; // Mark the file as 'New'
                }
              });
            }

          });
        } else {
          this.fileData.data = []; // Contents not found - set empty array in the data
        }
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1203S)', error, this.currentRequest);
    });
  }

  downloadReport(report: any): void {
    // Assign file name we would like to downlaod from S3 bucket here..
    this.currentRequest.fileName = report.name;

    // Apply agentEmail filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      this.currentRequest.agentEmail = Session.mySession.getUser().email;
    }

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportsService.getS3presignedUrl(this.currentRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
          window.open(output.presignedUrl, '_blank'); this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1205S)', error, this.currentRequest);
    });
  }

  filterBranches(event: any, form: any): void {
    // Depending on the selected company, show only branches within that company (SINGS STAFF ONLY)
    const allBranch = { tradeCode: 'Q0000', fullName: 'All Branches', isLive: 'yes' };
    if (form != null) { form.controls.selBranch.reset(); } // Reset selected branch so the form is not VALID

    if (event === 'ttng') {
      this.filteredBranches = this.branches.filter((branch: any) => ['worldchoicePlus', 'worldchoice'].includes(branch.membershipType));
      this.filteredBranches = this.filteredBranches.filter((branch: any) => branch.tradeCode !== 'Q0000');
    } else if (event === 'gtg') {
      this.filteredBranches = this.branches.filter((branch: any) => branch.membershipType === 'globalTravel');
    } else if (event === 'tta') {
      this.filteredBranches = this.branches.filter((branch: any) => branch.membershipType === 'tta');
    }

    // Break down trade codes and read the first one from the list..
    // Check if the trade code exist within filtered branches - if so, add the group to filtered branches list
    this.userGroups.forEach((element: any) => {
      const firstShop = element.tradeCodes.split(';')[0];

      if (this.filteredBranches.find((branch: any) => branch.tradeCode === firstShop) !== undefined) {
        const group = { tradeCode: 'groupAll', fullName: element.groupName, isLive: 'yes' };
        this.filteredBranches.unshift(group);
      }
    });

    this.filteredBranches.unshift(allBranch); // Put 'All' branch in the first position
    this.filterInBranches = this.filteredBranches; // It needs to be the same list
    this.filterString = ''; // Reset our strings here..
    this.filterSelect(); // Apply EOD filter immediately
  }

  changeReport(view: any): void {
    if (view.value === 'bankingReport' || view.value === 'getBankingReport') {
      this.selectedReport.value = 'getBankingReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Banking Report'; // Used to print in UI
      this.selectedReport.template = 'bankingReport'; // Different templates versions in the future..?
      this.selectedReport.keyword = 'bankingReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'bookingDate', viewValue: 'Booking Date' }, { value: 'receiptDate', viewValue: 'Receipt Date' }];
    } else if (view.value === 'commissionReport' || view.value === 'getCommissionReport') {
      this.selectedReport.value = 'getCommissionReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Commission Report - BETA'; // Used to print in UI
      this.selectedReport.template = 'commReportV1'; // Different templates versions in the future..?
      this.selectedReport.keyword = 'commissionReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'bookingDate', viewValue: 'Booking Date' }, { value: 'deptDate', viewValue: 'Departure Date' }, { value: 'returnDate', viewValue: 'Return Date' }];
    } else if (view.value === 'destinationReport' || view.value === 'getDestinationReport') {
      this.selectedReport.value = 'getDestinationReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Destination Report'; // Used to print in UI
      this.selectedReport.template = 'destinationReportV1'; // Different templates versions in the future..?
      this.selectedReport.keyword = 'destinationReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'deptDate', viewValue: 'Departure Date' }, { value: 'returnDate', viewValue: 'Return Date' }];
    } else if (view.value === 'salesReport' || view.value === 'getSalesReport') {
      this.selectedReport.value = 'getSalesReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Sales Report'; // Used to print in UI
      this.selectedReport.template = 'salesReportSummary1';
      this.selectedReport.keyword = 'salesReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'balanceDueDate', viewValue: 'Balance Due Date' }, { value: 'bookingDate', viewValue: 'Booking Date' }, { value: 'deptDate', viewValue: 'Departure Date' },
      { value: 'depositDueDate', viewValue: 'Deposit Due Date' }, { value: 'dueDate', viewValue: 'Due Date' }, { value: 'returnDate', viewValue: 'Return Date' }];
    } else if (view.value === 'bookingReport' || view.value === 'getBookingReport') {
      this.selectedReport.value = 'getBookingReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Booking Report'; // Used to print in UI
      this.selectedReport.template = 'bookingReportV1';
      this.selectedReport.keyword = 'bookingReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'bookingDate', viewValue: 'Booking Date' }, { value: 'deptDate', viewValue: 'Departure Date' }, { value: 'returnDate', viewValue: 'Return Date' }];
    } else if (view.value === 'sfcDeclarationReport' || view.value === 'getSfcDeclarationReport') {
      this.selectedReport.value = 'getSfcDeclarationReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'SFC Insurance Declaration'; // Used to print in UI
      this.selectedReport.template = 'sfcDeclarationReportV1';
      this.selectedReport.keyword = 'sfcDeclarationReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'bookingDate', viewValue: 'Booking Date' }, { value: 'deptDate', viewValue: 'Departure Date' }, { value: 'returnDate', viewValue: 'Return Date' },
        { value: 'createTS', viewValue: 'Issued Date'}, { value: 'updateTS', viewValue: 'Last Updated'} ];
    } else if (view.value === 'paymentsReport' || view.value === 'getPaymentsReport') {
      this.selectedReport.value = 'getPaymentsReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Supplier Payments Report'; // Used to print in UI
      this.selectedReport.template = 'paymentsV1'; // Different templates versions in the future..?
      this.selectedReport.keyword = 'paymentsReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'deptDate', viewValue: 'Departure Date' }, { value: 'paidDate', viewValue: 'Paid Date' },
      { value: 'supplierDueDate', viewValue: 'Supplier Due Date' }, { value: 'txnDate', viewValue: 'Transaction Date' }];
    } else if (view.value === 'custBalDue' || view.value === 'getCustBalDueReport') {
      this.selectedReport.value = 'getCustBalDueReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Customer Balance Due Report'; // Used to print in UI
      this.selectedReport.template = 'custBalDueV1'; // Different templates versions in the future..?
      this.selectedReport.keyword = 'custBalDueReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'balanceDueDate', viewValue: 'Customer Due Date' }, { value: 'deptDate', viewValue: 'Departure Date' }, { value: 'dueDate', viewValue: 'Supplier Due Date' }];
    } else if (view.value === 'custDetails' || view.value === 'getCustomerDetailReport') {
      this.selectedReport.value = 'getCustomerDetailReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Customer Detail Report'; // Used to print in UI
      this.selectedReport.template = 'custDetailsV1'; // Different templates versions in the future..?
      this.selectedReport.keyword = 'customerDetailReport'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'bookingDate', viewValue: 'Booking Date' }, { value: 'deptDate', viewValue: 'Departure Date' }];
    } else if (view.value === 'supplementReport' || view.value === 'getSupplementReport') {
      this.selectedReport.value = 'getSupplementReport'; // Used in the API url call (e.g. localhost:4567/getXXXXreport)
      this.selectedReport.niceValue = 'Supplement Report'; // Used to print in UI
      this.selectedReport.template = 'suppDetailsV1'; // Different templates versions in the future..?
      this.selectedReport.keyword = 'BookingSupplements'; // Different templates versions in the future..?
      // List of available date types for chosen report
      this.dateTypes = [{ value: 'bookingDate', viewValue: 'Booking Date' }];
    }
    this.dateType = ''; // Reset selected date type so the user needs to select one again
    this.reportData.data = []; // Reset table data
  }

  sumArray(array: any, reportName: any): void {
    if (reportName === 'getSalesReport') {
      // Sales report shows 10 columns which we can sum up
      // It may actually be the 'fattest' report available
      let grossSum = 0; let commission = 0; let vat = 0; let net = 0;
      let discount = 0; let markup = 0; let finaltax = 0; let customerPrice = 0;
      let totalInvoiced = 0; let totalReceipted = 0; let commissionPaid = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        grossSum = +grossSum + +array[i][16];
        commission = +commission + +array[i][17];
        vat = +vat + +array[i][18];
        net = +net + +array[i][19];
        discount = +discount + +array[i][20];
        markup = +markup + +array[i][22];
        finaltax = +finaltax + +array[i][23];
        customerPrice = +customerPrice + +array[i][24];
        totalInvoiced = +totalInvoiced + +array[i][25];
        totalReceipted = +totalReceipted + +array[i][26];
        commissionPaid = +commissionPaid + +array[i][27];
      }
      this.salesReportValues.gross = grossSum;
      this.salesReportValues.commission = commission;
      this.salesReportValues.vat = vat;
      this.salesReportValues.net = net;
      this.salesReportValues.discount = discount;
      this.salesReportValues.markup = markup;
      this.salesReportValues.finalTax = finaltax;
      this.salesReportValues.customerPrice = customerPrice;
      this.salesReportValues.totalInvoiced = totalInvoiced;
      this.salesReportValues.totalReceipted = totalReceipted;
      this.salesReportValues.commissionPaid = commissionPaid;
    } else if (reportName === 'getBankingReport') {
      // Banking report shows only one value we can sum which is 'Value' column - amount banked within date range
      let value = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        value = + Number(value) + Number(array[i][11]);
      }
      this.bankingReportValues.value = value;
    } else if (reportName === 'getCommissionReport') {
      let totalCommission = 0;
      let commissionPaid = 0;
      let commissionToPay = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        totalCommission = +totalCommission + +array[i][7];
        commissionPaid = +commissionPaid + +array[i][8];
        commissionToPay = +commissionToPay + +array[i][9];
      }
      this.commissionReportValues.totalCommission = totalCommission;
      this.commissionReportValues.commissionPaid = commissionPaid;
      this.commissionReportValues.commissionToPay = commissionToPay;
    } else if (reportName === 'getPaymentsReport') {
      // Payments report shows only one value we can sum which is 'Value' column - amount banked within date range
      let value = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        value = + Number(value) + Number(array[i][7]);
      }
      this.bankingReportValues.value = value;
    } else if (reportName === 'getSupplementReport') {
      // Supplement report shows two value we can sum - gross & net
      let gross = 0; let discount = 0; let net = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        gross = + Number(gross) + Number(array[i][7]);
        discount = + Number(discount) + Number(array[i][8]);
        net = + Number(net) + Number(array[i][9]);
      }
      this.supplementReportValues.gross = gross;
      this.supplementReportValues.discount = discount;
      this.supplementReportValues.net = net;
    } else if (reportName === 'getCustBalDueReport') {
      // Customer Balance Due report shows 4 columns which we can sum up
      let gross = 0; let discount = 0;
      let custPaid = 0; let custBal = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        gross = + Number(gross) + Number(array[i][9]);
        discount = + Number(discount) + Number(array[i][10]);
        custPaid = + Number(custPaid) + Number(array[i][11]);
        custBal = + Number(custBal) + Number(array[i][12]);
      }
      this.custBalDueValues.custPrice = gross;
      this.custBalDueValues.discount = discount;
      this.custBalDueValues.custPaid = custPaid;
      this.custBalDueValues.custBal = custBal;
    } else if (reportName === 'getBookingReport') {
      // Customer Balance Due report shows 4 columns which we can sum up
      let gross = 0; let net = 0; let markup = 0; let commission = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        gross = + Number(gross) + Number(array[i][10]);
        net = + Number(net) + Number(array[i][11]);
        markup = + Number(markup) + Number(array[i][12]);
        commission = + Number(commission) + Number(array[i][13]);
      }
      this.bookingReportValues.gross = gross;
      this.bookingReportValues.net = net;
      this.bookingReportValues.markup = markup;
      this.bookingReportValues.commission = commission;
    } else if (reportName === 'getSfcDeclarationReport') {
      // Customer Balance Due report shows 4 columns which we can sum up
      let sum = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        sum = + Number(sum) + Number(array[i][13]);
      }
      this.sfcDeclarationValues.value = sum;
    }
  }

  goToBooking(bookingReference: any): void {
    AppComponent.myapp.closeBooking('fromScript').then((res: any) => {
      AppComponent.myapp.navigateToBooking(bookingReference, false);
    });
  }

  getSystemSupplierList(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the supplier list is in the session variable, It not / expired - reload by calling API

      if (Session.mySession.getSupplierList(this.currentRequest.tradeCode).expiryTime === 'EXPIRED') {
        const request = {
          company: this.currentRequest.company, operation: this.currentRequest.operation,
          tradeCode: this.currentRequest.tradeCode, token: Session.mySession.get('user').token
        };

        this.supplierService.getSupplierList(request).then((suppliers: any) => {
          if (suppliers.status === 'OK') {
            const sorted = suppliers.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); // Sort suppliers alphabetically
            Session.mySession.setSupplierList(this.currentRequest.tradeCode, sorted); // Set suppliers values in session
            const grouped = Object.values(sorted.reduce((h: any, obj: any) => Object.assign(h, { [obj.supplierNameM]: (h[obj.supplierNameM] || []).concat(obj) }), {})); // Group suppliers by their master name
            this.suppliersList = grouped; // Assign supplier list in global variable
            this.supplierFilteredData = grouped; // Assign supplier list in global variable
            resolve(''); // And return back..
          } else {
            this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '', ''); // Print error message..
            resolve(''); // And return back..
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1206S)', error, request);
          resolve(''); // And return back..
        });
      } else {
        const supplierList = Session.mySession.getSupplierList(this.currentRequest.tradeCode).supplierList;
        const grouped = Object.values(supplierList.reduce((h: any, obj: any) => Object.assign(h, { [obj.supplierNameM]: (h[obj.supplierNameM] || []).concat(obj) }), {})); // Group suppliers by their master name
        this.suppliersList = grouped; // Get supplement list from the session varaible - no need to call API
        this.supplierFilteredData = grouped; // Get supplement list from the session varaible - no need to call API
        resolve(''); // And return back..
      }

    }).catch((err: any) => { });
  }

  showExtRefSwitch(): void {
    AppComponent.myapp.showExtRefSwitch(!this.showExtReference);
    this.showExtReference = AppComponent.myapp.showExternalRef;
  }

  switchView(view: any): void {
    if (view === 'generate') {
      this.generateView = true;
      this.browseView = false;
      // this.dateType = '';
      // In case the view was switched by a SinGS Admin / Staff, we need to reset filtered branches to an empty array
      // if (this.companies.length > 1) { this.filteredBranches = []; this.filterInBranches = []; };
    } else if (view === 'browse') {
      this.generateView = false;
      this.browseView = true;
      // In case the view was switched by a SinGS Admin / Staff, we need to filter branches
      // if (this.companies.length > 1) { this.filterBranches(this.currentRequest.company, null); }
    }
  }

  filterSelect(): void {
    this.filterInBranches = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.filteredBranches.length; i++) {
      const option = this.filteredBranches[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        if (this.branchShowEOD) { this.filterInBranches.push(option); }
        else if (option.isLive === 'yes') { this.filterInBranches.push(option); }
      }
    }
  }

  filterEOD(): void {
    this.branchShowEOD = !this.branchShowEOD;
    this.filterSelect();
  }

  filterSupplier(): void {
    this.supplierFilteredData = []; // Empty filtered array first
    const filter = this.filterSupplierStr.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.suppliersList.length; i++) {
      const option = this.suppliersList[i];
      if (option[0].supplierNameM.toLowerCase().indexOf(filter) >= 0) {
        this.supplierFilteredData.push(option);
      }
    }
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }

  scrollLeft(scrollLength: any) {
    this.scrollContainer.nativeElement.scrollBy({ left: scrollLength, behavior: 'smooth' });
  }

  scrollRight(scrollLength: any) {
    this.scrollContainer.nativeElement.scrollBy({ left: scrollLength, behavior: 'smooth' });
  }
}
