import { Directive, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[type="number"]'
})
export class IgnoreWheelDirective {


  // This class prevents from wheel incrementing / decreasing in input=number
  // Not essential, but it may change the value by accident when entering numbers by our users
  // I do believe that this have happened already to Fiona
  @HostListener('wheel', ['$event'])
  onWheel(event: Event): void {
    event.preventDefault();
  }

}
