<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div fxLayout="row" fxLayoutAlign="space-around center" [@inAnimation] *ngIf="openedBooking != null && innerWidth > 1024" class="buttonBar">
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Flight'" (click)="changeElementType('Flight')">
        <mat-icon *ngIf="flightDivs.length >= 1" matBadge="{{flightDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">flight</mat-icon>
        <mat-icon *ngIf="flightDivs.length == 0" class="topButtonIcons">flight</mat-icon> Flights
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Accommodation'" (click)="changeElementType('Accommodation')">
        <mat-icon *ngIf="hotelDivs.length >= 1" matBadge="{{hotelDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">hotel</mat-icon>
        <mat-icon *ngIf="hotelDivs.length == 0" class="topButtonIcons">hotel</mat-icon> Accoms.
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Car Parking'" (click)="changeElementType('Car Parking')">
        <mat-icon *ngIf="carParkDivs.length >= 1" matBadge="{{carParkDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">local_parking</mat-icon>
        <mat-icon *ngIf="carParkDivs.length == 0" class="topButtonIcons">local_parking</mat-icon> Car Parkings
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Car Hire'" (click)="changeElementType('Car Hire')">
        <mat-icon *ngIf="carHireDivs.length >= 1" matBadge="{{carHireDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">drive_eta</mat-icon>
        <mat-icon *ngIf="carHireDivs.length == 0" class="topButtonIcons">drive_eta</mat-icon> Car Hires
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Attraction'" (click)="changeElementType('Attraction')">
        <mat-icon *ngIf="attraDivs.length >= 1" matBadge="{{attraDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">attractions</mat-icon>
        <mat-icon *ngIf="attraDivs.length == 0" class="topButtonIcons">attractions</mat-icon> Attractions
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Cruise'" (click)="changeElementType('Cruise')">
        <mat-icon *ngIf="cruiseDivs.length >= 1" matBadge="{{cruiseDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">directions_boat</mat-icon>
        <mat-icon *ngIf="cruiseDivs.length == 0" class="topButtonIcons">directions_boat</mat-icon> Cruises
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Miscellaneous'" (click)="changeElementType('Miscellaneous')">
        <mat-icon *ngIf="miscDivs.length >= 1" matBadge="{{miscDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">miscellaneous_services</mat-icon>
        <mat-icon *ngIf="miscDivs.length == 0" class="topButtonIcons">miscellaneous_services</mat-icon> Miscs.
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Train'" (click)="changeElementType('Train')">
        <mat-icon *ngIf="trainDivs.length >= 1" matBadge="{{trainDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">train</mat-icon>
        <mat-icon *ngIf="trainDivs.length == 0" class="topButtonIcons">train</mat-icon> Trains
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Transfer'" (click)="changeElementType('Transfer')">
        <mat-icon *ngIf="transferDivs.length >= 1" matBadge="{{transferDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">airport_shuttle</mat-icon>
        <mat-icon *ngIf="transferDivs.length == 0" class="topButtonIcons">airport_shuttle</mat-icon> Transfers
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" [@inAnimation] *ngIf="openedBooking != null && innerWidth < 1025" class="buttonBar">
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Flight'" (click)="changeElementType('Flight')">
        <mat-icon *ngIf="flightDivs.length >= 1" matBadge="{{flightDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">flight</mat-icon>
        <mat-icon *ngIf="flightDivs.length == 0" class="topButtonIcons">flight</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Accommodation'" (click)="changeElementType('Accommodation')">
        <mat-icon *ngIf="hotelDivs.length >= 1" matBadge="{{hotelDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">hotel</mat-icon>
        <mat-icon *ngIf="hotelDivs.length == 0" class="topButtonIcons">hotel</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Car Parking'" (click)="changeElementType('Car Parking')">
        <mat-icon *ngIf="carParkDivs.length >= 1" matBadge="{{carParkDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">local_parking</mat-icon>
        <mat-icon *ngIf="carParkDivs.length == 0" class="topButtonIcons">local_parking</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Car Hire'" (click)="changeElementType('Car Hire')">
        <mat-icon *ngIf="carHireDivs.length >= 1" matBadge="{{carHireDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">drive_eta</mat-icon>
        <mat-icon *ngIf="carHireDivs.length == 0" class="topButtonIcons">drive_eta</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Attraction'" (click)="changeElementType('Attraction')">
        <mat-icon *ngIf="attraDivs.length >= 1" matBadge="{{attraDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">attractions</mat-icon>
        <mat-icon *ngIf="attraDivs.length == 0" class="topButtonIcons">attractions</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Cruise'" (click)="changeElementType('Cruise')">
        <mat-icon *ngIf="cruiseDivs.length >= 1" matBadge="{{cruiseDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">directions_boat</mat-icon>
        <mat-icon *ngIf="cruiseDivs.length == 0" class="topButtonIcons">directions_boat</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Miscellaneous'" (click)="changeElementType('Miscellaneous')">
        <mat-icon *ngIf="miscDivs.length >= 1" matBadge="{{miscDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">miscellaneous_services</mat-icon>
        <mat-icon *ngIf="miscDivs.length == 0" class="topButtonIcons">miscellaneous_services</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Train'" (click)="changeElementType('Train')">
        <mat-icon *ngIf="trainDivs.length >= 1" matBadge="{{trainDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">train</mat-icon>
        <mat-icon *ngIf="trainDivs.length == 0" class="topButtonIcons">train</mat-icon>
      </button>
      <button class="navButtonsTypes" mat-button [disabled]="selectedEleType == 'Transfer'" (click)="changeElementType('Transfer')">
        <mat-icon *ngIf="transferDivs.length >= 1" matBadge="{{transferDivs.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" class="topButtonIcons">airport_shuttle</mat-icon>
        <mat-icon *ngIf="transferDivs.length == 0" class="topButtonIcons">airport_shuttle</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="openedBooking != null">

          <h1 fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Package Details
          </h1>

          <div class="elementCreateDiv" fxLayout="row" fxLayoutAlign="center none">
            <div fxFlex="50">
              <h3>Reservation Information</h3>
              <mat-form-field class="supplierType">
                <input matInput required ngModel placeholder="Supplier" name="supplierName" type="text" #supplierName="ngModel" readonly="true" *ngIf="operation == 'edit'" [(ngModel)]="element.supplierName">
                <input matInput required ngModel placeholder="Supplier" name="supplierName" type="text" #supplierName="ngModel" readonly="true" *ngIf="operation == 'add'" [(ngModel)]="supplier.supplierNameM">
              </mat-form-field>

              <mat-form-field class="supplierReference" *ngIf="operation == 'add'">
                <input matInput required ngModel placeholder="Supplier Reference" [(ngModel)]="supplierReference" minlength="3" maxlength="50" name="suppRef" type="text" #suppRef="ngModel">
              </mat-form-field>

              <mat-form-field class="supplierReference" *ngIf="operation == 'edit'">
                <input matInput required ngModel placeholder="Supplier Reference" [(ngModel)]="element.supplierReference" minlength="3" maxlength="50" name="suppRef" type="text" #suppRef="ngModel">
              </mat-form-field>

              <mat-form-field style="max-width: 180px;" *ngIf="operation == 'add'">
                <mat-label>Booking Date</mat-label>
                <input ngModel required maxlength="50" matInput name="bookingDate" [matDatepicker]="bookingDate" [(ngModel)]="today" (dateChange)="valiDate($event)">
                <mat-datepicker-toggle matSuffix [for]="bookingDate"></mat-datepicker-toggle>
                <mat-datepicker #bookingDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: 180px;" *ngIf="operation == 'edit'">
                <mat-label>Booking Date</mat-label>
                <input ngModel required maxlength="50" matInput name="bookingDate" [matDatepicker]="bookingDate" [(ngModel)]="today" readonly="true" (dateChange)="valiDate($event)">
                <mat-datepicker #bookingDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex="50">
              <h3>Notes</h3>
              <mat-form-field style="width: 530px; max-width: unset;" *ngIf="operation == 'add'">
                <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500" cdkTextareaAutosize ngModel name="comment" #comment="ngModel" [(ngModel)]="elementNotes" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
              </mat-form-field>

              <mat-form-field style="width: 530px; max-width: unset;" *ngIf="operation == 'edit'">
                <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500" cdkTextareaAutosize ngModel name="comment" #comment="ngModel" [(ngModel)]="element.comment" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="elementCreateDiv" fxLayout="row" fxLayoutAlign="space-around stretch" style="margin-bottom: 150px;">
            <div fxFlex="50">
              <form class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createPackage(createForm)">

                <div *ngIf="selectedEleType == 'Flight' && flightDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of flightDivs" id="flightDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="flightNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Accommodation' && hotelDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of hotelDivs" id="hotelDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="hotelNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Car Parking' && carParkDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of carParkDivs" id="carParkDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="carParksNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Car Hire' && carHireDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of carHireDivs" id="carHireDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="carHireNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Attraction' && attraDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of attraDivs" id="attraDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="attraNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Cruise' && cruiseDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of cruiseDivs" id="cruiseDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="cruiseNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Miscellaneous' && miscDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of miscDivs" id="miscDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="miscNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Train' && trainDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of trainDivs" id="trainDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="trainNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div *ngIf="selectedEleType == 'Transfer' && transferDivs.length > 0" style="display: inline; margin-right: 10px;">
                  <span *ngFor="let div of transferDivs" id="transferDiv{{div}}">
                    <button class="packageButtons" mat-raised-button [disabled]="transferNoView[div]" (click)="switchView(div, selectedEleType)" type="button" color="primary">
                      {{selectedEleType}} {{div+1}}
                    </button>
                  </span>
                </div>

                <div style="display: inline;">
                  <button class="packageButtons" style="width: 65px;" mat-raised-button matTooltip="Remove Last" matTooltipClass="line-normal-tooltip" (click)="removeDiv(selectedEleType)" type="button" color="accent">
                    <mat-icon>remove</mat-icon>
                  </button>
                  <button class="packageButtons" style="width: 65px;" mat-raised-button matTooltip="Add {{selectedEleType}}" matTooltipClass="line-normal-tooltip" (click)="addDiv(selectedEleType, createForm)" type="button" color="primary">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>

                <span [hidden]="selectedEleType != 'Flight'">
                  <h3>Flight Details</h3>
                  <div *ngFor="let div of flightDivs" id="flightDiv{{div}}">
                    <div [hidden]="!flightNoView[div]">
                      <mat-form-field class="paxNo" *ngIf="flightInfo[div]">
                        <mat-select ngModel [(ngModel)]="flightInfo[div].adults" required name="paxNoFlight{{div}}" #paxNoFlight{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNo" *ngIf="!flightInfo[div]">
                        <mat-select ngModel required name="paxNoFlight{{div}}" #paxNoFlight{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="flightInfo[div]">
                        <mat-select ngModel [(ngModel)]="flightInfo[div].children" name="paxNoInfantsFlight{{div}}" #paxNoInfantsFlight{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="!flightInfo[div]">
                        <mat-select ngModel name="paxNoInfantsFlight{{div}}" #paxNoInfantsFlight{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="flightInfo[div]">
                        <mat-select ngModel [(ngModel)]="flightInfo[div].infants" name="paxInfantsFlight{{div}}" #paxInfantsFlight{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="!flightInfo[div]">
                        <mat-select ngModel name="paxInfantsFlight{{div}}" #paxInfantsFlight{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="carrier" *ngIf="flightInfo[div]">
                        <input matInput required ngModel placeholder="Carrier" [(ngModel)]="flightInfo[div].carrier" name="carrier{{div}}" maxlength="50" #carrier{{div}}="ngModel" id="carrier{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carrier" *ngIf="!flightInfo[div]">
                        <input matInput required ngModel placeholder="Carrier" id="carrier{{div}}" name="carrier{{div}}" maxlength="50" #carrier{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field class="flightNo" *ngIf="flightInfo[div]">
                        <input matInput required ngModel placeholder="Flight No." [(ngModel)]="flightInfo[div].number" name="flightNo{{div}}" maxlength="30" #flightNo{{div}}="ngModel" id="flightNo{{div}}">
                      </mat-form-field>

                      <mat-form-field class="flightNo" *ngIf="!flightInfo[div]">
                        <input matInput required ngModel placeholder="Flight No." id="flightNo{{div}}" name="flightNo{{div}}" maxlength="30" #flightNo{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field class="flightClass" *ngIf="flightInfo[div]">
                        <mat-select ngModel name="flightClass{{div}}" #flightClass{{div}}="ngModel" placeholder="Flight Class" [(ngModel)]="flightInfo[div].flightClass" id="flightClass{{div}}">
                          <mat-option *ngFor="let class of flightClass" [value]="class">
                            {{class}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="flightClass" *ngIf="!flightInfo[div]">
                        <mat-select ngModel name="flightClass{{div}}" #flightClass{{div}}="ngModel" placeholder="Flight Class" id="flightClass{{div}}">
                          <mat-option *ngFor="let class of flightClass" [value]="class">
                            {{class}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="departAirName" *ngIf="flightInfo[div]">
                        <input matInput required ngModel placeholder="Dep. Airport Name" [(ngModel)]="flightInfo[div].departAirName" name="departAirName{{div}}" maxlength="100" #departAirName{{div}}="ngModel" id="departAirName{{div}}">
                      </mat-form-field>

                      <mat-form-field class="departAirName" *ngIf="!flightInfo[div]">
                        <input matInput required ngModel placeholder="Dep. Airport Name" id="departAirName{{div}}" name="departAirName{{div}}" maxlength="100" #departAirName{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="flightInfo[div]">
                        <mat-label>Depart Date</mat-label>
                        <input ngModel required matInput name="departDate{{div}}" [matDatepicker]="departDate" [(ngModel)]="flightInfo[div].deptDate" id="departDate{{div}}" (dateChange)="valiDate($event); setEndDate('departDate' + div, 'arriveDate' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departDate"></mat-datepicker-toggle>
                        <mat-datepicker #departDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!flightInfo[div]">
                        <mat-label>Depart Date</mat-label>
                        <input ngModel required matInput name="departDate{{div}}" [matDatepicker]="departDate" id="departDate{{div}}" (dateChange)="valiDate($event); setEndDate('departDate' + div, 'arriveDate' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departDate"></mat-datepicker-toggle>
                        <mat-datepicker #departDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="flightInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" [(ngModel)]="flightInfo[div].departHrs" id="departHrs{{div}}" name="departHrs{{div}}" min="0" max="23" #departHrs{{div}}="ngModel" style="width: 85px;"
                          (change)="leadingZeroCheck('departHrs'+div)">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="!flightInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="departHrs{{div}}" name="departHrs{{div}}" min="0" max="23" #departHrs{{div}}="ngModel" style="width: 85px;" (change)="leadingZeroCheck('departHrs'+div)">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="flightInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" [(ngModel)]="flightInfo[div].departMin" id="departMin{{div}}" name="departMin{{div}}" min="0" max="59" #departMin{{div}}="ngModel" style="width: 85px;"
                          (change)="leadingZeroCheck('departMin'+div)">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="!flightInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="departMin{{div}}" name="departMin{{div}}" min="0" max="59" #departMin{{div}}="ngModel" style="width: 85px;" (change)="leadingZeroCheck('departMin'+div)">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 80px;" *ngIf="flightInfo[div]">
                        <input matInput ngModel placeholder="IATA Code" [(ngModel)]="flightInfo[div].departAirCode" name="departAirCode{{div}}" minlength="3" maxlength="3" #departAirCode{{div}}="ngModel" id="departAirCode{{div}}">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 80px;" *ngIf="!flightInfo[div]">
                        <input matInput ngModel placeholder="IATA Code" id="departAirCode{{div}}" name="departAirCode{{div}}" minlength="3" maxlength="3" #departAirCode{{div}}="ngModel">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="arriveAirName" *ngIf="flightInfo[div]">
                        <input matInput required ngModel placeholder="Arr. Airport Name" [(ngModel)]="flightInfo[div].arriveAirName" name="arriveAirName{{div}}" maxlength="100" #arriveAirName{{div}}="ngModel" id="arriveAirName{{div}}">
                      </mat-form-field>

                      <mat-form-field class="arriveAirName" *ngIf="!flightInfo[div]">
                        <input matInput required ngModel placeholder="Arr. Airport Name" id="arriveAirName{{div}}" name="arriveAirName{{div}}" maxlength="100" #arriveAirName{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="flightInfo[div]">
                        <mat-label>Arrive Date</mat-label>
                        <input ngModel required matInput name="arriveDate{{div}}" [matDatepicker]="arriveDate" [(ngModel)]="flightInfo[div].returnDate" id="arriveDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="arriveDate"></mat-datepicker-toggle>
                        <mat-datepicker #arriveDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!flightInfo[div]">
                        <mat-label>Arrive Date</mat-label>
                        <input ngModel required matInput name="arriveDate{{div}}" [matDatepicker]="arriveDate" id="arriveDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="arriveDate"></mat-datepicker-toggle>
                        <mat-datepicker #arriveDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="flightInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" [(ngModel)]="flightInfo[div].arriveHrs" id="arriveHrs{{div}}" name="arriveHrs{{div}}" min="0" max="23" #arriveHrs{{div}}="ngModel" style="width: 85px;"
                          (change)="leadingZeroCheck('arriveHrs'+div)">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="!flightInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="arriveHrs{{div}}" name="arriveHrs{{div}}" min="0" max="23" #arriveHrs{{div}}="ngModel" style="width: 85px;" (change)="leadingZeroCheck('arriveHrs'+div)">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="flightInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" [(ngModel)]="flightInfo[div].arriveMin" id="arriveMin{{div}}" name="arriveMin{{div}}" min="0" max="59" #arriveMin{{div}}="ngModel" style="width: 85px;"
                          (change)="leadingZeroCheck('arriveMin'+div)">
                      </mat-form-field>

                      <mat-form-field class="departAirCode" style="width: 40px;" *ngIf="!flightInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="arriveMin{{div}}" name="arriveMin{{div}}" min="0" max="59" #arriveMin{{div}}="ngModel" style="width: 85px;" (change)="leadingZeroCheck('arriveMin'+div)">
                      </mat-form-field>

                      <mat-form-field class="arriveAirCode" style="width: 80px;" *ngIf="flightInfo[div]">
                        <input matInput ngModel placeholder="IATA Code" [(ngModel)]="flightInfo[div].destinAirCode" name="arriveAirCode{{div}}" minlength="3" maxlength="3" #arriveAirCode{{div}}="ngModel" id="arriveAirCode{{div}}">
                      </mat-form-field>

                      <mat-form-field class="arriveAirCode" style="width: 80px;" *ngIf="!flightInfo[div]">
                        <input matInput ngModel placeholder="IATA Code" id="arriveAirCode{{div}}" name="arriveAirCode{{div}}" minlength="3" maxlength="3" #arriveAirCode{{div}}="ngModel">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="flightDirection" *ngIf="flightInfo[div]">
                        <mat-select required ngModel name="direction{{div}}" #direction{{div}}="ngModel" placeholder="Direction" [(ngModel)]="flightInfo[div].direction" id="direction{{div}}">
                          <mat-option required [value]="'inbound'">Inbound</mat-option>
                          <mat-option required [value]="'internal'">Internal</mat-option>
                          <mat-option required [value]="'outbound'">Outbound</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="flightDirection" *ngIf="!flightInfo[div]">
                        <mat-select required ngModel name="direction{{div}}" #direction{{div}}="ngModel" placeholder="Direction" id="direction{{div}}">
                          <mat-option required [value]="'inbound'">Inbound</mat-option>
                          <mat-option required [value]="'internal'">Internal</mat-option>
                          <mat-option required [value]="'outbound'">Outbound</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <input *ngIf="flightInfo[div]" type="hidden" name="flightCount{{div}}" #flightCount{{div}}="ngModel" [(ngModel)]="flightInfo[div].flightCount" />
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Accommodation'">
                  <h3>Accommodation Details</h3>
                  <div *ngFor="let div of hotelDivs" id="hotelDiv{{div}}">
                    <div [hidden]="!hotelNoView[div]">
                      <mat-form-field class="accomName" *ngIf="hotelInfo[div]">
                        <input matInput required ngModel placeholder="Accommodation Name" [(ngModel)]="hotelInfo[div].accomName" id="accomName{{div}}" maxlength="100" name="accomName{{div}}" type="text" #accomName{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field class="accomName" *ngIf="!hotelInfo[div]">
                        <input matInput required ngModel placeholder="Accommodation Name" id="accomName{{div}}" maxlength="100" name="accomName{{div}}" type="text" #accomName{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field class="cityResort" *ngIf="hotelInfo[div]">
                        <input required matInput ngModel placeholder="City/Resort" id="city{{div}}" maxlength="100" name="city{{div}}" type="text" #city{{div}}="ngModel" [(ngModel)]="hotelInfo[div].city">
                      </mat-form-field>

                      <mat-form-field class="cityResort" *ngIf="!hotelInfo[div]">
                        <input required matInput ngModel placeholder="City/Resort" id="city{{div}}" maxlength="100" name="city{{div}}" type="text" #city{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field class="country" *ngIf="hotelInfo[div]">
                        <mat-select ngModel required name="countryHotel{{div}}" #countryHotel{{div}}="ngModel" placeholder="Country" [(ngModel)]="hotelInfo[div].country" id="country{{div}}">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="country" *ngIf="!hotelInfo[div]">
                        <mat-select ngModel required name="countryHotel{{div}}" #countryHotel{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="min-width: 370px;" class="address" *ngIf="hotelInfo[div]">
                        <textarea style="min-width: 370px; overflow: hidden;" matInput maxlength="200" name="address{{div}}" #address{{div}}="ngModel" ngModel [(ngModel)]="hotelInfo[div].address" ngModel cdkTextareaAutosize id="address{{div}}"
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" placeholder="Address" name="address" #address="ngModel"></textarea>
                      </mat-form-field>

                      <mat-form-field style="min-width: 370px;" class="address" *ngIf="!hotelInfo[div]">
                        <textarea style="min-width: 370px; overflow: hidden;" matInput maxlength="200" name="address{{div}}" #address{{div}}="ngModel" ngModel ngModel cdkTextareaAutosize id="address{{div}}" #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" placeholder="Address" name="address" #address="ngModel"></textarea>
                      </mat-form-field>

                      <mat-form-field class="rating" *ngIf="hotelInfo[div]">
                        <mat-select ngModel name="rating{{div}}" #rating{{div}}="ngModel" placeholder="Rating" [(ngModel)]="hotelInfo[div].rating" id="rating{{div}}">
                          <mat-option *ngFor="let rate of ratings" [value]="rate">
                            {{rate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="rating" *ngIf="!hotelInfo[div]">
                        <mat-select ngModel name="rating{{div}}" #rating{{div}}="ngModel" placeholder="Rating" id="rating{{div}}">
                          <mat-option *ngFor="let rate of ratings" [value]="rate">
                            {{rate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="max-width: 180px;" *ngIf="hotelInfo[div]">
                        <mat-label>Check-In Date</mat-label>
                        <input ngModel required matInput name="checkinDate{{div}}" [matDatepicker]="checkinDate" [(ngModel)]="hotelInfo[div].checkInDate" id="checkinDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="checkinDate"></mat-datepicker-toggle>
                        <mat-datepicker #checkinDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!hotelInfo[div]">
                        <mat-label>Check-In Date</mat-label>
                        <input ngModel required matInput name="checkinDate{{div}}" [matDatepicker]="checkinDate" id="checkinDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="checkinDate"></mat-datepicker-toggle>
                        <mat-datepicker #checkinDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="nights" *ngIf="hotelInfo[div]">
                        <input required matInput ngModel placeholder="Nights" [(ngModel)]="hotelInfo[div].numNights" id="nights{{div}}" name="nights{{div}}" #nights{{div}}="ngModel" type="number" min="1">
                      </mat-form-field>

                      <mat-form-field class="nights" *ngIf="!hotelInfo[div]">
                        <input required matInput ngModel placeholder="Nights" id="nights{{div}}" name="nights{{div}}" #nights{{div}}="ngModel" type="number" min="1">
                      </mat-form-field>

                      <p></p>

                      <span *ngFor="let roomNo of hotelRoomDivs[div]; let first = first;">
                        <h3>Room {{roomNo + 1}} Details</h3>

                        <mat-form-field class="roomTypes" *ngIf="hotelInfo[div] && hotelInfo[div].addndata[roomNo]">
                          <input required matInput ngModel placeholder="Room Type" [(ngModel)]="hotelInfo[div].addndata[roomNo].valueStr1" id="roomType{{div}}R{{roomNo}}" maxlength="100" name="roomType{{div}}R{{roomNo}}" type="text"
                            #roomType{{div}}R{{roomNo}}="ngModel">
                        </mat-form-field>

                        <mat-form-field class="roomTypes" *ngIf="!hotelInfo[div] || !hotelInfo[div].addndata[roomNo]">
                          <input required matInput ngModel placeholder="Room Type" id="roomType{{div}}R{{roomNo}}" maxlength="100" name="roomType{{div}}R{{roomNo}}" type="text" #roomType{{div}}R{{roomNo}}="ngModel">
                        </mat-form-field>

                        <mat-form-field class="roomViews" *ngIf="hotelInfo[div] && hotelInfo[div].addndata[roomNo]">
                          <input matInput ngModel placeholder="Room Views" [(ngModel)]="hotelInfo[div].addndata[roomNo].valueStr2" id="roomView{{div}}R{{roomNo}}" maxlength="100" name="roomView{{div}}R{{roomNo}}" type="text" #roomView{{div}}R{{roomNo}}="ngModel">
                        </mat-form-field>

                        <mat-form-field class="roomViews" *ngIf="!hotelInfo[div] || !hotelInfo[div].addndata[roomNo]">
                          <input matInput ngModel placeholder="Room Views" id="roomView{{div}}R{{roomNo}}" maxlength="100" name="roomView{{div}}R{{roomNo}}" type="text" #roomView{{div}}R{{roomNo}}="ngModel">
                        </mat-form-field>

                        <mat-form-field class="boardBasis" *ngIf="hotelInfo[div] && hotelInfo[div].addndata[roomNo]">
                          <mat-select required ngModel name="boardBasis{{div}}R{{roomNo}}" #boardBasis{{div}}R{{roomNo}}="ngModel" placeholder="Board Basis" [(ngModel)]="hotelInfo[div].addndata[roomNo].valueStr3" id="boardBasis{{div}}R{{roomNo}}">
                            <mat-option *ngFor="let board of boardBasiss" [value]="board">
                              {{board}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field class="boardBasis" *ngIf="!hotelInfo[div] || !hotelInfo[div].addndata[roomNo]">
                          <mat-select required ngModel name="boardBasis{{div}}R{{roomNo}}" #boardBasis{{div}}R{{roomNo}}="ngModel" placeholder="Board Basis" id="boardBasis{{div}}R{{roomNo}}">
                            <mat-option *ngFor="let board of boardBasiss" [value]="board">
                              {{board}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <span *ngIf="first">
                          <button class="addRemoveButtons" mat-raised-button matTooltip="Remove Last" matTooltipClass="line-normal-tooltip" color="accent" (click)="removeAddnDiv(div, 'Accommodation')" type="button">
                            <mat-icon>remove</mat-icon>
                          </button>
                          <button class="addRemoveButtons" mat-raised-button matTooltip="Add Room" matTooltipClass="line-normal-tooltip" color="primary" (click)="addAddnDiv(div, 'Accommodation')" type="button">
                            <mat-icon>add</mat-icon>
                          </button>
                        </span>
                        <br />
                      </span>

                      <input *ngIf="hotelInfo[div]" type="hidden" name="accomCount{{div}}" #accomCount{{div}}="ngModel" [(ngModel)]="hotelInfo[div].accomCount" />
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Car Parking'">
                  <h3>Car Parking Details</h3>
                  <div *ngFor="let div of carParkDivs" id="carParkDiv{{div}}">
                    <div [hidden]="!carParksNoView[div]">
                      <mat-form-field style="max-width: 180px;" *ngIf="carParkInfo[div]">
                        <mat-label>Start Date</mat-label>
                        <input ngModel required matInput name="startDate{{div}}" [(ngModel)]="carParkInfo[div].startDate" [matDatepicker]="startDate" id="startDate{{div}}" (dateChange)="valiDate($event); setEndDate('startDate' + div, 'endDate' + div)">
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!carParkInfo[div]">
                        <mat-label>Start Date</mat-label>
                        <input ngModel required matInput name="startDate{{div}}" [matDatepicker]="startDate" id="startDate{{div}}" (dateChange)="valiDate($event); setEndDate('startDate' + div, 'endDate' + div)">
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="startHrs" style="width: 85px;" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="startDateHrs{{div}}" (change)="leadingZeroCheck('startDateHrs'+div)" [(ngModel)]="carParkInfo[div].startDateHrs" name="startDateHrs{{div}}" min="0" max="23"
                          #startDateHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startHrs" style="width: 85px;" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="startDateHrs{{div}}" (change)="leadingZeroCheck('startDateHrs'+div)" name="startDateHrs{{div}}" min="0" max="23" #startDateHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startMin" style="width: 85px;" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="startDateMin{{div}}" (change)="leadingZeroCheck('startDateMin'+div)" [(ngModel)]="carParkInfo[div].startDateMin" name="startDateMin{{div}}" min="0" max="59"
                          #startDateMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startMin" style="width: 85px;" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="startDateMin{{div}}" (change)="leadingZeroCheck('startDateMin'+div)" name="startDateMin{{div}}" min="0" max="59" #startDateMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="max-width: 180px;" *ngIf="carParkInfo[div]">
                        <mat-label>End Date</mat-label>
                        <input ngModel required matInput name="endDate{{div}}" [(ngModel)]="carParkInfo[div].endDate" [matDatepicker]="endDate" id="endDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!carParkInfo[div]">
                        <mat-label>End Date</mat-label>
                        <input ngModel required matInput name="endDate{{div}}" [matDatepicker]="endDate" id="endDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="endHrs" style="width: 85px;" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="endDateHrs{{div}}" (change)="leadingZeroCheck('endDateHrs'+div)" [(ngModel)]="carParkInfo[div].endDateHrs" name="endDateHrs{{div}}" min="0" max="23"
                          #endDateHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endHrs" style="width: 85px;" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="endDateHrs{{div}}" (change)="leadingZeroCheck('endDateHrs'+div)" name="endDateHrs{{div}}" min="0" max="23" #endDateHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endMin" style="width: 85px;" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="endDateMin{{div}}" (change)="leadingZeroCheck('endDateMin'+div)" [(ngModel)]="carParkInfo[div].endDateMin" name="endDateMin{{div}}" min="0" max="59"
                          #endDateMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endMin" style="width: 85px;" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="endDateMin{{div}}" (change)="leadingZeroCheck('endDateMin'+div)" name="endDateMin{{div}}" min="0" max="59" #endDateMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="locationPark" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Location" name="locationPark{{div}}" maxlength="100" [(ngModel)]="carParkInfo[div].location" #locationPark{{div}}="ngModel" type="text" id="locationPark{{div}}">
                      </mat-form-field>

                      <mat-form-field class="locationPark" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Location" name="locationPark{{div}}" maxlength="100" #locationPark{{div}}="ngModel" type="text" id="locationPark{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carParkName" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Parking Name" name="carParkName{{div}}" maxlength="100" [(ngModel)]="carParkInfo[div].carParkName" #carParkName{{div}}="ngModel" type="text" id="carParkName{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carParkName" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Parking Name" name="carParkName{{div}}" maxlength="100" #carParkName{{div}}="ngModel" type="text" id="carParkName{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carParkCode" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Parking Code" name="carParkCode{{div}}" maxlength="50" [(ngModel)]="carParkInfo[div].carParkCode" #carParkCode{{div}}="ngModel" type="text" id="carParkCode{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carParkCode" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Parking Code" name="carParkCode{{div}}" maxlength="50" #carParkCode{{div}}="ngModel" type="text" id="carParkCode{{div}}">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="carMake" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Make" name="carMake{{div}}" maxlength="50" [(ngModel)]="carParkInfo[div].carMake" #carMake{{div}}="ngModel" type="text" id="carMake{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carMake" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Make" name="carMake{{div}}" maxlength="50" #carMake{{div}}="ngModel" type="text" id="carMake{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carModel" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Model" name="carModel{{div}}" maxlength="50" [(ngModel)]="carParkInfo[div].carModel" #carModel{{div}}="ngModel" type="text" id="carModel{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carModel" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Model" name="carModel{{div}}" maxlength="50" #carModel{{div}}="ngModel" type="text" id="carModel{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carReg" *ngIf="carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Registration" name="carReg{{div}}" maxlength="10" [(ngModel)]="carParkInfo[div].carReg" #carReg{{div}}="ngModel" type="text" id="carReg{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carReg" *ngIf="!carParkInfo[div]">
                        <input matInput ngModel placeholder="Car Registration" name="carReg{{div}}" maxlength="10" #carReg{{div}}="ngModel" type="text" id="carReg{{div}}">
                      </mat-form-field>

                      <input *ngIf="carParkInfo[div]" type="hidden" name="carparkCount{{div}}" #carparkCount{{div}}="ngModel" [(ngModel)]="carParkInfo[div].carparkCount" />
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Car Hire'">
                  <h3>Car Hire Details</h3>
                  <div *ngFor="let div of carHireDivs" id="carHireDiv{{div}}">
                    <div [hidden]="!carHireNoView[div]">
                      <mat-form-field class="paxNo" *ngIf="carHireInfo[div]">
                        <mat-select ngModel [(ngModel)]="carHireInfo[div].NumNoInfants" name="paxNoCarH{{div}}" #paxNoCarH{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNo" *ngIf="!carHireInfo[div]">
                        <mat-select ngModel name="paxNoCarH{{div}}" #paxNoCarH{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="carHireInfo[div]">
                        <mat-select ngModel [(ngModel)]="carHireInfo[div].numInfants" name="paxNoInfantsCarH{{div}}" #paxNoInfantsCarH{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="!carHireInfo[div]">
                        <mat-select ngModel name="paxNoInfantsCarH{{div}}" #paxNoInfantsCarH{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="driver" *ngIf="carHireInfo[div]">
                        <mat-select ngModel [(ngModel)]="carHireInfo[div].driver" required name="driver{{div}}" #driver{{div}}="ngModel" placeholder="Driver" id="driver{{div}}">
                          <mat-option *ngFor="let driver of driverArray" [value]="driver.id">
                            {{ driver.firstName }} {{ driver.lastName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="driver" *ngIf="!carHireInfo[div]">
                        <mat-select ngModel required name="driver{{div}}" #driver{{div}}="ngModel" placeholder="Driver" id="driver{{div}}">
                          <mat-option *ngFor="let driver of driverArray" [value]="driver.id">
                            {{ driver.firstName }} {{ driver.lastName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="childSeats" *ngIf="carHireInfo[div]">
                        <input matInput ngModel [(ngModel)]="carHireInfo[div].childseats" placeholder="Child Seats" name="childSeats{{div}}" min="0" max="10" #childSeats{{div}}="ngModel" type="number" id="childSeats{{div}}">
                      </mat-form-field>

                      <mat-form-field class="childSeats" *ngIf="!carHireInfo[div]">
                        <input matInput ngModel placeholder="Child Seats" name="childSeats{{div}}" min="0" max="10" #childSeats{{div}}="ngModel" type="number" id="childSeats{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carType" *ngIf="carHireInfo[div]">
                        <input matInput ngModel placeholder="Car Type" name="carType{{div}}" maxlength="255" #carType{{div}}="ngModel" type="text" id="carType{{div}}" [(ngModel)]="carHireInfo[div].carType">
                      </mat-form-field>

                      <mat-form-field class="carType" *ngIf="!carHireInfo[div]">
                        <input matInput ngModel placeholder="Car Type" name="carType{{div}}" maxlength="255" #carType{{div}}="ngModel" type="text" id="carType{{div}}">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="pickUpPlace" *ngIf="carHireInfo[div]">
                        <input matInput ngModel placeholder="Pick Up Location" name="pickUpPlace{{div}}" maxlength="50" #pickUpPlace{{div}}="ngModel" id="pickUpPlace{{div}}" [(ngModel)]="carHireInfo[div].pickUpLocation">
                      </mat-form-field>
  
                      <mat-form-field class="pickUpPlace" *ngIf="!carHireInfo[div]">
                        <input matInput ngModel placeholder="Pick Up Location" name="pickUpPlace{{div}}" maxlength="50" #pickUpPlace{{div}}="ngModel" id="pickUpPlace{{div}}">
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="carHireInfo[div]">
                        <mat-label>Pick Up Date</mat-label>
                        <input ngModel required matInput name="pickUpDateCarHire{{div}}" [matDatepicker]="pickUpDate" [(ngModel)]="carHireInfo[div].pickUpDate" id="pickUpDateCarHire{{div}}" (dateChange)="valiDate($event); setEndDate('pickUpDateCarHire' + div, 'dropOffDate' + div)">
                        <mat-datepicker-toggle matSuffix [for]="pickUpDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickUpDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!carHireInfo[div]">
                        <mat-label>Pick Up Date</mat-label>
                        <input ngModel required matInput name="pickUpDateCarHire{{div}}" [matDatepicker]="pickUpDate" id="pickUpDateCarHire{{div}}" (dateChange)="valiDate($event); setEndDate('pickUpDateCarHire' + div, 'dropOffDate' + div)">
                        <mat-datepicker-toggle matSuffix [for]="pickUpDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickUpDate></mat-datepicker>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="dropOffPlace" *ngIf="carHireInfo[div]">
                        <input matInput ngModel placeholder="Drop Off Location" name="dropOffPlace{{div}}" maxlength="50" #dropOffPlace{{div}}="ngModel" id="dropOffPlace{{div}}" [(ngModel)]="carHireInfo[div].dropOffLocation">
                      </mat-form-field>
  
                      <mat-form-field class="dropOffPlace" *ngIf="!carHireInfo[div]">
                        <input matInput ngModel placeholder="Drop Off Location" name="dropOffPlace{{div}}" maxlength="50" #dropOffPlace{{div}}="ngModel" id="dropOffPlace{{div}}">
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="carHireInfo[div]">
                        <mat-label>Drop Off Date</mat-label>
                        <input ngModel required matInput name="dropOffDate{{div}}" [matDatepicker]="dropOffDate" [(ngModel)]="carHireInfo[div].dropOffDate" id="dropOffDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dropOffDate"></mat-datepicker-toggle>
                        <mat-datepicker #dropOffDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!carHireInfo[div]">
                        <mat-label>Drop Off Date</mat-label>
                        <input ngModel required matInput name="dropOffDate{{div}}" [matDatepicker]="dropOffDate" id="dropOffDate{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dropOffDate"></mat-datepicker-toggle>
                        <mat-datepicker #dropOffDate></mat-datepicker>
                      </mat-form-field>

                      <input *ngIf="carHireInfo[div]" type="hidden" name="carHireCount{{div}}" #carHireCount{{div}}="ngModel" [(ngModel)]="carHireInfo[div].carHireCount" />
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Attraction'">
                  <h3>Attraction Details</h3>
                  <div *ngFor="let div of attraDivs" id="attraDiv{{div}}">
                    <div [hidden]="!attraNoView[div]">
                      <mat-form-field class="paxNo" *ngIf="attraInfo[div]">
                        <mat-select ngModel [(ngModel)]="attraInfo[div].adults" required name="paxNoAttra{{div}}" #paxNoAttra{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNo" *ngIf="!attraInfo[div]">
                        <mat-select ngModel required name="paxNoAttra{{div}}" #paxNoAttra{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="attraInfo[div]">
                        <mat-select ngModel [(ngModel)]="attraInfo[div].children" name="paxNoInfantsAttra{{div}}" #paxNoInfantsAttra{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="!attraInfo[div]">
                        <mat-select ngModel name="paxNoInfantsAttra{{div}}" #paxNoInfantsAttra{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="attraInfo[div]">
                        <mat-select ngModel [(ngModel)]="attraInfo[div].infants" name="paxInfantsAttra{{div}}" #paxInfantsAttra{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="!attraInfo[div]">
                        <mat-select ngModel name="paxInfantsAttra{{div}}" #paxInfantsAttra{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="eventName" *ngIf="attraInfo[div]">
                        <input required matInput ngModel placeholder="Event Name" name="nameAttra{{div}}" maxlength="50" [(ngModel)]="attraInfo[div].name" #nameAttra{{div}}="ngModel" id="nameAttra{{div}}">
                      </mat-form-field>

                      <mat-form-field class="eventName" *ngIf="!attraInfo[div]">
                        <input required matInput ngModel placeholder="Event Name" name="nameAttra{{div}}" maxlength="50" #nameAttra{{div}}="ngModel" id="nameAttra{{div}}">
                      </mat-form-field>

                      <mat-form-field class="location" *ngIf="attraInfo[div]">
                        <input matInput ngModel placeholder="Location" name="locationAttra{{div}}" maxlength="50" [(ngModel)]="attraInfo[div].location" #locationAttra{{div}}="ngModel" id="locationAttra{{div}}">
                      </mat-form-field>

                      <mat-form-field class="location" *ngIf="!attraInfo[div]">
                        <input matInput ngModel placeholder="Location" name="locationAttra{{div}}" maxlength="50" #locationAttra{{div}}="ngModel" id="locationAttra{{div}}">
                      </mat-form-field>

                      <mat-form-field class="country" *ngIf="attraInfo[div]">
                        <mat-select ngModel required name="countryAttra{{div}}" #countryAttra{{div}}="ngModel" placeholder="Country" [(ngModel)]="attraInfo[div].country" id="country{{div}}">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="country" *ngIf="!attraInfo[div]">
                        <mat-select ngModel required name="countryAttra{{div}}" #countryAttra{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>
                      
                      <mat-form-field style="max-width: 180px;" *ngIf="attraInfo[div]">
                        <mat-label>Start Date</mat-label>
                        <input ngModel required matInput name="startDateTimeAttra{{div}}" [(ngModel)]="attraInfo[div].startDateTime" [matDatepicker]="startDateTimeAttra" id="startDateTimeAttra{{div}}" (dateChange)="valiDate($event); setEndDate('startDateTimeAttra' + div, 'endDateTimeAttra' + div)">
                        <mat-datepicker-toggle matSuffix [for]="startDateTimeAttra"></mat-datepicker-toggle>
                        <mat-datepicker #startDateTimeAttra></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!attraInfo[div]">
                        <mat-label>Start Date</mat-label>
                        <input ngModel required matInput name="startDateTimeAttra{{div}}" [matDatepicker]="startDateTimeAttra" id="startDateTimeAttra{{div}}" (dateChange)="valiDate($event); setEndDate('startDateTimeAttra' + div, 'endDateTimeAttra' + div)">
                        <mat-datepicker-toggle matSuffix [for]="startDateTimeAttra"></mat-datepicker-toggle>
                        <mat-datepicker #startDateTimeAttra></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="startHrs" style="width: 85px;" *ngIf="attraInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="startDateTimeHrsAttra{{div}}" (change)="leadingZeroCheck('startDateTimeHrsAttra'+div)" [(ngModel)]="attraInfo[div].startDateTimeHrs" name="startDateTimeHrsAttra{{div}}" min="0" max="23"
                          #startDateTimeHrsAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startHrs" style="width: 85px;" *ngIf="!attraInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="startDateTimeHrsAttra{{div}}" (change)="leadingZeroCheck('startDateTimeHrsAttra'+div)" name="startDateTimeHrsAttra{{div}}" min="0" max="23" #startDateTimeHrsAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startMin" style="width: 85px;" *ngIf="attraInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="startDateTimeMinAttra{{div}}" (change)="leadingZeroCheck('startDateTimeMinAttra'+div)" [(ngModel)]="attraInfo[div].startDateTimeMin" name="startDateTimeMinAttra{{div}}" min="0" max="59"
                          #startDateTimeMinAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startMin" style="width: 85px;" *ngIf="!attraInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="startDateTimeMinAttra{{div}}" (change)="leadingZeroCheck('startDateTimeMinAttra'+div)" name="startDateTimeMinAttra{{div}}" min="0" max="59" #startDateTimeMinAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="max-width: 180px;" *ngIf="attraInfo[div]">
                        <mat-label>End Date</mat-label>
                        <input ngModel required matInput name="endDateTimeAttra{{div}}" [(ngModel)]="attraInfo[div].endDateTime" [matDatepicker]="endDateTimeAttra" id="endDateTimeAttra{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="endDateTimeAttra"></mat-datepicker-toggle>
                        <mat-datepicker #endDateTimeAttra></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!attraInfo[div]">
                        <mat-label>End Date</mat-label>
                        <input ngModel required matInput name="endDateTimeAttra{{div}}" [matDatepicker]="endDateTimeAttra" id="endDateTimeAttra{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="endDateTimeAttra"></mat-datepicker-toggle>
                        <mat-datepicker #endDateTimeAttra></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="endHrs" style="width: 85px;" *ngIf="attraInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="endDateTimeHrsAttra{{div}}" (change)="leadingZeroCheck('endDateTimeHrsAttra'+div)" [(ngModel)]="attraInfo[div].endDateTimeHrs" name="endDateTimeHrsAttra{{div}}" min="0" max="23"
                          #endDateTimeHrsAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endHrs" style="width: 85px;" *ngIf="!attraInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="endDateTimeHrsAttra{{div}}" (change)="leadingZeroCheck('endDateTimeHrsAttra'+div)" name="endDateTimeHrsAttra{{div}}" min="0" max="23" #endDateTimeHrsAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endMin" style="width: 85px;" *ngIf="attraInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="endDateTimeMinAttra{{div}}" (change)="leadingZeroCheck('endDateTimeMinAttra'+div)" [(ngModel)]="attraInfo[div].endDateTimeMin" name="endDateTimeMinAttra{{div}}" min="0" max="59"
                          #endDateTimeMinAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endMin" style="width: 85px;" *ngIf="!attraInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="endDateTimeMinAttra{{div}}" (change)="leadingZeroCheck('endDateTimeMinAttra'+div)" name="endDateTimeMinAttra{{div}}" min="0" max="59" #endDateTimeMinAttra{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <input *ngIf="attraInfo[div]" type="hidden" name="attractionCount{{div}}" #attractionCount{{div}}="ngModel" [(ngModel)]="attraInfo[div].attractionCount" />
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Cruise'">
                  <h3>Cruise Details</h3>
                  <div *ngFor="let div of cruiseDivs" id="cruiseDiv{{div}}">
                    <div [hidden]="!cruiseNoView[div]">
                      <mat-form-field class="paxNo" *ngIf="cruiseInfo[div]">
                        <mat-select ngModel [(ngModel)]="cruiseInfo[div].adults" required name="paxNoCruise{{div}}" #paxNoCruise{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNo" *ngIf="!cruiseInfo[div]">
                        <mat-select ngModel required name="paxNoCruise{{div}}" #paxNoCruise{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="cruiseInfo[div]">
                        <mat-select ngModel [(ngModel)]="cruiseInfo[div].children" name="paxNoInfantsCruise{{div}}" #paxNoInfantsCruise{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="!cruiseInfo[div]">
                        <mat-select ngModel name="paxNoInfantsCruise{{div}}" #paxNoInfantsCruise{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="cruiseInfo[div]">
                        <mat-select ngModel [(ngModel)]="cruiseInfo[div].infants" name="paxInfantsCruise{{div}}" #paxInfantsCruise{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="!cruiseInfo[div]">
                        <mat-select ngModel name="paxInfantsCruise{{div}}" #paxInfantsCruise{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="cruiseLine" *ngIf="cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Cruise Line" name="cruiseLine{{div}}" maxlength="50" [(ngModel)]="cruiseInfo[div].cruiseLine" #cruiseLine{{div}}="ngModel" id="cruiseLine{{div}}">
                      </mat-form-field>

                      <mat-form-field class="cruiseLine" *ngIf="!cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Cruise Line" name="cruiseLine{{div}}" maxlength="50" #cruiseLine{{div}}="ngModel" id="cruiseLine{{div}}">
                      </mat-form-field>

                      <mat-form-field class="cruiseName" *ngIf="cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Cruise Name" name="cruiseName{{div}}" maxlength="50" [(ngModel)]="cruiseInfo[div].cruiseName" #cruiseName{{div}}="ngModel" id="cruiseName{{div}}">
                      </mat-form-field>

                      <mat-form-field class="cruiseName" *ngIf="!cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Cruise Name" name="cruiseName{{div}}" maxlength="50" #cruiseName{{div}}="ngModel" id="cruiseName{{div}}">
                      </mat-form-field>

                      <mat-form-field class="cruiseShip" *ngIf="cruiseInfo[div]">
                        <input matInput ngModel placeholder="Cruise Ship" name="cruiseShip{{div}}" maxlength="50" [(ngModel)]="cruiseInfo[div].cruiseShip" #cruiseShip{{div}}="ngModel" id="cruiseShip{{div}}">
                      </mat-form-field>

                      <mat-form-field class="cruiseShip" *ngIf="!cruiseInfo[div]">
                        <input matInput ngModel placeholder="Cruise Ship" name="cruiseShip{{div}}" maxlength="50" #cruiseShip{{div}}="ngModel" id="cruiseShip{{div}}">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="region" *ngIf="cruiseInfo[div]">
                        <mat-select ngModel name="region{{div}}" #region{{div}}="ngModel" placeholder="Region" id="region{{div}}" [(ngModel)]="cruiseInfo[div].region">
                          <mat-option *ngFor="let region of cruiseRegions" [value]="region">
                            {{region}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="region" *ngIf="!cruiseInfo[div]">
                        <mat-select ngModel name="region{{div}}" #region{{div}}="ngModel" placeholder="Region" id="region{{div}}">
                          <mat-option *ngFor="let region of cruiseRegions" [value]="region">
                            {{region}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="visitingPort" *ngIf="cruiseInfo[div]">
                        <input matInput ngModel placeholder="Visiting Port" name="visitingPort{{div}}" maxlength="50" [(ngModel)]="cruiseInfo[div].visitingPort" #visitingPort{{div}}="ngModel" id="visitingPort{{div}}">
                      </mat-form-field>

                      <mat-form-field class="visitingPort" *ngIf="!cruiseInfo[div]">
                        <input matInput ngModel placeholder="Visiting Port" name="visitingPort{{div}}" maxlength="50" #visitingPort{{div}}="ngModel" id="visitingPort{{div}}">
                      </mat-form-field>

                      <mat-form-field class="voyageCode" *ngIf="cruiseInfo[div]">
                        <input matInput ngModel placeholder="Voyage Code" name="voyageCode{{div}}" maxlength="50" [(ngModel)]="cruiseInfo[div].voyageCode" #voyageCode{{div}}="ngModel" id="voyageCode{{div}}">
                      </mat-form-field>

                      <mat-form-field class="voyageCode" *ngIf="!cruiseInfo[div]">
                        <input matInput ngModel placeholder="Voyage Code" name="voyageCode{{div}}" maxlength="50" #voyageCode{{div}}="ngModel" id="voyageCode{{div}}">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="max-width: 180px;" *ngIf="cruiseInfo[div]">
                        <mat-label>Departure Date</mat-label>
                        <input ngModel required matInput name="deptDate{{div}}" [matDatepicker]="departureDate" id="departureDate{{div}}" [(ngModel)]="cruiseInfo[div].deptDate" (dateChange)="valiDate($event); setEndDate('departureDate' + div, 'returnDateCruise' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                        <mat-datepicker #departureDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!cruiseInfo[div]">
                        <mat-label>Departure Date</mat-label>
                        <input ngModel required matInput name="deptDate{{div}}" [matDatepicker]="departureDate" id="departureDate{{div}}" (dateChange)="valiDate($event); setEndDate('departureDate' + div, 'returnDateCruise' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                        <mat-datepicker #departureDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="departurePort" *ngIf="cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Departure Port" name="departurePort{{div}}" maxlength="50" [(ngModel)]="cruiseInfo[div].departurePort" #departurePort{{div}}="ngModel" id="departurePort{{div}}">
                      </mat-form-field>

                      <mat-form-field class="departurePort" *ngIf="!cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Departure Port" name="departurePort{{div}}" maxlength="50" #departurePort{{div}}="ngModel" id="departurePort{{div}}">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="max-width: 180px;" *ngIf="cruiseInfo[div]">
                        <mat-label>Return Date</mat-label>
                        <input ngModel required matInput name="returnDateCruise{{div}}" [matDatepicker]="returnDateCruise" id="returnDateCruise{{div}}" [(ngModel)]="cruiseInfo[div].returnDate" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="returnDateCruise"></mat-datepicker-toggle>
                        <mat-datepicker #returnDateCruise></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!cruiseInfo[div]">
                        <mat-label>Return Date</mat-label>
                        <input ngModel required matInput name="returnDateCruise{{div}}" [matDatepicker]="returnDateCruise" id="returnDateCruise{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="returnDateCruise"></mat-datepicker-toggle>
                        <mat-datepicker #returnDateCruise></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="disembarkPort" *ngIf="cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Disembark Port" name="disembarkPort{{div}}" maxlength="50" [(ngModel)]="cruiseInfo[div].disembarkPort" #disembarkPort{{div}}="ngModel" id="disembarkPort{{div}}">
                      </mat-form-field>

                      <mat-form-field class="disembarkPort" *ngIf="!cruiseInfo[div]">
                        <input matInput required ngModel placeholder="Disembark Port" name="disembarkPort{{div}}" maxlength="50" #disembarkPort{{div}}="ngModel" id="disembarkPort{{div}}">
                      </mat-form-field>

                      <h3>On Board Credit</h3>

                      <mat-form-field class="creditValue" *ngIf="cruiseInfo[div]">
                        <input matInput ngModel placeholder="Credit Value" [(ngModel)]="cruiseInfo[div].onBoardCreditVal" id="onBoardCreditVal{{div}}" name="onBoardCreditVal{{div}}" type="number" step="0.01" #onBoardCreditVal{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field class="creditValue" *ngIf="!cruiseInfo[div]">
                        <input matInput ngModel placeholder="Credit Value" id="onBoardCreditVal{{div}}" name="onBoardCreditVal{{div}}" type="number" step="0.01" #onBoardCreditVal{{div}}="ngModel">
                      </mat-form-field>

                      <mat-form-field class="creditCurrency" *ngIf="cruiseInfo[div]">
                        <mat-select ngModel [(ngModel)]="cruiseInfo[div].onBoardCreditCur" name="onBoardCreditCur{{div}}" #onBoardCreditCur{{div}}="ngModel" placeholder="Currency">
                          <mat-option *ngFor="let currency of cruiseCurrencies" [value]="currency">
                            {{currency}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="creditCurrency" *ngIf="!cruiseInfo[div]">
                        <mat-select ngModel name="onBoardCreditCur{{div}}" #onBoardCreditCur{{div}}="ngModel" placeholder="Currency">
                          <mat-option *ngFor="let currency of cruiseCurrencies" [value]="currency">
                            {{currency}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <input *ngIf="cruiseInfo[div]" type="hidden" name="cruiseCount{{div}}" #cruiseCount{{div}}="ngModel" [(ngModel)]="cruiseInfo[div].cruiseCount" />

                      <span *ngFor="let cabinNo of cruiseCabinDivs[div]; let first = first;">
                        <h3>Cabin {{cabinNo + 1}} Details</h3>

                        <mat-form-field class="cabinName" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Name" name="cabinName{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr1" #cabinName{{div}}C{{cabinNo}}="ngModel" id="cabinName{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinName" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Name" name="cabinName{{div}}C{{cabinNo}}" maxlength="100" #cabinName{{div}}C{{cabinNo}}="ngModel" id="cabinName{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinGrade" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Grade" name="cabinGrade{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr2" #cabinGrade{{div}}C{{cabinNo}}="ngModel" id="cabinGrade{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinGrade" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Grade" name="cabinGrade{{div}}C{{cabinNo}}" maxlength="100" #cabinGrade{{div}}C{{cabinNo}}="ngModel" id="cabinGrade{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinNumber" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Number" name="cabinNumber{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr3" #cabinNumber{{div}}C{{cabinNo}}="ngModel"
                            id="cabinNumber{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinNumber" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Number" name="cabinNumber{{div}}C{{cabinNo}}" maxlength="100" #cabinNumber{{div}}C{{cabinNo}}="ngModel" id="cabinNumber{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <span *ngIf="first">
                          <button class="addRemoveButtons" mat-raised-button matTooltip="Remove Last" matTooltipClass="line-normal-tooltip" color="accent" (click)="removeAddnDiv(div, 'Cruise')" type="button">
                            <mat-icon>remove</mat-icon>
                          </button>
                          <button class="addRemoveButtons" mat-raised-button matTooltip="Add Cabin" matTooltipClass="line-normal-tooltip" color="primary" (click)="addAddnDiv(div, 'Cruise')" type="button">
                            <mat-icon>add</mat-icon>
                          </button>
                        </span>

                        <p></p>

                        <mat-form-field class="cabinDeck" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Deck" name="cabinDeck{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr4" #cabinDeck{{div}}C{{cabinNo}}="ngModel" id="cabinDeck{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinDeck" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Deck" name="cabinDeck{{div}}C{{cabinNo}}" maxlength="100" #cabinDeck{{div}}C{{cabinNo}}="ngModel" id="cabinDeck{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="shipSide" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Ship Side" name="shipSide{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr5" #shipSide{{div}}C{{cabinNo}}="ngModel" id="shipSide{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="shipSide" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Ship Side" name="shipSide{{div}}C{{cabinNo}}" maxlength="100" #shipSide{{div}}C{{cabinNo}}="ngModel" id="shipSide{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinPosition" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Position" name="cabinPosition{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr6" #cabinPosition{{div}}C{{cabinNo}}="ngModel"
                            id="cabinPosition{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="cabinPosition" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Cabin Position" name="cabinPosition{{div}}C{{cabinNo}}" maxlength="100" #cabinPosition{{div}}C{{cabinNo}}="ngModel" id="cabinPosition{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <p></p>

                        <mat-form-field class="bedType" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Bed Type" name="bedType{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr7" #bedType{{div}}C{{cabinNo}}="ngModel" id="bedType{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="bedType" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Bed Type" name="bedType{{div}}C{{cabinNo}}" maxlength="100" #bedType{{div}}C{{cabinNo}}="ngModel" id="bedType{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="seating" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Seating" name="seating{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr8" #seating{{div}}C{{cabinNo}}="ngModel" id="seating{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="seating" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Seating" name="seating{{div}}C{{cabinNo}}" maxlength="100" #seating{{div}}C{{cabinNo}}="ngModel" id="seating{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="tableSize" *ngIf="cruiseInfo[div] && cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Table Size" name="tableSize{{div}}C{{cabinNo}}" maxlength="100" [(ngModel)]="cruiseInfo[div].addndata[cabinNo].valueStr9" #tableSize{{div}}C{{cabinNo}}="ngModel" id="tableSize{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <mat-form-field class="tableSize" *ngIf="!cruiseInfo[div] || !cruiseInfo[div].addndata[cabinNo]">
                          <input matInput ngModel placeholder="Table Size" name="tableSize{{div}}C{{cabinNo}}" maxlength="100" #tableSize{{div}}C{{cabinNo}}="ngModel" id="tableSize{{div}}C{{cabinNo}}">
                        </mat-form-field>

                        <br />
                      </span>

                      <p></p>
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Miscellaneous'">
                  <h3>Miscellaneous Details</h3>
                  <div *ngFor="let div of miscDivs" id="miscDiv{{div}}">
                    <div [hidden]="!miscNoView[div]">
                      <mat-form-field class="paxNo" *ngIf="miscInfo[div]">
                        <mat-select ngModel [(ngModel)]="miscInfo[div].adults" required name="paxNoMisc{{div}}" #paxNoMisc{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNo" *ngIf="!miscInfo[div]">
                        <mat-select ngModel required name="paxNoMisc{{div}}" #paxNoMisc{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="miscInfo[div]">
                        <mat-select ngModel [(ngModel)]="miscInfo[div].children" name="paxNoInfantsMisc{{div}}" #paxNoInfantsMisc{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="!miscInfo[div]">
                        <mat-select ngModel name="paxNoInfantsMisc{{div}}" #paxNoInfantsMisc{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="miscInfo[div]">
                        <mat-select ngModel [(ngModel)]="miscInfo[div].infants" name="paxInfantsMisc{{div}}" #paxInfantsMisc{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="!miscInfo[div]">
                        <mat-select ngModel name="paxInfantsMisc{{div}}" #paxInfantsMisc{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="eventName" *ngIf="miscInfo[div]">
                        <input matInput required ngModel placeholder="Misc Name" name="nameMisc{{div}}" maxlength="50" [(ngModel)]="miscInfo[div].name" #nameMisc{{div}}="ngModel" id="nameMisc{{div}}">
                      </mat-form-field>

                      <mat-form-field class="eventName" *ngIf="!miscInfo[div]">
                        <input matInput required ngModel placeholder="Misc Name" name="nameMisc{{div}}" maxlength="50" #nameMisc{{div}}="ngModel" id="nameMisc{{div}}">
                      </mat-form-field>

                      <mat-form-field class="location" *ngIf="miscInfo[div]">
                        <input matInput ngModel placeholder="Location" name="locationMisc{{div}}" maxlength="50" [(ngModel)]="miscInfo[div].location" #locationMisc{{div}}="ngModel" id="locationMisc{{div}}">
                      </mat-form-field>

                      <mat-form-field class="location" *ngIf="!miscInfo[div]">
                        <input matInput ngModel placeholder="Location" name="locationMisc{{div}}" maxlength="50" #locationMisc{{div}}="ngModel" id="locationMisc{{div}}">
                      </mat-form-field>

                      <mat-form-field class="country" *ngIf="miscInfo[div]">
                        <mat-select ngModel required name="countryMisc{{div}}" #countryMisc{{div}}="ngModel" placeholder="Country" [(ngModel)]="miscInfo[div].country" id="country{{div}}">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="country" *ngIf="!miscInfo[div]">
                        <mat-select ngModel required name="countryMisc{{div}}" #countryMisc{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="max-width: 180px;" *ngIf="miscInfo[div]">
                        <mat-label>Start Date</mat-label>
                        <input ngModel required matInput name="startDateTimeMisc{{div}}" [(ngModel)]="miscInfo[div].startDateTime" [matDatepicker]="startDateTimeMisc" id="startDateTimeMisc{{div}}" (dateChange)="valiDate($event); setEndDate('startDateTimeMisc' + div, 'endDateTimeMisc' + div)">
                        <mat-datepicker-toggle matSuffix [for]="startDateTimeMisc"></mat-datepicker-toggle>
                        <mat-datepicker #startDateTimeMisc></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!miscInfo[div]">
                        <mat-label>Start Date</mat-label>
                        <input ngModel required matInput name="startDateTimeMisc{{div}}" [matDatepicker]="startDateTimeMisc" id="startDateTimeMisc{{div}}" (dateChange)="valiDate($event); setEndDate('startDateTimeMisc' + div, 'endDateTimeMisc' + div)">
                        <mat-datepicker-toggle matSuffix [for]="startDateTimeMisc"></mat-datepicker-toggle>
                        <mat-datepicker #startDateTimeMisc></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="startHrs" style="width: 85px;" *ngIf="miscInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="startDateTimeHrsMisc{{div}}" (change)="leadingZeroCheck('startDateTimeHrsMisc'+div)" [(ngModel)]="miscInfo[div].startDateTimeHrs" name="startDateTimeHrsMisc{{div}}" min="0" max="23"
                          #startDateTimeHrsMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startHrs" style="width: 85px;" *ngIf="!miscInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="startDateTimeHrsMisc{{div}}" (change)="leadingZeroCheck('startDateTimeHrsMisc'+div)" name="startDateTimeHrsMisc{{div}}" min="0" max="23" #startDateTimeHrsMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startMin" style="width: 85px;" *ngIf="miscInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="startDateTimeMinMisc{{div}}" (change)="leadingZeroCheck('startDateTimeMinMisc'+div)" [(ngModel)]="miscInfo[div].startDateTimeMin" name="startDateTimeMinMisc{{div}}" min="0" max="59"
                          #startDateTimeMinMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="startMin" style="width: 85px;" *ngIf="!miscInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="startDateTimeMinMisc{{div}}" (change)="leadingZeroCheck('startDateTimeMinMisc'+div)" name="startDateTimeMinMisc{{div}}" min="0" max="59" #startDateTimeMinMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field style="max-width: 180px;" *ngIf="miscInfo[div]">
                        <mat-label>End Date</mat-label>
                        <input ngModel required matInput name="endDateTimeMisc{{div}}" [(ngModel)]="miscInfo[div].endDateTime" [matDatepicker]="endDateTimeMisc" id="endDateTimeMisc{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="endDateTimeMisc"></mat-datepicker-toggle>
                        <mat-datepicker #endDateTimeMisc></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!miscInfo[div]">
                        <mat-label>End Date</mat-label>
                        <input ngModel required matInput name="endDateTimeMisc{{div}}" [matDatepicker]="endDateTimeMisc" id="endDateTimeMisc{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="endDateTimeMisc"></mat-datepicker-toggle>
                        <mat-datepicker #endDateTimeMisc></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="endHrs" style="width: 85px;" *ngIf="miscInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="endDateTimeHrsMisc{{div}}" (change)="leadingZeroCheck('endDateTimeHrsMisc'+div)" [(ngModel)]="miscInfo[div].endDateTimeHrs" name="endDateTimeHrsMisc{{div}}" min="0" max="23"
                          #endDateTimeHrsMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endHrs" style="width: 85px;" *ngIf="!miscInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="endDateTimeHrsMisc{{div}}" (change)="leadingZeroCheck('endDateTimeHrsMisc'+div)" name="endDateTimeHrsMisc{{div}}" min="0" max="23" #endDateTimeHrsMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endMin" style="width: 85px;" *ngIf="miscInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="endDateTimeMinMisc{{div}}" (change)="leadingZeroCheck('endDateTimeMinMisc'+div)" [(ngModel)]="miscInfo[div].endDateTimeMin" name="endDateTimeMinMisc{{div}}" min="0" max="59"
                          #endDateTimeMinMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="endMin" style="width: 85px;" *ngIf="!miscInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="endDateTimeMinMisc{{div}}" (change)="leadingZeroCheck('endDateTimeMinMisc'+div)" name="endDateTimeMinMisc{{div}}" min="0" max="59" #endDateTimeMinMisc{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <input *ngIf="miscInfo[div]" type="hidden" name="miscCount{{div}}" #miscCount{{div}}="ngModel" [(ngModel)]="miscInfo[div].miscCount" />
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Train'">
                  <h3>Train Details</h3>
                  <div *ngFor="let div of trainDivs" id="trainDiv{{div}}">
                    <div [hidden]="!trainNoView[div]">
                      <mat-form-field class="paxNo" *ngIf="trainInfo[div]">
                        <mat-select ngModel [(ngModel)]="trainInfo[div].adults" required name="paxNoTrain{{div}}" #paxNoTrain{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNo" *ngIf="!trainInfo[div]">
                        <mat-select ngModel required name="paxNoTrain{{div}}" #paxNoTrain{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="trainInfo[div]">
                        <mat-select ngModel [(ngModel)]="trainInfo[div].children" name="paxNoInfantsTrain{{div}}" #paxNoInfantsTrain{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="!trainInfo[div]">
                        <mat-select ngModel name="paxNoInfantsTrain{{div}}" #paxNoInfantsTrain{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="trainInfo[div]">
                        <mat-select ngModel [(ngModel)]="trainInfo[div].infants" name="paxInfantsTrain{{div}}" #paxInfantsTrain{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="!trainInfo[div]">
                        <mat-select ngModel name="paxInfantsTrain{{div}}" #paxInfantsTrain{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <h3>Departure</h3>

                      <mat-form-field class="departStation" *ngIf="trainInfo[div]">
                        <input matInput required ngModel placeholder="Station Name" name="departStation{{div}}" maxlength="100" [(ngModel)]="trainInfo[div].departStation" #departStation{{div}}="ngModel" id="departStation{{div}}">
                      </mat-form-field>
  
                      <mat-form-field class="departStation" *ngIf="!trainInfo[div]">
                        <input matInput required ngModel placeholder="Station Name" name="departStation{{div}}" maxlength="100" #departStation{{div}}="ngModel" id="departStation{{div}}">
                      </mat-form-field>
  
                      <mat-form-field style="max-width: 180px;" *ngIf="trainInfo[div]">
                        <mat-label>Departure Date</mat-label>
                        <input ngModel required matInput name="departDateTime{{div}}" [matDatepicker]="departureDate" id="departDateTime{{div}}" [(ngModel)]="trainInfo[div].departDateTime" (dateChange)="valiDate($event); setEndDate('departDateTime' + div, 'arriveDateTime' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                        <mat-datepicker #departureDate></mat-datepicker>
                      </mat-form-field>
  
                      <mat-form-field style="max-width: 180px;" *ngIf="!trainInfo[div]">
                        <mat-label>Departure Date</mat-label>
                        <input ngModel required matInput name="departDateTime{{div}}" [matDatepicker]="departureDate" id="departDateTime{{div}}" (dateChange)="valiDate($event); setEndDate('departDateTime' + div, 'arriveDateTime' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                        <mat-datepicker #departureDate></mat-datepicker>
                      </mat-form-field>
  
                      <mat-form-field class="deptHrs" style="width: 85px;" *ngIf="trainInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="deptHrs{{div}}" (change)="leadingZeroCheck('deptHrs'+div)" [(ngModel)]="trainInfo[div].departHrs" name="deptHrs{{div}}" min="0" max="23" #deptHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>
  
                      <mat-form-field class="deptHrs" style="width: 85px;" *ngIf="!trainInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="deptHrs{{div}}" (change)="leadingZeroCheck('deptHrs'+div)" name="deptHrs{{div}}" min="0" max="23" #deptHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>
  
                      <mat-form-field class="deptMin" style="width: 85px;" *ngIf="trainInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="deptMin{{div}}" (change)="leadingZeroCheck('deptMin'+div)" [(ngModel)]="trainInfo[div].departMin" name="deptMin{{div}}" min="0" max="59" #deptMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>
  
                      <mat-form-field class="deptMin" style="width: 85px;" *ngIf="!trainInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="deptMin{{div}}" (change)="leadingZeroCheck('deptMin'+div)" name="deptMin{{div}}" min="0" max="59" #deptMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <h3>Arrival</h3>

                      <mat-form-field class="arriveStation" *ngIf="trainInfo[div]">
                        <input matInput required ngModel placeholder="Station Name" name="arriveStation{{div}}" maxlength="100" [(ngModel)]="trainInfo[div].arriveStation" #arriveStation{{div}}="ngModel" id="arriveStation{{div}}">
                      </mat-form-field>
  
                      <mat-form-field class="arriveStation" *ngIf="!trainInfo[div]">
                        <input matInput required ngModel placeholder="Station Name" name="arriveStation{{div}}" maxlength="100" #arriveStation{{div}}="ngModel" id="arriveStation{{div}}">
                      </mat-form-field>
  
                      <mat-form-field style="max-width: 180px;" *ngIf="trainInfo[div]">
                        <mat-label>Arrival Date</mat-label>
                        <input ngModel required matInput name="arriveDateTime{{div}}" [matDatepicker]="dropDate" id="arriveDateTime{{div}}" [(ngModel)]="trainInfo[div].arriveDateTime" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dropDate"></mat-datepicker-toggle>
                        <mat-datepicker #dropDate></mat-datepicker>
                      </mat-form-field>
  
                      <mat-form-field style="max-width: 180px;" *ngIf="!trainInfo[div]">
                        <mat-label>Arrival Date</mat-label>
                        <input ngModel required matInput name="arriveDateTime{{div}}" [matDatepicker]="dropDate" id="arriveDateTime{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dropDate"></mat-datepicker-toggle>
                        <mat-datepicker #dropDate></mat-datepicker>
                      </mat-form-field>
  
                      <mat-form-field class="arrHrs" style="width: 85px;" *ngIf="trainInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="arrHrs{{div}}" (change)="leadingZeroCheck('arrHrs'+div)" [(ngModel)]="trainInfo[div].arriveHrs" name="arrHrs{{div}}" min="0" max="23" #arrHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>
  
                      <mat-form-field class="arrHrs" style="width: 85px;" *ngIf="!trainInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="arrHrs{{div}}" (change)="leadingZeroCheck('arriveHrs'+div)" name="arrHrs{{div}}" min="0" max="23" #arrHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>
  
                      <mat-form-field class="arrMin" style="width: 85px;" *ngIf="trainInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="arrMin{{div}}" (change)="leadingZeroCheck('arrMin'+div)" [(ngModel)]="trainInfo[div].arriveMin" name="arrMin{{div}}" min="0" max="59" #arrMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>
  
                      <mat-form-field class="arrMin" style="width: 85px;" *ngIf="!trainInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="arrMin{{div}}" (change)="leadingZeroCheck('arrMin'+div)" name="arrMin{{div}}" min="0" max="59" #arrMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <h3>Extras</h3>

                      <mat-form-field class="trainNameNo" *ngIf="trainInfo[div]">
                        <input matInput ngModel placeholder="Train Name / Number" [(ngModel)]="trainInfo[div].trainNameNo" name="trainNameNo{{div}}" maxlength="50" #trainNameNo{{div}}="ngModel" id="trainNameNo{{div}}">
                      </mat-form-field>

                      <mat-form-field class="trainNameNo" *ngIf="!trainInfo[div]">
                        <input matInput ngModel placeholder="Train Name / Number" name="trainNameNo{{div}}" maxlength="50" #trainNameNo{{div}}="ngModel" id="trainNameNo{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carriageClass" *ngIf="trainInfo[div]">
                        <input matInput ngModel placeholder="Carriage Class(es)" [(ngModel)]="trainInfo[div].carriageClass" name="carriageClass{{div}}" maxlength="50" #carriageClass{{div}}="ngModel" id="carriageClass{{div}}">
                      </mat-form-field>

                      <mat-form-field class="carriageClass" *ngIf="!trainInfo[div]">
                        <input matInput ngModel placeholder="Carriage Class(es)" name="carriageClass{{div}}" maxlength="50" #carriageClass{{div}}="ngModel" id="carriageClass{{div}}">
                      </mat-form-field>

                      <mat-form-field class="seat" *ngIf="trainInfo[div]">
                        <input matInput ngModel placeholder="Seat Number(s)" [(ngModel)]="trainInfo[div].seat" name="seat{{div}}" maxlength="10" #seat{{div}}="ngModel" id="seat{{div}}">
                      </mat-form-field>

                      <mat-form-field class="seat" *ngIf="!trainInfo[div]">
                        <input matInput ngModel placeholder="Seat Number(s)" name="seat{{div}}" maxlength="10" #seat{{div}}="ngModel" id="seat{{div}}">
                      </mat-form-field>

                      <input *ngIf="trainInfo[div]" type="hidden" name="trainCount{{div}}" #trainCount{{div}}="ngModel" [(ngModel)]="trainInfo[div].trainCount" />
                    </div>
                  </div>
                </span>

                <span [hidden]="selectedEleType != 'Transfer'">
                  <h3>Transfer Details</h3>
                  <div *ngFor="let div of transferDivs" id="transferDiv{{div}}">
                    <div [hidden]="!transferNoView[div]">
                      <mat-form-field class="paxNo" *ngIf="transferInfo[div]">
                        <mat-select ngModel [(ngModel)]="transferInfo[div].adults" required name="paxNoTransfer{{div}}" #paxNoTransfer{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNo" *ngIf="!transferInfo[div]">
                        <mat-select ngModel required name="paxNoTransfer{{div}}" #paxNoTransfer{{div}}="ngModel" placeholder="Adults">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="transferInfo[div]">
                        <mat-select ngModel [(ngModel)]="transferInfo[div].children" name="paxNoInfantsTransfer{{div}}" #paxNoInfantsTransfer{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxNoInfants" *ngIf="!transferInfo[div]">
                        <mat-select ngModel name="paxNoInfantsTransfer{{div}}" #paxNoInfantsTransfer{{div}}="ngModel" placeholder="Children">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="transferInfo[div]">
                        <mat-select ngModel [(ngModel)]="transferInfo[div].infants" name="paxInfantsTransfer{{div}}" #paxInfantsTransfer{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="paxInfants" *ngIf="!transferInfo[div]">
                        <mat-select ngModel name="paxInfantsTransfer{{div}}" #paxInfantsTransfer{{div}}="ngModel" placeholder="Infants">
                          <mat-option *ngFor="let number of paxNumber" [value]="number">
                            {{number}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="country" *ngIf="transferInfo[div]">
                        <mat-select ngModel required name="countryTransfer{{div}}" #countryTransfer{{div}}="ngModel" placeholder="Country" id="country{{div}}" [(ngModel)]="transferInfo[div].country">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="country" *ngIf="!transferInfo[div]">
                        <mat-select ngModel required name="countryTransfer{{div}}" #countryTransfer{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                          <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>
                          <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="vehicleType" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Vehicle Type" name="VehicleType{{div}}" maxlength="50" [(ngModel)]="transferInfo[div].VehicleType" #VehicleType{{div}}="ngModel" id="vehicleType{{div}}">
                      </mat-form-field>

                      <mat-form-field class="vehicleType" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Vehicle Type" name="VehicleType{{div}}" maxlength="50" #VehicleType{{div}}="ngModel" id="vehicleType{{div}}">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="pickUpLocation" *ngIf="transferInfo[div]">
                        <input matInput required ngModel placeholder="Pick Up Location" name="pickUpLocation{{div}}" maxlength="50" [(ngModel)]="transferInfo[div].pickUpLocation" #pickUpLocation{{div}}="ngModel" id="pickUpLocation{{div}}">
                      </mat-form-field>

                      <mat-form-field class="pickUpLocation" *ngIf="!transferInfo[div]">
                        <input matInput required ngModel placeholder="Pick Up Location" name="pickUpLocation{{div}}" maxlength="50" #pickUpLocation{{div}}="ngModel" id="pickUpLocation{{div}}">
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="transferInfo[div]">
                        <mat-label>Pick Up Date</mat-label>
                        <input ngModel required matInput name="pickUpDateTransfer{{div}}" [matDatepicker]="departureDate" id="pickUpDateTransfer{{div}}" [(ngModel)]="transferInfo[div].pickUpDateTime" (dateChange)="valiDate($event); setEndDate('pickUpDateTransfer' + div, 'dropOffDateTransfer' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                        <mat-datepicker #departureDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!transferInfo[div]">
                        <mat-label>Pick Up Date</mat-label>
                        <input ngModel required matInput name="pickUpDateTransfer{{div}}" [matDatepicker]="departureDate" id="pickUpDateTransfer{{div}}" (dateChange)="valiDate($event); setEndDate('pickUpDateTransfer' + div, 'dropOffDateTransfer' + div)">
                        <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                        <mat-datepicker #departureDate></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="pickUpHrs" style="width: 85px;" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="pickUpHrs{{div}}" (change)="leadingZeroCheck('pickUpHrs'+div)" [(ngModel)]="transferInfo[div].pickUpHrs" name="pickUpHrs{{div}}" min="0" max="23"
                          #pickUpHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="pickUpHrs" style="width: 85px;" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="pickUpHrs{{div}}" (change)="leadingZeroCheck('pickUpHrs'+div)" name="pickUpHrs{{div}}" min="0" max="23" #pickUpHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="pickUpMin" style="width: 85px;" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="pickUpMin{{div}}" (change)="leadingZeroCheck('pickUpMin'+div)" [(ngModel)]="transferInfo[div].pickUpMin" name="pickUpMin{{div}}" min="0" max="59"
                          #pickUpMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="pickUpMin" style="width: 85px;" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="pickUpMin{{div}}" (change)="leadingZeroCheck('pickUpMin'+div)" name="pickUpMin{{div}}" min="0" max="59" #pickUpMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <p></p>

                      <p></p>

                      <mat-form-field class="dropOffLocation" *ngIf="transferInfo[div]">
                        <input matInput required ngModel placeholder="Drop Off Location" name="dropOffLocation{{div}}" maxlength="50" [(ngModel)]="transferInfo[div].dropOffLocation" #dropOffLocation{{div}}="ngModel" id="dropOffLocation{{div}}">
                      </mat-form-field>

                      <mat-form-field class="dropOffLocation" *ngIf="!transferInfo[div]">
                        <input matInput required ngModel placeholder="Drop Off Location" name="dropOffLocation{{div}}" maxlength="50" #dropOffLocation{{div}}="ngModel" id="dropOffLocation{{div}}">
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="transferInfo[div]">
                        <mat-label>Drop Off Date</mat-label>
                        <input ngModel required matInput name="dropOffDateTransfer{{div}}" [matDatepicker]="dropOffDateTransfer" id="dropOffDateTransfer{{div}}" [(ngModel)]="transferInfo[div].dropOffDateTime" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dropOffDateTransfer"></mat-datepicker-toggle>
                        <mat-datepicker #dropOffDateTransfer></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field style="max-width: 180px;" *ngIf="!transferInfo[div]">
                        <mat-label>Drop Off Date</mat-label>
                        <input ngModel required matInput name="dropOffDateTransfer{{div}}" [matDatepicker]="dropOffDateTransfer" id="dropOffDateTransfer{{div}}" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dropOffDateTransfer"></mat-datepicker-toggle>
                        <mat-datepicker #dropOffDateTransfer></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="dropHrs" style="width: 85px;" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="dropHrs{{div}}" (change)="leadingZeroCheck('dropHrs'+div)" [(ngModel)]="transferInfo[div].dropHrs" name="dropHrs{{div}}" min="0" max="23" #dropHrs{{div}}="ngModel"
                          style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="dropHrs" style="width: 85px;" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Hrs" type="number" id="dropHrs{{div}}" (change)="leadingZeroCheck('dropHrs'+div)" name="dropHrs{{div}}" min="0" max="23" #dropHrs{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="dropMin" style="width: 85px;" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="dropMin{{div}}" (change)="leadingZeroCheck('dropMin'+div)" [(ngModel)]="transferInfo[div].dropMin" name="dropMin{{div}}" min="0" max="59" #dropMin{{div}}="ngModel"
                          style="width: 85px;">
                      </mat-form-field>

                      <mat-form-field class="dropMin" style="width: 85px;" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Min" type="number" id="dropMin{{div}}" (change)="leadingZeroCheck('dropMin'+div)" name="dropMin{{div}}" min="0" max="59" #dropMin{{div}}="ngModel" style="width: 85px;">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="pickUpInstructions" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Pick Up Instructions" name="pickUpInstructions{{div}}" maxlength="200" [(ngModel)]="transferInfo[div].pickUpInstructions" #pickUpInstructions{{div}}="ngModel"
                          id="pickUpInstructions{{div}}">
                      </mat-form-field>

                      <mat-form-field class="pickUpInstructions" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Pick Up Instructions" name="pickUpInstructions{{div}}" maxlength="200" #pickUpInstructions{{div}}="ngModel" id="pickUpInstructions{{div}}">
                      </mat-form-field>

                      <mat-form-field class="dropOffInstructions" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Drop Off Instructions" name="dropOffInstructions{{div}}" maxlength="200" [(ngModel)]="transferInfo[div].dropOffInstructions" #dropOffInstructions{{div}}="ngModel"
                          id="dropOffInstructions{{div}}">
                      </mat-form-field>

                      <mat-form-field class="dropOffInstructions" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Drop Off Instructions" name="dropOffInstructions{{div}}" maxlength="200" #dropOffInstructions{{div}}="ngModel" id="dropOffInstructions{{div}}">
                      </mat-form-field>

                      <h5>Emergency Details</h5>

                      <mat-form-field class="emergencyResortContact" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Resort Name" name="emergencyResortContact{{div}}" maxlength="50" [(ngModel)]="transferInfo[div].emergencyResortContact" #emergencyResortContact{{div}}="ngModel"
                          id="emergencyResortContact{{div}}">
                      </mat-form-field>

                      <mat-form-field class="emergencyResortContact" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Resort Name" name="emergencyResortContact{{div}}" maxlength="50" #emergencyResortContact{{div}}="ngModel" id="emergencyResortContact{{div}}">
                      </mat-form-field>

                      <mat-form-field class="emergencyPhoneNo" *ngIf="transferInfo[div]">
                        <input matInput ngModel placeholder="Phone Number" name="emergencyPhoneNo{{div}}" maxlength="50" [(ngModel)]="transferInfo[div].emergencyPhoneNo" #emergencyPhoneNo{{div}}="ngModel" id="emergencyPhoneNo{{div}}">
                      </mat-form-field>

                      <mat-form-field class="emergencyPhoneNo" *ngIf="!transferInfo[div]">
                        <input matInput ngModel placeholder="Phone Number" name="emergencyPhoneNo{{div}}" maxlength="50" #emergencyPhoneNo{{div}}="ngModel" id="emergencyPhoneNo{{div}}">
                      </mat-form-field>

                      <input *ngIf="transferInfo[div]" type="hidden" name="transferCount{{div}}" #transferCount{{div}}="ngModel" [(ngModel)]="transferInfo[div].transferCount" />
                    </div>
                  </div>
                </span>
              </form>
            </div>

            <div fxFlex="50">
              <h3>Cost</h3>
              <mat-form-field style="width: 530px; max-width: unset;" *ngIf="selectedEleType != 'transfer'">
                <input matInput ngModel placeholder="Description" [(ngModel)]="costingBreakdown.description" id="description" maxlength="200" name="description" type="text" #description="ngModel">
              </mat-form-field>

              <p *ngIf="selectedEleType != 'transfer'"></p>

              <mat-form-field class="supplierPrice">
                <input matInput required ngModel placeholder="Gross" [(ngModel)]="costingBreakdown.supplierPrice" (ngModelChange)="changeSuppPrice($event)" id="supplierPrice" name="supplierPrice" type="number" min="0.01" step="0.01"
                  #supplierPrice="ngModel">
                <mat-hint matTooltip="Actual Gross (applied discount)" matTooltipClass="line-normal-tooltip">{{ costingBreakdown.realGross | currency: 'GBP' }}</mat-hint>
              </mat-form-field>

              <mat-form-field class="net">
                <input required matInput ngModel placeholder="Net" [(ngModel)]="costingBreakdown.netPrice" id="net" name="net" type="number" step="0.01" #net="ngModel" (ngModelChange)="changeNet($event)">
              </mat-form-field>

              <mat-form-field class="commission">
                <input matInput ngModel placeholder="Commission" [(ngModel)]="costingBreakdown.commissionPrice" id="commission" name="commission" type="number" step="0.01" #commission="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="vat">
                <input required matInput ngModel placeholder="VAT" [(ngModel)]="costingBreakdown.vatPrice" id="vat" name="vat" type="number" step="0.01" #vat="ngModel" (ngModelChange)="changeVAT($event)">
              </mat-form-field>

              <mat-form-field class="discount">
                <input required matInput ngModel placeholder="Discount" [(ngModel)]="costingBreakdown.discountPrice" id="discount" name="discount" type="number" step="0.01" #discount="ngModel" (ngModelChange)="changeDiscount($event)">
              </mat-form-field>

              <mat-form-field class="deposit">
                <input required matInput ngModel placeholder="Deposit" [(ngModel)]="costingBreakdown.depositPrice" id="deposit" name="deposit" type="number" step="0.01" #deposit="ngModel" (ngModelChange)="addUpCostings()">
              </mat-form-field>
              <h3>Total</h3>
              <mat-form-field class="supplierPrice">
                <input matInput ngModel placeholder="Gross" [(ngModel)]="totalCosts.supplierPrice" name="supplierPricee" type="number" step="0.01" #supplierPricee="ngModel" readonly="true">
                <mat-hint matTooltip="Actual Gross (applied discount)" matTooltipClass="line-normal-tooltip">{{ totalCosts.realGross | currency: 'GBP' }}</mat-hint>
              </mat-form-field>

              <mat-form-field class="net">
                <input matInput ngModel placeholder="Net" [(ngModel)]="totalCosts.netPrice" name="net" type="number" step="0.01" #net="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="commission">
                <input matInput ngModel placeholder="Commission" [(ngModel)]="totalCosts.commissionPrice" name="commission" type="number" step="0.01" #commission="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="vat">
                <input matInput ngModel placeholder="VAT" [(ngModel)]="totalCosts.vatPrice" name="vat" type="number" step="0.01" #vat="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="discount">
                <input matInput ngModel placeholder="Discount" [(ngModel)]="totalCosts.discountPrice" name="discount" type="number" step="0.01" #discount="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="deposit">
                <input matInput ngModel placeholder="Deposit" [(ngModel)]="totalCosts.depositPrice" name="deposit" type="number" step="0.01" #deposit="ngModel" readonly="true">
              </mat-form-field>

              <h5>Please make sure you've filled in all mandatory fields marked with a start (*) in all elements</h5>
              <h5><button class="buttonClass" mat-raised-button color="primary" type="submit" id="create" form="ngForm"
                          *ngIf="['memberStaff', 'memberManager', 'sinGSAdmin'].includes(userType) && memberLive">Confirm</button>
              </h5>
            </div>
          </div>
        </div>

        <div class="accessDenied" *ngIf="openedBooking == null">
          <h2 style="text-align: center; margin-top: 2.5%;">You need to select booking you wish to add element to first.</h2>
        </div>
      </mat-card>
    </mat-sidenav-content>

  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Create Element</u></h1>
    <p>This is where you can add the first element to a new supplier reference.</p>

    <p>You'll find there are 2 main sections to fill in:<br>
      <b>1. Details</b> contains basic information about the element<br>
      <b>2. Costing Details</b> is being used for the element pricing
    </p>

    <p>Please remember - number of the costing will reflect the number of the element.<br>
      For example: <b>Flight 1 Details</b> costings will be under <b>Costing 1</b></p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
