<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content [@inAnimation]>
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <div fxFlex={{leftFX}} class="container" fxLayout="column" fxLayoutAlign="space-evenly center">

      <div [style.margin-top.px]="!goodBrowser ? 115 : 0">
        <mat-card class="loginPage" [style.overflow]="'auto'">
          <div *ngIf="loginPage">

            <form ngNativeValidate #loginForm="ngForm" (ngSubmit)="login(loginForm)">
              <h1>Login
                <a *ngIf="newVersion" [routerLink]="['/changelog']" style="color: white; position: absolute; top: 15px; left: 15px;" matTooltipClass="line-broken-tooltip" matTooltip="{{versionTooltip}}"><mat-icon>new_releases</mat-icon></a>
              </h1>
              <mat-card-content>
                <mat-form-field class="full-width-input">
                  <span matPrefix>
                    <mat-icon style="margin-right: 5px;">email</mat-icon>
                  </span>
                  <input matInput ngModel required placeholder="Email" maxlength="80" minlength="5" name="email" #email="ngModel" type="email">
                </mat-form-field>
                <mat-form-field class="full-width-input">
                  <span matPrefix>
                    <mat-icon style="margin-right: 5px;">lock</mat-icon>
                  </span>
                  <input matInput ngModel required placeholder="Password" name="password" #password="ngModel" type="password">
                </mat-form-field>
              </mat-card-content>

              <mat-form-field class="full-width-input" style="display:none !important">
                <input matInput ngModel type="text" name="a_password" tabindex="-1" autocomplete="false">
              </mat-form-field>

              <mat-form-field class="full-width-input" style="display:none !important">
                <input matInput ngModel type="text" name="login" tabindex="-1" autocomplete="false">
              </mat-form-field>

              <button mat-raised-button color="accent" type="submit" style="width: 100px;">Sign In</button>

              <h6 style="margin-top: 10px; padding-bottom: 0px; margin-bottom: 0; height: 20px;"><button mat-button (click)="switchView('password')">Forgot Password?</button></h6>
            </form>
          </div>

          <div *ngIf="forgotPage">
            <h1>Remind Password</h1>
            <form ngNativeValidate #remindForm="ngForm" (ngSubmit)="remindPassword(remindForm)">
              <mat-card-content>
                <mat-form-field class="full-width-input" style="margin-bottom: 73px;">
                  <span matPrefix>
                    <mat-icon style="margin-right: 5px;">email</mat-icon>
                  </span>
                  <input matInput ngModel required placeholder="Email" type="email" maxlength="80" minlength="5" name="email" #email="ngModel">
                </mat-form-field>

                <!-- <mat-form-field class="full-width-input">
          <span matPrefix><mat-icon style="margin-right: 5px;">face</mat-icon></span>
          <input matInput ngModel required placeholder="Full Name"
          name="fullName" #fullName="ngModel">
        </mat-form-field> -->
              </mat-card-content>

              <mat-form-field class="full-width-input" style="display:none !important">
                <input matInput ngModel type="text" name="a_password" tabindex="-1" autocomplete="false">
              </mat-form-field>

              <mat-form-field class="full-width-input" style="display:none !important">
                <input matInput ngModel type="text" name="login" tabindex="-1" autocomplete="false">
              </mat-form-field>

              <button mat-raised-button color="accent" type="submit" style="width: 100px;">Send</button>
            </form>

            <h6 style="margin-top: 10px; padding-bottom: 0px; margin-bottom: 0; height: 20px;"><button mat-button (click)="switchView('login')">Back to login page</button></h6>
          </div>
        </mat-card>

        <h6 class="wrongBrowser" *ngIf="!goodBrowser">
          We recommend that you use 'Google Chrome' as your web-browser when using SinGS. Otherwise, you risk having a different user experience than expected.<br><br>
          Thank you in advance for your support,<br>
          SinGS Team
        </h6>
      </div>

      <div class="singsLogoDiv" *ngIf="innerWidth < 640">
        <img src="../../assets/singsLogoSmall.png" class="singsLogo">
      </div>

      <div class="footer" *ngIf="innerWidth < 640">
        Copyright © 2024 SinGS, All rights reserved. <a [routerLink]="['/changelog']">v1.60d</a>
      </div>
    </div>

    <div fxFlex={{rightFX}} class="containerRight">

    </div>

  </div>


  <div class="footer" *ngIf="innerWidth > 640">
    Copyright © 2024 SinGS, All rights reserved. <a [routerLink]="['/changelog']">v1.60d</a>
  </div>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>
