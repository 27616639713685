import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from './../../../environments/environment';
import { Session } from '../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';

import { UserService } from '../../services/user.service';
import { FellohService } from '../../services/felloh.service';
import { BranchService } from '../../services/branch.service';

import { AppComponent } from '../../app.component';

import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';

@Component({
  selector: 'app-payments-out',
  templateUrl: './payments-out.component.html',
  styleUrls: ['./payments-out.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class PaymentsOutComponent implements OnInit {
  // Import stuff from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginatorPayout') paginatorPayout!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  // Table data and table columns below
  fellohData: any = new MatTableDataSource<any>();
  fellohColumns = ['batchId', 'beneficiary', 'date', 'paymentsNo', 'totalAmount'];
  payOutColumns = ['bookingRef', 'custName', 'amount', 'transactionId', 'paymentDate'];

  // Variables controlling user access
  userType = '';
  haveAccess = false;
  pageLoaded = false;

  // Variables controlling side-nav view
  overView = false;
  transactionView = true;
  adminJustOpened = false;
  testView = false;

  // Variables controlling Felloh getPayoutList() and getBeneficiaries() API
  pageSize = 50;
  pageNumber = 0;
  fromDate: any = '';
  toDate: any = '';

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  fellohTxnsDummyData: any = [];
  paymentNumber: any = 0;
  totalBalance: any = 0;
  unapprovedOnly = false;

  // Variables used for Admin Only
  branchListData: any = []; // Holds data of all branches
  branchListFiltered: any = []; // Holds data filtered by the company (TTNG/GTG etc..)
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  selectedBranch: any = null;
  selectedFellohAccount: any = null;

  // Main chart options below - initially without any data
  public chartOptions: Partial<any> = {
    series: [{ name: '', data: [] },
    { name: '', data: [] }],
    chart: {
      height: 375,
      type: 'area',
      zoom: {
        enabled: false
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    colors: ['#4D5FD1', '#b0b0b0'],
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Disbursements',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value;
          } else {
            return '-£' + (value * -1);
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'rounded'
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
  };

  constructor(private router: Router, private userService: UserService,
              private fellohService: FellohService, private branchService: BranchService,
              public dialog: MatDialog) { }

  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      AppComponent.myapp.fellohNav = true;
      this.router.navigate(['/']);
    } else {
      // Browser wasn't refreshed but the button was clicked so the user could get here - no API need to be called
      this.userType = Session.mySession.getUser().userType;
      this.setStartingDates(); // Call funciton which sets fromDate <-> toDate values..

      if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff' || this.userType === 'trustee') {
        // SinGS Admin and Staff are able to retrieve transactions from all of the branches.
        if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
          this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
            if (branches.status === 'OK') {
              // Sort branch list A to Z and save it in the session first..
              const sorted = branches.data.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
              Session.mySession.setBranchList(sorted);
              // Filter out all branches with an empty Felloh account ID
              // Change Q0000 branch name to '-- All branches --' and sort once again..
              this.branchListData = sorted.filter((branch: any) => branch.fellohSetup === 'yes');
              const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
              this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
              // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
              this.branchListFiltered = this.branchListData; // It needs to be the same list
              this.changeBranch(allBranch, 'no'); // Call this to change current branch
            } else {
              this.sendMessageToDialog('', branches.status, '', '');
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1801S)', error, Session.mySession.getUser());
          });
        } else {
          // Filter out all branches with an empty Felloh account ID
          this.branchListData = Session.mySession.getBranchList().branchList.filter((branch: any) => branch.fellohSetup === 'yes');
          // Change Q0000 branch name to '-- All branches --' and sort once again..
          const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
          this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
          // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
          this.branchListFiltered = this.branchListData; // It needs to be the same list
          this.changeBranch(allBranch, 'no'); // Call this to change current branch
        }

      } else {
        if (Session.mySession.getUsersGroup().length > 0) {
          // Logged in user is in the group and branch list already exists within the session variable..
          this.branchListData = Session.mySession.getUsersGroup();
          this.branchListFiltered = this.branchListData; // It needs to be the same list
          this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode)[0], 'yes');
        } else {
          this.changeBranch(Session.mySession.getBranch(), 'yes');
        }
      }
    }
  }

  prepareForFellohTxns(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Reset total balance & charges to £0 and recalculate it again
      this.totalBalance = 0; this.paymentNumber = 0;
      // Reset dummy array to nothing - we'll need to re-calculate stuff once again
      this.pageNumber = 0; this.fellohTxnsDummyData = [];
      // Call main function below (where there may be loop within the loop)
      if (this.selectedBranch.fellohSetup === 'yes' && this.selectedBranch?.fellohConfig?.length > 0) {
        this.loadPayoutTransactions().then(() => { resolve(''); });
      } else { this.pageLoaded = true; resolve(''); }
    });
  }

  loadPayoutTransactions(): Promise<any> {
    return new Promise((resolve, reject) => {
        // Function called whenever date range or branch has been changed in UI
        // Create Felloh request hash to request access token and call the API
        Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
          // Create a Felloh payment request hash to which will be used to retrieve Felloh transactions list
          const disbursementRequest = {
            organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
            skip: this.pageNumber * this.pageSize, take: this.pageSize,
            date_from: this.fromDate, date_to: this.toDate,
          };

          this.fellohService.fetchDisbursement(disbursementRequest, tokenOut).then((disbursementRes: any) => {
            const filteredList: any = disbursementRes.data;

            filteredList.forEach((element: any) => {              
              if (element.currency === 'GBX') { element.currency = 'GBP'; element.amount = element.amount / 100; }
              else if (element.currency === 'USX') { element.currency = 'USD'; element.amount = element.amount / 100; }
              else if (element.currency === 'EUX') { element.currency = 'EUR'; element.amount = element.amount / 100; }

              element.detailRow = true;  // Expandable rows..
              this.totalBalance = this.totalBalance + element.amount; // Add up total payouts

              element.transactions.forEach((txn: any) => {
                if (txn.currency === 'GBX') { txn.currency = 'GBP'; txn.amount = txn.amount / 100; }
                else if (txn.currency === 'USX') { txn.currency = 'USD'; txn.amount = txn.amount / 100; }
                else if (txn.currency === 'EUX') { txn.currency = 'EUR'; txn.amount = txn.amount / 100; }
              });
            });

            // Merge output into one variable here (in case there's more than 25 payments within date range)
            this.fellohTxnsDummyData = this.fellohTxnsDummyData.concat(filteredList);

            // In case there's more payments than 25, increase add another page and re-do the method
            if (disbursementRes.meta.count > (this.pageNumber + 1) * this.pageSize) {
              this.pageNumber = this.pageNumber + 1;
              this.loadPayoutTransactions(); // Call yourself once again
            } else {
              // Assign transaction list and paginator to the data table variable
              this.fellohData.data = this.fellohTxnsDummyData;
              this.paymentNumber = disbursementRes.meta.count;
              this.fellohData.paginator = this.paginatorPayout;
              this.feedChartWithFelloh().then(res => {
                this.pageLoaded = true;
                resolve('');
              });
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E1801F)', error, disbursementRequest);
            resolve('');
          });
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E1802F)', error, '');
          resolve('');
        });
    });
  }

  feedChartWithFelloh(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Convert date range using 'moment' function
      const fromDate = moment(this.fromDate);
      const toDate = moment(this.toDate);

      // Empty array lists for filtered payments
      let filtered: any = [];
      // Empty array lists for series objects
      const updateSeriesArray: any = [];
      // SinGS needs to go through each day within date range and check for the payment within it
      for (const day = moment(fromDate); day.diff(toDate, 'days') <= 0; day.add(1, 'days')) {
        // Create series object which will store x - date and y - total amount (££££)
        const seriesObject: any = { x: '', y: '' };
        seriesObject.x = day.format('YYYY-MM-DD');

        // Filter out/in temporary references from transaction list and assign results into variables below
        filtered = this.fellohData.data.filter((txn: any) => txn.created_at.includes(day.format('YYYY-MM-DD')));

        if (filtered.length > 0) {
          // Add up all of the payments' values together and assign it to series y variable
          seriesObject.y = filtered.reduce((sum: any, current: any) => sum + current.amount, 0).toFixed(2);
        } else {
          seriesObject.y = 0;
        }

        // Add series object into array list
        updateSeriesArray.push(seriesObject);
      }
      // Update chart series with series objects created earlier for temp/non-temp references
      this.chartOptions.series = [{ name: 'Completed', data: updateSeriesArray } ];
      resolve('');
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Payments Out Component - feedChartWithFelloh() failed');
    });
  }

  showTestDataToggle(): void {
    // Toggle funciton which will show/hide test data
    if (this.testView) {
      this.fellohData.data = [{
        id: 'abcde12345',
        bank_reference: 'T0001-1001220001001',
        receiving_account: {
          name: 'Test Client Account'
        },
        connectedAccountId: 'abcd1234-ab12-ab12-ab12-233f650cca',
        amount: 315.85,
        transactions: [
          {
            booking: {
              booking_reference: 'TEST-002125'
            },
            payment_link: {
              id: '63fs3f6b-fd22-bg44-ss11-7dsas528'
            },
            amount: 115.85,
            completed_at: '2022-01-09T20:32:05.526Z',
            customer: { customer_name: 'John Smith' }
          },
          {
            booking: {
              booking_reference: 'TEST-112154'
            },
            payment_link: {
              id: '5ss22d5c-cff7-4s53-85h4-86b56faaaaac8'
            },
            amount: 200,
            completed_at: '2022-01-09T20:34:43.300Z',
            customer: { customer_name: 'Jasmine Patel' }
          },
        ],
        created_at: '2022-01-10T15:01:36.625',
        detailRow: true
      },
      {
        id: 'abcde12345',
        bank_reference: 'T0001-1101220001001',
        receiving_account: {
          name: 'Test Client Account'
        },
        connectedAccountId: 'ab224-ab12-ab12-ab12-28sda4f650cca',
        amount: 678.85,
        transactions: [
          {
            booking: {
              booking_reference: 'TEST-22525'
            },
            payment_link: {
              id: '63fs3f6b-fd22-bg44-ss11-76ddadsd28'
            },
            amount: 678.85,
            completed_at: '2022-01-10T20:32:05.526Z',
            customer: { customer_name: 'Max Ramirez' }
          },
        ],
        created_at: '2022-01-11T15:01:36.625',
        detailRow: true
      }];
      this.feedChartWithFelloh().then(res => {
      });
    } else {
      this.pageLoaded = false;
      this.prepareForFellohTxns().then(res => {
      });
    }
  }

  setStartingDates(): void {
    this.haveAccess = true;
    // Calculate today's date for yyyy-mm-dd format
    const today: any = new Date(); // today.setDate(today.getDate() - 91); // Remove comment when testing..
    const twoWeeksEarlier = new Date(); twoWeeksEarlier.setDate(twoWeeksEarlier.getDate() - 10); // Change to 95 when testing..
    // Assign dates to the global dates which will be changed later on by the user
    this.toDate = this.constants.convertDateNotMoment(today);
    this.fromDate = this.constants.convertDateNotMoment(twoWeeksEarlier);
  }

  changeDate(dateType: any, date: any): void {
    // Translate for Felloh-friendly date and reload Felloh data
    if (dateType === 'fromDate') {
      if (date.value != null) {
        this.fromDate = this.constants.convertDateMoment(date.value);
      }

      if (this.toDate._i !== undefined) {
        this.toDate = this.constants.convertDateMoment(this.toDate);
      }
    } else if (dateType === 'toDate') {
      if (date.value != null) {
        this.toDate = this.constants.convertDateMoment(date.value);

        if (this.fromDate._i !== undefined) {
          this.fromDate = this.constants.convertDateMoment(this.fromDate);
        }
      }
    }
    this.adminJustOpened = false; // Changing dates will disable the justOpened admin case
    // Below we're checking the number of the days the request is made for
    // We don't to allow huge requests (e.g. a year worth of data) due to server limits
    const diffInDays = Math.ceil((new Date(this.toDate).getTime() - new Date(this.fromDate).getTime()) / (1000 * 60 * 60 * 24));

    // If the number is above the treshold, we'll set the other date type automatically
    // The new date will be !dateType (othertype) +/- threshold
    if (diffInDays > 30 && dateType === 'fromDate') {
      const toDate = new Date(this.fromDate); toDate.setDate(toDate.getDate() + 30);
      this.toDate = this.constants.convertDateNotMoment(toDate);
    } else if (diffInDays > 30 && dateType === 'toDate') {
      const fromDate = new Date(this.toDate); fromDate.setDate(fromDate.getDate() - 30);
      this.fromDate = this.constants.convertDateNotMoment(fromDate);
    }

    // Make sure none of these two are null (non-date value entered in the field)
    if (this.toDate != null && this.fromDate != null) {
      // In case the user writes the date in yyyy-mm-dd format, below will catch it and prevents from reloading transactions
      if (moment(this.fromDate, 'YYYY-MM-DD', true).isValid() && moment(this.toDate, 'YYYY-MM-DD', true).isValid()) {
        this.pageLoaded = false;
        this.prepareForFellohTxns();
      } else {
        this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
        this.setStartingDates();
      }
    } else {
      this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
      this.setStartingDates();
    }
  }

  changeBranch(branch: any, loadTxns: any): void {
    // Function called whenver SinGS Admin/Staff changes the branch from select list
    this.pageLoaded = false;
    this.selectedBranch = branch;
    this.selectedFellohAccount = branch?.fellohConfig[0];    
    // Reload felloh if loadTxns is set to 'yes'. Otherwise - we'll need user to do it themselves (saves Felloh money)
    if (loadTxns === 'yes') {
      this.adminJustOpened = false; // Second (or further) reload removes 'justOpened' which will remove unnecessary pre-load
      this.prepareForFellohTxns();
    } else { this.pageLoaded = true; this.adminJustOpened = true; }
  }

  exportToExcel(): void {
    const exportMe: any = [];
    let oneLine: any = {};

    this.fellohData.data.forEach((data: any) => {
      oneLine = {};
      oneLine.batchId = data.bank_reference;
      oneLine.amount = data.amount;
      oneLine.beneficiaryTransactionId = data.receiving_account.name;
      oneLine.date = data.created_at;
      oneLine.customerName = '';
      exportMe.push(oneLine);

      data.transactions.forEach((payment: any) => {
        oneLine = {};
        oneLine.batchId = payment.booking.booking_reference;
        oneLine.amount = payment.amount;
        oneLine.beneficiaryTransactionId = payment.id;
        oneLine.date = payment.completed_at;
        oneLine.customerName = payment.customer.customer_name;
        exportMe.push(oneLine);
      });
      oneLine = {};
      exportMe.push(oneLine);
    });
    this.constants.exportAsExcelFile(exportMe, 'fellohPayoutList');
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    this.expandedElement = null;
    if (view === 'overwiev') {
      this.overView = true;
      this.transactionView = false;
    } else if (view === 'transaction') {
      this.overView = false;
      this.transactionView = true;
    } else if (view === 'reloadView' && !this.testView) {
      this.pageLoaded = false;
      this.loadPayoutTransactions();
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  filterSelect(): void {
    this.branchListFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.branchListData.length; i++) {
      const option = this.branchListData[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.branchListFiltered.push(option);
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // Check if the error comes from Felloh and if we can display it to the user
      // If we can then let's display it rather than give them weird code..
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        failureMessage = error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error.hasOwnProperty('message')) {
        failureMessage = error.error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors.length > 0) {
        failureMessage = error.error.errors[0].message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      }
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }

  showHelp(): void {
    (window as any).Beacon('toggle'); // Open Help Scout
  }
}
