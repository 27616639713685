import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SupplierService } from '../../services/supplier.service';
import { UserService } from '../../services/user.service';
import { GlobalConstants } from '../../common/global-constants';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from './../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-supplement-list',
  templateUrl: './supplement-list.component.html',
  styleUrls: ['./supplement-list.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class SupplementListComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  // Table data and table columns below
  supplementData: any = new MatTableDataSource<any>();
  displayedColumns = ['supplementName', 'autoPricing', 'hidden', 'expand'];

  // Boolean deciding whether user has access or not
  userType = '';
  userEmail = '';
  pageLoaded = false;
  haveAccess = false;
  supplementListDiv = true;
  createSupplementDiv = false;

  // Custom mat expansion variables
  // TBD coming soon I think..

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  selectedCompany: any = 'tta';
  autoPricing = false; // By default user will see auto pricing set to 'No' - why not?
  supplementCosts: any = { // Holds new Supplement values - sets back to zero when autoPricing set to false
    costPerPax: 0, costPerBook: 0, costPerGross: 0, costPerMember: 0,
    netPercOfGross: 0, commissionPercOfGross: 100, taxPercOfGross: 0, discountPercOfGross: 0
  };
  chosenSupplement: any = {}; // Holds info about selected Supplement (clicked mat-table row)

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(private router: Router, private userService: UserService,
              private supplierService: SupplierService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType & userCompany and load the page
      this.userType = Session.mySession.getUser().userType;
      this.userEmail = Session.mySession.getUser().email;
      this.loadPage();
    }
  }

  loadPage(): void {
    if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff') {
      this.haveAccess = true; // Make the page available to the user
      this.loadSupplements();
    } else {
      this.pageLoaded = true;
    }
  }

  loadSupplements(): void {
    const request = { token: Session.mySession.getUser().token, company: this.selectedCompany, operation: '' };
    // Create request variable and use the global company variable to determine operaiton (one to one relation it is)
    if (this.selectedCompany === 'ttng') { request.operation = 'retail'; }
    else if (this.selectedCompany === 'gtg') { request.operation = 'member'; }
    else if (this.selectedCompany === 'tta') { request.operation = 'tta'; }
    this.pageLoaded = false;
    this.supplierService.getSupplmPriceList(request).then((supplements: any) => {
      if (supplements.status === 'OK') {
        supplements.suppriceList.forEach((element: any) => element.detailRow = true); // Make the data 'expandable'
        const sorted = supplements.suppriceList.sort((a: any, b: any) => (a.supName > b.supName) ? 1 : -1); // Sort data by name a->z
        this.supplementData.data = sorted; // Assign sorted data to data variable
        this.supplementData.paginator = this.paginator; // Assign table paginator
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', supplements.status, '', ''); // Print out error message to the user
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1401S)', error, request);
    });
  }

  getSupplmPrice(supplement: any): void {
    let today: any = new Date(); // Get today's date
    today = this.constants.convertDateNotMoment(today); // Convert the date so Ruby accepts it
    supplement.applicnDate = today; // Assign today's date to the supplement request
    supplement.token = Session.mySession.getUser().token; // Assign token to the supplement request
    this.pageLoaded = false; // Used so only the row does 'flicker' not the whole page

    this.supplierService.getSupplmPrice(supplement).then((suppPrice: any) => {

      if (suppPrice.status === 'OK') {
        this.chosenSupplement = suppPrice.supplm; // Assign result to the object
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', suppPrice.status, '', ''); // Print error message to the user
        this.chosenSupplement = {}; // Reset object back to empty
      }

    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1402S)', error, supplement);
    });
  }

  createSupplementPricing(form: NgForm): void {
    const format = /[`!@#$%^*_+\=\[\]{}"\\|,<>?~]/; // These characters are not allowed in the fields..
    if (format.test(form.value.supName)) {
      this.sendMessageToDialog('', 'Please remove invalid characters from Supplement Name', '', ''); // Please do!
    } else if (form.valid) {
      // Check if the form is valid first, all selects selected etc
      this.pageLoaded = false;
      // Create request variable and use the global company variable to determine operaiton (one to one relation it is)
      if (this.selectedCompany === 'ttng') { form.value.operation = 'retail'; }
      else if (this.selectedCompany === 'gtg') { form.value.operation = 'member'; }
      else if (this.selectedCompany === 'tta') { form.value.operation = 'tta'; }
      form.value.applicnDate = this.constants.convertDateMoment(form.value.activeDate); // Assign application date from the field (needed for calculations in Ruby)
      form.value.token = Session.mySession.get('user').token; // Assign token to the form

      this.supplierService.createSupplmPrice(form.value).then((result: any) => {
        if (result.status === 'OK') {
          this.switchView('supplementList'); // Switch the view to see the supplement list
          this.sendMessageToDialog('Supplement has been created!', '', '', ''); // Display success message
        } else {
          this.sendMessageToDialog('', 'Create supplement failed (' + result.status + ')', '', ''); // Default error message
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1403S)', error, form.value); // Display error message
      });
    } else {
      this.sendMessageToDialog('', 'Please fill in all required fields', '', ''); // Please do!
    }
  }

  unHideSupplmPrice(): void {
    // If the Supplement is hidden, then display it
    // Otherwise - hide it. This will work on every single activeDates
    if (this.chosenSupplement.hidden === 'yes') { this.chosenSupplement.isHidden = 'no'; }
    else if (this.chosenSupplement.hidden === 'no') { this.chosenSupplement.isHidden = 'yes'; }
    this.chosenSupplement.token = Session.mySession.get('user').token; // Append token

    this.pageLoaded = false;
    this.supplierService.unHideSupplmPrice(this.chosenSupplement).then((result: any) => {
      if (result.status === 'OK') {
        this.chosenSupplement = {}; // Clear chosen supplement data
        this.loadSupplements(); // Reload supplements
        this.sendMessageToDialog('Supplement has been updated', '', '', ''); // Error message
      } else {
        this.sendMessageToDialog('', 'Failed to update Supplement (' + result.status + ')', '', ''); // Error message
      }
    });
  }

  removeSupplement(): void {
    // Ask twice if user wants to remove supplement here..
    if (confirm('Are you sure to delete ' + this.chosenSupplement.supName)) {
      if (confirm('100% sure you want to delete ' + this.chosenSupplement.supName)) {
        this.chosenSupplement.applicnDate = this.chosenSupplement.activeDate; // We're removing the supplement with selected date, not ALL supplements
        this.chosenSupplement.token = Session.mySession.get('user').token; // Token, token...
        this.pageLoaded = false;
        this.supplierService.deleteSupplmPrice(this.chosenSupplement).then((result: any) => {
          if (result.status === 'OK') {
            this.chosenSupplement = {}; // Clear chosen supplement data
            this.loadSupplements(); // Reload supplements
            this.sendMessageToDialog('Supplement has been removed', '', '', ''); // Display success message
          } else {
            this.sendMessageToDialog('', 'Failed to remove Supplement pricing (' + result.status + ')', '', ''); // Error message
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1404S)', error, this.chosenSupplement); // Error message
        });
      }
    }
  }

  autoPriceChange(): void {
    if (this.autoPricing === false) {
      // Resets new supplement costs displayed in the UI below
      this.supplementCosts = {
        costPerPax: 0, costPerBook: 0, costPerGross: 0, costPerMember: 0,
        netPercOfGross: 0, commissionPercOfGross: 100, taxPercOfGross: 0, discountPercOfGross: 0
      };
    }
  }

  changeNetPerc(event: any): void {
    this.supplementCosts.netPercOfGross = event;
    this.supplementCosts.commissionPercOfGross = 100 - this.supplementCosts.netPercOfGross - this.supplementCosts.taxPercOfGross - this.supplementCosts.discountPercOfGross;
  }

  changeVatPerc(event: any): void {
    this.supplementCosts.taxPercOfGross = event;
    this.supplementCosts.commissionPercOfGross = 100 - this.supplementCosts.netPercOfGross - this.supplementCosts.taxPercOfGross - this.supplementCosts.discountPercOfGross;
  }

  changeDiscountPerc(event: any): void {
    this.supplementCosts.discountPercOfGross = event;
    this.supplementCosts.commissionPercOfGross = 100 - this.supplementCosts.netPercOfGross - this.supplementCosts.taxPercOfGross - this.supplementCosts.discountPercOfGross;
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    if (view === 'supplementList') {
      this.supplementListDiv = true;
      this.createSupplementDiv = false;
      this.expandedElement = false; // No row will be automatically expanded
      this.loadSupplements();
    } else if (view === 'createSupplement') {
      this.supplementListDiv = false;
      this.createSupplementDiv = true;
      this.autoPricing = false; // Auto-pricing auto-disabled
      this.autoPriceChange(); // New supplement costs auto-zeroed
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}
