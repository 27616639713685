<!-- Loading -->
<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <!-- Access Check -->
  <div *ngIf="!haveAccess && pageLoaded">
    <div class="errorMessage">
      <h2 style="text-align: center; margin-top: 2.5%;">We're sorry but You don't have access to this page.</h2>
    </div>
  </div>
  <!-- TAPS Interface -->
  <div *ngIf="haveAccess">

    <!-- SinGS Header Bar -->
    <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
      <div *ngIf="innerWidth > 1024" style="width: 100%;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button class="navButtons" (click)="showContactView()" mat-button>
            <mat-icon>phone</mat-icon> Contact Us
          </button>
          <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
            <mat-icon class="helpIcon">help</mat-icon> Help
          </button>
        </div>
      </div>
      <div style="width: 100%" *ngIf="innerWidth < 1025">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button class="navButtons" (click)="showContactView()" mat-button>
            <mat-icon>contact_support</mat-icon> 
          </button>
          <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
            <mat-icon class="helpIcon">help</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>

    <!-- TAPS Header Bar -->    
    <h1 fxLayout="row" fxLayoutAlign="start center" style="margin:10px !important;">
            <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>{{userName}} 
            <div *ngIf="loggedInUserType == 'Supplier'">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>{{userSupplierMisttaCode}} - {{userSupplierName}}  
            </div>
            <div *ngIf="loggedInUserType != 'Supplier'">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>{{userCompany}}
            </div>
            <div *ngIf="loggedInUserType != 'Supplier'">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>{{userFullCompanyName}}
            </div>
    </h1>          
    <mat-divider></mat-divider>

    <!-- Main TAPS Component -->  
    <mat-sidenav-container>
            <mat-sidenav #sidenav mode="side" opened="true">
                <div class="taps-sidenav-header">
                  <button (click)="switchView('homePage')" class="mainMenuBtn">
                    {{loggedInUserType}} Menu
                  </button>
                </div>
                <mat-nav-list>
                  <h3 *ngIf="loggedInUserType == 'Admin'">
                        <mat-list-item (click)="switchView('agentManagementPage')">
                          <span class="taps-navitem">Agent Management</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('supplierManagementPage')">
                          <span class="taps-navitem">Supplier Management</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('supplierStatusPage')">
                          <span class="taps-navitem">Supplier Status</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <div class="taps-navdivider"></div>
                        <mat-list-item (click)="switchView('unmatchedTapsPayments')">
                          <span class="taps-navitem">Unmatched TAPS Payments</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item [disabled]="uploadFileBlocked==true" (click)="switchView('collectFtpPage')">
                          <span *ngIf="uploadFileBlocked==false" class="taps-navitem">Collect FTP Uploads</span>
                          <span *ngIf="uploadFileBlocked==true" class="taps-navitem" style="color: #4D5FD1;">Uploading transactions ...</span>
                          <mat-icon *ngIf="uploadFileBlocked==false" mat-list-icon>chevron_right</mat-icon>
                          <mat-icon *ngIf="uploadFileBlocked==true" mat-list-icon>synch</mat-icon>
                        </mat-list-item>
                        <mat-list-item [disabled]="uploadFileBlocked==true" (click)="switchView('lateAdjustmentsPage')">
                          <span *ngIf="uploadFileBlocked==false" class="taps-navitem">Late Adjustments</span>
                          <span *ngIf="uploadFileBlocked==true" class="taps-navitem" style="color: #4D5FD1;">Uploading transactions ...</span>
                          <mat-icon *ngIf="uploadFileBlocked==false" mat-list-icon>chevron_right</mat-icon>
                          <mat-icon *ngIf="uploadFileBlocked==true" mat-list-icon>synch</mat-icon>
                        </mat-list-item>
                        <mat-list-item [disabled]="transactionApprovalBlocked==true" (click)="switchView('exportOrImportTransactionsAdminPage')">
                          <span *ngIf="transactionApprovalBlocked==false" class="taps-navitem">Export or Import Transactions</span>
                          <span *ngIf="transactionApprovalBlocked==true" class="taps-navitem" style="color: #4D5FD1;">Updating transactions ...</span>
                          <mat-icon *ngIf="transactionApprovalBlocked==false" mat-list-icon>chevron_right</mat-icon>
                          <mat-icon *ngIf="transactionApprovalBlocked==true" mat-list-icon style="color: #4D5FD1;">sync</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('sendAgentRemindersPage')">
                          <span class="taps-navitem">Send Agent Reminders</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('deleteUploadPage')">
                          <span class="taps-navitem">Delete Upload</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item [disabled]="commitAllInProgress==true" (click)="switchView('commitUploadPage')">
                          <span *ngIf="commitAllInProgress==false" class="taps-navitem">Commit Upload</span>
                          <span *ngIf="commitAllInProgress==true" class="taps-navitem" style="color: #4D5FD1;">Committing uploads ...</span>
                          <mat-icon *ngIf="commitAllInProgress==false" mat-list-icon>chevron_right</mat-icon>
                          <mat-icon *ngIf="commitAllInProgress==true" mat-list-icon style="color: #4D5FD1;">sync</mat-icon>
                        </mat-list-item>
                        <div class="taps-navdivider"></div>
                        <mat-list-item (click)="switchView('collectionFromAgentsPage')">
                          <span class="taps-navitem">Collection from Agents</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('deliveryToSuppliersPage')">
                          <span class="taps-navitem">Delivery to Suppliers</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('transactionsInvoicingPage')">
                          <span class="taps-navitem">Transactions Invoicing</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <div class="taps-navdivider"></div>
                        <mat-list-item (click)="switchView('reportingPage')">
                          <span class="taps-navitem">Reporting</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('analysisReportingPage')">
                          <span class="taps-navitem">Analysis Reporting</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('bacsReportingPage')">
                          <span class="taps-navitem">BACS Reporting</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('uploadHistoryAdmin')">
                          <span class="taps-navitem">Upload History</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <div *ngIf="userName == 'Taps Admin'">
                          <div class="taps-navdivider"></div>
                          <mat-list-item (click)="switchView('deleteS3UploadsPage')" *ngIf="userName == 'Taps Admin' && loggedInUserType == 'Admin'">
                            <span class="taps-navitem">Delete TAPS Upload</span>
                            <mat-icon mat-list-icon>chevron_right</mat-icon>
                          </mat-list-item>
                        </div>
                        <!-- 
                        <div class="taps-navdivider"></div>
                        <mat-list-item (click)="switchView('switchToDevAndLivePage')">
                          <span class="taps-navitem">Switch between Dev/Live</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        -->
                  </h3>
                  <h3 *ngIf="loggedInUserType == 'Supplier'">
                        <mat-list-item [disabled]="uploadFileBlocked==true"  (click)="switchView('uploadFilePage')">
                          <span *ngIf="uploadFileBlocked==false" class="taps-navitem">Upload File</span>
                          <span *ngIf="uploadFileBlocked==true" class="taps-navitem" style="color: #4D5FD1;">Uploading transactions ...</span>
                          <mat-icon *ngIf="uploadFileBlocked==false" mat-list-icon>chevron_right</mat-icon>
                          <mat-icon *ngIf="uploadFileBlocked==true" mat-list-icon>synch</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('uploadHistoryPage')">
                          <span class="taps-navitem">Upload History</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('pullDownFilePage')">
                          <span class="taps-navitem">Pull Down File</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <div class="taps-navdivider"></div>
                        <mat-list-item (click)="switchView('agentsPage')">
                          <span class="taps-navitem">Agents</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('reportingOperatorPage')">
                          <span class="taps-navitem">Reporting</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                  </h3>
                  <h3 *ngIf="loggedInUserType == 'Member'">
                        <mat-list-item [disabled]="transactionApprovalBlocked==true" (click)="switchView('transactionApprovalPage')">
                          <span *ngIf="transactionApprovalBlocked==false" class="taps-navitem">Transaction Approval</span>
                          <span *ngIf="transactionApprovalBlocked==true" class="taps-navitem" style="color: #4D5FD1;">Updating transactions ...</span>
                          <mat-icon *ngIf="transactionApprovalBlocked==false" mat-list-icon>chevron_right</mat-icon>
                          <mat-icon *ngIf="transactionApprovalBlocked==true" mat-list-icon style="color: #4D5FD1;">sync</mat-icon>
                        </mat-list-item>
                        <mat-list-item [disabled]="transactionApprovalBlocked==true" (click)="switchView('exportOrImportTransactionsMemberPage')">
                          <span *ngIf="transactionApprovalBlocked==false" class="taps-navitem">Export or Import Transactions</span>
                          <span *ngIf="transactionApprovalBlocked==true" class="taps-navitem" style="color: #4D5FD1;">Updating transactions ...</span>
                          <mat-icon *ngIf="transactionApprovalBlocked==false" mat-list-icon>chevron_right</mat-icon>
                          <mat-icon *ngIf="transactionApprovalBlocked==true" mat-list-icon style="color: #4D5FD1;">sync</mat-icon>
                        </mat-list-item>
                        <div class="taps-navdivider"></div>
                        <mat-list-item (click)="switchView('suppliersPage')">
                          <span class="taps-navitem">Suppliers</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('reportingMemberPage')">
                          <span class="taps-navitem">Reporting</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <mat-list-item (click)="switchView('analysisReportingMemberPage')">
                          <span class="taps-navitem">Analysis Reporting</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        <!-- 
                        <div class="taps-navdivider"></div>
                        <mat-list-item (click)="switchView('switchToDevAndLivePage')">
                          <span class="taps-navitem">Switch between Dev/Live</span>
                          <mat-icon mat-list-icon>chevron_right</mat-icon>
                        </mat-list-item>
                        -->
                  </h3>
                </mat-nav-list>
            </mat-sidenav>  
            <!-- Home Page -->
            <div *ngIf="listOfPages[0].value" class="taps-sidenav-content">
                  <h1>Welcome</h1>
                  <div class="taps-insidenav-content"> 
                    <h2 style="white-space:pre-wrap"><b>{{userHomeAddress}}</b></h2><br>
                    <h2><b>{{userHomeEmail}}</b></h2>
                    <h2><b>{{userHomeTelephone}}</b></h2><br>
                    <h3>If any of the above details are incorrect, please <button style="color: #717FDA; background:none; border: 0px; padding: 0px; font-weight: bold;" (click)="showContactView()"> Contact Us </button></h3>
                    <div *ngIf="onBookingPlatform == true && loggedInUserType == 'Member'">
                      <br>
                      <h3>To read about the Smart features,</h3>
                      <div>
                        <a class="tapsUserManualLink" href="https://docs.google.com/document/d/12QlUsfUD79QGCR1JgGwqO0h_4iyyB55yuJ8FLvghG2E/edit?usp=sharing" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Smart TAPS Guide </a>
                      </div>
                    </div>
                  </div>                
            </div>
            <!-- Agent Management Page -->
            <div *ngIf="listOfPages[1].value" class="taps-sidenav-content">
                  <h1>Agent Management
                    <button [disabled]="adminAgentListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="adminExportAgentManagement()" >
                      Export to Excel
                    </button>
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="downloadAllTapsMembersData()" >
                      Download All Members TAPS Data
                    </button>
                  </h1>
                  <div class="taps-insidenav-content"> 
                    <h3 style="margin-bottom: 0px;">
                      <mat-form-field style="width: 300px; max-width: unset; font-weight: bold;">
                        <mat-label>Find an Agent</mat-label>
                        <input matInput [(ngModel)]="agentFilter" autocomplete="nope">
                      </mat-form-field>
                      <button mat-raised-button style="margin-left: 20px;" color="primary" (click)="filterAgentsList()">Search</button>
                      <mat-checkbox (change)="activeAgents()" [(ngModel)]='listActiveAgents'  style="padding-left: 10px; margin-bottom: 10px;">Active Agents</mat-checkbox>
                      <span style="margin-left: 10px;">({{adminAgentListData.data.length}})</span>
                    </h3>
                    <div name="Agent Management List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="agentListName">
                            <mat-header-cell *matHeaderCellDef (click)="sortAdminAgentByName()" style="cursor:pointer"> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentListTapsIdenti"> 
                            <mat-header-cell *matHeaderCellDef (click)="sortAdminAgentByTradeCode()" style="cursor:pointer"> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentListTaps">
                            <mat-header-cell *matHeaderCellDef> TAPS </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentListAddress">
                            <mat-header-cell *matHeaderCellDef style="padding-right: 0px !important;"> Address </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="adminAgentListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8"  style="height: fit-content;">
                          <table mat-table [dataSource]="adminAgentListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="agentListName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px; color:#4D5FD1 !important; cursor:pointer;" *matCellDef="let agent" (click)="viewAgentPage(agent)">{{agent.branchName}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentListTapsIdenti">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let agent"> {{agent.tradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentListTaps">
                              <mat-header-cell *matHeaderCellDef> TAPS </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let agent"> 
                                {{agent.isTapsReg.toUpperCase()}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentListAddress">
                              <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px 15px 10px 10px;" *matCellDef="let agent"> 
                                {{agent.address1}}, {{agent.address2}}, {{agent.postcode}}, {{agent.country}}
                               </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: adminAgentListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>
            </div>
            <!-- Supplier Management Page -->
            <div *ngIf="listOfPages[2].value" class="taps-sidenav-content">
                  <h1>Supplier Management
                    <button [disabled]="backUpSupplierListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="adminExportSupplierManagement()" >
                      Export to Excel
                    </button>
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="downloadAllTapsSuppliersData()" >
                      Download All Suppliers TAPS Data
                    </button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 0px;">
                      <mat-form-field style="width: 300px; max-width: unset; font-weight: bold;">
                        <mat-label>Find a Supplier</mat-label>
                        <input matInput [(ngModel)]="supplierFilter" autocomplete="nope">
                      </mat-form-field>
                      <button mat-raised-button style="margin-left: 20px;" color="primary" (click)="filterSuppliersList()">Search</button>
                      <mat-checkbox (change)="activeSuppliers()" [(ngModel)]='listActiveSuppliers' style="padding-left: 10px; margin-bottom: 10px;">Active Suppliers</mat-checkbox>
                      <span style="margin-left: 10px;">({{backUpSupplierListData.data.length}})</span>
                    </h3>
                    <div name="Supplier Management List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="supplierName">
                            <mat-header-cell *matHeaderCellDef (click)="sortSupplierListByName('admin')" style="cursor:pointer"> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierTapsIdenti">
                            <mat-header-cell *matHeaderCellDef (click)="sortSupplierListByTradeCode('admin')" style="cursor:pointer"> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierTelephone">
                            <mat-header-cell *matHeaderCellDef> TAPS </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierAddress">
                            <mat-header-cell *matHeaderCellDef style="padding-right: 0px !important;"> Address </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="suppliersListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8"  style="height: fit-content;">
                          <table mat-table [dataSource]="backUpSupplierListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="supplierName">
                              <mat-cell style="font-weight:bold; padding: 20px; color:#4D5FD1 !important; cursor:pointer;" *matCellDef="let supplier" (click)="viewSupplierPage(supplier)">{{supplier.supplierNameM}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierTapsIdenti">
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.misttaCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierTelephone">
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> 
                                <span *ngIf="supplier.misttaCode != '' && supplier.misttaCode != null && supplier.isTapsReg == 'yes'">Yes</span>
                                <span *ngIf="supplier.misttaCode == '' || supplier.isTapsReg == 'no'">No</span>
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierAddress">
                              <mat-cell style="font-weight:bold; padding: 10px 15px 10px 10px;"*matCellDef="let supplier"> 
                                {{supplier.addressLine1}}, {{supplier.postCode}}, {{supplier.country}} 
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: suppliersListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>                
            </div>
            <!-- Supplier Status Page -->
            <div *ngIf="listOfPages[3].value" class="taps-sidenav-content">
                  <h1>
                    <button *ngIf="selectedSupplierForStatusReport!=''" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('supplierPage')">
                      <mat-icon>arrow_back</mat-icon>
                    </button> 
                    Supplier Status <span *ngIf="selectedSupplierForStatusReport!=''">for {{viewSupplierName}}</span>
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="setupSupplierStatusReport()" >Find</button>
                  </h1>
                  <div class="taps-insidenav-content">
                  <h3>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="12">
                        Upload Period	
                      </div>
                      <div fxFlex="30">
                        <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                          <mat-select placeholder="Period" style="vertical-align: middle;" [(ngModel)]="reportingDatePeriod"  (selectionChange)="setupDatesForReportingPeriod()">
                            <mat-option *ngFor="let period of adminReportingDatePeriods" [value]="period">
                              {{period.displayRow}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </h3>
                  <h3>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="12">
                        Date Range<span style="color:#4D5FD1">*</span> 
                      </div>
                      <div style="display: block; max-width: fit-content !important;">
                        <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                          <mat-label>From</mat-label>
                          <input matInput [matDatepicker]="fromPicker" [(ngModel)]="reportingDateFrom">
                          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                          <mat-datepicker #fromPicker ></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                          <mat-label>To</mat-label>
                          <input matInput [matDatepicker]="toPicker" [(ngModel)]="reportingDateTo">
                          <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                          <mat-datepicker #toPicker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom: 0px;">
                    <p>{{adminSupplierStatusListData.data.length}} Record(s)</p>
                  </h3>
                  <div name="Supplier Status List">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="supplierListName">
                          <mat-header-cell *matHeaderCellDef (click)="sortSupplierStatusReportByName()" style="cursor:pointer"> Name </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="supplierListTapsIdenti">
                          <mat-header-cell *matHeaderCellDef (click)="sortSupplierStatusReportByTradeCode()" style="cursor:pointer"> TAPs Identi.</mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="failed">
                          <mat-header-cell *matHeaderCellDef> Failed </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="successful">
                          <mat-header-cell *matHeaderCellDef> Successful </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastSuccessfulDate">
                          <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Last Successful Date </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastSuccessfulValue">
                          <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Last Successful Value </mat-header-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="adminSupplierStatusListColumns"></mat-header-row>
                      </table>
                      </div>
                      <div class="example-container mat-elevation-z8" style="height: fit-content;">
                        <table mat-table [dataSource]="adminSupplierStatusListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                          <ng-container matColumnDef="supplierListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let supplier">{{supplier.supplierName}}</mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.supplierTradeCode}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="failed">
                            <mat-header-cell *matHeaderCellDef> Failed </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.failed}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="successful">
                            <mat-header-cell *matHeaderCellDef> Successful </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.successful}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="lastSuccessfulDate">
                            <mat-header-cell *matHeaderCellDef>Last Successful Date </mat-header-cell>
                            <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let supplier"> 
                              <div *ngIf="supplier.lastSuccessfulDate">
                                <div *ngFor="let tempLastSuccessfulDate of supplier.lastSuccessfulDate.split(','); let last = last">
                                  {{ tempLastSuccessfulDate }}<br *ngIf="!last">
                                </div>
                              </div>
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="lastSuccessfulValue">
                            <mat-header-cell *matHeaderCellDef> Last Successful Value </mat-header-cell>
                            <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let supplier"> 
                              <div *ngIf="supplier.lastSuccessfulValue">
                                <div *ngFor="let tempLastSuccessfulValue of supplier.lastSuccessfulValue.split(','); let last = last">
                                  {{ tempLastSuccessfulValue | number : '1.2-2' }}<br *ngIf="!last">
                                </div>
                              </div>
                            </mat-cell>
                          </ng-container>
                          <mat-row *matRowDef="let row; columns: adminSupplierStatusListColumns;"></mat-row>
                        </table>
                      </div>
                  </div>
                  </div>
            </div>
            <!-- Unmatched TAPS Payments Page -->
            <div *ngIf="listOfPages[4].value" class="taps-sidenav-content">
                <h1>
                  Unmatched TAPS Payments
                  <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="getUnMatchedTapsPayments()">
                    Find
                  </button>
                  <button [disabled]="unmatchedTapsPaymentsListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="adminExportUnMatchedReport()" >
                    Export to Excel
                  </button>
                </h1>
                <div class="taps-insidenav-content">
                 <h3 style="margin-top:0px">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20">
                        Agent	
                      </div>
                      <div>
                        <mat-form-field style="width: 500px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select ngModel name="Agent" [(ngModel)]="unmatchedAgent" placeholder="Agent">
                              <mat-form-field style="max-width: unset; width: 520px;" appearance="fill">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminAgentsListString" (keyup)="filterAdminAgentListSelect()">
                              </mat-form-field>
                              <mat-option value="All" (click)="filterAdminAgentsListString = ''; filterAdminAgentListSelect();">
                                All
                              </mat-option>
                              <mat-option *ngFor="let agent of filteredAdminAgentsListData.data" [value]="agent.tradeCode" (click)="filterAdminAgentsListString = ''; filterAdminAgentListSelect();">
                                {{agent.branchName + " (" + agent.tradeCode + " - " + agent.isTapsReg.toUpperCase() +" TAPS)"}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </h3>
                  <h3>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20" style="margin-bottom:10px;">
                        Collection Date
                      </div>
                      <div style="display: block; max-width: fit-content !important;">
                        <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="fromPicker" [(ngModel)]="unmatchedCollectionDate">
                          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                          <mat-datepicker #fromPicker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom: 0px; margin-top: 26px;">
                    <p>{{unmatchedTapsPaymentsListData.data.length}} transaction(s) were found</p>
                  </h3>
                  <div name="UnMatched TAPS Payments List">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="unMatchedTransactionID">
                          <mat-header-cell *matHeaderCellDef> Transaction<br>ID </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedCollectionDate">
                          <mat-header-cell *matHeaderCellDef> Collection<br>Date</mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedSupplierName">
                          <mat-header-cell *matHeaderCellDef> Supplier<br>Name </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedSupplierTradeCode">
                          <mat-header-cell *matHeaderCellDef> Supplier<br>TradeCode </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedAgentName">
                          <mat-header-cell *matHeaderCellDef> Agent<br>Name </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedAgentTradeCode">
                          <mat-header-cell *matHeaderCellDef> Agent<br>TradeCode </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedOperatorReference">
                          <mat-header-cell *matHeaderCellDef> Operator<br>Ref </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedClientName">
                          <mat-header-cell *matHeaderCellDef> Client<br>Name </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedDepartureDate">
                          <mat-header-cell *matHeaderCellDef> Dept<br>Date </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedAmount">
                          <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedBookingRefOther">
                          <mat-header-cell *matHeaderCellDef>  Booking Ref<br>Other </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedBookingReference">
                          <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Booking<br>Reference </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="unMatchedType">
                          <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Match<br>Type </mat-header-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="unmatchedTapsPaymentsListColumns"></mat-header-row>
                      </table>
                      </div>
                      <div class="example-container mat-elevation-z8" style="height: fit-content;">
                        <table mat-table [dataSource]="unmatchedTapsPaymentsListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                          <ng-container matColumnDef="unMatchedTransactionID">
                            <mat-header-cell *matHeaderCellDef> Transaction ID </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let record">{{record.transactionID}}</mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedCollectionDate">
                            <mat-header-cell *matHeaderCellDef> Collection Date </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.formattedCollectionDate}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedSupplierName">
                            <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.supplierName}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedSupplierTradeCode">
                            <mat-header-cell *matHeaderCellDef> Supplier TradeCode </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.supplierTradeCode}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedAgentName">
                            <mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.agentName}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedAgentTradeCode">
                            <mat-header-cell *matHeaderCellDef> Agent TradeCode </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.agentTradeCode}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedOperatorReference">
                            <mat-header-cell *matHeaderCellDef> Operator Ref </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.operatorReference}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedClientName">
                            <mat-header-cell *matHeaderCellDef> Client Name </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.clientName}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedDepartureDate">
                            <mat-header-cell *matHeaderCellDef> Dept Date </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.formattedDepartureDate}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedAmount">
                            <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record"> {{record.tranValue | number : '1.2-2'}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedBookingRefOther">
                            <mat-header-cell *matHeaderCellDef> Booking Ref Other </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let record">
                              <span *ngIf="record.bookingRefOther == null">N/A</span>
                              <span *ngIf="record.bookingRefOther != null">{{record.bookingRefOther}}</span>
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedBookingReference">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Booking Reference </mat-header-cell>
                            <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let record"> 
                              <span *ngIf="record.bookingReference == null">N/A</span>
                              <span *ngIf="record.bookingReference != null">{{record.bookingReference}}</span>
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="unMatchedType">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Match Type </mat-header-cell>
                            <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let record"> 
                              {{record.matchType}}
                            </mat-cell>
                          </ng-container>
                          <mat-row *matRowDef="let row; columns: unmatchedTapsPaymentsListColumns;"></mat-row>
                        </table>
                      </div>
                  </div>
                </div>
            </div>
            <!-- Late Adjustments Page -->
            <div *ngIf="listOfPages[5].value" class="taps-sidenav-content">
                  <h1>Late Adjustments <span *ngIf="lateAdjustmentUploadedFile == true"> (State : {{viewTransactionState}}) <h1>Please scroll down to <span style="color: #ce983a;">'Accept'</span> or <span style="color: red;">'Reject'</span> your document.</h1> </span> </h1>
                  <div class="taps-insidenav-content">
                    <div *ngIf="lateAdjustmentUploadedFile == false">
                      <h3 style="margin-bottom: 0px;">
                        <p>The file you are uploading is for Agent processing from {{uploadFromDateAdmin}} to {{uploadToDateAdmin}}.</p>
                      </h3>
                      <h3 style="margin-top: 30px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="lateAdjustmentPreviewPage($event)"  accept=".xlsx,.csv,.txt,.xls" multiple="false" />
                          <button mat-raised-button color="primary" (click)="csvInput1.click()">
                            Upload File
                          </button>
                        </div>
                      </h3>
                      <h3 style="margin-top: 30px;">
                        <p>Submit a file for processing.</p>
                        <p>Use the Browse button to locate the file you wish to upload and then click the Upload File button.</p>
                        <p style="font-weight: bolder;"><span style="color:#4D5FD1">*</span>Do not close your browser during the process - a report will be presented to you when the process is completed.</p>
                      </h3>
                    </div>
                    <div *ngIf="lateAdjustmentUploadedFile == true">
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            TAPS ID	
                          </div>
                          <div fxFlex="30">
                            {{viewAdjustmentUploadTapsId}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Supplier	
                          </div>
                          <div fxFlex="30">
                            {{viewAdjustmentUploadSupplierName}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Upload Date	
                          </div>
                          <div fxFlex="30">
                            {{viewUploadDate}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 20px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Quantity	
                          </div>
                          <div fxFlex="30">
                            {{viewNumOfTransactions}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Value	
                          </div>
                          <div fxFlex="30">
                            {{viewValue | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Adjustments	
                          </div>
                          <div fxFlex="30">
                            {{viewAdjustments | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Agent Review	
                          </div>
                          <div fxFlex="30">
                            {{viewAgentReview}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 20px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            To Pay	
                          </div>
                          <div fxFlex="30">
                            {{viewToPay | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:30px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Payment Due	
                          </div>
                          <div fxFlex="30">
                            {{viewPaymentDue}}
                          </div>
                        </div>
                      </h3>
                      <h2 style="margin-bottom:20px; margin-top: 10px; font-weight:bold;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="30">
                            Accepted Transactions ({{tapsUploadTransactionData.data.length}})
                          </div>
                        </div>
                      </h2>
                      <div name="Upload History File (Accepted Transactions)">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranWarning">
                              <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAdjust">
                              <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCollectedValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCode">
                              <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranName">
                              <mat-header-cell *matHeaderCellDef > Name </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="tapsUploadTransactionColumns"></mat-header-row>
                          </table>
                          </div>
                          <div class="example-container mat-elevation-z8" style="height: fit-content;">
                            <table mat-table [dataSource]="tapsUploadTransactionData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                              <ng-container matColumnDef="tranAgent">
                                <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                                <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranTapsId">
                                <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranRef">
                                <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranDepart">
                                <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranClient">
                                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranWarning">
                                <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                                <mat-cell style="font-weight:bold;" *matCellDef="let upload"> {{upload.tranWarning}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranAdjust">
                                <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAdjust}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCollectedValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCollectedValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCode">
                                <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCode}} </mat-cell>
                              </ng-container>                        
                              <ng-container matColumnDef="tranName">
                                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranName}} </mat-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: tapsUploadTransactionColumns;"></mat-row>
                            </table>
                          </div>
                          <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <div fxFlex="90">
                                Unapproved - {{viewAdjustments | number : '1.2-2'}}	
                              </div>
                            </div>
                          </h3>
                          <h3 style="margin-bottom:10px; margin-top: 10px; text-align:end;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <div fxFlex="90">
                                Approved - {{viewToPay | number : '1.2-2'}}
                              </div>
                            </div>
                          </h3>
                          <h3 style="margin-bottom:30px; margin-top: 10px; text-align:end;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <div fxFlex="90">
                                Total - {{viewOverallAcceptedAmount | number : '1.2-2'}}
                              </div>
                            </div>
                          </h3>
                      </div>
                      <h2 style="margin-bottom:20px; margin-top: 40px; font-weight:bold;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="30">
                            Rejected Transactions ({{tapsUploadTransactionRejectedData.data.length}})
                          </div>
                        </div>
                      </h2>
                      <div name="Upload History File (Rejected Transactions)">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranFailure">
                              <mat-header-cell *matHeaderCellDef > Failure </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="tapsUploadRejectedTransactionColumns"></mat-header-row>
                          </table>
                          </div>
                          <div class="example-container mat-elevation-z8" style="height: fit-content;">
                            <table mat-table [dataSource]="tapsUploadTransactionRejectedData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                              <ng-container matColumnDef="tranAgent">
                                <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranTapsId">
                                <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranRef">
                                <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranDepart">
                                <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranClient">
                                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>                       
                              <ng-container matColumnDef="tranFailure">
                                <mat-header-cell *matHeaderCellDef> Failure </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranFailure}} </mat-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: tapsUploadRejectedTransactionColumns;"></mat-row>
                            </table>
                      </div>  
                      <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="90">
                            Rejected - {{viewOverallRejectedAmount | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <div style="margin:10px;">
                        <button class="buttonClass" style="margin:10px; background-color:#ce983a; color:white;" mat-raised-button (click)="acceptLateUpload()" >Accept</button>
                        <button class="buttonClass" style="margin:10px;" mat-raised-button color="warn" (click)="rejectLateUpload()">Reject</button>
                      </div>
                    </div>  
                    </div> 
                  </div> 
            </div>
            <!-- Export or Import Transactions Admin Page -->
            <div *ngIf="listOfPages[6].value" class="taps-sidenav-content">
                  <h1>Export or Import Transactions</h1>
                  <div class="taps-insidenav-content">
                    <h2 style="margin-top:40px; margin-bottom: 0px; font-weight:bold; font-size:19px;">Agent</h2>
                    <h3 style="margin-top: 0px; margin-bottom: 40px;;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="30">
                          Select an Agent to export or import
                        </div>
                        <div fxFlex="30">
                          <mat-form-field style="max-width: unset; width: 500px; margin-bottom: -15px;">
                            <mat-select ngModel required name="tradeCode" [(ngModel)]="selectedAgentForAdminExport" placeholder="Agent">
                              <mat-form-field style="max-width: unset; width: 520px;" appearance="fill">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminAgentsListString" (keyup)="filterAdminAgentListSelect()">
                              </mat-form-field>
                              <mat-option *ngFor="let agent of filteredAdminAgentsListData.data" [value]="agent.tradeCode" (click)="filterAdminAgentsListString = ''; filterAdminAgentListSelect();">
                                {{agent.fullName}} {{"("+ agent.isTapsReg.toUpperCase() + " TAPS)"}} 
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h2 style="margin-bottom: 0px; font-weight:bold; font-size:19px;">Export to Excel</h2>
                    <h3>
                      <p>Use this function to export transactions for the current period to an Excel spreadsheet. You may then edit manually and enter a Reason Code.</p>
                      <p>These transactions must be imported back into the system before the review period closes Wednesday 16.00pm. Items imported will overwrite any existing items already within the system.</p>
                      <div style="margin:10px;">
                        <button class="buttonClass" mat-raised-button color="primary" (click)="adminExportToExcelTransactionsForReview()">Export to Excel</button>
                      </div>
                    </h3>
                    <h2 style="margin-bottom: 0px; margin-top:40px; font-weight:bold; font-size:19px;">Import from Excel</h2>
                    <h3>
                      <p>To import your saved excel spreadsheet click the import button below. This will overwrite any items already in the system. You may only import a file while the review period is open. Monday 10.00am to Wednesday 16.00pm.</p>
                      <p>Please ensure all information is filled in and where payment is adjusted, you MUST specify a reason code. Failure to do will result in the file being rejected.</p>
                      <div style="margin:10px;">
                        <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="importAgentReviewedTransactions($event,true)" accept=".xlsx" multiple="false" />
                        <button [disabled]="false" mat-raised-button color="primary" (click)="csvInput1.click()" matTooltip="Upload File">
                          Import File
                        </button>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="loggedInUserType !='Admin' && !transactionAvailability" style="margin-top:30px;">
                    <h3>
                      System available from Saturday 9:00-AM to Wednesday 4:00-PM.<br><br>
                      You are advised that TAPS is now closed and any unconfirmed transactions will not have been approved.<br><br>
                      Please go to Reporting.
                    </h3>
                    <button class="buttonClass" (click)="switchView('homePage')" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" >
                      Home
                    </button>
                  </div>
            </div>
            <!-- Send Agent Reminders Page -->
            <div *ngIf="listOfPages[7].value" class="taps-sidenav-content">
                  <h1>
                    Send Agent Reminders
                    <button [disabled]="tapsAgentRemindersListData.data.length == 0" *ngIf="tapsAgentRemindersListData.data.length > 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="sendAllAgentReminders()">
                      Send All Reminders
                    </button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tapsAgentRemindersListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Agent Reminders List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="agentReminderListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentReminderListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentReminderListTelephone">
                            <mat-header-cell *matHeaderCellDef> Telephone </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentReminderListEmail">
                            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentReminderListValue">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentSendReminder">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "></mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsAgentRemindersListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsAgentRemindersListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="agentReminderListName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let agent">{{agent.branchName}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentReminderListTapsIdenti">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let agent"> {{agent.tradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentReminderListTelephone">
                              <mat-header-cell *matHeaderCellDef> Telphone </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let agent"> {{agent.telephone}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentReminderListEmail">
                              <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let agent"> {{agent.email}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentReminderListValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let agent"> £ {{agent.overallValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentSendReminder">
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let agent"> 
                                <button class="buttonClass" mat-raised-button color="primary" (click)="sendAgentReminder(agent)" >Send Reminder</button>
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsAgentRemindersListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>
            </div>
            <!-- Delete Upload Page -->
            <div *ngIf="listOfPages[8].value" class="taps-sidenav-content">
                  <h1>Delete Upload</h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 0px;">
                      <p>{{adminDeleteUploadListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Delete Upload List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="deleteUploadListSupplier">
                            <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteUploadListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteUploadListUpload">
                            <mat-header-cell *matHeaderCellDef> Upload </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteUploadListCollection">
                            <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteUploadListValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteUploadListState">
                            <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteUploadListId">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Delete </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="adminDeleteUploadListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="adminDeleteUploadListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="deleteUploadListSupplier">
                              <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let upload">{{upload.supplierName}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteUploadListTapsIdenti">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.supplierTradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteUploadListUpload">
                              <mat-header-cell *matHeaderCellDef> Upload </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.viewUploadDate}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteUploadListCollection">
                              <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.collectionDate}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteUploadListValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteUploadListState">
                              <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> Review </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteUploadListId">
                              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px; cursor:pointer;" *matCellDef="let upload" (click)="deleteReviewFile(upload)"> <mat-icon>clear</mat-icon> </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: adminDeleteUploadListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>
            </div>
            <!-- Commit Upload Page -->
            <div *ngIf="listOfPages[9].value" class="taps-sidenav-content">
                  <h1>
                    Commit Upload
                    <button [disabled]="!transactionAvailability" *ngIf="adminCommitUploadListData.data.length > 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="showCommitAllDialog()" >
                      Commit All
                    </button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 0px;">
                      <p>{{adminCommitUploadListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Commit Upload List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="commitListSupplier">
                            <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="commitListABTA">
                            <mat-header-cell *matHeaderCellDef> ABTA </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="commitListUpload">
                            <mat-header-cell *matHeaderCellDef> Upload </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="commitListCollection">
                            <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="commitListValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="commitListState">
                            <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="commitListId">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Commit </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="adminCommitUploadListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="adminCommitUploadListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="commitListSupplier">
                              <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let upload">{{upload.supplierName}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="commitListABTA">
                              <mat-header-cell *matHeaderCellDef> ABTA </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.supplierTradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="commitListUpload">
                              <mat-header-cell *matHeaderCellDef> Upload </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.viewUploadDate}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="commitListCollection">
                              <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.viewCollectionDate}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="commitListValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.overallAmount | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="commitListState">
                              <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> {{upload.state}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="commitListId">
                              <mat-header-cell *matHeaderCellDef> Commit </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px; cursor:pointer;" *matCellDef="let upload" >
                                <button [disabled]="!transactionAvailability" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="showCommitDialog(upload)" >
                                  Commit
                                </button>
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: adminCommitUploadListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>
            </div>
            <!-- Collection from Agents Page -->
            <div *ngIf="listOfPages[10].value" class="taps-sidenav-content">
                  <h1>
                    Collection from Agents
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15" style="padding-bottom: 20px">
                          Collection Due<span style="color:#4D5FD1">*</span>  
                        </div>
                        <div style="display: block; max-width: fit-content !important;">
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="collectionDueDateFrom">
                            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromPicker ></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="toPicker" [(ngModel)]="collectionDueDateTo">
                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                            <mat-datepicker #toPicker ></mat-datepicker>
                          </mat-form-field>
                          <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" (click)="setupCollectionFromAgents()">Find</button>
                        </div>
                      </div>
                    </h3>
                    <h2 style="margin-bottom: 0px; font-weight:bold; font-size:19px;">Accepted Payments</h2>
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tapsCollectionAgentAcceptedListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Accepted Payments List" style="margin-bottom: 25px;">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="collectionAgentAcceptedListCollection">
                            <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentAcceptedListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentAcceptedListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentAcceptedListSortcode">
                            <mat-header-cell *matHeaderCellDef> SortCode </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentAcceptedListAC">
                            <mat-header-cell *matHeaderCellDef> A/C </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentAcceptedListType">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center;"> Type </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentAcceptedListAmount">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: end;"> Amount </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentAcceptedListApproved">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Approved </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsCollectionAgentAcceptedListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsCollectionAgentAcceptedListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="collectionAgentAcceptedListCollection">
                              <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let transaction"> {{transaction.collectionDate}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentAcceptedListTapsIdenti">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.agentTradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentAcceptedListName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.accountName}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentAcceptedListSortcode">
                              <mat-header-cell *matHeaderCellDef> SortCode </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.sortCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentAcceptedListAC">
                              <mat-header-cell *matHeaderCellDef> A/C </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.accountNumber}}  </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentAcceptedListType">
                              <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px; display:block; text-align: center;" *matCellDef="let transaction"> {{transaction.type}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentAcceptedListAmount">
                              <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px; display:block; text-align: end;" *matCellDef="let transaction"> {{transaction.tranValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentAcceptedListApproved">
                              <mat-header-cell *matHeaderCellDef> Approved </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let transaction"> 
                                <span *ngIf="transaction.collection == 'Successful'">Yes</span>
                                <span *ngIf="transaction.collection != 'Successful'">No</span>
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsCollectionAgentAcceptedListColumns;"></mat-row>
                          </table>
                        </div>
                        <h3 style="margin-bottom:20px; margin-top: 20px; text-align:end;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="85">
                              <div>
                                Total - {{tapsCollectionAgentAcceptedOverall | number : '1.2-2'}}
                              </div>
                            </div>
                          </div>
                        </h3>
                    </div>  
                    <h2 style="margin-bottom: 0px; font-weight:bold; font-size:19px;">Rejected Payments</h2>
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tapsCollectionAgentRejectedListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Rejected Payments List">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="collectionAgentRejectedListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentRejectedListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentRejectedListBookingRef">
                            <mat-header-cell *matHeaderCellDef> Booking Ref </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentRejectedListDepart">
                            <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="collectionAgentRejectedListAmount">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Amount </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsCollectionAgentRejectedListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsCollectionAgentRejectedListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="collectionAgentRejectedListTapsIdenti">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let transaction"> {{transaction.agentTradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentRejectedListName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.clientName}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentRejectedListBookingRef">
                              <mat-header-cell *matHeaderCellDef> Booking Ref </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.operatorReference}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentRejectedListDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.formattedDepartureDate}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="collectionAgentRejectedListAmount">
                              <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsCollectionAgentRejectedListColumns;"></mat-row>
                          </table>
                        </div>
                        <h3 style="margin-bottom:20px; margin-top: 20px; text-align:end;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="95">
                              Total - {{tapsCollectionAgentRejectedOverall | number : '1.2-2'}}
                            </div>
                          </div>
                        </h3>
                    </div>
                    <h2 style="margin-bottom: 0px; font-weight:bold; padding-top:20px; font-size:19px;">Security</h2>
                    <h3 style="margin-bottom: 0px;">
                      <p>To commit BACS run you must enter your SinGS account credentials.</p>
                      <h3>
                        <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticateExport(authForm,'Collection')">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="40">
                              <mat-form-field style="width: 250px; max-width: unset; font-weight: bold;">
                                <mat-label>Email</mat-label>
                                <input matInput required ngModel name="email" #email="ngModel" maxlength="100" type="email">
                              </mat-form-field>
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="40">                              
                              <mat-form-field style="width: 250px; max-width: unset; font-weight: bold;">
                                <mat-label>Password</mat-label>
                                <input matInput required ngModel name="password" #password="ngModel" maxlength="100" type="password">
                              </mat-form-field>
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:10px;">
                            <div fxFlex="20">
                              <button [disabled]="tapsCollectionAgentAcceptedListData.data.length == 0 && tapsCollectionAgentRejectedListData.data.length == 0" class="buttonClass" mat-raised-button color="primary" type="submit">Generate BACS File</button>
                            </div>
                          </div>
                        </form>
                      </h3>        
                    </h3>
                  </div>
            </div>
            <!-- Delivery to Suppliers Page -->
            <div *ngIf="listOfPages[11].value" class="taps-sidenav-content">
                  <h1>
                    Delivery to Suppliers
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15" style="padding-bottom: 20px">
                          Payment Due<span style="color:#4D5FD1">*</span>  
                        </div>
                        <div style="display: block; max-width: fit-content !important;">
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="deliveryDueDateFrom">
                            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromPicker ></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="toPicker" [(ngModel)]="deliveryDueDateTo">
                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                            <mat-datepicker #toPicker ></mat-datepicker>
                          </mat-form-field>
                          <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" (click)="setupDeliveryToSuppliers()">Find</button>
                        </div>
                      </div>
                    </h3>
                    <h2 style="margin-bottom: 0px; font-weight:bold; font-size:19px;">Accepted Payments</h2>
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tapsDeliveryToSupplierAcceptedListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Accepted Payments List" style="margin-bottom:25px;">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPayment">
                            <mat-header-cell *matHeaderCellDef> Payment </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListSortcode">
                            <mat-header-cell *matHeaderCellDef> SortCode </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPaymentListAC">
                            <mat-header-cell *matHeaderCellDef> A/C </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPaymentType">
                            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPaymentDue">
                            <mat-header-cell *matHeaderCellDef> Due </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListApproved">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Approved </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsDeliveryToSupplierAcceptedListColumns"></mat-header-row>
                        </table>
                      </div>
                      <div class="example-container mat-elevation-z8" style="height: fit-content;">
                        <table mat-table [dataSource]="tapsDeliveryToSupplierAcceptedListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPayment">
                            <mat-header-cell *matHeaderCellDef> Payment </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let transaction"> {{transaction.paymentDate}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.supplierTradeCode}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.accountName}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListSortcode">
                            <mat-header-cell *matHeaderCellDef> SortCode </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.sortCode}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPaymentListAC">
                            <mat-header-cell *matHeaderCellDef> A/C </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.accountNumber}}  </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPaymentType">
                            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.type}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListPaymentDue">
                            <mat-header-cell *matHeaderCellDef> Due </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.value | number : '1.2-2'}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierAcceptedListApproved">
                            <mat-header-cell *matHeaderCellDef> Approved </mat-header-cell>
                            <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let transaction"> 
                              <span *ngIf="transaction.paid == 'Successful'">Yes</span>
                              <span *ngIf="transaction.paid != 'Successful'">No</span>
                            </mat-cell>
                          </ng-container>
                          <mat-row *matRowDef="let row; columns: tapsDeliveryToSupplierAcceptedListColumns;"></mat-row>
                        </table>
                      </div>
                      <h3 style="margin-bottom:20px; margin-top: 20px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="85">
                            Total - {{tapsDeliveryToSupplierAcceptedOverall | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                    </div>
                    <h2 style="margin-bottom: 0px; font-weight:bold; font-size:19px;">Rejected Payments</h2>
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tapsDeliveryToSupplierRejectedListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Rejected Payments List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">                        
                          <ng-container matColumnDef="deliveryToSupplierRejectedListTtaNo">
                            <mat-header-cell *matHeaderCellDef> TTA No </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListRef">
                            <mat-header-cell *matHeaderCellDef> Ref </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListPaymentListDepart">
                            <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListAmount">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Amount </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsDeliveryToSupplierRejectedListColumns"></mat-header-row>
                        </table>
                      </div>
                      <div class="example-container mat-elevation-z8" style="height: fit-content;">
                        <table mat-table [dataSource]="tapsDeliveryToSupplierRejectedListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                          <ng-container matColumnDef="deliveryToSupplierRejectedListTtaNo">
                            <mat-header-cell *matHeaderCellDef> TTA No </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let transaction"> {{transaction.supplierTradeCode}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.clientName}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListRef">
                            <mat-header-cell *matHeaderCellDef> Ref </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.operatorReference}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListPaymentListDepart">
                            <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                            <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.formattedDepartureDate}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="deliveryToSupplierRejectedListAmount">
                            <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                            <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranValue | number : '1.2-2'}} </mat-cell>
                          </ng-container>
                          <mat-row *matRowDef="let row; columns: tapsDeliveryToSupplierRejectedListColumns;"></mat-row>
                        </table>
                      </div>
                      <h3 style="margin-bottom:20px; margin-top: 20px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="95">
                            Total - {{tapsDeliveryToSupplierRejectedOverall | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                    </div>
                    <h2 style="margin-bottom: 0px; font-weight:bold; padding-top:20px; font-size:19px;">Security</h2>
                    <h3 style="margin-bottom: 0px;">
                      <p>To commit BACS run you must enter your SinGS account credentials.</p>
                      <h3>
                        <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticateExport(authForm,'Delivery')">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="40">
                              <mat-form-field style="width: 250px; max-width: unset; font-weight: bold;">
                                <mat-label>Email</mat-label>
                                <input matInput required ngModel name="email" #email="ngModel" maxlength="100" type="email">
                              </mat-form-field>
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="40">                              
                              <mat-form-field style="width: 250px; max-width: unset; font-weight: bold;">
                                <mat-label>Password</mat-label>
                                <input matInput required ngModel name="password" #password="ngModel" maxlength="100" type="password">
                              </mat-form-field>
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:10px;">
                            <div fxFlex="20">
                              <button [disabled]="tapsDeliveryToSupplierAcceptedListData.data.length == 0 && tapsDeliveryToSupplierRejectedListData.data.length == 0" class="buttonClass" mat-raised-button color="primary" type="submit">Generate BACS File</button>
                            </div>
                          </div>
                        </form>
                      </h3>        
                    </h3>  
                  </div>                
            </div>
            <!-- Transactions Invoicing Page -->
            <div *ngIf="listOfPages[12].value" class="taps-sidenav-content">
                  <h1>
                    Transactions Invoicing                    
                    <button [disabled]="tranInvoiceListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary"  (click)="exportTransactionsInvoicingReport()" >Export to Excel</button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="10" style="padding-bottom: 20px">
                          Uploads<span style="color:#4D5FD1">*</span> 
                        </div>  
                        <div style="display: block; max-width: fit-content !important;">
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="invoicingDateFrom">
                            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromPicker ></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="toPicker" [(ngModel)]="invoicingDateTo">
                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                            <mat-datepicker #toPicker ></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" (click)="setupTransactionsInvoicingList()" >Find</button>
                      </div>
                    </h3>
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tranInvoiceListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Transactions Invoicing List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="tranInvoiceTapsId">
                            <mat-header-cell *matHeaderCellDef (click)="sortTransactionsInvoicingByTradeCode()" style="cursor:pointer"> TAPS IDENT </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranInvoiceName">
                            <mat-header-cell *matHeaderCellDef (click)="sortTransactionsInvoicingByName()" style="cursor:pointer"> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranInvoiceQuantity">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align:center;"> Quantity </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranInvoiceRate">
                            <mat-header-cell *matHeaderCellDef> Rate </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranInvoiceValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranInvoiceChecked">
                            <mat-header-cell *matHeaderCellDef> To Export </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tranInvoiceListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tranInvoiceListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="tranInvoiceTapsId">
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let supplier"> {{supplier.tapsIdent}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranInvoiceName">
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.name}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranInvoiceQuantity">
                              <mat-cell style="font-weight:bold; padding: 10px; display:block; text-align:center;" *matCellDef="let supplier"> {{supplier.quantity}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranInvoiceRate">
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.rate | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranInvoiceValue">
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.value | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranInvoiceChecked">
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier">
                                 <mat-checkbox [(ngModel)]='supplier.checked'></mat-checkbox>  
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tranInvoiceListColumns;"></mat-row>
                          </table>
                        </div>
                        <h2 style="margin-bottom: 0px; font-weight:bold; padding-top:20px; font-size:19px;">Security</h2>
                        <h3 style="margin-bottom: 0px;">
                          <p>To 'Commit Invoicing Transactions' you must enter your SinGS account credentials.</p>
                          <h3>
                            <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticateCommitForInvoicingTransactions(authForm)">
                              <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="40">
                                  <mat-form-field style="width: 250px; max-width: unset; font-weight: bold;">
                                    <mat-label>Email</mat-label>
                                    <input matInput required ngModel name="email" #email="ngModel" maxlength="100" type="email">
                                  </mat-form-field>
                                </div>
                              </div>
                              <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="40">                              
                                  <mat-form-field style="width: 250px; max-width: unset; font-weight: bold;">
                                    <mat-label>Password</mat-label>
                                    <input matInput required ngModel name="password" #password="ngModel" maxlength="100" type="password">
                                  </mat-form-field>
                                </div>
                              </div>
                              <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:10px;">
                                <div fxFlex="20">
                                  <button [disabled]="tranInvoiceListData.data.length == 0" class="buttonClass" mat-raised-button color="primary" type="submit">Commit Invoicing Transactions</button>
                                </div>
                              </div>
                            </form>
                          </h3>        
                        </h3>  
                    </div>
                  </div>
            </div>
            <!-- Reporting Admin Page -->
            <div *ngIf="listOfPages[13].value" class="taps-sidenav-content">
                  <h1>
                    <div *ngIf="switchToGeneratedReportsView == false">
                      <button *ngIf="adminCameFromAgentManagement == true" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('agentPage')">
                        <mat-icon>arrow_back</mat-icon>
                      </button> 
                      <button *ngIf="adminCameFromSupplierManagement == true" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('supplierPage')">
                        <mat-icon>arrow_back</mat-icon>
                      </button>
                      Reporting
                      <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="adminReport()" >
                        Find
                      </button>
                      <button [disabled]="tapsReportAdminListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="adminExportReport()" >
                        Export to Excel
                      </button>
                      <button class="buttonClass" [matBadge]="generatedReportBadge" matBadgeColor="warn" [matBadgeHidden]="generatedReportBadgeHidden" mat-raised-button style="margin-left:10px;" [color]="generatedReportTitle == 'Generated Reports' ? 'primary' : 'accent'" (click)="switchToGeneratedReports('tapsAdminReport')" >
                        {{generatedReportTitle}}  
                      </button>
                    </div>
                    <div *ngIf="switchToGeneratedReportsView == true">
                      <button *ngIf="adminCameFromAgentManagement == true" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('agentPage')">
                        <mat-icon>arrow_back</mat-icon>
                      </button> 
                      <button *ngIf="adminCameFromSupplierManagement == true" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('supplierPage')">
                        <mat-icon>arrow_back</mat-icon>
                      </button>
                      Generated Reports
                      <button class="buttonClass" matTooltip="Refresh Generated Reports List" mat-raised-button style="margin-left:10px;" color="primary" (click)="setupListOfTapsReporting('tapsAdminReport')" >
                        Refresh
                      </button>
                      <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="switchToGeneratedReports('tapsAdminReport')" >
                        Back to Reporting
                      </button>
                    </div>
                  </h1>
                  <div class="taps-insidenav-content">
                    <div *ngIf="switchToGeneratedReportsView == true">
                      <div *ngIf="attachedDocs.data.length > 0">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">
                              <mat-header-cell *matHeaderCellDef> File Name</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-header-cell *matHeaderCellDef> Size </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="download">
                              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                          </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="attachedDocs" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">                      
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.name }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="download">
                              <mat-cell *matCellDef="let data">
                                <button matTooltip="Download" class="matIcon" style="float: right;" type="button" mat-mini-fab color="primary" (click)="downloadDocument(data,'tapsAdminReport','')" [class.mat-elevation-z0]="true">
                                  <mat-icon>file_download</mat-icon>
                                </button>
                              </mat-cell>
                            </ng-container>                          
                            <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                          </table>
                        </div>
                      </div>
                      <div *ngIf="attachedDocs.data.length == 0">
                        <h2> No records found. </h2>
                      </div>
                    </div>
                    <div *ngIf="switchToGeneratedReportsView == false">
                      <h3 style="margin-bottom: 30px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="12">
                            Agent	
                          </div>
                          <div fxFlex="70">
                            <div style="display:flex">
                              <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                                <mat-select ngModel required name="tradeCode" [(ngModel)]="adminReportAgent" placeholder="Agent">
                                  <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                                    <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminAgentsListString" (keyup)="filterAdminAgentListSelect()">
                                  </mat-form-field>
                                  <mat-option value="all">
                                    All
                                  </mat-option>
                                  <mat-option *ngFor="let agent of filteredAdminAgentsListData.data" [value]="agent.tradeCode" (click)="filterAdminAgentsListString = ''; filterAdminAgentListSelect();">
                                    {{agent.branchName + " (" + agent.tradeCode + " - " + agent.isTapsReg.toUpperCase() +" TAPS)"}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-checkbox style="padding-left: 15px; margin-bottom: 10px;" [(ngModel)]='adminIncludeBranches'>Include Branches</mat-checkbox>
                            </div>
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom: 30px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="12">
                            Supplier	
                          </div>
                          <div fxFlex="30">
                            <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                              <mat-select ngModel required name="tradeCode" [(ngModel)]="adminReportSupplier" placeholder="Supplier">
                                <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                                  <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminSupplierListString" (keyup)="filterMemberSupplierListSelect()">
                                </mat-form-field>
                                <mat-option value="all">
                                  All
                                </mat-option>
                                <mat-option *ngFor="let supplier of filteredAdminSupplierListData.data" [value]="supplier.misttaCode ? supplier.misttaCode : 'N/A'" (click)="filterAdminSupplierListString = ''; filterMemberSupplierListSelect();">
                                  {{supplier.supplierNameM + " (" + (supplier.misttaCode ? supplier.misttaCode : 'N/A') + " - " + supplier.isTapsReg.toUpperCase() +" TAPS)"}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="12">
                            Collection Period
                          </div>
                          <div fxFlex="30">
                            <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select placeholder="Period" style="vertical-align: middle;" [(ngModel)]="adminReportingDatePeriod"  (selectionChange)="setupDatesForAdminReportingPeriod()">
                                <mat-option *ngFor="let period of adminReportingDatePeriods" [value]="period">
                                  {{period.displayRow}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="12">
                            Date Range<span style="color:#4D5FD1">*</span> 
                          </div>
                          <div style="display: block; max-width: fit-content !important;">
                            <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                              <mat-label>From</mat-label>
                              <input matInput [matDatepicker]="fromPicker" [(ngModel)]="adminReportingDateFrom">
                              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                              <mat-datepicker #fromPicker ></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                              <mat-label>To</mat-label>
                              <input matInput [matDatepicker]="toPicker" [(ngModel)]="adminReportingDateTo">
                              <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                              <mat-datepicker #toPicker ></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="12">
                            Upload State	
                          </div>
                          <div fxFlex="30">
                            <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select placeholder="State" [(ngModel)]="adminReportUploadState" style="vertical-align: middle;">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="Succeeded">Succeeded</mat-option>
                                <mat-option value="Failed">Failed</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="12">
                            Payment State	
                          </div>
                          <div fxFlex="30">
                            <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select placeholder="State" [(ngModel)]="adminReportPaymentState" style="vertical-align: middle;">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="Review">Agent Review</mat-option>
                                <mat-option value="Collected"> Collected from Agents / Awaiting Approval</mat-option>
                                <mat-option value="Paid">Paid to Suppliers</mat-option>
                                <mat-option value="Unpaid"> Unpaid Agend Debits / Not Authorised</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3> 
                      <h3 style="margin-bottom: 0px;">
                        <p>{{tapsReportAdminListData.data.length}} Record(s)</p>
                      </h3>
                      <div name="Reporting (Admin) List">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="tranSupplier">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportBySupplier()" style="cursor:pointer;"> Supplier</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranSupTapsId">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportBySupplierTapsId()" style="cursor:pointer;"> TAPs Identi. </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByAgent()" style="cursor:pointer;"> Agent </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByTapsId()" style="cursor:pointer;"> TAPs Identi. </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByRef()" style="cursor:pointer;"> Ref </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByDepart()" style="cursor:pointer;"> Depart </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByClient()" style="cursor:pointer;"> Client </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranPayment">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByCollectionDate()" style="cursor:pointer;"> Collection </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranWarning">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByWarning()" style="cursor:pointer;"> Failure </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByValue()" style="cursor:pointer;"> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAdjust">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByAdjust()" style="cursor:pointer;"> Adjusted </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCollectedValue">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByCollection()" style="cursor:pointer;"> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCode">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByCode()" style="cursor:pointer;"> Code </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranName">
                              <mat-header-cell *matHeaderCellDef (click)="sortAdminReportByName()" style="cursor:pointer; display:block; text-align: center; "> Reason </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="tapsReportAdminListColumns"></mat-header-row>
                          </table>
                          </div>
                          <div class="example-container mat-elevation-z8" style="height: fit-content;">
                            <table mat-table [dataSource]="tapsReportAdminListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                              <ng-container matColumnDef="tranSupplier">
                                <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;  margin-left: 10px;" *matCellDef="let transaction"> {{transaction.tranSupplier}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranSupTapsId">
                                <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranSupTapsId}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranAgent">
                                <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranAgent}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranTapsId">
                                <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranTapsId}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranRef">
                                <mat-header-cell *matHeaderCellDef> Ref </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranRef}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranDepart">
                                <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranDepart}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranClient">
                                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranClient}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranPayment">
                                <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranCollectionDate}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranWarning">
                                <mat-header-cell *matHeaderCellDef> Failure </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranWarning}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranAdjust">
                                <mat-header-cell *matHeaderCellDef> Adjusted </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> 
                                  <span *ngIf="transaction.tranApproval != 'Adjusted'">No</span>
                                  <span *ngIf="transaction.tranApproval == 'Adjusted'">Yes</span>
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCollectedValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranCollectedValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCode">
                                <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranCode}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranName">
                                <mat-header-cell *matHeaderCellDef> Reason </mat-header-cell>
                                <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranName}} </mat-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: tapsReportAdminListColumns;"></mat-row>
                            </table>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
            <!-- Analysis Reporting Admin Page -->
            <div *ngIf="listOfPages[14].value" class="taps-sidenav-content">
                  <h1>
                    Analysis Reporting
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" color="primary" (click)="adminExportAnalysisReport()">
                      Export to Excel
                    </button>                   
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 30px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Agent	
                        </div>
                        <div fxFlex="70">
                          <div style="display:flex">
                            <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                              <mat-select ngModel required name="tradeCode" [(ngModel)]="adminReportAgent" placeholder="Agent">
                                <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                                  <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminAgentsListString" (keyup)="filterAdminAgentListSelect()">
                                </mat-form-field>
                                <mat-option value="all">
                                  All
                                </mat-option>
                                <mat-option *ngFor="let agent of filteredAdminAgentsListData.data" [value]="agent.tradeCode" (click)="filterAdminAgentsListString = ''; filterAdminAgentListSelect();">
                                  {{agent.branchName + " (" + agent.tradeCode + " - " + agent.isTapsReg.toUpperCase() +" TAPS)"}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-checkbox style="padding-left: 15px; margin-bottom: 10px;" [(ngModel)]='adminIncludeBranches'>Include Branches</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom: 30px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Supplier	
                        </div>
                        <div fxFlex="30">
                          <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                            <mat-select ngModel required name="tradeCode" [(ngModel)]="adminReportSupplier" placeholder="Supplier">
                              <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminSupplierListString" (keyup)="filterMemberSupplierListSelect()">
                              </mat-form-field>
                              <mat-option value="all">
                                All
                              </mat-option>
                              <mat-option *ngFor="let supplier of filteredAdminSupplierListData.data" [value]="supplier.misttaCode ? supplier.misttaCode : 'N/A'" (click)="filterAdminSupplierListString = ''; filterMemberSupplierListSelect();">
                                {{supplier.supplierNameM + " (" + (supplier.misttaCode ? supplier.misttaCode : 'N/A') + " - " + supplier.isTapsReg.toUpperCase() +" TAPS)"}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Report<span style="color:#4D5FD1">*</span>	
                        </div>
                        <div style="display:block">
                          <mat-form-field style="width: 300px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Report Type" [(ngModel)]="adminAnalysisReport" style="vertical-align: middle;">
                              <mat-option *ngFor="let report of analysisReportType" [value]="report">
                                {{report.display}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-checkbox style="padding-left: 10px; margin-bottom: 10px;">Separate ABTAs</mat-checkbox>
                        </div>
                      </div>
                    </h3>

                    <div *ngIf="adminAnalysisReport != ''">
  
                      <div *ngIf="adminAnalysisReport.format == '1'">
                        <h3>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Analysis	<span style="color:#4D5FD1">*</span> 
                            </div>
                            <div style="display: block;">
                              <h3 style="margin-bottom: 0px;">
                                <mat-form-field class="tapsFilterInput" style="width: 100px !important;">
                                  <mat-select [(ngModel)]="adminAnalysisYear" placeholder="Year" style="vertical-align: middle;">
                                    <mat-option *ngFor="let year of analysisYearsList" [value]="year">
                                      {{year}}
                                    </mat-option> 
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field class="tapsFilterInput" style="margin-left: 20px; width: 100px !important">
                                  <mat-label>Week (WW)</mat-label>
                                  <input [(ngModel)]="adminAnalysisWeek" matInput type="number" autocomplete="nope">
                                </mat-form-field>
                              </h3>
                            </div>
                          </div>
                        </h3>
                      </div>
  
                      <div *ngIf="adminAnalysisReport.format == '2'">
                        <h3>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Period <span style="color:#4D5FD1">*</span> 
                            </div>
                            <div style="display: block;">
                              <h3 style="margin-bottom: 0px;">
                                <mat-form-field class="tapsFilterInput" style="width: 100px !important;">
                                  <mat-label>Year (YYYY)</mat-label>
                                  <input [(ngModel)]="adminAnalysisYear" matInput type="number" autocomplete="nope">
                                </mat-form-field>
                                <mat-form-field style="width: 150px; max-width: unset;" class="transactionValueNormalTab">
                                  <mat-select placeholder="Month" [(ngModel)]="adminAnalysisMonth" style="vertical-align: middle;">
                                    <mat-option value="1">January</mat-option>
                                    <mat-option value="2">February</mat-option>
                                    <mat-option value="3">March</mat-option>
                                    <mat-option value="4">April</mat-option>
                                    <mat-option value="5">May</mat-option>
                                    <mat-option value="6">June</mat-option>
                                    <mat-option value="7">July</mat-option>
                                    <mat-option value="8">August</mat-option>
                                    <mat-option value="9">September</mat-option>
                                    <mat-option value="10">October</mat-option>
                                    <mat-option value="11">November</mat-option>
                                    <mat-option value="12">December</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </h3>
                            </div>
                          </div>
                        </h3>
                      </div>
  
                      <div *ngIf="adminAnalysisReport.format == '3'">
                        <h3>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Year <span style="color:#4D5FD1">*</span> 
                            </div>
                            <div style="display: block;">
                              <h3 style="margin-bottom: 0px;">
                                <mat-form-field class="tapsFilterInput" style="width: 100px !important;">
                                  <mat-select [(ngModel)]="adminAnalysisYear" placeholder="Year" style="vertical-align: middle;">
                                    <mat-option *ngFor="let year of analysisYearsList" [value]="year">
                                      {{year}}
                                    </mat-option> 
                                  </mat-select>
                                </mat-form-field>
                              </h3>
                            </div>
                          </div>
                        </h3>
                      </div>
  
                      <div *ngIf="adminAnalysisReport.format == '4'">
                        <h3>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Period<span style="color:#4D5FD1">*</span> 
                            </div>
                            <div fxFlex="30">
                              <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                                <mat-select placeholder="Period" [(ngModel)]="reportingDatePeriod" style="vertical-align: middle;" (selectionChange)="setupDatesForAnalysisReportingPeriod()">
                                  <mat-option *ngFor="let period of adminAnalysisReportingDatePeriods" [value]="period">
                                    {{period.displayRow}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                        </h3>
                        <h3>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Date Range<span style="color:#4D5FD1">*</span> 
                            </div>
                            <div fxFlex="30">
                              <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                                <mat-label>From</mat-label>
                                <input matInput [matDatepicker]="fromPicker" [(ngModel)]="adminAnalysisStartDate">
                                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                                <mat-datepicker #fromPicker ></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                                <mat-label>To</mat-label>
                                <input matInput [matDatepicker]="toPicker" [(ngModel)]="adminAnalysisEndDate">
                                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                                <mat-datepicker #toPicker ></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                        </h3>
                      </div>
  
                      <div *ngIf="adminAnalysisReport.format == '5'">
                        <h3>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Period One <span style="color:#4D5FD1">*</span> 
                            </div>
                            <div style="display: block;">
                              <h3 style="margin-bottom: 0px;">
                                <mat-form-field class="tapsFilterInput" style="width: 100px !important;">
                                  <mat-select [(ngModel)]="adminAnalysisYearOne" placeholder="Year" style="vertical-align: middle;">
                                    <mat-option *ngFor="let year of analysisYearsList" [value]="year">
                                      {{year}}
                                    </mat-option> 
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 150px; max-width: unset;" class="transactionValueNormalTab">
                                  <mat-select placeholder="Month" [(ngModel)]="adminAnalysisMonthOne" style="vertical-align: middle;">
                                    <mat-option value="1">January</mat-option>
                                    <mat-option value="2">February</mat-option>
                                    <mat-option value="3">March</mat-option>
                                    <mat-option value="4">April</mat-option>
                                    <mat-option value="5">May</mat-option>
                                    <mat-option value="6">June</mat-option>
                                    <mat-option value="7">July</mat-option>
                                    <mat-option value="8">August</mat-option>
                                    <mat-option value="9">September</mat-option>
                                    <mat-option value="10">October</mat-option>
                                    <mat-option value="11">November</mat-option>
                                    <mat-option value="12">December</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </h3>
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Period Two <span style="color:#4D5FD1">*</span> 
                            </div>
                            <div style="display: block;">
                              <h3 style="margin-bottom: 0px;">
                                <mat-form-field class="tapsFilterInput"  style="width:100px !important;">
                                  <mat-select [(ngModel)]="adminAnalysisYearTwo" placeholder="Year" style="vertical-align: middle;">
                                    <mat-option *ngFor="let year of analysisYearsList" [value]="year">
                                      {{year}}
                                    </mat-option> 
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 150px; max-width: unset;" class="transactionValueNormalTab">
                                  <mat-select placeholder="Month" [(ngModel)]="adminAnalysisMonthTwo" style="vertical-align: middle;">
                                    <mat-option value="1">January</mat-option>
                                    <mat-option value="2">February</mat-option>
                                    <mat-option value="3">March</mat-option>
                                    <mat-option value="4">April</mat-option>
                                    <mat-option value="5">May</mat-option>
                                    <mat-option value="6">June</mat-option>
                                    <mat-option value="7">July</mat-option>
                                    <mat-option value="8">August</mat-option>
                                    <mat-option value="9">September</mat-option>
                                    <mat-option value="10">October</mat-option>
                                    <mat-option value="11">November</mat-option>
                                    <mat-option value="12">December</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </h3>
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="12">
                              Months <span style="color:#4D5FD1">*</span> 
                            </div>
                            <div style="display: block;">
                              <h3 style="margin-bottom: 0px;">                               
                                <mat-form-field style="width: 150px; max-width: unset;" class="transactionValueNormalTab">
                                  <mat-select placeholder="Months" [(ngModel)]="adminAnalysisMonths" style="vertical-align: middle;">
                                    <mat-option value="1">1</mat-option>
                                    <mat-option value="2">2</mat-option>
                                    <mat-option value="3">3</mat-option>
                                    <mat-option value="4">4</mat-option>
                                    <mat-option value="5">5</mat-option>
                                    <mat-option value="6">6</mat-option>
                                    <mat-option value="7">7</mat-option>
                                    <mat-option value="8">8</mat-option>
                                    <mat-option value="9">9</mat-option>
                                    <mat-option value="10">10</mat-option>
                                    <mat-option value="11">11</mat-option>
                                    <mat-option value="12">12</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </h3>
                            </div>
                          </div>
                        </h3>
                      </div>   
  
                    </div>
                  </div>
            </div>
            <!-- Transaction Approval Page -->
            <div *ngIf="listOfPages[15].value" class="taps-sidenav-content">
              <h1>
                Transaction Approval
                <button *ngIf="transactionAvailability && transactionViewIfAvailable" [disabled]="tapsTransactionApprovalData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="memberExportToExcelTransactionsForReviewList()" >
                  <span *ngIf = "tapsTransactionApprovalData.data.length == 0">
                    Loading ...
                  </span>
                  <span *ngIf = "tapsTransactionApprovalData.data.length > 0">
                    Export to Excel
                  </span>
                </button>
                <button *ngIf="transactionAvailability && transactionViewIfAvailable" [disabled]="tapsTransactionApprovalData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="memberPrintTransactionsForReviewList()" >
                  <span *ngIf = "tapsTransactionApprovalData.data.length == 0">
                    Loading ...
                  </span>
                  <span *ngIf = "tapsTransactionApprovalData.data.length > 0">
                    Print
                  </span>
                </button>
              </h1>
              <div class="taps-insidenav-content">
                <div *ngIf="transactionAvailability && transactionViewIfAvailable">
                  <h3 style="margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20">
                        Total Transactions
                      </div>
                      <div fxFlex="30">
                        {{tranAprTotalTransactions}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20">
                        Collection Date	
                      </div>
                      <div fxFlex="30">
                        {{transAprCollectionDate}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20">
                        Original Total	
                      </div>
                      <div fxFlex="30">
                        {{tranAprOriginalTotal | number : '1.2-2'}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20">
                        Adjustments	
                      </div>
                      <div fxFlex="30">
                        <div *ngIf="tranAprAdjustmentsTotal >= 0">
                          -{{tranAprAdjustmentsTotal | number : '1.2-2'}}
                        </div>
                        <div *ngIf="tranAprAdjustmentsTotal < 0">
                          - ({{tranAprAdjustmentsTotal | number : '1.2-2'}})
                        </div>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20">
                        Adjustments	
                      </div>
                      <div fxFlex="30">
                        % {{tranAprAdjustmentsPercentage | number : '1.2-2'}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:30px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="20">
                        Collection Total	
                      </div>
                      <div fxFlex="30">
                        {{tranAprCollectionTotal | number : '1.2-2'}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="display:block;">
                    <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px; background-color:#ce983a; color:white;" (click)="acceptAllTransactions()">Accept All</button>
                    <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="warn" (click)="rejectAllTransactions()">Reject All</button>
                  </h3>
                  <mat-table [dataSource]="tapsTransactionApprovalData" class="mat-elevation-z8">

                    <div *ngIf="onBookingPlatform == true">
                      <ng-container matColumnDef="tranAprAgent">
                        <mat-header-cell (click)="sortTransactionsByAgent()" *matHeaderCellDef style="cursor:pointer;"> Agent </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprAgent }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprAgentTapsId">
                        <mat-header-cell (click)="sortTransactionsByAgentTaps()" *matHeaderCellDef style="cursor:pointer;"> TAPs<br>Identi. </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprAgentTapsId }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprSupplier">
                        <mat-header-cell (click)="sortTransactionsBySupplier()" *matHeaderCellDef style="margin-left:30px; cursor:pointer;"> Supplier </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;  margin-left: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprSupplier }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprSupTapsId">
                        <mat-header-cell (click)="sortTransactionsBySupplierTaps()" *matHeaderCellDef style="margin-left:15px; cursor:pointer;"> TAPs<br>Identi. </mat-header-cell>
                        <mat-cell *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprSupTapsId }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprRef">
                        <mat-header-cell (click)="sortTransactionsByRef()" *matHeaderCellDef style="margin-left:15px; cursor:pointer;"> Supplier Ref </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px; display: block; text-align: end;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprRef }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprDepart">
                        <mat-header-cell (click)="sortTransactionsByDepart()" *matHeaderCellDef style="cursor:pointer; display: block; text-align: end; margin-right: 20px;"> Depart </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px; display: block; text-align: end;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprDepart }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprClient">
                        <mat-header-cell (click)="sortTransactionsByClient()" *matHeaderCellDef style="cursor:pointer;margin-left:0px;" > Client </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprClient }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprValue">
                        <mat-header-cell (click)="sortTransactionsByValue()" *matHeaderCellDef style="cursor:pointer; display:block; text-align:right; padding-right: 20px;"> Value </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px; display: block; text-align: end;" *matCellDef="let aprTransaction">
                          <div *ngIf="checkIfApprovedOrNotReviewed(aprTransaction) == true"> {{aprTransaction.tranAprValue | number : '1.2-2'}} </div>
                          <div *ngIf="checkIfApprovedOrNotReviewed(aprTransaction) == false"> 0.00 </div> 
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="bookingReference">
                          <mat-header-cell (click)="sortTransactionsByBookingReference()" *matHeaderCellDef style="cursor:pointer; display:block; text-align:left; padding-right: 30px;"> SinGS Ref </mat-header-cell>
                          <mat-cell style="font-weight:bold; padding: 10px; text-align: left; margin-top: 0px;" *matCellDef="let aprTransaction">
                            <div style="display:flex !important;">
                              <div *ngIf="aprTransaction.bookingReference != null" style="flex:1 !important; width: 120px !important; font-size: 15px !important; padding-top: 10px !important;">
                                <input matInput [(ngModel)]="aprTransaction.bookingReference" placeholder="SinGS Ref" style="font-weight: bold; width: 120px; padding-top:5px;" maxlength="12">
                              </div>   
                              <button *ngIf="aprTransaction.bookingReference != null" mat-button [matMenuTriggerFor]="menu" style="margin-right: 5px; margin-left: 5px; color:black;">
                                <mat-icon>arrow_drop_down</mat-icon>
                              </button>
                              <mat-menu #menu="matMenu" class="matMenuTransactions">
                                <div *ngIf="aprTransaction.bookingRefOther != '' && aprTransaction.bookingRefOther != null && aprTransaction.bookingRefOther.length > 0">
                                  <h3 style="color:white; background:#4D5FD1; padding-left: 16px; padding-right: 16px; padding-bottom: 20px; padding-top: 20px; margin:0px; text-align: center;"> Suggested Booking(s) </h3>
                                  <mat-divider></mat-divider>
                                  <ng-container *ngFor="let bkReference of aprTransaction.bookingRefOther.split(','); let last = last">
                                    <button style="color: black; font-weight: bold;" mat-menu-item (click)="selectedBookingReference(aprTransaction, bkReference)" [value]="bkReference">
                                      {{ bkReference }}
                                    </button>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                  </ng-container>
                                  <mat-divider></mat-divider>
                                </div>
                                <button mat-menu-item (click)="resetBookingReference(aprTransaction)" matTooltip="Reset" style="color: black; font-weight: bold;">
                                  <mat-icon>refresh</mat-icon> Reset SinGS Reference
                                </button>
                              </mat-menu>
                              <button *ngIf="aprTransaction.bookingReference == null" class="buttonClass" (click)="addBookingReference(aprTransaction)" mat-raised-button style="padding-left:5px; padding-right:10px;" color="primary">
                                <mat-icon>add</mat-icon> SinGS Reference
                              </button>
                            </div>
                          </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="matchType">
                          <mat-header-cell (click)="sortTransactionsByMatchType()" *matHeaderCellDef style="cursor:pointer; display:block; text-align:left; padding-right: 30px;"> Match Type </mat-header-cell>
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.matchType }} </mat-cell>
                      </ng-container> 
                      <ng-container matColumnDef="tranAprViewState">
                        <mat-header-cell (click)="sortTransactionsByState()" *matHeaderCellDef class="tranHeader-cell"> State </mat-header-cell>
                        <mat-cell *matCellDef="let aprTransaction" class="tranCell">
                          <mat-select [(ngModel)]="aprTransaction.tranAprViewState" (selectionChange)="changeTransactionState(aprTransaction)" class="tranSelect">
                            <mat-option *ngFor="let option of transactionStateOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="tapsTransactionApprovalColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: tapsTransactionApprovalColumns;"></mat-row>
                    </div>

                    <div *ngIf="onBookingPlatform == false">
                      <ng-container matColumnDef="tranAprAgent">
                        <mat-header-cell (click)="sortTransactionsByAgent()" *matHeaderCellDef style="cursor:pointer;"> Agent </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprAgent }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprAgentTapsId">
                        <mat-header-cell (click)="sortTransactionsByAgentTaps()" *matHeaderCellDef style="cursor:pointer;"> TAPs<br>Identi. </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprAgentTapsId }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprSupplier">
                        <mat-header-cell (click)="sortTransactionsBySupplier()" *matHeaderCellDef style="margin-left:30px; cursor:pointer;"> Supplier </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;  margin-left: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprSupplier }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprSupTapsId">
                        <mat-header-cell (click)="sortTransactionsBySupplierTaps()" *matHeaderCellDef style="margin-left:15px; cursor:pointer;"> TAPs<br>Identi. </mat-header-cell>
                        <mat-cell *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprSupTapsId }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprRef">
                        <mat-header-cell (click)="sortTransactionsByRef()" *matHeaderCellDef style="margin-left:15px; cursor:pointer;"> Supplier Ref </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px; display: block; text-align: end;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprRef }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprDepart">
                        <mat-header-cell (click)="sortTransactionsByDepart()" *matHeaderCellDef style="cursor:pointer; display: block; text-align: end; margin-right: 20px;"> Depart </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px; display: block; text-align: end;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprDepart }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprClient">
                        <mat-header-cell (click)="sortTransactionsByClient()" *matHeaderCellDef style="cursor:pointer;margin-left:0px;" > Client </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let aprTransaction"> {{ aprTransaction.tranAprClient }} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprValue">
                        <mat-header-cell (click)="sortTransactionsByValue()" *matHeaderCellDef style="cursor:pointer; display:block; text-align:right; padding-right: 30px;"> Value </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px; display: block; text-align: end;" *matCellDef="let aprTransaction">
                          <div *ngIf="checkIfApprovedOrNotReviewed(aprTransaction) == true"> {{aprTransaction.tranAprValue | number : '1.2-2'}} </div>
                          <div *ngIf="checkIfApprovedOrNotReviewed(aprTransaction) == false"> 0.00 </div> 
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranAprViewState">
                        <mat-header-cell (click)="sortTransactionsByState()" *matHeaderCellDef class="tranHeader-cell"> State </mat-header-cell>
                        <mat-cell *matCellDef="let aprTransaction" class="tranCell">
                          <mat-select [(ngModel)]="aprTransaction.tranAprViewState" (selectionChange)="changeTransactionState(aprTransaction)" class="tranSelect">
                            <mat-option *ngFor="let option of transactionStateOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="tapsTransactionApprovalNoBookingColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: tapsTransactionApprovalNoBookingColumns;"></mat-row>
                    </div>

                  </mat-table>
                  <h3 style="margin-top:30px; margin-bottom:40px;">
                    <span style="color:#4D5FD1">*</span> Please ensure that all approvals are saved before 4pm on Wednesday
                  </h3>
                  <h3>
                    I hereby certify the above release of funds is due in accordance with the TTA trust deed document and in compliance with the TTA code of conduct.<br><br>
                    I hereby certify that all client funds have been correctly banked into the trust account for this release and have cleared into the trust account in readiness for the payment I am about to authorise.
                  </h3>
                  <h3 style="display:block;">
                    <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px; background-color:#ce983a; color:white;" (click)="saveAndCloseTransactionApproval()">Save & Close</button>
                    <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="warn" (click)="cancelTheseChanges()">Cancel These Changes</button>
                    <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="warn" (click)="cancelAllChanges()">Cancel All Changes</button>
                  </h3>
                </div>
                <div *ngIf="transactionAvailability && !transactionViewIfAvailable">                    
                  <h3>
                    There were no transactions posted to the system for this review period.<br>
                    Please refresh the page in case of 'Late Adjustment' uploads.<br><br>
                  </h3>
                  <button class="buttonClass" (click)="switchView('homePage')" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" >
                    Home
                  </button>
                </div>
                <div *ngIf="!transactionAvailability">                    
                    <h3>
                      System available from Saturday 9:00-AM to Wednesday 4:00-PM.<br>
                      You are advised that TAPS is now closed and any unconfirmed transactions will not have been approved.<br>
                      Please go to Reporting.<br><br>
                    </h3>
                    <button class="buttonClass" (click)="switchView('homePage')" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" >
                      Home
                    </button>
                </div>
              </div>
            </div>
            <!-- Export or Import Transactions Member Page -->
            <div *ngIf="listOfPages[16].value" class="taps-sidenav-content">
                  <h1>Export or Import Transactions</h1>
                  <div class="taps-insidenav-content">
                    <div *ngIf="transactionAvailability && transactionViewIfAvailable">
                      <h2 style="margin-bottom: 0px; font-weight:bold; font-size:19px;">Export to Excel</h2>
                      <h3>
                        <p>Use this function to export transactions for the current period to an Excel spreadsheet. You may then edit manually and enter a Reason Code.</p>
                        <p>These transactions must be imported back into the system before the review period closes Wednesday 16.00pm. Items imported will overwrite any existing items already within the system.</p>
                        <div style="margin:10px;">
                          <button class="buttonClass" mat-raised-button color="primary" (click)="memberExportToExcelTransactionsForReview()">Export to Excel</button>
                        </div>
                      </h3>
                      <h2 style="margin-bottom: 0px; margin-top:40px; font-weight:bold; font-size:19px;">Import from Excel</h2>
                      <h3>
                        <p>To import your saved excel spreadsheet click the import button below. This will overwrite any items already in the system. You may only import a file while the review period is open. Monday 10.00am to Wednesday 16.00pm.</p>
                        <p>Please ensure all information is filled in and where payment is adjusted, you MUST specify a reason code. Failure to do will result in the file being rejected.</p>
                        <div style="margin:10px;">
                          <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="importAgentReviewedTransactions($event,false)"  accept=".xlsx" multiple="false" />
                          <button [disabled]="false" mat-raised-button color="primary" (click)="csvInput1.click()" matTooltip="Upload File">
                            Import File
                          </button>
                        </div>
                      </h3>
                    </div>
                    <div *ngIf="transactionAvailability && !transactionViewIfAvailable">                    
                      <h3>
                        There were no transactions posted to the system for this review period.<br>
                        Please refresh the page in case of 'Late Adjustment' uploads.<br><br>
                      </h3>
                      <button class="buttonClass" (click)="switchView('homePage')" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" >
                        Home
                      </button>
                    </div>
                    <div *ngIf="!transactionAvailability">
                      <h3>
                        System available from Saturday 9:00-AM to Wednesday 4:00-PM.<br>
                        You are advised that TAPS is now closed and any unconfirmed transactions will not have been approved.<br>
                        Please go to Reporting.<br><br>
                      </h3>
                      <button class="buttonClass" (click)="switchView('homePage')" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" >
                        Home
                      </button>
                    </div>
                  </div>
            </div>
            <!-- Suppliers Page -->
            <div *ngIf="listOfPages[17].value" class="taps-sidenav-content">
                  <h1>
                    <button *ngIf="adminCameFromAgentManagement == true" class="buttonClass" 
                    mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('agentPage')">
                      <mat-icon>arrow_back</mat-icon>
                    </button>
                    Suppliers <span *ngIf="adminCameFromAgentManagement == true"> for {{viewAgentName}} ({{viewAgentTradeCode}})</span>
                    <button [disabled]="activeSuppliersListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="memberExportSupplierList()" >
                      Export to Excel
                    </button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 0px;">
                      <h3>Included Suppliers ({{activeSuppliersListData.data.length}})</h3>
                    </h3>
                    <div name="Suppliers Page List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="supplierName">
                            <mat-header-cell *matHeaderCellDef (click)="sortSupplierListByName(false)" style="cursor:pointer"> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierTapsIdenti">
                            <mat-header-cell *matHeaderCellDef (click)="sortSupplierListByTradeCode(false)" style="cursor:pointer"> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierTelephone">
                            <mat-header-cell *matHeaderCellDef> Telephone </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierAddress">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center;"> Email </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="suppliersListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8"  style="height: fit-content;">
                          <table mat-table [dataSource]="activeSuppliersListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="supplierName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let supplier">{{supplier.supplierNameM}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierTapsIdenti">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.misttaCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierTelephone">
                              <mat-header-cell *matHeaderCellDef> Telphone </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.telephoneTAPS}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierAddress">
                              <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;  color:#4D5FD1 !important; cursor:pointer;" *matCellDef="let supplier" (click)="openEmail(supplier.emailTAPS)"> {{supplier.emailTAPS}} </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: suppliersListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                    <h3 style="margin-bottom: 0px; margin-top: 30px !important;">
                      <h3>Exclude Suppliers ({{inactiveSuppliersListData.data.length}})</h3>
                    </h3>
                    <div name="Suppliers Page List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="supplierName">
                            <mat-header-cell *matHeaderCellDef (click)="sortSupplierListByName(true)" style="cursor:pointer"> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierTapsIdenti">
                            <mat-header-cell *matHeaderCellDef (click)="sortSupplierListByTradeCode(true)" style="cursor:pointer"> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierTelephone">
                            <mat-header-cell *matHeaderCellDef> Telephone </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="supplierAddress">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center;"> Email </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="suppliersListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8"  style="height: fit-content;">
                          <table mat-table [dataSource]="inactiveSuppliersListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="supplierName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let supplier">{{supplier.supplierNameM}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierTapsIdenti">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.misttaCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierTelephone">
                              <mat-header-cell *matHeaderCellDef> Telphone </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let supplier"> {{supplier.telephoneTAPS}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplierAddress">
                              <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;  color:#4D5FD1 !important; cursor:pointer;" *matCellDef="let supplier" (click)="openEmail(supplier.emailTAPS)"> {{supplier.emailTAPS}} </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: suppliersListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>                
            </div>
            <!-- Reporting Member Page -->
            <div *ngIf="listOfPages[18].value" class="taps-sidenav-content">
                  <h1>
                    <div *ngIf="switchToGeneratedReportsView == false">
                      Reporting
                      <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="memberReport()">
                        Find
                      </button>                   
                      <button [disabled]="this.tapsReportMemberListData.data.length == 0"  class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="memberExportReport()">
                        Export to Excel
                      </button>
                      <button class="buttonClass" [matBadge]="generatedReportBadge" matBadgeColor="warn" [matBadgeHidden]="generatedReportBadgeHidden" mat-raised-button style="margin-left:10px;" [color]="generatedReportTitle == 'Generated Reports' ? 'primary' : 'accent'" (click)="switchToGeneratedReports('tapsMemberReport')" >
                        {{generatedReportTitle}}  
                      </button>
                    </div>
                    <div *ngIf="switchToGeneratedReportsView == true">
                      Generated Reports
                      <button class="buttonClass" matTooltip="Refresh Generated Reports List" mat-raised-button style="margin-left:10px;" color="primary" (click)="setupListOfTapsReporting('tapsMemberReport')" >
                        Refresh
                      </button>
                      <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="switchToGeneratedReports('tapsMemberReport')" >
                        Back to Reporting
                      </button>
                    </div>
                  </h1>
                  <div class="taps-insidenav-content">
                    <div *ngIf="switchToGeneratedReportsView == false">
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Agent<span style="color:#4D5FD1">*</span>	
                        </div>            	
                        <div style="display:block">
                          <mat-form-field style="width: 600px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Agent" [(ngModel)]="selectedAgentTradeCode" style="vertical-align: middle;">
                              <mat-option [value]="userTradeCode">
                                {{userCompany}} {{userName}} ({{userTradeCode}})
                              </mat-option>
                              <mat-option *ngFor="let childAgentTradeCode of listOfChildrenAgentsTradeCode" [value]="childAgentTradeCode.tradeCode">
                                {{childAgentTradeCode.branchName}} ({{childAgentTradeCode.tradeCode}} <span *ngIf="childAgentTradeCode.isTapsReg == 'yes'"> TAPS </span><span *ngIf="childAgentTradeCode.isTapsReg != 'yes'"> Not TAPS </span>)
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-checkbox style="padding-left: 10px; margin-bottom: 10px;" [(ngModel)]='memberIncludeBranches'>Include Branches</mat-checkbox>
                        </div>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Supplier<span style="color:#4D5FD1">*</span>	
                        </div>
                        <div fxFlex="30">
                          <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                            <mat-select ngModel required name="tradeCode" [(ngModel)]="selectedSupplierTradeCode" placeholder="Supplier">
                              <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminSupplierListString" (keyup)="filterMemberSupplierListSelect()">
                              </mat-form-field>
                              <mat-option value="all">
                                All
                              </mat-option>
                              <mat-option *ngFor="let supplier of filteredAdminSupplierListData.data" [value]="supplier.misttaCode ? supplier.misttaCode : 'N/A'" (click)="filterAdminSupplierListString = ''; filterMemberSupplierListSelect();">
                                {{supplier.supplierNameM + " (" + (supplier.misttaCode ? supplier.misttaCode : 'N/A') + " - " + supplier.isTapsReg.toUpperCase() +" TAPS)"}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Collection Period	
                        </div>
                        <div fxFlex="30">
                          <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Period" style="vertical-align: middle;" [(ngModel)]="reportingDatePeriod"  (selectionChange)="setupDatesForReportingPeriod()">
                              <mat-option *ngFor="let period of reportingDatePeriods" [value]="period">
                                {{period.displayRow}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Date Range<span style="color:#4D5FD1">*</span> 
                        </div>
                        <div style="display: block; max-width: fit-content !important;">
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="reportingDateFrom">
                            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromPicker ></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="toPicker" [(ngModel)]="reportingDateTo">
                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                            <mat-datepicker #toPicker ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>                   
                    <h3 style="margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Transactions	
                        </div>
                        <div fxFlex="30">
                          {{tapsReportMemberListData.data.length}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Original Total	
                        </div>
                        <div fxFlex="30">
                          {{memberReportOriginalTotal | number : '1.2-2'}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Adjustments	
                        </div>
                        <div fxFlex="12">
                          - <span *ngIf="memberReportAdjustmentsTotal < 0">(</span>{{memberReportAdjustmentsTotal | number : '1.2-2'}}<span *ngIf="memberReportAdjustmentsTotal < 0">)</span>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:30px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Collection Total	
                        </div>
                        <div fxFlex="30">
                          {{memberReportCollectionTotal | number : '1.2-2'}}
                        </div>
                      </div>
                    </h3>
                    <div name="Reporting (Member) List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="tranSupplier">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportBySupplier()" style="cursor:pointer;"> Supplier</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranSupTapsId">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportBySupplierTapsId()" style="cursor:pointer;"> TAPs Identi. </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranAgent">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByAgent()" style="cursor:pointer;"> Agent </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranTapsId">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByTapsId()" style="cursor:pointer;"> TAPs Identi. </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranRef">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByRef()" style="cursor:pointer;"> Ref </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranDepart">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByDepart()" style="cursor:pointer;"> Depart </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranClient">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByClient()" style="cursor:pointer;"> Client </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranPayment">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByCollectionDate()" style="cursor:pointer;"> Collection </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranValue">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByValue()" style="cursor:pointer;"> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranAdjust">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByAdjust()" style="cursor:pointer;"> Adjusted </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranCollectedValue">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByCollection()" style="cursor:pointer;"> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranCode">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByCode()" style="cursor:pointer;"> Code </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranName">
                            <mat-header-cell *matHeaderCellDef (click)="sortMemberReportByName()" style="cursor:pointer; display:block; text-align: center; "> Reason </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsReportMemberListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsReportMemberListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="tranSupplier">
                              <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;  margin-left: 10px;" *matCellDef="let transaction"> {{transaction.tranSupplier}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranSupTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranSupTapsId}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranAgent}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPs Identi. </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranTapsId}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef> Ref </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranRef}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranDepart}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranClient}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranPayment">
                              <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranCollectionDate}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAdjust">
                              <mat-header-cell *matHeaderCellDef> Adjusted </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranAdjust}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCollectedValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranCollectedValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCode">
                              <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranName">
                              <mat-header-cell *matHeaderCellDef> Reason </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let transaction"> {{transaction.tranName}} </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsReportMemberListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                    </div>
                    <div *ngIf="switchToGeneratedReportsView == true">
                      <div *ngIf="attachedDocs.data.length > 0">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">
                              <mat-header-cell *matHeaderCellDef> File Name</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-header-cell *matHeaderCellDef> Size </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="download">
                              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                          </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="attachedDocs" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">                      
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.name }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="download">
                              <mat-cell *matCellDef="let data">
                                <button matTooltip="Download" class="matIcon" style="float: right;" type="button" mat-mini-fab color="primary" (click)="downloadDocument(data,'tapsMemberReport','')" [class.mat-elevation-z0]="true">
                                  <mat-icon>file_download</mat-icon>
                                </button>
                              </mat-cell>
                            </ng-container>                          
                            <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                          </table>
                        </div>
                      </div>
                      <div *ngIf="attachedDocs.data.length == 0">
                        <h2> No records found. </h2>
                      </div>
                    </div>
                  </div>
            </div>
            <!-- Analysis Reporting Member Page -->
            <div *ngIf="listOfPages[19].value" class="taps-sidenav-content">
                  <h1>
                    Analysis Reporting
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" color="primary" (click)="memberExportAnalysisReport()">
                      Export to Excel
                    </button>   
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Agent<span style="color:#4D5FD1">*</span>		
                        </div>
                        <div style="display:block">
                          <mat-form-field style="width: 600px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select [(ngModel)]="selectedAgentTradeCode" placeholder="Agent" style="vertical-align: middle;">
                              <mat-option value="{{userTradeCode}}">{{userCompany}} {{userName}} ({{userTradeCode}})</mat-option>
                              <mat-option *ngFor="let childAgentTradeCode of listOfChildrenAgentsTradeCode" [value]="childAgentTradeCode.tradeCode">
                                {{childAgentTradeCode.branchName}} ({{childAgentTradeCode.tradeCode}})
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-checkbox style="padding-left: 10px; margin-bottom: 10px;" [(ngModel)]='memberIncludeBranches'>Include Branches</mat-checkbox>
                        </div>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Supplier<span style="color:#4D5FD1">*</span>		
                        </div>
                        <div fxFlex="30">
                          <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                            <mat-select ngModel required name="tradeCode" [(ngModel)]="selectedSupplierTradeCode" placeholder="Supplier">
                              <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminSupplierListString" (keyup)="filterMemberSupplierListSelect()">
                              </mat-form-field>
                              <mat-option value="all">
                                All
                              </mat-option>
                              <mat-option *ngFor="let supplier of filteredAdminSupplierListData.data" [value]="supplier.misttaCode ? supplier.misttaCode : 'N/A'" (click)="filterAdminSupplierListString = ''; filterMemberSupplierListSelect();">
                                {{supplier.supplierNameM + " (" + (supplier.misttaCode ? supplier.misttaCode : 'N/A') + " - " + supplier.isTapsReg.toUpperCase() +" TAPS)"}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>                    
                    <h3 style="margin-top: 20px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="12">
                          Analysis Year<span style="color:#4D5FD1">*</span>		
                        </div>
                        <div style="display:block">
                          <mat-form-field style="width: 100px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select [(ngModel)]="memberAnalysisReportYear" placeholder="Year" style="vertical-align: middle;">
                              <mat-option *ngFor="let year of analysisYearsList" [value]="year">
                                {{year}}
                              </mat-option> 
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>                    
                  </div>                
            </div>
            <!-- Upload Preview File Page -->
            <div *ngIf="listOfPages[20].value" class="taps-sidenav-content">
                  <h1>
                    Upload File <span *ngIf="supplierUploadedFile == true"> (State : {{viewTransactionState}}) <h1>Please scroll down to <span style="color: #ce983a;">'Accept'</span> or <span style="color: red;">'Reject'</span> your document.</h1> </span>                  
                  </h1>
                  <div class="taps-insidenav-content">
                    <div *ngIf="supplierUploadedFile == false">
                      <h3 style="margin-bottom: 0px;">
                        <p>The file you are uploading is for Agent processing from {{uploadFromDate}} to {{uploadToDate}}.</p>
                      </h3>
                      <h3 style="margin-top: 30px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="uploadFilePreviewPage($event)"  accept=".xlsx,.csv,.txt,.xls" multiple="false" />
                          <button mat-raised-button color="primary" (click)="csvInput1.click()">
                            Upload File
                          </button>
                        </div>
                      </h3>
                      <h3 style="margin-top: 30px;">
                        <p>Submit a file for processing.</p>
                        <p>Use the Browse button to locate the file you wish to upload and then click the Upload File button.</p>
                        <p style="font-weight: bolder;"><span style="color:#4D5FD1">*</span>Do not close your browser during the process - a report will be presented to you when the process is completed.</p>
                      </h3>
                    </div>
  
                    <div *ngIf="supplierUploadedFile == true">
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            TAPS ID	
                          </div>
                          <div fxFlex="30">
                            {{viewAdjustmentUploadTapsId}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Supplier	
                          </div>
                          <div fxFlex="30">
                            {{viewAdjustmentUploadSupplierName}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Upload Date	
                          </div>
                          <div fxFlex="30">
                            {{viewUploadDate}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 20px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Quantity	
                          </div>
                          <div fxFlex="30">
                            {{viewNumOfTransactions}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Value	
                          </div>
                          <div fxFlex="30">
                            {{viewValue | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Adjustments	
                          </div>
                          <div fxFlex="30">
                            {{viewAdjustments | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Agent Review	
                          </div>
                          <div fxFlex="30">
                            {{viewAgentReview}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px; margin-top: 20px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            To Pay	
                          </div>
                          <div fxFlex="30">
                            {{viewToPay | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:30px; margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15">
                            Payment Due	
                          </div>
                          <div fxFlex="30">
                            {{viewPaymentDue}}
                          </div>
                        </div>
                      </h3>
                      <h2 style="margin-bottom:20px; margin-top: 10px; font-weight:bold;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="30">
                            Accepted Transactions ({{tapsUploadTransactionData.data.length}})
                          </div>
                        </div>
                      </h2>
                      <div name="Upload History File (Accepted Transactions)">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranWarning">
                              <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAdjust">
                              <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCollectedValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCode">
                              <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranName">
                              <mat-header-cell *matHeaderCellDef > Name </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="tapsUploadTransactionColumns"></mat-header-row>
                          </table>
                          </div>
                          <div class="example-container mat-elevation-z8" style="height: fit-content;">
                            <table mat-table [dataSource]="tapsUploadTransactionData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                              <ng-container matColumnDef="tranAgent">
                                <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                                <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranTapsId">
                                <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranRef">
                                <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranDepart">
                                <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranClient">
                                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranWarning">
                                <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                                <mat-cell style="font-weight:bold;" *matCellDef="let upload"> {{upload.tranWarning}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranAdjust">
                                <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAdjust}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCollectedValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCollectedValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCode">
                                <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCode}} </mat-cell>
                              </ng-container>                        
                              <ng-container matColumnDef="tranName">
                                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranName}} </mat-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: tapsUploadTransactionColumns;"></mat-row>
                            </table>
                          </div>
                          <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <div fxFlex="90">
                                Unapproved - {{viewAdjustments | number : '1.2-2'}}	
                              </div>
                            </div>
                          </h3>
                          <h3 style="margin-bottom:10px; margin-top: 10px; text-align:end;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <div fxFlex="90">
                                Approved - {{viewToPay | number : '1.2-2'}}
                              </div>
                            </div>
                          </h3>
                          <h3 style="margin-bottom:30px; margin-top: 10px; text-align:end;">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <div fxFlex="90">
                                Total - {{viewOverallAcceptedAmount | number : '1.2-2'}}
                              </div>
                            </div>
                          </h3>
                      </div>
                      <h2 style="margin-bottom:20px; margin-top: 40px; font-weight:bold;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="30">
                            Rejected Transactions ({{tapsUploadTransactionRejectedData.data.length}})
                          </div>
                        </div>
                      </h2>
                      <div name="Upload History File (Rejected Transactions)">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranFailure">
                              <mat-header-cell *matHeaderCellDef > Failure </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="tapsUploadRejectedTransactionColumns"></mat-header-row>
                          </table>
                          </div>
                          <div class="example-container mat-elevation-z8" style="height: fit-content;">
                            <table mat-table [dataSource]="tapsUploadTransactionRejectedData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                              <ng-container matColumnDef="tranAgent">
                                <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranTapsId">
                                <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranRef">
                                <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranDepart">
                                <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranClient">
                                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>                       
                              <ng-container matColumnDef="tranFailure">
                                <mat-header-cell *matHeaderCellDef> Failure </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranFailure}} </mat-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: tapsUploadRejectedTransactionColumns;"></mat-row>
                            </table>
                      </div>  
  
                      <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="90">
                            Rejected - {{viewOverallRejectedAmount | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
  
                      <div style="margin:10px;">
                        <button class="buttonClass" style="margin:10px; background-color:#ce983a; color:white;" mat-raised-button (click)="acceptSupplierUpload()" >Accept</button>
                        <button class="buttonClass" style="margin:10px;" mat-raised-button color="warn" (click)="rejectSupplierUpload()">Reject</button>
                      </div>
                    </div>  
                  </div> 
  
                  </div>                
            </div>
            <!-- Upload History Page -->
            <div *ngIf="listOfPages[21].value" class="taps-sidenav-content">
                  <h1>
                    Upload History
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="listUploadedFilesOfSupplier(false)">
                      Find
                    </button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="20">
                          Upload Period
                        </div>
                        <div fxFlex="30">
                          <mat-form-field style="width: 500px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Period" style="vertical-align: middle;" [(ngModel)]="selectedDatePeriod"  (selectionChange)="setupDatesForUploadedPeriod(false)">
                              <mat-option *ngFor="let period of datePeriods" [value]="period">
                                {{period.displayRow}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="20">
                          Date Range<span style="color:#4D5FD1">*</span> 
                        </div>
                        <div style="display: block; max-width: fit-content !important;">
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="selectedDateFrom">
                            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromPicker ></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="toPicker" [(ngModel)]="selectedDateTo">
                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                            <mat-datepicker #toPicker ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tapsUploadHistoryListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Upload History List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="uploadPeriod">
                            <mat-header-cell style="margin-left: 10px;" *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByDate()" style="cursor:pointer"> Upload</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranValue">
                            <mat-header-cell *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByValue()" style="cursor:pointer"> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranState">
                            <mat-header-cell *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByState()" style="cursor:pointer"> State </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranFailure">
                            <mat-header-cell *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByFailure()" style="cursor:pointer"> Failure </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="numOfTransactions">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center;" (click)="sortTapsUploadHistoryListDataByTransactions()" style="cursor:pointer"> No. of Transactions </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsUploadHistoryListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsUploadHistoryListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="uploadPeriod">
                              <mat-header-cell *matHeaderCellDef> Upload </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding-left: 15px !important; color:#4D5FD1 !important; cursor:pointer; margin-left: 10px;"*matCellDef="let upload" (click)="openSupplierUploadedFilePage(upload)">
                                 {{upload.viewUploadDate}} 
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 5px;" *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranState">
                              <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 5px;" *matCellDef="let upload"> 
                                <span *ngIf="upload.tranState == 'Imported'">Review</span>
                                <span *ngIf="upload.tranState == 'Importing'">Imported</span>
                                <span *ngIf="upload.tranState != 'Imported' && upload.tranState != 'Importing'">{{upload.tranState}} </span>
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranFailure">
                              <mat-header-cell *matHeaderCellDef> Failure</mat-header-cell>
                              <mat-cell style="font-weight:bold; padding: 5px;" *matCellDef="let upload"> {{upload.tranFailure}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="numOfTransactions">
                              <mat-header-cell *matHeaderCellDef> No. of Transactions </mat-header-cell>
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 5px;" *matCellDef="let upload"> {{upload.numOfTransactions}} </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsUploadHistoryListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>                
            </div>
            <!-- Pull Down File Page -->
            <div *ngIf="listOfPages[22].value" class="taps-sidenav-content">
                  <h1>Pull Down File</h1>
                  <div class="taps-insidenav-content">
                    <h3>Please click find after screen refreshes to display results</h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="20">
                          Upload File<span style="color:#4D5FD1">*</span> 
                        </div>
                        <div fxFlex="50">
                          <mat-form-field style="width: 500px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Period of Upload (TransactionIDs)" style="vertical-align: middle;" [(ngModel)]="selectedPullDownFilePeriod" (selectionChange)="checkIfMultipleCommittedFiles()">                          
                              <mat-option *ngFor="let period of pullDownFilePeriods" [value]="period">
                                {{period.displayRow}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3 *ngIf="includesPullDownMultipleTransactionFiles">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="20">
                          Select Committed File<span style="color:#4D5FD1">*</span> 
                        </div>
                        <div fxFlex="50">
                          <mat-form-field style="width: 500px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Committed File" style="vertical-align: middle;" [(ngModel)]="selectedPullDownCommittedFile">                          
                              <mat-option *ngFor="let tapsUpload of pullDownCommittedFiles" [value]="tapsUpload">
                                {{tapsUpload.transactionID}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3 style="display: block;">
                      <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" (click)="getPullDownFile('Excel')" >Download Excel Format</button>
                      <button class="buttonClass" mat-raised-button style="margin-bottom: 20px; margin-left:10px;" color="primary" (click)="getPullDownFile('Legacy')" >Download Legacy Format</button>
                    </h3>
                  </div>
            </div>
            <!-- Agents Page -->
            <div *ngIf="listOfPages[23].value" class="taps-sidenav-content">
                  <h1>Agents
                    <button [disabled]="tapsIncludedAgentPageListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="supplierExportAgentsList()" >
                      Export to Excel
                    </button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 0px;">
                      <h3>Included Agents ({{tapsIncludedAgentPageListData.data.length}})</h3>
                    </h3>
                    <div name="Agent Page List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="agentPageListName">
                            <mat-header-cell *matHeaderCellDef (click)="sortAgentByName(false)" style="cursor:pointer"> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentPageListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef (click)="sortAgentByTradeCode(false)" style="cursor:pointer"> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentPageListTelephone">
                            <mat-header-cell *matHeaderCellDef> Telephone</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentPageListEmail">
                            <mat-header-cell *matHeaderCellDef > Email </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsAgentPageListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsIncludedAgentPageListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="agentPageListName">
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let agent">{{agent.branchName}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentPageListTapsIdenti">
                              <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let agent"> {{agent.tradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentPageListTelephone">
                              <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let agent"> {{agent.telephoneTAPS}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentPageListEmail">
                              <mat-cell style="font-weight:bold; padding: 10px; color:#4D5FD1 !important; cursor:pointer;" *matCellDef="let agent" (click)="openEmail(agent.emailTAPS)"> 
                                {{agent.emailTAPS}} 
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsAgentPageListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                    <h3 style="margin-bottom: 0px;">
                      <h3>Exclude Agents ({{tapsExcludedAgentPageListData.data.length}})</h3>
                    </h3>
                    <div name="Agent Page List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="agentPageListName">
                            <mat-header-cell *matHeaderCellDef (click)="sortAgentByName(true)" style="cursor:pointer"> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentPageListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef (click)="sortAgentByTradeCode(true)" style="cursor:pointer"> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentPageListTelephone">
                            <mat-header-cell *matHeaderCellDef> Telephone</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="agentPageListEmail">
                            <mat-header-cell *matHeaderCellDef > Email </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsAgentPageListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsExcludedAgentPageListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="agentPageListName">
                              <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let agent">{{agent.branchName}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentPageListTapsIdenti">
                              <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let agent"> {{agent.tradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentPageListTelephone">
                              <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let agent"> {{agent.telephoneTAPS}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="agentPageListEmail">
                              <mat-cell style="font-weight:bold; padding: 10px; color:#4D5FD1 !important; cursor:pointer;" *matCellDef="let agent" (click)="openEmail(agent.emailTAPS)"> 
                                {{agent.emailTAPS}} 
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsAgentPageListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>
            </div>
            <!-- Reporting Supplier Page -->
            <div *ngIf="listOfPages[24].value" class="taps-sidenav-content">
                  <h1>
                    <div *ngIf="switchToGeneratedReportsView == false">
                      Reporting
                      <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="supplierReport()">
                        Find
                      </button>
                      <button [disabled]="tapsSupplierReportListData.data.length == 0" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary"  (click)="supplierExportReport()">
                        Export to Excel
                      </button>
                      <button class="buttonClass" [matBadge]="generatedReportBadge" matBadgeColor="warn" [matBadgeHidden]="generatedReportBadgeHidden" mat-raised-button style="margin-left:10px;" [color]="generatedReportTitle == 'Generated Reports' ? 'primary' : 'accent'" (click)="switchToGeneratedReports('tapsSupplierReport')" >
                        {{generatedReportTitle}}  
                      </button>
                    </div>
                    <div *ngIf="switchToGeneratedReportsView == true">
                      Generated Reports
                      <button class="buttonClass" matTooltip="Refresh Generated Reports List" mat-raised-button style="margin-left:10px;" color="primary" (click)="setupListOfTapsReporting('tapsSupplierReport')" >
                        Refresh
                      </button>
                      <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="switchToGeneratedReports('tapsSupplierReport')" >
                        Back to Reporting
                      </button>
                    </div>
                  </h1>
                  <div class="taps-insidenav-content">
                    <div *ngIf="switchToGeneratedReportsView == false">
                      <h3 style="margin-top:0px">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Agent<span style="color:#4D5FD1">*</span>	
                          </div>
                          <div fxFlex="30">
                            <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                              <mat-select ngModel required name="tradeCode" [(ngModel)]="selectedAgent" placeholder="Agent">
                                <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                                  <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminAgentsListString" (keyup)="filterSupplierAgentListSelect()">
                                </mat-form-field>
                                <mat-option value="All">
                                  All
                                </mat-option>
                                <mat-option *ngFor="let agent of filteredAdminAgentsListData.data" [value]="agent.tradeCode" (click)="filterAdminAgentsListString = ''; filterSupplierAgentListSelect();">
                                  {{agent.branchName + " (" + agent.tradeCode + " - " + agent.isTapsReg.toUpperCase() +" TAPS)"}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Upload Period	
                          </div>
                          <div fxFlex="30">
                            <mat-form-field style="width: 600px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select placeholder="Period" style="vertical-align: middle;" [(ngModel)]="reportingDatePeriod"  (selectionChange)="setupDatesForReportingPeriod()">
                                <mat-option *ngFor="let period of reportingDatePeriods" [value]="period">
                                  {{period.displayRow}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Date Range<span style="color:#4D5FD1">*</span> 
                          </div>
                          <div style="display: block; max-width: fit-content !important;">
                            <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                              <mat-label>From</mat-label>
                              <input matInput [matDatepicker]="fromPicker" [(ngModel)]="reportingDateFrom">
                              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                              <mat-datepicker #fromPicker ></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                              <mat-label>To</mat-label>
                              <input matInput [matDatepicker]="toPicker" [(ngModel)]="reportingDateTo">
                              <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                              <mat-datepicker #toPicker ></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Rejected Transactions	
                          </div>
                          <div fxFlex="30">
                            <mat-checkbox style="padding-left: 10px; margin-bottom: 10px;" [(ngModel)]='onlyRejectedTransactions'></mat-checkbox>
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Transactions	
                          </div>
                          <div fxFlex="30" style="padding-left: 10px;">
                            {{tapsSupplierReportListData.data.length}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Original Total	
                          </div>
                          <div fxFlex="30" style="padding-left: 10px;">
                            {{supplierReportOriginalTotal | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:0px;" style="padding-left: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Adjustments	
                          </div>
                          <div fxFlex="30">
                            - <span *ngIf="supplierReportAdjustmentsTotal < 0">(</span>{{supplierReportAdjustmentsTotal | number : '1.2-2'}}<span *ngIf="supplierReportAdjustmentsTotal < 0">)</span>
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:30px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="20">
                            Collection Total	
                          </div>
                          <div fxFlex="30" style="padding-left: 10px;">
                            {{supplierReportCollectionTotal | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <div name="Reporting (Operator) List">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByAgent()" style="cursor:pointer;"> Agent</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByTapsId()" style="cursor:pointer;"> TAPS ID </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByRef()" style="cursor:pointer;"> Ref. </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByDepart()" style="cursor:pointer;"> Depart </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByClient()" style="cursor:pointer;"> Client </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranPayment">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByPayment()" style="cursor:pointer;"> Payment </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranWarning">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByWarning()" style="cursor:pointer;"> Failure </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByValue()" style="cursor:pointer;"> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAdjust">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByAdjust()" style="cursor:pointer;"> Adjust </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCollectedValue">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByCollection()" style="cursor:pointer;"> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCode">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByCode()" style="cursor:pointer; display:block; text-align:center;"> Code </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranName">
                              <mat-header-cell *matHeaderCellDef (click)="sortSupplierReportByName()" style="cursor:pointer;"> Reason </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="tapsSupplierReportListColumns"></mat-header-row>
                          </table>
                          </div>
                          <div class="example-container mat-elevation-z8" style="height: fit-content;">
                            <table mat-table [dataSource]="tapsSupplierReportListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                              <ng-container matColumnDef="tranAgent">
                                <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranTapsId">
                                <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranRef">
                                <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranDepart">
                                <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranClient">
                                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranPayment">
                                <mat-header-cell *matHeaderCellDef> Payment </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranPayment}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranWarning">
                                <mat-header-cell *matHeaderCellDef> Failure </mat-header-cell>
                                <mat-cell style="font-weight:bold;" *matCellDef="let upload"> {{upload.tranWarning}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranAdjust">
                                <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAdjust}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCollectedValue">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCollectedValue | number : '1.2-2'}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="tranCode">
                                <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                                <mat-cell style="font-weight:bold; display:block; text-align:center;" *matCellDef="let upload"> {{upload.tranCode}} </mat-cell>
                              </ng-container>                        
                              <ng-container matColumnDef="tranName">
                                <mat-header-cell *matHeaderCellDef> Reason </mat-header-cell>
                                <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranName}} </mat-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: tapsSupplierReportListColumns;"></mat-row>
                            </table>
                          </div>
                      </div>
                    </div>
                    <div *ngIf="switchToGeneratedReportsView == true">
                      <div *ngIf="attachedDocs.data.length > 0">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">
                              <mat-header-cell *matHeaderCellDef> File Name</mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-header-cell *matHeaderCellDef> Size </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="download">
                              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                          </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="attachedDocs" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">                      
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.name }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="download">
                              <mat-cell *matCellDef="let data">
                                <button matTooltip="Download" class="matIcon" style="float: right;" type="button" mat-mini-fab color="primary" (click)="downloadDocument(data,'tapsSupplierReport','')" [class.mat-elevation-z0]="true">
                                  <mat-icon>file_download</mat-icon>
                                </button>
                              </mat-cell>
                            </ng-container>                          
                            <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                          </table>
                        </div>
                      </div>
                      <div *ngIf="attachedDocs.data.length == 0">
                        <h2> No records found. </h2>
                      </div>
                    </div>
                  </div>
            </div>
            <!-- Upload History File Screen Page -->
            <div *ngIf="listOfPages[25].value" class="taps-sidenav-content">
                  <h1>
                    <button *ngIf="loggedInUserType == 'Supplier'" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('uploadHistoryPage')">
                      <mat-icon>arrow_back</mat-icon>
                    </button>
                    <button *ngIf="loggedInUserType == 'Admin'" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('uploadHistoryAdmin')">
                      <mat-icon>arrow_back</mat-icon>
                    </button>
                    Upload File Screen 
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="downloadSupplierUploadFile()">
                      Export to Excel
                    </button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom:0px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Transaction ID	
                        </div>
                        <div fxFlex="30">
                          <span *ngIf="viewOriginalTransactionID == 'Original'"> 
                            {{viewTransactionID}}
                          </span> 
                          <span *ngIf="viewOriginalTransactionID != 'Original'"> 
                            {{viewOriginalTransactionID}}
                          </span>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px; margin-top: 10px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Upload Date	
                        </div>
                        <div fxFlex="30">
                          {{viewUploadDate}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px; margin-top: 20px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Quantity	
                        </div>
                        <div fxFlex="30">
                          {{viewNumOfTransactions}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px; margin-top: 10px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Value	
                        </div>
                        <div fxFlex="30">
                          {{viewValue | number : '1.2-2'}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px; margin-top: 10px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Adjustments	
                        </div>
                        <div fxFlex="30">
                          {{viewOverallAdjustments | number : '1.2-2'}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px; margin-top: 10px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Agent Review	
                        </div>
                        <div fxFlex="30">
                          {{viewAgentReview}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:0px; margin-top: 20px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          To Pay	
                        </div>
                        <div fxFlex="30">
                          {{viewToPay | number : '1.2-2'}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:30px; margin-top: 10px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Payment Due	
                        </div>
                        <div fxFlex="30">
                          {{viewPaymentDue}}
                        </div>
                      </div>
                    </h3>
                    <h2 style="margin-bottom:20px; margin-top: 10px; font-weight:bold;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="30">
                          Accepted Transactions ({{tapsUploadTransactionData.data.length}})
                        </div>
                      </div>
                    </h2>
                    <div name="Upload History File (Accepted Transactions)">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="tranAgent">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByAgent()" style="cursor:pointer;"> Agent</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranTapsId">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByTapsId()" style="cursor:pointer;"> TAPS ID </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranRef">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByRef()" style="cursor:pointer;"> Ref. </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranDepart">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByDepart()" style="cursor:pointer;"> Depart </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranClient">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByClient()" style="cursor:pointer;"> Client </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranWarning">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByWarning()" style="cursor:pointer;"> Warning </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranValue">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByValue()" style="cursor:pointer;"> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranAdjust">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByAdjust()" style="cursor:pointer;"> Adjust </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranCollectedValue">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByCollection()" style="cursor:pointer;"> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranCode">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByCode()" style="cursor:pointer;"> Code </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranName">
                            <mat-header-cell *matHeaderCellDef (click)="sortUploadTransactionByName()" style="cursor:pointer;"> Name </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsUploadTransactionColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsUploadTransactionData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                              <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranWarning">
                              <mat-header-cell *matHeaderCellDef> Warning </mat-header-cell>
                              <mat-cell style="font-weight:bold;" *matCellDef="let upload"> {{upload.tranWarning}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranAdjust">
                              <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAdjust}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCollectedValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCollectedValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranCode">
                              <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCode}} </mat-cell>
                            </ng-container>                        
                            <ng-container matColumnDef="tranName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranName}} </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsUploadTransactionColumns;"></mat-row>
                          </table>
                        </div>
                        <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="90">
                              Unapproved - {{viewAdjustments | number : '1.2-2'}}	
                            </div>
                          </div>
                        </h3>
                        <h3 style="margin-bottom:10px; margin-top: 10px; text-align:end;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="90">
                              Approved - {{viewToPay | number : '1.2-2'}}
                            </div>
                          </div>
                        </h3>
                        <h3 style="margin-bottom:30px; margin-top: 10px; text-align:end;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="90">
                              Total - {{viewOverallAcceptedAmount | number : '1.2-2'}}
                            </div>
                          </div>
                        </h3>
                    </div>
                    <h2 style="margin-bottom:20px; margin-top: 40px; font-weight:bold;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="30">
                          Rejected Transactions ({{tapsUploadTransactionRejectedData.data.length}})
                        </div>
                      </div>
                    </h2>
                    <div name="Upload History File (Rejected Transactions)">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="tranAgent">
                            <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranTapsId">
                            <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranRef">
                            <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranDepart">
                            <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranClient">
                            <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranFailure">
                            <mat-header-cell *matHeaderCellDef > Failure </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="tapsUploadRejectedTransactionColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsUploadTransactionRejectedData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="tranAgent">
                              <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranTapsId">
                              <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranRef">
                              <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranDepart">
                              <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranClient">
                              <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="tranValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                            </ng-container>                       
                            <ng-container matColumnDef="tranFailure">
                              <mat-header-cell *matHeaderCellDef> Failure </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranWarning}} </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: tapsUploadRejectedTransactionColumns;"></mat-row>
                          </table>
                      </div>  
                      <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="90">
                            Rejected - {{viewOverallRejectedAmount | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>  
                    </div>
                  </div>
            </div>
            <!-- Agent's View Page -->
            <div *ngIf="listOfPages[26].value" class="taps-sidenav-content">
                  <h1>
                    <button class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('agentManagementPage')">
                      <mat-icon>arrow_back</mat-icon>
                    </button> 
                    Agent View Page
                    <button mat-raised-button color="primary" style="margin:10px;" (click)="viewAgentHistory()" >History</button>
                    <button mat-raised-button color="primary" style="margin:10px;" (click)="viewAgentSupplierList()">Supplier</button>
                    <button mat-raised-button color="primary" style="margin:10px;" (click)="viewAgentBACSHistory()">BACS History</button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom:15px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50">
                          <h2 style="font-size:25px;">{{viewAgentName}}</h2>	
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:20px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          TAPS IDENT	
                        </div>
                        <div fxFlex="50">
                          {{viewAgentTradeCode}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:20px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start top">
                        <div fxFlex="15">
                          Address
                        </div>
                        <div fxFlex="50">
                          <div style="white-space:pre-wrap">{{viewAgentAddress}}</div>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:15px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Contact
                        </div>
                        <div fxFlex="50">
                          {{viewAgentContact}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:15px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Telephone 
                        </div>
                        <div fxFlex="50">
                          {{viewAgentTelephone}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:25px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Email 
                        </div>
                        <div fxFlex="50">
                          <div style="color:#4D5FD1; cursor:pointer;" (click)="openEmail(viewAgentEmail)">{{viewAgentEmail}}</div>
                        </div>
                      </div>
                    </h3>
  
                    <h3 style="margin-bottom:10px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Threshold	
                        </div>
                        <div fxFlex="50">
                          {{viewAgentThreshold}}%
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:10px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          TAPS	
                        </div>
                        <div fxFlex="50">
                          {{viewAgentTaps.toUpperCase()}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:10px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Head Office		
                        </div>
                        <div fxFlex="50">
                          {{viewAgentHeadOffice.toUpperCase()}}
                        </div>
                      </div>
                    </h3>
  
  
                  </div>  
            </div>
            <!-- Supplier's View Page -->
            <div *ngIf="listOfPages[27].value" class="taps-sidenav-content">
                  <h1>
                    <button class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('supplierManagementPage')">
                      <mat-icon>arrow_back</mat-icon>
                    </button> 
                    Supplier View Page
                    <button mat-raised-button color="primary" style="margin:10px;" (click)="viewSupplierStatus()">Status</button>
                    <button mat-raised-button color="primary" style="margin:10px;" (click)="viewSupplierHistory()">History</button>
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom:15px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50">
                          <h2 style="font-size:25px;">{{viewSupplierName}}</h2>	
                        </div>
                      </div>
                    </h3>                  
                    <h3 style="margin-bottom:20px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          TAPS ID	
                        </div>
                        <div fxFlex="50">
                          {{viewSupplierTradeCode}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:25px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start top">
                        <div fxFlex="15">
                          Address
                        </div>
                        <div fxFlex="50">
                          <div style="white-space:pre-wrap">{{viewSupplierAddress}}</div>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:10px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Email 
                        </div>
                        <div fxFlex="50">
                          <div style="color:#4D5FD1; cursor:pointer;" (click)="openEmail(viewSupplierEmail)">{{viewSupplierEmail}}</div>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:10px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Contact	
                        </div>
                        <div fxFlex="50">
                          {{viewSupplierContact}}
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom:25px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Telephone	
                        </div>
                        <div fxFlex="50">
                          {{viewSupplierTelephone}}
                        </div>
                      </div>
                    </h3>
  
                    <h3 style="margin-bottom:15px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          TAPS	
                        </div>
                        <div fxFlex="50">
                          {{viewSupplierTaps}}
                        </div>
                      </div>
                    </h3>
  
                    <h3 style="margin-bottom:15px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Deny Amendments	
                        </div>
                        <div fxFlex="50">
                          {{viewSupplierDenyAmendments}}
                        </div>
                      </div>
                    </h3>
  
                    <h3 style="margin-bottom:20px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Transaction Fee
                        </div>
                        <div fxFlex="50">
                          {{viewSupplierTransactionFee}}
                        </div>
                      </div>
                    </h3>
  
                    <h3 style="margin-bottom:10px; margin-top: 0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="15">
                          Paymend Days 
                        </div>
                        <div fxFlex="50">
                          {{viewSupplierPaymentDays}}
                        </div>
                      </div>
                    </h3>
  
                  </div>     
            </div>
            <!-- Delete TAPS Upload Page -->
            <div *ngIf="listOfPages[28].value && userName == 'Taps Admin' && loggedInUserType == 'Admin'" class="taps-sidenav-content">
                  <h1>
                    Delete TAPS Upload
                    <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary"  (click)="setupDeleteTapsUploadDataPage()">
                      Find
                    </button>
                  </h1>               
                  <div class="taps-insidenav-content">
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="20">
                          Collection Period<span style="color:#4D5FD1">*</span>
                        </div>
                        <div fxFlex="30">
                          <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Period" style="vertical-align: middle;" [(ngModel)]="adminReportingDatePeriod"  (selectionChange)="setupDatesForTapsAdminReportingPeriod()">
                              <mat-option *ngFor="let period of adminReportingDatePeriods" [value]="period">
                                {{period.displayRow}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="20">
                          Date Range<span style="color:#4D5FD1">*</span> 
                        </div>
                        <div style="display: block; max-width: fit-content !important;">
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="adminReportingDateFrom">
                            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromPicker ></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="toPicker" [(ngModel)]="adminReportingDateTo">
                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                            <mat-datepicker #toPicker ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-bottom: 0px;">
                      <p>{{adminDeleteTapsUploadListData.data.length}} Record(s)</p>
                    </h3>
                    <div name="Delete Upload List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="deleteTapsUploadTransactionID">
                            <mat-header-cell *matHeaderCellDef> Transaction ID </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteTapsUploadListSupplier">
                            <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteTapsUploadListTapsIdenti">
                            <mat-header-cell *matHeaderCellDef> TAPs Identi.</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteTapsUploadListUpload">
                            <mat-header-cell *matHeaderCellDef> Upload Date </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteTapsUploadListCollection">
                            <mat-header-cell *matHeaderCellDef> Collection </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteTapsUploadListValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteTapsUploadListState">
                            <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="deleteTapsUploadListId">
                            <mat-header-cell *matHeaderCellDef style="display:block; text-align: center; "> Delete </mat-header-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="adminDeleteTapsUploadListColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="adminDeleteTapsUploadListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                            
                            <ng-container matColumnDef="deleteTapsUploadTransactionID">                              
                              <mat-cell style="font-weight:bold; padding: 20px; color:#4D5FD1 !important; cursor:pointer;" (click)="openSupplierUploadedFilePage(upload)" *matCellDef="let upload"> 
                                #{{upload.transactionID}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteTapsUploadListSupplier">                              
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload">
                                {{upload.supplierName}}
                              </mat-cell>                 
                            </ng-container>
                            <ng-container matColumnDef="deleteTapsUploadListTapsIdenti">                              
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> 
                                {{upload.supplierTradeCode}} 
                              </mat-cell>                             
                            </ng-container>
                            <ng-container matColumnDef="deleteTapsUploadListUpload">                              
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> 
                                {{upload.uploadDate}} 
                              </mat-cell>                             
                            </ng-container>
                            <ng-container matColumnDef="deleteTapsUploadListCollection">                              
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> 
                                {{upload.collectionDate}} 
                              </mat-cell>                             
                            </ng-container>
                            <ng-container matColumnDef="deleteTapsUploadListValue">                             
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> 
                                {{upload.tranValue}} 
                              </mat-cell>                            
                            </ng-container>
                            <ng-container matColumnDef="deleteTapsUploadListState">                             
                              <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let upload"> 
                                {{upload.tranState}} 
                              </mat-cell>                            
                            </ng-container>
                            <ng-container matColumnDef="deleteTapsUploadListId">
                              <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px; cursor:pointer;" *matCellDef="let upload" (click)="deleteTapsUploadData(upload)"> 
                                <mat-icon>clear</mat-icon> 
                              </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: adminDeleteTapsUploadListColumns;"></mat-row>
                          </table>
                        </div>
                    </div>
                  </div>
            </div>
            <!-- BACS History Page -->
            <div *ngIf="listOfPages[29].value" class="taps-sidenav-content">
                  <h1>
                    <button *ngIf="adminCameFromAgentManagement == true" class="buttonClass" mat-raised-button style="margin-right:10px;" color="primary" (click)="switchView('agentPage')">
                      <mat-icon>arrow_back</mat-icon>
                    </button> 
                    BACS History Page
                  </h1>
                  <div class="taps-insidenav-content">
                    <h3 style="margin-bottom: 0px;">
                      <p>{{tapsMembersBacsReportData.data.length}} Record(s)</p>
                    </h3>
                    <h2 style="margin-bottom:20px; margin-top: 10px; font-weight:bold;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="30">
                          Accepted Transactions
                        </div>
                      </div>
                    </h2>
                    <div name="Reporting (BACS) List">
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="bacsReportTradeCode">
                            <mat-header-cell *matHeaderCellDef> TAPS ID</mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="bacsReportAgentName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="bacsReportReference">
                            <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="bacsReportSortCode">
                            <mat-header-cell *matHeaderCellDef> Sort Code </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="bacsReportAC">
                            <mat-header-cell *matHeaderCellDef> A/C </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="bacsReportDue">
                            <mat-header-cell *matHeaderCellDef> Due </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="bacsReportValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                          </ng-container>                        
                          <mat-header-row *matHeaderRowDef="tapsMembersBacsReportColumns"></mat-header-row>
                        </table>
                        </div>
                        <div class="example-container mat-elevation-z8" style="height: fit-content;">
                          <table mat-table [dataSource]="tapsMembersBacsReportData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                            <ng-container matColumnDef="bacsReportTradeCode">
                              <mat-header-cell *matHeaderCellDef> TAPS ID</mat-header-cell>                          
                              <mat-cell style="font-weight:bold; " *matCellDef="let bacs"> {{bacs.operatorTradeCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="bacsReportAgentName">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let bacs"> {{bacs.operatorName}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="bacsReportReference">
                              <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let bacs"> {{bacs.reference}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="bacsReportSortCode">
                              <mat-header-cell *matHeaderCellDef> Sort Code </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let bacs"> {{bacs.operatorSortCode}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="bacsReportAC">
                              <mat-header-cell *matHeaderCellDef> A/C </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let bacs"> {{bacs.operatorBankAcc}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="bacsReportDue">
                              <mat-header-cell *matHeaderCellDef> Due </mat-header-cell>
                              <mat-cell style="font-weight:bold; " *matCellDef="let bacs"> {{bacs.due}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="bacsReportValue">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell style="font-weight:bold;" *matCellDef="let bacs"> {{bacs.value | number : '1.2-2'}} </mat-cell>
                            </ng-container>                          
                            <mat-row *matRowDef="let row; columns: tapsMembersBacsReportColumns;"></mat-row>
                          </table>
                        </div>
                        <h3 style="margin-bottom:30px; margin-top: 20px; text-align:end;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="90">
                              Total - {{viewOverallBacsReportAmount | number : '1.2-2'}}
                            </div>
                          </div>
                        </h3>
                    </div>                  
                  </div>     
            </div>
            <!-- Switch between Dev/Live Page 
            <div *ngIf="listOfPages[30].value" class="taps-sidenav-content">
              <h1>
                Switch between Dev/Live
              </h1>               
              <div class="taps-insidenav-content">
                <div style="padding-top:20px;">
                  <div class="toggleable-container">
                    <button *ngIf="!showSwitchContent" mat-raised-button color="primary" (click)="toggleToViewSwitch()">View 'Switch' Form  <mat-icon mat-list-icon>keyboard_arrow_down</mat-icon></button>
                    <button *ngIf="showSwitchContent"  mat-raised-button color="primary" (click)="toggleToViewSwitch()">Hide 'Switch' Form  <mat-icon mat-list-icon>keyboard_arrow_up</mat-icon></button>
                    <div *ngIf="showSwitchContent" style="padding-top:20px;">
                      <h3 *ngIf="liveVersion">Switch to the 'Dev' Site</h3>
                      <h3 *ngIf="!liveVersion">Switch to the 'Live' Site</h3>
                      <h3 style="margin-bottom: 0px;">
                        <mat-form-field style="width: 200px; max-width: unset; font-weight: bold;">
                          <mat-label>Pin</mat-label>
                          <input matInput [(ngModel)]="pinGiven" autocomplete="nope">
                        </mat-form-field>
                        <button *ngIf="loggedInUserType == 'Admin'"  mat-raised-button style="margin-left: 10px;" color="primary" (click)="switchToDevSiteForAdmin()">Switch</button>
                        <button *ngIf="loggedInUserType == 'Member'" mat-raised-button style="margin-left: 10px;" color="primary" (click)="switchToDevSite()">Switch</button>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            -->
            <!-- BACS Reporting Page -->
            <div *ngIf="listOfPages[31].value" class="taps-sidenav-content">
              <h1>
                <button *ngIf="adminBacsReporting != ''" class="buttonClass" mat-raised-button style="margin-left:10px; margin-right: 10px;" color="primary"  (click)="adminBacsReporting=''">
                  <mat-icon>arrow_back</mat-icon>
                </button>
                BACS Reporting 
                <span *ngIf="adminBacsReporting == 'tapsAdminCollection'"> - Collection from Agents </span>
                <span *ngIf="adminBacsReporting == 'tapsAdminDelivery'"> - Delivery to Suppliers </span>
                <button *ngIf="adminBacsReporting != ''" matTooltip="Refresh" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="refreshBacsReporting()">
                  Refresh
                </button>
                <button *ngIf="adminBacsReporting != '' && showArchived == false" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary"  (click)="showArchivedBacs()">
                  Archives
                </button>
                <button *ngIf="adminBacsReporting != '' && showArchived == true" class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary"  (click)="backToToday()">
                  Today
                </button>
              </h1>               
              <div class="taps-insidenav-content">
                <div *ngIf="adminBacsReporting == ''">
                  <button mat-raised-button style="margin: 20px;" color="primary" (click)="setupListOfBacsReporting('tapsAdminCollection')">Collection from Agents</button>
                  <button mat-raised-button style="margin: 20px;" color="primary" (click)="setupListOfBacsReporting('tapsAdminDelivery')">Delivery to Suppliers</button>
                </div>
                <div *ngIf="adminBacsReporting != ''">
                  <div *ngIf="attachedDocs.data.length > 0">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="fileName">
                          <mat-header-cell *matHeaderCellDef> File Name</mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastModified">
                          <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="size">
                          <mat-header-cell *matHeaderCellDef> Size </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="download">
                          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                      </table>
                    </div>
                    <div class="example-container mat-elevation-z8" style="height: fit-content;">
                      <table mat-table [dataSource]="attachedDocs" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                        <ng-container matColumnDef="fileName">                      
                          <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.name }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastModified">
                          <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="size">
                          <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="download">
                          <mat-cell *matCellDef="let data">
                            <button matTooltip="Download" class="matIcon" style="float: right;" type="button" mat-mini-fab color="primary" (click)="downloadTXTDocument(data,adminBacsReporting)" [class.mat-elevation-z0]="true">
                              <mat-icon>file_download</mat-icon>
                            </button>
                          </mat-cell>
                        </ng-container>                          
                        <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="attachedDocs.data.length == 0">
                    <h2> No records found. </h2>
                  </div>
                </div>
              </div>
            </div>
            <!-- Collect FTP Page -->
            <div *ngIf="listOfPages[32].value" class="taps-sidenav-content">
              <h1>
                <button *ngIf="lateAdjustmentUploadedFile == true" class="buttonClass" mat-raised-button style="margin-left:10px; margin-right: 10px;" color="primary"  (click)="switchView('collectFtpPage')">
                  <mat-icon>arrow_back</mat-icon>
                </button>
                Collect FTP Uploads <span *ngIf="lateAdjustmentUploadedFile == true"> (State : {{viewTransactionState}}) </span>
              </h1>               
              <div class="taps-insidenav-content">
                <div *ngIf="lateAdjustmentUploadedFile == false">
                  <div *ngIf="attachedDocs.data.length > 0">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="name">
                          <mat-header-cell *matHeaderCellDef> File Name</mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="supplierCode">
                          <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="supplierName">
                          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        </ng-container>
                        <!-- For future use if requested.
                          <ng-container matColumnDef="download">
                            <mat-header-cell *matHeaderCellDef> Download </mat-header-cell>
                          </ng-container>
                        !-->
                        <ng-container matColumnDef="collect">
                          <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="collectFtpColumns"></mat-header-row>
                      </table>
                    </div>
                    <div class="example-container mat-elevation-z8" style="height: fit-content;">
                      <table mat-table [dataSource]="attachedDocs" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                        <ng-container matColumnDef="name">                      
                          <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.name }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="supplierCode">
                          <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.supplierCode }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="supplierName">
                          <mat-cell style="font-weight:bold; " *matCellDef="let data"> {{ data.supplierName }} </mat-cell>
                        </ng-container>
                        <!-- For future use if requested.
                          <ng-container matColumnDef="download">
                            <mat-cell *matCellDef="let data">
                              <button matTooltip="Download" class="matIcon" style="float: center;" type="button" mat-mini-fab color="primary" (click)="downloadDocument(data,'tapsCollectFtp',data.supplierCode)" [class.mat-elevation-z0]="true">
                                <mat-icon>file_download</mat-icon>
                              </button>
                            </mat-cell>
                          </ng-container> 
                        -->
                        <ng-container matColumnDef="collect">
                          <mat-cell style="display: block !important; text-align: center !important;" *matCellDef="let data">
                            <button mat-raised-button color="primary" (click)="collectFtpFile(data)">
                              Collect
                            </button>
                          </mat-cell>
                        </ng-container>                         
                        <mat-row *matRowDef="let row; columns: collectFtpColumns;"></mat-row>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="attachedDocs.data.length == 0">
                    <h2 *ngIf="pageLoaded == true"> No records found. </h2>
                  </div>
                </div>
                <div *ngIf="lateAdjustmentUploadedFile == true">
                  <h3 style="margin-bottom:0px; margin-top: 10px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        TAPS ID	
                      </div>
                      <div fxFlex="30">
                        {{viewAdjustmentUploadTapsId}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px; margin-top: 10px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Supplier	
                      </div>
                      <div fxFlex="30">
                        {{viewAdjustmentUploadSupplierName}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px; margin-top: 10px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Upload Date	
                      </div>
                      <div fxFlex="30">
                        {{viewUploadDate}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px; margin-top: 20px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Quantity	
                      </div>
                      <div fxFlex="30">
                        {{viewNumOfTransactions}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px; margin-top: 10px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Value	
                      </div>
                      <div fxFlex="30">
                        {{viewValue | number : '1.2-2'}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px; margin-top: 10px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Adjustments	
                      </div>
                      <div fxFlex="30">
                        {{viewAdjustments | number : '1.2-2'}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px; margin-top: 10px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Agent Review	
                      </div>
                      <div fxFlex="30">
                        {{viewAgentReview}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:0px; margin-top: 20px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        To Pay	
                      </div>
                      <div fxFlex="30">
                        {{viewToPay | number : '1.2-2'}}
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-bottom:30px; margin-top: 10px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Payment Due	
                      </div>
                      <div fxFlex="30">
                        {{viewPaymentDue}}
                      </div>
                    </div>
                  </h3>
                  <h2 style="margin-bottom:20px; margin-top: 10px; font-weight:bold;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="30">
                        Accepted Transactions ({{tapsUploadTransactionData.data.length}})
                      </div>
                    </div>
                  </h2>
                  <div name="Collect FTP File (Accepted Transactions)">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="tranAgent">
                          <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranTapsId">
                          <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranRef">
                          <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranDepart">
                          <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranClient">
                          <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranWarning">
                          <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranValue">
                          <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranAdjust">
                          <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranCollectedValue">
                          <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranCode">
                          <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranName">
                          <mat-header-cell *matHeaderCellDef > Name </mat-header-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="tapsUploadTransactionColumns"></mat-header-row>
                      </table>
                      </div>
                      <div class="example-container mat-elevation-z8" style="height: fit-content;">
                        <table mat-table [dataSource]="tapsUploadTransactionData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                          <ng-container matColumnDef="tranAgent">
                            <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                            <mat-cell style="font-weight:bold; padding-left: 20px;" *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranTapsId">
                            <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranRef">
                            <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranDepart">
                            <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranClient">
                            <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranWarning">
                            <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                            <mat-cell style="font-weight:bold;" *matCellDef="let upload"> {{upload.tranWarning}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranAdjust">
                            <mat-header-cell *matHeaderCellDef> Adjust </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAdjust}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranCollectedValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCollectedValue | number : '1.2-2'}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranCode">
                            <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranCode}} </mat-cell>
                          </ng-container>                        
                          <ng-container matColumnDef="tranName">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranName}} </mat-cell>
                          </ng-container>
                          <mat-row *matRowDef="let row; columns: tapsUploadTransactionColumns;"></mat-row>
                        </table>
                      </div>
                      <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="90">
                            Unapproved - {{viewAdjustments | number : '1.2-2'}}	
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:10px; margin-top: 10px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="90">
                            Approved - {{viewToPay | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-bottom:30px; margin-top: 10px; text-align:end;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="90">
                            Total - {{viewOverallAcceptedAmount | number : '1.2-2'}}
                          </div>
                        </div>
                      </h3>
                  </div>
                  <h2 style="margin-bottom:20px; margin-top: 40px; font-weight:bold;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="30">
                        Rejected Transactions ({{tapsUploadTransactionRejectedData.data.length}})
                      </div>
                    </div>
                  </h2>
                  <div name="Collect FTP File (Rejected Transactions)">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="tranAgent">
                          <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranTapsId">
                          <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranRef">
                          <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranDepart">
                          <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranClient">
                          <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranValue">
                          <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranFailure">
                          <mat-header-cell *matHeaderCellDef > Failure </mat-header-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="tapsUploadRejectedTransactionColumns"></mat-header-row>
                      </table>
                      </div>
                      <div class="example-container mat-elevation-z8" style="height: fit-content;">
                        <table mat-table [dataSource]="tapsUploadTransactionRejectedData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                          <ng-container matColumnDef="tranAgent">
                            <mat-header-cell *matHeaderCellDef> Agent</mat-header-cell>                          
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranAgent}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranTapsId">
                            <mat-header-cell *matHeaderCellDef> TAPS ID </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranTapsId}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranRef">
                            <mat-header-cell *matHeaderCellDef> Ref. </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranRef}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranDepart">
                            <mat-header-cell *matHeaderCellDef> Depart </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranDepart}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranClient">
                            <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranClient}} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="tranValue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                          </ng-container>                       
                          <ng-container matColumnDef="tranFailure">
                            <mat-header-cell *matHeaderCellDef> Failure </mat-header-cell>
                            <mat-cell style="font-weight:bold; " *matCellDef="let upload"> {{upload.tranFailure}} </mat-cell>
                          </ng-container>
                          <mat-row *matRowDef="let row; columns: tapsUploadRejectedTransactionColumns;"></mat-row>
                        </table>
                  </div>  
                  <h3 style="margin-bottom:10px; margin-top: 20px; text-align:end;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="90">
                        Rejected - {{viewOverallRejectedAmount | number : '1.2-2'}}
                      </div>
                    </div>
                  </h3>
                  <div style="margin:10px;">
                    <button class="buttonClass" style="margin:10px; background-color:#ce983a; color:white;" mat-raised-button (click)="acceptLateUpload()" >Accept</button>
                    <button class="buttonClass" style="margin:10px;" mat-raised-button color="warn" (click)="rejectLateUpload()">Reject</button>
                  </div>
                </div>  
                </div> 
              </div>
            </div>
            <!-- Upload History Admin Page -->
            <div *ngIf="listOfPages[33].value" class="taps-sidenav-content">
              <h1>
                Upload History
                <button class="buttonClass" mat-raised-button style="margin-left:10px;" color="primary" (click)="listUploadedFilesOfSupplier(true)">
                  Find
                </button>
              </h1>
              <div class="taps-insidenav-content">
                <h3 style="margin-bottom: 30px;">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="20">
                      Supplier	
                    </div>
                    <div fxFlex="30">
                      <mat-form-field style="max-width: unset; width: 600px; margin-bottom: -15px; padding-left: 5px;">
                        <mat-select ngModel required name="tradeCode" [(ngModel)]="adminReportSupplier" placeholder="Supplier">
                          <mat-form-field style="max-width: unset; width: 627px;" appearance="fill">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterAdminSupplierListString" (keyup)="filterMemberSupplierListSelect()">
                          </mat-form-field>
                          <mat-option value="all">
                            All
                          </mat-option>
                          <mat-option *ngFor="let supplier of filteredAdminSupplierListData.data" [value]="supplier.misttaCode ? supplier.misttaCode : 'N/A'" (click)="filterAdminSupplierListString = ''; filterMemberSupplierListSelect();">
                            {{supplier.supplierNameM + " (" + (supplier.misttaCode ? supplier.misttaCode : 'N/A') + " - " + supplier.isTapsReg.toUpperCase() +" TAPS)"}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </h3>
                <h3>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="20">
                      Upload Period
                    </div>
                    <div fxFlex="30">
                      <mat-form-field style="width: 400px; max-width: unset;" class="transactionValueNormalTab">
                        <mat-select placeholder="Period" style="vertical-align: middle;" [(ngModel)]="selectedDatePeriod"  (selectionChange)="setupDatesForUploadedPeriod(true)">
                          <mat-option *ngFor="let period of datePeriods" [value]="period">
                            {{period.displayRow}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </h3>
                <h3>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="20">
                      Date Range<span style="color:#4D5FD1">*</span> 
                    </div>
                    <div style="display: block; max-width: fit-content !important;">
                      <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-right: 10px !important;">
                        <mat-label>From</mat-label>
                        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="selectedDateFrom">
                        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromPicker ></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                        <mat-label>To</mat-label>
                        <input matInput [matDatepicker]="toPicker" [(ngModel)]="selectedDateTo">
                        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                        <mat-datepicker #toPicker ></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </h3>
                <h3 style="margin-bottom: 0px;">
                  <p>{{tapsUploadHistoryListData.data.length}} Record(s)</p>
                </h3>
                <div name="Upload History List">
                  <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                    <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container matColumnDef="uploadPeriod">
                        <mat-header-cell style="margin-left: 10px;" *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByDate()" style="cursor:pointer"> Upload</mat-header-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranValue">
                        <mat-header-cell *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByValue()" style="cursor:pointer"> Value </mat-header-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranState">
                        <mat-header-cell *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByState()" style="cursor:pointer"> State </mat-header-cell>
                      </ng-container>
                      <ng-container matColumnDef="tranFailure">
                        <mat-header-cell *matHeaderCellDef (click)="sortTapsUploadHistoryListDataByFailure()" style="cursor:pointer"> Failure </mat-header-cell>
                      </ng-container>
                      <ng-container matColumnDef="numOfTransactions">
                        <mat-header-cell *matHeaderCellDef style="display:block; text-align: center;" (click)="sortTapsUploadHistoryListDataByTransactions()" style="cursor:pointer"> No. of Transactions </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="tapsUploadHistoryListColumns"></mat-header-row>
                    </table>
                    </div>
                    <div class="example-container mat-elevation-z8" style="height: fit-content;">
                      <table mat-table [dataSource]="tapsUploadHistoryListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                        <ng-container matColumnDef="uploadPeriod">
                          <mat-header-cell *matHeaderCellDef> Upload </mat-header-cell>
                          <mat-cell style="font-weight:bold; padding-left: 15px !important; color:#4D5FD1 !important; cursor:pointer; margin-left: 10px;"*matCellDef="let upload" (click)="openSupplierUploadedFilePage(upload)">
                             {{upload.viewUploadDate}} 
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranValue">
                          <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                          <mat-cell style="font-weight:bold; padding: 5px;" *matCellDef="let upload"> {{upload.tranValue | number : '1.2-2'}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranState">
                          <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                          <mat-cell style="font-weight:bold; padding: 5px;" *matCellDef="let upload"> 
                            <span *ngIf="upload.tranState == 'Imported'">Review</span>
                            <span *ngIf="upload.tranState == 'Importing'">Imported</span>
                            <span *ngIf="upload.tranState != 'Imported' && upload.tranState != 'Importing'">{{upload.tranState}} </span>
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="tranFailure">
                          <mat-header-cell *matHeaderCellDef> Failure</mat-header-cell>
                          <mat-cell style="font-weight:bold; padding: 5px;" *matCellDef="let upload"> {{upload.tranFailure}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="numOfTransactions">
                          <mat-header-cell *matHeaderCellDef> No. of Transactions </mat-header-cell>
                          <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 5px;" *matCellDef="let upload"> {{upload.numOfTransactions}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: tapsUploadHistoryListColumns;"></mat-row>
                      </table>
                    </div>
                </div>
              </div>                
        </div>
    </mat-sidenav-container> 
  </div>
</mat-card-content>

<!-- Messages Dialog-->
<ng-template #myDialog>
  
    <div *ngIf="errorMessage != ''" class="errorMessage">
      <p>{{errorMessage}} <mat-icon class="errorIcon">error</mat-icon></p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>

    <div *ngIf="successMessage != '' && !successMessage.includes('<br>')" class="successMessage">
      <p>{{successMessage}} <mat-icon class="successIcon">check_circle</mat-icon></p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>

    <div *ngIf="successMessage != '' && successMessage.includes('<br>') " class="successMessage">
      <div class="taps-response-content">
        <p style="padding: 0px 0px 0px 15px;" [innerHTML]="successMessage"></p> 
      </div> 
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>

</ng-template>

<!-- Help Dialog -->
<ng-template #helpDialog>
  <div style="padding: 10px; width: 500px;">
    <div style="display: flex; justify-content: space-between;">
      <h1 style="padding: 0px; margin-top: 0px; margin-bottom: 10px; margin-right: 30px; font-size: 30px; display:inline;">
        TAPS Info
      </h1>
      <div style="display:inline;">
        <button mat-button style="color: rgb(88, 88, 88) !important; margin-top: 0px; margin-bottom: 10px;" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <mat-dialog-content style="padding: 10px; min-height:800px;">
      <div [@inAnimation]>
        <!-- TAPS USER MANUAL -->
        <div style="width: 100%; margin-bottom: 20px;">
          <div class="ownExpansionHeader">
            <div class="headerTopLeft" style="width:fit-content !important;">
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionTAPSUserManual" (click)="expansionTAPSUserManual = false;">
                <mat-icon>expand_less</mat-icon>
              </button>
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionTAPSUserManual" (click)="expansionTAPSUserManual = true;">
                <mat-icon>expand_more</mat-icon>
              </button>
              TAPS User Manual
            </div>
          </div>
          <div [@customExpansionDetails]="expansionTAPSUserManual == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" style="padding-left: 0px !important;">
                <h3 style="padding-left: 20px; padding-right: 20px; margin-bottom: 30px;">      
                  
                  <div *ngIf="loggedInUserType == 'Supplier' || loggedInUserType == 'Admin'">
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Upload File</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Upload History</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Pull Down File</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Reporting</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                  </div>

                  <div *ngIf="loggedInUserType == 'Member' || loggedInUserType == 'Admin'">
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Transaction Approval</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Export or Import Transactions</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Reporting</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                    <div style="margin:10px; padding:10px;">
                      <a class="tapsUserManualLink" href="" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">chevron_right</mat-icon> Analysis Reporting</a>
                      <mat-divider style="margin-top: 10px;"></mat-divider>
                    </div>
                  </div>
                  
                </h3>
          </div>
          <div [@customExpansionDetails]="expansionTAPSUserManual == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansionTAPSUserManual = true;">
            <mat-icon>expand_more</mat-icon>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</ng-template>

<!-- Contact Us -->
<ng-template #contactDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px; font-weight: bold; font-size: 27px;">
      Contact Us
    </h1>
    <h1>
      TTA Trustees (UK) Ltd <br> TAPS Collections Dep. <br><br>
    </h1>
    <h2>
      St Andrews House<br>
      West Street<br>
      Woking<br>
      Surrey<br>
      GU21 6EB<br><br>
    </h2>
    <h2>
      Telephone: <b>+44 (0) 1483 545 780</b><br>
      Fax: No fax<br>
      Email:<span style="font-weight:bold; display:block; text-align: center; padding: 10px;  color:#4D5FD1 !important; cursor:pointer; display:inline;" (click)="openEmail('taps@traveltrust.co.uk')"> taps@traveltrust.co.uk </span> <br>
    </h2>
    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>

<!-- Commit All -->
<ng-template #commitAll>
  <mat-dialog-content>
    <div style="margin: 20px;">
      <h1 style="margin-left: 10px; margin-right: 10px; margin-top: 0px; margin-bottom: 50px; font-weight: bold; font-size: 27px;">
        Commit Upload ({{adminCommitSelectedUpload}})
      </h1>
      <h3 style="margin-bottom:0px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div style="padding-right: 50px;">
            Transactions 
          </div>
          <div style="margin-left: 120px;">
            {{adminCommitNumOfTransactions}}
          </div>
        </div>
      </h3>
      <h3 style="margin-bottom:0px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            Original Total 
          </div>
          <div style="margin-left: 115px;">
            {{adminCommitOriginalTotal | number : '1.2-2'}}
          </div>
        </div>
      </h3>
      <h3 style="margin-bottom:0px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            Adjustments 
          </div>
          <div style="margin-left: 110px;">
            - <span *ngIf="adminCommitAdjustments < 0">(</span>{{adminCommitAdjustments | number : '1.2-2'}}<span *ngIf="adminCommitAdjustments < 0">)</span>
          </div>
        </div>
      </h3>
      <h3 style="margin-bottom:50px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            Collection Total 
          </div>
          <div style="margin-left: 100px;">
            {{adminCommitCollectionTotal | number : '1.2-2'}}
          </div>
        </div>
      </h3>
      <div style="display: flex; text-align: center; margin: auto; justify-content: center; padding: 10px;">
        <button style="margin-right: 40px; font-size: 20px; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 10px;" mat-raised-button color="primary" matDialogClose (click)="commitAllReviewFiles()">Confirm</button>
        <button style="margin-left: 40px; font-size: 20px; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 10px;" mat-raised-button color="warn" matDialogClose>Cancel</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<!-- Commit Upload -->
<ng-template #commitUpload>
  <mat-dialog-content>
    <div style="margin: 20px;">
      <h1 style="margin-left: 10px; margin-right: 10px; margin-top: 0px; margin-bottom: 50px; font-weight: bold; font-size: 27px;">
        Commit Upload (TransactionID : {{adminCommitSelectedUpload}})
      </h1>
      <h3 style="margin-bottom:0px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div style="padding-right: 50px;">
            Transactions 
          </div>
          <div style="margin-left: 120px;">
            {{adminCommitNumOfTransactions}}
          </div>
        </div>
      </h3>
      <h3 style="margin-bottom:0px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            Original Total 
          </div>
          <div style="margin-left: 115px;">
            {{adminCommitOriginalTotal | number : '1.2-2'}}
          </div>
        </div>
      </h3>
      <h3 style="margin-bottom:0px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            Adjustments 
          </div>
          <div style="margin-left: 110px;">
            - <span *ngIf="adminCommitAdjustments < 0">(</span>{{adminCommitAdjustments | number : '1.2-2'}}<span *ngIf="adminCommitAdjustments < 0">)</span>
          </div>
        </div>
      </h3>
      <h3 style="margin-bottom:50px; display: flex; justify-content: center;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            Collection Total 
          </div>
          <div style="margin-left: 100px;">
            {{adminCommitCollectionTotal | number : '1.2-2'}}
          </div>
        </div>
      </h3>
      <div style="display: flex; text-align: center; margin: auto; justify-content: center; padding: 10px;">
        <button [disabled]="adminCommitState == 'Imported'" style="margin-right: 40px; font-size: 20px; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 10px;" mat-raised-button color="primary" matDialogClose (click)="commitReviewFile()">Confirm</button>
        <button style="margin-left: 40px; font-size: 20px; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 10px;" mat-raised-button color="warn" matDialogClose>Cancel</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
