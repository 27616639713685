import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  async getPaymentList(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'getPaymentList', body, { headers }).toPromise();
    return output;
  }

  async createPayment(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'createPayment', body, { headers }).toPromise();
    return output;
  }

  async updatePaymentSeq(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'updatePaymentSeq', body, { headers }).toPromise();
    return output;
  }
}
