import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { Session } from '../../common/session';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BookingService } from '../../services/booking.service';
import { ReportsService } from '../../services/reports.service';
import { UserService } from '../../services/user.service';
import { ReceiptService } from '../../services/receipt.service';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1, display: 'inline' }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class HomeComponent implements OnInit, AfterViewInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;
  memberLive = AppComponent.myapp.memberLive;
  showExtReference = false;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  // Table data and table columns below
  displayedColumns = ['bookingRef', 'tradingName', 'created', 'departureDate', 'leadName', 'grossVal'];
  bankingColumns = ['bookingReference', 'leadPax', 'receiptDate', 'payerName', 'paymentMethod', 'value', 'ticked'];
  custDueColumns = ['bookingReference', 'tradingName', 'leadPax', 'custDueDate', 'suppDueDate', 'deptDate', 'returnDate', 'gross', 'discount', 'totalReceipted', 'balanceToOp'];
  monthlyRetColumns = ['bookingRefMonthly', 'leadName', 'paxNo', 'paxNoTatolPackage', 'paxNoTatolFlOnly', 'bookingDate', 'bookedTS'];
  bookingsData: any = new MatTableDataSource<any>();
  enquiriesData: any = new MatTableDataSource<any>();
  bankingReportData: any = new MatTableDataSource<any>();
  customerDueReportData: any = new MatTableDataSource<any>();
  monthlyReturnBreakdown: any = new MatTableDataSource<any>();

  // Monthly variables below
  monthlyReturnSummary: any = {};
  motnhlyReturnBranch: any = {};
  monthlyReturnAll: any = [];
  monthlyRetFiltered: any = [];
  monthlyReturnCheckbox: any = [];
  branchFilterStr: any = '';
  monthlyPeriod: any = '';
  monthlyTradeCode: any = '';
  // With all dates in
  currentDate: any = '';
  monthlyMonth: number | undefined;
  monthlyYear: number | undefined;

  // Boolean deciding whether user has access or not
  userType = '';
  pageLoaded = false;
  haveAccess = false;
  myLast5 = true;
  dailyBanking = false;
  reminders = false;
  performanceView = false;
  monthlyReturnView = false;
  monthlySubmitDisabled = false;
  monthlyBoxFilter = { pending: false, approved: false };

  // Custom mat expansion variables
  expansion5enquiries = true;
  expansion5bookings = true;
  expansionPerformanceView = true;
  expansionDailyBanking = true;
  expansionReminders = true;
  expansionMonthlySummary = true;
  expansionMonthlyBreakdown = false;

  // Charts variables
  public bookingChart: Partial<any> = {
    series: [],
    chart: {
      type: 'area',
      background: '#ff22f',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          reset: false,
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      height: 175,
      width: '100%',
      autoSelected: 'pan',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'categories',
      tickPlacement: 'between',
      categories: [],
      tooltip: {
        enabled: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value;
          } else {
            return '-£' + (value * -1);
          }
        }
      }
    },
    title: {
      text: 'Gross Sales',
      align: 'center',
      offsetY: 15,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: 'black'
      },
    }
  };
  public enquiryChart: Partial<any> = {
    colors: ['#CE983A'],
    series: [],
    chart: {
      type: 'area',
      background: '#ff22f',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          reset: false,
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      height: 175,
      width: '100%',
      autoSelected: 'pan',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#CE983A']
    },
    xaxis: {
      type: 'categories',
      tickPlacement: 'between',
      categories: [],
      tooltip: {
        enabled: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value;
          } else {
            return '-£' + (value * -1);
          }
        }
      }
    },
    title: {
      text: 'Gross Sales',
      align: 'center',
      offsetY: 15,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: 'black'
      },
    },
    fill: {
      colors: ['#CE983A']
    }
  };
  public ytdMoneyChart: Partial<any> = {
    colors: ['#4D5FD1', '#689e89', '#82b4e0'],
    series: [
      {
        name: 'Gross',
        data: [],
        type: 'area'
      },
      {
        name: 'Net',
        data: [],
        type: 'area'
      },
      {
        name: 'Commission',
        data: [],
        type: 'area'
      }
    ],
    chart: {
      type: 'area',
      background: '#ff22f',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          reset: false,
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      height: 400,
      width: '100%',
      autoSelected: 'pan',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'categories',
      tickPlacement: 'on',
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value;
          } else {
            return '-£' + (value * -1);
          }
        }
      }
    },
    title: {
      text: 'Performance By Booking Date',
      align: 'center',
      offsetY: 15,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: 'black'
      },
    }
  };
  public ytdPaxBookChart: Partial<any> = {
    colors: ['#394475', '#adb8ba'],
    series: [
      {
        name: 'Total Pax',
        data: [],
        type: 'line'
      },
      {
        name: 'Total Bookings',
        data: [],
        type: 'line'
      },
    ],
    chart: {
      type: 'line',
      background: '#ff22f',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          reset: false,
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      height: 400,
      width: '100%',
      autoSelected: 'pan',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight'
    },
    xaxis: {
      type: 'categories',
      tickPlacement: 'on',
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return value;
        }
      },
    },
    title: {
      text: 'Number of Passengers & Bookings',
      align: 'center',
      offsetY: 15,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: 'black'
      },
    }
  };
  public dailyBaChart: Partial<any> = {
    series: [],
    chart: {
      type: 'donut',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      height: 300,
      zoom: {
        enabled: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [{
      breakpoint: 280,
      options: {
        chart: {
          width: 100
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    colors: ['#4D5FD1', '#b0b0b0'],
    labels: ['Checked', 'Not Checked'],
    legend: {
      show: true,
      position: 'bottom',
      itemMargin: {
        horizontal: 5,
      },
    },
    yaxis: {},
    title: {
      text: 'Completion Status',
      align: 'center',
      offsetY: 0,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: 'black'
      },
    },
  };
  public reminderChart: Partial<any> = {
    colors: ['#CE983A'],
    series: [],
    chart: {
      type: 'area',
      background: '#ff22f',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          reset: false,
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      height: 200,
      width: '100%',
      autoSelected: 'pan',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['#CE983A']
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value;
          } else {
            return '-£' + (value * -1);
          }
        }
      }
    },
    title: {
      text: 'Balance Due By Date',
      align: 'center',
      offsetY: 15,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: 'black'
      },
    },
    fill: {
      colors: ['#CE983A']
    }
  };
  ytdChartDetails: any = { currentMonthSales: 0, lastMonthSales: 0, lastYearSales: 0 };

  // Other variables
  currentRequest: any = {};
  branchList: any = []; // Used in select-list to switch between branch
  performanceListAll: any = []; // Used in Performance tab
  performanceList: any = []; // Used in Performance tab
  selBranchAdmin: any = {}; // Selected branch (admin only)
  bankingReportTotalValue: any = { value: 0 };
  custDueTotalValue: any = { value: 0 };
  monthlyReportPaxNo: any = { bookings: 0, paxNo: 0, paxNoTAtolFlOnly: 0, paxNoTAtolPackage: 0 };
  badgeValues: any = { dailyNo: 0, reminderNo: 0 };
  yesterDate: any = '';
  bankingFrom: any = '';
  bankingTo: any = '';

  // Error message variables
  errorMessage: any = '';
  successMessage: any = '';

  // This child is actually not being used - its only for referencing in other components..
  // We just want to remove mat tooltip from mat paginators
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('paginatorReturns') paginatorReturns!: MatPaginator;

  constructor(private router: Router, private bookingService: BookingService, private reportsService: ReportsService,
              private receiptService: ReceiptService, private userService: UserService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (Session.mySession.get('justLoggedIn') === true) {
      // If the user has just logged in, justLoggedIn session variable will be set to true. This is done for the top-nav to appear
      window.location.reload(); // Refresh the browser first
      Session.mySession.set('justLoggedIn', false); // Set justLoggedIn to false so it won't be refreshed after loading for the second time
      Session.mySession.set('customerFacing', false); // As a default, we set customer facing feature as false
      Session.mySession.set('mistakesOnOff', false); // Same as above.. default value
      Session.mySession.set('bookingPop', true); // We will not make our bookings 'pop' initially..
      Session.mySession.set('showExternalRef', false); // Based on this we will display either our ref or 'your ref'
    } else {
      if (sessionStorage.length === 0 || Session.mySession === undefined) {
        sessionStorage.clear(); // If session storage isn't defined etc. we clear it (just in case)
        this.router.navigate(['/login']); // And redirect users to /login page
      } else {
        this.userType = Session.mySession.getUser().userType; // Assign user type from session to the variable
        setTimeout(() => { // It's done so there's some delay between setting boolean value in AppComponent
          AppComponent.myapp.fellohNav = false;
          AppComponent.myapp.bookingPop = Session.mySession.get('bookingPop');
          AppComponent.myapp.showExternalRef = Session.mySession.get('showExternalRef');
          this.showExtReference = AppComponent.myapp.showExternalRef;
          // Logged in user is in the group and branch list already exists within the session variable.
          if (Session.mySession.getUsersGroup().length > 0) { this.branchList = Session.mySession.getUsersGroup(); }
        }, 250);

        const date: any = new Date(); date.setDate(date.getDate() - 1); // Get yesterday's date here
        const threeDaysBf = new Date(); threeDaysBf.setDate(threeDaysBf.getDate() - 3); // Get 3 days ago date
        this.yesterDate = moment(date).format('DD.MM.YYYY');  // Assign 'global' date here
        this.bankingTo = this.constants.convertDateNotMoment(date); // Convert the date so Ruby accepts it
        this.bankingFrom = this.constants.convertDateNotMoment(threeDaysBf); // Convert the date so Ruby accepts it
        this.setMonthlyDates();

        // Create first request variable to get latest bookings (first view tab)
        this.currentRequest = {
          company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode, email: Session.mySession.getUser().email, token: Session.mySession.get('user').token
        };
        this.loadPage();
      }
    }
  }

  loadPage(): void {
    // SinGS Admin & Staff doesn't need some of the reports which are done for normal memberManager / memberStaff
    if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff') {
      this.haveAccess = true; // Have access - hurray!
      this.myLast5 = false; // What really should be on for SinGS Staff / Admins..
      this.pageLoaded = false;
      this.getYtdReport().then(res => {
        this.getMonthlyReturns(this.currentRequest.tradeCode, false).then(() => {
          window.dispatchEvent(new Event('resize')); // Used so all elements are 'set in places'
          this.performanceView = true; this.pageLoaded = true;
        });
      }).then(() => { this.pageLoaded = true; });
    } else if (this.userType === 'memberManager' || this.userType === 'memberStaff') {
      this.haveAccess = true; // Have access - hurray!
      this.pageLoaded = false;
      this.getLatestBookings('enquiry', this.enquiriesData, this.enquiryChart).then(() => {
        this.getLatestBookings('booking', this.bookingsData, this.bookingChart).then(() => {
          this.getBankingReport('rtnData').then(() => {
            this.getCustDueReport('rtnData').then(() => {
              this.getYtdReport().then(() => {
                this.getMonthlyReturns(this.currentRequest.tradeCode, false).then(() => {
                  window.dispatchEvent(new Event('resize')); // Used so all elements are 'set in places'
                  this.pageLoaded = true;
                });
              });
            });
          });
        });
      });
    } else if (this.userType === 'wcMember' || this.userType === 'wcManager') {
      this.router.navigate(['/paymentLinks']); // redirect to Felloh app
    } else if (this.userType === 'trustee') {
      this.router.navigate(['/prf']); // Navigate to the Felloh UI + PRF system as a default
    } else if (this.userType === 'supplier' || this.userType === 'tapsAdmin') {
      this.router.navigate(['/taps']);
    } else {
      this.pageLoaded = true;
      window.dispatchEvent(new Event('resize')); // Used so all elements are 'set in places'
    }
  }

  ngAfterViewInit(): void {
    // Remove paginator's mat tooltips (unnecesary)
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.firstPageLabel = '';
    paginatorIntl.nextPageLabel = '';
    paginatorIntl.previousPageLabel = '';
    paginatorIntl.lastPageLabel = '';
  }

  getLatestBookings(bookingStatus: any, dataSource: any, dataChart: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bookingService.getBookingsLatest(this.currentRequest, 5, bookingStatus).then((result: any) => {
        if (result.status === 'OK') {
          dataSource.data = result.bookingList;
          // Set the height of the chart (increase only if there's 4 or 5 elements in data table)
          if (result.bookingList.length === 4) { dataChart.chart.height = 225; }
          if (result.bookingList.length === 5) { dataChart.chart.height = 275; }
          // Populate enquiry chart as per Sofia's proposition
          this.populateLast5Chart(result.bookingList, dataChart).then(res => {
            resolve('');
          });
        } else {
          this.sendMessageToDialog('', 'Failed to produce last 5 bookings (' + result.status + ')', '', ''); // Print error message
          resolve('');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1301S)', error, this.currentRequest);
      });
    });
  }

  getBankingReport(dataType: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reportStructure = { value: 'getBankingReport', template: 'bankingReport' }; // Value holds API URL name, template the version of report (currently one..)

      // Create a request variable below with the pre-defined date type
      const reportRequest: any = this.currentRequest; reportRequest.dateType = 'receiptDate';
      // Set start and end dates below
      reportRequest.startDate = this.bankingFrom; reportRequest.endDate = this.bankingTo;
      // Set bookingGroup to empty string
      reportRequest.bookingGroup = '';
      // Set the version to 'summary' as default
      reportRequest.version = 'summary';

      // Apply agentEmail filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        reportRequest.agentEmail = Session.mySession.getUser().email;
      }

      this.pageLoaded = false;
      this.reportsService.getReport(reportStructure, reportRequest, dataType).then((output: any) => {
        if (output.status === 'OK' && dataType === 'rtnData') {
          output.reportData.shift(); // Remove first line from the output array
          output.reportData.pop(); // Remove last line from the output array
          this.bankingReportData.data = output.reportData; // Assign the output to data table
          this.badgeValues.dailyNo = output.reportData.filter((row: any) => row[12] === 'no').length; // Work out how many 'unticked' rows there is
          this.calculateTotalValue(this.bankingReportData.data, 'banking'); // Calculate the SUM
          this.populateDailyBaChart(output.reportData); // Populate reminder chart here
          this.pageLoaded = true; resolve('');
        } else {
          this.sendMessageToDialog('', 'Failed to produce Daily Banking (' + output.status + ')', '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1302S)', error, reportRequest);
      });
    });
  }

  getCustDueReport(type: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let dateFrom: any = new Date(); dateFrom.setDate(dateFrom.getDate()); // Get today's date
      let dateTo: any = new Date(); dateTo.setDate(dateTo.getDate() + 29); // Get 'in 4 weeks' date here
      dateFrom = this.constants.convertDateNotMoment(dateFrom); // Convert the date so Ruby accepts it
      dateTo = this.constants.convertDateNotMoment(dateTo); // Convert the date so Ruby accepts it
      const reportStructure = { value: 'getCustBalDueReport', template: 'custBalDueV1' }; // Value holds API URL name, template the version of report (currently one..)

      // Create a request variable below with the pre-defined date type
      const reportRequest: any = this.currentRequest; reportRequest.dateType = 'balanceDueDate';
      // Set start and end dates below
      reportRequest.startDate = dateFrom; reportRequest.endDate = dateTo;
      // Set bookingGroup to empty string
      reportRequest.bookingGroup = '';
      // Set the version to 'summary' as default
      reportRequest.version = 'summary';

      // Apply agentEmail filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        reportRequest.agentEmail = Session.mySession.getUser().email;
      }

      this.pageLoaded = false;
      this.reportsService.getReport(reportStructure, reportRequest, type).then((output: any) => {
        if (output.status === 'OK' && type === 'rtnData') {
          output.reportData.shift(); // Remove first line from the output array
          output.reportData.pop(); // Remove last line from the output array
          this.customerDueReportData.data = output.reportData; // Assign the output to data table
          this.badgeValues.reminderNo = output.reportData.filter((row: any) => Number(row[12]) !== 0).length; // Work out how many 'unticked' rows there is
          this.calculateTotalValue(this.customerDueReportData.data, 'custDue'); // Calculate the SUM
          this.populateRemindersChart(output.reportData, dateFrom, dateTo); // Populate reminder chart here
          this.pageLoaded = true; resolve('');
        } else {
          this.sendMessageToDialog('', 'Failed to produce Reminders (' + output.status + ')', '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1303S)', error, reportRequest);
      });
    });
  }

  getYtdReport(): Promise<any> {
    return new Promise((resolve, reject) => {
      const sessionData = Session.mySession.getYtdReportsValues(); // Get the session data - check if already exist!
      // If the data does not exist (yet), we'll need to get it via API
      if (!sessionData) {
        this.reportsService.getYtdReport(this.currentRequest).then((output: any) => {
          if (output.status === 'OK') {
            Session.mySession.setYtdReportsValues(output); // Set session variables below
            this.computeYtdReport(output.ytdReport); // Call method to compute and display values
            resolve('');
          } else {
            this.sendMessageToDialog('', 'Failed to produce YTD report (' + output.status + ')', '', ''); resolve('');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1303S)', error, this.currentRequest);
        });
      } else {
        this.computeYtdReport(sessionData.ytdReport); // Call this method to 'compute' the data and feed into chart
        resolve('');
      }
    });
  }

  getMonthlyReturns(tradeCode: any, fullData: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Number(this.monthlyYear) < 2024 || Number(this.monthlyYear) > 2030 || Number(this.monthlyMonth) < 1 || Number(this.monthlyMonth) > 12) {
        this.sendMessageToDialog('', 'One of requested dates is invalid', '', ''); resolve('');
      } else if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff' || this.userType === 'memberManager') {
        const request: any = {
          company: 'tta', operation: 'tta', year: this.monthlyYear, month: this.monthlyMonth, fullData, token: Session.mySession.get('user').token
        }
        // Set the trade code to our request - much needed.. [ONLY FOR NON-Q0000 requests]
        if (this.userType === 'memberManager' || (tradeCode !== 'Q0000' && (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff'))) {
          request.tradeCode = tradeCode;
        }
        // We need to re-set monthly return data breakdown first (set to empty array)
        this.monthlyReturnBreakdown.data = [];

        this.pageLoaded = false;
        this.reportsService.getMonthlyReturns(request).then((output: any) => {
          if (output.status === 'OK') {

            if (fullData) {
              this.motnhlyReturnBranch = output.branchDetails; // Branch details to be displayed on the return
              this.monthlyReturnBreakdown.data = output.breakdown; // Breakdown - display each individual booking..
              this.monthlyReturnBreakdown.paginator = this.paginatorReturns; // Apply pagination
            }
            // If the request had Q0000 as a tradeCode (or not..), we will receive all returns at once (without breakdowns)
            // The summary is then re-set, so the admin user needs to select the branch which will pull the breakdown..
            if (tradeCode === 'Q0000') {
              this.monthlyReturnAll = output.data; this.monthlyReturnSummary = {}; this.monthlyTradeCode = '';
              this.monthlyReturnCheckbox = output.data; this.monthlyRetFiltered = output.data; this.branchFilterStr = '';
            } // Request was for single trade code, so we will automatically assign the report to the variable..
            else { this.monthlyReturnSummary = output.data[0]; }
            // Carry on with below methods..
            this.recalcMonthlyReport();
            this.formatMonthYear();
            this.pageLoaded = true;
            resolve('');
          } else {
            this.sendMessageToDialog('', 'Failed to produce monthly report (' + output.status + ')', '', ''); resolve('');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1304S)', error, request);
        });
      }
    });
  }

  authenticate(form: NgForm): void {
    const part1 = "I hereby declare that the information I have confirmed in the Monthly Returns Compliance Certificate is correct and we have acted in accordance with the TTA Code of Conduct.";
    const part2 = "I confirm that a Standalone Safe Seat Plan Guarantee has been issued to all customers booked throughout this month and has been declared correctly on the Monthly Returns Compliance Certificate.";
    const final = "Do you wish to submit?";

    if(this.userType != 'memberManager' || confirm(part1)) {
      if(this.userType != 'memberManager' || confirm(part2)) {
        if(this.userType != 'memberManager' || confirm(final)) {
          // Use login funcitonality (copied from login) to authenticate the user
          const request = { email: form.value.email, password: form.value.password };
          this.pageLoaded = false;
          this.userService.login(request).then(async (output: any) => {
            if (output.status === 'OK') {
              // Re-do sesson variables as we've just logged in
              Session.mySession.set('user', { id: output.data.id, token: output.data.token });
              Session.mySession.setToken(output.data.token);
              // Depending on who's authenticated, we will either sign or approve the monthly report
              if (this.userType === 'memberManager') { this.confirmMonthlyReturn(); }
              else { this.approveMonthlyReturn(); }
            } else {
              this.pageLoaded = true;
              this.sendMessageToDialog('', output.status, '', ''); // Display error status from the back
            }
          }).catch((error: any) => {
            this.pageLoaded = true;
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1305S) - please try again', error, request);
          })
        }
      }
    }
  }

  confirmMonthlyReturn(): void {
    const request = {
      company: this.monthlyReturnSummary.company, operation: this.monthlyReturnSummary.operation, tradeCode: this.monthlyReturnSummary.tradeCode,
      year: this.monthlyYear, month: this.monthlyMonth, monthlyArr: [this.monthlyReturnSummary], monthlyBreakdown: this.monthlyReturnBreakdown.data,
      agentName: Session.mySession.getUser().fullName, agentEmail: Session.mySession.getUser().email,
      token: Session.mySession.get('user').token
     }

     this.pageLoaded = false;
     this.reportsService.confirmMonthlyReturn(request).then((output: any) => {
      if (output.status === 'OK') {
        this.sendMessageToDialog('Your monthly report has been signed off', '', '', '');
        this.getMonthlyReturns(this.currentRequest.tradeCode, true);
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
     }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1306S) - please try again', error, request);
    })
  }

  approveMonthlyReturn(): void {
    const request = {
      company: this.monthlyReturnSummary.company, operation: this.monthlyReturnSummary.operation, tradeCode: this.monthlyReturnSummary.tradeCode,
      year: this.monthlyYear, month: this.monthlyMonth, monthlyArr: [this.monthlyReturnSummary], monthlyBreakdown: this.monthlyReturnBreakdown.data,
      agentName: Session.mySession.getUser().fullName, agentEmail: Session.mySession.getUser().email,
      token: Session.mySession.get('user').token
     }

     this.pageLoaded = false;
     this.reportsService.approveMonthlyReturn(request).then((output: any) => {
      if (output.status === 'OK') {
        this.sendMessageToDialog('Monthly report has been approved', '', '', '');
        this.getMonthlyReturns(this.currentRequest.tradeCode, true);
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
     }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1307S) - please try again', error, request);
    })
  }

  computeYtdReport(ytdData: any): void {
    // This here means that everyrhing probably went ok and chart exists..
    if (this.userType === 'memberManager' || this.userType === 'memberStaff') {
      this.populateYtdChartNormal(ytdData); // Populate chart
    } else {
      this.performanceList = ytdData.reduce((r: any, a: any) => {
        r[a.branchName] = r[a.branchName] || []; // Rather than having a list with ALL branches
        r[a.branchName].push(a); // We're adding branches to the list ONLY if they exist in the YTD output
        return r; // Right here!
      }, Object.create(null));
      this.performanceListAll = this.performanceList; // This is needed for filtering
      this.selBranchAdmin = Object.values(this.performanceList)[0]; // This variable will be used in select list (admin only)
      this.populateYtdChartNormal(Object.values(this.performanceList)[0]); // Populate chart
    }
  }

  populateLast5Chart(bookingList: any, globalChart: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const chartData: any = []; // This is where series data lands at the end
      const seriesData: any = { name: 'Gross', data: [] }; // Variable which will hold data used in the chart
      const data: any = []; // This is where pure data lads

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < bookingList.length; i++) {
        data.unshift(bookingList[i].gross); // Each booking's gross value lands in the data array
        globalChart.xaxis.categories.unshift(bookingList[i].bookingReference); // Chart variable xaxis is also populated with the booking reference
      }
      seriesData.data = data; chartData.push(seriesData); // Assign data to the series data and push the whole thing to array container
      globalChart.series = chartData; // Assign series data calculated above
      window.dispatchEvent(new Event('resize')); // Used so all elements are 'set in places'
      resolve('');
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Home Component - populateLast5Chart() failed');
    });
  }

  populateRemindersChart(reportData: any, dateFrom: any, dateTo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Convert date range using 'moment' function (date range taken from function calling this method)
      const fromDate: any = moment(dateFrom); const toDate: any = moment(dateTo);

      // Empty array lists for report data
      let filtered: any = [];
      // Empty array lists for series objects
      const updateSeriesArray: any = [];
      // SinGS needs to go through each day within date range and check for the rows in the report with it
      for (const day = moment(fromDate); day.diff(toDate, 'days') <= 0; day.add(1, 'days')) {
        // Create series object which will store x - date and y - total amount (££££)
        const seriesObject: any = { x: '', y: '' };
        seriesObject.x = day.format('YYYY-MM-DD');
        // Filter out/in rwos from the report and assign results into variables below
        filtered = reportData.filter((row: any) => row[6].includes(day.format('YYYY-MM-DD')));
        // Add up all of the rows' values together and assign it to series y variable
        if (filtered.length > 0) {
          seriesObject.y = filtered.reduce((sum: any, current: any) => sum + Number(current[12]), 0).toFixed(2);
        } else {
          seriesObject.y = 0;
        }
        // Add series object into array list
        updateSeriesArray.push(seriesObject);
      }
      // Update chart series with series objects created earlier for temp/non-temp references
      this.reminderChart.series = [{ name: 'Balance Due', data: updateSeriesArray }];
      resolve('');
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Home Component - populateRemindersChart() failed');
    });
  }

  populateDailyBaChart(reportData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Variables holding total £££ amounts
      let sumTicked = 0; let sumUnticked = 0;
      // Calculate the total number of ticked / unticked receipts
      if (reportData.length > 0) {
        sumUnticked = reportData.filter((row: any) => row[12].includes('no')).length;
        sumTicked = reportData.filter((row: any) => row[12].includes('yes')).length;
      } else {
        sumTicked = 0; sumUnticked = 0;
      }
      // Use the total number of ticked / unticked to populate chart sieries here..
      this.dailyBaChart.series = [Number(sumTicked), Number(sumUnticked)];
      resolve('');
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Home Component - populateDailyBaChart() failed');
    });
  }

  populateYtdChartNormal(reportData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Create variables holding all numbers / data below
      const grossSeries: any = { name: 'Gross', data: [], type: 'area' }; const netSeries: any = { name: 'Net', data: [], type: 'area' };
      const taxSeries: any = { name: 'Tax', data: [], type: 'area' }; const commSeries: any = { name: 'Commission', data: [], type: 'area' };
      const paxSeries: any = { name: 'Total Pax', data: [], type: 'line' }; const bookingsSeries: any = { name: 'Total Bookings', data: [], type: 'line' };
      // Reset bottom axis - very much needed to be done this way. Otherwise it's 'undefined'
      this.ytdMoneyChart.xaxis.categories.splice(0); this.ytdPaxBookChart.xaxis.categories.splice(0);
      // Hopefully report data isn't empty - we'll need to loop through it!
      if (typeof reportData !== 'undefined' && reportData.length > 0) {
        reportData.forEach((monthSum: any) => {
          grossSeries.data.push(monthSum.custPrice); // Push number in the array!
          netSeries.data.push(monthSum.net); // Push number in the array!
          taxSeries.data.push(monthSum.tax); // Push number in the array!
          commSeries.data.push(monthSum.commission); // Push number in the array!
          paxSeries.data.push(monthSum.paxNo); // Push number in the array!
          bookingsSeries.data.push(monthSum.bookingNo); // Push number in the array!
          // Convert year and month into format easily read by people below..
          this.ytdMoneyChart.xaxis.categories.push(moment(monthSum.year + String(this.constants.zeroBeforeNo(monthSum.month))).format('MMM YY'));
          this.ytdPaxBookChart.xaxis.categories.push(moment(monthSum.year + String(this.constants.zeroBeforeNo(monthSum.month))).format('MMM YY'));
        });
        // Append all data into globally used chart
        this.ytdMoneyChart.series = [grossSeries, netSeries, commSeries];
        this.ytdPaxBookChart.series = [paxSeries, bookingsSeries];
        // Assign all necessary data for the top - details above our chart
        this.ytdChartDetails.currentMonthSales = Number(grossSeries.data[grossSeries.data.length - 1]);
        this.ytdChartDetails.lastMonthSales = Number(grossSeries.data[grossSeries.data.length - 2]);
        if (reportData.length === 12) { this.ytdChartDetails.lastYearSales = Number(grossSeries.data[11]); }
      }
      resolve('');
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Home Component - populateYtdChartNormal() failed');
    });
  }

  calculateTotalValue(reportData: any, reportType: any): void {
    let totalValue: any = 0; // Need only one variable at the moment in both reports..
    if (reportType === 'banking') {
      // Banking report shows only one value we can sum which is 'Value' column - amount banked within date range
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < reportData.length; i++) {
        totalValue = Number(totalValue) + Number(reportData[i][11]);
      }
      this.bankingReportTotalValue.value = totalValue;
    } else if (reportType === 'custDue') {
      // Cusotmer due report shows only one value we can sum which is 'Value' column - amount banked within date range
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < reportData.length; i++) {
        totalValue = Number(totalValue) + Number(reportData[i][12]);
      }
      this.custDueTotalValue.value = totalValue;
    } else if (reportType === 'monthlyReturns') {
      // Monthly report - we will need to add up the total number of passengers (for ony only this..)
      this.monthlyReportPaxNo.bookings = reportData.filter((booking: any) => !booking.oldBooking).length;
      this.monthlyReportPaxNo.paxNo = reportData.reduce((total: any, booking: any) => total + booking.paxNoSbmt, 0);
      this.monthlyReportPaxNo.paxNoTAtolFlOnly = reportData.reduce((total: any, booking: any) => total + booking.tAtolPaxFlOnlySbmt, 0);
      this.monthlyReportPaxNo.paxNoTAtolPackage = reportData.reduce((total: any, booking: any) => total + booking.tAtolPaxPkgSbmt, 0);
    }
  }

  recalcMonthlyReport(): void {
    this.calculateTotalValue(this.monthlyReturnBreakdown.data, 'monthlyReturns');

    if (this.userType === 'sinGSAdmin') {
      if (this.monthlyReportPaxNo.paxNo !== this.monthlyReturnSummary.paxNo) {
        this.monthlySubmitDisabled = true;
      } else if (this.monthlyReportPaxNo.paxNoTAtolFlOnly !== this.monthlyReturnSummary.tAtolPaxFlOnly) {
        this.monthlySubmitDisabled = true;
      } else if (this.monthlyReportPaxNo.paxNoTAtolPackage !== this.monthlyReturnSummary.tAtolPaxPkg) {
        this.monthlySubmitDisabled = true;
      } else {
        this.monthlySubmitDisabled = false;
      }
    } else {
      this.monthlySubmitDisabled = false;
    }
  }

  goToBooking(bookingReference: any, mode: any): void {
    AppComponent.myapp.closeBooking('fromScript').then((res: any) => {
      if (mode === 'normal') {
        AppComponent.myapp.navigateToBooking(bookingReference, true);
      } else if (mode === 'reminder') {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => // Go quickly to Dashboard
          this.router.navigate(['/booking/', bookingReference, 5])); // And enter bookign reference URL (to re-draw everything..)
      } else if (mode === 'overview') {
        AppComponent.myapp.bookingPopSwitch(false);
        AppComponent.myapp.navigateToBooking(bookingReference, false);
        AppComponent.myapp.bookingPopSwitch(true);
      }
    });
  }

  exportToExcel(whatData: any): void {
    const exportMe: any = [];
    let oneLine: any = {};

    // Depending on what page we're trying to export, we'll be using different
    // variable storing data. An object represents one line which are then pushed
    // into array list. That represents excel sheet in short
    if (whatData === 'bankingReport') {
      this.bankingReportData.data.forEach((data: any) => {
        oneLine = {};
        oneLine.bookingReference = data[0];
        oneLine.externalReference = data[1];
        oneLine.tradingName = data[3];
        oneLine.leadName = data[6];
        oneLine.receiptDate = data[8];
        oneLine.payerName = data[9];
        oneLine.paymentMethod = data[10];
        oneLine.value = data[11];
        oneLine.checked = data[12];
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'dailyBanking');
    } else if (whatData === 'customerDueReport') {
      this.customerDueReportData.data.forEach((data: any) => {
        oneLine = {};
        oneLine.bookingReference = data[0];
        oneLine.externalReference = data[1];
        oneLine.tradingName = data[3];
        oneLine.leadPax = data[4];
        oneLine.custDueDate = data[5];
        oneLine.suppDueDate = data[6];
        oneLine.deptDate = data[7];
        oneLine.returnDate = data[8];
        oneLine.gross = data[9];
        oneLine.discount = data[10];
        oneLine.totalReceipted = data[11];
        oneLine.balanceDue = data[12];
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'reminders');
    } else if (whatData === 'monthlyReturns') {
      this.monthlyReturnAll.forEach((data: any) => {
        oneLine = {};
        oneLine.tradeCode = data.tradeCode;
        oneLine.branchName = data.branchName;
        oneLine.month = data.month;
        oneLine.bookingNo = data.bookingNo;
        oneLine.paxNo = data.paxNo;
        oneLine.tAtolFliOnly = data.tAtolPaxFlOnly;
        oneLine.tAtolPackage = data.tAtolPaxPkg;
        oneLine.status = data.status;
        oneLine[''] = '';
        oneLine.signeeEmail = data.signeeEmail;
        oneLine.signeeName = data.signeeName;
        oneLine.signedTS = data.signedTS;
        oneLine[' '] = '';
        oneLine.approvedEmail = data.approvedEmail;
        oneLine.approvedName = data.approvedName;
        oneLine.approvedTS = data.approvedTS;
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'monthlyReturns');
    } else if (whatData === 'monthlyBreakdown') {
      this.monthlyReturnBreakdown.data.forEach((data: any) => {
        oneLine = {};
        oneLine.bookingReference = data.bookingReference;
        oneLine.yourReference = data.extBookingSource;
        oneLine.leadName = data.leadName;
        oneLine.paxNo = data.paxNoSbmt;
        oneLine.tAtolPaxPkg = data.tAtolPaxPkgSbmt;
        oneLine.tAtolPaxFlOnly = data.tAtolPaxFlOnlySbmt;
        oneLine.bookingDate = data.bookingDate;
        oneLine.bookedTS = data.bookedTS;
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'monthlyBreakdown');
    }
  }

  checkBankingRow(bankRow: any): void {
    const request: any = {
      company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation, tradeCode: this.currentRequest.tradeCode,
      bookingReference: bankRow[0], receiptCount: bankRow[4], receiptDate: bankRow[8], tickedOff: 'yes', token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.receiptService.updateReceiptSeq(request).then((output: any) => {
      if (output.status === 'OK') {
        this.getBankingReport('rtnData');
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1305S)', error, request);
    });
  }

  changeBranch(tradeCode: any): void {
    this.currentRequest.tradeCode = tradeCode; // Assign new trade code to the 'global' request
    this.pageLoaded = false; this.loadPage(); // Get user list for selected trade code
  }

  changeDate(dateType: any, date: any): void {
    // Translate for Felloh-friendly date and reload Felloh data
    if (dateType === 'fromDate') {
      if (date.value != null) {
        this.bankingFrom = this.constants.convertDateMoment(date.value);
      }

      if (this.bankingTo._i !== undefined) {
        this.bankingTo = this.constants.convertDateMoment(this.bankingTo);
      }
    } else if (dateType === 'toDate') {
      if (date.value != null) {
        this.bankingTo = this.constants.convertDateMoment(date.value);

        if (this.bankingFrom._i !== undefined) {
          this.bankingFrom = this.constants.convertDateMoment(this.bankingFrom);
        }
      }
    }

    // Make sure none of these two are null (non-date value entered in the field)
    if (this.bankingTo != null && this.bankingFrom != null) {
      // Check if the date is in the right format here
      if (moment(this.bankingTo, 'YYYY-MM-DD', true).isValid() && moment(this.bankingFrom, 'YYYY-MM-DD', true).isValid()) {
        this.pageLoaded = false;
        this.getBankingReport('rtnData');
      } else {
        this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
      }
    } else {
      this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
    }
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    this.myLast5 = false;
    this.dailyBanking = false;
    this.reminders = false;
    this.performanceView = false;
    this.monthlyReturnView = false;

    if (view === 'myLast') { this.myLast5 = true; }
    else if (view === 'dailyBanking') { this.dailyBanking = true; }
    else if (view === 'reminders') { this.reminders = true; }
    else if (view === 'performanceView') { this.performanceView = true; }
    else if (view === 'monthlyReturnView') { this.monthlyReturnView = true; this.getMonthlyReturns(this.currentRequest.tradeCode, true); }
  }

  formatMonthYear(): void {
    // Split the input value into year and month
    const year: any = this.monthlyYear;
    const month: any = this.monthlyMonth;
  
    // Create a new Date object with the given year and month
    const date = new Date(year, month - 1); // month is 0-indexed
  
    // Define options for formatting the month
    const options = { year: 'numeric', month: 'long' } as const;
  
    // Use the Intl.DateTimeFormat API to format the date
    this.monthlyPeriod = new Intl.DateTimeFormat('en-US', options).format(date);
  }

  setMonthlyDates(): void {
    const currentDate = new Date(); // Get current date
    let currentMonth = currentDate.getMonth(); // getMonth() is zero-based
    let currentYear = currentDate.getFullYear();
    
    // Adjust to get the previous month
    if (currentMonth === 0) { // January
      currentMonth = 12; // December
      currentYear -= 1; // Previous year
    } else if (currentMonth === 1) {
      currentMonth = currentMonth;
    }
    // Set variables to be displayed on the page
    this.currentDate = currentDate;
    this.monthlyMonth = currentMonth;
    this.monthlyYear = currentYear;
  }

  showExtRefSwitch(): void {
    AppComponent.myapp.showExtRefSwitch(!this.showExtReference);
    this.showExtReference = AppComponent.myapp.showExternalRef;
  }

  filterMonthly(): void {
    this.performanceList = []; // Empty filtered array first
    const filter = this.branchFilterStr.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    const filteredHash = Object.keys(this.performanceListAll)
    .filter(key => key.toLowerCase().includes(filter.toLowerCase())).reduce((acc, key) => {
      acc[key] = this.performanceListAll[key];
      return acc;
    }, {} as { [key: string]: any });

    this.performanceList = filteredHash;
  }

  filterBranches(): void {
    this.monthlyRetFiltered = []; // Empty filtered array first
    const filter = this.branchFilterStr.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.monthlyReturnCheckbox.length; i++) {
      const option = this.monthlyReturnCheckbox[i];
      if (option.branchName.toLowerCase().indexOf(filter) >= 0 || option.tradeCode.toLowerCase().indexOf(filter) >= 0) {
        this.monthlyRetFiltered.push(option);
      }
    }
  }

  filterBranchesCheckbox(checkboxType: string): void {
    // If 'Pending' checkbox is selected
    if (checkboxType === 'pending') {
      if (this.monthlyBoxFilter.pending) {
        this.monthlyBoxFilter.approved = false; // Deselect 'Approved' if 'Pending' is checked
        this.monthlyReturnCheckbox = this.monthlyReturnAll.filter((item: any) => item.status === 'Pending');
      } else {
        this.monthlyReturnCheckbox = this.monthlyReturnAll; // Reset to all if unchecked
      }
    }
    
    // If 'Approved' checkbox is selected
    else if (checkboxType === 'approved') {
      if (this.monthlyBoxFilter.approved) {
        this.monthlyBoxFilter.pending = false; // Deselect 'Pending' if 'Approved' is checked
        this.monthlyReturnCheckbox = this.monthlyReturnAll.filter((item: any) => item.status === 'Approved');
      } else {
        this.monthlyReturnCheckbox = this.monthlyReturnAll; // Reset to all if unchecked
      }
    }
  
    // Apply filtering based on updated checkbox states
    if (!this.monthlyBoxFilter.pending && !this.monthlyBoxFilter.approved) {
      this.monthlyReturnCheckbox = this.monthlyReturnAll; // If both are unchecked, show all
    }
  
    this.filterBranches(); // Call filterBranches after setting the filtered list
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }
}
