import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BjornService {

  constructor(private http: HttpClient) { }

  async getTempBookingRef(token: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const output = await this.http.post(environment.apiURL + 'getTempBookingRef?token=' + token, { headers }).toPromise();
    return output;
  }

  async isValidBookingRef(request: any): Promise<object> {
    let type = '';
    if (request.company === 'ttng') {
      type = 'uat3';
    } else if (request.company === 'gtg') {
      type = 'gloUAT';
    }
    const output = await this.http.get(environment.apiURL + 'isValidBookingRef?company=' + request.company + '&operation=' + request.operation +
      '&tradeCode=' + request.tradeCode + '&bookingRef=' + request.bookingRef + '&type=' + type + '&token=' + request.token).toPromise();
    return output;
  }
}
