import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private http: HttpClient) { }

  async getPublicKeys(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getPublicKeys?company=' + request.company +
      '&operation=' + request.operation + '&tradeCode=' + request.tradeCode + '&bookingReference=' + request.bookingReference +
      '&apiCalling=' + request.apiCalling + '&token=' + request.token).toPromise();
    return output;
  }

  async createPublicKey(request: any): Promise<object> {
    const output = await this.http.post(environment.apiURL + 'createPublicKey?company=' + request.company +
      '&operation=' + request.operation + '&tradeCode=' + request.tradeCode + '&bookingReference=' + request.bookingReference +
      '&apiCalling=' + request.apiCalling + '&limitUse=' + request.limitUse + '&token=' + request.token, request).toPromise();
    return output;
  }

  async regeneratePublicKey(request: any): Promise<object> {
    const output = await this.http.post(environment.apiURL + 'regeneratePublicKey?company=' + request.company +
      '&operation=' + request.operation + '&tradeCode=' + request.tradeCode + '&bookingReference=' + request.bookingReference +
      '&apiCalling=' + request.apiCalling + '&limitUse=' + request.limitUse + '&publicRequest=' + request.publicRequest + '&token=' + request.token, request).toPromise();
    return output;
  }
}
