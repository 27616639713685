<div class="mainDiv">
    <div class="chosenDiv">
        <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="bankAccData">
            <ng-container matColumnDef="accountType">
                <mat-header-cell *matHeaderCellDef>Account Type</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.accountType }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Account Type" name="accountType"
                    #accountType="ngModel" [(ngModel)]="newAccount.accountType" maxlength="100">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="accountName">
                <mat-header-cell *matHeaderCellDef>Account Name</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.accountName }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Account Name" name="accountName"
                #accountName="ngModel" [(ngModel)]="newAccount.accountName" maxlength="200">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="bankName">
                <mat-header-cell *matHeaderCellDef>Bank Name</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.bankName }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Bank Name" name="bankName"
                #bankName="ngModel" [(ngModel)]="newAccount.bankName" maxlength="100">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="accountNumber">
                <mat-header-cell *matHeaderCellDef>Account Number</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.accountNumber }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Account Number" name="accountNumber"
                    #accountNumber="ngModel" [(ngModel)]="newAccount.accountNumber" pattern="[0-9]+" minlength="8" maxlength="12">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="sortCode">
                <mat-header-cell *matHeaderCellDef>Sort Code</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.sortCode }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Sort Code" name="sortCode"
                    #sortCode="ngModel" [(ngModel)]="newAccount.sortCode" pattern="[0-9]+" minlength="6" maxlength="8">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="iban">
                <mat-header-cell *matHeaderCellDef>IBAN</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.iban }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="IBAN" name="iban"
                    #iban="ngModel" [(ngModel)]="newAccount.iban" pattern="[0-9]+" minlength="15" maxlength="50">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="swift">
                <mat-header-cell *matHeaderCellDef>SWIFT/BIC</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.swift }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="SWIFT/BIC" name="swift"
                    #swift="ngModel" [(ngModel)]="newAccount.swift" pattern="[0-9]+" minlength="8" maxlength="20">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="currency">
                <mat-header-cell *matHeaderCellDef>Currency</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">{{ bankAcc.currency }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Currency" name="currency"
                    #currency="ngModel" [(ngModel)]="newAccount.currency" minlength="3" maxlength="3">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
                <mat-cell *matCellDef="let bankAcc">
                    <span *ngIf="bankAcc.active == 'yes'">
                        <button mat-button color="primary" type="button" [disableRipple]="true"
                        (click)="editBankAccount(bankAcc, 'no')">
                            <mat-icon>done</mat-icon>
                        </button>
                    </span>

                    <span *ngIf="bankAcc.active == 'no'">
                        <button mat-button color="warn" type="button" [disableRipple]="true"
                        (click)="editBankAccount(bankAcc, 'yes')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <button type="button" mat-button color="primary" [disableRipple]="true"
                    (click)="createBankAccount()">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns : displayedColumns;" class="trueRow"></mat-row>
            <mat-footer-row class="rowNotClick" *matFooterRowDef="displayedColumns"></mat-footer-row>
        </mat-table>

        <h3 style="text-align: center;">Fill in above fields in order to add another account</h3>

        
    </div>
</div>

<ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
        <p>{{errorMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>

    <div *ngIf="successMessage != ''" class="successMessage">
        <p>{{successMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>
</ng-template>