import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientJsonpModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxFilesizeModule } from 'ngx-filesize';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ElementTypeComponent } from './components/element-type/element-type.component';
import { ElementCreateComponent } from './components/element-create/element-create.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { SupplierListComponent } from './components/supplier-list/supplier-list.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { BranchListComponent } from './components/branch-list/branch-list.component';
import { BookingPortfolioComponent } from './components/booking-portfolio/booking-portfolio.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { ElementEditComponent } from './components/element-edit/element-edit.component';
import { FinancialReportsComponent } from './components/financial-reports/financial-reports.component';
import { ConfigMarketingComponent } from './components/config-marketing/config-marketing.component';
import { SupplementListComponent } from './components/supplement-list/supplement-list.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { UserGroupsComponent } from './components/user-groups/user-groups.component';
import { ElementPackageComponent } from './components/element-package/element-package.component';

import { IgnoreWheelDirective } from './common/ignore-wheel-directive';

import { PrfComponent } from './wcFelloh/prf/prf.component';
import { TapsComponent } from './wcFelloh/taps/taps.component';
import { PaymentsOutComponent } from './wcFelloh/payments-out/payments-out.component';
import { ChargesComponent } from './wcFelloh/charges/charges.component';
import { BankAccountsComponent } from './components/bank-accounts/bank-accounts.component';
import { BookingExternalComponent } from './components/booking-external/booking-external.component';
import { BankingComponent } from './components/banking/banking.component';
import { AsynchRequests } from './common/asynch-requests';
import { DialogService } from './common/custom-dialog';
import { EmailPopupComponent } from './components/email-popup/email-popup.component';
import { DocumentSettingsComponent } from './components/booking-portfolio/document-settings/document-settings.component';
import { FellohTransactionsComponent } from './wcFelloh/felloh-transactions/felloh-transactions.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PaymentLinksComponent } from './wcFelloh/payment-links/payment-links.component';
import { NewLinkComponent } from './wcFelloh/payment-links/new-link/new-link.component';
import { RequestSupplierComponent } from './components/supplier-list/request-supplier/request-supplier.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ElementTypeComponent,
    ElementCreateComponent,
    UserListComponent,
    SupplierListComponent,
    CustomerListComponent,
    BranchListComponent,
    BookingPortfolioComponent,
    BookingListComponent,
    ElementEditComponent,
    FinancialReportsComponent,
    ConfigMarketingComponent,
    SupplementListComponent,
    ChangelogComponent,
    PrfComponent,
    TapsComponent,
    PaymentsOutComponent,
    UserGroupsComponent,
    ElementPackageComponent,
    ChargesComponent,
    BankAccountsComponent,
    BookingExternalComponent,
    IgnoreWheelDirective,
    BankingComponent,
    EmailPopupComponent,
    DocumentSettingsComponent,
    FellohTransactionsComponent,
    ContactsComponent,
    PaymentLinksComponent,
    NewLinkComponent,
    RequestSupplierComponent
  ],
  imports: [
    BrowserModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatChipsModule,
    MatListModule,
    MatToolbarModule,
    MatRadioModule,
    MatMenuModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSidenavModule,
    MatBadgeModule,
    MatIconModule,
    MatTooltipModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    ClipboardModule,
    NgxQRCodeModule,
    FlexLayoutModule,
    FormsModule,
    NgApexchartsModule,
    AngularSvgIconModule.forRoot(),
    NgxCsvParserModule,
    NgxFilesizeModule,
    DragDropModule
  ],
  providers: [AsynchRequests, DialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
