import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { AsynchRequests } from '../../common/asynch-requests';
import { Documents } from '../../common/documents';
import { GlobalConstants } from '../../common/global-constants';
import { SupplierService } from '../../services/supplier.service';
import { BookingService } from '../../services/booking.service';
import { CustomerService } from '../../services/customer.service';
import { ElementService } from '../../services/element.service';
import { ReceiptService } from '../../services/receipt.service';
import { FellohService } from '../../services/felloh.service';
import { PaymentService } from '../../services/payment.service';
import { NoteService } from '../../services/note.service';
import { ReportsService } from '../../services/reports.service';
import { UserService } from '../../services/user.service';
import { PublicService } from '../../services/public.service';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';

import { NgxQrcodeElementTypes } from '@techiediaries/ngx-qrcode';
import { NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from './../../../environments/environment';
import { EmailTemplates } from 'src/app/common/email-templates';

@Component({
  selector: 'app-booking-portfolio',
  templateUrl: './booking-portfolio.component.html',
  styleUrls: ['./booking-portfolio.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpandWithinTable', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inOutAnimationSupplement',
      [
        transition(
          ':enter',
          [
            style({ height: 0 }),
            animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: '*' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*' }),
            animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 0 }))
          ]
        )
      ]
    ),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class BookingPortfolioComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  titles = GlobalConstants.titles;
  countryList = GlobalConstants.countryList;
  countryFiltered = GlobalConstants.countryList;
  holidayInterests = GlobalConstants.holidayInterests;
  // insuranceTypes = GlobalConstants.insuranceTypes;
  sfcPricing = GlobalConstants.sfcPricing;
  safiPricing = GlobalConstants.safiPricing;
  standaloneCustoms = GlobalConstants.standaloneCustoms;
  fellohPayGate = environment.fellohPayGate;
  singsCustomerURL = environment.singsCustomerURL;
  innerWidth = AppComponent.myapp.innerWidth;
  memberLive = AppComponent.myapp.memberLive;
  userList = [];

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('chartDialog') chartDialog!: TemplateRef<any>;
  @ViewChild('groupedBookingsDialog') groupedBookingsDialog!: TemplateRef<any>;
  @ViewChild('emailPopUpDialog') emailPopUpDialog!: TemplateRef<any>;
  @ViewChild('docSettingsDialog') docSettingsDialog!: TemplateRef<any>;

  // Table data and table columns below
  passengersData: any = new MatTableDataSource<any>();
  customerData: any = new MatTableDataSource<any>();
  costingsData: any = new MatTableDataSource<any>();
  supplementsData: any = new MatTableDataSource<any>();
  receiptsData: any = new MatTableDataSource<any>();
  receiptsOrgn: any = []; // Needed for filtering..
  paymentsData: any = new MatTableDataSource<any>();
  paymentsOrgn: any = []; // Needed for filtering..
  // insurancesData: any = new MatTableDataSource<any>();
  fellohData: any = new MatTableDataSource<any>();
  attachedDocs: any = new MatTableDataSource<any>();
  bookingNotes: any = new MatTableDataSource<any>();
  elementNotes: any = new MatTableDataSource<any>();
  bookingHistory: any = new MatTableDataSource<any>();
  availableDocs: any = [];
  flightsOnly: any = [];

  // List of the table columns which are displayed in .html file
  passengerColumns = ['name', 'operationsPax', 'paxDoc', 'dobPax', 'email', 'receipted'];
  passengerNewColumns = ['name', 'dob', 'telephone', 'email', 'addElement'];
  elementsColumns = ['supplierName', 'ref', 'bookingDate', 'deptDate', 'returnDate', 'suppDueDate', 'gross'];
  costingColumns = ['supplierName', 'ref', 'gross', 'net', 'commission', 'vat', 'discount', 'status', 'addElement'];
  supplementColumns = ['supplementName', 'gross', 'net', 'commission', 'vat', 'discount', 'status', 'addElement'];
  receiptColumns = ['receiptDate', 'totalCharge', 'paymentMethod', 'reference', 'payerRef', 'receiptStatus', 'printAndSend', 'expand'];
  paymentColumns = ['paymentDate', 'paymentAmount', 'paymentMethod', 'reference', 'suppRef', 'paymentStatus', 'printAndSend', 'expand'];
  // insuranceColumns = ['insuranceStatus', 'insuranceType', 'policyNo', 'underwriter', 'gross', 'net', 'dicount', 'commission', 'vat'];
  fellohColumns = ['custName', 'amount', 'openBanking', 'cardPayments', 'createdDate', 'paymentDate', 'status', 'delete'];
  s3FilesColumns = ['fileName', 'lastModified', 'size', 'download'];
  generateDocCol = ['fileName', 'lastModified', 'version', 'download'];
  bkgEleNotesCol = ['createdAt', 'notes', 'operations'];
  historyColumns = ['historyDate', 'entity', 'description'];

  // Variables controlling user access
  userType = '';
  pageLoaded = false;
  restrictedUser = false; // Used for Phone + In-Person Felloh payment
  newPaxRadioValue = 'existing';
  bookingAccess = true; // Variable which will tell whether user has access to the booking or not
  bookingExists = true; // Variable which will tell whether booking exists within the system or not

  // CRF75 - Duplicate customer. Display / not display message about duplicate customers
  createConfirm = true;
  showDupliBox = false;

  // Variables controlling HTML view file
  customerView = false; // Whether customer is around you - take from session and assign here
  showMistakes = false; // On/Off mistakes / deleted payments..
  atolReceipt = false; // Needed for ATOL text within the Receipt Letter document..
  showPaymentsOnly = false; // On/Off payments only..
  showCommOnly = false; // On/Off commissions only..
  bookingDatesDisable = false; // Whether we can edit booking dates - empty bookings CANNOT edit them!
  bookingDateDisable = true; // Whether booking date can be changed - it's possible to do so on the same day
  oldSafi = false; // Determine whether we're displaying old SAFI calcualtions or not [1st of March 2024]
  standaloneExists = false; // Detemines whether the Standalone already exists in the booking..
  atolExists = false; // Determines whether the ATOL already exists in the booking...
  sfcExists = false; // Determines whether the SFC already exists in the booking...
  safiExists = false; // Determines whether the SAFI already exists in the booking...
  editToggle = false; // Toggle to edit / not edit booing values and dates
  graphsView = true; // Booking tab menu variable
  addnDataView: any = 0; // Variable used to 'switch' between rooms / cabins
  generalView = false; // Booking tab menu variable
  passengerView = false; // Booking tab menu variable
  suppliersView = false; // Booking tab menu variable
  // insuranceView: boolean = false; // Booking tab menu variable
  receiptsView = false; // Booking tab menu variable
  paymentsView = false; // Booking tab menu variable
  documentationView = false; // Booking tab menu variable
  notesView = false; // Booking tab menu variable
  historyView = false; // Booking tab menu variable
  newNoteOptions = { important: false, showOnDocs: false }; // Mark as important from the get go

  // Other variables
  selectedBranch: any = {}; // Variable which holds branch data (depending on the access - it may differ..)
  selectedFellohAccount: any = null; // Variable which will be used for Felloh-related activities
  fellohCurrencies: any = []; // Variable holding available currencies
  bookingData: any = {}; // Variable which contains booking data only (without element)
  selectedPassenger: any = {}; // Currently selected passenger in UI - mostly use in Felloh
  passengersDataText: any = ''; // Variable which holds passengers data in the text format for matTooltip
  selHolidayInterests: any = []; // Variable holding currently selected holiday interests
  supplementList: any = []; // Variable used to contain list of available Supplements in the system
  suppliersList: any = []; // Variable used to contain list of available Suppliers in the system
  selectedSupplement: any = {}; // Currently selected supplement in UI - used in Costs tab
  suppManPrice: any = { grossCost: 0, realGross: 0, netCost: 0, commission: 0, tax: 0, discount: 0 }; // Holds all numbers needed for calculations etc.
  selectedSupplier: any = {}; // Currently selected supplier in UI - used in Costs tab when adding supplement to it
  suppliersTotalSums: any = { grossCost: 0, netCost: 0, commission: 0, tax: 0, discount: 0 };
  supplementsTotalSums: any = { grossCost: 0, netCost: 0, commission: 0, tax: 0, discount: 0 };
  receiptTotalSum: any = { totalCharge: 0, merchantFee: 0, creditValue: 0 };
  paymentTotalSum: any = { paymentAmount: 0 };
  supplementSelect: any = {}; // Currently selected supplement..
  sfcCovered: any = false; // Based on whether any suppliers within booking is under SFC
  newSfcInfo: any = {}; // This is the variable which will be used to display booking's sfc price when adding a supplement
  newSafiInfo: any = {}; // This is the variable which will be used to display booking's safi price when adding a supplement
  selectedCurrency = 'GBP'; // This is for the payment input - as a default we want GBP to be visible
  chartSelected: any = {}; // Whenever user clicks on the element in the timeline chart, element's data is loaded here
  request: any = {}; // Variable which holds all important variables when calling all APIs
  publicAccess: any = { request: '', key: '', wpPassword: '' }; // Variable which holds two strings needed for accessing booking info from outside
  openedDialog: any = ''; // Variable which dialogs are assigned to when clicking on the travel timeline element
  qrVar = { type: NgxQrcodeElementTypes.URL, corrLevel: NgxQrcodeErrorCorrectionLevels.HIGH, value: '' }; // QR code hash needed for display..
  today: any = new Date(); // Current date - used in receipting / supplier payments as a default date
  documentRequest = {}; // Append request details so they can be passed from the pop-up
  templateName = ''; // A TEMPLATE name which document is called with (such as bookingConfiramtion.erb)
  isNewTemplate = false; // Depending on that we will display / hide specific document settings
  documentName = ''; // A NICE document name will be appended here..
  htmlPage = ''; // Append html markup here to send the document later..
  errorMessage: any = ''; // Error message to UI
  successMessage: any = ''; // Success message to UI
  filterString: any = ''; // Variable used for filtering stuff (countries here?)

  // Felloh payment link options below
  fellohOptions: any = {
    types: { amex: true, mastercard: true, visa: true },
    regions: { uk: true, europe: true, world: true },
    methods: { card: true, openBanking: true },
    surcharging: true,
    expiryDate: ''
  };

  // Variables strictly booking-related
  nonActivePaxNo: any = 0; // Add up passsengers which are not active here..
  customerBalanceDue: any = 0; // Holds the value customer still needs to pay for the booking
  dueToSuppliers: any = 0; // Holds the value this booking still needs to pay to all suppliers
  finalVAT: any = 0; // Holds the value of either tax or finalTax (depending on whether its been set by someone already or not..)
  finalDeposit: any = 0; // Holds the value of either depositAmount or finalDeposit (depending on whether its been set by someone as abve..)
  profitPerc: any = 0; // Holds the calculation of the profit which equals commission / custPrice * 100%
  progressBarValue: any = 0; // Holds the percantage value of compleation which goes from bookingDate -> departureDate
  daysToDeptDate: any = 0; // Holds the number of days between bookingDate -> departureDate
  newReceipt: any = { createSupplement: false, paymentMethod: '', creditValue: 0.00, merchantFee: 0.00, totalCharge: 0.00 }; // Used when creating new receipt

  // Address variables which allows postcode lookup
  addressLookup: any = { address1: '', address2: '', address3: '', address4: '', postCode: '', postCodeString: '', countyLine: '', countryLine: '' };
  postCodeString: any = '';
  rawAddressList: any = [];
  addressList: any = [];

  // Custom mat expansion variables
  generalInformation = true;
  financialDetails = true;
  elementsDetails = true;
  passengersDetails = true;
  addPassengers = true;
  supplierList = true;
  supplementAdd = true;
  supplementCreate = false;
  // insuranceList: boolean = true;
  receiptList = true;
  receiptCreate = false;
  fellohReady = false;
  fellohList = true;
  fellohCreate = false;
  paymentList = true;
  paymentCreate = false;
  s3Documents = true;
  generateDocument = true;
  bookingNotesExp = true;
  elementNotesExp = true;
  bookingHistoryExp = true;
  commissionPaymentSelected = false;
  meetsATOLcriteria: any = {};

  // Chart variables below..
  public chartOptions: Partial<any> = {
    series: [],
    chart: {
      type: 'rangeBar',
      background: '#ff22f',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          reset: false,
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      width: '90%',
      height: '90%',
      autoSelected: 'pan',
      events: {
        click: (event: any, chartContext: any, config: any) => {
          if (config.dataPointIndex >= 0) {
            this.addnDataView = 0; // When clicking on the timeline 'box', reset room/cabin number to 0
            this.chartSelected = config.config.series[config.seriesIndex].data[config.dataPointIndex];
            this.openedDialog = this.dialog.open(this.chartDialog, { autoFocus: false });
          }
        }
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    grid: {
      row: {
        colors: ['#f8f8f8', '#e0e0e0'],
      },
      column: {
        colors: ['#f8f8f8', 'transparent'],
      },
      xaxis: {
        lines: {
          show: true
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: false,
        rangeBarOverlap: false,
        barHeight: '60%',
        dataLabels: {
          hideOverflowingLabels: true,
          color: 'red'
        },
      }
    },
    tooltip: {
      custom({ seriesIndex, dataPointIndex, w }: any): any {
        const from = new Date(w.config.series[seriesIndex].data[dataPointIndex].y[0]);
        const to = new Date(w.config.series[seriesIndex].data[dataPointIndex].y[1]);
        const suppRef = w.config.series[seriesIndex].data[dataPointIndex].costing.supplierReference;

        return (
          '<div style="color: #717FDA; margin: 3.5px;"><b>' +
          w.config.series[seriesIndex].data[dataPointIndex].niceType +
          ' <span style=\'text-transform: none;\'>(' + suppRef + ')</span></b></div>' +
          '<div style="color: #202020; margin: 3.5px;"><i>' +
          from.toLocaleDateString() + ' - ' + to.toLocaleDateString() +
          '</i></div>'
        );
      }
    },
    xaxis: {
      type: 'datetime',
      autorange: true,
      position: 'top',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      autorange: false,
      labels: {
        align: 'center',
        minWidth: 0,
        maxWidth: 500,
        style: {
          fontFamily: 'Montserrat',
          fontWeight: 600
        },
        formatter: (value: any) => {
          if (value.toString().split('|').length > 1) {
            const arrayToReturn = []; // That's where we'll enter new text line
            const supplierNameFull = value.toString().split('|')[0]; // Get the full name
            const supplierReference = value.toString().split('|')[1]; // Get the full supp ref
            let supplierName = ''; // That's where we'll assign supplier names from 'full string' in a loop
            let supplierNameDone = ''; // That's where we'll merge the whole supplier name

            let count = 0; // This will allow us to escape from never ending loop
            while (supplierNameDone !== supplierNameFull && count < 7) {
              supplierName = supplierNameFull.replace(supplierNameDone, '').replace(/^(.{15}[^\s]*).*/, '$1'); // Split the name to 20 characters (+ finish current word)
              supplierNameDone = supplierNameDone + supplierName; // Add piece to the puzzle
              arrayToReturn.push(supplierName); // Add text line to array list
              count++;
            }
            arrayToReturn.push(''); // Spacing between name and reference
            arrayToReturn.push(supplierReference); // Last line will be supplier reference
            return arrayToReturn;
          } else {
            return '';
          }
        }
      }
    },
    legend: {
      show: false
    },
    colors: ['#4D5FD1']
  };
  public pieChartOptions1: Partial<any> = {
    series: [],
    chart: {
      width: '100%',
      type: 'donut',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    labels: ['Paid', 'Due'],
    colors: ['#4D5FD1', '#a6a6a6'],
    stroke: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value.toFixed(2);
          } else {
            return '-£' + (value.toFixed(2) * -1);
          }
        }
      }
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: false,
        offsetX: 0,
        offsetY: 0,
        customScale: 0.9,
      }
    },
    legend: {
      show: true,
      position: 'top',
    },
    dataLabels: {
      formatter(val: any, opts: any): any {
        const value = opts.w.config.series[opts.seriesIndex];
        if (value >= 0) {
          return '£' + value.toFixed(2);
        } else {
          return '-£' + (value.toFixed(2) * -1);
        }
      }
    }
  };
  public pieChartOptions2: Partial<any> = {
    series: [],
    chart: {
      width: '100%',
      type: 'donut',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    labels: ['Paid', 'Due'],
    colors: ['#4D5FD1', '#a6a6a6'],
    stroke: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value.toFixed(2);
          } else {
            return '-£' + (value.toFixed(2) * -1);
          }
        }
      }
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: false,
        offsetX: 0,
        offsetY: 0,
        customScale: 0.9
      }
    },
    legend: {
      show: true,
      position: 'top',
    },
    dataLabels: {
      formatter(val: any, opts: any): any {
        const value = opts.w.config.series[opts.seriesIndex];
        if (value >= 0) {
          return '£' + value.toFixed(2);
        } else {
          return '-£' + (value.toFixed(2) * -1);
        }
      }
    }
  };

  // GLOBAL STUFF BELOW

  constructor(private route: ActivatedRoute, private router: Router, private customerService: CustomerService,
              private supplierService: SupplierService, private bookingService: BookingService, private elementService: ElementService,
              private receiptService: ReceiptService, private paymentService: PaymentService, private noteService: NoteService,
              private reportService: ReportsService, private location: Location, private publicService: PublicService,
              private fellohService: FellohService, private userService: UserService, public dialog: MatDialog) {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      this.router.navigate(['/']);
    } else {
      this.userType = Session.mySession.getUser().userType;
      this.loadPage(route);
    }
  }

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any; // First expandable row (used everywhere)
  expandedElementWithin: any; // Expandable stuff within the expandable row (used mostly in Costs tab)
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const tabNo = Number(params.openTab); // Get the number from the first parameter in URL
      // If the number is not undefined, use it in the if statements below to determine
      // which tab should be automatically open
      if (tabNo !== undefined) {
        if (tabNo === 0) {
          this.tabChanged('graphsView', 'topNav');
        } else if (tabNo === 1) {
          this.tabChanged('generalView', 'topNav');
        } else if (tabNo === 2) {
          this.tabChanged('passengerView', 'topNav');
        } else if (tabNo === 3) {
          this.tabChanged('suppliersView', 'topNav');
          // } else if (tabNo == 4) {
          //  this.tabChanged('insuranceView', 'topNav');
        } else if (tabNo === 5) {
          this.tabChanged('receiptsView', 'topNav');
        } else if (tabNo === 6) {
          this.tabChanged('paymentsView', 'topNav');
        } else if (tabNo === 7) {
          this.tabChanged('documentationView', 'topNav');
        } else if (tabNo === 8) {
          this.tabChanged('notesView', 'topNav');
        } else if (tabNo === 9) {
          this.tabChanged('historyView', 'topNav');
        }
        // In case the number was undefined and the screen width is too small - change the view to general tab
      } else if (this.innerWidth < 610) {
        this.tabChanged('generalView', 'topNav');
      }
      this.location.replaceState('/booking/' + params.bookingReference); // Change the URL so it removes the number used earlier
    });
  }

  loadPage(route: any): void {
    if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff' || this.userType === 'memberManager' || this.userType === 'memberStaff') {
      route.params.subscribe((params: any) => {
        this.pageLoaded = false; this.bookingExists = true; this.bookingAccess = true; // Set access variables to default below
        this.customerView = Session.mySession.get('customerFacing'); // Set the vustomer view straight away
        this.showMistakes = Session.mySession.get('mistakesOnOff'); // Same as above..

        const bookingReference = params.bookingReference; // Get the booking reference from params
        // We assign the request variable straght away with USERS PARAMETERS (not booking at this stage)
        this.request = {
          company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode, bookingReference, token: Session.mySession.get('user').token
        };

        // Apply agentEmail filter if user is not permitted to see others' bookings
        if (Session.mySession.getUser().othersBookingAccess === 'no') {
          this.request.agentEmail = Session.mySession.getUser().email; this.restrictedUser = true;
        }

        // We're calling one by one each function stated below to pull all necessary data from the booking
        this.recalculateBookingsValues().then(res => {
          if (res === 'OK') {
            // Logged in user is in the group and branch list already exists within the session variable..
            if (Session.mySession.getUsersGroup().length > 0) {
              this.selectedBranch = Session.mySession.getUsersGroup().filter((branch: any) => branch.tradeCode === this.bookingData.tradeCode)[0];
            } else {
              this.selectedBranch = Session.mySession.getBranch();
            }
            this.selectedFellohAccount = this.selectedBranch?.fellohConfig[0]; // Assign selectedTradeCode below..
            this.setFellohCurrenciesUp(); // Work out currency list for given Felloh account

            // If fellohSetup is set to 'yes', we will enable Felloh table in Receipt tab
            if (this.selectedBranch.fellohSetup === 'yes' && this.selectedBranch?.fellohConfig?.length > 0) { this.fellohReady = true; }

            this.populateChart();
            this.populatePieChart(this.customerBalanceDue, this.bookingData.totalReceiptedCharged);
            this.populatePieChart2(this.dueToSuppliers, this.bookingData.totalSuppPayments);
            this.recalculatePassengers().then(() => {
              this.recalculateReceipts().then(() => {
                this.recalculatePayments().then(() => {
                  this.recaulculateNotes().then(() => {
                    // this.recalculateInsurance().then(() => {
                    this.getPublicKey().then(() => {
                      this.setUserList().then(() => {
                        AppComponent.myapp.getIconsWidth();
                        this.pageLoaded = true;
                      });
                    });
                    // });
                  });
                });
              });
            });

          } else if (res === 'No Access') {
            this.pageLoaded = true; this.bookingAccess = false; // Access to booking not granted
          } else if (res === 'No Booking') {
            this.pageLoaded = true; this.bookingExists = false; // Booking does not exist in SinGS
          }
        });
      });
    } else {
      this.pageLoaded = true; // Page is loaded but booking not which prevents access
    }
  }

  requestUpdateVariable(updateWhat: any): any {
    if (updateWhat === 'booking') {
      if (this.bookingData.bookingStatus !== 'booking' && !this.bookingData.returnDate) {
        return { // Non 'booking' status booking will not have return date in it
          company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
          bookingReference: this.bookingData.bookingReference, bookingStatus: this.bookingData.bookingStatus, token: Session.mySession.get('user').token
        };
      } else {
        return { // 'Booked' booking WILL have return date as it's required by the back-end
          company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
          bookingReference: this.bookingData.bookingReference, bookingStatus: this.bookingData.bookingStatus, returnDate: this.bookingData.returnDate,
          token: Session.mySession.get('user').token
        };
      }
    } else if (updateWhat === 'bookCustLink') {
      return {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        bookingReference: this.bookingData.bookingReference, token: Session.mySession.getUser().token,
      };
    } else {
      return null;
    }
  }

  recalculateBookingsValues(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API

      if (Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'skeleton').expiryTime === 'EXPIRED') {
        this.bookingService.getBookingFull(this.request).then((skeleton: any) => {
          if (skeleton.status === 'OK' && skeleton.bookingData.status !== 'no such booking') {
            Session.mySession.setCurrentBookingsValue(this.bookingData.bookingReference, 'skeleton', skeleton); // Set bookings values in session
            this.recalculateBookingsValuesLogic(skeleton).then((res: any) => {
              this.getSystemSupplementList().then(() => {
                this.getSystemSupplierList().then(() => {
                  this.getSFCcoverPrice();
                  this.getFlightsOnly();
                  resolve('OK'); // Return booking OK, recalculate booking values
                });
              });
            });
          } else if (skeleton.status === 'OK' && skeleton.bookingData.status === 'no such booking') {
            this.pageLoaded = true; resolve('No Booking'); // Page loaded but no access for the user..
          } else if (skeleton.status !== 'OK') {
            this.pageLoaded = true; resolve('No Access'); // Page laoded but the booking wasn't there!
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0818S)', error, this.request);
          resolve('Error'); // Return 'error' status back
        });
      } else {
        // Use session's variable to recaulcate booking's values below. Return 'OK'
        this.recalculateBookingsValuesLogic(Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'skeleton').value).then((res: any) => {
          this.getSystemSupplementList().then(() => {
            this.getSystemSupplierList().then(() => {
              this.getSFCcoverPrice();
              this.getFlightsOnly();
              resolve('OK'); // Return booking OK, recalculate booking values
            });
          });
        });
      }

    });
  }

  recalculateBookingsValuesLogic(skeleton: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bookingData = skeleton.bookingData; // Assign booking data from skeleton to the global variable
      this.customerBalanceDue = this.bookingData.custPrice - this.bookingData.totalReceiptedCharged; // Calculate the customer balance here  <--- TBD THIS WILL PROBABLY GO..
      this.dueToSuppliers = this.bookingData.net - this.bookingData.totalSuppPayments; // Calcualte the supplier balance here

      /* TBD THIS WILL PROBABLY BE USED IN THE FUTURE...
      if (this.bookingData.bookingStatus === 'cancelled' && this.bookingData.depositPaid) {
        this.customerBalanceDue = this.bookingData.depositAmount - this.bookingData.totalReceiptedCharged; // Calculate the customer balance here
      } else {
        this.customerBalanceDue = this.bookingData.custPrice - this.bookingData.totalReceiptedCharged; // Calculate the customer balance here
      }*/

      // Calculate TAX below. If finalTax isn't null and 0, display it. Otherwise display tax (not overriden by anyone)
      if (this.bookingData.finalTax != null && this.bookingData.finalTax.toString() !== '0.0') { this.finalVAT = this.bookingData.finalTax; }
      else { this.finalVAT = this.bookingData.tax; }

      // Calculate deposit below - if finalDeposit isn't null and 0, display it. Otherwise display depositAmount
      if (this.bookingData.finalDeposit != null && this.bookingData.finalDeposit.toString() !== '0.0') { this.finalDeposit = this.bookingData.finalDeposit; }
      else { this.finalDeposit = this.bookingData.depositAmount; }

      // Caulcate the profit percentage below. Only if commission is more than £0 (obviosusly..)
      if (this.bookingData.commission.toString() !== '0.0') { this.profitPerc = ((this.bookingData.commission / this.bookingData.custPrice) * 100).toFixed(2); }
      else { this.profitPerc = 0; }

      const nonSupplm: any = []; const supplm: any = []; // Create empty array to contain costings/supplements..
      skeleton.elementData.forEach((element: any) => {
        // Make rows expandable here
        element.detailRow = true;
        // Assign costing/element data (supplement / non-supplement) from skeleton
        if (element.elementType === 'supplement') { supplm.push(element); }
        else { nonSupplm.push(element); }
      });

      this.costingsData.data = nonSupplm; this.supplementsData.data = supplm; // Assign arrays to global data variables here..

      this.suppliersTotalSums = { grossCost: 0, netCost: 0, commission: 0, tax: 0, discount: 0 }; // Reset total sums of elements
      this.supplementsTotalSums = { grossCost: 0, netCost: 0, commission: 0, tax: 0, discount: 0 }; // Reset total sums of elements
      const eleNotes: any = []; // Reset all element notes

      this.costingsData.data.forEach((costing: any) => {
        this.suppliersTotalSums.grossCost = this.suppliersTotalSums.grossCost += parseFloat(costing.gross); // Add up the value..
        this.suppliersTotalSums.netCost = this.suppliersTotalSums.netCost += parseFloat(costing.net); // Add up the value..
        this.suppliersTotalSums.commission = this.suppliersTotalSums.commission += parseFloat(costing.commission); // Add up the value..
        this.suppliersTotalSums.tax = this.suppliersTotalSums.tax += parseFloat(costing.tax); // Add up the value..
        this.suppliersTotalSums.discount = this.suppliersTotalSums.discount += parseFloat(costing.discount); // Add up the value..

        if (costing.elementType !== 'package') {
          if (costing.accoms.length > 0) {
            // Accommodation subElement setup
            costing.accoms.forEach((accom: any) => {
              const status = accom.accomStatus.charAt(0).toUpperCase() + accom.accomStatus.slice(1); // Capitalise first letter..
              const checkIn = this.constants.convertDateDdMmYyyy(accom.checkInDate); // Convert the date to dd.mm.yyyy
              // Set the text displayed in matTooltip inside top nav (icons)
              accom.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                'Status: ' + status + '\n' + 'Check-In: ' + checkIn + '\n' +
                'Nights: ' + accom.numNights);
            });
          }
          if (costing.flights.length > 0) {
            // Flight subElement setup
            costing.flights.forEach((flight: any) => {
              const status = flight.flightStatus.charAt(0).toUpperCase() + flight.flightStatus.slice(1); // Capitalise first letter..
              const deptDate = this.constants.convertDateDdMmYyyy(flight.deptDate); // Convert the date to dd.mm.yyyy
              const returnDate = this.constants.convertDateDdMmYyyy(flight.returnDate); // Convert the date to dd.mm.yyyy
              // Set the text displayed in matTooltip inside top nav (icons)
              flight.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                'Status: ' + status + '\n' + 'Departure: ' + deptDate + '\n' +
                'Arrival: ' + returnDate);
            });
          }
          if (costing.carhires.length > 0) {
            // Car hire subElement setup
            costing.carhires.forEach((hire: any) => {
              const status = hire.carHireStatus.charAt(0).toUpperCase() + hire.carHireStatus.slice(1); // Capitalise first letter..
              const pickUpDate = this.constants.convertDateDdMmYyyy(hire.pickUpDate); // Convert the date to dd.mm.yyyy
              const dropOffDate = this.constants.convertDateDdMmYyyy(hire.dropOffDate); // Convert the date to dd.mm.yyyy
              // Set the text displayed in matTooltip inside top nav (icons)
              hire.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                'Status: ' + status + '\n' + 'Pick-Up: ' + pickUpDate + '\n' +
                'Drop-Off: ' + dropOffDate);
            });
          }
          if (costing.carparks.length > 0) {
            // Car park subElement setup
            costing.carparks.forEach((park: any) => {
              const status = park.carparkStatus.charAt(0).toUpperCase() + park.carparkStatus.slice(1); // Capitalise first letter..
              const startDate = this.constants.convertDateDdMmYyyy(park.startDate); // Convert the date to dd.mm.yyyy
              const endDate = this.constants.convertDateDdMmYyyy(park.endDate); // Convert the date to dd.mm.yyyy
              // Set the text displayed in matTooltip inside top nav (icons)
              park.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                'Status: ' + status + '\n' + 'Starts: ' + startDate + '\n' +
                'Ends: ' + endDate);
            });
          }
          if (costing.attractions.length > 0) {
            // Attraction subElement setup
            costing.attractions.forEach((attraction: any) => {
              const status = attraction.attractionStatus.charAt(0).toUpperCase() + attraction.attractionStatus.slice(1); // Capitalise first letter..
              const startDate = attraction.startDateTime.substring(11, 16) + ' ' + attraction.startDateTime.substring(8, 10) + '.' + attraction.startDateTime.substring(5, 7) + '.' + attraction.startDateTime.substring(0, 4);
              const endDate = attraction.endDateTime.substring(11, 16) + ' ' + attraction.endDateTime.substring(8, 10) + '.' + attraction.endDateTime.substring(5, 7) + '.' + attraction.endDateTime.substring(0, 4);
              // Set the text displayed in matTooltip inside top nav (icons)
              attraction.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                'Status: ' + status + '\n' + 'Starts: ' + startDate + '\n' +
                'Ends: ' + endDate);
            });
          }
          if (costing.cruises.length > 0) {
            // Cruise subElement setup
            costing.cruises.forEach((cruise: any) => {
              const status = cruise.cruiseStatus.charAt(0).toUpperCase() + cruise.cruiseStatus.slice(1); // Capitalise first letter..
              const startDate = this.constants.convertDateDdMmYyyy(cruise.deptDate); // Convert the date to dd.mm.yyyy
              const endDate = this.constants.convertDateDdMmYyyy(cruise.returnDate); // Convert the date to dd.mm.yyyy
              // Set the text displayed in matTooltip inside top nav (icons)
              cruise.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                'Status: ' + status + '\n' + 'Departure: ' + startDate + '\n' + 'Return: ' + endDate);
            });
          }
          if (costing.miscs.length > 0) {
            // Miscellaneous subElement setup
            costing.miscs.forEach((misc: any) => {
              const status = misc.miscStatus.charAt(0).toUpperCase() + misc.miscStatus.slice(1); // Capitalise first letter..
              const startDate = misc.startDateTime.substring(11, 16) + ' ' + misc.startDateTime.substring(8, 10) + '.' + misc.startDateTime.substring(5, 7) + '.' + misc.startDateTime.substring(0, 4);
              const endDate = misc.endDateTime.substring(11, 16) + ' ' + misc.endDateTime.substring(8, 10) + '.' + misc.endDateTime.substring(5, 7) + '.' + misc.endDateTime.substring(0, 4);
              // Set the text displayed in matTooltip inside top nav (icons)
              misc.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                'Status: ' + status + '\n' + 'Starts: ' + startDate + '\n' +
                'Ends: ' + endDate);
            });
          }
          if (costing.trains.length > 0) {
            // Train subElement setup
            costing.trains.forEach((train: any) => {
              const status = train.trainStatus.charAt(0).toUpperCase() + train.trainStatus.slice(1); // Capitalise first letter..
              const startDate = train.departDateTime.substring(11, 16) + ' ' + train.departDateTime.substring(8, 10) + '.' + train.departDateTime.substring(5, 7) + '.' + train.departDateTime.substring(0, 4);
              const endDate = train.arriveDateTime.substring(11, 16) + ' ' + train.arriveDateTime.substring(8, 10) + '.' + train.arriveDateTime.substring(5, 7) + '.' + train.arriveDateTime.substring(0, 4);
              // Set the text displayed in matTooltip inside top nav (icons)
              train.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
              train.departStation + ' -> ' + train.arriveStation + '\n' +
                'Status: ' + status + '\n' + 'Departure: ' + startDate + '\n' +
                'Arrival: ' + endDate);
            });
          }
          if (costing.transfers.length > 0) {
            // Transfer subElement setup
            costing.transfers.forEach((transfer: any) => {
              const status = transfer.transferStatus.charAt(0).toUpperCase() + transfer.transferStatus.slice(1); // Capitalise first letter..
              const startDate = transfer.pickUpDateTime.substring(11, 16) + ' ' + transfer.pickUpDateTime.substring(8, 10) + '.' + transfer.pickUpDateTime.substring(5, 7) + '.' + transfer.pickUpDateTime.substring(0, 4);
              const endDate = transfer.dropOffDateTime.substring(11, 16) + ' ' + transfer.dropOffDateTime.substring(8, 10) + '.' + transfer.dropOffDateTime.substring(5, 7) + '.' + transfer.dropOffDateTime.substring(0, 4);
              // Set the text displayed in matTooltip inside top nav (icons)
              transfer.freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
                transfer.pickUpLocation + ' -> ' + transfer.dropOffLocation + '\n' +
                'Status: ' + status + '\n' + 'Pick-Up: ' + startDate + '\n' +
                'Drop-Off: ' + endDate);
            });
          }
        } else {
          const status = costing.elementStatus.charAt(0).toUpperCase() + costing.elementStatus.slice(1); // Capitalise first letter..
          const deptDate = this.constants.convertDateDdMmYyyy(costing.deptDate); // Convert the date to dd.mm.yyyy
          const returnDate = this.constants.convertDateDdMmYyyy(costing.returnDate); // Convert the date to dd.mm.yyyy
          // Set the text displayed in matTooltip inside top nav (icons)
          let packageBreakdown = '\n';
          if (costing.flights.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Flights: x' + costing.flights.length; }
          if (costing.accoms.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Accommodations: x' + costing.accoms.length; }
          if (costing.carhires.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Car Hires: x' + costing.carhires.length; }
          if (costing.carparks.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Car Parks: x' + costing.carparks.length; }
          if (costing.attractions.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Attractions: x' + costing.attractions.length; }
          if (costing.cruises.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Cruises: x' + costing.cruises.length; }
          if (costing.miscs.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Miscellaneous: x' + costing.miscs.length; }
          if (costing.trains.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Trains: x' + costing.trains.length; }
          if (costing.transfers.length > 0) { packageBreakdown = packageBreakdown + '\n' + 'Transfers: x' + costing.transfers.length; }
          costing.packages[0].freeText = String(costing.supplierName + ' (' + costing.supplierReference + ') \n' +
            'Status: ' + status + '\n' + 'Departure: ' + deptDate + '\n' +
            'Return: ' + returnDate + packageBreakdown);
        }

        if (costing.comment != null && costing.comment !== '') {
          eleNotes.push({ supplierName: costing.supplierName, note: costing.comment }); // To be displayed in notes (and possibly on top-nav?)
        }
      });

      this.elementNotes.data = eleNotes; // Override global element notes variable
      this.supplementsData.data.forEach((costing: any) => {
        this.supplementsTotalSums.grossCost = this.supplementsTotalSums.grossCost += parseFloat(costing.gross); // Add up the value..
        this.supplementsTotalSums.netCost = this.supplementsTotalSums.netCost += parseFloat(costing.net); // Add up the value..
        this.supplementsTotalSums.commission = this.supplementsTotalSums.commission += parseFloat(costing.commission); // Add up the value..
        this.supplementsTotalSums.tax = this.supplementsTotalSums.tax += parseFloat(costing.tax); // Add up the value..
        this.supplementsTotalSums.discount = this.supplementsTotalSums.discount += parseFloat(costing.discount); // Add up the value..
        // Mark as 'completed' if standalone has been found in the booking
        if (costing.supplierName === 'Safe Seat Plan Guarantee - Standalone') { this.standaloneExists = true; }
        if (costing.supplierName === 'APC - ATOL Protection Contribution') { this.atolExists = true; }
        if (costing.supplierName === 'SFC') { this.sfcExists = true; }
        if (costing.supplierName === 'SAFI') { this.safiExists = true; }
      });

      if (this.bookingData.bookingDate == null || this.bookingData.deptDate == null || this.bookingData.returnDate == null) {
        this.bookingDatesDisable = true; // All three above dates are mandatory for non-enquiry - we're disabling if any is null
      } else {
        this.bookingDatesDisable = false; // Booking dates are editable as all of them have non-null values!
        // Get current date in ISO format..
        const todaysDate = new Date().toISOString().split('T')[0];
        // Calculate the the number of days between today and the departure date
        // tslint:disable-next-line:no-angle-bracket-type-assertion
        let differenceInDaysTodaysDate = (<any> new Date(this.bookingData.deptDate) - <any> new Date(todaysDate));
        differenceInDaysTodaysDate = (differenceInDaysTodaysDate) / (1000 * 3600 * 24);
        // If the departre date is today or it already happened, the progress bar value will be 100% (0 days until dept date..)
        if (differenceInDaysTodaysDate <= 0) { this.progressBarValue = 100; this.daysToDeptDate = 0; }
        else {
          // Calculate the nubmer of days between booking's departure date and its booking date
          // tslint:disable-next-line:no-angle-bracket-type-assertion
          let differenceInDaysBookDate = (<any> new Date(this.bookingData.deptDate) - <any> new Date(this.bookingData.bookingDate));
          differenceInDaysBookDate = (differenceInDaysBookDate) / (1000 * 3600 * 24);
          // Calculate the 'date completion' in percantages below
          this.progressBarValue = ((differenceInDaysBookDate - differenceInDaysTodaysDate) / differenceInDaysBookDate) * 100;
          this.daysToDeptDate = differenceInDaysTodaysDate; // Set the number of days until departure date below..
        }
      }
      // Work out 10 days rule for changing booking dates below
      const TEN_DAYS_IN_MS = 10 * 24 * 60 * 60 * 1000;
      const tenDaysAfter = new Date(new Date(this.bookingData.bookedTS).getTime() + TEN_DAYS_IN_MS);
      // If the booking was created TODAY, make it possible to change its booking date. Otherwise - sorry!
      if (this.bookingData.bookingStatus === 'enquiry' || (this.bookingData.bookedTS && this.bookingData.bookedTS.length > 10 && this.today < tenDaysAfter)) {
        this.bookingDateDisable = false;
      }

      // If the booking was created before 2024-03-01 then mark it with oldSafi calculations..
      if (this.bookingData.createTS.length > 10 && (new Date(this.bookingData.createTS) < new Date('2024-03-01'))) {
        this.oldSafi = true;
      }
      // Work out whether this booking meets ATOL criteria below
      this.meetsATOLcriteria = Documents.myDocuments.meetsATOLcriteria(this.costingsData.data, this.bookingData.custPrice, true);

      // Re-assign values in the AppComponent (refresh it)
      AppComponent.myapp.bookingReference = this.bookingData.bookingReference; // Set main-app book ref immediately (needed for booking button [sell])
      AppComponent.myapp.bookingData = null; // Set to null
      AppComponent.myapp.bookingData = this.bookingData; // And set back to bookings data
      AppComponent.myapp.bookingCostings = null; // Set to null
      AppComponent.myapp.bookingCostings = this.costingsData.data; // And back to booking costings

      this.request.company = skeleton.bookingData.company; // All of the future calls from portfolio will use bookings data value
      this.request.operation = skeleton.bookingData.operation; // All of the future calls from portfolio will use bookings data value
      this.request.tradeCode = skeleton.bookingData.tradeCode; // All of the future calls from portfolio will use bookings data value
      this.request.bookingReference = skeleton.bookingData.bookingReference; // All of the future calls from portfolio will use bookings data value
      resolve('OK');
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '', ''); // Display error message here..
    });
  }

  recalculatePassengers(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API

      if (Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'passengers').expiryTime === 'EXPIRED') {
        this.customerService.getBookCustList(this.request).then((bookCustList: any) => {
          Session.mySession.setCurrentBookingsValue(this.bookingData.bookingReference, 'passengers', bookCustList); // Set bookings values in session
          this.recalculatePassengersLogic(bookCustList).then(res => { resolve(''); }); // Return booking OK, recalculate passengers values
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0819S)', error, this.request);
          resolve('');
        });
      } else {
        this.recalculatePassengersLogic(Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'passengers').value).then(res => {
          resolve('');
        });
      }

    });
  }

  recalculatePassengersLogic(bookCustList: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const passengerData: any = []; // Create a new array variable which will hold passengers data
      let passengerDataText: any = ''; // Create a new variable for passenger text (needed for matTooltip)
      let nonActivePaxNo = 0; // This will go either up or stay as is
      this.passengersDataText = ''; // Reset top-nav passenger text here

      if (bookCustList.status !== 'OK') {
        this.sendMessageToDialog('', bookCustList.status, '', ''); // Print error message
        resolve('');
      } else {
        bookCustList.bookCustList.forEach((element: any) => {
          if (element.active == 'yes') {
            element.customer.detailRow = true; // Make the passenger's table row expandable here..
            
            if (element.customer.id) {
              // Produce free text for each passenger. It will be displayed in the UI icons.
              if (element.customer.title && element.customer.title.trim() !== '') { passengerDataText += `${element.customer.title} `; }
              passengerDataText += `${element.customer.firstName}`;
              if (element.customer.middleName && element.customer.middleName.trim() !== '') { passengerDataText += ` ${element.customer.middleName}`; }
              passengerDataText += ` ${element.customer.lastName}\n`;
            } else {
              passengerDataText += 'Name TBA\n';
            }

            this.passengersDataText = String(passengerDataText); // Assign passengers data to global variable. It's displated in graphs tab
          } else { nonActivePaxNo += 1; }

          element.customer.emailDone = element.customer.email; // Assign email address
          element.customer.bookCustID = element.bookCustID; // Assign bookCust.id value..
          element.customer.isLead = element.isLead; // Assign isLead to the passenger
          element.customer.receipted = element.receipted // Assign receipted value to the passenger
          element.customer.active = element.active; // Assign active to the passenger
          element.customer.showOnDocs = element.showOnDocs; // Assign active to the passenger
          passengerData.push(element.customer); // Finally, push the customer to passengersData array list
        });

        this.nonActivePaxNo = nonActivePaxNo; // Set the number of non-active passengers here (to display in the UI)
        this.passengersData.data = passengerData; // Array fiull of passengers from above will be used to set global passengers table data variable
        Session.mySession.setLatestPax(passengerData.filter((passenger: any) => passenger.id !== null && passenger.id !== undefined)); // Set passengers in session array (used for Car Hire stuff for now..)
        AppComponent.myapp.passengersDataText = this.passengersDataText; // Send pax data text to main component
        resolve('');
      }
    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - recalculatePassengersLogic() failed');
    });
  }

  recalculateReceipts(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      if (Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'receipts').expiryTime === 'EXPIRED') {
        this.receiptService.getReceiptList(this.request).then((receipts: any) => {
          Session.mySession.setCurrentBookingsValue(this.bookingData.bookingReference, 'receipts', receipts); // Set bookings values in session
          this.recalculateReceiptsLogic(receipts); // Recalculate receipt values
          resolve(''); // And return back
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0820S)', error, this.request);
          resolve('');
        });
      } else {
        this.recalculateReceiptsLogic(Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'receipts').value); // Recalculate receipt values
        resolve(''); // And return back
      }

    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - recalculateReceipts() failed');
    });
  }

  recalculateReceiptsLogic(receipts: any): void {
    if (receipts.status !== 'OK') {
      this.sendMessageToDialog('', receipts.status, '', ''); // Print error message
    } else {
      // Make receeipts 'expandable' in the mat-table
      receipts.receiptList.forEach((rec: any) => {
        if (rec.payerId === null) { rec.payerName = 'N/A'; }
        // Double check if the payerId exists within bookCusts list..
        else {
          const payerIdString = rec.payerId.toString();
          if (!this.passengersData.data.some((pax: any) => pax.id?.toString() === payerIdString)) {
            rec.missingPax = true; // Mark receipt so the user knows there's missing passenger
          }
        }
        rec.detailRow = true;
      })

      this.receiptsData.data = receipts.receiptList; // Asssign the receipt list from the parameter into receipt data variable
      this.receiptsOrgn = receipts.receiptList; // Needed for filtering mistakes

      this.receiptsData.data.forEach((receipt: any) => {
        const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
        let value = '£0'; // Begin with £0 value and format positive/non-positive value below
        if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
        // Assign free-text value visible in UI below..
        receipt.freeText = 'Date: ' + receiptDate + '\n' +
          'Method: ' + receipt.paymentMethod + '\n' +
          'Value: ' + value;
      });
      // Transfer receipts data to main component (top nav)
      AppComponent.myapp.bookingReceipts = this.receiptsData.data.filter((receipt: any) => receipt.receiptCategory === 'receipt');
      this.showMistakesOnOff(this.showMistakes);
    }
  }

  reloadFelloh(): Promise<any> { 
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      // Create Felloh request hash to request access token and call the API
      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {

        // Create a Felloh payment request hash to which will be used to retrieve Felloh transactions list
        const paymentRequest = {
          organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
          keyword: this.bookingData.bookingReference
        };

        this.fellohService.fetchPaymentLinks(paymentRequest, tokenOut).then((paymentLinks: any) => {
          if (paymentLinks && paymentLinks?.meta?.reason === 'OK') {
        
            paymentLinks.data.forEach((link: any) => {
              if (link.currency === 'GBX') { link.currency = 'GBP'; link.amount = link.amount / 100; }
              else if (link.currency === 'USX') { link.currency = 'USD'; link.amount = link.amount / 100; }
              else if (link.currency === 'EUX') { link.currency = 'EUR'; link.amount = link.amount / 100; }
              link.detailRow = true;
              link.transactions = link.transactions.filter((txn: any) => txn.status === 'COMPLETE');
            });

            this.fellohData.data = paymentLinks.data;
          } else {
            this.sendMessageToDialog('', paymentLinks?.meta?.reason, '', '');
          }
          resolve('');
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0802F)', error, '');
          resolve('');
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0802F)', error, '');
        resolve('');
      });

    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - reloadFelloh() failed');
    });
  }

  recalculatePayments(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API

      if (Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'payments').expiryTime === 'EXPIRED') {
        this.paymentService.getPaymentList(this.request).then((payments: any) => {
          Session.mySession.setCurrentBookingsValue(this.bookingData.bookingReference, 'payments', payments); // Set bookings values in session
          this.recalculatePaymentsLogic(payments); // Recalculate payment values
          resolve(''); // And return back
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0821S)', error, this.request);
          resolve('');
        });
      } else {
        this.recalculatePaymentsLogic(Session.mySession.getCurrentBookingsValue(this.bookingData.bookingReference, 'payments').value); // Recualcate payment values
        resolve(''); // And return back
      }

    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - recalculatePayments() failed');
    });
  }

  recalculatePaymentsLogic(payments: any): void {
    if (payments.status !== 'OK') {
      this.sendMessageToDialog('', payments.status, '', ''); // Print error message
    } else {
      this.paymentsData.data = payments.paymentList; // Asssign the payment list from the parameter into payment data variable
      this.paymentsOrgn = payments.paymentList; // Needed for filtering mistakes

      this.paymentsData.data.forEach((payment: any) => {
        const paymentDate = this.constants.convertDateDdMmYyyy(payment.paymentDate); // Convert date to dd.mm.yyyy
        let value = '£0'; // Begin with £0 value and format positive/non-positive value below
        if (payment.paymentAmount < 0) { value = '-£' + (payment.paymentAmount * -1); } else { value = '£' + payment.paymentAmount; }
        // Assign free-text value visible in UI below...
        payment.freeText = 'Date: ' + paymentDate + '\n' +
          'Method: ' + payment.paymentMethod + '\n' +
          'Value: ' + value;
        // Make payment 'expandable' in the mat-table
        payment.detailRow = true;
        // Additionally, assign supplier name to the object..
        if (payment.supplierID != null) {
          payment.supplierName = this.suppliersList.find((supplier: any) => supplier.id.toString() === payment.supplierID.toString())?.supplierNameM;
          if (payment.supplierName === undefined) { payment.supplierName = 'N/A'; } // Supplier not found? Type in N/A!
        } else { payment.supplierName = 'N/A'; }
      });
      // Transfer receipts data to main component (top nav)
      AppComponent.myapp.bookingPayments = this.paymentsData.data.filter((payment: any) => payment.paymentCategory === 'payment');
      this.showMistakesOnOff(this.showMistakes);
    }
  }

  getSystemSupplementList(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the supplement list is in the session variable, It not / expired - reload by calling API

      if (Session.mySession.getSupplementList().expiryTime === 'EXPIRED') {
        this.supplierService.getSupplmPriceList(this.request).then((output: any) => {
          if (output.status === 'OK') {
            const sorted = output.suppriceList.sort((a: any, b: any) => (a.supName > b.supName) ? 1 : -1); // Sort supplements alphabetically
            Session.mySession.setSupplementList(sorted); // Set supplement values in session
            this.supplementList = sorted; // Assign supplement list in global variable

            // Set the autoPricing attribute of all supplement costs
            this.supplementsData.data.forEach((costing: any) => {
              const autoPricing = this.supplementList.find((supplm: any) => supplm.supName === costing.supplierName); // Find the supplement first
              if (autoPricing === undefined) { costing.autoPricing = true; } // In case it's been deleted or something, set autoPricing to true
              else { costing.autoPricing = autoPricing.autoPricing; } // If exists, assign supplements pricing as per set up
            });

            resolve(''); // And return back..
          } else {
            this.sendMessageToDialog('', output.status, '', ''); // Print error message..
            resolve(''); // And return back..
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0814S)', error, this.request);
          resolve(''); // And return back..
        });
      } else {
        this.supplementList = Session.mySession.getSupplementList().supplementList; // Get supplement list from the session varaible - no need to call API

        // Set the autoPricing attribute of all supplement costs
        this.supplementsData.data.forEach((costing: any) => {
          const autoPricing = this.supplementList.find((supplm: any) => supplm.supName === costing.supplierName); // Find the supplement first
          if (autoPricing === undefined) { costing.autoPricing = true; } // In case it's been deleted or something, set autoPricing to true
          else { costing.autoPricing = autoPricing.autoPricing; } // If exists, assign supplements pricing as per set up
        });

        resolve(''); // And return back..
      }

    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - getSystemSupplementList() failed');
    });
  }

  getSystemSupplierList(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the supplier list is in the session variable, It not / expired - reload by calling API

      if (Session.mySession.getSupplierList(this.bookingData.tradeCode).expiryTime === 'EXPIRED') {
        const request = {
          company: this.bookingData.company, operation: this.bookingData.operation,
          tradeCode: this.bookingData.tradeCode, token: Session.mySession.get('user').token
        };
        this.supplierService.getSupplierList(request).then((suppliers: any) => {
          if (suppliers.status === 'OK') {
            const sorted = suppliers.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); // Sort suppliers alphabetically
            Session.mySession.setSupplierList(this.bookingData.tradeCode, sorted); // Set suppliers values in session
            sorted.map(async (element: any) => { element.isUnderSFC = element.sfcs.some((e: any) => e.status === 'Approved'); });

            this.suppliersList = sorted; // Assign supplier list in global variable
            resolve(''); // And return back..
          } else {
            this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '', ''); // Print error message..
            resolve(''); // And return back..
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0813S)', error, request);
          resolve(''); // And return back..
        });
      } else {
        const supplierList = Session.mySession.getSupplierList(this.bookingData.tradeCode).supplierList;
        supplierList.map(async (element: any) => { element.isUnderSFC = element.sfcs.some((e: any) => e.status === 'Approved'); });

        this.suppliersList = Session.mySession.getSupplierList(this.bookingData.tradeCode).supplierList; // Get supplement list from the session varaible - no need to call API
        resolve(''); // And return back..
      }

    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - getSystemSupplierList() failed');
    });
  }

  recaulculateNotes(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API

      const request = {
        company: this.bookingData.company, operation: this.bookingData.operation,
        tradeCode: this.bookingData.tradeCode, noteType: 'booking',
        reference: this.bookingData.bookingReference, token: Session.mySession.get('user').token
      };

      this.noteService.getNotes(request).then((res: any) => {
        if (res.status !== 'OK') {
          this.sendMessageToDialog('', res.status, '', ''); // Display error message
        } else {
          // Step 1: Group the objects by 'important' property (yes / no)
          const groupedData = res.data.reduce((result: any, item: any) => {
            const key = item.important === 'yes' ? 'importantYes' : 'importantNo';
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(item);
            return result;
          }, {} as { importantYes?: any[], importantNo?: any[] });

          // Step 2: Sort the objects by date (latest first) within each group
          groupedData.importantYes?.sort((a: any, b: any) => new Date(b.createTS).getTime() - new Date(a.createTS).getTime());
          groupedData.importantNo?.sort((a: any, b: any) => new Date(b.createTS).getTime() - new Date(a.createTS).getTime());

          // Step 3: Combine and sort the groups in the desired order
          const combinedArray = [
            ...(groupedData.importantYes || []),
            ...(groupedData.importantNo || [])
          ].sort((a: any, b: any) => {
            if (a.important === 'yes' && b.important === 'yes') {
              return new Date(b.createTS).getTime() - new Date(a.createTS).getTime();
            } else if (a.important === 'no' && b.important === 'no') {
              return new Date(b.createTS).getTime() - new Date(a.createTS).getTime();
            } else if (a.important === 'yes' && b.important === 'no') {
              return -1;
            } else {
              return 1;
            }
          });

          // Step 4: Assign grouped data into booking notes and top-nav notes
          this.bookingNotes.data = combinedArray;
          AppComponent.myapp.bookingNotes = this.bookingNotes.data; // Assign top nav variable here

          // Step 5: Work out element comments / notes below
          if (this.elementNotes.data.length > 0) {
            const elNotesToTop: any = []; // Create new array where we'll dump formatted text
            this.elementNotes.data.forEach((comment: any) => {
              elNotesToTop.push(comment.supplierName + '\n' + comment.note); // Dump the text into array list here
            });
            AppComponent.myapp.elementNotes = elNotesToTop; // Assign dumped text to main variable in top-nav
          } else {
            AppComponent.myapp.elementNotes = []; // Assign top nav variable here
          }
        }
        resolve('');
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0839S)', error, request);
        resolve('');
      });
    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - recaulculateNotes() failed');
    });
  }

  recalculateHistory(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API

      const request = {
        company: this.bookingData.company, operation: this.bookingData.operation,
        tradeCode: this.bookingData.tradeCode, bookingReference: this.bookingData.bookingReference,
        token: Session.mySession.get('user').token
      };

      this.bookingService.getBookingHistory(request).then((res: any) => {
        if (res.status === 'OK') {
          this.bookingHistory.data = res.data; // Append history to a global variable
        } else {
          this.sendMessageToDialog('', res.status, '', '');
        }
        resolve('');
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0843S)', error, request);
        resolve('');
      });
    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.bookingData.bookingReference + ' - recalculateHistory() failed');
    });
  }

  // GLOBAL STUFF ABOVE

  // GRAPHS TAB BELOW

  populateChart(): void {
    const chartData: any = []; // Initialise empty variable for the chart series array
    const elementData: any = { name: '', data: [] }; // Intialise empty variable for the series hash
    // We need to loop through every single element in the costingsData table data variable
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.costingsData.data.length; i++) {
      // Set the series name - it doesn't appear anywhere in the UI so we keep it generic..
      elementData.name = 'Travel Timeline';
      if (this.costingsData.data[i].flights.length > 0) {
        this.costingsData.data[i].flights.forEach((element: any) => {
          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(element.deptDate + ' 01:00:00').getTime(),
                new Date(element.returnDate + ' 23:59:59').getTime()],
              type: 'flight',
              niceType: 'Flight',
              fillColor: this.constants.getSeriesColour(element.flightStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].accoms.length > 0) {
        this.costingsData.data[i].accoms.forEach((element: any) => {
          const retDate = new Date(element.checkInDate); // Get the date when checks in to hotel
          retDate.setDate(retDate.getDate() + Number(element.numNights)); // Add number of nights to the date
          const returnDate = this.constants.convertDateNotMoment(retDate); // Convert the date here

          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(element.checkInDate + ' 01:00:00').getTime(),
                new Date(returnDate + ' 23:59:59').getTime()],
              type: 'accommodation',
              niceType: 'Accommodation',
              fillColor: this.constants.getSeriesColour(element.accomStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].carhires.length > 0) {
        this.costingsData.data[i].carhires.forEach((element: any) => {
          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(element.pickUpDate + ' 01:00:00').getTime(),
                new Date(element.dropOffDate + ' 23:59:59').getTime()],
              type: 'carHire',
              niceType: 'Car Hire',
              fillColor: this.constants.getSeriesColour(element.carHireStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].carparks.length > 0) {
        this.costingsData.data[i].carparks.forEach((element: any) => {
          let startDate = element.startDate; // Assign date time to a variable
          let endDate = element.endDate; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.startDate.substring(0, 4) + '-' + element.startDate.substring(5, 7) + '-' +
              element.startDate.substring(8, 10) + ' 01:00:00';
            endDate = element.endDate.substring(0, 4) + '-' + element.endDate.substring(5, 7) + '-' +
              element.endDate.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'carParking',
              niceType: 'Car Parking',
              fillColor: this.constants.getSeriesColour(element.carparkStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].attractions.length > 0) {
        this.costingsData.data[i].attractions.forEach((element: any) => {
          let startDate = element.startDateTime; // Assign date time to a variable
          let endDate = element.endDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.startDateTime.substring(0, 4) + '-' + element.startDateTime.substring(5, 7) + '-' +
              element.startDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.endDateTime.substring(0, 4) + '-' + element.endDateTime.substring(5, 7) + '-' +
              element.endDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'attraction',
              niceType: 'Attraction',
              fillColor: this.constants.getSeriesColour(element.attractionStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].miscs.length > 0) {
        this.costingsData.data[i].miscs.forEach((element: any) => {
          let startDate = element.startDateTime; // Assign date time to a variable
          let endDate = element.endDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.startDateTime.substring(0, 4) + '-' + element.startDateTime.substring(5, 7) + '-' +
              element.startDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.endDateTime.substring(0, 4) + '-' + element.endDateTime.substring(5, 7) + '-' +
              element.endDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'miscellaneous',
              niceType: 'Miscellaneous',
              fillColor: this.constants.getSeriesColour(element.miscStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].transfers.length > 0) {
        this.costingsData.data[i].transfers.forEach((element: any) => {
          let startDate = element.pickUpDateTime; // Assign date time to a variable
          let endDate = element.dropOffDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.pickUpDateTime.substring(0, 4) + '-' + element.pickUpDateTime.substring(5, 7) + '-' +
              element.pickUpDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.dropOffDateTime.substring(0, 4) + '-' + element.dropOffDateTime.substring(5, 7) + '-' +
              element.dropOffDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'transfer',
              niceType: 'Transfer',
              fillColor: this.constants.getSeriesColour(element.transferStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].trains.length > 0) {
        this.costingsData.data[i].trains.forEach((element: any) => {
          let startDate = element.departDateTime; // Assign date time to a variable
          let endDate = element.arriveDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.departDateTime.substring(0, 4) + '-' + element.departDateTime.substring(5, 7) + '-' +
              element.departDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.arriveDateTime.substring(0, 4) + '-' + element.arriveDateTime.substring(5, 7) + '-' +
              element.arriveDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'train',
              niceType: 'Train',
              fillColor: this.constants.getSeriesColour(element.trainStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
      if (this.costingsData.data[i].cruises.length > 0) {
        this.costingsData.data[i].cruises.forEach((element: any) => {
          let startDate = element.deptDate; // Assign date time to a variable
          let endDate = element.returnDate; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.deptDate.substring(0, 4) + '-' + element.deptDate.substring(5, 7) + '-' +
              element.deptDate.substring(8, 10) + ' 01:00:00';
            endDate = element.returnDate.substring(0, 4) + '-' + element.returnDate.substring(5, 7) + '-' +
              element.returnDate.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            { // x contains the name + supplier reference which will be then grouped by in the chart ( if EXACT, put under the same x line )
              x: this.costingsData.data[i].supplierName + '|' + this.costingsData.data[i].supplierReference,
              y: [ // y contains start date - end date lines..
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'cruise',
              niceType: 'Cruise',
              fillColor: this.constants.getSeriesColour(element.cruiseStatus), // Different statuses have different colours on the chart..
              element, // Required for later use upon user click event
              costing: this.costingsData.data[i] // Required for later use upon user click event
            });
        });
      }
    }
    chartData.push(elementData); // Add all series data (object) into one array
    this.chartOptions.series = chartData; // Assign that array to global chart variable
  }

  populatePieChart(balanceDue: any, totalReceipted: any): void {
    const chartData = [Number(totalReceipted), Number(balanceDue)]; // Put both in-values into new array
    this.pieChartOptions1.series = chartData; // And assign it into pie chart series
  }

  populatePieChart2(dueToSuppliers: any, totalSuppPayments: any): void {
    const chartData = [Number(totalSuppPayments), Number(dueToSuppliers)]; // Put both in-values into new array
    this.pieChartOptions2.series = chartData; // And assign it into pie chart series
  }

  changeElementStatus(costing: any, element: any, elementType: any, event: any, whatElement: any, returnPage: any): void {
    if (this.openedDialog !== '') { this.openedDialog.close(this.chartDialog); } // If dialog is open, close it first..
    const elementData: any = { }; // Create a hash used to update element. miniHash here is not needed!

    if (whatElement === 'subElement') { // In case user wants to edit subElement only (not the main element), go below..
      elementData.coreElementData = {}; elementData.elmentDataMiniHash = {};
      elementData.coreElementData.company = this.bookingData.company; // Usual stuff..
      elementData.coreElementData.operation = this.bookingData.operation; // Usual stuff..
      elementData.coreElementData.tradeCode = this.bookingData.tradeCode; // Usual stuff..
      elementData.coreElementData.elementType = costing.elementType; // Just copy, don't override it
      elementData.coreElementData.supplierName = costing.supplierName; // Just copy, don't override it
      elementData.coreElementData.bookingReference = this.bookingData.bookingReference; // Usual stuff..
      elementData.coreElementData.elementCount = costing.elementCount; // Just copy, don't override it
      elementData.coreElementData.elementStatus = costing.elementStatus; // Just copy, don't override it
      elementData.elmentDataMiniHash.action = 'update'; // Update mini hash (subElement), not create it!
      elementData.token = Session.mySession.get('user').token; // Usual stuff..

      // We NEED to provide subElementType & elementCount (e.g. flightCount). Status is set as per event value
      if (elementType === 'flight') {
        elementData.elmentDataMiniHash.subElementType = 'flight';
        elementData.elmentDataMiniHash.flightCount = element.flightCount;
        elementData.elmentDataMiniHash.flightStatus = event;
      } else if (elementType === 'accommodation') {
        elementData.elmentDataMiniHash.subElementType = 'accom';
        elementData.elmentDataMiniHash.accomCount = element.accomCount;
        elementData.elmentDataMiniHash.accomStatus = event;
      } else if (elementType === 'carHire') {
        elementData.elmentDataMiniHash.subElementType = 'carhire';
        elementData.elmentDataMiniHash.carHireCount = element.carHireCount;
        elementData.elmentDataMiniHash.carHireStatus = event;
      } else if (elementType === 'carParking') {
        elementData.elmentDataMiniHash.subElementType = 'carpark';
        elementData.elmentDataMiniHash.carparkCount = element.carparkCount;
        elementData.elmentDataMiniHash.carparkStatus = event;
      } else if (elementType === 'attraction') {
        elementData.elmentDataMiniHash.subElementType = 'attraction';
        elementData.elmentDataMiniHash.attractionCount = element.attractionCount;
        elementData.elmentDataMiniHash.attractionStatus = event;
      } else if (elementType === 'miscellaneous') {
        elementData.elmentDataMiniHash.subElementType = 'misc';
        elementData.elmentDataMiniHash.miscCount = element.miscCount;
        elementData.elmentDataMiniHash.miscStatus = event;
      } else if (elementType === 'cruise') {
        elementData.elmentDataMiniHash.subElementType = 'cruise';
        elementData.elmentDataMiniHash.cruiseCount = element.cruiseCount;
        elementData.elmentDataMiniHash.cruiseStatus = event;
      } else if (elementType === 'train') {
        elementData.elmentDataMiniHash.subElementType = 'train';
        elementData.elmentDataMiniHash.trainCount = element.trainCount;
        elementData.elmentDataMiniHash.trainStatus = event;
      } else if (elementType === 'transfer') {
        elementData.elmentDataMiniHash.subElementType = 'transfer';
        elementData.elmentDataMiniHash.transferCount = element.transferCount;
        elementData.elmentDataMiniHash.transferStatus = event;
      } else if (elementType === 'package') {
        elementData.elmentDataMiniHash.subElementType = 'package';
        elementData.elmentDataMiniHash.packageCount = element.packageCount;
        elementData.elmentDataMiniHash.packageStatus = event;
      }

    } else if (whatElement === 'mainElement') { // User decided to edit main element (containing subElements). Go below..
      elementData.coreElementData = {};
      elementData.coreElementData.company = this.bookingData.company; // Usual stuff..
      elementData.coreElementData.operation = this.bookingData.operation; // Usual stuff..
      elementData.coreElementData.tradeCode = this.bookingData.tradeCode; // Usual stuff..
      elementData.coreElementData.elementType = costing.elementType; // Just copy, don't override it
      elementData.coreElementData.supplierName = costing.supplierName; // Just copy, don't override it
      elementData.coreElementData.bookingReference = this.bookingData.bookingReference; // Usual stuff..
      elementData.coreElementData.elementCount = costing.elementCount; // Just copy, don't override it
      elementData.token = Session.mySession.get('user').token; // Usual stuff..
      elementData.coreElementData.elementStatus = event;  // This is where we set the status of main element!

      if (elementType === 'package') {
        elementData.elmentDataMiniHash = {};
        elementData.elmentDataMiniHash.subElementType = 'package';
        elementData.elmentDataMiniHash.packageCount = element.packageCount;
        elementData.elmentDataMiniHash.packageStatus = event;
        elementData.elmentDataMiniHash.action = 'update';
      }
    }
    this.pageLoaded = false;
    this.elementService.updateElement(elementData).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog('Element status has been updated', '', '', ''); // Display success message
          this.tabChanged(returnPage, 'main');
        });
      } else {
        this.sendMessageToDialog('', 'Element update failed (' + output.status + ')', '', ''); // Display error message
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0812S)', error, elementData);
    });
  }

  editElement(type: any, costings: any): void {
    if (this.openedDialog !== '') { this.openedDialog.close(this.chartDialog); } // If dialog is open, close it first..

    try {
      // We need to find supplier from the array by its ID first (to get date due stuff.. (and maybe more later?))
      const supplierInfo = this.suppliersList.find((supplier: any) => supplier.id.toString() === costings.supplierID.toString());
      Session.mySession.setElementSupplier(supplierInfo); // Set the supplier information in the special session variable
    } catch {
      Session.mySession.setElementSupplier({}); // Not existent supplier / supplier ID not there (external bookings..)
    }

    const blockSuppRefs: any = []; // Create an array list which will contain invalid supplier references (cannot use already existing ones in new element)
    const openedBooking = this.bookingData; // Create a variable which will hold booking data (and more below). Used in create-element page..

    this.costingsData.data.forEach((costing: any) => {
      // Dump all but one supplier reference in the array (other than selected one)
      if (costing.id !== costings.id) { blockSuppRefs.push(costing.supplierReference); }
    });
    openedBooking.blockSuppRefs = blockSuppRefs; // Assign supp refs to block to the object here..

    Session.mySession.setOpenedBooking(openedBooking); // Set current booking in the session variable

    // Go to element-edit page with the costings in the state OR to package edit
    if (costings.elementType !== 'package') { this.router.navigateByUrl('/element/' + type, { state: costings }); }
    else { this.router.navigateByUrl('/package/edit', { state: costings }); }
  }

  addElement(options: any): void {
    if (this.openedDialog !== '') { this.openedDialog.close(this.chartDialog); } // If dialog is open, close it first..
    const openedBooking = this.bookingData; // Create a variable which will hold booking data (and more below). Used in create-element page..
    if (options === 'new') {
      const blockSuppRefs: any = []; // Create an array list which will contain invalid supplier references (cannot use already existing ones in new element)

      this.costingsData.data.forEach((costing: any) => { blockSuppRefs.push(costing.supplierReference); }); // Dump each supp reference from booking to array
      // Set fresh element to 'yes' as it'll be a new supplier reference. No subElements are done yet for it
      openedBooking.freshElement = 'yes'; openedBooking.flightsDone = 'no'; openedBooking.accomsDone = 'no'; openedBooking.carHiresDone = 'no';
      openedBooking.carParksDone = 'no'; openedBooking.attractionsDone = 'no'; openedBooking.miscsDone = 'no'; openedBooking.cruisesDone = 'no';
      openedBooking.transfersDone = 'no'; openedBooking.packageDone = 'no'; openedBooking.blockSuppRefs = blockSuppRefs;

      Session.mySession.setOpenedBooking(openedBooking); // Set opened booking with attributes above in the session vatiable
      this.router.navigate(['/addElement']); // Go to the page to choose element type.. yay!
    } else {
      // Set fresh element to 'no' as it will be a new subElement sharing supplier reference with its father
      openedBooking.freshElement = 'no'; openedBooking.flightsDone = 'no'; openedBooking.accomsDone = 'no'; openedBooking.carHiresDone = 'no';
      openedBooking.carParksDone = 'no'; openedBooking.attractionsDone = 'no'; openedBooking.miscsDone = 'no'; openedBooking.cruisesDone = 'no';
      openedBooking.trainsDone = 'no'; openedBooking.transfersDone = 'no'; openedBooking.supplierReference = '';
      openedBooking.supplierID = options.supplierID; openedBooking.packageDone = 'yes'; openedBooking.supplierName = options.supplierName;

      // Set the supplier reference below (flight has supplierRef which is quite annoying..)
      if (options.supplierRef !== undefined) { openedBooking.supplierReference = options.supplierRef; }
      if (options.supplierReference !== undefined) { openedBooking.supplierReference = options.supplierReference; }

      this.costingsData.data.forEach((costing: any) => {
        if (costing.flights.length > 0 && costing.flights[0].supplierRef === openedBooking.supplierReference) {
          openedBooking.flightsDone = 'yes';
        }
        if (costing.accoms.length > 0 && costing.accoms[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.accomsDone = 'yes';
        }
        if (costing.carhires.length > 0 && costing.carhires[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.carHiresDone = 'yes';
        }
        if (costing.carparks.length > 0 && costing.carparks[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.carParksDone = 'yes';
        }
        if (costing.attractions.length > 0 && costing.attractions[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.attractionsDone = 'yes';
        }
        if (costing.cruises.length > 0 && costing.cruises[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.cruisesDone = 'yes';
        }
        if (costing.miscs.length > 0 && costing.miscs[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.miscsDone = 'yes';
        }
        if (costing.trains.length > 0 && costing.trains[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.trainsDone = 'yes';
        }
        if (costing.transfers.length > 0 && costing.transfers[0].supplierReference === openedBooking.supplierReference) {
          openedBooking.transfersDone = 'yes';
        }
      });
      Session.mySession.setOpenedBooking(openedBooking); // Set opened booking with attributes above in the session vatiable
      this.router.navigateByUrl('/addElement', { state: options }); // Go to the page to choose element type.. yay!
    }
  }

  // GRAPHS TAB ABOVE

  // SUMMARY TAB BELOW

  showGroupBookings(): void {
    // Request's core parameters set up here
    const currentRequest = { company: this.bookingData.company, operation: this.bookingData.operation, bookRefOnly: 'false',
    tradeCode: this.bookingData.tradeCode, bookingGroup: this.bookingData.bookingGroup, token: Session.mySession.get('user').token };

    this.pageLoaded = false;
    this.bookingService.getBookingList(currentRequest).then((output: any) => {
      if (output.status === 'OK') {
        this.dialog.open(this.groupedBookingsDialog, { autoFocus: false, data: output.data });
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0841S)', error, currentRequest);
    });
  }

  editBookingGroup(): void {
    // Validate characters entered in the form
    if ((this.constants.validateVariableRegex(this.bookingData.bookingGroup, 'variable') !== true)) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.bookingData.bookingGroup, '', '');
    } else if (this.bookingData.bookingGroup.includes('\'')) {
      this.sendMessageToDialog('', 'You are not allowed to use single quote character', '', '');
    } else if (this.bookingData.bookingGroup !== null) {
      const request: any = this.requestUpdateVariable('booking'); // Get pre-defined request variable
      request.bookingGroup = this.bookingData.bookingGroup; // Set the name which is being displayed in the UI

      this.pageLoaded = false;
      this.bookingService.updateBookingSeq(request).then((output: any) => {
        if (output.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            this.sendMessageToDialog('Booking\'s group name has been updated', '', '', ''); // Print success message
            this.tabChanged('generalView', 'main');
          });
        } else {
          this.sendMessageToDialog('', 'Booking\'s group name update failed (' + output.status + ')', '', ''); // Fail message..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0840S)', error, request);
      });
    }
  }

  editBookingExtRef(): void {
    if (this.bookingData.extBookingSource !== '' && this.bookingData.extBookingSource.length < 3) {
      this.sendMessageToDialog('', 'You\'r reference needs to be at least 3 characters long', '', '');
    } else if (!/^[a-zA-Z0-9-]*$/.test(this.bookingData.extBookingSource)) {
      this.sendMessageToDialog('', 'Only numbers, letters and dashes are allowed in your reference', '', '');
    } else if (this.bookingData.extBookingSource !== null || this.bookingData.extBookingData !== '') {
      const request: any = this.requestUpdateVariable('booking'); // Get pre-defined request variable
      request.extBookingSource = this.bookingData.extBookingSource; // Set the name which is being displayed in the UI
      request.fromUI = 'true'; // Without this, the extenral reference won't be updated..

      this.pageLoaded = false;
      this.bookingService.updateBookingSeq(request).then((output: any) => {
        if (output.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            this.sendMessageToDialog('Booking\'s reference has been updated', '', '', ''); // Print success message
            this.tabChanged('generalView', 'main');
          });
        } else {
          this.sendMessageToDialog('', 'Booking\'s reference update failed (' + output.status + ')', '', ''); // Fail message..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0850S)', error, request);
      });
    }
  }

  editMarkupVATdeposit(what: any, reset: boolean): void {
    const request: any = this.requestUpdateVariable('booking'); // Get pre-defined request variable
    if (what === 'VAT' && !reset) { request.finalTax = String(this.finalVAT); } // Set finalTax property if we're editing tax
    else if (what === 'VAT' && reset) { request.finalTax = '0.0'; } // Set finalTax back to £0
    else if (what === 'Markup') { request.markUpDown = String(this.bookingData.markUpDown); } // Set markup property if we're editing markup
    else if (what === 'Deposit' && !reset) { request.finalDeposit = String(this.finalDeposit); } // Set finalDeposit property if we're editing deposit
    else if (what === 'Deposit' && reset) { request.finalDeposit = '0.0'; } // Set finalDeposit back to £0
    else if (what === 'Deposit Paid') { request.depositPaid = reset; } // Set the depositPaid value to RESET [!depositPaid]

    this.pageLoaded = false;
    this.bookingService.updateBookingSeq(request).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog(what + ' has been updated', '', '', ''); // Print success message
          this.tabChanged('generalView', 'main');
        });
      } else {
        this.sendMessageToDialog('', what + ' update failed (' + output.status + ')', '', ''); // Fail message..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0802S)', error, request);
    });
  }

  changeBookingStatus(event: any): void {
    // Create a request variable to hold the booking status user wants to change it to. Also return date which is mandatory
    const request: any = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, newStatus: event.value, token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.bookingService.updateBookingStatus(request).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog('Booking status update in progress. Please wait..', '', '', ''); // Print success message
          AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID);
        });
      } else {
        this.sendMessageToDialog('', 'Booking status update has failed (' + output.status + ')', '', ''); // Print error message
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0803S)', error, request);
    });
  }

  editDate(date: any, whatDate: any): void {
    // Date SHOULD be always set up from the date-picker
    // If so, we need to convert it below (if not null obviously..)
    if (date.value != null) { date = this.constants.convertDateMoment(date.value); }

    // Get the current date - need to compare with requested date
    const curDate = new Date(); const currentDate = this.constants.convertDateNotMoment(curDate);
    // CRF 133 - check if booking date is not in the future
    if (whatDate === 'Booking Date' && date > currentDate) {
      this.sendMessageToDialog('', 'Booking Date cannot be set in the future', '', ''); // Print error message
    } else {
      // Create a variable containing bookingDate & deptDate - both are necessary for non-enquiry bookings
      const request: any = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        bookingReference: this.bookingData.bookingReference, bookingStatus: this.bookingData.bookingStatus,
        bookingDate: this.bookingData.bookingDate, deptDate: this.bookingData.deptDate, returnDate: this.bookingData.returnDate,
        token: Session.mySession.get('user').token
      };

      // Depending on what date has been requested to change, change UI and back-end booking
      if (whatDate === 'Booking Date') { this.bookingData.bookingDate = date; request.bookingDate = date; }
      else if (whatDate === 'Departure Date') { this.bookingData.deptDate = date; request.deptDate = date; }
      else if (whatDate === 'Customer Due Date') { this.bookingData.balanceDueDate = date; request.balanceDueDate = date; }
      else if (whatDate === 'Supplier Due Date') { this.bookingData.dueDate = date; request.dueDate = date; }
      else if (whatDate === 'Return Date') { this.bookingData.returnDate = date; request.returnDate = date; }
      else if (whatDate === 'Deposit Due Date') { this.bookingData.depositDueDate = date; request.depositDueDate = date; }

      this.pageLoaded = false;
      this.bookingService.updateBookingSeq(request).then((output: any) => {
        if (output.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            this.sendMessageToDialog(whatDate + ' has been updated', '', '', ''); // Print success message
          });
        } else {
          this.sendMessageToDialog('', whatDate + ' update has failed (' + output.status + ')', '', ''); // Print error message
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0804S)', error, request);
      });
    }
  }

  editSuppsDueDate(costing: any): void {
    let suppDueDate: any = costing.suppDueDate;
    if (suppDueDate._i === undefined) { } else { suppDueDate = this.constants.convertDateMoment(suppDueDate); }

    // Create a request variable needed for editing the element (just like in element-edit page)
    // We set the suppDueDate taken from the UI (whatever it was set to)
    const request = {
      token: Session.mySession.get('user').token,
      coreElementData: {
        company: costing.company, operation: costing.operation, tradeCode: costing.tradeCode, elementStatus: costing.elementStatus,
        bookingReference: costing.bookingReference, supplierName: costing.supplierName, elementType: costing.elementType,
        elementCount: costing.elementCount, suppDueDate
      }
    };

    this.pageLoaded = false;
    this.elementService.updateElement(request).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.recalculateBookingsValues().then(() => {
            this.sendMessageToDialog('Due date has been updated', '', '', ''); // Display success message to the user
          });
        });
      } else {
        this.sendMessageToDialog('', 'Due date update failed (' + output.status + ')', '', ''); // Display error message to the user
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0832S)', error, request);
    });
  }

  editBookingSelects(selectType: any): void {
    const request: any = this.requestUpdateVariable('booking'); // Get pre-defined request variable
    request.leadSupplier = String(this.bookingData.leadSupplier); // Assign lead supplier value here..
    request.destCountry = String(this.bookingData.destCountry); // Assign destination country value here
    request.agentEmail = String(this.bookingData.agentEmail); // Assign travel agent here
    request.underATOL = String(this.bookingData.underATOL); // Assign underATOL variable

    if (selectType === 'ATOL coverage' && this.meetsATOLcriteria.flightOnly) { request.atolType = 'Flight'; }
    else if (selectType === 'ATOL coverage' && this.meetsATOLcriteria.atolPackage) { request.atolType = 'Package'; }

    if (selectType === 'Branch name') {
      request.tradingNameId = this.bookingData.tradingNameId;
      request.branchName = this.selectedBranch.tradingNames.find((name: any) => name.id === request.tradingNameId).tradingName;
      this.bookingData.branchName = request.branchName;
    }

    this.pageLoaded = false;
    this.bookingService.updateBookingSeq(request).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog(`${selectType} has been updated`, '', '', ''); // Display success message..
        });
      } else {
        this.sendMessageToDialog('', 'Update has failed (' + output.status + ')', '', ''); // Display error message
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0833S)', error, request);
    });
  }

  duplicateBooking(): void {
    if (confirm('Are you sure you want to duplicate this booking?')) {
      if (confirm('This will create a new booking with the same element data, except for passengers, receipts, payments, and notes.')) {
        const request: any = {
          company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
          bookingReference: this.bookingData.bookingReference, userID: Session.mySession.getUser().id.toString(),
          token: Session.mySession.get('user').token
        };

        this.pageLoaded = false;
        this.bookingService.duplicateBooking(request).then((res: any) => {
          if (res.status === 'OK') {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => // Go quickly to Dashboard
            this.router.navigate(['/booking/', res.reference])); // And enter bookign reference URL (to re-draw everything..)
          } else {
            this.sendMessageToDialog('', res.status, '', ''); // Display error message
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0851S)', error, request);
        });
      }
    }
  }

  // SUMMARY TAB ABOVE

  // PASSENGERS TAB BELOW

  getPassenger(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      form.value.token = Session.mySession.get('user').token; // Assign users token here..
      form.value.tradeCode = this.bookingData.tradeCode; // Assign trade code here..
      form.value.telNo = ''; // Assign telNo (which we don't use anymore..)
      
      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        form.value.exclusiveToId = Session.mySession.getUser().id.toString();
      }

      // Call getCustomer API and assign its output to 'customers'
      this.pageLoaded = false;
      this.customerService.getCustomer(form.value).then((passengers: any) => {
        if (passengers.status === 'OK') {
          this.disableAndAssignCustomerData(passengers); // Used to hide 'Add Passenger' buttons
          this.pageLoaded = true;
        } else {
          this.sendMessageToDialog('', passengers.status, '', ''); // Print error message here..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0808S)', error, form.value);
      });
    }
  }

  getBookCustCompanions(customerID: any): void {
    const request: any = {
      company: this.bookingData.company, operation: this.bookingData.operation,
      tradeCode: this.bookingData.tradeCode, customerID, token: Session.mySession.get('user').token
    };

    // Apply exclusiveToId filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      request.exclusiveToId = Session.mySession.getUser().id.toString();
    }

    this.pageLoaded = false;
    this.customerService.getBookCustCompanions(request).then((passengers: any) => {
      if (passengers.status === 'OK') {
        this.radioChangeCustomer('existing'); // Make sure the correct radio button is selected
        this.disableAndAssignCustomerData(passengers); // Used to hide 'Add Passenger' buttons
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', passengers.status, '', ''); // Print error message here..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0844S)', error, request);
    });
  }

  radioChangeCustomer(event: any): void {
    // Override the global radio value in new pax section
    this.newPaxRadioValue = event;
    // No matter what have been chosen, customer data array is cleared up every time change is made
    this.customerData.data = [];
  }

  // From 23.06.2022 this method is being called only from the Receipts tabs
  passengerSelected(index: any): void {
    if (index === null) {
      // Remove selected customer
      this.resetSelectedPassenger();
    } else {
      // Choose selected passenger by index in passenger table data
      this.selectedPassenger = this.passengersData.data[index];
    }
  }

  // From 23.06.2022 this method is being called only from the Receipts tabs
  resetSelectedPassenger(): void {
    this.selectedPassenger = {
      title: '', middleName: '', lastName: '', dob: '', telNo: '', homeNo: '', email: '', tradeCode: '', postcode: '',
      addressLine1: '', addressLine2: '', addressLine3: '', addressLine4: '', county: '', country: '',
      holidayInterest: '', customerNotes: ''
    };
    this.clearPostCodeString(); // Make customer table data empty and remove postcodeString
    this.selHolidayInterests = []; // Make sure holiday interests are empty from the go
  }

  // Below method checks for any possible duplicates in the variable
  disableAndAssignCustomerData(customers: any): void {
    customers.data = customers.data.filter((pax: any) => {
      if (pax && this.passengersData.data.some((pass: any) => pass.id === pax.id)) {
        pax.disabled = true;
        return true; // Keep the element in the array
      } else if (pax) {
        pax.disabled = false;
        return true; // Keep the element in the array
      } else {
        return false; // Remove the element from the array
      }
    });
    this.customerData.data = customers.data; // Assign customers data over here..
  }

  createAndAddPassenger(form: NgForm, bookCustID: any): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (this.constants.validateVariableRegex(form.value.customerNotes, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + form.value.customerNotes, '', '');
    } else {
      // DoB isn't required anymore, hence we need to check if it was provided by the user before converting it
      if (form.value.dob !== undefined && form.value.dob !== '') {
        form.value.dob = this.constants.convertDateMoment(form.value.dob);
        // In case the date was entered in the wrong format, 'erase' it instead..
        if (!moment(form.value.dob, 'YYYY-MM-DD', true).isValid()) { form.value.dob = null; }
        else {
          let dateToCompare = null; // This is the date we will compare with passenger DoB - it will NEED to be set before DoB
          if (this.bookingData.bookingDate != null) { dateToCompare = this.bookingData.bookingDate; }
          if (this.bookingData.deptDate != null) { dateToCompare = this.bookingData.deptDate; }
          // Passengers which happen to have their birthday 'in the future' are not allowed to be added - sorry!
          if (dateToCompare != null && moment(form.value.dob, 'YYYY-MM-DD').isSameOrAfter(dateToCompare)) {
            this.sendMessageToDialog('', 'Passengers must be born before the departure date', '', ''); return;
          }
        }
      }
      // CRF75 - By default the unique field will be set to true. Only after checking the checkbox we will allow duplicates
      if (this.showDupliBox && this.createConfirm) { form.value.onlyUnique = false; }
      // Get postcode value from postCodeString - autocomplete thing (annoying it is but I made it work!)
      form.value.postcode = this.postCodeString;
      // Join all selected holiday interests into a string (from array of strings). Separate them with semicolon
      form.value.holidayInterest = this.selHolidayInterests.join(';');

      form.value.tradeCode = this.bookingData.tradeCode; // Assign trade code here
      form.value.token = Session.mySession.get('user').token; // Assign the token to form.value here
  

      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        form.value.exclusiveToId = Session.mySession.getUser().id.toString();
      }
      
      this.pageLoaded = false;
      if (this.passengersData.data.length === 0 && form.value.email === '' && this.userType !== 'sinGSAdmin') {
        this.sendMessageToDialog('', 'Lead passenger must have an email address', '', '');
      } else {
        this.customerService.createCustomer(form.value).then((createOutput: any) => {
   
          if (createOutput.status === 'OK') {

            if (bookCustID) {
              this.updateGhostPassenger(createOutput.id, bookCustID);
            } else {
              const customer = { id: createOutput.id }
              this.addSelectedPassenger(customer);
            }

          } else if (createOutput.status === 'Error - this customer already exist in the system') {
            // We are not creating the customer yet - instead we'll display a checkbox to do. Re-send form to create duplicate
            this.showDupliBox = true; this.createConfirm = false; this.pageLoaded = true;
          } else {
            this.sendMessageToDialog('', createOutput.status, '', '');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0811S)', error, form.value);
        });
      }
    }
  }

  async addGhostPassengers(form: NgForm): Promise<void> {
    const numberOfPassengers: number = parseInt(form.value.ghostPaxNo);
    const customer = { id: null };

    if (numberOfPassengers > 10) {
      this.sendMessageToDialog('', 'You can add a maximum of 10 blank passengers at once', '', '');
    } else {
      for (let i = 0; i < numberOfPassengers; i++) {
        try {
          await this.addSelectedPassenger(customer); this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', 'There was an error when adding blank passenger', error, customer);
        }
      }
    }
  }

  updateGhostPassenger(customerID: any, bookCustID: any): void {
    const custLinkRequest: any = this.requestUpdateVariable('bookCustLink'); // Create a variable used later to create book cust link
    custLinkRequest.customerID = customerID;
    custLinkRequest.bookCustID = bookCustID;

    this.customerService.updateGhostBookCust(custLinkRequest).then((ghostUpdate: any) => {
      if (ghostUpdate.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog('Blank passenger has been updated', '', '', ''); // Print success message..
          this.tabChanged('passengerView', 'main'); // 'Reload' passengers data..
          this.unblockNewBooking(); // Unlock booking..
        });
      } else {
        this.sendMessageToDialog('', ghostUpdate.status, '', ''); // Print error message here..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0847S)', error, custLinkRequest);
    });
  }

  addMissingPaxFromReceipt(receipt: any): void {
    const customer: any = {
      company: receipt.company, operation: receipt.operation, tradeCode: receipt.tradeCode,
      bookingReference: receipt.bookingReference, id: receipt.payerId, isLead: false
    };

    if (this.passengersData.data.length === 0) { customer.isLead = true };

    this.addSelectedPassenger(customer); // Call method to add the passengers
    this.tabChanged('passengerView', 'main'); // 'Reload' passengers data..
  }

  addSelectedPassenger(customer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const custLinkRequest: any = this.requestUpdateVariable('bookCustLink'); // Create a variable used later to create book cust link
      // Below, if there's no passengers in the booking yet, we will make this one a lead. We need to update the booking's lead as well!
      if (this.passengersData.data.length === 0) { custLinkRequest.isLead = 'true'; }
      else { custLinkRequest.isLead = 'false'; } // It's not the first passenger so no need to lead it
      custLinkRequest.customerID = customer.id; // Assign customer ID to create customer - booking link here..
  
      this.pageLoaded = false;
      if (this.passengersData.data.length === 0 && customer.email === '' && this.userType !== 'sinGSAdmin') {
        this.sendMessageToDialog('', 'Lead passenger must have an email address', '', ''); resolve('');
      } else {
        this.customerService.createBookCustLink(custLinkRequest).then((bookCustOutput: any) => {
          if (bookCustOutput.status === 'OK') {
            Session.mySession.resetTimersOnBookingValues().then((res: any) => {
              this.recalculatePassengers().then(() => { // Refresh passenger values..
                this.disableAndAssignCustomerData(this.customerData); // Re-calculate and hide 'Add Passenger' buttons
                if (customer.id) { this.sendMessageToDialog('Passenger has been created', '', '', ''); } // Print success message..
                this.unblockNewBooking(); // Unlock booking..
                resolve('');
              });
           });
          } else {
            this.sendMessageToDialog('', bookCustOutput.status, '', ''); // Print error message here..
            resolve('');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0810S)', error, custLinkRequest);
          resolve('');
        });
      }
    });
   }

  editPassenger(form: NgForm, passenger: any): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (this.constants.validateVariableRegex(form.value.customerNotes, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + form.value.customerNotes, '', '');
    } else {
      // DoB isn't required anymore, hence we need to check if it was provided by the usr before converting it
      if (form.value.dob !== undefined && form.value.dob !== '') {
        passenger.dob = this.constants.convertDateMoment(form.value.dob);
        // In case the date was entered in the wrong format, 'erase' it instead..
        if (moment(passenger.dob, 'YYYY-MM-DD', true).isValid() === false) { passenger.dob = null; }
      }
      // Join all selected holiday interests into a string (from array of strings). Separate them with semicolon
      passenger.holidayInterest = this.selHolidayInterests.join(';');

      passenger.token = Session.mySession.get('user').token; // Assign the token to the object here!
      
      if (passenger.isLead && passenger.email === '' && this.userType !== 'sinGSAdmin') {
        passenger.email = passenger.emailDone; // Set the email back to what it was
        this.sendMessageToDialog('', 'Lead passenger must have an email address', '', '');
      } else {
        this.pageLoaded = false;
        this.customerService.updateCustomer(passenger).then((updateResult: any) => {
          if (updateResult.status === 'OK') {
            Session.mySession.resetTimersOnBookingValues().then((res: any) => {
              this.tabChanged('passengerView', 'main'); // 'Reload' passengers data..
              this.sendMessageToDialog('Passenger has been updated', '', '', ''); // Print success message here
            });
          } else {
            this.sendMessageToDialog('', updateResult.status, '', ''); // Print out error message if customer not updated
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0806S)', error, passenger);
        });
      }
    }
  }

  setLeadPassenger(passenger: any): void {
    if (passenger.emailDone === '' && this.userType !== 'sinGSAdmin') {
      this.sendMessageToDialog('', 'Lead passenger must have an email address', '', '');
    } else {
      const request: any = this.requestUpdateVariable('bookCustLink'); // Get the 'global' variable
      request.customerID = passenger.id; request.isLead = true; // And assign customerID & isLead to it
  
      this.customerService.updateIsLead(request).then((isLeadResult: any) => {
        if (isLeadResult.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            this.tabChanged('passengerView', 'main'); // 'Reload' passengers data..
            this.sendMessageToDialog('Passenger has been updated', '', '', ''); // Print success message here
          });
        } else {
          this.sendMessageToDialog('', isLeadResult.status, '', ''); // Print out error message
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0806S)', error, passenger);
      });
    }
  }

  removePassenger(passenger: any): void {
    const paxName = passenger.id !== null ? passenger.firstName + ' ' + passenger.lastName : 'Blank Passenger';

    if (confirm('Are you sure you want to remove ' + paxName + '?')) {
      if (confirm('100% sure you want to remove ' + paxName + '?')) {

        const request: any = this.requestUpdateVariable('bookCustLink'); // Get the 'global' variable
        request.bookCustID = passenger.bookCustID; // Assign bookCustID to it
        request.customerID = passenger.id; // And assign customerID to it

        this.pageLoaded = false;
        this.customerService.removeBookCustLink(request).then((result: any) => {
          if (result.status === 'OK') {
            Session.mySession.resetTimersOnBookingValues().then((res: any) => {
              this.recalculatePassengers().then(() => { // Refresh passenger values..
                this.disableAndAssignCustomerData(this.customerData); // Re-calculate and hide 'Add Passenger' buttons
                this.sendMessageToDialog('Passenger has been removed', '', '', ''); // Display success message here..
              });
            });
          } else {
            this.sendMessageToDialog('', result.status, '', ''); // Display error message here
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0807S)', error, request);
        });

      }
    }
  }

  changePaxActive(passenger: any, active: any): void {
    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, customerID: passenger.id, active: active, bookCustID: passenger.bookCustID,
      token: Session.mySession.get('user').token
    }

    this.customerService.changePaxActive(request).then((result: any) => {
      Session.mySession.resetTimersOnBookingValues().then((res: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.recalculatePassengers().then(() => { // Refresh passenger values..
          });
        });
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0846S)', error, request);
    });
  }

  changeShowOnDocs(passenger: any): void {
    if (passenger.showOnDocs === 'yes') { passenger.showOnDocs = 'no'; }
    else { passenger.showOnDocs = 'yes'; }

    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, showOnDocs: passenger.showOnDocs, bookCustID: passenger.bookCustID,
      token: Session.mySession.get('user').token
    }

    this.customerService.changeShowOnDocs(request).then((result: any) => {
      Session.mySession.resetTimersOnBookingValues().then((res: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.recalculatePassengers().then(() => { // Refresh passenger values..
          });
        });
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0848S)', error, request);
    });
  }

  // From Sep '22 this has been added - which should work just like in CRM
  postCodeLookup(event: any): void {
    this.postCodeString = event.target.value;
    // Whenever user types into the field, assign to variable in UPCASE
    // If it meets the regex then proceed with if statement. Otherwise - clear the list
    if (/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(event.target.value.toUpperCase())) {
      const niceOutput: any = [];
      // Postcode API needs two calls to be sent in order to retrieve address list we need
      // Second call requires container together with the postcode value
      const request = { postcode: event.target.value.toUpperCase(), container: '' };
      this.customerService.getPostcodeAddress(request).then((results: any) => {
        request.container = results.Items[0].Id;
        this.customerService.getPostcodeAddress(request).then((output: any) => {
          let address = '';
          // We need to break the address list output to an array(s) so we can work with them
          output.Items.forEach((addressLine: any) => {
            address = addressLine.Text + ', ' + addressLine.Description;
            niceOutput.push(address);
          });
          // Both varaibles contain same data here but they're used in a different way later
          this.rawAddressList = niceOutput;
          this.addressList = niceOutput;
        }).catch((error: any) => {
          //this.sendMessageToDialog('', 'Post Code checker could not complete your request at this time (E0802P)', error, request);
        });
      }).catch((error: any) => {
        //this.sendMessageToDialog('', 'Post Code checker could not complete your request at this time (E0801P)', error, request);
      });
    } else {
      this.addressList = [];
    }
  }

  // From Sep '22 this has been added - which should work just like in CRM
  selectPostcode(event: any, option: any, customer: any): void {
    const selectedRaw = this.rawAddressList[event].split(', ');
    this.filterString = ''; this.filterSelect(); // Whether there was a string or not - we need to erase the 'Search..' bit
    // Whether we assign the auto-address to new or existing customers, we have no way of knowing whether something is county, postcode etc..
    // With the current API provider, the lines are not cohesive hence we're assigning whatever's first to address1 etc. etc.
    if (option === 'new') {
      // Erase current selection below first
      this.addressLookup.address1 = ''; this.addressLookup.address2 = ''; this.addressLookup.address3 = ''; this.addressLookup.address4 = '';
      if (selectedRaw[0] != null && selectedRaw[0] !== this.postCodeString.toUpperCase()) { this.addressLookup.address1 = selectedRaw[0]; }
      if (selectedRaw[1] != null && selectedRaw[1] !== this.postCodeString.toUpperCase()) { this.addressLookup.address2 = selectedRaw[1]; }
      if (selectedRaw[2] != null && selectedRaw[2] !== this.postCodeString.toUpperCase()) { this.addressLookup.address3 = selectedRaw[2]; }
      if (selectedRaw[3] != null && selectedRaw[3] !== this.postCodeString.toUpperCase()) { this.addressLookup.address4 = selectedRaw[3]; }
      // tslint:disable-next-line:no-non-null-assertion
      (document.getElementById('postCode')! as HTMLInputElement).value = this.postCodeString;
      this.addressLookup.countryLine = 'United Kingdom';
    } else {
      // Erase current selection below first
      customer.addressLine1 = ''; customer.addressLine2 = ''; customer.addressLine3 = ''; customer.addressLine4 = '';
      if (selectedRaw[0] != null && selectedRaw[0] !== this.postCodeString.toUpperCase()) { customer.addressLine1 = selectedRaw[0]; }
      if (selectedRaw[1] != null && selectedRaw[1] !== this.postCodeString.toUpperCase()) { customer.addressLine2 = selectedRaw[1]; }
      if (selectedRaw[2] != null && selectedRaw[2] !== this.postCodeString.toUpperCase()) { customer.addressLine3 = selectedRaw[2]; }
      if (selectedRaw[3] != null && selectedRaw[3] !== this.postCodeString.toUpperCase()) { customer.addressLine4 = selectedRaw[3]; }
      customer.postcode = this.postCodeString;
      // tslint:disable-next-line:no-non-null-assertion
      (document.getElementById('postCode' + customer.id)! as HTMLInputElement).value = this.postCodeString;
      customer.country = 'United Kingdom';
    }
  }

  // From Sep '22 this has been added - which should work just like in CRM
  clearPostCodeString(): void {
    // Clear the postCodeString variable
    this.postCodeString = '';
    this.addressLookup = { address1: '', address2: '', address3: '', address4: '', postCode: '', postCodeString: '', countyLine: '', countryLine: '' };
    this.addressList = [];
    this.rawAddressList = [];
  }

  setHolidayInterests(customer: any): void {
    // Holiday interests are stored as a string - separated by comma
    // We need to convert it to array so the whole mini-system works as designed
    if (customer.holidayInterest !== null && customer.holidayInterest !== '') {
      this.selHolidayInterests = customer.holidayInterest.split(';');
    } else {
      this.selHolidayInterests = []; // Anything non-expected - set as empty
    }
  }

  addRemoveHolInterest(event: any): void {
    if (!event.target.checked) {
      // Remove selected holiday interest from the array list
      this.selHolidayInterests.splice(this.selHolidayInterests.indexOf(event.target.value), 1);
    } else {
      // Add selected holiday interest into the list (end of the array but so what..?)
      this.selHolidayInterests.push(event.target.value);
    }
  }

  // PASSENGERS TAB ABOVE

  // COSTS TAB BELOW

  resetSupplmPrices(resetOption: any): void {
    // Clear selected supplement/supplier
    if (resetOption === 'all') { this.selectedSupplement = {}; this.selectedSupplier = {}; }
    this.suppManPrice.grossCost = 0; this.suppManPrice.netCost = 0; // Set zero costs
    this.suppManPrice.commission = 0; this.suppManPrice.tax = 0; // Set zero costs
    this.suppManPrice.realGross = 0; this.suppManPrice.discount = 0; // Set zero costs
  }

  getSupplementPrice(supplement: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let today: any = new Date(); // Get today's date  here..
      today = this.constants.convertDateNotMoment(today); // Covert it to ruby-friendly format

      supplement.applicnDate = today; // Apply the date to supplement (so Ruby knows what price to get)
      supplement.token = Session.mySession.getUser().token; // Usual stuff..
      this.resetSupplmPrices('part'); // Reset prices quick quick

      this.pageLoaded = false;
      this.supplierService.getSupplmPrice(supplement).then((suppPrice: any) => {
        if (suppPrice.status === 'OK') {
          this.selectedSupplement = suppPrice.supplm; // Assign the output supplm to global variable
          // Find out whether the branch has special Standalone rate
          const customEntry = this.standaloneCustoms.find((entry: any) => entry.tradeCode === this.bookingData?.tradeCode);
          if (this.selectedSupplement.supName === 'Safe Seat Plan Guarantee - Standalone' && customEntry && new Date(this.bookingData?.createTS) >= new Date('2024-09-01')) {
            this.selectedSupplement.costFixPerPx = customEntry.rate;
          }
          this.pageLoaded = true; resolve('');
        } else {
          this.selectedSupplement = {}; // Make sure selected supplement is empty
          this.sendMessageToDialog('', 'Obtaining supplement information has failed (' + suppPrice.status + ')', '', ''); // Display error message
          resolve('');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0815S)', error, supplement);
        resolve('');
      });
    });
  }

  prepCardFeeSupplement(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Find the fee supplement in the array below - it should be "paymentMethod + Fee"
      const chosenSupl = this.supplementList.find((supplement: any) => supplement.supName === `${this.newReceipt.paymentMethod} Fee`);
      if (this.newReceipt.createSupplement && !chosenSupl) {
        this.sendMessageToDialog('', `Something went wrong with creating ${this.newReceipt.paymentMethod} Fee supplement`, '', ''); // Print error message..
        resolve(''); // End of promise
      } else if (this.newReceipt.createSupplement) {
        // Switch to 'Costings' tab and open div which creates a new Supplement
        this.tabChanged('suppliersView', 'script'); this.supplementCreate = true;

        this.supplementSelect = chosenSupl; // Change selected mat-option first, and then call getSupplementPrice to pull Suppl. details
        this.getSupplementPrice(chosenSupl).then(() => {
          // Change gross the gross value of the supplement --> supplement.gross = receipt.merchantFee
          this.changeGross(this.newReceipt.merchantFee, this.suppManPrice); // Automatically assign the gross value
          this.changeVAT(0, this.suppManPrice); // Make sure the commission = gross
          this.selectedSupplement.highlight = true; // Make sure we highlight the row by default (to get users attention)
        });
      } else { resolve(''); } // End of promise
    });
  }

  // COSTS TAB ABOVE

  // RECEIPT TAB BELOW

  calcReceiptMerchantFee(): void {
    if (this.newReceipt.paymentMethod === 'Amex') {
      this.newReceipt.merchantFee = (Number(this.newReceipt.totalCharge) * (Number(this.selectedBranch.cardFeePercAmex) / 100)).toFixed(2);
    } else if (this.newReceipt.paymentMethod === 'Credit Card') {
      this.newReceipt.merchantFee = (Number(this.newReceipt.totalCharge) * (Number(this.selectedBranch.cardFeePercCredit) / 100)).toFixed(2);
    } else if (this.newReceipt.paymentMethod === 'Debit Card') {
      this.newReceipt.merchantFee = (Number(this.newReceipt.totalCharge) * (Number(this.selectedBranch.cardFeePercDebit) / 100)).toFixed(2);
    }
  }

  newReceiptCalc(changeAttr: any): void {
    try { // Calculate the total charge - creditValue (what's been received) + totalCharge (what's been taken (by AMEX etc))
      if (!['Amex', 'Credit Card', 'Debit Card'].includes(this.newReceipt.paymentMethod) || this.newReceipt.merchantFee === null) {
        this.newReceipt.merchantFee = 0.00; // Anything but Amex, Credit Card, Debit Card will have £0 merchant fee
      } // Null values must be converted back to £0.00
      if (this.newReceipt.creditValue === null) { this.newReceipt.creditValue = 0.00; }
      if (this.newReceipt.totalCharge === null) { this.newReceipt.totalCharge = 0.00; }

      if (changeAttr === 'creditValue') {
        this.newReceipt.totalCharge = this.newReceipt.creditValue;
        this.calcReceiptMerchantFee();
        this.newReceipt.totalCharge = (Number(this.newReceipt.creditValue) + Number(this.newReceipt.merchantFee)).toFixed(2);
      } else if (changeAttr === 'merchantFee') {
        this.newReceipt.totalCharge = (Number(this.newReceipt.creditValue) + Number(this.newReceipt.merchantFee)).toFixed(2);
      } else if (changeAttr === 'totalCharge') {
        this.calcReceiptMerchantFee();
        this.newReceipt.creditValue = (Number(this.newReceipt.totalCharge) - Number(this.newReceipt.merchantFee)).toFixed(2);
      } else if (changeAttr === '') {
        this.newReceipt.creditValue = 0.00; this.newReceipt.totalCharge = 0.00; this.newReceipt.merchantFee = 0.00; this.newReceipt.createSupplement = false;
      }
    } catch (err: any) { console.log(err); }
  }

  addNewReceipt(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    // } else if (this.newReceipt.paymentMethod === 'Amex' && this.newReceipt.creditValue > 0 && this.newReceipt.merchantFee <= 0) {
    //  this.sendMessageToDialog('', 'The Merchant Fee must be higher than £0', '', '');
    } else if (this.newReceipt.creditValue === 0) {
      this.sendMessageToDialog('', 'The Credit Value cannot be £0', '', '');
    } else {
      let formDate: any = '';
      if (form.value.receiptDate._i == null) {
        formDate = this.constants.convertDateNotMoment(form.value.receiptDate); // Convert non-moment date here..
      } else {
        formDate = this.constants.convertDateMoment(form.value.receiptDate); // Convert moment date here..
      }
      // Set all necessery dates for date-related validations
      const weekFwDate = new Date(new Date().setDate(new Date().getDate() + 10)).setHours(0, 0, 0, 0);
      const weekBfDate = new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0);
      const chosenDate = new Date(formDate).setHours(0, 0, 0, 0);
      // Validate receipt dates below - no future, warn olds
      if ((chosenDate > weekBfDate && chosenDate < weekFwDate) ||
      (chosenDate <= weekBfDate && confirm('The receipt date seems to be at least 7 days old. Do you want to continue?')) ||
      (chosenDate >= weekFwDate && confirm('The receipt date seems to be at least 10 days in the future. Do you want to continue?'))) {

        let recStatus; // Work out the reconciliation status of receipt - usually it would be newReceipt (unless its Felloh)
        if (this.newReceipt.paymentMethod === 'Felloh - Manual') { recStatus = 'noneReqd'; }
        else { recStatus = 'newReceipt'; }

        // Create request variable containing all necessary information to create a receipt
        const request: any = {
          company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
          bookingReference: this.bookingData.bookingReference, creditValue: this.newReceipt.creditValue,
          totalCharge: this.newReceipt.totalCharge, merchantFee: this.newReceipt.merchantFee, reference: form.value.reference,
          receiptDate: formDate, paymentMethod: this.newReceipt.paymentMethod, recStatus,
          token: Session.mySession.get('user').token
        };

        if (form.value.customer !== '') {
          request.payerId = form.value.customer.id; // Assign payer ID and full name if customer was selected
          request.payerName = form.value.customer.firstName + ' ' + form.value.customer.lastName;
        }

        this.pageLoaded = false;
        this.receiptService.createReceipt(request).then((receipt: any) => {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            if (receipt.status === 'OK') {
              this.recalculateReceipts().then(() => {
                this.sendMessageToDialog('Receipt has been added to the booking', '', '', ''); // Print success message..
                this.bookingData.totalReceiptedCharged = (Number(this.bookingData.totalReceiptedCharged) + Number(this.newReceipt.totalCharge)).toFixed(2);
                this.unblockNewBooking(); // Unlock booking from future 'create new booking'
                this.receiptCreate = false; // Make receipt list visible once again..
                this.today = new Date(); // Set today's date back to what it was
                this.prepCardFeeSupplement().then(() => {
                  // Reset new receipt's values below..
                  this.newReceipt = { createSupplement: false, paymentMethod: '', creditValue: 0.00, merchantFee: 0.00, totalCharge: 0.00 };
                });
              });
            } else {
              this.tabChanged('receiptsView', 'main'); // Reload the page once again!
              this.sendMessageToDialog('', 'Adding receipt to the booking has failed (' + receipt.status + ')', '', ''); // Print error message..
            }
          });
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0816S)', error, request);
        });
      }
    }
  }

  editReceiptDate(event: any, receipt: any): void {
    delete receipt.detailRow; // Remove detailRow attribute which is not recognisable at the back
    delete receipt.freeText; // Remove freeText attribute which is not recognisable at the back
    delete receipt.missingPax; // Remove missingPax attribute which is not recognisable at the back
    receipt.token = Session.mySession.get('user').token;
    if (event.value != null) { receipt.receiptDate = this.constants.convertDateMoment(event.value); }
    else { receipt.receiptDate = event.value; }

    this.pageLoaded = false;
    this.receiptService.updateReceiptSeq(receipt).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog('Receipt date has been updated', '', '', ''); // Print success message..
          this.tabChanged('receiptsView', 'main'); // Reload the page once again!
        });
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // Print error message..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0836S)', error, receipt);
    });
  }

  cancelReceipt(receipt: any): void {
    if (confirm('Are you sure you want to CANCEL receipt ref ' + receipt.reference + '?')) {
      delete receipt.detailRow; // Remove detailRow attribute which is not recognisable at the back
      delete receipt.freeText; // Remove freeText attribute which is not recognisable at the back
      delete receipt.missingPax; // Remove missingPax attribute which is not recognisable at the back
      receipt.token = Session.mySession.get('user').token;
      receipt.receiptCategory = 'mistake'; // Mark the payment as a mistake (reverse thingy)
      receipt.payerId = null; receipt.payerName = null;

      this.pageLoaded = false;
      this.receiptService.updateReceiptSeq(receipt).then((output: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          if (output.status === 'OK') {
            this.sendMessageToDialog('Receipt has been cancelled', '', '', ''); // Print success message..
            this.tabChanged('receiptsView', 'main'); // Reload the page once again!
            // Below is so we don't need to reload booking values (saves us an API call..)
            this.bookingData.totalReceiptedCharged = (Number(this.bookingData.totalReceiptedCharged) - Number(receipt.totalCharge)).toFixed(2);
          } else {
            this.tabChanged('receiptsView', 'main'); // Reload the page once again!
            this.sendMessageToDialog('', output.status, '', ''); // Print error message..
          }
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0837S)', error, receipt);
      });
    }
  }

  changeReceiptLink(linkAction: any, receiptOrg: any, form: NgForm|any): void {
    const receipt = { ...receiptOrg }; // Create non-binding copy
    // Remove attributes which is not recognisable at the back
    delete receipt.freeText; delete receipt.detailRow;

    receipt.token = Session.mySession.get('user').token;

    // We are unlinking the element's references from receipt data object
    // All of below need to be set to null; the linkAction is used and ignored in Ruby
    if (linkAction === 'unlink') {
      receipt.linkAction = 'unlink'; receipt.payerId = null; receipt.payerName = null;
    // We're linking new element's references to selected payment object
    // All of below properties are assigned to the payment which is then updated in Ruby
    } else if (linkAction === 'link') {
      receipt.linkAction = 'link'; receipt.payerId = form.value.selectedPassenger.id;
      receipt.payerName = form.value.selectedPassenger.firstName + ' ' + form.value.selectedPassenger.lastName;
    }

    this.pageLoaded = false;
    this.receiptService.updateReceiptSeq(receipt).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog('Receipt link has been updated', '', '', ''); // Print success message..
          this.tabChanged('receiptsView', 'main'); // Reload the page once again!∂
        });
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // Print error message..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0805S)', error, receipt);
    });
  }

  validateFellohBooking(form: NgForm): void {
    // Validate characters entered in the form
    if (!this.constants.validateFormCharacters(form)) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // Check if the booking already exists within Felloh..
      this.getFellohBooking().then((bookingList: any) => {
        
        if (bookingList && bookingList?.meta?.reason === 'OK') {
          // Call method to create a payment link within that booking
          if (bookingList.data.length > 0) { 
            this.updateFellohBooking(bookingList.data[0].id).then((res: any) => {
              if (res && res?.meta?.reason === 'OK') { this.createFellohLink(form, bookingList.data[0].id); } 
              else { this.sendMessageToDialog('', res?.meta?.reason, '', ''); }
            });
          }
          
          // Booking does not exist - we need to create one and get its ID
          else {
            this.createFellohBooking().then((bookingRef: any) => {
              // Call method to create a payment link within that booking ==== CREATE BELOW ==== 
              if (bookingRef && bookingRef?.meta?.reason === 'OK') { this.createFellohLink(form, bookingRef.data.id); }
              else { this.sendMessageToDialog('', bookingRef?.meta?.reason, '', ''); }
            });
          }

        } else { this.sendMessageToDialog('', bookingList?.meta?.reason, '', ''); }
      });
    }
  }

  createFellohLink(form: NgForm, bookingId: any): void {
    // Create Felloh request hash to request access token and call the API
    Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
      // Create new hash variable which hold all sorts of data required by Felloh before creating new payment link. Parameters worth noting are
      // merchantRequestId -> booking reference | successURL - page which will display after payment complete (TBD)
      // cancelURL -> page which will display after payment cancelled(? TBD) | paymentStatusCallbackUrl -> API which will be called after successful payment (TBD)
      const tradingNameObj = this.selectedBranch.tradingNames.find((name: any) => name.id === this.bookingData.tradingNameId);

      // logoUrl -> logo which will appear in the top-left corner
      let logoURL = 'null'; // Set the logoRef here to null initially (as it is required anyway..)
      // If exists, re-set branch logo here
      if (tradingNameObj.logoRef !== '') { logoURL = encodeURI(tradingNameObj.logoRef); }

      // Set the success url - where the Felloh will redirect user after payment (either failed or not failed - doesn't matter)
      const successURL = environment.apiURL + 'fellohRedirect/uid=' + this.selectedPassenger.id + '/u=' + Session.mySession.getEmail().emailU +
        '/p=' + Session.mySession.getEmail().emailP + '/';
      // Set the fail url - where the user will be redirected upon fail? (tbd)
      const cancelURL = this.singsCustomerURL + 'cancelFelloh/' + encodeURI(tradingNameObj.tradingName) + '/' + logoURL + '/error';

      // Work out the currency below
      let currency = 'GBX';
      if (this.selectedCurrency === 'USD') { currency = 'USX'; }
      else if (this.selectedCurrency === 'EUR') { currency = 'EUX'; }

      // Work out excluded card types / regions below
      const excludedRegions = []; const excludedTypes = [];
      if (!this.fellohOptions.regions.uk) { excludedRegions.push('DOMESTIC'); }
      if (!this.fellohOptions.regions.europe) { excludedRegions.push('INTER'); }
      if (!this.fellohOptions.regions.world) { excludedRegions.push('INTRA'); }
      if (!this.fellohOptions.types.amex) { excludedTypes.push('AMEX'); }
      if (!this.fellohOptions.types.mastercard) { excludedTypes.push('MASTERCARD'); }
      if (!this.fellohOptions.types.visa) { excludedTypes.push('VISA'); }

      // Convert moment / non-moment date to appropriate date below yyyy-mm-dd
      if (this.fellohOptions.expiryDate === '') { this.fellohOptions.expiryDate = ''; }
      else if (this.fellohOptions.expiryDate._i == null) { this.fellohOptions.expiryDate = this.constants.convertDateNotMoment(form.value.expiryDate); }
      else { this.fellohOptions.expiryDate = this.constants.convertDateMoment(this.fellohOptions.expiryDate); }
      
      let expiryDate = this.fellohOptions.expiryDate === "" ? null : this.fellohOptions.expiryDate;

      // Create a large payment request hash object which contains all information needed to create a Felloh link
      const newLinkRequest: any = {
        customer_name: this.selectedPassenger.firstName + ' ' + this.selectedPassenger.lastName, email: this.selectedPassenger.email,
        organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode), booking_id: bookingId,
        currency: currency, amount: Math.round(parseFloat(form.value.amountDue) * 100),
        description: form.value.notes,
        success_url: successURL, cancel_url: cancelURL, type: 'UNKNOWN',
        metadata: { requestCreator: Session.mySession.getUser().email, tradingNameId: this.bookingData.tradingNameId, tradingName: this.bookingData.branchName, logoURL: logoURL },
        open_banking_enabled: this.fellohOptions.methods.openBanking, card_enabled: this.fellohOptions.methods.card,
        excluded_card_regions: excludedRegions,
        excluded_card_types: excludedTypes,
        expires_at: expiryDate
      };

      if (this.selectedFellohAccount.surcharging === 'yes') { newLinkRequest.surcharging_enabled = this.fellohOptions.surcharging; }

      this.pageLoaded = false;
      this.fellohService.createFellohLink(newLinkRequest, tokenOut).then(() => {
        this.reloadFelloh().then((res: any) => {
          this.expandedElement = this.fellohData.data[0]; // Automatically open the first table row
          this.passengerSelected(null); // Hide passenger select..
          this.unblockNewBooking(); // Unlock create booking funcitonality
          this.resetFellohOptions(); // Reset felloh options to default ones..
          this.fellohCreate = false; this.pageLoaded = true; // Make felloh list visible..
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0801F)', error, newLinkRequest);
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0803F)', error, '');
    });
  }

  emailFellohLink(payment: any, type: any): void {
    // Create urlLink variable which is fellohPayGate (taken from Global Constants file) + unique transactionId
    const urlLink = this.fellohPayGate + payment.id;
    // Create a clone of selected branch, but change their branch name to trading name..
    const tradingNameObj = this.selectedBranch.tradingNames.find((name: any) => name.id === this.bookingData.tradingNameId);
    const tradingName = { ...this.selectedBranch }; tradingName.branchName = tradingNameObj.tradingName;
    tradingName.telephone = tradingNameObj.phoneNumber; tradingName.email = tradingNameObj.emailAddress;
    // Call email tempalte method which will open a new window with pre-populated email text
    // This is also where custom email templates are created for selected branch(es)
    EmailTemplates.myEmails.getFellohEmailBodyV2(urlLink, payment, tradingName, Session.mySession.getUser(), type);
  }

  deleteFellohLink(payment: any): void {
    if (confirm('Are you sure to remove payment ' + payment.id + '?')) {
      // Create Felloh request hash to request access token and call the API
      this.pageLoaded = false;
      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {

        this.fellohService.deleteFellohLink(payment.id, tokenOut).then((output: any) => {
          // Remove payment link from the data source variable (no need to reload felloh - better!)
          this.fellohData.data = this.fellohData.data.filter((transaction: any) => transaction.id !== payment.id);
          this.sendMessageToDialog('Payment link has been removed', '', '', ''); // Print out success message
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0805F)', error, payment.id);
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0804F)', error, '');
      });
    }
  }

  openFellohLink(payment: any, source: any): void {
    const url = this.fellohPayGate + payment.id + '?' + source;
    // Create a url based from the link's ID + source (over phone or in person)
    window.open(url, '_blank'); // Open the link afterwards
  }

  getFellohBooking(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageLoaded = false;
      
      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
        // Create a Felloh request object with an organisation + booking reference pair
        const request: any = {
          organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
          booking_reference: this.bookingData.bookingReference
        };

        // Below request will return Felloh booking (probably just one but who knows..?)
        this.fellohService.getFellohBookings(request, tokenOut).then((bookingList: any) => {
          resolve(bookingList);
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2909F)', error, '');
          resolve(error?.error);
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2910F)', error, '');
        resolve(error?.error);
      });
    });
  }

  createFellohBooking(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageLoaded = false;

      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
        // Create a Felloh request object with an organisation + booking reference pair
        // Additionally parse in all optional values to make Felloh more happy
        const request: any = {
          organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
          customer_name: this.selectedPassenger.firstName + ' ' + this.selectedPassenger.lastName,
          email: this.selectedPassenger.email, booking_reference: this.bookingData.bookingReference,
          departure_date: this.bookingData.deptDate, return_date: this.bookingData.returnDate
        };
        // Append gross_amount ONLY IF GROSS IS HIGHER THAN £0.00
        if (Number(this.bookingData.custPrice) * 100 > 0) {
          request.gross_amount = Math.round((Number(this.bookingData.custPrice) * 100));
        }

        // Below request will create a Felloh booking and return its ID to us
        this.fellohService.createFellohBooking(request, tokenOut).then((bookingRes: any) => {
          resolve(bookingRes);
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2911F)', error, '');
          resolve(error?.error);
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2912F)', error, '');
        resolve(error?.error);
      });
    });
  }

  updateFellohBooking(bookingId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageLoaded = false;

      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
        // Create a Felloh request object with an organisation + booking reference pair
        // Additionally parse in all optional values to make Felloh more happy
        const request: any = {
          departure_date: this.bookingData.deptDate, return_date: this.bookingData.returnDate
        };
        // Append gross_amount ONLY IF GROSS IS HIGHER THAN £0.00
        if ((Number(this.bookingData.custPrice)) > 0) {
          request.gross_amount = (Number(this.bookingData.custPrice));
        }

        // Below request will create a Felloh booking and return its ID to us
        this.fellohService.updateFellohBooking(request, bookingId, tokenOut).then((bookingRes: any) => {
          resolve(bookingRes);
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2913F)', error, '');
          resolve(error?.error);
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2914F)', error, '');
        resolve(error?.error);
      });
    });
  }

  resetFellohOptions(): void {
    this.fellohOptions = {
      opened: false,
      types: { amex: true, mastercard: true, visa: true },
      regions: { uk: true, europe: true, world: true },
      methods: { card: true, openBanking: true },
      surcharging: true,
      expiryDate: ''
    };
  }

  toggleFellohOptions(): void {
    this.fellohOptions.opened = !this.fellohOptions.opened;
  }

  // RECEIPT TAB ABOVE

  // PAYMENTS TAB BELOW

  selectPaymentSupplier(costing: any): void {
    this.deselectAllCostings(); // First we need to reset and de-select all costings
    costing.costingSelected = true; // We then make clicked costing 'selected'
    this.commissionPaymentSelected = false; // We can't select both supplier payment and commission
  }

  selectPaymentCommission(): void {
    this.deselectAllCostings(); // First we need to reset and de-select all costings
    this.commissionPaymentSelected = true; // We're setting commission to true (on confirmation please see method below..
  }

  addNewPayment(form: NgForm): void {
    // Retrieve selected items first..
    const selectedItems = this.costingsData.data.filter((item: any) => item.costingSelected === true);
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (selectedItems.length === 0 && !this.commissionPaymentSelected) {
      this.sendMessageToDialog('', 'Please choose the payment\'s relevant supplier', '', '');
    } else if (selectedItems.length > 1) {
      this.sendMessageToDialog('', 'Please limit selection to a single supplier for this payment', '', '');
    } else {
      // Retrieve selected item - should be only one, and if it's not it would be shown in two else if above
      const selectedElement = selectedItems[0];

      if (form.value.paymentDate._i == null) {
        form.value.paymentDate = this.constants.convertDateNotMoment(form.value.paymentDate); // Convert non-moment date here..
      } else {
        form.value.paymentDate = this.constants.convertDateMoment(form.value.paymentDate); // Convert moment date here..
      }

      let paymentCategory = 'payment';
      if (this.commissionPaymentSelected) { paymentCategory = 'commission'; }

      // Create request variable containing all necessary information to create a payment
      const request: any = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode, bookingReference: this.bookingData.bookingReference,
        paymentDate: form.value.paymentDate, paymentCurrency: form.value.currency, paymentMethod: form.value.paymentMethod,
        supplierRef: selectedElement?.supplierReference, supplierID: selectedElement?.supplierID, supplierDueDate: selectedElement?.suppDueDate,
        elementCount: selectedElement?.elementCount, paymentReference: form.value.paymentReference, paymentAmount: form.value.paymentValue,
        paymentCategory, token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      this.paymentService.createPayment(request).then((payment: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          if (payment.status === 'OK') {
            this.sendMessageToDialog('Payment has been added to the booking', '', '', ''); // Print success message..
            this.unblockNewBooking(); // Unlock booking from future 'create new booking'
            this.tabChanged('paymentsView', 'main'); // Reload the page once again!
            this.paymentCreate = false; // Make payment list visible once again..
            this.today = new Date(); // Set today's date back to what it was
            // Below is so we don't need to reload booking values (saves us an API call..)
            if (this.commissionPaymentSelected) {
              this.bookingData.commissionPaid = (Number(this.bookingData.commissionPaid) + Number(form.value.paymentValue)).toFixed(2);
            } else {
              selectedElement.supplierPayments = (Number(selectedElement.supplierPayments) + Number(form.value.paymentValue)).toFixed(2);
            }
          } else {
            this.tabChanged('paymentsView', 'main'); // Reload the page once again!
            this.sendMessageToDialog('', payment.status, '', ''); // Print error message..
          }
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0817S)', error, request);
      });
    }
  }

  changePaymentLink(linkAction: any, paymentOrg: any, form: NgForm|any): void {
    const payment = { ...paymentOrg }; // Create non-binding copy
    // Remove attributes which is not recognisable at the back
    delete payment.freeText; delete payment.detailRow; delete payment.supplierName;

    payment.token = Session.mySession.get('user').token;

    // We are unlinking the element's references from payment data object
    // All of below need to be set to null; the linkAction is used and ignored in Ruby
    if (linkAction === 'unlink') {
      payment.linkAction = 'unlink'; payment.elementCount = null;
      payment.supplierRef = null; payment.supplierID = null;
      payment.supplierDueDate = null;
    // We're linking new element's references to selected payment object
    // All of below properties are assigned to the payment which is then updated in Ruby
    } else if (linkAction === 'link') {
      payment.linkAction = 'link'; payment.elementCount = form.value.supplierCosting.elementCount;
      payment.supplierRef = form.value.supplierCosting.supplierReference;
      payment.supplierID = form.value.supplierCosting.supplierID;
      payment.supplierDueDate = form.value.supplierCosting.suppDueDate;
    }

    this.pageLoaded = false;
    this.paymentService.updatePaymentSeq(payment).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog('Payment link has been updated', '', '', ''); // Print success message..
          this.tabChanged('paymentsView', 'main'); // Reload the page once again!

          if (linkAction === 'link') {
            // Below is so we don't need to reload booking values (saves us an API call..)
            const selectedItems = this.costingsData.data.filter((item: any) => item.elementCount === form.value.supplierCosting.elementCount);
            selectedItems[0].supplierPayments = (Number(selectedItems[0].supplierPayments) + Number(payment.paymentAmount)).toFixed(2);
          } else if (linkAction === 'unlink') {
            // Below is so we don't need to reload booking values (saves us an API call..)
            const selectedItems = this.costingsData.data.filter((item: any) => item.elementCount === paymentOrg.elementCount);
            selectedItems[0].supplierPayments = (Number(selectedItems[0].supplierPayments) - Number(payment.paymentAmount)).toFixed(2);
          }
        });
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // Print error message..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0805S)', error, payment);
    });
  }

  editPaymentDate(event: any, paymentOrg: any): void {
    const payment = { ...paymentOrg }; // Create non-binding copy
    // Remove attributes which is not recognisable at the back
    delete payment.freeText; delete payment.detailRow; delete payment.supplierName;

    payment.token = Session.mySession.get('user').token;
    if (event.value != null) { payment.paymentDate = this.constants.convertDateMoment(event.value); }
    else { payment.paymentDate = event.value; }

    this.pageLoaded = false;
    this.paymentService.updatePaymentSeq(payment).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.sendMessageToDialog('Payment date has been updated', '', '', ''); // Print success message..
          this.tabChanged('paymentsView', 'main'); // Reload the page once again!
        });
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // Print error message..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0835S)', error, payment);
    });
  }

  cancelPayment(paymentOrg: any): void {
    const payment = { ...paymentOrg }; // Create non-binding copy
    // Retrieve selected items first..
    const selectedItems = this.costingsData.data.filter((item: any) => item.elementCount === payment.elementCount);

    if (confirm('Are you sure you want to CANCEL payment ref ' + payment.paymentReference + '?')) {
      // Remove attributes which is not recognisable at the back
      delete payment.freeText; delete payment.detailRow; delete payment.supplierName;

      payment.token = Session.mySession.get('user').token;
      payment.paymentCategory = 'mistake'; // Mark the payment as a mistake (reverse thingy)

      payment.elementCount = null; payment.supplierRef = null;
      payment.supplierID = null; payment.supplierDueDate = null;

      this.pageLoaded = false;
      this.paymentService.updatePaymentSeq(payment).then((output: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          if (output.status === 'OK') {
            this.sendMessageToDialog('Payment has been cancelled', '', '', ''); // Print success message..
            this.tabChanged('paymentsView', 'main'); // Reload the page once again!
            // Below is so we don't need to reload booking values (saves us an API call..)
            if (selectedItems.length === 1) {
              selectedItems[0].supplierPayments = (Number(selectedItems[0].supplierPayments) - Number(payment.paymentAmount)).toFixed(2);
            }
          } else {
            this.tabChanged('paymentsView', 'main'); // Reload the page once again!
            this.sendMessageToDialog('', output.status, '', ''); // Print error message..
          }
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0838S)', error, payment);
      });
    }
  }

  // PAYMENTS TAB ABOVE

  // DOCUMENTATION STUFF BELOW

  uploadDocument(fileInput: any): void {
    const allFiles = Array.from(fileInput.target.files); // Get the file from target array list

    if (allFiles.some((singleFile: any) => singleFile.size > 4194304)) {
      this.sendMessageToDialog('', 'One of the files is too big. Maximum size is 4MB per file', '', '');
    } else {
      const uploadRequest = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        bookingReference: this.bookingData.bookingReference, pathType: 'attachedBookDocs', token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      // Upload files one by one using a loop
      this.uploadFilesSequentially(uploadRequest, allFiles).then(() => {
        this.reloadS3documents();
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'There was problem with uploading your file(s) (E0828S)', error, uploadRequest);
      });
    }
  }

  private async uploadFilesSequentially(uploadRequest: any, files: any): Promise<void> {
    for (const singleFile of files) {
      try {
        const result: any = await this.reportService.uploadS3files(uploadRequest, [singleFile]); // Wrap the file in an array
        if (result.status !== 'OK') {
          this.sendMessageToDialog('', result.status, '', '');
        }
      } catch (error) {
        throw error; // Exit the loop on error
      }
    }
  }

  reloadS3documents(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      const uploadRequest = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        bookingReference: this.bookingData.bookingReference, pathType: 'attachedBookDocs', token: Session.mySession.get('user').token
      };

      // this.pageLoaded = false;
      this.reportService.getS3files(uploadRequest).then((output: any) => {
        if (output.status === 'OK') {

          if (output.data.contents !== undefined) {
            // Sort files by date
            output.data.contents = output.data.contents.sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
            this.attachedDocs.data = output.data.contents; // If contents exist within S3 buckets, put them into data property
            this.attachedDocs.data.forEach((file: any) => {
              file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
            });
          } else {
            this.attachedDocs.data = []; // Contents not found - set empty array in the data
          }

          this.pageLoaded = true; resolve('');
        } else {
          this.sendMessageToDialog('', output.status, '', ''); resolve(''); // Print error..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0829S)', error, uploadRequest); resolve(''); // Print error..
      });
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '', ''); // Display error message here..
    });
  }

  downloadDocument(file: any, pathType: any): void {
    if (!file.name) { file = { name: file }; } // In case file is just a string, we will convert it into an object..

    const downloadRequest = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, pathType, fileName: file.name, token: Session.mySession.get('user').token
    };

    // Get file's extension and prepare for assigning MIME type below..
    const extension = file.name.substr(file.name.lastIndexOf('.') + 1); let type = '';
    // Depending on the extension, we'll assign the right MIME type below..
    if (extension === 'csv') { type = 'text/csv'; }
    else if (extension === 'doc') { type = 'application/msword'; }
    else if (extension === 'docx') { type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; }
    else if (extension === 'pdf') { type = 'application/pdf'; }
    else if (extension === 'xls') { type = 'application/vnd.ms-excel'; }
    else if (extension === 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
    else if (extension === 'jpeg' || extension === 'jpg') { type = 'image/jpeg'; }

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportService.downloadS3file(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
          // convert base64 to raw binary data held in a string
          // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
          const byteString = atob(output.fileContent);

          // write the bytes of the string to an ArrayBuffer
          const ab = new ArrayBuffer(byteString.length);

          // create a view into the buffer
          const ia = new Uint8Array(ab);

          // set the bytes of the buffer to the correct values
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          // Output BLOB needs to be transformed into an excel application file
          const data = new Blob([ab], { type });
          saveAs(data, file.name); // Call this function which opens browser's 'Save As..' window
          this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0830S)', error, downloadRequest);
    });

  }

  removeAttachedDocument(file: any): void {
    if (confirm('Are you sure you want to remove ' + file.name + '?')) {
      const removeRequest = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        bookingReference: this.bookingData.bookingReference, fileName: file.name, pathType: 'attachedBookDocs',
        token: Session.mySession.get('user').token
      };

      // Call downloadS3file method
      this.pageLoaded = false;
      this.reportService.removeS3file(removeRequest).then((output: any) => {
        if (output.status === 'OK') {
          setTimeout(() => { // It's done so there's some delay between setting boolean value in AppComponent
            this.reloadS3documents().then(() => {
              this.pageLoaded = true;
            }); // Reload documentation
          }, 1000);
        } else {
          this.sendMessageToDialog('', output.status, '', ''); // File removal failed at the back-end
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0831S)', error, removeRequest);
      });
    }
  }

  generateATOLpdf(atolType: any): void {
    // We need a request variable which will contain all necessary req. info
    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, atolType, actionedBy: Session.mySession.getUser().fullName,
      token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.reportService.generateATOLpdf(request).then((output: any) => {
      if (output.status === 'OK') {
        this.sendMessageToDialog('Your certificate has been successfully generated and is now available in the "Attached Documents" section', '', '', '');
        this.reloadS3documents(); // ATOL Certificates are being automatically uploaded to booking's S3 documents..
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // Print error message..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0842S)', error, request);
    });
  }

  downloadCertificate(costing: any, type: any): void {
    // We need a request variable which will contain all necessary req. info
    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, actionedBy: Session.mySession.getUser().fullName,
      supplementCount: costing.supplements[0].supplementCount, type, token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.reportService.generateSAG(request).then((output: any) => {
      if (output.status === 'OK') {
        try {
          window.open(output.presignedUrl, '_blank'); this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0849S)', error, request);
    });
  }

  printDocument(documentName: any, niceName: any, subRef: any, emailDocument: any): void {
    // Create a request variable which holds all booking data and more..
    // subRef used for receipt/payment count (we select what receipt/payment we want to print)
    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode, subRef,
      bookingReference: this.bookingData.bookingReference, template: documentName + '.erb', format: 'html', rtnData: 'yes',
      outputFileName: '', transcribe: 'yes', emailDocument: emailDocument, documentNiceName: niceName,
      generatedBy: Session.mySession.getUser().fullName, token: Session.mySession.get('user').token
    };
    // If receipt was marked to add ATOL text, we are changing the document format below
    if (documentName === 'receiptLetterV2' && this.atolReceipt) { request.format = 'htmlATOL'; }

    this.pageLoaded = false;
    this.reportService.getDocument(request).then((output: any) => {
      if (output.status === 'OK') {
        
        if (emailDocument == 'no') {
          const a = window.open('', '', 'height=700, width=1024'); // Open new window where we'll print selected document
          // tslint:disable-next-line:no-non-null-assertion
          a!.document.write(String(output.rtnData)); a!.document.close(); // Push the html output into the window opened ealier on
        } else if (emailDocument == 'yes') {
          this.documentRequest = request; this.documentName = niceName;
          this.htmlPage = String(output.rtnData);
          this.dialog.open(this.emailPopUpDialog, { autoFocus: false,  disableClose: false, panelClass: 'emailPopUpDialog' });
        }

        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // Print error message..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0801S)', error, request);
    });
  }

  docTemplateSettings(templateName: any, documentName: any, isNewTemplate: boolean): void {
    this.templateName = templateName; this.documentName = documentName; this.isNewTemplate = isNewTemplate;
    this.dialog.open(this.docSettingsDialog, { autoFocus: false,  disableClose: false, panelClass: 'docSettingsDialog' });
  }

  // DOCUMENTATION STUFF ABOVE

  // NOTES TAB BELOW

  addBookingNote(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateVariableRegex(form.value.comment, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // Create a variable which will be used to create a new note
      const request: any = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        noteType: 'booking', reference: this.bookingData.bookingReference, note: form.value.comment,
        important: 'no', showOnDocs: 'no', token: Session.mySession.get('user').token
      };

      // Work out whether the note should be marked as 'important' and/or 'showOnDocs'
      if (this.newNoteOptions.important) { request.important = 'yes'; }
      if (this.newNoteOptions.showOnDocs) { request.showOnDocs = 'yes'; }

      this.pageLoaded = false;
      this.noteService.createNote(request).then((output: any) => {
        if (output.status === 'OK') {
          form.reset({ comment: '' }); // Reset form's comment
          this.newNoteOptions.important = false; // Reset 'important' checkbox
          this.newNoteOptions.showOnDocs = false; // Reset 'showOnDocs' checkbox
          this.tabChanged('notesView', 'main'); // Reload notes..showOnDocs
        } else {
          this.sendMessageToDialog('', output.status, '', ''); // Print error message..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0822S)', error, request);
      });
    }
  }

  editBookingNote(note: any, operation: any): void {
    if (confirm(`Are you sure you want to mark this note ${operation}?`)) {
      // A very easy way to change object's values
      // We're only changing two properties, and we can append it to the request below
      if (operation === 'as archived') { note.archive = 'yes'; }
      else if (operation === 'as important') { note.important = 'yes'; }
      else if (operation === 'as non important') { note.important = 'no'; }
      else if (operation === 'to show on documents') { note.showOnDocs = 'yes'; }
      else if (operation === 'to not show on documents') { note.showOnDocs = 'no'; }

      note.token = Session.mySession.get('user').token; // Append the token - much needed..

      this.pageLoaded = false;
      this.noteService.editNote(note).then((output: any) => {
        if (output.status === 'OK') {
          this.tabChanged('notesView', 'main'); // Reload note views
        } else {
          this.sendMessageToDialog('', output.status, '', ''); // Fail message..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0834S)', error, note);
      });
    }
  }

  // NOTES TAB ABOVE

  addNewSupplement(form: any, pricing: any): void {
    // Validate characters entered in the form
    if (form !== null && this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // Create a new request variable which holds coreElementData (necessary for creating supplements now..)
      // Supplier Name is actually supplement type client name (it may change in the future..?)
      const request: any = {
        token: Session.mySession.get('user').token,
        coreElementData: {
          company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode, elementStatus: 'enquiry',
          bookingReference: this.bookingData.bookingReference, supplierName: this.selectedSupplement.supName, elementType: 'supplement'
        },
        supplementElementData: {
          subElementType: 'supplement', subElementStatus: 'enquiry', supplementStatus: 'enquiry', supplementTypeClient: this.selectedSupplement.supName
        }
      };

      // If user decides to add a supplement which is manually priced from the UI, we need to put additonal fields as below..
      if (pricing === 'manualPricing') {
        request.supplementElementData.grossCost = this.suppManPrice.realGross;
        request.supplementElementData.netCost = this.suppManPrice.netCost;
        request.supplementElementData.tax = this.suppManPrice.tax;
        request.supplementElementData.commission = this.suppManPrice.commission;
        request.supplementElementData.discount = this.suppManPrice.discount;
      }

      // If the supplement is SFC, then we will take the costBasedOn from its related object
      if (this.selectedSupplement.supName === 'SFC') { request.supplementElementData.costBasedOn = this.newSfcInfo.costBasedOn; }

      // If the supplement is APC - ATOL Protection Contribution, then we will need to append the ATOL type..
      if (this.selectedSupplement.supName === 'APC - ATOL Protection Contribution') {
        if (this.meetsATOLcriteria.flightOnly) { request.supplementElementData.supplementTypeMember = 'Flight'; }
        else if (this.meetsATOLcriteria.atolPackage) { request.supplementElementData.supplementTypeMember = 'Package'; }
      }

      this.pageLoaded = false;
      this.elementService.createElement(request).then((output: any) => {
        if (output.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            this.recalculateBookingsValues().then(() => {
              this.unblockNewBooking(); // Unlock booking from future 'create new booking'
              this.resetSupplmPrices('all'); // Reset supplement prices available from select list (supplement create)
              this.supplementCreate = false; // Switch the view to create
              this.sendMessageToDialog('Supplement has been created', '', '', '');
            });
          });
        } else {
          this.sendMessageToDialog('', 'Failed to create Supplement element (' + output.status + ')', '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0826S)', error, request);
      });
    }
  }

  calcSupplementCosts(supplement: any): void {
    supplement.supplements[0].realGross = supplement.supplements[0].grossCost;
    supplement.supplements[0].realGross = (Number(supplement.supplements[0].grossCost) + Number(supplement.supplements[0].discount)).toFixed(2);

    if (supplement.autoPricing && this.expandedElement === supplement && (!['SFC', 'SAFI'].includes(supplement.supplierName) || (supplement.supplierName === 'SAFI' && this.oldSafi))) {
      this.pageLoaded = false; this.selectedSupplement = {};
      const suppObj = { ... supplement }; suppObj.supName = suppObj.supplierName;
      this.getSupplementPrice(suppObj).then((res: any) => {})
    }
  }

  changeSFCbasis(supplement: any, elementCount: any): void {
    // Change the costBasedOn value below - that's all!
    if (supplement.costBasedOn === 'gross') { supplement.costBasedOn = 'net'; }
    else { supplement.costBasedOn = 'gross'; }
    // Call editSupplement method - viola!
    this.editSupplement(supplement, supplement.supplementStatus, elementCount);
  }

  changeAppliedDiscount(supplement: any, elementCount: any, applyDiscount: boolean): void {
    // Change supplement's applyDiscount boolean below
    supplement.applyDiscount = applyDiscount;
    // Call editSupplement method - viola!
    this.editSupplement(supplement, supplement.supplementStatus, elementCount);
  }

  editSupplement(supplement: any, status: any, elementCount: any): void {
    // Create a request variable needed for editing supplement (just like in element-edit page)
    // We set status in core/subElement status taken from the UI (depending on what button has been clicked)
    const request = {
      token: Session.mySession.get('user').token,
      coreElementData: {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode, elementStatus: status,
        bookingReference: this.bookingData.bookingReference, supplierName: supplement.supplementTypeClient, elementType: 'supplement', elementCount
      },
      supplementElementData: {
        subElementType: 'supplement', subElementStatus: status, supplementStatus: status, supplementTypeClient: supplement.supplementTypeClient,
        grossCost: supplement.grossCost, netCost: supplement.netCost, tax: supplement.tax, commission: supplement.commission, discount: supplement.discount,
        supplementCount: supplement.supplementCount, costBasedOn: supplement.costBasedOn, applyDiscount: supplement.applyDiscount, action: 'update'
      }
    };

    this.pageLoaded = false;
    this.elementService.updateElement(request).then((output: any) => {
      if (output.status === 'OK' || output.status === 'OK - no diff') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.recalculateBookingsValues().then(() => {
            this.sendMessageToDialog('Supplement has been updated', '', '', ''); // Display success message to the user
          });
        });
      } else {
        this.sendMessageToDialog('', 'Failed to update Supplement element (' + output.status + ')', '', ''); // Display error message to the user
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0827S)', error, request);
    });
  }

  getSFCcoverPrice(): void {
    let sfcSuppGross: any = 0; let sfcSuppNet: any = 0;

    // Loop through each element and sum both gross and net values of all SFC-covered suppliers
    this.costingsData.data.forEach((costing: any) => {
      const supplier = this.suppliersList.find((obj: any) => {
        return ( obj.id?.toString() === costing.supplierID?.toString() || obj.supplierNameM === costing.supplierName);
      });
      costing.isUnderSFC = supplier?.isUnderSFC; // Assign isUnderSFC to our costing
      costing.isUnderSafi = supplier?.underSafi === 'yes'; // Assign underSAFI to our costing
      // Adding Gross and Net to the total SFCgross below
      if (supplier && supplier?.isUnderSFC) {
        sfcSuppGross = (parseFloat(sfcSuppGross) + parseFloat(costing.gross)).toFixed(2);
        sfcSuppNet = (parseFloat(sfcSuppNet) + parseFloat(costing.net)).toFixed(2);
      }
    });

    // Determine whether one or more suppliers is under SFC
    this.sfcCovered = this.costingsData.data.some((costing: any) => costing.isUnderSFC === true);

    // Divide the total SFC gross & net by the number of passengers
    if (this.bookingData.paxNo > 0) {
      sfcSuppGross = (sfcSuppGross / parseFloat(this.bookingData.paxNo)).toFixed(2);
      sfcSuppNet = (sfcSuppNet / parseFloat(this.bookingData.paxNo)).toFixed(2); 
    }

    // Make sure we're getting float, not string
    sfcSuppGross = parseFloat(sfcSuppGross);
    sfcSuppNet = parseFloat(sfcSuppNet);

    // Find sfc mapping and assign fixed price per pax based on the gross value
    // This is for a 'Gross' calculations
    let sfcPricingCntGross = 0; let loopBreakGross = 'no';
    this.sfcPricing.forEach((pricing: any) => {
      // if (loopBreakGross === 'no' && parseFloat(sfcSuppGross) === 0.00) { loopBreakGross = 'yes'; }
      if (loopBreakGross === 'no' && sfcSuppGross === 0.00 || sfcSuppGross < pricing.cover) { loopBreakGross = 'yes'; }
      else if (loopBreakGross === 'no') { sfcPricingCntGross += 1; }
    });
    // This is for a 'Net' calculations
    let sfcPricingCnttNet = 0; let loopBreakNet = 'no';
    this.sfcPricing.forEach((pricing: any) => {
      // if (loopBreakNet === 'no' && parseFloat(sfcSuppNet) === 0.00) { loopBreakNet = 'yes'; }
      if (loopBreakNet === 'no' && sfcSuppNet === 0.00 || sfcSuppNet < pricing.cover) { loopBreakNet = 'yes'; }
      else if (loopBreakNet === 'no') { sfcPricingCnttNet += 1; }
    });

    // Works out what needs to be read from the mapping (translated from Ruby)
    if (sfcPricingCntGross === this.sfcPricing.length) { sfcPricingCntGross = this.sfcPricing.length - 1; }
    if (sfcPricingCnttNet === this.sfcPricing.length) { sfcPricingCnttNet = this.sfcPricing.length - 1; }
    // Set default SFC prices which are displayed to the user
    this.newSfcInfo.sfcBookGrossPrice = this.sfcPricing[sfcPricingCntGross].pricePerPx;
    this.newSfcInfo.sfcBookNetPrice = this.sfcPricing[sfcPricingCnttNet].pricePerPx;
    this.newSfcInfo.sfcGrossPrice = sfcSuppGross; this.newSfcInfo.sfcNetPrice = sfcSuppNet;
    // Additionally, set 'gross' cover as a default
    this.newSfcInfo.costBasedOn = 'net';
  }

  getFlightsOnly(): void {
    this.flightsOnly = this.costingsData.data
      .filter((costing: any) => costing.elementType === 'flight')
      .flatMap((costing: any) => {
        // Find the corresponding supplier
        const supplier = this.suppliersList.find((obj: any) => { return (obj.id?.toString() === costing.supplierID?.toString() || obj.supplierName === costing.supplierName); });
        // Check if the supplier is under SAFI
        if (supplier?.underSafi === 'yes') { return costing.flights; }
        else { return []; }
      });

    let safiGross: any = 0;
    // Adding Gross to the total SAFIgross below
    this.flightsOnly.forEach((flight: any) => {
      if (flight.flightStatus !== 'cancelled' && flight.underSafi === 'yes') {
        safiGross = (parseFloat(safiGross) + parseFloat(flight.grossCost)).toFixed(2);
      }
    });

    // Divide the total SAFI gross & net by the number of passengers
    if (this.bookingData.paxNo > 0) {
      safiGross = (safiGross / parseFloat(this.bookingData.paxNo)).toFixed(2);
    }

    // Make sure we're getting float, not string
    safiGross = parseFloat(safiGross);

    // Find SAFI mapping and assign fixed price per pax based on the gross value
    let safiPricingCntGross = 0; let loopBreakGross = 'no';
    this.safiPricing.forEach((pricing: any) => {
      // if (loopBreakGross === 'no' && parseFloat(safiGross) === 0.00) { loopBreakGross = 'yes'; }
      if (loopBreakGross === 'no' && safiGross === 0.00 || safiGross < pricing.cover) { loopBreakGross = 'yes'; }
      else if (loopBreakGross === 'no') { safiPricingCntGross += 1; }
    });

    // Works out what needs to be read from the mapping (translated from Ruby)
    if (safiPricingCntGross === this.safiPricing.length) { safiPricingCntGross = this.safiPricing.length - 1; }

    this.newSafiInfo.safiBookGrossPrice = this.safiPricing[safiPricingCntGross].pricePerPx;
    this.newSafiInfo.safiGrossPrice = safiGross;
  }

  changeFlightSafi(event: any, flight: any): void {
    if (!event.target.checked) { flight.underSafi = 'no'; }
    else { flight.underSafi = 'yes'; }

    this.getFlightsOnly(); // This will reload SAFI cost and coverage..
  }

  updateSafiCoverage(createSupplement: boolean): void {
    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      flights: this.flightsOnly, token: Session.mySession.get('user').token
    }

    this.pageLoaded = false;
    this.supplierService.updateSafiCoverage(request).then((res: any) => {
      if (res.status === 'OK' && !createSupplement) {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.recalculateBookingsValues().then(() => {
            this.sendMessageToDialog('SAFI coverage has been updated', '', '', ''); // Display success message to the user
          });
        });
      } else if (res.status === 'OK' && createSupplement) {
        this.addNewSupplement(null, 'autoPricing'); // SAFI is updated - not we can finally create our SAFI supplement!
      } else {
        this.sendMessageToDialog('', 'Failed to update SAFI coverage (' + res.status + ')', '', ''); // Display error message to the user
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0845S)', error, request);
    });
  }

  tabChanged(event: any, whereFrom: any): void {
    this.chartSelected = null; // Reset variable to default
    this.pageLoaded = false; // Reset variable to default
    this.graphsView = false; // Reset variable to default
    this.generalView = false; // Reset variable to default
    this.passengerView = false; // Reset variable to default
    this.suppliersView = false; // Reset variable to default
    // this.insuranceView = false; // Reset variable to default
    this.receiptsView = false; // Reset variable to default
    this.paymentsView = false; // Reset variable to default
    this.documentationView = false; // Reset variable to default
    this.notesView = false; // Reset variable to default
    this.historyView = false; // Reset variable to default
    this.expandedElement = false; // Reset variable to default
    this.newPaxRadioValue = 'existing'; // Reset variable to default
    this.resetSelectedPassenger(); // Reset variable to default
    if (event === 'graphsView') {
      this.graphsView = true; // Set the view in UI to the tab as described..
      if (whereFrom === 'main') {
        this.recalculateBookingsValues().then(res => { // Refresh booking values and re-draw the charts..
          this.populateChart();
          this.populatePieChart(this.customerBalanceDue, this.bookingData.totalReceiptedCharged);
          this.populatePieChart2(this.dueToSuppliers, this.bookingData.totalSuppPayments);
          this.pageLoaded = true;
        });
      }
    } else if (event === 'generalView') { // General Info
      this.generalView = true; // Set the view in UI to the tab as described..
      if (whereFrom === 'main') {
        this.recalculateBookingsValues().then(res => { // Refresh booking and supplement values..
          this.pageLoaded = true;
        });
      }
    } else if (event === 'passengerView') { // Passengers
      this.passengerView = true; // Set the view in UI to the tab as described..
      this.createConfirm = true; this.showDupliBox = false; // CRF75 - Reset duplicate checkbox
      if (whereFrom === 'main') {
        this.recalculatePassengers().then(res => { // Refresh passenger values..
          this.pageLoaded = true;
        });
      }
    } else if (event === 'suppliersView') { // Costings
      this.suppliersView = true; // Set the view in UI to the tab as described..
      this.supplementSelect = {}; // Reset selected supplement..
      if (whereFrom === 'main') {
        this.recalculateBookingsValues().then(res => { // Refresh booking and supplement values..
          this.resetSupplmPrices('all');
          this.pageLoaded = true;
        });
      }
      /*} else if (event == 'insuranceView') { // Insurances
        this.insuranceView = true; // Set the view in UI to the tab as described..
        if (whereFrom == 'main') {
          this.recalculateInsurance().then(res => {
            this.pageLoaded = true;
          });
        } */
    } else if (event === 'receiptsView') { // Receipts
      this.receiptsView = true; // Set the view in UI to the tab as described..
      this.newReceipt = { createSupplement: false, paymentMethod: '', creditValue: 0.00, merchantFee: 0.00, totalCharge: 0.00 };
      if (whereFrom === 'topNav' && this.fellohReady) {
        this.reloadFelloh().then(res => { this.pageLoaded = true; });
      }
      else if (whereFrom === 'main') {
        this.recalculateReceipts().then(res => { // Refresh receipt and Felloh values..
          this.deselectAllCostings();
          if (this.fellohReady) {
            this.reloadFelloh().then(() => { this.pageLoaded = true; });
          } else { this.pageLoaded = true; }
        });
      }
    } else if (event === 'paymentsView') { // Payments
      this.paymentsView = true; // Set the view in UI to the tab as described..
      if (whereFrom === 'main') {
        this.recalculatePayments().then(res => { // Refresh payment values..
          this.deselectAllCostings();
          this.pageLoaded = true;
        });
      }
    } else if (event === 'documentationView') { // Documentation - PAGE IS STATIC NO DATA SO FAR IN IT
      this.documentationView = true; // Set the view in UI to the tab as described..
      this.reloadS3documents().then(res => {
        // All of the available documents will be populated here
        this.availableDocs = Documents.myDocuments.getAll(this.selectedBranch, this.meetsATOLcriteria);
        this.pageLoaded = true;
      });
    } else if (event === 'notesView') { // Notes
      this.notesView = true; // Set the view in UI to the tab as described..
      if (whereFrom === 'main') {
        this.recaulculateNotes().then(() => {
          this.pageLoaded = true;
        });
      }
    } else if (event === 'historyView') { // History
      this.historyView = true; // Set the view in UI to the tab as described
      this.recalculateHistory().then(() => {
        this.pageLoaded = true;
      });
    }
  }

  changeGross(event: any, object: any): void {
    object.grossCost = event; // Assign objects value here

    // Calculate the real gross below
    object.realGross = (object.grossCost - object.discount).toFixed(2);
    // Calculate objects commission without the VAT
    object.commission = (object.realGross - object.netCost).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (object.hasOwnProperty('supplementTypeClient')) {
      if (this.bookingData.vatReg === 'yes' && object.supplementTypeClient.indexOf('no VAT') < 0) {
        object.tax = (object.commission * 0.16666667).toFixed(2);
      } else { object.tax = 0; }
    } else {
      if (this.bookingData.vatReg === 'yes' && this.selectedSupplement.supName.indexOf('no VAT') < 0) {
        object.tax = (object.commission * 0.16666667).toFixed(2);
      } else { object.tax = 0; }
    }
    // Calculate objects commission with VAT
    object.commission = (object.realGross - object.netCost - object.tax).toFixed(2);
  }

  changeNet(event: any, object: any): void {
    object.netCost = event; // Assign objects value here
    // Calculate objects commission without the VAT
    object.commission = (object.realGross - object.netCost).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (object.hasOwnProperty('supplementTypeClient')) {
      if (this.bookingData.vatReg === 'yes' && object.supplementTypeClient.indexOf('no VAT') < 0) {
        object.tax = (object.commission * 0.16666667).toFixed(2);
      } else { object.tax = 0; }
    } else {
      if (this.bookingData.vatReg === 'yes' && this.selectedSupplement.supName.indexOf('no VAT') < 0) {
        object.tax = (object.commission * 0.16666667).toFixed(2);
      } else { object.tax = 0; }
    }
    // Calculate objects commission with VAT
    object.commission = (object.realGross - object.netCost - object.tax).toFixed(2);
  }

  changeVAT(event: any, object: any): void {
    object.tax = event; // Assign objects value here
    // Calculate objects commission without the VAT
    object.commission = (object.realGross - object.netCost).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    // if (this.bookingData.vatReg == 'yes') { object.tax = (object.commission * 0.16666667).toFixed(2); }
    // else { object.tax = 0; }
    // Calculate objects commission with VAT
    object.commission = (object.realGross - object.netCost - object.tax).toFixed(2);
  }

  changeDiscount(event: any, object: any): void {
    object.discount = event; // Assign objects value here

    // Calculate the real gross below
    object.realGross = (object.grossCost - object.discount).toFixed(2);
    // Calculate objects commission without the VAT
    object.commission = (object.realGross - object.netCost).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (object.hasOwnProperty('supplementTypeClient')) {
      if (this.bookingData.vatReg === 'yes' && object.supplementTypeClient.indexOf('no VAT') < 0) {
        object.tax = (object.commission * 0.16666667).toFixed(2);
      } else { object.tax = 0; }
    } else {
      if (this.bookingData.vatReg === 'yes' && this.selectedSupplement.supName.indexOf('no VAT') < 0) {
        object.tax = (object.commission * 0.16666667).toFixed(2);
      } else { object.tax = 0; }
    }
    // Calculate objects commission with VAT
    object.commission = (object.realGross - object.netCost - object.tax).toFixed(2);
  }

  getPublicKey(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Create a request variable which will be used to get public variables called to view bookings by customers
      const getRequest = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        bookingReference: this.bookingData.bookingReference, apiCalling: 'getBookingFull', token: Session.mySession.get('user').token
      };

      this.publicService.getPublicKeys(getRequest).then((result: any) => {
        if (result.status === 'OK') {
          this.publicAccess.request = result.publicRequest; // Assign the public access property
          this.publicAccess.key = result.publicKey; // Assign the public access property
          this.publicAccess.wpPassword = result.wpPassword; // Assign WP password property
          this.qrVar.value = this.singsCustomerURL + 'booking/' + this.publicAccess.request + '/' + this.publicAccess.key; // Create a QR code here..
          resolve(''); // Return back to the script
        } else {
          this.createPublicKey(); // Status not OK - let's create a public key for this booking!
          resolve(''); // Return back to the script
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0823S)', error, getRequest);
        resolve(''); // Return back to the script
      });
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '', ''); // Display error message here..
    });
  }

  regeneratePublicKey(): void {
    if (confirm('Are you sure you want to regenerate external link? Current link will not work anymore and the new one will need to be sent to the customer if requested')) {
      // User decided to regenerate a public key for selected booking. We need a JSON object below to to so - all properties are mandatory..
      const request = {
        company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
        bookingReference: this.bookingData.bookingReference, apiCalling: 'getBookingFull', publicRequest: this.publicAccess.request,
        limitUse: 0, token: Session.mySession.get('user').token
      };

      this.publicService.regeneratePublicKey(request).then((result: any) => {
        if (result.status === 'OK') {
          this.publicAccess.key = result.publicKey; // Re-assign the public access property
          this.qrVar.value = this.singsCustomerURL + 'booking/' + this.publicAccess.request + '/' + this.publicAccess.key; // Re-draw the QR code below
          this.sendMessageToDialog('URL link has been regenerated', '', '', ''); // Show success message..
        } else {
          this.sendMessageToDialog('', result.status, '', ''); // Somethig went wrong - display error message to the user
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0824S)', error, request);
      });
    }
  }

  createPublicKey(): void {
    // Create a request varaible which will be used to create a public access variables (view booking outside system)
    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, apiCalling: 'getBookingFull', limitUse: 0, token: Session.mySession.get('user').token
    };

    this.publicService.createPublicKey(request).then((publicOutput: any) => {
      if (publicOutput.status === 'OK') {
        this.getPublicKey().then(res => { }); // The public key has been created - let's call getPublicKey method to assign all the variables!
      } else {
        this.sendMessageToDialog('', publicOutput.status, '', ''); // Something went wrong - display message to the user..
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0825S)', error, request);
    });
  }

  setUserList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userType === 'memberManager' || this.userType === 'memberStaff') {
        const request = { tradeCode: this.bookingData.tradeCode, token: Session.mySession.get('user').token };

        if (Session.mySession.getUserList(this.bookingData.tradeCode).expiryTime === 'EXPIRED') {
          this.userService.getUserList(request).then((users: any) => {
            if (users.status === 'OK') {
              Session.mySession.setUserList(this.bookingData.tradeCode, users.data);
              this.userList = users.data;
            }
            resolve('');
          });
        } else {
          this.userList = Session.mySession.getUserList(this.bookingData.tradeCode).userList; resolve('');
        }
      } else { resolve(''); } // We don't need to re-assign users as admins..
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'Agent list failed to load', '', ''); // Display error message here..
    });
  }

  customerFacingChange(event: any): void {
    // Set both of below values to the event value taken from the checkbox in UI
    Session.mySession.set('customerFacing', event.checked);
    this.customerView = event.checked;
  }

  showMistakesOnOff(event: any): void {
    // Set both of below values to the event value taken from the checkbox in UI
    Session.mySession.set('mistakesOnOff', event);
    this.showMistakes = event;

    // Reset both checkboxes to 'unchecked'
    this.showPaymentsOnly = false; this.showCommOnly = false;

    if (event) {
      this.receiptsData.data = this.receiptsOrgn; this.paymentsData.data = this.paymentsOrgn;
    } else {
      this.receiptsData.data = this.receiptsOrgn.filter((receipt: any) => receipt.receiptCategory !== 'mistake' && receipt.receiptCategory !== 'cancelled');
      this.paymentsData.data = this.paymentsOrgn.filter((payment: any) => payment.paymentCategory !== 'mistake' && payment.paymentCategory !== 'cancelled');
    }
    this.sumPaymentAndRecieptColumns();
  }

  showSpecificPayments(event: any, selectedGroup: any): void {
    this.showMistakesOnOff(false); // Make a reset so all checkboxes are off first

    if (event && selectedGroup === 'showSuppPayOnly') {
      this.paymentsData.data = this.paymentsOrgn.filter((payment: any) => payment.paymentCategory === 'payment');
      this.showPaymentsOnly = true; this.showCommOnly = false;
      this.sumPaymentAndRecieptColumns();
    } else if (event && selectedGroup === 'showCommisisonOnly') {
      this.paymentsData.data = this.paymentsOrgn.filter((payment: any) => payment.paymentCategory === 'commission');
      this.showPaymentsOnly = false; this.showCommOnly = true;
      this.sumPaymentAndRecieptColumns();
    } else {
      this.showMistakesOnOff(false); // This will bring up all payments / receipts..
    }
  }

  sumPaymentAndRecieptColumns(): void {
    this.receiptTotalSum.totalCharge = this.receiptsData.data
    .reduce((sum: any, obj: any) => {
      if (obj.receiptCategory !== 'mistake') {
        return sum + parseFloat(obj.totalCharge || 0);
      }
      return sum;
    }, 0);

    this.receiptTotalSum.creditValue = this.receiptsData.data
      .reduce((sum: any, obj: any) => {
        if (obj.receiptCategory !== 'mistake') {
          return sum + parseFloat(obj.creditValue || 0);
        }
        return sum;
      }, 0);

    this.receiptTotalSum.merchantFee = this.receiptsData.data
      .reduce((sum: any, obj: any) => {
        if (obj.receiptCategory !== 'mistake') {
          return sum + parseFloat(obj.merchantFee || 0);
        }
        return sum;
      }, 0);

    this.paymentTotalSum.paymentAmount = this.paymentsData.data
      .reduce((sum: any, obj: any) => {
        if (obj.paymentCategory !== 'mistake') {
          return sum + parseFloat(obj.paymentAmount || 0);
        }
        return sum;
    }, 0);
  }

  editToggleOnOff(): void {
    if (this.editToggle === true) { this.editToggle = false; } // Make summary non-editable
    else { this.editToggle = true; } // Make summary editable..
  }

  changeFellohAccount(): void {
    this.setFellohCurrenciesUp();
    if (!this.fellohCreate) { this.pageLoaded = false; }
    this.reloadFelloh().then(() => { if (!this.fellohCreate) { this.pageLoaded = true; } })
  }

  setFellohCurrenciesUp(): void {
    this.selectedCurrency = 'GBP'; // As a default, we'll switch it to GBP
    this.fellohCurrencies = this.selectedFellohAccount?.currencies?.split(';');
  }

  reloadBooking(what: any): void {
    this.pageLoaded = false;
    Session.mySession.resetTimersOnBookingValues().then((res: any) => {
      this.tabChanged(what, 'main');
    });
  }

  deselectAllCostings(): void {
    // Method which de-selects all costings (e.g. in Payments and Receipt (TBD))
    this.costingsData.data.forEach((costingElement: any) => { costingElement.costingSelected = false; });
  }

  toNumber(numString: any): number {
    return Number(numString);
  }

  autoCalcBookingDates(): void {
    const request = {
      company: this.bookingData.company, operation: this.bookingData.operation, tradeCode: this.bookingData.tradeCode,
      bookingReference: this.bookingData.bookingReference, token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.bookingService.autoCalcBookingDates(request).then((res: any) => {
      if (res.status === 'OK') {
        this.reloadBooking('generalView');
        this.sendMessageToDialog('Booking values have been updated', '', '', '');
      } else {
        this.sendMessageToDialog('', res.status, '', ''); // Something went wrong - display message to the user..
      }
    });
  }

  goToBooking(bookingReference: any): void {
    AppComponent.myapp.closeBooking('fromScript').then((res: any) => {
      AppComponent.myapp.navigateToBooking(bookingReference, true);
    });
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  unblockNewBooking(): void {
    if (Session.mySession.get('blockNewBooking') != null &&
      Session.mySession.get('blockNewBooking') !== false) {
      Session.mySession.set('blockNewBooking', false);
    }
  }

  filterSelect(): void {
    this.countryFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.countryList.length; i++) {
      const option = this.countryList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.countryFiltered.push(option);
      }
    }
  }

  valiDate(event: any): void {
    if (event.value !== null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // Check if the error comes from Felloh and if we can display it to the user
      // If we can then let's display it rather than give them weird code..
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        failureMessage = error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error.hasOwnProperty('message')) {
        failureMessage = error.error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors.length > 0) {
        failureMessage = error.error.errors[0].message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      }
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a production - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }
}
