import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(private http: HttpClient) { }

  async getNotes(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getNotes?company=' + request.company + '&operation=' + request.operation +
    '&tradeCode=' + request.tradeCode + '&noteType=' + request.noteType + '&reference=' + request.reference + '&token=' + request.token).toPromise();
    return output;
  }

  async createNote(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'createNote', body, { headers }).toPromise();
    return output;
  }

  async editNote(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'editNote', body, { headers }).toPromise();
    return output;
  }
}
