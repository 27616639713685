import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BranchService } from '../../services/branch.service';
import { UserService } from '../../services/user.service';
import { BankingService } from '../../services/banking.service';
import { GlobalConstants } from '../../common/global-constants';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from './../../../environments/environment';
import * as moment from 'moment';
import { AppComponent } from '../../app.component';
import { BookingExternalComponent } from '../booking-external/booking-external.component';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-banking',
  templateUrl: './banking.component.html',
  styleUrls: ['./banking.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class BankingComponent implements OnInit {
  // Boolean deciding whether user has access or not
  userType = '';
  pageLoaded = false;
  summaryView = false;
  detailsView = true;
  balanceView = false;
  showExtReference = false;
  unbalancedOnly = true;

  // List of the table columns which are displayed in .html file
  displayedColumns = ['checkbox', 'date', 'description', 'credit', 'debit', 'matched', 'operations'];
  balanceColumns = ['date', 'description', 'type', 'reconciled', 'credit', 'debit'];
  assoColumns = ['date', 'description', 'type', 'reconciled', 'credit', 'debit'];

  // The branchData from API will be assign to this variable. The MatTableDataSource class
  // is used for to the whole Component to work (+ it has its own built-in functions)
  bankingData: any = new MatTableDataSource<any>();
  balanceData: any = new MatTableDataSource<any>();
  balanceDataFiltered: any = new MatTableDataSource<any>();
  allChartData: any = {};

  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  bankingFromDate: any = '';
  bankingToDate: any = '';
  descriptionFilter: any = '';
  approvedFilter: any = '';

  // Custom mat expansion variables
  expansionSearch = true;
  expansionList = true;

  // Pagination variables
  pageNo = 0; limit = 100; offset = 0;

  // Matching variables
  selectedRawRow: any = {};
  selectedBankRow: any = {};
  systemMatched: any = {};
  prefixOn = true; // Display pefix on the top-right corner or not
  maxBkLength = 7; // The max length user can type in the book ref field
  bookRefPrefix = ''; // Prefix set by us
  bookRefSearch = ''; // Free type string
  bookingRefExternal = ''; // This will go into child [booking-external]

  // Access varaibles
  companies: any = [];
  branches: any = []; // Holds data of ALL branches from the system
  filteredBranches: any = []; // Holds data filtered by the company (TTNG/GTG etc..)
  filterInBranches: any = []; // Holds data filtered by user input ('worldchoice..')
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  selectedCompany: any = ''; // Currently selected company - used only by admins..
  selectedOperation: any = ''; // Currently selected operation - used only by admins..
  selectedBranch: any = ''; // Currently selected branch - not used in single-branch users
  reconcileAll: any = 'no'; // Sel-all checkbox for reconciliation

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('bookingExternalBox') bookingExternalBox!: TemplateRef<any>;
  @ViewChild('commentDialog') commentDialog!: TemplateRef<any>;
  @ViewChild('synchDialog') synchDialog!: TemplateRef<any>;
  @ViewChild('matchingDialog') matchingDialog!: TemplateRef<any>;
  @ViewChild('rawStmntDialog') rawStmntDialog!: TemplateRef<any>;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  public pieChartMatched: Partial<any> = {
    series: [],
    chart: {
      width: '100%',
      type: 'donut',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    labels: ['Auto Matched', 'Manually Matched', 'Not Required', 'Unmatched'],
    colors: ['#4D5FD1', '#ce983a', '#6992c9', '#a6a6a6'],
    stroke: {
      show: true,
      width: 2,
      colors: ['#FFFFFF'] // White stroke for separation between segments
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 0.9,
        donut: {
          size: '65%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '13px', // Set font size to 1.2em
              fontWeight: 600,
              fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
              offsetY: -10,
              color: '#333'
            },
            value: {
              show: true,
              fontSize: '13px', // Set font size to 1.2em
              fontWeight: 'bold',
              fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
              offsetY: 10,
              color: '#333',
              formatter: (val: number) => val.toLocaleString()
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '13px', // Set font size to 1.2em
              fontWeight: 'bold',
              fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
              color: '#333',
              formatter: (w: any) => w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0).toLocaleString()
            }
          }
        }
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '11px',
      fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      labels: {
        colors: ['#333'], // Set label color to dark gray
        useSeriesColors: false // Use specified color instead of series color
      },
      markers: {
        width: 12,
        height: 12,
        radius: 2
      },
      itemMargin: {
        horizontal: 8,
        vertical: 4
      }
    },
    dataLabels: {
      enabled: false,
      formatter: (val: number) => `${val.toFixed(1)}%`,
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        opacity: 0.5
      },
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
        fontWeight: 'bold',
        colors: ['#f9f9f9'] // White color for better readability on dark colors
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.4,
        opacityFrom: 0.9,
        opacityTo: 1,
        stops: [0, 100]
      }
    }
  };

  public pieChartCategories: Partial<any> = {
    series: [],
    chart: {
      width: '100%',
      type: 'donut',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    labels: [],
    colors: [
      '#4D5FD1', '#CE983A', '#305687', '#A66B2A', '#6992C9',
      '#D14285', '#3ECF8E', '#E56E3A', '#2E3B5A', '#6AB65E',
      '#C44430', '#5F3D9D', '#F2B134', '#7C77A5', '#26A8E0',
      '#EB3E50', '#F28A2F', '#3BCA63', '#4C97EB', '#D1AF3A'
    ],
    stroke: {
      show: true,
      width: 2,
      colors: ['#FFFFFF']
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 0.9,
        donut: {
          size: '65%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '13px', // Set font size to 1.2em
              fontWeight: 600,
              fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
              offsetY: -10,
              color: '#333'
            },
            value: {
              show: true,
              fontSize: '13px', // Set font size to 1.2em
              fontWeight: 600,
              fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
              offsetY: -10,
              color: '#333',
              formatter: (val: number) => val.toLocaleString() // Format with commas if needed
            },
            total: {
              show: true,
              fontSize: '13px', // Set font size to 1.2em
              fontWeight: 600,
              fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
              offsetY: -10,
              color: '#333',
              formatter: (w: any) => w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0).toLocaleString()
            }
          }
        }
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '11px',
      fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      labels: {
        colors: ['#333'],
      },
      markers: {
        width: 12,
        height: 12,
        radius: 2,
      },
      itemMargin: {
        horizontal: 8,
        vertical: 4
      }
    },
    dataLabels: {
      enabled: false,
      formatter: (val: number) => `${val.toFixed(1)}%`,
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        opacity: 0.5,
      },
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
        fontWeight: 'bold',
        colors: ['#f9f9f9']
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.4,
        opacityFrom: 0.9,
        opacityTo: 1,
        stops: [0, 100]
      }
    }
  };
  
  public radialBarReconciled: Partial<any> = {
    series: [],
    chart: {
      width: 300, // Fixed width for the radial chart
      height: 300, // Fixed height for the radial chart
      type: 'radialBar',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      offsetY: 0, // Move the entire chart upwards to reduce bottom whitespace
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    labels: [],
    colors: ['#4D5FD1'],
    stroke: {
      show: false,
    },
    sparkline: {
      enabled: true
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -5, // Move the percentage text up slightly to center it better
            fontSize: '14px', // Set font size to 1.2em
            fontWeight: 600,
            fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
            color: '#333'
          }
        },
        hollow: {
          size: '60%' // Adjust the size if necessary to reduce the radial arc size
        }
      }
    },
    legend: {
      show: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    },
  };

  public lineBarCredits: Partial<any> = {
    series: [
      {
        name: 'Credits',
        data: []
      }
    ],
    chart: {
      id: 'chartCredits',
      group: 'sync-charts',
      type: 'line',
      height: 200,
      width: '100%', // Ensure chart width matches the container
      toolbar: {
        autoSelected: 'pan',
        show: false
      },
      zoom: {
        enabled: false
      },
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 150
            }
          }
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    },
    stroke: {
      curve: "smooth"
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '11px', // Set font size for x-axis
          fontFamily: 'Montserrat, sans-serif', // Set font family for x-axis
          fontWeight: 500, // Optional: set font weight if needed
          colors: ['#333'] // Set label color for better contrast
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (val: any) => `£${val.toFixed(2)}`, // Format Y-axis labels with £ symbol
        style: {
          fontSize: '11px', // Set font size for x-axis
          fontFamily: 'Montserrat, sans-serif', // Set font family for x-axis
          fontWeight: 500, // Optional: set font weight if needed
          colors: ['#333'] // Set label color for better contrast
        }
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val: any) => `£${val.toFixed(2)}` // Add currency sign and format to 2 decimal places
      },
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val: any) => `£${val.toFixed(2)}`, // Display currency in data labels if enabled
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      }
    }
  };

  public lineBarDebits: Partial<any> = {
    series: [
      {
        name: 'Debits',
        data: []
      }
    ],
    chart: {
      id: 'chartDebits',
      group: 'sync-charts',
      type: 'line',
      height: 200,
      width: '100%', // Ensure chart width matches the container
      toolbar: {
        autoSelected: 'pan',
        show: false
      },
      zoom: {
        enabled: false
      },
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 150
            }
          }
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    },
    stroke: {
      curve: "smooth"
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '11px', // Set font size for x-axis
          fontFamily: 'Montserrat, sans-serif', // Set font family for x-axis
          fontWeight: 500, // Optional: set font weight if needed
          colors: ['#333'] // Set label color for better contrast
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (val: any) => `£${val.toFixed(2)}`, // Add currency sign and format Y-axis labels
        style: {
          fontSize: '11px', // Set font size for x-axis
          fontFamily: 'Montserrat, sans-serif', // Set font family for x-axis
          fontWeight: 500, // Optional: set font weight if needed
          colors: ['#333'] // Set label color for better contrast
        }
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val: any) => `£${val.toFixed(2)}` // Add currency sign and format to 2 decimal places
      },
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val: any) => `£${val.toFixed(2)}`, // Display currency in data labels if enabled
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      }
    }
  };

  public lineBarDiff: Partial<any> = {
    series: [
      {
        name: 'Difference (Credits - Debits)',
        data: []
      }
    ],
    chart: {
      id: 'chartDiffs',
      group: 'sync-charts',
      type: 'line',
      height: 200,
      width: '100%', // Ensure chart width matches the container
      toolbar: {
        autoSelected: 'pan',
        show: false
      },
      zoom: {
        enabled: false
      },
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 150
            }
          }
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    },
    yaxis: {
      labels: {
        formatter: (val: any) => `£${val.toFixed(2)}`, // Add currency sign and format Y-axis labels
        style: {
          fontSize: '11px', // Set font size for x-axis
          fontFamily: 'Montserrat, sans-serif', // Set font family for x-axis
          fontWeight: 500, // Optional: set font weight if needed
          colors: ['#333'] // Set label color for better contrast
        }
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '11px', // Set font size for x-axis
          fontFamily: 'Montserrat, sans-serif', // Set font family for x-axis
          fontWeight: 500, // Optional: set font weight if needed
          colors: ['#333'] // Set label color for better contrast
        }
      }
    },
    stroke: {
      curve: "smooth"
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val: any) => `£${val.toFixed(2)}` // Add currency sign and format to 2 decimal places
      },
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val: any) => `£${val.toFixed(2)}`, // Display currency in data labels if enabled
      style: {
        fontSize: '12px', // Set font size to 1.2em
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif', // Use Montserrat font
      }
    }
  };
  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(private router: Router, private userService: UserService,
              private branchService: BranchService, public dialog: MatDialog,
              private bankingService: BankingService) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // Usual stuff.. if session storage is empty or undefined then redirect to home
      // Otherwise loadPage()
      this.router.navigate(['/']);
    } else {
      this.userType = Session.mySession.getUser().userType;
      this.bookRefPrefix = Session.mySession.getUser().bookRefStr;
      this.showExtReference = AppComponent.myapp.showExternalRef;
      this.setStartingDates(); // Call funciton which sets fromDate <-> toDate values..
      this.loadPage();
    }
  }

  loadPage(): void {
    this.companies = this.constants.getCompanies(Session.mySession.getUser()); // Get list based on user type
    this.selectedCompany = Session.mySession.getUser().company; // Selected company by default - needed for later API calls..
    this.selectedBranch = Session.mySession.getUser().tradeCode; // Selected trade code - used by everyone really (can change under certain circumstances)

    if (Session.mySession.getUsersGroup().length > 0) {
      // Logged in user is in the group and branch list already exists within the session variable..
      this.branches = Session.mySession.getUsersGroup();
      // Check if the branch has a fellohConfig array and if at least one object in the array has bankingPull set to 'yes'
      this.filteredBranches = this.branches.filter((branch: any) => {
        return branch.tradeCode !== 'Q0000' && branch.fellohConfig && branch.fellohConfig.some((config: any) => config.bankingPull === 'yes');
      });

      this.filterInBranches = this.filteredBranches; // It needs to be the same list
      // We want to filter branches based on user company in start-up
      const event = { value: this.selectedCompany };
      this.filterBranches(event);
      this.loadAllStatements('seq'); // Finally - load bank statement data
    } else if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
      this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
        if (branches.status === 'OK') {
          // Assign sorted to global variable and session varaible (for later use) and call loadBookings()
          const sorted = branches.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1);
          this.branches = sorted; Session.mySession.setBranchList(sorted);
          // We want to filter branches based on user company in start-up
          const event = { value: this.selectedCompany };
          this.filterBranches(event);
          this.loadAllStatements('seq'); // Finally - load bank statement data
        } else {
          // Status was not OK meaning something went wrong with it.. display it to the user
          this.sendMessageToDialog('', branches.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2301S)', error, Session.mySession.getUser());
      });
    } else {
      this.branches = Session.mySession.getBranchList().branchList; // Get branch list from the session varaible - no need to call API
      // We want to filter branches based on user company in start-up
      const event = { value: this.selectedCompany };
      this.filterBranches(event);
      this.loadAllStatements('seq'); // Finally - load bank statement data
    }
  }

  loadAllStatements(stmntType: any): void {
    this.loadBankStmnt(stmntType).then((res: any) => {
      this.loadBalanceSheet().then((out: any) => { });
    });
  }

  loadBankStmnt(stmntType: any): Promise<any> {
    return new Promise((resolve, reject) => {

      if (this.selectedBranch) {
        const request = {
          company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
          startDate: this.bankingFromDate, endDate: this.bankingToDate, approvedFilter: this.approvedFilter,
          descriptionFilter: this.descriptionFilter, stmntType, limit: this.limit, offset: this.offset,
          token: Session.mySession.get('user').token
        };

        this.pageLoaded = false;
        this.bankingService.getBankStmntLines(request).then((output: any) => {
          if (stmntType === 'csv' || stmntType === 'xlsx') {
            // Output BLOB needs to be transformed into an excel application file
            const data = new Blob([output], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            // Call this function which opens browser's 'Save As..' window
            saveAs(data, `bankingReconciliation${this.bankingFromDate}-to-${this.bankingToDate}-${this.selectedBranch}.${stmntType}`);
            this.pageLoaded = true;
            resolve('OK');
          } else if (output.status === 'OK') {
            output.bankStmntLines.forEach((element: any) => { element.detailRow = true; });
            // Status OK meaning everything went good - assign data to data variable
            this.bankingData.data = output.bankStmntLines;
            this.reconcileAll = 'no';
            this.pageLoaded = true;
            resolve('OK');
          } else { // Something went wrong. Display error message to the user
            this.bankingData.data = [];
            this.sendMessageToDialog('', output.status, '', '');
            resolve('ERROR');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2302S)', error, request);
          resolve('ERROR');
        });
      } else { this.pageLoaded = true; resolve('OK'); } // Make sure to finish loading..

    });
  }

  loadBalanceSheet(): Promise<any> {
    return new Promise((resolve, reject) => {

      if (this.selectedBranch) {
        const request = {
          company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
          startDate: this.bankingFromDate, endDate: this.bankingToDate, token: Session.mySession.get('user').token
        };

        this.pageLoaded = false;
        this.bankingService.getBankBalanceSheet(request).then((output: any) => {
          if (output.status === 'OK') {
            output.bankStmntLines.forEach((element: any) => { element.detailRow = true; });
            // Status OK meaning everything went good - assign data to data variable
            this.balanceData.data = output.bankStmntLines;
            this.allChartData = output;
            this.unbalancedOnlySwitch(true);
            this.populateCharts(output);
            this.pageLoaded = true;
            resolve('OK');
          } else { // Something went wrong. Display error message to the user
            this.balanceData.data = [];
            this.sendMessageToDialog('', output.status, '', '');
            resolve('ERROR');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2309S)', error, request);
          resolve('ERROR');
        });
      } else { this.pageLoaded = true; resolve('OK'); } // Make sure to finish loading..

    });
  }

  populateCharts(stats: any): void {
    // Reset pie chart and radial bar data
    this.pieChartMatched.series = [];
    this.pieChartCategories.series = [];
    this.pieChartCategories.labels = [];
    this.radialBarReconciled.series = [];
    
    // Reset line chart data correctly by clearing the `data` arrays within `series`
    this.lineBarCredits.series[0].data = [];
    this.lineBarDebits.series[0].data = [];
    this.lineBarDiff.series[0].data = []; 

    const pieDataMatch = [stats.totalAutoMatch, stats.totalManualMatch, stats.totalNotReqMatch, stats.totalUnmatched];

    const pieDataCategory = []; const pieLabelCategory = [];
    for (const [category, count] of Object.entries(stats.categoryCounts)) {
      pieDataCategory.push(count);
      pieLabelCategory.push(category);
    }

    const radialBarData = ((stats.totalReconciled / stats.totalSplits) * 100).toFixed(2);

    // Step 1: Group transactions by posting_date and calculate totals
    const transactionsByDate: { [key: string]: { credit: number; debit: number } } = {};

    stats.bankStmntLines.forEach((stmntLine: any) => {
      const date = stmntLine.raw.posting_date;

      if (!transactionsByDate[date]) {
        transactionsByDate[date] = { credit: 0, debit: 0 };
      }

      transactionsByDate[date].credit += Number(stmntLine.raw.credit);
      transactionsByDate[date].debit += Number(stmntLine.raw.debit);
    });

    // Step 2: Determine the full date range from the earliest to the latest date
    const dates = Object.keys(transactionsByDate).sort();
    const startDate = moment(dates[0]);
    const endDate = moment(dates[dates.length - 1]);

    // Step 3: Prepare data arrays for credits, debits, and difference
    const lineCredits: any[] = []; const lineDebits: any[] = []; const lineDiff: any[] = [];

    let currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate)) {
      const dateStr = currentDate.format('YYYY-MM-DD');
  
      const credit = transactionsByDate[dateStr]?.credit || 0;
      const debit = transactionsByDate[dateStr]?.debit || 0;
  
      // Push data for each day in the range, using 0 if there's no data for that day
      lineCredits.push({ x: dateStr, y: credit });
      lineDebits.push({ x: dateStr, y: debit });
      lineDiff.push({ x: dateStr, y: credit - debit });
  
      // Move to the next day
      currentDate.add(1, 'day');
    }

    // Assign data to appropriate charts
    this.pieChartMatched.series = pieDataMatch;
    this.pieChartCategories.series = pieDataCategory;
    this.pieChartCategories.labels = pieLabelCategory;
    this.radialBarReconciled.series = [Number(radialBarData)];

    // Populate series data for line charts correctly
    this.lineBarCredits.series = [{ name: 'Credits', data: lineCredits }];
    this.lineBarDebits.series = [{ name: 'Debits', data: lineDebits }];
    this.lineBarDiff.series = [{ name: 'Difference (Credits - Debits)', data: lineDiff }];
  }

  updateSelectedRows(): void {
    // Construct a variable where we'll dump all 'to update' bank statement rows
    const filteredValues = this.bankingData.data.filter((element: any) => element.update === true);
    const toUpdate = filteredValues.map((element: any) => ({ ...element })); // Remove two-way binding here

    // We need to remove unwanted properties first
    toUpdate.forEach((element: any) => {
      delete element.detailRow; delete element.update; delete element.archive; delete element.seq; delete element.matchFinal;
    });
    // The actual request variable is here - we're dumping array in the property
    const request = {
      company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
      dataToUpdate: toUpdate, token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.bankingService.updateBankStmntLine(request).then((res: any) => {
      if (res.status !== 'OK') { this.sendMessageToDialog('', res.status, '', ''); }
      this.loadAllStatements('seq'); // The status does not matter here - we'll reload all payments anyway..
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2303S)', error, request);
    });
  }

  pullRawDataFromFelloh(form: NgForm): void {
    // Create two dates - requested one and today's date
    const today = new Date(); today.setHours(0, 0, 0, 0);
    let dateIn = form.value.pullDate;

    if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
      !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
      this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
    } else {
      // Convert any of the .i (moment) into a Ruby-friendly date below
      dateIn = this.constants.convertDateMoment(dateIn);

      if (new Date(dateIn) >= today) {
        this.sendMessageToDialog('', 'You can only request data from previous days', '', '');
      } else {
        const request = {
          company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
          pullDate: dateIn, token: Session.mySession.get('user').token
        };

        this.pageLoaded = false;
        this.bankingService.pullRawFromFelloh(request).then((res: any) => {
          if (res.status === 'OK') {
            this.bankingToDate = dateIn; this.bankingFromDate = dateIn;
            this.pageNo = 0; this.offset = 0; // Reset the page number
            this.loadAllStatements('seq'); // Once the dates are set above we'll want to reload the page to see results
            this.loadBalanceSheet(); // Load Balance Sheet
          } else {
            this.sendMessageToDialog('', res.status, '', '');
          }
          this.pageLoaded = true;
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2304S)', error, request);
        });
      }
    }
  }

  openMatchWindow(bankStmntRow: any): void {
    const request = {
      company: bankStmntRow.company, operation: bankStmntRow.operation,
      tradeCode: bankStmntRow.tradeCode, id: bankStmntRow.id, reCalcCategory: 'yes',
      token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.bankingService.showPossibleMatches(request).then((res: any) => {
      if (res.status === 'OK') {
        this.pageLoaded = true; // Remove spinning circle first
        this.selectedBankRow = { ...bankStmntRow }; // Remove two-way binding
        this.systemMatched = res; // Re-assign system's matches which will be displayed in the dialog
        this.dialog.open(this.matchingDialog, { panelClass: 'matchingDialog', disableClose: false, autoFocus: false });
      } else {
        this.selectedBankRow = {}; // Reset selected row - we don't want to display wrong details..
        this.systemMatched = {}; // Reset system's matches - we don't want to show incorrect matches..
        this.sendMessageToDialog('', res.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2305S)', error, request);
    });
  }

  manMatchBankStmntLine(chosenReceipt: any, chosenPayment: any, bookingReference: any, extBookingSource: any): void {
    const request = {
      company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
      stmntID: this.selectedBankRow.id, bookingReference,
      paymentCount: chosenPayment?.paymentCount, receiptCount: chosenReceipt?.receiptCount,
      token: Session.mySession.get('user').token
    };

    // We are finding out the matchStatus below. There are 3 options in which this can go (it's the same at the back end)
    // To find out what status there is, we are liiking at autoMatchesUI - if booking exists then it's either auto / autoM
    const autoMatches = this.selectedBankRow.autoMatchesUI;
    let matchStatusBankRec: any;

    if (autoMatches !== undefined && autoMatches !== null) {
      matchStatusBankRec = autoMatches !== null && autoMatches.some((match: any) => 
      match["bookingRef"] === bookingReference) ? (autoMatches.length === 1 ? 'auto' : 'autoM') : 'man';
    } else {
      matchStatusBankRec = 'man';
    }

    this.pageLoaded = false;
    this.bankingService.manMatchBankStmntLine(request).then((res: any) => {
      if (res.status === 'OK') {
        // We're upating the bankStmnts row, so it displays appropiate booking reference (with count references)
        const newFinalMatch = { bookingRef: bookingReference, paymentCount: chosenPayment?.paymentCount, receiptCount: chosenReceipt?.receiptCount, extBookingSource };
        const bankingRowUpdate = this.bankingData.data.find((obj: any) => obj.id === this.selectedBankRow.id);
        // We're also updating matchStatus after successful linking [based on what's been set above]
        bankingRowUpdate.matchFinalUI = newFinalMatch; bankingRowUpdate.matchStatus = matchStatusBankRec;
        // In order to update our mat-table data, we need to artificially re-construct our table data
        const arrayToCopy = [...this.bankingData.data];
        this.bankingData.data = arrayToCopy;
        this.loadBalanceSheet().then((res: any) => { this.pageLoaded = true; });
      } else {
        this.sendMessageToDialog('', res.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2306S)', error, request);
    });
  }

  unlinkMatchStmnt(stmntLine: any): void {
    const request = {
      company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
      stmntID: stmntLine.id, bookingReference: stmntLine.matchFinalUI.bookingRef,
      paymentCount: stmntLine.matchFinalUI.paymentCount, receiptCount: stmntLine.matchFinalUI.receiptCount,
      token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.bankingService.unlinkBankStmntLine(request).then((res: any) => {
      if (res.status === 'OK') {
        // 'Remove' artificially matchFinal - saves us reloading data
        stmntLine.matchFinal = ''; stmntLine.matchFinalUI = null;
        stmntLine.matchStatus = 'TBD'; // Additionally, we want to make sure the row has a 'TBD' matchStatus
        this.loadBalanceSheet().then((res: any) => { this.pageLoaded = true; });
      } else {
        this.sendMessageToDialog('', res.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2308S)', error, request);
    });
  }

  public selectManualMatch(chosenMatch: any): void {
    this.dialog.closeAll(); // Make sure we're closing all dialogs - in case we're matching 'from child component'
    if (chosenMatch.creditValue !== undefined) {
      this.manMatchBankStmntLine(chosenMatch, null, chosenMatch.bookingReference, chosenMatch.extBookingSource);
    } else {
      this.manMatchBankStmntLine(null, chosenMatch, chosenMatch.bookingReference, chosenMatch.extBookingSource);
    }
  }

  showBankRawDetails(bankStmntRow: any): void {
    const request = {
      company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
      stmntType: 'raw', rawID: bankStmntRow.rawID, token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.bankingService.getBankStmntLines(request).then((output: any) => {
      if (output.status === 'OK') {
        this.pageLoaded = true;
        this.selectedRawRow = output.bankStmntLines[0];
        this.dialog.open(this.rawStmntDialog, { panelClass: 'matchingDialog', disableClose: false, autoFocus: false });
      } else {
        this.selectedRawRow = {}; // Reset selected raw row - we don't want to display 'wrong' one
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2307S)', error, request);
    });
  }

  filterSelectedStmntID(bankStmntRow: any): void {
    this.descriptionFilter = bankStmntRow.description;
    this.loadBankStmnt('seq');
  }

  openCommentPopup(bankStmntRow: any): void {
    this.selectedBankRow = { ...bankStmntRow }; // Remove two-way binding
    this.openSelectedDialog(this.commentDialog, 'pullRawDialog'); // Open comment section - we'll take it from there
  }

  commentSelectedStmntID(bankingRow: any, form: any, bookingReference: any): void {
    // Construct an array containing hash, which will be currently selected row
    const toUpdate = {... bankingRow };

    // We need to remove unwanted properties first
    delete toUpdate.detailRow; delete toUpdate.update;
    delete toUpdate.archive; delete toUpdate.seq;

    // Assign our comment to the object below
    if (bookingReference) { toUpdate.matchFinal = bookingReference; toUpdate.matchStatus = 'notReqd'; }
    else if (form && form.value.comment.trim() !== '') { toUpdate.matchFinal = form.value.comment; toUpdate.matchStatus = 'notReqd'; }
    // This ought to prove useful if empty comment was submitted OR 'clear' button was pressed
    else { toUpdate.matchFinal = null; toUpdate.approved = 'no'; toUpdate.matchStatus = 'TBD'; }

    // The actual request variable is here - we're dumping array in the property
    const request = {
      company: this.selectedCompany, operation: this.selectedOperation, tradeCode: this.selectedBranch,
      dataToUpdate: [toUpdate], token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.bankingService.updateBankStmntLine(request).then((res: any) => {
      if (res.status !== 'OK') { this.sendMessageToDialog('', res.status, '', ''); }
      // Append the comment (or its lack) to our object at the end
      // Additionally, append matchStatus to it - this is needed due to two way binding not exisitng via dialog
      const bankingRowUpdate = this.bankingData.data.find((obj: any) => obj.id === bankingRow.id);
      bankingRowUpdate.matchFinal = toUpdate.matchFinal; bankingRowUpdate.matchStatus = toUpdate.matchStatus;
      // In order to update our mat-table data, we need to artificially re-construct our table data
      const arrayToCopy = [...this.bankingData.data]; this.bankingData.data = arrayToCopy;
      this.loadBalanceSheet().then((res: any) => { this.pageLoaded = true; });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2303S)', error, request);
    });
  }

  duplicateSelectedRow(bankStmntRow: any): void {
    if (confirm('Are you sure you want to dupliate this row?\nIt\'s not possible to remove it afterwards')) {
      // const toDuplicate = { ...bankStmntRow }; // Create non-binding copy

      const toDuplicate = {
        company: bankStmntRow.company, operation: bankStmntRow.operation, tradeCode: bankStmntRow.tradeCode,
        id: bankStmntRow.id, override: 'yes', token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      this.bankingService.duplicateSelectedRow(toDuplicate).then((res: any) => {
        if (res.status !== 'OK') { this.sendMessageToDialog('', res.status, '', ''); }
        this.loadAllStatements('seq'); // The status does not matter here - we'll reload all payments anyway..
      });
    }
  }

  changeApprovedStatus(bankingRow: any, checkState: any): void {
    if (Array.isArray(bankingRow)) {
      // A 'select all' checkbox was clicked - we're changing status for all visible rows
      this.reconcileAll = checkState;
      bankingRow.forEach((element) => {
        element.approved = checkState;
        element.update = true;
      });
    } else {
      // We simply assign yes/no to a new property (which will be removed later..)
      bankingRow.approved = checkState;
      bankingRow.update = true;
    }
  }

  changeUpdateStatus(bankingRow: any, updateState: any): void {
    if (Array.isArray(bankingRow)) {
      // A 'select all' checkbox was clicked - we're changing status for all visible rows
      bankingRow.forEach((element) => {
        element.update = updateState;
      });
    } else {
      // We simply assign true/false to a new property (which will be removed later..)
      bankingRow.update = updateState;
    }
  }

  changeDate(dateType: any, date: any): void {
    // Translate for Felloh-friendly date and reload Felloh data
    if (dateType === 'fromDate') {
      if (date.value != null) {
        this.bankingFromDate = this.constants.convertDateMoment(date.value);
      }

      if (this.bankingToDate._i !== undefined) {
        this.bankingToDate = this.constants.convertDateMoment(this.bankingToDate);
      }
    } else if (dateType === 'toDate') {
      if (date.value != null) {
        this.bankingToDate = this.constants.convertDateMoment(date.value);

        if (this.bankingFromDate._i !== undefined) {
          this.bankingFromDate = this.constants.convertDateMoment(this.bankingFromDate);
        }
      }
    }

    // Make sure none of these two are null (non-date value entered in the field)
    if (this.bankingToDate != null && this.bankingFromDate != null) {
      // Check if the date is in the right format here
      if (moment(this.bankingFromDate, 'YYYY-MM-DD', true).isValid() && moment(this.bankingToDate, 'YYYY-MM-DD', true).isValid()) {
        this.loadAllStatements('seq'); // Reload bank statement
      } else {
        this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
        this.setStartingDates();
      }
    } else {
      this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
      this.setStartingDates();
    }
  }

  setStartingDates(): void {
    const date: any = new Date(); // Get current date here
    this.bankingToDate = this.constants.convertDateNotMoment(date); // Convert the date so Ruby accepts it
    date.setDate(date.getDate() - 7); // Get last week's date
    this.bankingFromDate = this.constants.convertDateNotMoment(date); // Convert the date so Ruby accepts it
  }

  clearDescriptionFilter(): void {
    this.descriptionFilter = '';
  }

  setStartingPagination(): void {
    // Reset pagination variables below
    this.pageNo = 0; this.offset = 0; this.limit = 100;
  }

  changePagination(direction: any): void {
    // Add or remove page number below
    if (direction === 'back') { this.pageNo--; }
    else if (direction === 'forward') { this.pageNo++; }
    // Work out the offset below - simple!
    this.offset = this.pageNo * this.limit;
    this.loadBankStmnt('seq');
  }

  searchForMatchManually(): void {
    this.bookRefSearch = this.bookRefSearch.trim(); // Remove whitespaces
    const stringLength = this.bookRefSearch.length;
    let bookNo = ''; // To be generated as booking reference
    let optionView = 0; // Based on debit / credit

    // Work out whether we should open Receipts or Credits..
    if (this.selectedBankRow.debit !== null && Number(this.selectedBankRow.debit) !== 0) { optionView = 3; }
    else if (this.selectedBankRow.credit !== null && Number(this.selectedBankRow.credit) !== 0) { optionView = 2; }

    // Check if user entered only numbers and if the reference is 7 characters or less
    if (this.prefixOn && this.bookRefSearch.match(/^[0-9]+$/) !== null && this.bookRefSearch.length < 8) {

      for (let i = stringLength; i < 7; i++) { bookNo = '0' + bookNo; } // Add any missing leading zeroes to the booking reference
      bookNo = bookNo + this.bookRefSearch; // Add user input to missing zeroes

      this.openBookingNewWindow(`${this.bookRefPrefix}-${bookNo}`, optionView, null, 'no', true);
    } else if (!this.prefixOn && this.bookRefSearch.length < 26) {
      this.openBookingNewWindow(this.bookRefSearch, optionView, null, 'no', true);
    } else {
      this.sendMessageToDialog('', 'Requested booking reference is in the wrong format', '', '');
    }
  }

  openBookingNewWindow(bookingReference: any, bookSelOptionView: any, payRecCount: any, highlitRow: any, canMatchBanking: any): void {
    this.bookingRefExternal = bookingReference;
    this.dialog.open(this.bookingExternalBox, { panelClass: 'bookingExternalBox', disableClose: false, autoFocus: false });

    // We need to reset session timers (booking) - otherwise we may see possible Links which are out of date
    Session.mySession.resetTimersOnBookingValues();
    // Right after we've opened the dialog, we'll force browser to 'wait' 0.5 seconds
    setTimeout(() => {
      // Display either receipts or payments as a default (bookSelOptionView)
      BookingExternalComponent.myExternal.bookSelOptionView = bookSelOptionView;

      // We also open appropiate options for receipts / payments..
      if (bookSelOptionView === 2) { BookingExternalComponent.myExternal.viewFromMatch = 'receipts'; }
      else if (bookSelOptionView === 3) { BookingExternalComponent.myExternal.viewFromMatch = 'payments'; }

      // Wait up to 30 seconds for the whole booking to load
      this.checkIfExtBookingLoaded(30).then((res: any) => {

        // Disable matching in case Sings staff opens booking from different branch
        if (BookingExternalComponent.myExternal.extBookingData.tradeCode !== this.selectedBranch) {
          BookingExternalComponent.myExternal.matchCodeMismatch = true;
        }
        // Set matching possibility based on the provided method argument
        BookingExternalComponent.myExternal.canMatchBanking = canMatchBanking;

        // Once loaded - we'll highlight the receipt / payment Sings is suggesting
        if (res && highlitRow === 'yes' && bookSelOptionView === 2) {
          BookingExternalComponent.myExternal.receiptsData.data.find((obj: any) => obj.receiptCount === payRecCount).highlight = true;
        } else if (res && highlitRow === 'yes' && bookSelOptionView === 3) {
          BookingExternalComponent.myExternal.paymentsData.data.find((obj: any) => obj.paymentCount === payRecCount).highlight = true;
        }
      });
    }, 100); // 500 milliseconds = 0.5 seconds
  }

  async checkIfExtBookingLoaded(maxSeconds: any): Promise<boolean> {
    let timeoutCounter = 0;

    return new Promise<boolean>((resolve) => {
      const checkCondition = () => {
        if (BookingExternalComponent.myExternal.pageLoaded) {
          resolve(true);
        } else {
          timeoutCounter++;

          if (timeoutCounter >= maxSeconds) {
            resolve(false); // Condition not met within the timeout
          } else {
            setTimeout(checkCondition, 1000); // Check the condition again after 1 second
          }
        }
      };

      checkCondition();
    });
  }

  filterBranches(event: any): void {
    // Depending on the selected company, show only branches within that company (SINGS STAFF ONLY)
    // const allBranch = { tradeCode: 'Q0000', fullName: 'All Branches' };
    if (event.value === 'ttng') {
      this.filteredBranches = this.branches.filter((branch: any) => branch.membershipType === 'worldchoicePlus' || branch.membershipType === 'worldchoice');
      // this.filteredBranches.unshift(allBranch); // Put 'All' branch in the first position
      this.selectedOperation = 'retail'; // Change local sel. operation
      this.bookRefPrefix = 'ttng'; // Change prefix so we can manually match as sings admin / staff
    } else if (event.value === 'gtg') {
      this.filteredBranches = this.branches.filter((branch: any) => branch.membershipType === 'globalTravel');
      // this.filteredBranches.unshift(allBranch); // Put 'All' branch in the first position
      this.selectedOperation = 'member'; // Change local sel. operation
      this.bookRefPrefix = 'GTGS'; // Change prefix so we can manually match as sings admin / staff
    } else if (event.value === 'tta') {
      this.filteredBranches = this.branches.filter((branch: any) => branch.membershipType === 'tta');
      // this.filteredBranches.unshift(allBranch); // Put 'All' branch in the first position
      this.selectedOperation = 'tta'; // Change local sel. operation
      this.bookRefPrefix = 'TTAS'; // Change prefix so we can manually match as sings admin / staff
    }
    // Check if the branch has a fellohConfig array and if at least one object in the array has bankingPull set to 'yes'
    this.filteredBranches = this.filteredBranches.filter((branch: any) => {
      return branch.fellohConfig && branch.fellohConfig.some((config: any) => config.bankingPull === 'yes');
    });
    // We need to ignore below if the firstUse is present - otherwise normal user will end up without trade code
    if (this.companies.length > 1) {
      this.filterInBranches = this.filteredBranches; // It needs to be the same list
      this.selectedBranch = ''; this.filterString = ''; // Reset our strings here..
    }
  }

  filterSelect(): void {
    this.filterInBranches = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.filteredBranches.length; i++) {
      const option = this.filteredBranches[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.filterInBranches.push(option);
      }
    }
  }

  unbalancedOnlySwitch(event: any): void {
    this.unbalancedOnly = event;

    const balanceData: any = [];
    this.balanceDataFiltered.data = [];
    
    this.balanceData.data.forEach((balData: any) => {
      if (!this.unbalancedOnly) { balanceData.push(balData); }
      else {
        // Calculate the sum of all debits and credits
        const total = balData.associatedSequences.reduce((acc: any, item: any) => {
        acc.debit += parseFloat(item.debit);
        acc.credit += parseFloat(item.credit);
        return acc; }, { debit: 0, credit: 0 });

        if (balData.reconciled !== balData.associatedSequences.length) {
          balanceData.push(balData);
        } else if (total.debit !== parseFloat(balData.debit)) {
          balanceData.push(balData);
        } else if (total.credit !== parseFloat(balData.credit)) {
          balanceData.push(balData);
        }
      }
    });

    this.balanceDataFiltered.data = balanceData;
  }

  showExtRefSwitch(): void {
    AppComponent.myapp.showExtRefSwitch(!this.showExtReference);
    this.showExtReference = AppComponent.myapp.showExternalRef;
  }

  switchView(view: any): void {
    // Simple controller to change the page view ports
    this.expandedElement = null;
    if (view === 'summary') {
      this.summaryView = true;
      this.detailsView = false;
      this.balanceView = false;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100); // Adding a small delay ensures the charts are loaded before triggering resize
    } else if (view === 'details') {
      this.summaryView = false;
      this.detailsView = true;
      this.balanceView = false;
    } else if (view === 'balance') {
      this.summaryView = false;
      this.detailsView = false;
      this.balanceView = true;
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  searchPrefixOnOff(): void {
    if (this.prefixOn) { this.prefixOn = false; this.maxBkLength = 25; }
    else { this.prefixOn = true; this.maxBkLength = 7; }
  }

  openSelectedDialog(selDialog: any, cssClass: any): void {
    this.dialog.open(selDialog, { panelClass: cssClass, disableClose: false, autoFocus: false });
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}

