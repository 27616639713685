import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Session } from '../../common/session';
import { GlobalConstants } from '../../common/global-constants';
import { UserService } from '../../services/user.service';
import { SupplierService } from '../../services/supplier.service';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-element-type',
  templateUrl: './element-type.component.html',
  styleUrls: ['./element-type.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class ElementTypeComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  supplierTypes = GlobalConstants.supplierTypes;
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('requestDialog') requestDialog!: TemplateRef<any>;

  // Boolean deciding whether user has access or not
  pageLoaded = false;
  externalElement = false;
  openedBooking: any = '';

  // Supplier type views various booleans here
  flightOn = false;
  hotelOn = false;
  carHireOn = false;
  carParkOn = false;
  attractionOn = false;
  cruiseOn = false;
  miscOn = false;
  transferOn = false;
  packageOn = false;
  trainOn = false;

  // SFC (and others in the future) below
  sfc = { flight: false, hotel: false, carHire: false, carPark: false, attra: false,
          cruise: false, misc: false, transfer: false, package: false, train: false };

  // Supplier variables below
  supplierData: any = []; // Holds data taken from API
  supplierFilteredData: any = []; // Holda data filtered out from variable above
  supplierTypesData: any = []; // Holds types (grouping)
  chosenSupplierType: any = '';
  filterString: any = '';

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  currentCompany: any = '';
  currentOperation: any = '';
  currentTradeCode: any = '';

  constructor(private router: Router, public dialog: MatDialog, private userService: UserService,
              private supplierService: SupplierService) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button load the page
      this.openedBooking = Session.mySession.getOpenedBooking();
      this.currentCompany = this.openedBooking.company;
      this.currentOperation = this.openedBooking.operation;
      this.currentTradeCode = this.openedBooking.tradeCode;

      // Get the booking data from session (TBD this should change I think..)
      if (this.openedBooking != null) {

        let sorted = Session.mySession.supplierList.supplierList.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); // Sort suppliers by their name first
        sorted.map(async (element: any) => { element.isUnderSFC = element.sfcs.some((e: any) => e.status === 'Approved'); });
        sorted = sorted.filter((supplier: any) => supplier.supplierType !== 'Supplement'); // Filter out any Supplement suppliers from the array list
        sorted = sorted.filter((supplier: any) => supplier.hidden === 'no'); // Filter out all HIDDEN suppliers from the list
        const grouped = Object.values(sorted.reduce((h: any, obj: any) => Object.assign(h, { [obj.supplierNameM]: (h[obj.supplierNameM] || []).concat(obj) }), {})); // Group suppliers by their master name
        this.supplierData = grouped; // Supplier data we're using in UI is grouped (at this point)
        this.supplierFilteredData = grouped; // Supplier data which will be used when filtering etc

        // Non-fresh elements will have some (or all) elements blocked to prevent multi-same supplier reference stuff
        if (this.openedBooking.freshElement === 'no') {
          // Take only suppliers which are element's supplier and set it to global variable
          this.supplierTypesData = this.supplierData.find((supplier: any) => supplier[0].supplierNameM === this.openedBooking.supplierName);
          this.setLogoOptions(this.supplierTypesData); // Whatever's done / available - hide it / show it in UI function
        }
        this.pageLoaded = true;
      } else {
        this.pageLoaded = true; // Page loaded but the booking data is missing. Nothing happens after that
      }
    }
  }

  changeSupplier(supplier: any): void {
    this.chosenSupplierType = null; // When changed the supplier, reset mat radio group (nothing selected)
    this.supplierTypesData = supplier; // Assign supplier groups to the variable
    this.setLogoOptions(supplier); // Whatever's done / available - hide it / show it in UI function
  }

  setLogoOptions(supplierTypes: any): void {
    this.resetAll(); // Reset disabling buttons before doing anything first
    // Loop through each supplier in supplier group varaible
    // If given type exists within the group, make the radio button available in UI (typeOn)
    supplierTypes?.forEach((supplier: any) => {
      //if (supplier.supplierType === 'Flight') {
        this.flightOn = true; if (supplier.isUnderSFC) { this.sfc.flight = true; }
      //} else if (supplier.supplierType === 'Accommodation') {
        this.hotelOn = true; if (supplier.isUnderSFC) { this.sfc.hotel = true; }
      //} else if (supplier.supplierType === 'CarParking') {
        this.carParkOn = true; if (supplier.isUnderSFC) { this.sfc.carPark = true; }
      //} else if (supplier.supplierType === 'CarHire') {
        this.carHireOn = true; if (supplier.isUnderSFC) { this.sfc.carHire = true; }
      //} else if (supplier.supplierType === 'Attraction') {
        this.attractionOn = true; if (supplier.isUnderSFC) { this.sfc.attra = true; }
      //} else if (supplier.supplierType === 'Cruise') {
        this.cruiseOn = true; if (supplier.isUnderSFC) { this.sfc.cruise = true; }
      //} else if (supplier.supplierType === 'Miscellaneous') {
        this.miscOn = true; if (supplier.isUnderSFC) { this.sfc.misc = true; }
      //} else if (supplier.supplierType === 'Train') {
        this.trainOn = true; if (supplier.isUnderSFC) { this.sfc.train = true; }
      //} else if (supplier.supplierType === 'Transfer') {
        this.transferOn = true; if (supplier.isUnderSFC) { this.sfc.transfer = true; }
      //} else if (supplier.supplierType === 'Package') {
        this.packageOn = true; if (supplier.isUnderSFC) { this.sfc.package = true; }
      //}
    });
    // In case the supplierTypes is undefined then it most likely comes from an external source - raise the flag!
    if (supplierTypes === undefined) { this.externalElement = true; }
  }

  selectSuppType(form: NgForm): void {
    // Find chosen element type in supplier group variable and set it to session
    //const chosenSupplier = this.supplierTypesData.filter((i: any) => i.supplierType.toLowerCase() === form.value.type.toLowerCase())[0];
    const chosenSupplier = this.supplierTypesData[0];
    Session.mySession.setElementSupplier(chosenSupplier);

    if (form.value.type !== 'package') {
      this.router.navigateByUrl('/addElement/' + form.value.type, { state: window.history.state });
    } else {
      this.router.navigateByUrl('package/add', { state: window.history.state });
    }
  }

  filterSelect(): void {
    this.supplierFilteredData = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.supplierData.length; i++) {
      const option = this.supplierData[i];
      if (option[0].supplierNameM.toLowerCase().indexOf(filter) >= 0) {
        this.supplierFilteredData.push(option);
      }
    }
  }

  resetAll(): void {
    this.flightOn = false;
    this.hotelOn = false;
    this.carHireOn = false;
    this.carParkOn = false;
    this.attractionOn = false;
    this.cruiseOn = false;
    this.miscOn = false;
    this.transferOn = false;
    this.packageOn = false;
    this.trainOn = false;
    this.sfc = { flight: false, hotel: false, carHire: false, carPark: false, cruise: false,
                  attra: false, misc: false, transfer: false, package: false, train: false };
  }

  openRequestDialog(): void {
    this.dialog.open(this.requestDialog, {panelClass: 'bankingExternalBox', disableClose: true, autoFocus: false});
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}
