<div class="mainDiv">
    <div style="margin-bottom: 40px;">
        <mat-toolbar>
            <div class="buttonBar">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <button class="navButtons" mat-button [disabled]="preview" (click)="preview = true; emailBody = false;">
                        <mat-icon style="margin-right: 5px;">preview</mat-icon> PDF Preview
                    </button>
                    <button class="navButtons" mat-button [disabled]="emailBody" (click)="preview = false; emailBody = true;">
                        <mat-icon style="margin-right: 5px;">mail</mat-icon> Send Email
                    </button>
                </div>
            </div>
        </mat-toolbar>
    </div>
    <div class="chosenDiv" *ngIf="preview">
        <div [innerHtml]="sanitizedHtmlMarkup"></div>
    </div>

    <div class="chosenDiv" *ngIf="emailBody">
        <div class="additionalInformation" *ngIf="pageLoaded">
            <form ngNativeValidate #sendDocumentForm="ngForm" style="font-size: 13px; height: 100%;">
                <div style="height: 100%; display: flex; flex-direction: column;">
                    <div style="margin-top: 20px; margin-bottom: 20px;">
                        <h2>Please take a moment to review the email content below.<br><br>
                            You can send the PDF file as an attachment by simply clicking the buttons located at the bottom of the page.<br><br>
                            You have the option to send it to either yourself or the lead passenger.</h2>
                    </div>
                    <div style="margin-top: 20px; margin-bottom: 20px; border-top: 1px solid #dfdfdf">
                        <h3 style="text-align: left;;">Email Body</h3>
                        <mat-form-field style="width: 100%; max-width: unset;">
                            <textarea style="min-width: 50%; overflow: hidden; background: #fafafa; border-radius: 10px;" matInput
                              cdkTextareaAutosize ngModel name="emailWording" #emailWording="ngModel"
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20" [(ngModel)]="preSetEmail"></textarea>
                        </mat-form-field>
                    </div>
                    <div style="margin-top: 20px; margin-bottom: 20px; font-size: 13.5px;">
                        <i>Emails will be sent to the lead passenger's email with the sender's email set as <b>singsadmin@thetravelnetworkgroup.co.uk</b>.</i>

                        <p><i>Due to technical limitations, the PDF file may vary slightly from what was presented on the screen.<br>
                            We recommend either sending the file to yourself first or using the other button for printing instead.</i></p>
                    </div>
                    <div style="padding-bottom: 10px;">
                        <button class="emailButtons" mat-raised-button color="primary" [disableRipple]="true" (click)="sendEmail(sendDocumentForm, true)">
                            <mat-icon style="margin-right: 10px; margin-top: -1px;">email</mat-icon>Email Yourself
                        </button>

                        <button class="emailButtons" mat-raised-button color="primary" [disableRipple]="true" (click)="sendEmail(sendDocumentForm, false)">
                            <mat-icon style="margin-right: 5px; margin-top: -1px;">email</mat-icon>Email Passenger
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="additionalInformation" style="display: flex; align-items: center; justify-content: center; height: 500px;" *ngIf="!pageLoaded">
            <h1>Producing and sending the document, please wait..</h1>
        </div>
    </div>
</div>

<ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
      <p style="font-weight: 400;">{{errorMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  
    <div *ngIf="successMessage != ''" class="successMessage">
      <p style="font-weight: 400;">{{successMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  </ng-template>