import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { BranchService } from '../../services/branch.service';
import { UserService } from '../../services/user.service';
import { GlobalConstants } from '../../common/global-constants';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class BankAccountsComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // Variables controlling user access
  pageLoaded = false;

  // List of the table columns which are displayed in .html file
  displayedColumns = ['accountType', 'accountName', 'bankName', 'accountNumber', 'sortCode', 'iban', 'swift', 'currency', 'active'];

  // Data will be dumped into this varaible below
  bankAccData: any = new MatTableDataSource<any>();

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  newAccount: any = {};

  // Variables taken from the child component
  @Input() ownerCode: any;
  @Input() ownerType: any;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(public dialog: MatDialog, private userService: UserService, private branchService: BranchService) { }

  ngOnInit(): void {
    this.getBankAccounts(); // Initial load of all bank accounts for a supplier / branch
    this.resetNewAccount(); // Set the new account 'form' fields
  }

  getBankAccounts(): void {
    const request = { ownerCode: this.ownerCode, ownerType: this.ownerType, token: Session.mySession.get('user').token };
    this.bankAccData.data = [];
    this.pageLoaded = false; // Create a request variable and set as 'loading..'

    this.branchService.getBankAccounts(request).then((bankAccounts: any) => {
      if (bankAccounts.status === 'OK') {
        this.bankAccData.data = bankAccounts.data;
        this.pageLoaded = true; // Looks like everything went well - stop 'loading..' and assign data to a var.
      } else {
        this.sendMessageToDialog('', bankAccounts.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2201S)', error, request);
    });
  }

  createBankAccount(): void {
    const mandatoryFields = Object.keys(this.newAccount).filter(key => key !== 'iban' && key !== 'swift' && key !== 'accountNumber' && key !== 'sortCode');

    // Check for null or empty values in mandatory fields
    const hasEmptyMandatoryField = mandatoryFields.some(key => this.newAccount[key] === null || this.newAccount[key] === '');
    
    const accountNumberEmpty = this.newAccount.accountNumber === null || this.newAccount.accountNumber === '';
    const sortCodeEmpty = this.newAccount.sortCode === null || this.newAccount.sortCode === '';
    const ibanEmpty = this.newAccount.iban === null || this.newAccount.iban === '';
    const swiftEmpty = this.newAccount.swift === null || this.newAccount.swift === '';

    if (hasEmptyMandatoryField) {
      this.sendMessageToDialog('', 'All fields are mandatory, except IBAN and SWIFT or Sort Code and Account Number', '', '');
    } else if ((accountNumberEmpty || sortCodeEmpty) && (ibanEmpty || swiftEmpty)) {
      this.sendMessageToDialog('', 'Either Account Number and Sort Code or IBAN and SWIFT must be provided', '', '');
    } else if (!accountNumberEmpty && !/^[0-9]+$/.test(this.newAccount.accountNumber)) {
      this.sendMessageToDialog('', 'Account number must be made of numbers only', '', '');
    } else if (!sortCodeEmpty && !/^[0-9]+$/.test(this.newAccount.sortCode)) {
      this.sendMessageToDialog('', 'Sort code must be made of numbers only', '', '');
    } else if (!accountNumberEmpty && this.newAccount.accountNumber.length < 8) {
      this.sendMessageToDialog('', 'Account number needs to be at least 8 characters long', '', '');
    } else if (!sortCodeEmpty && this.newAccount.sortCode.length < 6) {
      this.sendMessageToDialog('', 'Sort code needs to be at least 6 characters long', '', '');
    } else if (!/^[A-Za-z]{3}$/.test(this.newAccount.currency)) {
      this.sendMessageToDialog('', 'Currency needs to be in ISO 4217 format which is 3 characters long', '', '');
    } else if (!ibanEmpty && this.newAccount.iban.length < 15) {
      this.sendMessageToDialog('', 'IBAN needs to be at least 15 characters long', '', '');
    } else if (!swiftEmpty && this.newAccount.swift.length < 8) {
      this.sendMessageToDialog('', 'SWIFT/BIC needs to be at least 8 characters long', '', '');
    } else {
      const request: any = Object.assign({}, this.newAccount);
      request.currency = request.currency.toUpperCase();
      request.token = Session.mySession.get('user').token;

      this.pageLoaded = false; // Duplicate object and use it to create new account

      this.branchService.createBankAccount(request).then((output: any) => {
        if (output.status === 'OK') {
          // Reset new account fields and reload account list
          this.resetNewAccount(); this.getBankAccounts();
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2202S)', error, request);
      });
    }
  }

  editBankAccount(bankAcc: any, active: any): void {
    const request: any = Object.assign({}, bankAcc); request.active = active;
    request.token = Session.mySession.get('user').token;
    this.pageLoaded = false; // Duplicate object and change its active state..

    this.branchService.updateBankAccount(request).then((output: any) => {
      if (output.status === 'OK') {
        bankAcc.active = active; // Assign the requested status if OK
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2203S)', error, request);
    });
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  resetNewAccount(): void {
    this.newAccount = { ownerCode: this.ownerCode, ownerType: this.ownerType, accountType: '',
                        accountName: '', accountNumber: '', sortCode: '', bankName: '',
                        iban: '', swift: '', currency: '' };
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}
