import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TapsService {
  constructor(private http: HttpClient) { }

  // Admin
  async listUnCommittedImportedTapsUploads(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'listUnCommittedImportedTapsUploads?' + body).toPromise();
    return output;
  }
  async deleteTapsFile(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'deleteTapsFile', body, { 'headers': headers }).toPromise();
    return output;
  }
  async deleteTapsUploadData(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'deleteTapsUploadData', body, { 'headers': headers }).toPromise();
    return output;
  }
  async listReviewedTapsUploadsToBeCommitted(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'listReviewedTapsUploadsToBeCommitted?' + body).toPromise();
    return output;
  }
  async getTransactionsInvoicingReport(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTransactionsInvoicingReport?' + body).toPromise();
    return output;
  }
  async commitTapsUpload(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'commitTapsUpload', body, { 'headers': headers }).toPromise();
    return output;
  }
  async commitMultipleTapsUploads(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'commitMultipleTapsUploads', body, { 'headers': headers }).toPromise();
    return output;
  }
  async getTapsAdminReport(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsAdminReport?' + body).toPromise();
    return output;
  }
  async listAllTapsUploads(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'listAllTapsUploads?' + body).toPromise();
    return output;
  }
  async getTapsSupplierStatusReport(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsSupplierStatusReport?' + body).toPromise();
    return output;
  }
  async getTapsAgentsForReminder(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsAgentsForReminder?' + body).toPromise();
    return output;
  }
  async sendTapsAgentReminder(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'sendTapsAgentReminder', body, { 'headers': headers }).toPromise();
    return output;
  }
  async sendAllTapsAgentsReminder(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'sendAllTapsAgentsReminder', body, { 'headers': headers }).toPromise();
    return output;
  }
  async listCollectionFromAgents(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'listCollectionFromAgents?' + body).toPromise();
    return output;
  }
  async submitCollectionFromAgents(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'submitCollectionFromAgents', body, { 'headers': headers }).toPromise();
    return output;
  }
  async listDeliveryToSuppliers(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'listDeliveryToSuppliers?' + body).toPromise();
    return output;
  }  
  async submitDeliveryToSuppliers(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'submitDeliveryToSuppliers', body, { 'headers': headers }).toPromise();
    return output;
  }
  async getTapsBacsReport(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsBacsReport?' + body).toPromise();
    return output;
  }
  async getTapsAdminAnalysisReport(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsAdminAnalysisReport?' + body).toPromise();
    return output;
  }
  async getUnMatchedTapsPayments(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getUnmatchedTapsPayments?' + body).toPromise();
    return output;
  }
  async generateTapsAdminReport(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'generateTapsAdminReport', body, { 'headers': headers }).toPromise();
    return output;
  }
  async commitTransactionsInvoicingReport(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'commitTransactionsInvoicingReport', body, { 'headers': headers }).toPromise();
    return output;
  } 
  async getAllTapsMembersData(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getAllTapsMembersData?' + body).toPromise();
    return output;
  }
  async getAllTapsSuppliersData(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getAllTapsSuppliersData?' + body).toPromise();
    return output;
  }

  // Suppliers 
  async preCreateTapsUpload(request:any) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'preCreateTapsUpload', body, { 'headers': headers }).toPromise();
    return output;
  }
  async createTapsUpload(request: any) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'createTapsUpload', body, { 'headers': headers }).toPromise();
    return output;
  }
  async getTapsUploadHistoryList(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsUploadHistoryList?' + body).toPromise();
    return output;
  }
  async getTapsUploadTransactions(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsUploadTransactions?' + body).toPromise();
    return output;
  }
  async listPullDownFiles(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'listPullDownFiles?' + body).toPromise();
    return output;
  }
  async getPullDownFile(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getPullDownFile?' + body).toPromise();
    return output;
  }
  async getTapsAgentsList(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsAgentsList?' + body).toPromise();
    return output;
  }
  async getTapsSupplierReport(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsSupplierReport?' + body).toPromise();
    return output;
  }
  async generateTapsSupplierReport(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'generateTapsSupplierReport', body, { 'headers': headers }).toPromise();
    return output;
  }
  async rejectTapsUpload(request: any) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'rejectTapsUpload', body, { 'headers': headers }).toPromise();
    return output;
  }

  // Members
  async getTransactionApprovalUploads(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTransactionApprovalUploads?' + body).toPromise();
    return output;
  }  
  async saveMemberTransactionsForReview(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'saveMemberTransactionsForReview', body, { 'headers': headers }).toPromise();
    return output;
  }
  async getTapsMemberReport(request:any) {
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsMemberReport?' + body).toPromise();
    return output;
  }
  async getTapsMemberAnalysisReport(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsMemberAnalysisReport?' + body).toPromise();
    return output;
  }
  async exportToExcelMembersTransactionsForReview(request:any) {
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'exportToExcelMembersTransactionsForReview?' + body).toPromise();
    return output;
  }
  async getPreImportOfTapsTransactionsForMemberReview(request:any) {
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getPreImportOfTapsTransactionsForMemberReview?' + body).toPromise();
    return output;
  }
  async generateTapsMemberReport(request:any){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let output = await this.http.post(environment.apiURL + 'generateTapsMemberReport', body, { 'headers': headers }).toPromise();
    return output;
  }
  async getTapsMasterAgents(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'getTapsMasterAgents?' + body).toPromise();
    return output;
  }
  async exportToPdfMembersTransactionsForReview(request:any){
    let body = ""
    for (const [key,value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value
    }
    let output = await this.http.get(environment.apiURL + 'exportToPdfMembersTransactionsForReview?' + body).toPromise();
    return output;
  }

}
