import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from './../../../environments/environment';
import { Session } from '../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { ReportsService } from '../../services/reports.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatStepper } from '@angular/material/stepper';
import { CustomerService } from '../../services/customer.service';
import { UserService } from '../../services/user.service';
import { FellohService } from '../../services/felloh.service';
import { SupplierService } from '../../services/supplier.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PrfService } from 'src/app/services/prf.service';
import { AppComponent } from '../../app.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsynchRequests } from 'src/app/common/asynch-requests';
import { ReceiptService } from '../../services/receipt.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'app-prf',
  templateUrl: './prf.component.html',
  styleUrls: ['./prf.component.css', '../../../app/app.component.fellohStyles.css'],
    animations: [
      trigger('inAnimation',
        [
          transition(
            ':enter',
            [
              style({ opacity: 0 }),
              animate('375ms cubic-bezier(.67,.52,.34,.82)',
                style({ opacity: 1, display: 'inline' }))
            ]
          )
        ]
      ),
      trigger('customExpansionDetails', [
        state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
        state('expanded', style({ height: '*', opacity: 1 })),
        transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ]
})
export class PrfComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  supplierTypes = GlobalConstants.supplierTypes;
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginator') paginatorPrfs!: MatPaginator;
  @ViewChild('paginator') paginatorDocuments!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('suggestedBookingDialog') suggestedBookingDialog!: TemplateRef<any>;
  @ViewChild('popUpPrfDialog') popUpPrfDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('createReportDialog') createReportDialog!: TemplateRef<any>;
  @ViewChild('viewReportDialog') viewReportDialog!: TemplateRef<any>;
  @ViewChild('editDialog') editDialog!: TemplateRef<any>;
  @ViewChild('stepper') stepper!: MatStepper;

  // Used in Authentication form
  actionType = '';

  // Variables controlling user access
  userName = '';
  userEmail = '';
  userType = '';
  haveAccess = false;
  pageLoaded = false;

  // Variables controlling side-nav view
  prfList: boolean = true;
  prfNew: boolean = false;
  prfView: boolean = false;
  prfReports: boolean = false;
  newSupplierDiv = false;

  // Variable for viewing PRF's Tabs
  listedViewPrfs: any = []; // What PRFS we have open in new tabs
  prfNoView: boolean[] = []; // What prf view are we currently looking at? What number
  initialPrfNo: any = 0; // This will block removing prfs with already existing prfs
  prfBreakdown: any = []; // Holds prfs info

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';

  // Variables supporting mat-stepper
  firstCompleted: boolean = false;
  secondCompleted: boolean = false; 
  thirdCompleted: boolean = false;
  firstViewCompleted: boolean = false;
  thirdViewCompleted: boolean = false;
  exTransactionViewChanged: boolean = false;
  expansionOnlinePrf: boolean = true;
  expansionSupportingDocumentation: boolean = true;
  expansionTransactionValues: boolean = true;
  expansionSignOff:boolean = true;
  expansionPrfStatusSymbols:boolean = true;
  expansionPrfUserManual:boolean = true;

  // Variables for PRF list
  prfs: any = new MatTableDataSource<any>();
  prfsColumns = ['prfNumber', 'assignedTo', 'dateSubmitted', 'supplierName', 'passengerName', 'prfType', 'paymentOption', 'amount', 'status', 'id']
  prfsTrusteeColumns = ['prfNumber', 'prfTradeCode', 'prfCreator', 'assignedTo', 'dateSubmitted', 'supplierName', 'passengerName', 'prfType', 'paymentOption', 'amount', 'status', 'id']
  filterPrfs = false;
  prfNumber = "";
  passengerName = "";
  supplierName = "";
  prfsTradeCode = "";
  prfsPaymentOptions:any = 0;
  prfsAssignedTo = "";
  prfsStatus:any = 0;
  prfsFrom:any = "";
  prfsTo:any = "";
  prfsPerPage = 20;
  prfsSorted = false;
  prfsEditMode = false;
  prfsEditList:any = [];
  prfsEditStatus: any = 0;

  // Variables to Create PRF (Online PRF Information)
  createIsClientFundsReceived = 2;
  createHasClientReturned = 2;
  createSupplierName = "";
  createSupplierNoneIsAvailable = false;
  createTypesOfClaim = "";
  createClaimSupplierPayment = { 
    checked: false, 
    paymentMethods: {
      checks:[false,false,false,false,false,false,false], 
      methods: ["Natwest Faster","Natwest CHAPS","Barclays","Corpay","Monteith","Amex Forex Transfer","Other"]
  }};
  createClaimCommission = { checked: false, paymentMethod: "" };
  createClaimReimbursement = { checked: false, paymentMethod: "" };
  createClaimClientRefund = { checked: false, paymentMethod: "" };
  createAssignedTo = "";
  createDateSubmitted = "";
  createPassengerName = "";
  createAmount = "";
  createFinancialProtection = {  
    checks:[false,false,false,false,false], 
    methods: ["None","ATOL","PSA","SFC / SAFI","TTA Member"]
  };
  createFinancialProtectionEnable = false;
  createClientReturnDate: any = null
  createClientReturnDateEnable = false;
  createNotesToTrustee = "";
  createTransactionValuesList: Array<any> = [{
    createBookingReference: "",
    createClientSurname: "", 
    createDateFundsBanked: null, 
    createPaymentMethod: "",
    createGrossClientFunds: { currency: "", amount: null }, 
    createGuaranteeDeducted: { currency: "", amount: null },
    createVAT: 0.00,
    createSupplierAmount: { currency: "", amount: null }, 
    createCommission: { currency: "", amount: null },
    createClientRefund: { currency: "", amount: null }, 
    createReimbursement: { currency: "", amount: null },
    createListOfSurnames: [],
    createBookingData: null,
    createReceiptData: new MatTableDataSource<any>(),
    createShowReceipt: false,
    createIsNotComplete: true
  }];
  createCollectedFrom = "";
  createMultipleBookingReference = false;
  createNotMatchingSuppliers = false;
  createListOfPaymentMethods:any = [];
  createListOfSuggestedBookings:any = [];
  createToogleSuggestedBookings:any = false;
  createSelectedSuggestedBooking:any = {};
  createSelectedSuggestions: any[] = [];

  // Variables for current viewing PRF (Online PRF Information)
  viewPrfNumber = "";
  viewPrfAssignedTo = "";
  viewPrfDateSubmitted = "";
  viewPrfSupplierName = "";
  viewPrfSupplierNoneIsAvailable = false;
  viewPrfPassengerName = "";
  viewPrfStatus = "";
  viewPrfAmountOne = 0.00;
  viewPrfCurrencyOne = "";
  viewPrfClaimOne = "";
  viewPrfPaymentMethodOne = "";
  viewPrfAmountTwo:any = null;
  viewPrfCurrencyTwo:any = null;
  viewPrfClaimTwo:any = null;
  viewPrfPaymentMethodTwo:any = null;
  viewPrfClientFundsBanked = false;
  viewPrfThirdPartyPaperwork = false;
  viewPrfMemberAccountBalanceAmount:any = null;
  viewPrfMemberAccountBalanceCurrency:any = null;
  viewPrfComCheckForAtol:any = null;
  viewPrfComCheckForPsa:any = null;
  viewPrfComCheckForClientsReturned:any = null;
  viewPrfComCheckForSfc:any = null;
  viewPrfActionRequiredMessage:any = null;
  viewPrfIsClientFundsReceived = 2;
  viewPrfHasClientReturned = 2;
  viewPrfFinancialProtection = {  
    checks:[false,false,false,false,false], 
    methods: ["None","ATOL","PSA","SFC / SAFI","TTA Member"]
  };
  viewPrfFinancialProtectionText = "";
  viewPrfFinancialProtectionEnable = false;
  viewPrfClientReturnDate:any = null;
  viewPrfClientReturnDateEnable = false;
  viewPrfNotesToTrustee:any = null;
  viewPrfCollectedFrom:any = null;
  viewPrfCompany = "";
  viewPrfOperation = "";
  viewPrfTradeCode = "";
  viewPrfCreator = "";
  viewPrfCreatorEmail = "";  
  viewPrfOfficeNotes:any = "";
  viewPrfEmailText:any = "";
  viewPrfTransactionValuesList : Array<any> = []; 
  viewPrfDeletedTransactionValuesList : Array<any> = []; 
  viewPrfAddedTransactionValuesList : Array<any> = []; 
  viewClaimSupplierPayment = { 
    checked: false, 
    paymentMethods: {checks:[false,false,false,false,false,false,false], 
    methods: ["Natwest Faster","Natwest CHAPS","Barclays","Corpay","Monteith","Amex Forex Transfer","Other"]}};
  viewClaimCommission = { checked: false, paymentMethod: "" };
  viewClaimReimbursement = { checked: false, paymentMethod: "" };
  viewClaimClientRefund = { checked: false, paymentMethod: "" };
  viewPrfLinkedBookings : Array<any> = [];
  viewPrfBookingReference:any = null;
  viewListOfPaymentMethods:any = [];
  viewPrfAmountOneTrustee = 0.00;
  viewPrfAmountTwoTrustee = 0.00;
  viewPrfCurrencyOneTrustee = "GBP";
  viewPrfCurrencyTwoTrustee = "GBP";
  viewNotMatchingSuppliers = true;
  viewPrfBranchName = "";

  //Trustees Variables
  reAssignTo = "0";
  selectedAction = 0;
  listOfActions: any = []
  emailTo: any[] = [];
  emailOptions: any = [];
  addEmail = "";
  checkOpenEmail = false;
  checkAuthorisedEmail = false;

  // Variables for PRF's Documents (Create and Update)
  viewPrfDocumentColumns = ['documentType', 'dateAttached', 'fileName', 'downloadDocument', 'deleteDocument']
  viewPrfPopUpDocumentColumns = ['documentType', 'dateAttached', 'fileName', 'downloadDocument']
  viewPrfDocumentsData: any = new MatTableDataSource<any>();
  uploadedPrfDocumentsColumns = ['documentType', 'dateAttached', 'fileName', 'downloadDocument', 'deleteDocument']
  uploadedPrfDocuments: any = new MatTableDataSource<any>();
  showPrfDocuments = true;
  attachedDocs: any = new MatTableDataSource<any>();
  s3Documents = true;
  s3FilesColumns = ['fileName', 'lastModified', 'size', 'download'];

  // Attr -> fileName / documentType  / file
  invoiceFilesAttr: Object[] = [];
  scheduleFilesAttr: Object[] = [];
  confirmationOfPaymentFilesAttr: Object[] = [];
  rbsBankFileAttr: Object[] = [];
  newInvoiceFilesLoaded = "";
  newScheduleFilesLoaded = "";
  newRbsFilesLoaded = "";
  newCoPFilesLoaded = "";

  // Variable used to contain list of available Suppliers in the system
  suppliersList: any = [];

  // Audit Table (Office Control)
  viewPrfAuditListColumns = ['signedDate','trusteeName','status','officeNotes']
  viewPrfAuditListData: any = new MatTableDataSource<any>();

  // Currencys
  listOfCurrency = [
    {value:"GBP",key:"GBP"},{value:"USD",key:"USD"},{value:"EUR",key:"EUR"},{value:"ZAR",key:"ZAR"},
    {value:"AED",key:"AED"},{value:"AFN",key:"AFN"},{value:"ALL",key:"ALL"},{value:"AMD",key:"AMD"},
    {value:"ANG",key:"ANG"},{value:"AOA",key:"AOA"},{value:"ARS",key:"ARS"},{value:"AUD",key:"AUD"},
    {value:"AWG",key:"AWG"},
    {value:"AZN",key:"AZN"},{value:"BAM",key:"BAM"},{value:"BBD",key:"BBD"},{value:"BDT",key:"BDT"},
    {value:"BGN",key:"BGN"},{value:"BHD",key:"BHD"},{value:"BIF",key:"BIF"},{value:"BMD",key:"BMD"},
    {value:"BND",key:"BND"},{value:"BOB",key:"BOB"},{value:"BRL",key:"BRL"},{value:"BSD",key:"BSD"},
    {value:"BWP",key:"BWP"},{value:"BYR",key:"BYR"},{value:"BZD",key:"BZD"},{value:"CAD",key:"CAD"},
    {value:"CDF",key:"CDF"},{value:"CHF",key:"CHF"},{value:"CLP",key:"CLP"},{value:"CNY",key:"CNY"},
    {value:"COP",key:"COP"},{value:"CRC",key:"CRC"},{value:"CUP",key:"CUP"},{value:"CVE",key:"CVE"},
    {value:"CZK",key:"CZK"},{value:"DJF",key:"DJF"},{value:"DKK",key:"DKK"},{value:"DOP",key:"DOP"},
    {value:"DZD",key:"DZD"},{value:"EEK",key:"EEK"},{value:"EGP",key:"EGP"},{value:"ERN",key:"ERN"},
    {value:"ETB",key:"ETB"},{value:"FJD",key:"FJD"},{value:"FKP",key:"FKP"},{value:"GEL",key:"GEL"},
    {value:"GHS",key:"GHS"},{value:"GIP",key:"GIP"},{value:"GMD",key:"GMD"},{value:"GNF",key:"GNF"},
    {value:"GTQ",key:"GTQ"},{value:"GWP",key:"GWP"},{value:"GYD",key:"GYD"},{value:"HKD",key:"HKD"},
    {value:"HNL",key:"HNL"},{value:"HRK",key:"HRK"},{value:"HTG",key:"HTG"},{value:"HUF",key:"HUF"},
    {value:"IDR",key:"IDR"},{value:"ILS",key:"ILS"},{value:"INR",key:"INR"},{value:"IQD",key:"IQD"},
    {value:"IRR",key:"IRR"},{value:"ISK",key:"ISK"},{value:"JMD",key:"JMD"},{value:"JOD",key:"JOD"},
    {value:"JPY",key:"JPY"},{value:"KES",key:"KES"},{value:"KGS",key:"KGS"},{value:"KHR",key:"KHR"},
    {value:"KMF",key:"KMF"},{value:"KPW",key:"KPW"},{value:"KRW",key:"KRW"},{value:"KWD",key:"KWD"},
    {value:"KYD",key:"KYD"},{value:"KZT",key:"KZT"},{value:"LAK",key:"LAK"},{value:"LBP",key:"LBP"},
    {value:"LKR",key:"LKR"},{value:"LRD",key:"LRD"},{value:"LTL",key:"LTL"},{value:"LVL",key:"LVL"},
    {value:"LYD",key:"LYD"},{value:"MAD",key:"MAD"},{value:"MDL",key:"MDL"},{value:"MGA",key:"MGA"},
    {value:"MKD",key:"MKD"},{value:"MMK",key:"MMK"},{value:"MNT",key:"MNT"},{value:"MOP",key:"MOP"},
    {value:"MRO",key:"MRO"},{value:"MUR",key:"MUR"},{value:"MVR",key:"MVR"},{value:"MWK",key:"MWK"},
    {value:"MXN",key:"MXN"},{value:"MYR",key:"MYR"},{value:"MZN",key:"MZN"},{value:"NAD",key:"NAD"},
    {value:"NGN",key:"NGN"},{value:"NIO",key:"NIO"},{value:"NOK",key:"NOK"},{value:"NPR",key:"NPR"},
    {value:"NZD",key:"NZD"},{value:"OMR",key:"OMR"},{value:"PAB",key:"PAB"},{value:"PEN",key:"PEN"},
    {value:"PGK",key:"PGK"},{value:"PHP",key:"PHP"},{value:"PKR",key:"PKR"},{value:"PLN",key:"PLN"},
    {value:"PYG",key:"PYG"},{value:"QAR",key:"QAR"},{value:"RON",key:"RON"},{value:"RSD",key:"RSD"},
    {value:"RUB",key:"RUB"},{value:"RWF",key:"RWF"},{value:"SAR",key:"SAR"},{value:"SBD",key:"SBD"},
    {value:"SCR",key:"SCR"},{value:"SDG",key:"SDG"},{value:"SEK",key:"SEK"},{value:"SGD",key:"SGD"},
    {value:"SHP",key:"SHP"},{value:"SKK",key:"SKK"},{value:"SLL",key:"SLL"},{value:"SOS",key:"SOS"},
    {value:"SRD",key:"SRD"},{value:"STD",key:"STD"},{value:"SVC",key:"SVC"},{value:"SYP",key:"SYP"},
    {value:"SZL",key:"SZL"},{value:"THB",key:"THB"},{value:"TJS",key:"TJS"},{value:"TMM",key:"TMM"},
    {value:"TND",key:"TND"},{value:"TOP",key:"TOP"},{value:"TRY",key:"TRY"},{value:"TTD",key:"TTD"},
    {value:"TWD",key:"TWD"},{value:"TZS",key:"TZS"},{value:"UAH",key:"UAH"},{value:"UGX",key:"UGX"},
    {value:"UYU",key:"UYU"},{value:"UZS",key:"UZS"},{value:"VEF",key:"VEF"},{value:"VND",key:"VND"},
    {value:"VUV",key:"VUV"},{value:"WST",key:"WST"},{value:"XAF",key:"XAF"},{value:"XCD",key:"XCD"},
    {value:"XOF",key:"XOF"},{value:"XPF",key:"XPF"},{value:"YER",key:"YER"},{value:"ZMK",key:"ZMK"},
    {value:"ZWD",key:"ZWD"}
  ];

  //
  // SMART PRF Variables BELOW
  //

  // CREATE & VIEW PRF - SmartPRF
  originalListOfAvailableBookings:any = []
  listOfAvailableBookings:any = []
  
  // CREATE PRF VARIABLES - SmartPRF
  createBookingReference:any = "";
  createMatchedBookingReference:any = '';
  createMatchedBookingData:any = {};
  createCustomerData:any = {};
  createListOfElementData:any = [];
  createListOfClientSurnames:any = [];
  createListOfSupplierElements:any = [];

  // VIEW PRF VARIABLES - SmartPRF
  viewBookingReference = "";
  viewMatchedBookingReference:any = '';
  viewMatchedBookingData:any = [];
  viewCustomerData:any = {};
  viewListOfElementData:any = [];
  viewBookingReferenceFlag = false;
  viewListOfClientSurnames:any = [];
  viewListOfSupplierElements:any = [];
  viewMultipleBookingReference = false;
  viewListOfReceiptData:any = [];

  // PRF REPORT VARIABLES - SmartPRF
  selectedReport:any = null;
  listOfReports:any = [
    {
      name: "Member PRF Volume", 
      description: "", 
      parameters: {startDate: "", endDate: "", ttaNumber: "", supplierName: ""},
      table: new MatTableDataSource<any>(),
      columns: ["ttaNumber","name","paymentAmountOne","paymentAmountTwo"],
      sortedBy: {ttaNumber: false, name: false, paymentAmountOne: false, paymentAmountTwo: false},
      onlyForTrustees: true
    },
    {
      name: "No Commission Claims By Members", 
      description: "", 
      parameters: {startDate: "", endDate: "", ttaNumber: null, supplierName: null},
      table: new MatTableDataSource<any>(),
      columns: ["ttaNumber","name","numberOfNewBookings","commission","supplierPayment"],
      sortedBy: {ttaNumber: false, name: false, numberOfNewBookings: false, commission: false, supplierPayment:false},
      onlyForTrustees: true
    },
    {
      name: "PRF Email Sent", 
      description: "Reports the number of PRF's and emails sent from Trustees per member", 
      parameters: {startDate: "", endDate: "", ttaNumber: null, supplierName: null},
      table: new MatTableDataSource<any>(),
      columns: ["ttaNumber","companyName","prfsSubmitted","approxEmailsSent"],
      sortedBy: {ttaNumber: false, companyName: false, prfsSubmitted: false, approxEmailsSent: false},
      onlyForTrustees: true
    },
    {
      name: "Payment Search", 
      description: "Payment search by date range, Supplier name.", 
      parameters: {startDate: "", endDate: "", ttaNumber: "", supplierName: ""},
      table: new MatTableDataSource<any>(),
      columns: ["prfNumber","ttaNumber","assignedTo","dateSubmitted","dateAuthorised","authorisedBy","supplierName","paymentMethod","paymentAmountOne",
      "paymentAmountTwo","amount","authorised","type","claimType"],
      sortedBy: {prfNumber: false, ttaNumber: false, assignedTo: false, dateSubmitted: false, dateAuthorised: false, authorisedBy: false, supplierName: false, 
        paymentMethod: false, paymentAmountOne: false, paymentAmountTwo: false, amount: false, authorised: false, type: false, claimType: false},
      onlyForTrustees: false
    },
    {
      name: "Payment Search - Board Report", 
      description: "", 
      parameters: {startDate: "", endDate: "", ttaNumber: "", supplierName: ""},
      table: new MatTableDataSource<any>(),
      columns: ["ttaNumber","name","clientRefundCount","clientRefundAmountOne","commissionCount","commissionAmountOne",
      "reimbursementCount","reimbursementAmountOne","supplierPaymentCount","supplierPaymentAmountOne","overallCount"],
      sortedBy: {ttaNumber: false, name: false, clientRefundCount: false, clientRefundAmountOne: false, 
        commissionCount: false, commissionAmountOne: false, reimbursementCount: false, reimbursementAmountOne: false, 
        supplierPaymentCount: false, supplierPaymentAmountOne: false, overallCount: false},
      onlyForTrustees: true
    },
    {
      name: "Payment Search - Grouped by Payment", 
      description: "Payment search by date range, TTA Number, Supplier name.", 
      parameters: {startDate: "", endDate: "", ttaNumber: "", supplierName: ""},
      table: new MatTableDataSource<any>(),
      columns: ["prfNumber","ttaNumber","assignedTo","dateSubmitted","dateAuthorised","supplierName","paymentMethod","paymentAmountOne",
      "paymentAmountTwo","authorised","type","claimCount"],
      sortedBy: {prfNumber: false, ttaNumber: false, assignedTo: false, dateSubmitted: false, dateAuthorised: false, supplierName: false, 
        paymentMethod: false, paymentAmountOne: false, paymentAmountTwo: false,  authorised: false, type: false, claimCount: false},
      onlyForTrustees: true
    },
    {
      name: "Supplier Payment Summary", 
      description: "Payment search by date range, Supplier name.", 
      parameters: {startDate: "", endDate: "", ttaNumber: "", supplierName: ""},
      table: new MatTableDataSource<any>(),
      columns: ["supplierName","count","paymentAmountOne","paymentAmountTwo"],
      sortedBy: {supplierName: false, count: false, paymentAmountOne: false, paymentAmountTwo: false},
      onlyForTrustees: false
    },
    {
      name: "Trustee SLA Report - All Members", 
      description: "Show SLA for all TTA members within a given date range", 
      parameters: {startDate: "", endDate: "", ttaNumber: null, supplierName: null},
      table: new MatTableDataSource<any>(),
      columns: ["ttaNumber","onlineVolume","onlineDispatched","onlinePending","onlineCancelled","withinSla","percentageSla"],
      sortedBy: {ttaNumber:false, onlineVolume: false, onlineDispatched: false, onlinePending: false, onlineCancelled: false, withinSla:false, percentageSla:false},
      onlyForTrustees: true
    },
    {
      name: "Trustee SLA Report - By Date", 
      description: "Show SLA by date within an given date range", 
      parameters: {startDate: "", endDate: "", ttaNumber: null, supplierName: null},
      table: new MatTableDataSource<any>(),
      columns: ["dateReceived","onlineVolume","onlineDispatched","onlinePending","onlineCancelled","withinSla","percentageSla"],
      sortedBy: {dateReceived:false, onlineVolume: false, onlineDispatched: false, onlinePending: false, onlineCancelled: false, withinSla:false, percentageSla:false},
      onlyForTrustees: true
    }
    // { 
    //   name: "Current Members with TTA Trustees", 
    //   description: "Shows their Trust Account Details (GBP).", 
    //   parameters: "None",
    //   table: new MatTableDataSource<any>(),
    //   columns: ["ttaNumber","branchName","openingDate","trustee","bankAccNo","sortCode","telephone","email","address1","address2","city","county","postcode","country"],
    //   sortedBy: {ttaNumber:false, branchName: false, openingDate: false, trustee: false, bankAccNo: false, sortCode: false, telephone: false, email: false, address1: false, address2: false, city: false, county: false, postcode: false, country: false},
    //   onlyForTrustees: true
    // },
  ]

  // Booking Reference Filters
  filterBookingReference: string = '';
  filterClientName: string = '';
  filterBookingGroup: string = '';
  filterDepartureDate: Date | null = null;

  // Receipt Variables
  receiptData: any = new MatTableDataSource<any>();
  receiptColumns = ['receiptDate', 'totalCharge', 'paymentMethod', 'reference', 'payerRef', 'receiptStatus'];
  showReceiptList = true;
  createListOfReceiptData:any = [];

  // Members that use Legacy and Smart PRF on SinGS
  testingMembersForOneTouchPRF = ["Q2542","R5735","U8902","Q9325","Q919X","Q9700","Q1164","X8564","Q3174","Q718X","Q5362","S1234"];
  isATestingMember = false;
  createIsOneTouchBooking = 2
  preAttachOfBooking = true

  // SFC re-generate for Trustees
  sfcAvailableToReGenerate = false;

  constructor(private router: Router, private route: ActivatedRoute,
    private customerService: CustomerService, private userService: UserService, private supplierService: SupplierService, private receiptService: ReceiptService,
    private fellohService: FellohService, private session: Session, private prfService: PrfService, public dialog: MatDialog, private reportsService: ReportsService) { }

  ngOnInit(): void {
    // Login redirects user to this page. If that happens, browser needs to refresh the page
    // This is so the top-nav renders correctly
    if (Session.mySession.get('justLoggedIn') == true) {
      window.location.reload();
      Session.mySession.set('showStartPopup', false);
      Session.mySession.set('justLoggedIn', false);
    } else if (sessionStorage.length == 0 || Session.mySession == undefined) {
      sessionStorage.clear();
      this.router.navigate(['/login']);
    } else {
      // If the page has been accessed by clicking a button
      this.userName = Session.mySession.getUser().fullName;
      this.userEmail = Session.mySession.getUser().email;
      this.userType = Session.mySession.getUser().userType;
      this.loadPage();
    }
  }

  loadPage() {
    if (Session.mySession.getUser().company == 'tta' || Session.mySession.getUser().userType == 'trustee' || Session.mySession.getUser().userType == 'sinGSAdmin') {
      // Above users will have access to the page. Transaction data object needs first line (well, it doesnt but it looks better!)
      window.dispatchEvent(new Event('resize'));
      this.haveAccess = true;
      this.setSuppliersList();
      this.loadPrfData();
      this.pageLoaded = true;
    } else {
      this.pageLoaded = true;
    }
  }

  // Set's the list of suppliers for member's
  setSuppliersList() {
    if (Session.mySession.getSupplierList().expiryTime == 'EXPIRED') {
      const request = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode, token: Session.mySession.getUser().token
      };
      this.supplierService.getSupplierList(request).then((suppliers: any) => {
        if (suppliers.status != "OK") {
          this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '', '');
        } else {
          let sorted = suppliers.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); // Sort by supplier name
          sorted = sorted.filter((supplier: any) => supplier.supplierType !== 'Supplement'); // Filter out any Supplement suppliers from the array list
          sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierNameM'] === e['supplierNameM']) === i); // Group suppliers (1 of each name) here
          this.suppliersList = sorted; // Assign supplier data to the table data variable
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error, request);
      });
    } else {
      this.suppliersList = Session.mySession.getSupplierList().supplierList; // Get supplement list from the session varaible - no need to call API
    }
  }

  // Gets all PRFs from PrfService and adds the respone into the prfs array ...
  // ... then when the switchView() will be clicked to "PRF List" it will load ...
  // ... the data from prfs into the MatTableDataSource (Table).
  loadPrfData() {
    let request = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token,
      userType: Session.mySession.getUser().userType
    };
    this.prfService.getAllPrfs(request).then((allPrfs: any) => {
      if (allPrfs.status != "OK") {
        this.sendMessageToDialog("", "Error with request status: " + allPrfs.res, "", "");
      } else if (allPrfs != null && allPrfs.res != "No Prfs found") {
        allPrfs.res.forEach((prf: any) => {
          if(this.checkIfJustTrustee()){
            if(prf.status != "Ready for signing"){
              this.prfs.data.push(prf);
            } 
          } else {
            this.prfs.data.push(prf);
          }
        })
        this.prfs.paginator = this.paginatorPrfs;
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2002S)', error, request);
    });
  }

  // Used to check if the prfNumber is a 6 digit number when it's been given a value
  checkFilterInputs() {
    let regexp = new RegExp('^[0-9]{6}')
    if (this.prfNumber != "" && !regexp.test(this.prfNumber)) {
      return false
    } else {
      return true
    }
  }

  // Used when the user clicks the filter button and checks for what filtered parameters are given
  // If non then it will return all PRF's with defualt number to 20 records
  // Also whenever a user make's an update it will automatically filter it ...
  // ... So that the viewed listed PRFs are having the updated values.
  filterPrfData() {
    this.prfsSorted = false;
    if (this.checkFilterInputs() == true) {
      let request: any = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        token: Session.mySession.get('user').token,
        userType: Session.mySession.getUser().userType,
        filteredPrfs: "True",
        prfNumber: this.prfNumber,
        passengerName: this.passengerName,
        supplierName: encodeURIComponent(this.supplierName),
        prfsTradeCode: this.prfsTradeCode,
        assignedTo: this.prfsAssignedTo,
        prfsPerPage: this.prfsPerPage
      };
      if (this.prfsStatus != 0 && this.prfsStatus != "0") {
        request.prfsStatus = this.prfsStatus;
      }
      if (this.prfsPaymentOptions != "" && this.prfsPaymentOptions != null && this.prfsPaymentOptions != 0) {
        let paymentOptions = "";
        this.prfsPaymentOptions.forEach((option:any)=>{
          if (paymentOptions == ""){
            paymentOptions = option;
          } else {
            paymentOptions = paymentOptions + '%7C' + option
          }
        });       
        request.paymentOptions = paymentOptions;
      } else
      {
        request.paymentOptions = "";
      }
      if (this.prfsFrom != "" && this.prfsFrom != null){
        request.submittedFrom = this.convertDate(this.prfsFrom._d);
      } else {
        request.submittedFrom = "0"
      }
      if (this.prfsTo != "" && this.prfsTo != null){
        request.submittedTo = this.convertDate(this.prfsTo._d);
      } else {
        request.submittedTo = "0"
      }
      let filteredPrfsList = new MatTableDataSource<any>();
      this.prfService.getAllPrfs(request).then((allPrfs: any) => {
        if (allPrfs.status != "OK") {
          this.sendMessageToDialog("", "Error with request status: " + allPrfs.res, "", "");
        } else if (allPrfs != null && allPrfs.res != "No Prfs found") {
          allPrfs.res.forEach((prf: any) => {
            if(this.checkIfJustTrustee()){
              if(prf.status != "Ready for signing"){
                filteredPrfsList.data.push(prf);
              } 
            } else {
              filteredPrfsList.data.push(prf);
            }
          })
          this.prfs.data = filteredPrfsList.data;
        } else {
          this.prfs.data = [];
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2003S)', error, request);
      });
    } else {
      this.sendMessageToDialog('', "PRF should be a 6 digit number", "", "");
    }
  }

  // This one can be used in the future for any type of custom sorting
  // For now we only sort by the PRF's Amount
  sortPrfAmount(){
    const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
    let newPrfsList = new MatTableDataSource<any>();
    let sortedPrfsList:any = [];
    this.prfs.data.forEach((tempPrf:any) => {
      let overallPrfsTempAmount:any = 0;
      let prfsTempAmountOne = tempPrf.amountOne.match(NUMERIC_REGEXP);
      let prfsTempAmountTwo = 0
      if (tempPrf.amountTwo != null){
        prfsTempAmountTwo = tempPrf.amountTwo.match(NUMERIC_REGEXP);
      }
      overallPrfsTempAmount = Number(prfsTempAmountOne) + Number(prfsTempAmountTwo)
      sortedPrfsList.push({prfNumber:tempPrf.prfNumber, prfAmount:overallPrfsTempAmount, currentPrf:tempPrf});
    });
    if (this.prfsSorted == true)
    {
      this.prfsSorted = false;
      sortedPrfsList.sort(function(a:any, b:any) {
        return a.prfAmount - b.prfAmount;
      });
      sortedPrfsList.forEach((sortedPrf:any)=>{
        newPrfsList.data.push(sortedPrf.currentPrf);
      });
      this.prfs.data = newPrfsList.data;
    }
    else
    {
      this.prfsSorted = true;
      sortedPrfsList.sort(function(a:any, b:any) {
        return b.prfAmount - a.prfAmount;
      });
      sortedPrfsList.forEach((sortedPrf:any)=>{
        newPrfsList.data.push(sortedPrf.currentPrf);
      });
      this.prfs.data = newPrfsList.data;
    }
  }

  // Clears all the filters from PRF List
  clearfilters(){
    this.prfsSorted = false;
    this.filterPrfs = false;
    this.prfNumber = "";
    this.passengerName = "";
    this.supplierName = "";
    this.prfsTradeCode = "";
    this.prfsAssignedTo = "";
    this.prfsPaymentOptions = 0;
    this.prfsStatus = 0;
    this.prfsFrom = "";
    this.prfsTo = "";
    this.prfsPerPage = 20;
  }

  // Bulk edit for Trustees to change status of multiple PRFs
  bulkEdit(){
    if (this.prfsEditMode == true){
      this.prfsEditMode = false;
      this.prfsEditList = [];
    } else {
      this.prfsEditMode = true;
      this.prfsEditList = [];
    }
  }

  // This is used to select and unselect PRF's for editing
  selectedPrfTab(selectedPrfNumber:any){
    let indexOfEditPrfNumber = -1;
    let addedPrf:any = selectedPrfNumber;
    this.prfsEditList.forEach((obj:any,index:any) => {
      if(obj == addedPrf) {
        indexOfEditPrfNumber = index;
      }
    });
    if (indexOfEditPrfNumber != -1){
      this.prfsEditList.splice(indexOfEditPrfNumber, 1);
    } else {
      this.prfsEditList.push(addedPrf);
    }
  }

  // Opens the edit dialog to bulk status edit
  openEditWindow(){
    if (this.prfsEditList.length > 0){
      this.prfsEditStatus = 0;
      this.dialog.open(this.editDialog);
    } else {
      this.sendMessageToDialog("","You need to select at least one PRF","","");
    }
  }
  
  // Checks if a new status is selected and then start's the bulk update process
  updatePrfsStatus() {
    if (this.prfsEditStatus != 0 && this.prfsEditStatus != "0"){
      this.prfsEditMode = false;
      this.callSignMultiplePrfs();
    } else {
      this.sendMessageToDialog("","You need to select a status from the 'Status List'","","");
    }
  }

  // New version of PRF's Bulk Edit (Sign Multiple PRFs - Trustee(s)/Ex(s) feature) 
  // For Trustees the status list include "Processing" and "Authorised"
  // For Trustee Ex includes ...
  // BackEnd route /signMultiplePrfs
  callSignMultiplePrfs(){
    this.pageLoaded = false;
    let request: any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token,
      status: this.prfsEditStatus,
      listOfPrfNumbers: this.prfsEditList,
      trusteeName: this.userName,
      hideDialog: true
    };
    this.prfService.signMultiplePrfs(request).then((output: any) => {
      if (output["status"] == "OK"){
        Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
          this.sendMessageToDialog('Editing PRFs in progress. Please wait..','','',request);
          Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
            AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
            this.checkIfJobIsDone().then((res:any)=>{
              this.filterPrfData();
              this.dialog.closeAll();
            })
          })
        })
      } else {
        this.sendMessageToDialog('', 'Editing multiple PRFS failed (' + output.status + ')', output,request);
      }
      this.pageLoaded = true;
    }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1501S)', error, request);
    });
  }

  async checkIfJobIsDone(): Promise<boolean> {
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    let isDone = false; 
    while (!isDone) {
        let tempCurrentJobs: any[] = Session.mySession.get('currentJobs');
        if (tempCurrentJobs.length === 0) {
            isDone = true;
        } else {
          await delay(1000);
        }
    }
    return true;
  }

  // This will first check the currenlty logged in user and then
  // check if the status of the returned PRF is "Ready to signing"
  // -) If it's a Trustee it will allow access no matter what and it will
  //    sign the PRF's status to Processing if it's "Ready for signing".
  // -) If it'as a Member it will check if the status is "Ready for signing"
  //    before giving acces to them. If not it will alert them with the updated status.
  checkUserAccess(tempStatus: String, tempPrfNumber: String, tempAssignedTo: String) {
    if ((Session.mySession.getUser().userType == "trustee" || Session.mySession.getUser().userType == "sinGSAdmin")) {
      // Initial view of Trustee
      if (tempStatus == "Ready for signing" && Session.mySession.getUser().userType == "trustee") {       
        let signRequest = {
          prfNumber: tempPrfNumber,
          status: "Processing",
          assignedTo: Session.mySession.getUser().fullName,
          clientFundsBanked: this.viewPrfClientFundsBanked,
          thirdPartyPaperwork: this.viewPrfThirdPartyPaperwork,
          memberAccountBalanceAmount: this.viewPrfMemberAccountBalanceAmount,
          memberAccountBalanceCurrency: this.viewPrfMemberAccountBalanceCurrency,
          comCheckForAtol: this.viewPrfComCheckForAtol,
          comCheckForPsa: this.viewPrfComCheckForPsa,
          comCheckForClientsReturned: this.viewPrfComCheckForClientsReturned,
          comCheckForSfc: this.viewPrfComCheckForSfc,
          actionRequiredMessage: this.viewPrfActionRequiredMessage,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          company: Session.mySession.getUser().company,
          token: Session.mySession.get('user').token
        }
        let auditRequest = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          token: Session.mySession.get('user').token,
          prfNumber: tempPrfNumber,
          status: 'Processing',
          trusteeName: this.userName,
          assignedTo: signRequest.assignedTo,
          officeNotes: 'PRF status updated to Processing'
        }
        this.signPrf(signRequest).then((signRes:any)=>{
          console.log(signRes);
          this.createPrfsAudit(auditRequest,false).then((auditRes:any)=>{
            console.log(auditRes);
          });
        });
        return "Processing";
      } 
      return true;
    } else {
      if (tempStatus == "Not Submitted") {
        return true;
      } else if (tempStatus == "Action Required" && tempAssignedTo == this.userName) {
        return true;
      } else if (tempStatus == "Action Required" && tempAssignedTo == "All") {
        return true;
      } else if (tempStatus == "Ready for signing" && this.checkIfManager()){
        return true;
      }
      else {
        return false;
      }
    }
  }

  // If it's a Trustee it will reveal all the necessary checkboxes in the PRF
  checkUserType() {
    if (Session.mySession.getUser().userType == "trustee") {
      return true;
    } else {
      return false;
    }
  }

  // If it's a Trustee it will reveal all the necessary buttons (Delete) in the PRF
  checkIfSingsAdmin(){
    if (Session.mySession.getUser().userType == "sinGSAdmin") {
      return true;
    } else {
      return false;
    }
  }

  // If it's a Manager, he can submit the PRF (Ready for signing)
  // If it's not then he is a Staff, he can only send PRF to Managers (Not Submitted)
  checkIfManager(){
    if (Session.mySession.getUser().userType == "memberManager" || Session.mySession.getUser().userType == "wcManager") {
      return true;
    } else{
      return false;
    }
  }

  // If the PRF is "Not Submitted" the they can still save it
  // If not then it needs to be Signed off back to the Trustees
  checkIfSaveOption(type:any){
    if (type == "View"){
      if (this.viewPrfStatus == "Not Submitted") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // Used to set the list of status that a trustee can change a PRF
  checkIfExecutive() {
    if ((this.userName == 'Steve Button' || this.userName == 'Ryan Cherrington' || this.userName == 'Faaiq Malik' || this.userName == 'Ashley Harvey') && Session.mySession.getUser().userType == "trustee") {
      return true;
    } else {
      return false;
    }
  }

  checkIfJustTrustee(){
    if(!this.checkIfExecutive() && Session.mySession.getUser().userType == "trustee"){
      return true;
    } else {
      return false
    }
  }

  // Status Icon Check
  checkStatusIcon(givenStatus:any){
    if (givenStatus) {
      if (givenStatus == 'Action Required'){
        return "Action Required";
      } else if (givenStatus == 'Processing') {
        return  "Processing";
      } else if (givenStatus == 'Not Submitted') {
        return  "Not Submitted";
      } else if (givenStatus == 'Awaiting return date') {
        return "Awaiting return date";
      } else if (givenStatus == 'Awaiting cleared fund') {
        return "Awaiting cleared fund";
      } else if (givenStatus == 'Ready for signing') {
        return "Ready for signing";
      } else if (givenStatus == 'Authorisation Pending') {
        return "Authorisation Pending";
      } else if (givenStatus == 'Authorised') {
        return "Authorised";
      } else if (givenStatus == 'Cancelled') {
        return "Cancelled";
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Returns SUP or COM or REI or CR or null
  getClaimAcronym(givenClaim:any){
    if (givenClaim == "Supplier Payment"){
      return "SUP"
    }
    if (givenClaim == "Commission"){
      return "COM"
    }
    if (givenClaim == "Reimbursement"){
      return "REI"
    }
    if (givenClaim == "Client Refund"){
      return "CR"
    }
    return null;
  }

  // This is the main function that will set the currently viewed PRF,
  // When you click the "View" button it will go here to set it up from the
  // prfBreakdown array which holds all the currently viewed PRFs.
  // Then it checks for all the documents that are attached to that PRF.
  // Also, checks if some of the attributes are null or not
  // * Only the ones who can be :
  // { financialProtection, clientReturnDate, clientRefund, reimbursement ...
  // ... guaranteeDeducted, commission, supplierAmount, collectedFrom }
  setViewPrfData(prfIndex: any) {
    this.viewPrfBranchName = this.prfBreakdown[prfIndex][0]["branchName"];   
    this.viewPrfNumber = this.prfBreakdown[prfIndex][0]["prfNumber"];   
    this.viewPrfAssignedTo = this.prfBreakdown[prfIndex][0]["assignedTo"];
    this.viewPrfDateSubmitted = this.prfBreakdown[prfIndex][0]["dateSubmitted"];
    this.viewPrfSupplierName = this.prfBreakdown[prfIndex][0]["supplierName"];
    this.viewPrfPassengerName = this.prfBreakdown[prfIndex][0]["passengerName"];
    this.viewPrfStatus = this.prfBreakdown[prfIndex][0]["status"];
    this.viewPrfAmountOne = this.prfBreakdown[prfIndex][0]["amountOne"];
    if (this.prfBreakdown[prfIndex][0]["amountOneTrustee"] != null){
      this.viewPrfAmountOneTrustee = this.prfBreakdown[prfIndex][0]["amountOneTrustee"];
    } else {
      this.viewPrfAmountOneTrustee = this.prfBreakdown[prfIndex][0]["amountOne"];
    }

    if (this.prfBreakdown[prfIndex][0]["currencyOneTrustee"] != null){
      this.viewPrfCurrencyOneTrustee = this.prfBreakdown[prfIndex][0]["currencyOneTrustee"];
    } else {
      this.viewPrfCurrencyOneTrustee = this.prfBreakdown[prfIndex][0]["currencyOne"];
    }

    if (this.prfBreakdown[prfIndex][0]["currencyTwoTrustee"] != null){
      this.viewPrfCurrencyTwoTrustee = this.prfBreakdown[prfIndex][0]["currencyTwoTrustee"];
    } else {
      this.viewPrfCurrencyTwoTrustee = this.prfBreakdown[prfIndex][0]["currencyTwo"];
    }

    this.viewPrfCurrencyOne = this.prfBreakdown[prfIndex][0]["currencyOne"];
    this.viewPrfClaimOne = this.prfBreakdown[prfIndex][0]["claimOne"];
    this.viewPrfPaymentMethodOne = this.prfBreakdown[prfIndex][0]["paymentMethodOne"];
    this.viewPrfAmountTwo = this.prfBreakdown[prfIndex][0]["amountTwo"];
    if (this.prfBreakdown[prfIndex][0]["amountTwoTrustee"] != null){
      this.viewPrfAmountTwoTrustee = this.prfBreakdown[prfIndex][0]["amountTwoTrustee"];
    } else {
      this.viewPrfAmountTwoTrustee = this.prfBreakdown[prfIndex][0]["amountTwo"];
    }
    this.viewPrfCurrencyTwo = this.prfBreakdown[prfIndex][0]["currencyTwo"];
    this.viewPrfClaimTwo = this.prfBreakdown[prfIndex][0]["claimTwo"];
    this.viewPrfPaymentMethodTwo = this.prfBreakdown[prfIndex][0]["paymentMethodTwo"];
    this.viewPrfClientFundsBanked = this.prfBreakdown[prfIndex][0]["clientFundsBanked"];
    this.viewPrfThirdPartyPaperwork = this.prfBreakdown[prfIndex][0]["thirdPartyPaperwork"];
    this.viewPrfMemberAccountBalanceAmount = this.prfBreakdown[prfIndex][0]["memberAccountBalanceAmount"];
    this.viewPrfMemberAccountBalanceCurrency = this.prfBreakdown[prfIndex][0]["memberAccountBalanceCurrency"];
    this.viewPrfComCheckForAtol = this.prfBreakdown[prfIndex][0]["comCheckForAtol"];
    this.viewPrfComCheckForPsa = this.prfBreakdown[prfIndex][0]["comCheckForPsa"];
    this.viewPrfComCheckForClientsReturned = this.prfBreakdown[prfIndex][0]["comCheckForClientsReturned"];
    this.viewPrfComCheckForSfc = this.prfBreakdown[prfIndex][0]["comCheckForSfc"];
    this.viewPrfActionRequiredMessage = this.prfBreakdown[prfIndex][0]["actionRequiredMessage"];
    this.viewPrfIsClientFundsReceived = this.prfBreakdown[prfIndex][0]["isClientFundsReceived"] ? 1 : 0;
    this.viewPrfHasClientReturned = this.prfBreakdown[prfIndex][0]["hasClientReturned"] ? 1 : 0;
    if (this.prfBreakdown[prfIndex][0]["financialProtection"] != null && this.prfBreakdown[prfIndex][0]["financialProtection"] != ""){
      const givenFP = this.prfBreakdown[prfIndex][0]["financialProtection"];
      this.viewPrfFinancialProtection.methods.forEach((method,index)=>{
        if (givenFP.indexOf(method) !== -1) {
          this.viewPrfFinancialProtection.checks[index] = true
        } 
      })
    }
    // this.viewPrfFinancialProtection = this.prfBreakdown[prfIndex][0]["financialProtection"];
    this.viewPrfNotesToTrustee = this.prfBreakdown[prfIndex][0]["notesToTrustee"];
    this.viewPrfCollectedFrom = this.prfBreakdown[prfIndex][0]["collectedFrom"];
    this.viewPrfCompany = this.prfBreakdown[prfIndex][0]["company"].toUpperCase();
    this.viewPrfOperation = this.prfBreakdown[prfIndex][0]["operation"];
    this.viewPrfTradeCode = this.prfBreakdown[prfIndex][0]["tradeCode"];
    this.viewPrfCreator = this.prfBreakdown[prfIndex][0]["prfCreator"];
    this.viewPrfCreatorEmail = this.prfBreakdown[prfIndex][0]["prfCreatorEmail"];
    this.viewPrfBookingReference = this.prfBreakdown[prfIndex][0]["bookingReference"];


    if (this.viewPrfBookingReference != null){
      if (this.viewPrfBookingReference == "Multiple"){
        this.viewMultipleBookingReference = true;
      } else {
        this.viewMultipleBookingReference = false;
      }
    }

    // Financial Protection Check
    if (this.viewPrfHasClientReturned == 0) {
      this.viewPrfFinancialProtectionEnable = true;
    }

    // None Supplier Check
    if (this.viewPrfClaimOne == "Commission" && this.viewPrfClaimTwo == null){
      this.viewPrfSupplierNoneIsAvailable = true;
    } else {
      this.viewPrfSupplierNoneIsAvailable = false;
    }

    // Supplier Payment Checks
    if (this.viewPrfClaimOne == "Supplier Payment"){
      this.viewNotMatchingSuppliers = false;
      this.viewClaimSupplierPayment.checked = true;
      let tempPaymentMethods = String(this.viewPrfPaymentMethodOne);
      if (tempPaymentMethods.includes("/")){
        tempPaymentMethods.split("/").forEach((method)=>{
          if (method == "Natwest Faster")
          {
            this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
          } else if(method == "Natwest CHAPS") {
            this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
          } else if(method == "Barclays") {
            this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
          } else if(method == "Corpay") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Monteith") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Amex Forex Transfer") {
            this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
          } else if(method == "Other") {
            this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
          }
        });
      } else {
        if (tempPaymentMethods == "Natwest Faster")
        {
          this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
        } else if(tempPaymentMethods == "Natwest CHAPS") {
          this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
        } else if(tempPaymentMethods == "Barclays") {
          this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
        } else if(tempPaymentMethods == "Corpay") {
          this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
        } else if(tempPaymentMethods == "Monteith") {
          this.viewClaimSupplierPayment.paymentMethods.checks[4] = true;
        } else if(tempPaymentMethods == "Amex Forex Transfer") {
          this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
        } else if(tempPaymentMethods == "Other") {
          this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
        }
      }      
    }
    if (this.viewPrfClaimTwo == "Supplier Payment"){
      this.viewNotMatchingSuppliers = false;
      this.viewClaimSupplierPayment.checked = true;
      let tempPaymentMethods = String(this.viewPrfPaymentMethodTwo);
      if (tempPaymentMethods.includes("/")){
        tempPaymentMethods.split("/").forEach((method)=>{
          if (method == "Natwest Faster")
          {
            this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
          } else if(method == "Natwest CHAPS") {
            this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
          } else if(method == "Barclays") {
            this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
          } else if(method == "Corpay") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Monteith") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Amex Forex Transfer") {
            this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
          } else if(method == "Other") {
            this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
          }
        });
      } else {
        if (tempPaymentMethods == "Natwest Faster")
        {
          this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
        } else if(tempPaymentMethods == "Natwest CHAPS") {
          this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
        } else if(tempPaymentMethods == "Barclays") {
          this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
        } else if(tempPaymentMethods == "Corpay") {
          this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
        } else if(tempPaymentMethods == "Monteith") {
          this.viewClaimSupplierPayment.paymentMethods.checks[4] = true;
        } else if(tempPaymentMethods == "Amex Forex Transfer") {
          this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
        } else if(tempPaymentMethods == "Other") {
          this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
        }
      }      
    }

    // Commission Checks
    if (this.viewPrfClaimOne == "Commission"){
      this.viewClaimCommission.checked = true;
      this.viewClaimCommission.paymentMethod = String(this.viewPrfPaymentMethodOne);
    }
    if (this.viewPrfClaimTwo == "Commission"){
      this.viewClaimCommission.checked = true;
      this.viewClaimCommission.paymentMethod = String(this.viewPrfPaymentMethodTwo);
    }

    // Reimbursement Checks
    if (this.viewPrfClaimOne == "Reimbursement"){
      this.viewClaimReimbursement.checked = true;
      this.viewClaimReimbursement.paymentMethod = String(this.viewPrfPaymentMethodOne);
    }
    if (this.viewPrfClaimTwo == "Reimbursement"){
      this.viewClaimReimbursement.checked = true;
      this.viewClaimReimbursement.paymentMethod = String(this.viewPrfPaymentMethodTwo);
    }

    // Client Refund Checks
    if (this.viewPrfClaimOne == "Client Refund"){
      this.viewClaimClientRefund.checked = true;
      this.viewClaimClientRefund.paymentMethod = String(this.viewPrfPaymentMethodOne);
    }
    if (this.viewPrfClaimTwo == "Client Refund"){
      this.viewClaimClientRefund.checked = true;
      this.viewClaimClientRefund.paymentMethod = String(this.viewPrfPaymentMethodTwo);
    }

    // Client Return Date Check
    if (this.viewPrfFinancialProtection.checks[0] == true && this.viewClaimCommission.checked == false && this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false  && this.viewClaimClientRefund.checked == true) {
      this.viewPrfClientReturnDateEnable = false;
    } else {
      if (this.viewPrfFinancialProtectionEnable == true && this.viewPrfFinancialProtection.checks[0] == true){
        this.viewPrfClientReturnDateEnable = true;
        if (this.prfBreakdown[prfIndex][0]["clientReturnDate"] != null) {
          this.viewPrfClientReturnDate = new Date(this.prfBreakdown[prfIndex][0]["clientReturnDate"]);
        } else {
          this.viewPrfClientReturnDate = new Date();
        }
      }
    }

    // Setup PRF's Transaction Values & Booking Reference (If not null)
    let transactionsRequest:any = {
      company: Session.mySession.getUser().company, 
      operation: Session.mySession.getUser().operation, 
      tradeCode: Session.mySession.getUser().tradeCode,  
      token: Session.mySession.get('user').token,
      prfNumber: this.viewPrfNumber
    }
    this.reloadPrfsTransactionValues(transactionsRequest,this.viewPrfBookingReference,true);

    // Setup PRF's Audit
    if (this.checkUserType()){
      let auditRequest:any = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        token: Session.mySession.get('user').token,
        prfNumber: this.viewPrfNumber
      }
      this.reloadPrfsAuditList(auditRequest);
    }
    
    // Setup PRF's Supporting Documentation
    this.reloadS3prfDocs(true);
    
    // Make all PRF's forms available for view
    this.firstViewCompleted = true;
    this.thirdViewCompleted = true;
    this.exTransactionViewChanged = true;

    // Check if not Trustee to enable 'Online Payment' uploads
    if (this.firstViewCompleted == true && !this.checkUserType()){
      this.setupListOfPaymentMethodUploads()
    }
  }

  // Calculate Overall Commission (Create)
  calculateCreateCommissionTotal(): number  {
    let overallCommission: number = 0.00;
    for (const item of this.createTransactionValuesList) {
      if (item.createCommission.amount != null && !Number.isNaN(item.createCommission.amount)) {
        overallCommission = Number(overallCommission) + Number(item.createCommission.amount);
      }
    }
    return Number(overallCommission.toFixed(2));
  }

  // Calculate Overall Reimbursement (Create)
  calculateCreateReimbursementTotal(): number {
    let overallReimbursement: number = 0.00;
    for (const item of this.createTransactionValuesList) {
      if (item.createReimbursement.amount != null) {
        overallReimbursement = Number(overallReimbursement) + Number(item.createReimbursement.amount);
      }
    }
    return Number(overallReimbursement.toFixed(2));
  }

  // Calculate Overall Client  Refund (Create)
  calculateCreateClientRefundTotal(): number {
    let overallClientRefund: number = 0.00;
    for (const item of this.createTransactionValuesList) {
      if (item.createClientRefund.amount != null) {
        overallClientRefund = Number(overallClientRefund) + Number(item.createClientRefund.amount);
      }
    }
    return Number(overallClientRefund.toFixed(2));
  }

  // Calculate Overall Supplier Amount (Create)
  calculateCreateSupplierAmountTotal(): number {
    let overallSupplierAmount: number = 0.00;
    for (const item of this.createTransactionValuesList) {
      if (item.createSupplierAmount.amount != null) {
        overallSupplierAmount = Number(overallSupplierAmount) + Number(item.createSupplierAmount.amount);
      }
    }
    return Number(overallSupplierAmount.toFixed(2));
  }

  // Calculate Overall Commission (View)
  calculateViewCommissionTotal(): number  {
    let overallCommission: number = 0.00;
    for (const item of this.viewPrfTransactionValuesList) {
      if (item.viewCommission.amount != null && !Number.isNaN(item.viewCommission.amount)) {
        overallCommission = Number(overallCommission) + Number(item.viewCommission.amount);
      }
    }
    for (const item of this.viewPrfAddedTransactionValuesList) {
      if (item.viewCommission.amount != null && !Number.isNaN(item.viewCommission.amount)) {
        overallCommission = Number(overallCommission) + Number(item.viewCommission.amount);
      }
    }
    return Number(overallCommission.toFixed(2));
  }

  // Calculate Overall Reimbursement (View)
  calculateViewReimbursementTotal(): number {
    let overallReimbursement: number = 0.00;
    for (const item of this.viewPrfTransactionValuesList) {
      if (item.viewReimbursement.amount != null) {
        overallReimbursement = Number(overallReimbursement) + Number(item.viewReimbursement.amount);
      }
    }
    for (const item of this.viewPrfAddedTransactionValuesList) {
      if (item.viewReimbursement.amount != null) {
        overallReimbursement = Number(overallReimbursement) + Number(item.viewReimbursement.amount);
      }
    }
    return Number(overallReimbursement.toFixed(2));
  }

  // Calculate Overall Client  Refund (View)
  calculateViewClientRefundTotal(): number {
    let overallClientRefund: number = 0.00;
    for (const item of this.viewPrfTransactionValuesList) {
      if (item.viewClientRefund.amount != null) {
        overallClientRefund = Number(overallClientRefund) + Number(item.viewClientRefund.amount);
      }
    }
    for (const item of this.viewPrfAddedTransactionValuesList) {
      if (item.viewClientRefund.amount != null) {
        overallClientRefund = Number(overallClientRefund) + Number(item.viewClientRefund.amount);
      }
    }
    return Number(overallClientRefund.toFixed(2));
  }

  // Calculate Overall Supplier Amount (View)
  calculateViewSupplierAmountTotal(): number {
    let overallSupplierAmount: number = 0.00;
    for (const item of this.viewPrfTransactionValuesList) {
      if (item.viewSupplierAmount.amount != null) {
        overallSupplierAmount = Number(overallSupplierAmount) + Number(item.viewSupplierAmount.amount);
      }
    }
    for (const item of this.viewPrfAddedTransactionValuesList) {
      if (item.viewSupplierAmount.amount != null) {
        overallSupplierAmount = Number(overallSupplierAmount) + Number(item.viewSupplierAmount.amount);
      }
    }
    return Number(overallSupplierAmount.toFixed(2));
  }

  // Filters the listOfAvailableBookings based on what was fill in by the filters
  // Also if previously selected any Bookings then it will keep them in the filtered list
  applyBookingReferenceListFilters() {
    // Initialise the list with all the given bookings
    this.listOfAvailableBookings = this.originalListOfAvailableBookings;

    // Check for already selected Bookings to keep in another list tempListOfAlreadySelectedBookings = []
    let tempListOfAlreadySelectedBookings:any = ""
    if (this.createBookingReference != ""){
      tempListOfAlreadySelectedBookings = this.originalListOfAvailableBookings;
      tempListOfAlreadySelectedBookings = tempListOfAlreadySelectedBookings.filter((booking:any) => {
        if (booking.bookingReference && this.createBookingReference.toString().includes(booking.bookingReference))
        {
          return booking;
        } 
      })
    }

    // Begin filtering process
    if (this.filterBookingReference == "" && this.filterClientName == "" && this.filterDepartureDate == null && this.filterBookingGroup == ""){
      this.clearBookingReferenceListFilters();
      this.sendMessageToDialog("","Please fill in at least one filter before searching","","")
    } else {
      this.listOfAvailableBookings = this.listOfAvailableBookings.filter((booking:any) => {
        let tempBookingPass = true;

        // Filter check by Booking Reference
        if (this.filterBookingReference != ""){
          if (booking.bookingReference && this.filterBookingReference && !booking.bookingReference.toLowerCase().includes(this.filterBookingReference.toLowerCase()))
          {
            if (booking.externalBooking == true){
              if (booking.extBookingSource && !booking.extBookingSource.toLowerCase().includes(this.filterBookingReference.toLowerCase())){
                tempBookingPass = false;
              }
            } else {
              tempBookingPass = false;
            }
          }
        }

        // Filter check by Lead Client Name 
        if (this.filterClientName != "") {
          if (booking.leadName == null){
            tempBookingPass = false;
          } else {
            if (booking.leadName && this.filterClientName && !booking.leadName.toLowerCase().includes(this.filterClientName.toLowerCase()))
            {
              tempBookingPass = false;
            } 
          }
        }

        // Filter check by Booking Group Name
        if (this.filterBookingGroup != "") {
          if (booking.bookingGroup == null){
            tempBookingPass = false;
          } else {
            if (booking.bookingGroup && this.filterBookingGroup && !booking.bookingGroup.toLowerCase().includes(this.filterBookingGroup.toLowerCase()))
            {
              tempBookingPass = false;
            }
          }
        }

        // Filter check by Departure Date
        if (this.filterDepartureDate != null){
          if (booking.deptDate != null && booking.deptDate != ""){
            let tempDeptDate = new Date(booking.deptDate)
            let tempFilterDate = new Date(this.filterDepartureDate)
            if (tempDeptDate.toLocaleDateString() != tempFilterDate.toLocaleDateString()){
              tempBookingPass = false;
            }
          } else {
            tempBookingPass = false;
          }
        }

        // Final check to see if it passes all the given filters
        if (tempBookingPass == true){
          return booking;
        }

      });

      // Add previously selected Bookings if any booking was selected
      if (tempListOfAlreadySelectedBookings != ""){
        // Create a references of already filtered bookings list
        let tempFilteredListOfBookingReferences = new Set(this.listOfAvailableBookings);
        // Check if any of the tempListOfAlreadySelectedBookings are not in tempFilteredListOfBookingReferences
        const previouslySelectedBookings = tempListOfAlreadySelectedBookings.filter((selectedBooking: any) => {
          return !tempFilteredListOfBookingReferences.has(selectedBooking);
        });
        // Add the new selected bookings to listOfAvailableBookings
        this.listOfAvailableBookings = [...this.listOfAvailableBookings, ...previouslySelectedBookings];
      }
    }
  }

  // Reset the listOfAvailable Bookings (all back in) and clears the filters values
  clearBookingReferenceListFilters() {
    this.filterBookingReference = "";
    this.filterClientName = "";
    this.filterBookingGroup = "";
    this.filterDepartureDate = null;
    this.listOfAvailableBookings = this.originalListOfAvailableBookings;
  }
  
  // TBD - Sorting? Maybe last 30 days or some filtering to load less?
  searchForCreateBookingRef() {
    if (this.createBookingReference == "" && this.createSelectedSuggestions.length == 0){
      this.sendMessageToDialog("","Please fill in the 'Booking Reference' input box","","");
    } else {
      let aBookingReference = ""
      let finalListOfBookings:any = ""
      let listOfBookings = this.createBookingReference.toString();
      // Prepared the aBookingReference
      if (this.createBookingReference != ""){
        aBookingReference = this.createBookingReference[0]
      } else {
        aBookingReference = this.createSelectedSuggestions[0]
      }
      // Prepare the listOfBookings
      if (this.createSelectedSuggestions.length > 0){
        if (listOfBookings == "") {
          listOfBookings = this.createSelectedSuggestions.toString();
        } else {
          listOfBookings = listOfBookings + "," + this.createSelectedSuggestions.toString();
        }
      }
      // Check if duplicates and prepare the finalListOfBookings
      if (listOfBookings.length > 12){
        const originalList = listOfBookings.split(',')
        const uniqueList = new Set(originalList);  
        const uniqueValuesArray = Array.from(uniqueList);
        finalListOfBookings = uniqueValuesArray.join(',');
      } else {
        finalListOfBookings = listOfBookings
      }
      // Final check to see if Single or Multiple Booking PRF
      if (finalListOfBookings.length > 12){
        this.getCreateMultipleBookingRefForPrf(aBookingReference,finalListOfBookings);
      } else {
        this.getCreateBookingRefForPrf(aBookingReference);
      }
    }
  }

  // In 'CREATE PRF' page,
  // If a valid (exists) and mathced (*with tradeCode and bookingStatus) booking is found
  // Then it will attach it to the PRF and receives all the relevant information
  // Else return invalid match message
  getCreateMultipleBookingRefForPrf(aBookingReference:any, listOfBookings:any){
    this.pageLoaded = false;
    let request: any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      prfTradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      bookingReference: aBookingReference,
      listOfBookingReference: listOfBookings,
      token: Session.mySession.get('user').token,
    };
    this.prfService.getMultipleBookingRefForCreatePrf(request).then((res: any) => {
      if (res["status"] == "OK"){
        this.loadCreateMultipleBookingData(res["res"],listOfBookings)
      } else {
        this.createBookingReference = "";
        this.createMatchedBookingReference = "";
        this.sendMessageToDialog('',res["status"], "", "");
      }
      this.checkOnlinePrfInformation();
      this.pageLoaded = true;
    }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2013S)', error, request);
    });
  }

  // In 'CREATE PRF' page,
  // Initial process of matching a 'Booking' to a PRF (*executes whenever a valid Booking is attached to PRF)
  loadCreateMultipleBookingData(result:any,listOfBookings:any){
    // Clear all PRF's Data
    let tempListOfMatchedBookingReference = listOfBookings.split(",");
    this.clearCreatePrf();
    this.preAttachOfBooking = false;
    
    // Add Matched Booking References
    this.createMultipleBookingReference = true;
    this.createMatchedBookingData = result;
    this.createMatchedBookingReference = tempListOfMatchedBookingReference;

    // Load Booking Data
    this.reLoadMultipleCreateBookingData(true);
  }

  // In 'CREATE PRF' page,
  // When executed at first time is confirmed otherwire needs to check 
  // Loads the original data from the Bookings not the elements of any supplier
  reLoadMultipleCreateBookingData(firstTime:Boolean){
    let continueWithIt = false;
    if (firstTime == true){
      continueWithIt = true;
    } else {
      if (confirm("Are you sure you want to reload the added booking data to the PRF?\n(This will refresh all PRF's current information)")) {
        continueWithIt = true;
      }
    }
    if (continueWithIt == true) {

      let bookingCounter = 0;
      let supplierCount = new Map<string, number>();
      let matchedSuppliers = new Set<any>();

      this.createMatchedBookingData.forEach((tempBooking:any) => {

          //
          // ONLINE PRF INFORMATION BELOW
          //

          if (tempBooking.listOfSuppliers.length > 0){
            tempBooking.listOfSuppliers.forEach((tempSupplier:any)=>{
              if (supplierCount.has(tempSupplier)){
                supplierCount.set(tempSupplier, supplierCount.get(tempSupplier)! + 1);
              } else {
                supplierCount.set(tempSupplier, 1);
              }
            })
          }
      
          //
          // ONLINE PRF INFORMATION ABOVE
          //

          //
          // SUPPORTING DOCUMENTATION BELOW
          //

          tempBooking.listOfDocumentsData = new MatTableDataSource<any>();
          if (tempBooking.listOfDocuments.contents !== undefined) {
            tempBooking.listOfDocuments.contents = tempBooking.listOfDocuments.contents.sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
            tempBooking.listOfDocumentsData.data = tempBooking.listOfDocuments.contents; 
            tempBooking.listOfDocumentsData.data.forEach((file: any) => {
              file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
            });
          }  

          //
          // SUPPORTING DOCUMENTATION ABOVE
          //

          //
          // PRF TRANSACTION VALUES BELOW 
          //

          // Initialise the Transaction Value Record of each Booking
          this.createTransactionValuesList[bookingCounter] = {
            createBookingReference: "",
            createClientSurname: "", 
            createDateFundsBanked: null, 
            createPaymentMethod: "",
            createGrossClientFunds: { currency: "", amount: null }, 
            createGuaranteeDeducted: { currency: "", amount: null },
            createVAT: 0.00,
            createSupplierAmount: { currency: "", amount: null }, 
            createCommission: { currency: "", amount: null },
            createClientRefund: { currency: "", amount: null }, 
            createReimbursement: { currency: "", amount: null },
            createListOfSurnames: [],
            createBookingData: null,
            createReceiptData: new MatTableDataSource<any>(),
            createShowReceipts: false
          }

          // Fill in the createBookingReference
          this.createTransactionValuesList[bookingCounter].createBookingReference = tempBooking.bookingReference;

          // Fill in the createBookingData for future use
          this.createTransactionValuesList[bookingCounter].createBookingData = tempBooking.bookingData

          // Fill in createListOfSurnames
          if (tempBooking.customerData.length > 0){
            tempBooking.customerData.forEach((customerData:any)=>{
              if (customerData["isLead"] == true){
                this.createTransactionValuesList[bookingCounter].createListOfSurnames.push({lastName:customerData["customer"].lastName, lead:true});
              } else {
                this.createTransactionValuesList[bookingCounter].createListOfSurnames.push({lastName:customerData["customer"].lastName, lead:false});
              }
            });
          }
          if (this.createTransactionValuesList[bookingCounter].createListOfSurnames.length > 0){
            this.createTransactionValuesList[bookingCounter].createListOfSurnames.forEach((tempCustomer:any)=>{
              if (tempCustomer.lead == true){
                this.createTransactionValuesList[bookingCounter].createClientSurname = tempCustomer.lastName;
              } else {
                if (this.createTransactionValuesList[bookingCounter].createClientSurname == "") {
                  this.createTransactionValuesList[bookingCounter].createClientSurname = tempCustomer.lastName;
                }
              }
            })
          }

          // Load Date funds banked - Booking Date
          this.createTransactionValuesList[bookingCounter].createDateFundsBanked = tempBooking.bookingData.bookingData.bookingDate;
          if (tempBooking.receiptData.receiptList.length > 0){
            this.createTransactionValuesList[bookingCounter].createReceiptData = new MatTableDataSource<any>();
            let receiptDate:any = new Date(tempBooking.receiptData.receiptList[0].receiptDate)
            if (receiptDate) {
              this.createTransactionValuesList[bookingCounter].createDateFundsBanked = receiptDate;
            }
            // Load Receipt
            this.createTransactionValuesList[bookingCounter].createReceiptData.data = tempBooking.receiptData.receiptList;
            this.createTransactionValuesList[bookingCounter].createReceiptData.data.forEach((receipt: any) => {
              const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
              let value = '£0';
              if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
              receipt.freeText = 'Date: ' + receiptDate + '\n' +
                'Method: ' + receipt.paymentMethod + '\n' +
                'Value: ' + value;
            });
          }

          // Load Payment Method - Usually a Card
          this.createTransactionValuesList[bookingCounter].createPaymentMethod = "Card";

          // Load Gross Client Funds - from createMatchedBookingData [gross]
          this.createTransactionValuesList[bookingCounter].createGrossClientFunds.currency = "GBP";
          this.createTransactionValuesList[bookingCounter].createGrossClientFunds.amount = tempBooking.bookingData.bookingData.gross

          // Load Guarantee Deducted 
          this.createTransactionValuesList[bookingCounter].createGuaranteeDeducted.currency = "GBP"

          // Load Tax - from createMatchedBookingData [tax]
          this.createTransactionValuesList[bookingCounter].createVAT = tempBooking.bookingData.bookingData.tax;

          // Load Supplier Amount - from createMatchedBookingData [net]
          this.createTransactionValuesList[bookingCounter].createSupplierAmount.currency = "GBP"
          this.createTransactionValuesList[bookingCounter].createSupplierAmount.amount = tempBooking.bookingData.bookingData.net;

          // Load Reimbursement - Just Currency
          this.createTransactionValuesList[bookingCounter].createReimbursement.currency = "GBP"

          // Load Commission -  Just Currency
          this.createTransactionValuesList[bookingCounter].createCommission.currency = "GBP"


          //
          // PRF TRANSACTION VALUES ABOVE 
          //

          bookingCounter +=1;
        
      });

      // Setup of Suppliers List
      supplierCount.forEach((count, supplierName) => {
        if (count === bookingCounter) {
            matchedSuppliers.add({ supplierNameM: supplierName });
        }
      });
      if (matchedSuppliers.size > 0){
        this.createNotMatchingSuppliers = false;
        this.suppliersList = matchedSuppliers;
      } else {
        this.createSupplierNoneIsAvailable = true;
        this.createSupplierName = 'None'
        this.createNotMatchingSuppliers = true;
        this.suppliersList = [];
      }

      // guarantee deducted or immediately calculate commission ()
      if (this.createClaimCommission.checked == true){
        this.checkGuaranteeDeducted()
      } else {
        this.checkTransactionValues(false)
      }
      
    }
  }

  // In 'CREATE PRF' page,
  // If a valid (exists) and mathced (*with tradeCode and bookingStatus) booking is found
  // Then it will attach it to the PRF and receives all the relevant information
  // Else return invalid match message
  getCreateBookingRefForPrf(aBookingReference:any){
    this.pageLoaded = false;
    let request: any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      bookingReference: aBookingReference,
      token: Session.mySession.get('user').token,
    };
    this.prfService.getBookingRefForPrf(request).then((res: any) => {
      if (res["status"] == "OK"){
        this.loadCreateBookingData(res);
      } else {
        this.createBookingReference = "";
        this.createMatchedBookingReference = "";
        this.sendMessageToDialog('',res["status"], "", "");
      }
      this.checkOnlinePrfInformation();
      this.pageLoaded = true;
    }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2013S)', error, request);
    });
  }

  // In 'CREATE PRF' page,
  // Initial process of matching a 'Booking' to a PRF (*executes whenever a valid Booking is attached to PRF)
  loadCreateBookingData(result:any){
    // Clear all PRF's Data
    this.clearCreatePrf();
    this.preAttachOfBooking = false;
    // Add Matched Booking Reference
    this.createMatchedBookingData = result["bookingData"];
    this.createMatchedBookingReference = this.createMatchedBookingData.bookingReference;
    // Set Booking Data Variables
    this.createCustomerData = result["customerData"];    
    this.createListOfElementData = result["elementData"];
    this.createListOfReceiptData = result["receiptData"];
    // Load Booking Data
    this.reLoadCreateBookingData(true);
  }

  // In 'CREATE PRF' page,
  // When executed at first time is confirmed otherwire needs to check 
  // Loads the original data from the Booking not the elements of any supplier
  reLoadCreateBookingData(firstTime:Boolean){
    let continueWithIt = false;
    if (firstTime == true){
      continueWithIt = true;
    } else {
      if (confirm("Are you sure you want to reload the original booking data to the PRF?\n(This will refresh all PRF's current information)")) {
        continueWithIt = true;
      }
    }

    if (continueWithIt == true) {
      //
      // PRF ONLINE INFORMATION BELOW
      //

      // Have the client funds been received? - automatically "Yes"
      this.createIsClientFundsReceived = 1;

      // Has the client returned from travel? - from createMatchedBookingData, returnDate    
      let today:Date = new Date();
      if (this.createMatchedBookingData.returnDate != null && this.createMatchedBookingData.returnDate.length>0){
        let bookingReturnDate:Date = new Date(this.createMatchedBookingData.returnDate);
        if (today > bookingReturnDate){
          this.createHasClientReturned = 1
          this.createClientReturnDateEnable = false;
          this.createFinancialProtectionEnable = false;
        } else {
          this.createHasClientReturned = 0;
          this.createClientReturnDate = bookingReturnDate;
          this.createFinancialProtectionEnable = true;
          if (this.createFinancialProtection.checks[0] == true){
            this.createClientReturnDateEnable = true;
          }
        }
      }

      // Load Suppliers - from elementData
      if (this.createListOfElementData.length > 0){
        if (this.createSupplierName != "None" && this.createSupplierName != "Various Suppliers"){
          this.createSupplierName = '';
        } 
        let bookingSupplierList:any = [];
        this.createListOfElementData.forEach((element:any)=> {
          if (element.supplierID !== null && element.elementStatus == "booking") {
            const elementSupplierName = element.supplierName;
            const existingSupplier = bookingSupplierList.find((supplier:any) => supplier.supplierNameM === elementSupplierName);
            if (!existingSupplier) {
              bookingSupplierList.push({supplierNameM: elementSupplierName});
            }
            this.createListOfSupplierElements.push(element);
          }
        });
        if (bookingSupplierList.length > 0){
          this.suppliersList = bookingSupplierList;
        }    
      }

      //
      // PRF ONLINE INFORMATION ABOVE
      //

      //
      // SUPPORTING DOCUMENTATION BELOW
      //

      this.reloadS3documents()

      //
      // SUPPORTING DOCUMENTATION ABOVE
      //

      //
      // PRF TRANSACTION VALUES BELOW 
      //

      // Load Client Surname - from customersData [customer .. lastName]
      this.clearAllFromCreateListOfCustomers();
      if (this.createCustomerData.status == "OK" && this.createCustomerData.bookCustList.length > 0){
        const listOfCustomers = this.createCustomerData.bookCustList;
        listOfCustomers.forEach((customerData:any)=>{
          if (customerData["isLead"] == true){
            this.createListOfClientSurnames.push({lastName:customerData["customer"].lastName, lead:true});
          } else {
            this.createListOfClientSurnames.push({lastName:customerData["customer"].lastName, lead:false});
          }
        });
      } else {
        if (this.createMatchedBookingData.leadName != null && this.createMatchedBookingData.leadName != ""){
          let lastName = ""
          if (this.createMatchedBookingData.leadName.split(' ').length > 0) {
            lastName = this.createMatchedBookingData.leadName.split(' ').pop()
          } else {
            lastName = this.createMatchedBookingData.leadName
          }
          this.createListOfClientSurnames.push({lastName:lastName, lead:true});
        }
      }
      if (this.createListOfClientSurnames.length > 0){
        this.createListOfClientSurnames.forEach((tempCustomer:any)=>{
          if (tempCustomer.lead == true){
            this.createTransactionValuesList[0].createClientSurname = tempCustomer.lastName;
          } else {
            if (this.createTransactionValuesList[0].createClientSurname == "") {
              this.createTransactionValuesList[0].createClientSurname = tempCustomer.lastName;
            }
          }
        })
      }

      // Load Date funds banked - Booking Date
      this.createTransactionValuesList[0].createDateFundsBanked = this.createMatchedBookingData.bookingDate;
      if (this.createListOfReceiptData.receiptList.length > 0){
        let receiptDate:any = new Date(this.createListOfReceiptData.receiptList[0].receiptDate)
        if (receiptDate) {
          this.createTransactionValuesList[0].createDateFundsBanked = receiptDate;
        }
        // Load Receipt
        this.receiptData.data = [];
        if (this.createListOfReceiptData.receiptList){
          this.receiptData.data = this.createListOfReceiptData.receiptList;
          this.receiptData.data.forEach((receipt: any) => {
            const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
            let value = '£0'; // Begin with £0 value and format positive/non-positive value below
            if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
            // Assign free-text value visible in UI below..
            receipt.freeText = 'Date: ' + receiptDate + '\n' +
              'Method: ' + receipt.paymentMethod + '\n' +
              'Value: ' + value;
          });
        }
      }

      // Load Payment Method - Usually a Card
      this.createTransactionValuesList[0].createPaymentMethod = "Card";

      // Load Gross Client Funds - from createMatchedBookingData [gross]
      this.createTransactionValuesList[0].createGrossClientFunds.currency = "GBP";
      this.createTransactionValuesList[0].createGrossClientFunds.amount = this.createMatchedBookingData.gross;

      // Load Guarantee Deducted
      this.createTransactionValuesList[0].createGuaranteeDeducted.currency = "GBP"

      // Load Tax - from createMatchedBookingData [tax]
      this.createTransactionValuesList[0].createVAT = this.createMatchedBookingData.tax;

      // Load Supplier Amount - from createMatchedBookingData [net]
      this.createTransactionValuesList[0].createSupplierAmount.currency = "GBP"
      this.createTransactionValuesList[0].createSupplierAmount.amount = this.createMatchedBookingData.net;

      // Load Reimbursement - Just Currency
      this.createTransactionValuesList[0].createReimbursement.currency = "GBP"

      // Load Client Refund - Just Currency
      this.createTransactionValuesList[0].createClientRefund.currency = "GBP"

      // Load Commission -  Just Currency
      this.createTransactionValuesList[0].createCommission.currency = "GBP"

      // guarantee deducted or immediately calculate commission ()
      if (this.createClaimCommission.checked == true){
        this.checkGuaranteeDeducted();
      }

      //
      // PRF TRANSACTION VALUES ABOVE
      //

      // CHECK IF READY FOR NEXT STEP
      this.checkOnlinePrfInformation()
    }
  }

  // In 'CREATE PRF' page,
  // When Member selects a supplier then it will load the amounts from the supplier's transaction (element)
  // If it's 'None' then it will load the original booking amounts
  // If it's 'Multiple' booking PRF then it will not do anything just check if the first page is finished
  loadCreateSupplierDataFromBooking(fromRefresh:Boolean){
    let continueWithIt = false;
    if (fromRefresh == true){
      if (confirm("Are you sure you want to reload the supplier's booking data to the PRF?\n(This will refresh PRF's Transaction Values information)")) {
        continueWithIt = true;
      }
    } else {
      continueWithIt = true;
    }
    if (continueWithIt == true) {
      if (this.createSupplierName != ""){
        if (this.createMultipleBookingReference != true){
          if (this.createSupplierName != 'None' && this.createSupplierName != 'Various Suppliers') {
            // NOT MULTIPLE & SPECIFIC SUPPLIER
            if (this.createListOfSupplierElements.length > 0){
              let today = new Date();
              this.createListOfSupplierElements.forEach((supplier:any)=>{
                if (supplier.supplierName == this.createSupplierName){
                  // returnDate
                  if (supplier.returnDate != null && supplier.returnDate.length > 0){
                    let elementReturnDate = new Date(supplier.returnDate);
                    if (today > elementReturnDate){
                      this.createHasClientReturned = 1;
                      this.createClientReturnDateEnable = false;
                      this.createFinancialProtectionEnable = false;
                    } else {
                      this.createHasClientReturned = 0;
                      this.createClientReturnDate = elementReturnDate;
                      this.createFinancialProtectionEnable = true;
                      if (this.createFinancialProtection.checks[0] == true){
                        this.createClientReturnDateEnable = true;
                      }
                    }
                  }
                  // gross
                  this.createTransactionValuesList[0].createGrossClientFunds.amount = supplier.gross;            
                  // supplier amount
                  this.createTransactionValuesList[0].createSupplierAmount.amount = supplier.net;
                  // tax
                  this.createTransactionValuesList[0].createVAT = supplier.tax;
                  // guarantee deducted or immediately calculate commission ()
                  if (this.createClaimCommission.checked == true){
                    this.checkGuaranteeDeducted();
                  } else {
                    this.checkTransactionValues(false);
                  }
                }
              })
            }
          } else {
            // NOT MULTIPLE & NOT SPECIFIC SUPPLIER
            this.reLoadCreateBookingData(true);
          }
        } else {
          if (this.createSupplierName != 'None' && this.createSupplierName != 'Various Suppliers'){
            // MULTIPLE & SPECIFIC SUPPLIER
            this.createTransactionValuesList.forEach((tempTransaction:any)=>{
              const listOfElementData = tempTransaction.createBookingData.elementData;
              listOfElementData.forEach((element:any)=>{
                if (element.supplierName == this.createSupplierName){
                  // gross
                  tempTransaction.createGrossClientFunds.amount = element.gross;            
                  // supplier amount
                  tempTransaction.createSupplierAmount.amount = element.net;
                  // tax
                  tempTransaction.createVAT = element.tax;
                }
              })
            });
            // guarantee deducted or immediately calculate commission ()
            if (this.createClaimCommission.checked == true){
              this.checkGuaranteeDeducted();
            } else {
              this.checkTransactionValues(false);
            }
          } else {
            // MULTIPLE & NOT SPECIFIC SUPPLIER
            this.reLoadMultipleCreateBookingData(true);
          }
        }
        this.checkOnlinePrfInformation();
      } 
    }
  }

  // In 'CREATE PRF' page,
  // When user changes Guarantees and Insurances Collected From then we run the following,
  checkGuaranteeDeducted(){
    if (this.createCollectedFrom == "Trust Account" && this.createClaimCommission.checked == true){
      if (this.createMultipleBookingReference != true){
        // Load Guarantee Deducted - Amount (Single PRF)
        this.createTransactionValuesList[0].createGuaranteeDeducted.amount = 0.00
        this.createListOfElementData.forEach((element:any)=>{
          if (element.supplierName == "Safe Seat Plan Guarantee - Standalone" && element.elementStatus == "booking"){
            if (Number(element.gross) > 0){
              let elementGross = Number(this.createTransactionValuesList[0].createGuaranteeDeducted.amount) + Number(element.gross);
              this.createTransactionValuesList[0].createGuaranteeDeducted.amount = Number(elementGross).toFixed(2);
            } else {
              if (Number(element.discount) > 0){
                let elementDiscount = Number(this.createTransactionValuesList[0].createGuaranteeDeducted.amount) + Number(element.discount);
                this.createTransactionValuesList[0].createGuaranteeDeducted.amount = Number(elementDiscount).toFixed(2);
              }
            }
           }
        })
      } else {
        this.createTransactionValuesList.forEach((tempTransaction:any)=>{
          // Load Guarantee Deducted - Amount (Multiple PRF)
          tempTransaction.createGuaranteeDeducted.amount = 0.00
          if (tempTransaction.createBookingData != null){
            const listOfElementData = tempTransaction.createBookingData.elementData;
            listOfElementData.forEach((element:any)=>{
              if (element.supplierName == "Safe Seat Plan Guarantee - Standalone" && element.elementStatus == "booking"){
                if (Number(element.gross) > 0){
                  let elementGross = Number(element.gross) + Number(tempTransaction.createGuaranteeDeducted.amount)
                  tempTransaction.createGuaranteeDeducted.amount = Number(elementGross).toFixed(2);
                } else {
                  if (Number(element.discount) > 0){
                    let elementDiscount = Number(element.discount) + Number(tempTransaction.createGuaranteeDeducted.amount)
                    tempTransaction.createGuaranteeDeducted.amount = Number(elementDiscount).toFixed(2);
                  }
                }
               }
            })
          }
        })
      }
    } else {
      if (this.createMultipleBookingReference != true){ 
        // Unload Guarantee Deducted - Amount (Single PRF)
        this.createTransactionValuesList[0].createGuaranteeDeducted.amount = null
      } else {
        // Unload Guarantee Deducted - Amount (Multiple PRF)
        this.createTransactionValuesList.forEach((tempTransaction:any)=>{
          tempTransaction.createGuaranteeDeducted.amount = null
        })
      }
    }
    this.checkTransactionValues(false);
  }

  // In 'View PRF' page,
  // When user changes Guarantees and Insurances Collected From then we run the following,
  checkViewGuaranteeDeducted(){
    if (this.viewPrfBookingReference != null){ 
      if (this.viewPrfCollectedFrom == "Trust Account" && this.viewClaimCommission.checked == true){
        if (this.viewMultipleBookingReference != true){
          // Load Guarantee Deducted - Amount (Single PRF)
          this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.currency = "GBP"
          this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount = 0.00
          this.viewListOfElementData.forEach((element:any)=>{
            if (element.supplierName == "Safe Seat Plan Guarantee - Standalone" && element.elementStatus == "booking"){
              if (Number(element.gross)>0){
                let elementGross = Number(this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount) + Number(element.gross);
                this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount = Number(elementGross).toFixed(2);
              } else {
                if (Number(element.discount)>0){
                  let elementDiscount = Number(this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount) + Number(element.discount);
                  this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount = Number(elementDiscount).toFixed(2);
                }
              }
             } 
          })
        } else {
          // Load Guarantee Deducted - Amount (Multiple PRF)
          this.viewPrfTransactionValuesList.forEach((tempTransaction:any)=>{
            tempTransaction.viewGuaranteeDeducted.currency = "GBP"
            tempTransaction.viewGuaranteeDeducted.amount = 0.00
            this.viewMatchedBookingData.forEach((matchTransaction:any)=>{
              if (matchTransaction.bookingReference == tempTransaction.viewBookingReference){
                if (matchTransaction.bookingData != null){
                  const listOfElementData = matchTransaction.bookingData.elementData;
                  listOfElementData.forEach((element:any)=>{
                    if (element.supplierName == "Safe Seat Plan Guarantee - Standalone" && element.elementStatus == "booking"){
                      if (Number(element.gross)>0){
                        let elementGross = Number(element.gross) + Number(tempTransaction.viewGuaranteeDeducted.amount)
                        tempTransaction.viewGuaranteeDeducted.amount = Number(elementGross).toFixed(2);
                      } else {
                        if (Number(element.discount)>0){
                          let elementDiscount = Number(element.discount) + Number(tempTransaction.viewGuaranteeDeducted.amount)
                          tempTransaction.viewGuaranteeDeducted.amount = Number(elementDiscount).toFixed(2);
                        }
                      }
                     }
                  })
                }
              }
            });
          })
        }
      } else {
        if (this.viewMultipleBookingReference != true){ 
          // Unload Guarantee Deducted - Amount (Single PRF)
          this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount = null
        } else {
          // Unload Guarantee Deducted - Amount (Multiple PRF)
          this.viewPrfTransactionValuesList.forEach((tempTransaction:any)=>{
            tempTransaction.viewGuaranteeDeducted.amount = null
          })
        }
      }
    }
    this.checkViewTransactionValues(false,false);
  }

  // In 'CREATE PRF' page,
  // Resets all the customer's surname which where received from booking
  clearAllFromCreateListOfCustomers(){
    this.createListOfClientSurnames = []
  }

  // In 'CREATE PRF' page,
  // Removes the already set Booking Referece and returns the 'Create Prf' object to it's initial form
  removeCreateMatchBookingRef(){
    if (confirm("Are you sure you want to remove the booking data from the PRF?\n(This will reset all PRF's current information)")){
      this.clearCreatePrf();
      this.setSuppliersList();
      this.checkOnlinePrfInformation();
    }
  }

  // In 'VIEW PRF' page, (INITIAL LOAD OF VIEW)
  // If a valid (exists) and mathced (*with tradeCode and bookingStatus) booking is found
  // Then it will attach it to the PRF and receives all the relevant information
  // Else return invalid match message
  setInitialViewBookingRefForPrf() {
    this.pageLoaded = false;
    let request: any = {
      company: this.viewPrfCompany.toLowerCase(),
      tradeCode: this.viewPrfTradeCode,
      operation: this.viewPrfOperation,
      bookingReference: this.viewMatchedBookingReference,
      token: Session.mySession.get('user').token,
    };
    this.prfService.getBookingRefForPrf(request).then((res: any) => {
      if (res["status"] == "OK"){
        this.pageLoaded = true;
        this.viewBookingReference = "";
        this.viewMatchedBookingReference = res["bookingData"].bookingReference;
        this.loadInitialViewBookingData(res);
        this.reloadS3documents();
      } else {
        this.pageLoaded = true;
        this.viewBookingReference = "";
        this.viewMatchedBookingReference = "";
        this.sendMessageToDialog('',res["status"], "", "");
      }
      this.checkViewOnlinePrfInformation();
    }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2014S)', error, request);
    });
  }

  // In 'VIEW PRF' page, (INITIAL LOAD OF VIEW)
  // If multiple valid (exists) and mathced (*with tradeCode and bookingStatus) booking is found
  // Then it will attach it to the PRF and receives all the relevant information
  // Else return invalid match message
  setInitialMultipleViewBookingRefForPrf(){
    let request: any = {
      company: this.viewPrfCompany.toLowerCase(),
      tradeCode: Session.mySession.getUser().tradeCode,
      prfTradeCode: this.viewPrfTradeCode,
      operation: this.viewPrfOperation,
      prfNumber: this.viewPrfNumber,
      token: Session.mySession.get('user').token,
    };
    this.prfService.getMultipleBookingRefForViewPrf(request).then((res: any) => {
      if (res["status"] == "OK"){

        // Setup list of matched bookings into the PRF
        this.viewPrfLinkedBookings = res["listOfBookingReference"];

        // Setup all the matching data from all of those Bookings
        this.viewMatchedBookingData = res["res"]
        this.setupMultipleBookingVAT();

        // Setup available suppliers from matched bookings
        this.loadInitialMultipleViewAvailableSuppliers();

        // Setup all the matching documents from the Bookings
        this.reloadS3Multipledocuments()

        // Setup SFC Buttons
        // ONLY TRUSTEES NEED TO CHECK
        if (this.checkUserType()){
          this.checkIfSfcsAreAvailable();
        }

        // Setup receipt list
        this.loadInitialMultipleReceipts()

        // Page Loaded
        this.pageLoaded = true;

      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog('',res["status"], "", "");
      }
      this.checkViewOnlinePrfInformation();
    }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2014S)', error, request);
    });
  }

  checkIfSfcsAreAvailable(){
    this.viewMatchedBookingData.forEach((tempBooking:any)=>{
      if (tempBooking.bookingData.elementData && tempBooking.bookingData.elementData.length > 0){
        tempBooking.bookingData.elementData.forEach((tempElement:any)=>{
          if (tempElement.archive == 'no' && tempElement.elementType == 'supplement' && tempElement.elementStatus == 'booking' && tempElement.supplierName == "SFC"){
            tempBooking.sfcAvailableToReGenerate = true
            tempBooking.sfcSupplementCount = tempElement.supplements[0].supplementCount;
          }
        })
      }
    });
  }

  // Setup List of Select Suppliers if multiple booking PRF and not Trustee
  loadInitialMultipleViewAvailableSuppliers(){
    if (this.checkUserType() == false){
      let bookingsSupplierList:any = [];
      this.viewMatchedBookingData.forEach((tempBooking:any)=>{
        if (tempBooking.listOfSuppliers.length > 0){ 
          tempBooking.listOfSuppliers.forEach((tempSupplier:any)=> {
              // Check if the supplier name already exists in bookingsSupplierList
              const existingSupplier = bookingsSupplierList.find((supplier:any) => supplier.supplierNameM === tempSupplier);
              if (!existingSupplier) {
                bookingsSupplierList.push({ supplierNameM: tempSupplier });
              }
          });    
        }
      })
      if (bookingsSupplierList.length > 0){
        this.suppliersList = bookingsSupplierList;
      }
    }
  }

  loadInitialMultipleReceipts(){
    this.viewMatchedBookingData.forEach((tempVieBooking:any)=>{
      this.viewPrfTransactionValuesList.forEach((tempViewTransaction:any)=>{
        if (tempVieBooking.bookingReference == tempViewTransaction.viewBookingReference){
          if (tempVieBooking.receiptData.receiptList){
            // Load Receipt
            tempViewTransaction.viewReceiptData.data = tempVieBooking.receiptData.receiptList
            tempViewTransaction.viewReceiptData.data.forEach((receipt: any) => {
              const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
              let value = '£0'; // Begin with £0 value and format positive/non-positive value below
              if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
              // Assign free-text value visible in UI below..
              receipt.freeText = 'Date: ' + receiptDate + '\n' +
                'Method: ' + receipt.paymentMethod + '\n' +
                'Value: ' + value;
            });
          }
        }
      })
    })
  }

  // In 'VIEW PRF' page, (INITIAL LOAD OF VIEW)
  // Initial process of matching a 'Booking' to a PRF (*executes whenever a valid Booking is attached to PRF)
  loadInitialViewBookingData(result:any){
    // Set Booking Data Variables
    this.viewMatchedBookingData = result["bookingData"];
    this.setupSingleBookingVAT();
    this.viewCustomerData = result["customerData"];
    this.viewListOfElementData = result["elementData"];
    // ONLY TRUSTEES NEED TO CHECK
    if (this.checkUserType()){
      this.checkIfSfcIsAvailable();
    }
    this.viewListOfReceiptData = result["receiptData"];
    this.receiptData.data = this.viewListOfReceiptData.receiptList;
    this.showReceiptList = true;
    // Load Receipt
    if (this.receiptData.data){
      this.receiptData.data.forEach((receipt: any) => {
        const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
        let value = '£0'; // Begin with £0 value and format positive/non-positive value below
        if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
        // Assign free-text value visible in UI below..
        receipt.freeText = 'Date: ' + receiptDate + '\n' +
          'Method: ' + receipt.paymentMethod + '\n' +
          'Value: ' + value;
      });
    }
    // Load ... Suppliers /  Customers
    this.loadInitialViewAvailableSuppliers();
    this.loadInitialViewAvailableCustomers();
  }

  // This will check if SFC is available for Single Booking PRF
  checkIfSfcIsAvailable(){
    this.sfcAvailableToReGenerate = false;
    this.viewListOfElementData.forEach((tempElement:any)=>{
      if (tempElement.archive == 'no' && tempElement.elementType == 'supplement' && tempElement.elementStatus == 'booking' && tempElement.supplierName == "SFC"){
        this.sfcAvailableToReGenerate = true;
      }
    });
  }

  // In 'VIEW PRF' page, (INITIAL LOAD OF VIEW)
  // Load Available Suppliers
  // If found, from elementData (viewListOfElementData)
  // If not found then keep it as it is
  loadInitialViewAvailableSuppliers(){
    if (this.viewListOfElementData.length > 0){
      let bookingSupplierList:any = [];
      this.viewListOfElementData.forEach((element:any)=> {
        if (element.supplierID !== null && element.elementStatus == "booking") {
          const elementSupplierName = element.supplierName;
          const existingSupplier = bookingSupplierList.find((supplier:any) => supplier.supplierNameM === elementSupplierName);
          if (!existingSupplier) {
            bookingSupplierList.push({supplierNameM: elementSupplierName});
          }
          this.viewListOfSupplierElements.push(element);
        }
      });
      if (bookingSupplierList.length > 0){
        this.suppliersList = bookingSupplierList;
      }    
    }
  }

  // In 'VIEW PRF' page, (INITIAL LOAD OF VIEW)
  // Load Available Customers
  // If found, from customerData or bookings leadName (viewCustomerData)
  loadInitialViewAvailableCustomers(){
    this.clearAllFromViewListOfCustomers();
      if (this.viewCustomerData.status == "OK" && this.viewCustomerData.bookCustList.length > 0){
        const listOfCustomers = this.viewCustomerData.bookCustList;
        listOfCustomers.forEach((customerData:any)=>{
          if (customerData["isLead"] == true){
            this.viewListOfClientSurnames.push({lastName:customerData["customer"].lastName, lead:true});
            if(this.viewPrfTransactionValuesList[0].viewClientSurname == null || this.viewPrfTransactionValuesList[0].viewClientSurname == ""){
              this.viewPrfTransactionValuesList[0].viewClientSurname = customerData["customer"].lastName;
            }
          } else {
            this.viewListOfClientSurnames.push({lastName:customerData["customer"].lastName, lead:false});
          }
        });
      } else {
        if (this.viewMatchedBookingData.leadName != null && this.viewMatchedBookingData.leadName != ""){
          let lastName = ""
          if (this.viewMatchedBookingData.leadName.split(' ').length > 0) {
            lastName = this.viewMatchedBookingData.leadName.split(' ').pop()
          } else {
            lastName = this.viewMatchedBookingData.leadName
          }
          this.viewListOfClientSurnames.push({lastName:lastName, lead:true});
        }
      }
  }

  // In 'VIEW PRF' page,
  // Resets all the customer's surname which where received from booking
  clearAllFromViewListOfCustomers(){
    this.viewListOfClientSurnames = []
  }

  // Check if PRF Online Information is complete (CREATE)
  checkOnlinePrfInformation() {
    // Every time a user is: typing, changing date or chaning select, this function
    // will check if any of 3 fields are blank. If so, user will be blocked from next step until
    // the fields are filled in (like required - I couldnt make it work)
    let firstCompleted = true;
    if (this.createHasClientReturned == 2 || this.createIsClientFundsReceived == 2 || this.createSupplierName == "" ||
      (this.createFinancialProtectionEnable == true && this.createFinancialProtection.checks[0] == false && 
        this.createFinancialProtection.checks[1] == false && this.createFinancialProtection.checks[2] == false && this.createFinancialProtection.checks[3] == false &&
        this.createFinancialProtection.checks[4] == false) ||
      (this.createClaimClientRefund.checked == true && this.createClaimClientRefund.paymentMethod == "") ||
      (this.createClaimSupplierPayment.checked == true &&
        (this.createClaimSupplierPayment.paymentMethods.checks[0] == false && this.createClaimSupplierPayment.paymentMethods.checks[1] == false &&
         this.createClaimSupplierPayment.paymentMethods.checks[2] == false && this.createClaimSupplierPayment.paymentMethods.checks[3] == false &&
         this.createClaimSupplierPayment.paymentMethods.checks[4] == false && this.createClaimSupplierPayment.paymentMethods.checks[5] == false && 
         this.createClaimSupplierPayment.paymentMethods.checks[6] == false)) ||
      (this.createClaimReimbursement.checked == true && this.createClaimReimbursement.paymentMethod == "") ||
      (this.createClaimCommission.checked == true && this.createClaimCommission.paymentMethod == "") ||
      (this.createClaimCommission.checked == false && this.createClaimReimbursement.checked == false && this.createClaimSupplierPayment.checked == false && this.createClaimClientRefund.checked == false)) {
      firstCompleted = false;
    }
    if (this.createFinancialProtectionEnable == true && this.createFinancialProtection.checks[0] == true && this.createClientReturnDate == null){
      if ((this.createClaimCommission.checked == false && this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false  && this.createClaimClientRefund.checked == true) == false){
        firstCompleted = false;
      }
    }
    if (this.createMatchedBookingReference == '') {
      firstCompleted = false;
    }
    if (this.createClaimSupplierPayment.checked == true && this.createSupplierName == "Various Suppliers"){
      firstCompleted = false;
    }
    if (firstCompleted == true){
      let tempCreateListOfPaymentMethods = new Set(); // Use a Set to ensure uniqueness

      const paymentMethodsToCheck = [
        this.createClaimCommission,
        this.createClaimClientRefund,
        this.createClaimReimbursement
      ];

      paymentMethodsToCheck.forEach((method:any) => {
        if (method.checked && !tempCreateListOfPaymentMethods.has(method.paymentMethod)) {
          tempCreateListOfPaymentMethods.add(method.paymentMethod);
        }
      });

      if (this.createClaimSupplierPayment.checked == true){
        if (this.createClaimSupplierPayment.paymentMethods.checks[0] == true && !tempCreateListOfPaymentMethods.has(this.createClaimSupplierPayment.paymentMethods.methods[0])){
          tempCreateListOfPaymentMethods.add(this.createClaimSupplierPayment.paymentMethods.methods[0])
        }
        if (this.createClaimSupplierPayment.paymentMethods.checks[1] == true && !tempCreateListOfPaymentMethods.has(this.createClaimSupplierPayment.paymentMethods.methods[1])){
          tempCreateListOfPaymentMethods.add(this.createClaimSupplierPayment.paymentMethods.methods[1])
        }
        if (this.createClaimSupplierPayment.paymentMethods.checks[2] == true && !tempCreateListOfPaymentMethods.has(this.createClaimSupplierPayment.paymentMethods.methods[2])){
          tempCreateListOfPaymentMethods.add(this.createClaimSupplierPayment.paymentMethods.methods[2])
        }
        if (this.createClaimSupplierPayment.paymentMethods.checks[3] == true && !tempCreateListOfPaymentMethods.has(this.createClaimSupplierPayment.paymentMethods.methods[3])){
          tempCreateListOfPaymentMethods.add(this.createClaimSupplierPayment.paymentMethods.methods[3])
        }
        if (this.createClaimSupplierPayment.paymentMethods.checks[4] == true && !tempCreateListOfPaymentMethods.has(this.createClaimSupplierPayment.paymentMethods.methods[4])){
          tempCreateListOfPaymentMethods.add(this.createClaimSupplierPayment.paymentMethods.methods[4])
        }
        if (this.createClaimSupplierPayment.paymentMethods.checks[5] == true && !tempCreateListOfPaymentMethods.has(this.createClaimSupplierPayment.paymentMethods.methods[5])){
          tempCreateListOfPaymentMethods.add(this.createClaimSupplierPayment.paymentMethods.methods[5])
        }
        if (this.createClaimSupplierPayment.paymentMethods.checks[6] == true && !tempCreateListOfPaymentMethods.has(this.createClaimSupplierPayment.paymentMethods.methods[6])){
          tempCreateListOfPaymentMethods.add(this.createClaimSupplierPayment.paymentMethods.methods[6])
        }
      }
      
      this.createListOfPaymentMethods = Array.from(tempCreateListOfPaymentMethods);
    
    }
    
    this.firstCompleted = firstCompleted;
  }

  // Check if Member uploaded at least one file (CREATE)
  checkSupportingDocumentation(){
    let foundFile:boolean = false;
    if (this.uploadedPrfDocuments.data.length > 0){
      this.uploadedPrfDocuments.data.forEach((tempUploadedFile:any)=>{
        if (tempUploadedFile.documentType != 'Confirmation Of Payment' && tempUploadedFile.documentType != 'Schedule' && tempUploadedFile.documentType != 'Invoice'){
          foundFile = true;
        }
      })
    } 
    this.secondCompleted = foundFile;
  }

  // Check if PRF Transaction Values form is complete (CREATE)
  checkTransactionValues(isCommission:boolean) {
    // Every time a user is: typing, changing date or chaning select, this function
    // will check if any of 3 fields are blank. If so, user will be blocked from next step until
    // the fields are filled in (like required - I couldnt make it work)
    let thirdCompleted = true;
    this.createTransactionValuesList.forEach((transaction) => {
      transaction.createIsNotComplete = false
      if (this.createMultipleBookingReference == true){
        if (transaction.createBookingReference == ""){
          thirdCompleted = false;
          transaction.createIsNotComplete = true;
        }
      }
      if (transaction.createClientSurname == "" || transaction.createPaymentMethod == "" ||
        transaction.createGrossClientFunds.amount == null || transaction.createGrossClientFunds.currency == "" || 
        (this.createClaimClientRefund.checked == true && (transaction.createClientRefund.amount == null || transaction.createClientRefund.currency == "")) ||
        (this.createClaimSupplierPayment.checked == true && (transaction.createSupplierAmount.amount == null || transaction.createSupplierAmount.currency == "")) ||
        (this.createClaimReimbursement.checked == true && (transaction.createReimbursement.amount == null || transaction.createReimbursement.currency == "")) ||
        (this.createClaimCommission.checked == true && (transaction.createCommission.amount == null || transaction.createCommission.currency == "" )) ||
        ((this.createClaimCommission.checked == true && this.createCollectedFrom == "Trust Account") && (transaction.createGuaranteeDeducted.currency == "" || transaction.createGuaranteeDeducted.amount == null)) ||
        ((this.createClaimCommission.checked == true && this.createClaimClientRefund.checked == false && this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false) 
          && (transaction.createSupplierAmount.amount == null || transaction.createSupplierAmount.currency == ""))) {
        thirdCompleted = false;
        transaction.createIsNotComplete = true;
      }
      if (isCommission == false) {
        // Commission Calculation
        if (this.createMultipleBookingReference == true){
          if (this.createClaimCommission.checked == true && transaction.createGrossClientFunds.amount != null){
            // Commission 
            if (this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false && this.createClaimClientRefund.checked == false){
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createSupplierAmount.amount
                if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createSupplierAmount.amount
                if (transaction != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
            }
            // Supplier Payment
            if (this.createClaimSupplierPayment.checked == true && transaction.createSupplierAmount.amount != null) {
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createSupplierAmount.amount 
                if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createSupplierAmount.amount
                if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
            }
            // Reimbursement
            if (this.createClaimReimbursement.checked == true && transaction.createReimbursement.amount != null) {
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createReimbursement.amount
                if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createReimbursement.amount
                if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }            
            }
            // Client Refund
            if (this.createClaimClientRefund.checked == true && transaction.createClientRefund.amount != null) {
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createClientRefund.amount
                if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                } 
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createClientRefund.amount
                if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != "" && transaction.createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
            }
            transaction.createCommission.amount = Number(transaction.createCommission.amount).toFixed(2);
          }
        } else {
          if (this.createClaimCommission.checked == true && transaction.createGrossClientFunds.amount != null){
            // Commission 
            if (this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false && this.createClaimClientRefund.checked == false){
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createSupplierAmount.amount
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createSupplierAmount.amount
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
            }
            // Supplier Payment
            if (this.createClaimSupplierPayment.checked == true && transaction.createSupplierAmount.amount != null) {
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createSupplierAmount.amount 
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createSupplierAmount.amount
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
            }
            // Reimbursement
            if (this.createClaimReimbursement.checked == true && transaction.createReimbursement.amount != null) {
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createReimbursement.amount
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createReimbursement.amount
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }            
            }
            // Client Refund
            if (this.createClaimClientRefund.checked == true && transaction.createClientRefund.amount != null) {
              if (this.createCollectedFrom == "Trust Account" && transaction.createGuaranteeDeducted.amount != null){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createGuaranteeDeducted.amount - transaction.createClientRefund.amount
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                } 
              }
              if (this.createCollectedFrom == "Trading Account"){
                transaction.createCommission.amount = transaction.createGrossClientFunds.amount - transaction.createClientRefund.amount
                if (this.createTransactionValuesList[0].createVAT != null && this.createTransactionValuesList[0].createVAT != "NaN" && 
                    this.createTransactionValuesList[0].createVAT != "" && this.createTransactionValuesList[0].createVAT > 0.00){
                  transaction.createCommission.amount = transaction.createCommission.amount - transaction.createVAT;
                }
              }
            }
            transaction.createCommission.amount = Number(transaction.createCommission.amount).toFixed(2);
          }
        }
      }
    });
    if (this.createClaimCommission.checked == true && this.createCollectedFrom == "") {
      thirdCompleted = false;
    }
    this.thirdCompleted = thirdCompleted;
  }

  // Setup data from selected Booking Reference into Transaction Value Record (CREATE)
  setupCreateBookingTransactionRecord(givenTransaction:any){
    this.createMatchedBookingData.forEach((matchTransaction:any)=>{
      if (matchTransaction.bookingReference == givenTransaction.createBookingReference)
      {
        // Load Booking Customers
        if (matchTransaction.customerData.length > 0){
          givenTransaction.createListOfSurnames = []
          matchTransaction.customerData.forEach((customerData:any)=>{
            if (customerData["isLead"] == true){
              givenTransaction.createClientSurname = customerData["customer"].lastName;
              givenTransaction.createListOfSurnames.push({lastName:customerData["customer"].lastName, lead:true});
            } else {
              givenTransaction.createListOfSurnames.push({lastName:customerData["customer"].lastName, lead:false});
            }
          })
        }

        // Load Date funds banked - Booking Date
        givenTransaction.createDateFundsBanked = matchTransaction.bookingData.bookingData.bookingDate;
        if (matchTransaction.receiptData && matchTransaction.receiptData.receiptList.length > 0){
          let receiptDate:any = new Date(matchTransaction.receiptData.receiptList[0].receiptDate)
          if (receiptDate) {
            givenTransaction.createDateFundsBanked = receiptDate;
          }
          // Load Receipt
          givenTransaction.createReceiptData.data = matchTransaction.receiptData.receiptList
          givenTransaction.createReceiptData.data.forEach((receipt: any) => {
            const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
            let value = '£0'; // Begin with £0 value and format positive/non-positive value below
            if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
            // Assign free-text value visible in UI below..
            receipt.freeText = 'Date: ' + receiptDate + '\n' +
              'Method: ' + receipt.paymentMethod + '\n' +
              'Value: ' + value;
          });
        }

        // Load Payment Method - Usually a Card
        givenTransaction.createPaymentMethod = "Card";

        // Load Gross Client Funds - from createMatchedBookingData [gross]
        givenTransaction.createGrossClientFunds.currency = "GBP";
        givenTransaction.createGrossClientFunds.amount = matchTransaction.bookingData.bookingData.gross

        // Load Tax - from createMatchedBookingData [tax]
        givenTransaction.createVAT = matchTransaction.bookingData.bookingData.tax;

        // Load Supplier Amount - from createMatchedBookingData [net]
        givenTransaction.createSupplierAmount.currency = "GBP"
        givenTransaction.createSupplierAmount.amount = matchTransaction.bookingData.bookingData.net;

        // Load Reimbursement - Just Currency
        givenTransaction.createReimbursement.currency = "GBP"

        // Load Commission -  Just Currency
        givenTransaction.createCommission.currency = "GBP"

        // Add booking data 
        if (matchTransaction.bookingData != null){
          givenTransaction.createBookingData = matchTransaction.bookingData;
        }
        
        // Load Guarantee Deducted - Amount & Currency
        givenTransaction.createGuaranteeDeducted.currency = "GBP"
        if (this.createCollectedFrom == "Trust Account" && this.createClaimCommission.checked == true){
          givenTransaction.createGuaranteeDeducted.amount = 0.00
          if (matchTransaction.bookingData != null){
            const listOfElementData = matchTransaction.bookingData.elementData;
            listOfElementData.forEach((element:any)=>{
              if (element.supplierName == "Safe Seat Plan Guarantee - Standalone" && element.elementStatus == "booking"){
                if (Number(element.gross)>0){
                  let elementGross = Number(givenTransaction.createGuaranteeDeducted.amount) + Number(element.gross);
                  givenTransaction.createGuaranteeDeducted.amount = Number(elementGross).toFixed(2);
                } else {
                  if (Number(element.discount)>0){
                    let elementDiscount = Number(givenTransaction.createGuaranteeDeducted.amount) + Number(element.discount);
                    givenTransaction.createGuaranteeDeducted.amount = Number(elementDiscount).toFixed(2);
                  }
                }
               }
            })
          }
        }

      }
    });
    this.checkTransactionValues(false);
  }

  // Check if attached bookings list is more than 50
  isCreateBookingDisabled(): boolean {
    if (this.createBookingReference.length > 49){
      return true;
    } else {
      return false;
    }
  }

  // Clear list of attached booking references (CREATE)
  clearAttachedBookingReferences(){
    if (confirm("Are you sure you want to clear the list of 'Added Booking Reference'?")) {
      this.createBookingReference = ""
    }
  }

  // Clear list of attached booking references (VIEW)
  clearViewAttachedBookingReferences(){
    if (confirm("Are you sure you want to clear the list of 'Added Booking Reference'?")) {
      this.viewBookingReference = ""
    }
  }

  // Check if PRF Online Information form is complete (VIEW)
  checkViewOnlinePrfInformation() {
    // Every time a user is: typing, changing date or chaning select, this function
    // will check if any of 3 fields are blank. If so, user will be blocked from next step until
    // the fields are filled in (like required - I couldnt make it work)
    let firstViewCompleted = true;
    if (this.viewPrfHasClientReturned == 2 || this.viewPrfIsClientFundsReceived == 2 || this.viewPrfSupplierName == "" ||
      (this.viewPrfFinancialProtectionEnable == true && this.viewPrfFinancialProtection.checks[0] == false && this.viewPrfFinancialProtection.checks[1] == false 
        && this.viewPrfFinancialProtection.checks[2] == false && this.viewPrfFinancialProtection.checks[3] == false && this.viewPrfFinancialProtection.checks[4] == false) ||
      (this.viewClaimClientRefund.checked == true && this.viewClaimClientRefund.paymentMethod == "") ||
      (this.viewClaimSupplierPayment.checked == true &&
        (this.viewClaimSupplierPayment.paymentMethods.checks[0] == false && this.viewClaimSupplierPayment.paymentMethods.checks[1] == false &&
         this.viewClaimSupplierPayment.paymentMethods.checks[2] == false && this.viewClaimSupplierPayment.paymentMethods.checks[3] == false &&
          this.viewClaimSupplierPayment.paymentMethods.checks[4] == false && this.viewClaimSupplierPayment.paymentMethods.checks[5] == false && 
          this.viewClaimSupplierPayment.paymentMethods.checks[6] == false)) ||
      (this.viewClaimReimbursement.checked == true && this.viewClaimReimbursement.paymentMethod == "") ||
      (this.viewClaimCommission.checked == true && this.viewClaimCommission.paymentMethod == "") ||
      (this.viewClaimCommission.checked == false && this.viewClaimReimbursement.checked == false && this.viewClaimSupplierPayment.checked == false && this.viewClaimClientRefund.checked == false)) {
      firstViewCompleted = false;
    }
    if (this.viewPrfFinancialProtectionEnable == true && this.viewPrfFinancialProtection.checks[0] == true && this.viewPrfClientReturnDate == null ) {
      if ((this.viewClaimCommission.checked == false && this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false  && this.viewClaimClientRefund.checked == true) == false) {
        firstViewCompleted = false;
      }
    }
    if (this.viewMatchedBookingReference == '') {
      firstViewCompleted = false;
    }
    if (this.viewClaimSupplierPayment.checked == true && this.viewPrfSupplierName == "Various Suppliers"){
      firstViewCompleted = false;
    }
    if (firstViewCompleted == true && !this.checkUserType()){
      this.setupListOfPaymentMethodUploads()
    }
    this.firstViewCompleted = firstViewCompleted;
  }

  // Setup the list of Online Payments Uploads 
  // Now it varys based on the selected 'Payment methods'
  setupListOfPaymentMethodUploads(){
    let tempViewListOfPaymentMethods = new Set(); 

    const paymentMethodsToCheck = [
      this.viewClaimCommission,
      this.viewClaimClientRefund,
      this.viewClaimReimbursement
    ];

    paymentMethodsToCheck.forEach((method) => {
      if (method.checked && !tempViewListOfPaymentMethods.has(method.paymentMethod)) {
        tempViewListOfPaymentMethods.add(method.paymentMethod);
      }
    });

    if (this.viewClaimSupplierPayment.checked == true) {
      if (this.viewClaimSupplierPayment.paymentMethods.checks[0] == true && !tempViewListOfPaymentMethods.has(this.viewClaimSupplierPayment.paymentMethods.methods[0])) {
        tempViewListOfPaymentMethods.add(this.viewClaimSupplierPayment.paymentMethods.methods[0]);
      }
      if (this.viewClaimSupplierPayment.paymentMethods.checks[1] == true && !tempViewListOfPaymentMethods.has(this.viewClaimSupplierPayment.paymentMethods.methods[1])) {
        tempViewListOfPaymentMethods.add(this.viewClaimSupplierPayment.paymentMethods.methods[1]);
      }
      if (this.viewClaimSupplierPayment.paymentMethods.checks[2] == true && !tempViewListOfPaymentMethods.has(this.viewClaimSupplierPayment.paymentMethods.methods[2])) {
        tempViewListOfPaymentMethods.add(this.viewClaimSupplierPayment.paymentMethods.methods[2]);
      }
      if (this.viewClaimSupplierPayment.paymentMethods.checks[3] == true && !tempViewListOfPaymentMethods.has(this.viewClaimSupplierPayment.paymentMethods.methods[3])) {
        tempViewListOfPaymentMethods.add(this.viewClaimSupplierPayment.paymentMethods.methods[3]);
      }
      if (this.viewClaimSupplierPayment.paymentMethods.checks[4] == true && !tempViewListOfPaymentMethods.has(this.viewClaimSupplierPayment.paymentMethods.methods[4])) {
        tempViewListOfPaymentMethods.add(this.viewClaimSupplierPayment.paymentMethods.methods[4]);
      }
      if (this.viewClaimSupplierPayment.paymentMethods.checks[5] == true && !tempViewListOfPaymentMethods.has(this.viewClaimSupplierPayment.paymentMethods.methods[5])) {
        tempViewListOfPaymentMethods.add(this.viewClaimSupplierPayment.paymentMethods.methods[5]);
      }
      if (this.viewClaimSupplierPayment.paymentMethods.checks[6] == true && !tempViewListOfPaymentMethods.has(this.viewClaimSupplierPayment.paymentMethods.methods[6])) {
        tempViewListOfPaymentMethods.add(this.viewClaimSupplierPayment.paymentMethods.methods[6]);
      }
    }

    this.viewListOfPaymentMethods = Array.from(tempViewListOfPaymentMethods);
  }

  // Setup data from selected Booking Reference into Transaction Value Record (VIEW)
  setupViewBookingTransactionRecord(givenTransaction:any){
    this.viewMatchedBookingData.forEach((matchTransaction:any)=>{
      if (matchTransaction.bookingReference == givenTransaction.viewBookingReference)
      {
        // Load Booking Customers
        if (matchTransaction.customerData.length > 0){
          givenTransaction.viewListOfSurnames = []
          matchTransaction.customerData.forEach((customerData:any)=>{
            if (customerData["isLead"] == true){
              givenTransaction.viewClientSurname = customerData["customer"].lastName;
              givenTransaction.viewListOfClientSurnames.push({lastName:customerData["customer"].lastName, lead:true});
            } else {
              givenTransaction.viewListOfClientSurnames.push({lastName:customerData["customer"].lastName, lead:false});
            }
          })
        }

        // Load Date funds banked - Booking Date
        givenTransaction.viewDateFundsBanked = matchTransaction.bookingData.bookingData.bookingDate;
        if (matchTransaction.receiptData && matchTransaction.receiptData.receiptList.length > 0){
          let receiptDate:any = new Date(matchTransaction.receiptData.receiptList[0].receiptDate)
          if (receiptDate) {
            givenTransaction.viewDateFundsBanked = receiptDate;
          }
          // Load Receipt
          givenTransaction.viewReceiptData.data = matchTransaction.receiptData.receiptList
          givenTransaction.viewReceiptData.data.forEach((receipt: any) => {
            const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
            let value = '£0'; // Begin with £0 value and format positive/non-positive value below
            if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
            // Assign free-text value visible in UI below..
            receipt.freeText = 'Date: ' + receiptDate + '\n' +
              'Method: ' + receipt.paymentMethod + '\n' +
              'Value: ' + value;
          });
        }

        // Load Payment Method - Usually a Card
        givenTransaction.viewPaymentMethod = "Card";

        // Load Gross Client Funds - from viewMatchedBookingData [gross]
        givenTransaction.viewGrossClientFunds.currency = "GBP";
        givenTransaction.viewGrossClientFunds.amount = matchTransaction.bookingData.bookingData.gross

        // Load Tax - from viewMatchedBookingData [tax]
        givenTransaction.viewVAT = matchTransaction.bookingData.bookingData.tax;

        // Load Supplier Amount - from viewMatchedBookingData [net]
        givenTransaction.viewSupplierAmount.currency = "GBP"
        givenTransaction.viewSupplierAmount.amount = matchTransaction.bookingData.bookingData.net;

        // Load Reimbursement - Just Currency
        givenTransaction.viewReimbursement.currency = "GBP"

        // Load Commission -  Just Currency
        givenTransaction.viewCommission.currency = "GBP"

        // Load Guarantee Deducted - Amount & Currency
        givenTransaction.viewGuaranteeDeducted.currency = "GBP"
        if (this.viewPrfCollectedFrom == "Trust Account" && this.viewClaimCommission.checked == true){
          givenTransaction.viewGuaranteeDeducted.amount = 0.00
          if (matchTransaction.bookingData != null){
            const listOfElementData = matchTransaction.bookingData.elementData;
            listOfElementData.forEach((element:any)=>{
              if (element.supplierName == "Safe Seat Plan Guarantee - Standalone" && element.elementStatus == "booking"){
                if (Number(element.gross)>0){
                  let elementGross = Number(givenTransaction.viewGuaranteeDeducted.amount) + Number(element.gross);
                  givenTransaction.viewGuaranteeDeducted.amount = Number(elementGross).toFixed(2);
                } else {
                  if (Number(element.discount)>0){
                    let elementDiscount = Number(givenTransaction.viewGuaranteeDeducted.amount) + Number(element.discount);
                    givenTransaction.viewGuaranteeDeducted.amount = Number(elementDiscount).toFixed(2);
                  }
                }
               }
            })
          }
        }

      }
    });
    this.checkViewTransactionValues(false, givenTransaction);
  }

  // Setup the VAT for all transactions
  setupSingleBookingVAT() {
    this.viewPrfTransactionValuesList.forEach((givenTransaction:any)=>{ 
      givenTransaction.viewVAT = this.viewMatchedBookingData.tax;
    })
  }

  // Setup the VAT for all transactions
  setupMultipleBookingVAT() {
    this.viewPrfTransactionValuesList.forEach((givenTransaction:any)=>{
      this.viewMatchedBookingData.forEach((matchTransaction:any)=>{
        if (matchTransaction.bookingReference == givenTransaction.viewBookingReference)
        {
          givenTransaction.viewVAT = matchTransaction.bookingData.bookingData.tax;
        }
      });
    })
  }

  // Check if PRF Transaction Values form is complete (VIEW)
  checkViewTransactionValues(isCommission:boolean, givenRecord:any) {
    // Every time a user is: typing, changing date or chaning select, this function
    // will check if any of 3 fields are blank. If so, user will be blocked from next step until
    // the fields are filled in (like required - I couldnt make it work)
    if (this.viewMultipleBookingReference == true && givenRecord != false){
      givenRecord.viewPrfChanged = true
    }
    let thirdViewCompleted = true;
    if (this.viewPrfTransactionValuesList.length == 0) {
      if (this.viewPrfAddedTransactionValuesList.length == 0) {
        thirdViewCompleted = false;
      } 
    } else {
      this.viewPrfTransactionValuesList.forEach((transaction) => {
        transaction.viewIsNotComplete = false;
        if (transaction.viewClientSurname == "" || transaction.viewClientSurname == null || transaction.viewPaymentMethod == "" || transaction.viewPaymentMethod == null ||
          transaction.viewGrossClientFunds.amount == null || transaction.viewGrossClientFunds.amount == "" || transaction.viewGrossClientFunds.currency == "" || transaction.viewGrossClientFunds.currency == null ||
          (this.viewClaimClientRefund.checked == true && (transaction.viewClientRefund.amount == null || transaction.viewClientRefund.amount == "" || transaction.viewClientRefund.currency == null || transaction.viewClientRefund.currency == "")) ||
          (this.viewClaimSupplierPayment.checked == true && (transaction.viewSupplierAmount.amount == null || transaction.viewSupplierAmount.amount == "" || transaction.viewSupplierAmount.currency == null || transaction.viewSupplierAmount.currency == "")) ||
          (this.viewClaimReimbursement.checked == true && (transaction.viewReimbursement.amount == null || transaction.viewReimbursement.amount == "" || transaction.viewReimbursement.currency == null || transaction.viewReimbursement.currency == "")) ||
          (this.viewClaimCommission.checked == true && (transaction.viewCommission.amount == null || transaction.viewCommission.amount == "" || transaction.viewCommission.currency == "" || transaction.viewCommission.currency == null)) ||
          ((this.viewClaimCommission.checked == true && this.viewPrfCollectedFrom == "Trust Account") && (transaction.viewGuaranteeDeducted.currency == "" || transaction.viewGuaranteeDeducted.currency == null || transaction.viewGuaranteeDeducted.amount == "" || transaction.viewGuaranteeDeducted.amount == null)) || 
          ((this.viewClaimCommission.checked == true && 
            this.viewClaimClientRefund.checked == false && 
            this.viewClaimSupplierPayment.checked == false && 
            this.viewClaimReimbursement.checked == false) && 
            (transaction.viewSupplierAmount.amount == null || transaction.viewSupplierAmount.amount == ""  || 
            transaction.viewSupplierAmount.currency == "" || transaction.viewSupplierAmount.currency == null ))) {
            transaction.viewIsNotComplete = true;
          thirdViewCompleted = false;
        }
        if (this.viewMultipleBookingReference == true){
          if (transaction.viewBookingReference == ""){
            transaction.viewIsNotComplete = true;
            thirdViewCompleted = false;
          }
        }
        if (isCommission == false) {
          // Commission Calcalation
          if (this.viewClaimCommission.checked == true && transaction.viewGrossClientFunds.amount != null){
            // Commission 
            if (this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false && this.viewClaimClientRefund.checked == false){
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewSupplierAmount.amount 
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewSupplierAmount.amount 
              }
            }
            // Supplier Payment
            if (this.viewClaimSupplierPayment.checked == true && transaction.viewSupplierAmount.amount != null) {
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewSupplierAmount.amount 
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewSupplierAmount.amount 
              }
            }
            // Reimbursement
            if (this.viewClaimReimbursement.checked == true && transaction.viewReimbursement.amount != null) {
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewReimbursement.amount 
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewReimbursement.amount 
              }
            }
            // Client Refund
            if (this.viewClaimClientRefund.checked == true && transaction.viewClientRefund.amount != null) {
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewClientRefund.amount
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewClientRefund.amount 
              }
            }        
            if (transaction.createVAT != null && transaction.createVAT != "NaN" && transaction.createVAT != undefined  && transaction.createVAT != "" && transaction.createVAT > 0.00 
                && transaction.viewCommission.amount != null && transaction.viewCommission.amount > 0.00) {
              transaction.viewCommission.amount = transaction.viewCommission.amount  - transaction.viewVAT
            } 
            transaction.viewCommission.amount = Number(transaction.viewCommission.amount).toFixed(2);
          }
        }
      });
    }
    
    if (this.viewPrfAddedTransactionValuesList.length > 0){
      this.viewPrfAddedTransactionValuesList.forEach((transaction) => {
        transaction.viewIsNotComplete = false;
        if (transaction.viewClientSurname == "" || transaction.viewClientSurname == null || transaction.viewPaymentMethod == "" || transaction.viewPaymentMethod == null ||
          transaction.viewGrossClientFunds.amount == null || transaction.viewGrossClientFunds.amount == "" || transaction.viewGrossClientFunds.currency == "" || transaction.viewGrossClientFunds.currency == null ||
          (this.viewClaimClientRefund.checked == true && (transaction.viewClientRefund.amount == null || transaction.viewClientRefund.amount == "" || transaction.viewClientRefund.currency == null || transaction.viewClientRefund.currency == "")) ||
          (this.viewClaimSupplierPayment.checked == true && (transaction.viewSupplierAmount.amount == null || transaction.viewSupplierAmount.amount == "" || transaction.viewSupplierAmount.currency == null || transaction.viewSupplierAmount.currency == "")) ||
          (this.viewClaimReimbursement.checked == true && (transaction.viewReimbursement.amount == null || transaction.viewReimbursement.amount == "" || transaction.viewReimbursement.currency == null || transaction.viewReimbursement.currency == "")) ||
          (this.viewClaimCommission.checked == true && (transaction.viewCommission.amount == null || transaction.viewCommission.amount == "" || transaction.viewCommission.currency == "" || transaction.viewCommission.currency == null)) ||
          ((this.viewClaimCommission.checked == true && this.viewPrfCollectedFrom == "Trust Account") && (transaction.viewGuaranteeDeducted.currency == "" || transaction.viewGuaranteeDeducted.currency == null || transaction.viewGuaranteeDeducted.amount == "" || transaction.viewGuaranteeDeducted.amount == null)) || 
          ((this.viewClaimCommission.checked == true && 
            this.viewClaimClientRefund.checked == false && 
            this.viewClaimSupplierPayment.checked == false && 
            this.viewClaimReimbursement.checked == false) && 
            (transaction.viewSupplierAmount.amount == null || transaction.viewSupplierAmount.amount == ""  || 
            transaction.viewSupplierAmount.currency == "" || transaction.viewSupplierAmount.currency == null ))) {
          transaction.viewIsNotComplete = true;
          thirdViewCompleted = false;
        }
        if (this.viewMultipleBookingReference == true){
          if (transaction.viewBookingReference == ""){
            transaction.viewIsNotComplete = true;
            thirdViewCompleted = false;
          }
        }
        if (isCommission == false) {
          // Commission Calcalation
          if (this.viewClaimCommission.checked == true && transaction.viewGrossClientFunds.amount != null){
            // Commission 
            if (this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false && this.viewClaimClientRefund.checked == false){
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewSupplierAmount.amount
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewSupplierAmount.amount
              }
            }
            // Supplier Payment
            if (this.viewClaimSupplierPayment.checked == true && transaction.viewSupplierAmount.amount != null) {
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewSupplierAmount.amount
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewSupplierAmount.amount
              }
            }
            // Reimbursement
            if (this.viewClaimReimbursement.checked == true && transaction.viewReimbursement.amount != null) {
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewReimbursement.amount
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewReimbursement.amount
              }
            }
            // Client Refund
            if (this.viewClaimClientRefund.checked == true && transaction.viewClientRefund.amount != null) {
              if (this.viewPrfCollectedFrom == "Trust Account" && transaction.viewGuaranteeDeducted.amount != null){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewGuaranteeDeducted.amount - transaction.viewClientRefund.amount
              }
              if (this.viewPrfCollectedFrom == "Trading Account"){
                transaction.viewCommission.amount = transaction.viewGrossClientFunds.amount - transaction.viewClientRefund.amount
              }
            }
            transaction.viewCommission.amount = Number(transaction.viewCommission.amount).toFixed(2);
          }
        }
      });
    }    

    if (this.viewClaimCommission.checked == true && this.viewPrfCollectedFrom == "") {
      thirdViewCompleted = false;
    }

    this.thirdViewCompleted = thirdViewCompleted;
  }

  // This is used for Trustee Executives which are allowed to fixed the transaction values,
  // when the members add wrong values. (Office Control)
  checkViewExTransactionValues() {
    this.exTransactionViewChanged = false;
  }

  // When a Trustee set's the re-assigned value equal with the creator or a member ..
  // it should make the PRF status as an Action Required
  checkReAssignTo() {
    if (this.reAssignTo == this.viewPrfCreator || this.reAssignTo == 'All') {
      this.viewPrfStatus = "Action Required"
    }
    if (this.reAssignTo == "Trustees"){
      this.viewPrfStatus = "Authorisation Pending"
    }
    this.checkStatus();
  }

  // If the PRF status is changed to Action Required it should load the actions if not already by the
  checkStatus() {
    this.checkAuthorisedEmail = false;
    this.viewPrfOfficeNotes = 'PRF status updated to '+ this.viewPrfStatus;
    if (this.viewPrfStatus == "Authorised") {
      let authorisedEmail = "Dear " + this.viewPrfCreator + ",\n\nThank you for submitting your PRF.\n\nWe are pleased to confirm that the payment (PRF-" + this.viewPrfNumber + ") to " + this.viewPrfSupplierName + " has been authorised today." + "\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees";
      this.viewPrfOfficeNotes = authorisedEmail;
      this.checkAuthorisedEmail = true;
    } else if (this.viewPrfStatus == "Action Required") {
      this.loadTrusteesActions();
      if (this.viewPrfActionRequiredMessage = "Select a reason...") {
        this.viewPrfActionRequiredMessage = null;
      }
      this.viewPrfOfficeNotes = 'PRF status updated to '+ this.viewPrfStatus;
    } else {
      this.viewPrfActionRequiredMessage = null;
    }
  }

  // This checks which action required is selected and changes both officeNotes and actionRequired values of PRF's Status
  checkActionRequired() {
    this.viewPrfOfficeNotes = 'PRF status updated to '+ this.viewPrfStatus
    this.viewPrfActionRequiredMessage = this.listOfActions[this.selectedAction].text;
    this.viewPrfEmailText = this.listOfActions[this.selectedAction].email;
    this.emailOptions = [
      { key: this.userEmail, value: this.userEmail },
      { key: this.viewPrfCreatorEmail, value: this.viewPrfCreatorEmail },
    ]
  }

  // Load all trustees actions after he will assign someone to change the PRF status to "Action Required"
  loadTrusteesActions() {
    this.listOfActions = [
      { value: 0, text: "Select a reason...", email: "" },
      {
        value: 1, text: "Atol Certificate required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nPlease can you attach a copy of the ATOL certificate.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-" +
          this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      },
      { value: 2, text: "Cheque not signed", email: "" },
      {
        value: 3, text: "Client banking and confirmation of supplier payment required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nAs a random sample please can you attach copies of the following client banking receipts and confirmation that the supplier has been pre-paid.\n\nPlease log into Worldpay and go to Payments and you can search the passenger and bring up a page called Payment & Order Details.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
          + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      },
      {
        value: 4, text: "Client banking receipts and supplier invoices required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nAs a random sample please can you attach copies of the following client banking receipts and supplier invoices.\n\n-\n-\n-\n\nPlease log into Worldpay and go to Payments and you can search the passenger and bring up a page called Payment & Order Details.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
          + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      },
      {
        value: 5, text: "Client banking receipts required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nAs a random sample please can you attach the following client banking receipts:\n\n-\n-\n-\n\nPlease log into Worldpay and go to Payments and you can search the passenger and bring up a page called Payment & Order Details.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
          + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      },
      {
        value: 6, text: "Client banking supplier invoices and confirmation of supplier payment required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nAs a random sample please can you attach copies of the following client banking receipts, supplier invoices and confirmation that the supplier has been pre-paid.\n\n-\n-\n-\n\nPlease log into Worldpay and go to Payments and you can search the passenger and bring up a page called Payment & Order Details.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
          + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      },
      {
        value: 7, text: "Client funds not cleared", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nWe are currently unable to see the client funds clearing into the trust account. We will check the trust account statement again on the next working day.\n\nPlease can you clarify in the Step 4 notes box if and when the client funds have been banked in PRF-"
          + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      }]
    if (this.viewPrfClientReturnDateEnable == true) {
      this.listOfActions.push({
        value: 8, text: "Client not returned from travel", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nThe final client does not return until " + this.convertDate(this.viewPrfClientReturnDate) + ".\nTherefore, we will release payment on the next working day after this date.\n\nIf you wish for the PRF to be cancelled please let me know in the Step 4 notes box.\nOtherwise, please send the Online PRF back through to me in PRF-"
          + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      })
    } else {
      this.listOfActions.push({
        value: 8, text: "Client not returned from travel", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nThe final client does not return until " + "N/A" + ".\nTherefore, we will release payment on the next working day after this date.\n\nIf you wish for the PRF to be cancelled please let me know in the Step 4 notes box.\nOtherwise, please send the Online PRF back through to me in PRF-"
          + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
      })
    }
    let restOfActions = [{
      value: 9, text: "Confirmation of supplier payment required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nPlease can you attach confirmation that the supplier has been pre-paid.\nExamples of pre-payment may include a copy of your trading account bank statement or wire transfer confirmation page.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
        + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    { value: 10, text: "Direct debit mandate cannot be set up", email: "" },
    { value: 11, text: "Direct debit mandate not signed", email: "" },
    {
      value: 12, text: "Flight details required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nPlease can you attach a copy of a supplier invoice showing the flight details for this booking.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF"
        + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    {
      value: 13, text: "Future bookings", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nAs you may be aware this payment release amount is a high proportion of the current trust account balance.\nWe need to establish that the remaining funds in the trust account is sufficient to meet all future travel.\n\nTo assist the team with the processing of your PRF, could you please let me know of all deposits and/or balances that you have collected from your clients and have yet to pay out to suppliers or claim as commission.\n\nPlease supply the client name, date of travel and the amount collected to date.\n\nPlease let me know if you have any queries and I look to hearing from you soon. (PRF-"
        + this.viewPrfNumber + ")\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    {
      value: 14, text: "Information required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nPlease can you attach the following information.\n\n-\n-\n-\n\nPlease can you log back into the Online PRF System and attach the required information \nonto PRF-"
        + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    {
      value: 15, text: "Invoices required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nAs a random sample please can you attach copies of the following supplier invoices.\n\n-\n-\n- \n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
        + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    {
      value: 16, text: "Payment reload required", email:
        "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nUnfortunately, the Bankline transfer has expired.\nIn order for TTA Trustees to authorise the transfer please can you update the payment date within www.natwest.com/bankline and Edit Payment Date.\n\nFor future reference, please be aware that you must set up Bankline payments as Standard transfers three working days in advance of the date of PRF submission.\n\nPlease re-enter Online PRF, attach the reloaded bankline transfer to PRF-" + this.viewPrfNumber + " and send the Online PRF back to me."
        + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    { value: 17, text: "Prf does not match onetouch booking", email: "" },
    {
      value: 18, text: "Safi not issued", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nThe SAFI reference page is showing that SAFI has not been issued on this booking.\n\nIf you have not done so already please can you enter One Touch and issue the policy.\nPlease attach the amended One Touch attachment in Step 2 of PRF-" + this.viewPrfNumber + " and send the PRF back to me."
        + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    {
      value: 19, text: "Sfc not issued", email:
        "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nThe SFC reference page is showing that SFC has not been issued on this booking.\nIf you have not done so already please can you enter One Touch and issue the policy.\nPlease attach the amended One Touch attachment in Step 2 of PRF-" + this.viewPrfNumber + " and send the PRF back to me."
        + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    {
      value: 20, text: "Supplier cancellation invoice required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nPlease can you attach a copy of the supplier cancellation invoice.\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
        + this.viewPrfNumber + ".\n\nKind Regards,\n" + "\n\nTTA Trustees"
    },
    {
      value: 21, text: "Supplier invoices and confirmation of supplier payment required", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nPlease can you attach copies of the following supplier invoices and confirmation that the supplier has been pre-paid as a random sample.\n\n-\n-\n-\n\nPlease log back into the Online PRF System and attach the required information \nonto PRF-"
        + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    },
    {
      value: 22, text: "Taps not cleared", email: "Dear " + this.reAssignTo + ",\n\nThank you for submitting your PRF.\n\nWe are unable to see whether the supplier has been paid.\nTo assist the team with you request please can you advise if and when the TAPS payment has been confirmed.\n\nPlease log back into the Online PRF System and add to the Step 4 notes box in PRF-"
        + this.viewPrfNumber + ".\n\nKind Regards,\n" + this.userName + "\n\nTTA Trustees"
    }
    ]
    restOfActions.forEach((action) => {
      this.listOfActions.push(action);
    })
  }

  // This is when you have selected that the client didn't return from travel,
  // which will show the financial protection options
  setFP(event: any, type: String) {
    if (type == "Create") {
      if (event.value == 1) {
        this.createFinancialProtectionEnable = false;
        this.createClientReturnDateEnable = false;
      } else {
        this.createFinancialProtectionEnable = true;
      }
      if (this.createFinancialProtectionEnable == true && this.createFinancialProtection.checks[0] == true) {
        this.setCRD({value:"None"},"Create");
      }
      this.checkOnlinePrfInformation();
    } else {
      if (event.value == 1) {
        this.viewPrfFinancialProtectionEnable = false;
        this.viewPrfClientReturnDateEnable = false;
      } else {
        this.viewPrfFinancialProtectionEnable = true;
      }
      if (this.viewPrfFinancialProtectionEnable == true && this.viewPrfFinancialProtection.checks[0] == true) {
        this.setCRD({value:"None"},"View");
      }
      this.checkViewOnlinePrfInformation();
    }
  }

  // This is when you have selected that the client didn't return from travel,
  // and the financial protection options is "None"
  // Then you will need to add a client return date
  setCRD(event: any, type: String) {
    if (type == "Create") {
      if (event.value == "None") {
        if (this.createFinancialProtection.checks[0] == false){
          this.createClientReturnDateEnable = false;
        } else {
          if (this.createFinancialProtection.checks[0] == true && this.createClaimCommission.checked == false && this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false  && this.createClaimClientRefund.checked == true) {
            this.createClientReturnDateEnable = false;
          } else {
            if (this.createFinancialProtectionEnable == true && this.createFinancialProtection.checks[0] == true){
              this.createClientReturnDateEnable = true;
            }
          }
        }
      } else {
        if (this.createFinancialProtection.checks[0] == false){
          this.createClientReturnDateEnable = false;
        }
      }
      this.checkOnlinePrfInformation();
    } else {
      if (event.value == "None") {
        if (this.viewPrfFinancialProtection.checks[0] == false){
          this.viewPrfClientReturnDateEnable = false;
        } else {
          if (this.viewPrfFinancialProtection.checks[0] == true && this.viewClaimCommission.checked == false && this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false  && this.viewClaimClientRefund.checked == true) {
            this.viewPrfClientReturnDateEnable = false;
          } else {
            if (this.viewPrfFinancialProtectionEnable == true && this.viewPrfFinancialProtection.checks[0] == true){
              this.viewPrfClientReturnDateEnable = true;
            }
          }
        }        
      } else {
        if (this.viewPrfFinancialProtection.checks[0] == false){
          this.viewPrfClientReturnDateEnable = false;
        }
      }
      this.checkViewOnlinePrfInformation();
    }
  }

  // For each claim that is being clicked then the payment method of that ..
  // .. claim will be revealed
  clickedClaim(claimStatus: MatCheckboxChange, claim: String) {
      if (claimStatus.checked == true) {
        if (claim == "Supplier Payment") {
          this.createClaimSupplierPayment.checked = true;          
        } else if (claim == "Commission") {
          this.createClaimCommission.checked = true;
        } else if (claim == "Reimbursement") {
          this.createClaimReimbursement.checked = true;
        } else if (claim == "Client Refund") {
          this.createClaimClientRefund.checked = true;
        }
        let claimCounter = 0;
        let supplierPaymentIsCheck = false;
        let commissionIsCheck = false;
        let reimbursementIsCheck = false;
        let clientRefundIsCheck = false;
        if (this.createClaimSupplierPayment.checked == true){
          claimCounter = claimCounter + 1;
          supplierPaymentIsCheck = true
        }
        if (this.createClaimCommission.checked == true){
          claimCounter = claimCounter + 1;
          commissionIsCheck = true
        } 
        if (this.createClaimReimbursement.checked == true){
          claimCounter = claimCounter + 1;
          reimbursementIsCheck = true;
        }
        if (this.createClaimClientRefund.checked == true){
          claimCounter = claimCounter + 1;
          clientRefundIsCheck =  true;
        }
        if (claimCounter > 2) {
          if (claim == "Supplier Payment") {
            if (commissionIsCheck == true){
              this.createClaimCommission.checked = false
            } else {
              if (reimbursementIsCheck == true){
                this.createClaimReimbursement.checked = false
              } else {
                this.createClaimClientRefund.checked = false
              }
            }
          }
          if (claim == "Commission") {
            if (supplierPaymentIsCheck == true){
              this.createClaimSupplierPayment.checked = false
            } else {
              if (reimbursementIsCheck == true){
                this.createClaimReimbursement.checked = false
              } else {
                this.createClaimClientRefund.checked = false
              }
            }
          }
          if (claim == "Reimbursement") {
            if (clientRefundIsCheck == true){
              this.createClaimClientRefund.checked = false
            } else {
              if (supplierPaymentIsCheck == true){
                this.createClaimSupplierPayment.checked = false
              } else {
                this.createClaimCommission.checked = false
              }
            }
          }
          if (claim == "Client Refund") {
            if (reimbursementIsCheck == true){
              this.createClaimReimbursement.checked = false
            } else {
              if (commissionIsCheck == true){
                this.createClaimCommission.checked = false
              } else {
                this.createClaimSupplierPayment.checked = false
              }
            }
          }
        }        
      } else {
        if (claim == "Supplier Payment") {
          this.createClaimSupplierPayment.checked = false;
        } else if (claim == "Commission") {
          this.createClaimCommission.checked = false;
          this.createCollectedFrom = "";
        } else if (claim == "Reimbursement") {
          this.createClaimReimbursement.checked = false;
        } else if (claim == "Client Refund") {
          this.createClaimClientRefund.checked = false;
        }
      }
      if (this.createClaimCommission.checked == true && 
        this.createClaimSupplierPayment.checked == false && 
        this.createClaimReimbursement.checked == false &&
        this.createClaimClientRefund.checked == false){
          this.createSupplierNoneIsAvailable = true;
          if (this.createSupplierName == ""){
            this.createSupplierName = "None"
          }
      } else {
        if (this.createNotMatchingSuppliers == false){
          this.createSupplierNoneIsAvailable = false;
          if (this.createSupplierName == "None"){
            this.createSupplierName = "";
          }
        }
      }
      if (this.createFinancialProtection.checks[0] == true && this.createClaimCommission.checked == false && this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false  && this.createClaimClientRefund.checked == true) {
        this.createClientReturnDateEnable = false;
      } else {
        if (this.createFinancialProtectionEnable == true && this.createFinancialProtection.checks[0] == true){
          this.createClientReturnDateEnable = true;
        }
      }
      this.checkOnlinePrfInformation();
      this.checkTransactionValues(false);
  }

  clickedViewClaim(claimStatus: MatCheckboxChange, claim: String) {
    if (claimStatus.checked == true) {
        if (claim == "Supplier Payment") {
            this.viewClaimSupplierPayment.checked = true;          
        } else if (claim == "Commission") {
            this.viewClaimCommission.checked = true;
        } else if (claim == "Reimbursement") {
            this.viewClaimReimbursement.checked = true;
        } else if (claim == "Client Refund") {
            this.viewClaimClientRefund.checked = true;
        }
        let claimCounter = 0;
        let supplierPaymentIsCheck = false;
        let commissionIsCheck = false;
        let reimbursementIsCheck = false;
        let clientRefundIsCheck = false;
        if (this.viewClaimSupplierPayment.checked == true){
            claimCounter = claimCounter + 1;
            supplierPaymentIsCheck = true
        }
        if (this.viewClaimCommission.checked == true){
            claimCounter = claimCounter + 1;
            commissionIsCheck = true
        } 
        if (this.viewClaimReimbursement.checked == true){
            claimCounter = claimCounter + 1;
            reimbursementIsCheck = true;
        }
        if (this.viewClaimClientRefund.checked == true){
            claimCounter = claimCounter + 1;
            clientRefundIsCheck =  true;
        }
        if (claimCounter > 2) {
            if (claim == "Supplier Payment") {
                if (commissionIsCheck == true){
                    this.viewClaimCommission.checked = false
                } else {
                    if (reimbursementIsCheck == true){
                        this.viewClaimReimbursement.checked = false
                    } else {
                        this.viewClaimClientRefund.checked = false
                    }
                }
            }
            if (claim == "Commission") {
                if (supplierPaymentIsCheck == true){
                    this.viewClaimSupplierPayment.checked = false
                } else {
                    if (reimbursementIsCheck == true){
                        this.viewClaimReimbursement.checked = false
                    } else {
                        this.viewClaimClientRefund.checked = false
                    }
                }
            }
            if (claim == "Reimbursement") {
                if (clientRefundIsCheck == true){
                    this.viewClaimClientRefund.checked = false
                } else {
                    if (supplierPaymentIsCheck == true){
                        this.viewClaimSupplierPayment.checked = false
                    } else {
                        this.viewClaimCommission.checked = false
                    }
                }
            }
            if (claim == "Client Refund") {
                if (reimbursementIsCheck == true){
                    this.viewClaimReimbursement.checked = false
                } else {
                    if (commissionIsCheck == true){
                        this.viewClaimCommission.checked = false
                    } else {
                        this.viewClaimSupplierPayment.checked = false
                    }
                }
            }
        }        
    } else {
        if (claim == "Supplier Payment") {
            this.viewClaimSupplierPayment.checked = false;
        } else if (claim == "Commission") {
            this.viewClaimCommission.checked = false;
            this.viewPrfCollectedFrom = "";
        } else if (claim == "Reimbursement") {
            this.viewClaimReimbursement.checked = false;
        } else if (claim == "Client Refund") {
            this.viewClaimClientRefund.checked = false;
        }
    }
    if (this.viewClaimCommission.checked == true && 
        this.viewClaimSupplierPayment.checked == false && 
        this.viewClaimReimbursement.checked == false &&
        this.viewClaimClientRefund.checked == false){
            this.viewPrfSupplierNoneIsAvailable = true;
            if (this.viewPrfSupplierName == ""){
                this.viewPrfSupplierName = "None"
            }
    } else {
        if (this.viewNotMatchingSuppliers == false){
            this.viewPrfSupplierNoneIsAvailable = false;
            if (this.viewPrfSupplierName == "None"){
                this.viewPrfSupplierName = "";
            }
        }
    }
    if (this.viewPrfFinancialProtection.checks[0] == true && this.viewClaimCommission.checked == false && this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false  && this.viewClaimClientRefund.checked == true) {
        this.viewPrfClientReturnDateEnable = false;
    } else {
        if (this.viewPrfFinancialProtectionEnable == true && this.viewPrfFinancialProtection.checks[0] == true){
            this.viewPrfClientReturnDateEnable = true;
        }
    }
    if (this.viewClaimCommission.checked == false){
      this.viewPrfCollectedFrom = "";
    } 
    this.checkViewOnlinePrfInformation();
    this.checkViewTransactionValues(false,false);
  }


  // Before we enable the prfView to true which will show the currently viewed PRF ...
  // ... we need to set the data of the viewed PRF.
  loadingViewPrfData(givenPrfIndex: any) {
    this.setViewPrfData(givenPrfIndex);
    this.prfView = true;
  }

  // Clears all the data from the currently viewed PRF
  async clearViewedPrf() {
    this.firstViewCompleted = false;
    this.thirdViewCompleted = false;
    this.exTransactionViewChanged = false;
    this.viewPrfNumber = "";   
    this.viewPrfAssignedTo = "";
    this.viewPrfDateSubmitted = "";
    this.viewPrfSupplierName = "";
    this.viewPrfSupplierNoneIsAvailable = false;
    this.viewPrfPassengerName = "";
    this.viewPrfStatus = "";
    this.viewPrfAmountOne = 0.00;
    this.viewPrfCurrencyOne = "";
    this.viewPrfClaimOne = "";
    this.viewPrfPaymentMethodOne = "";
    this.viewPrfAmountTwo = null;
    this.viewPrfCurrencyTwo = null;
    this.viewPrfClaimTwo = null;
    this.viewPrfPaymentMethodTwo = null;
    this.viewPrfClientFundsBanked = false;
    this.viewPrfThirdPartyPaperwork = false;
    this.viewPrfMemberAccountBalanceAmount = null;
    this.viewPrfMemberAccountBalanceCurrency = null;
    this.viewPrfComCheckForAtol = null;
    this.viewPrfComCheckForPsa = null;
    this.viewPrfComCheckForClientsReturned = null;
    this.viewPrfComCheckForSfc = null;
    this.viewPrfActionRequiredMessage = null;
    this.viewPrfIsClientFundsReceived = 2;
    this.viewPrfHasClientReturned = 2;
    this.viewPrfFinancialProtection = {  
      checks:[false,false,false,false,false], 
      methods: ["None","ATOL","PSA","SFC / SAFI","TTA Member"]
    };
    this.viewPrfFinancialProtectionText = "";
    this.viewPrfFinancialProtectionEnable = false;
    this.viewPrfClientReturnDate = null;
    this.viewPrfClientReturnDateEnable = false;
    this.viewPrfNotesToTrustee = null;
    this.viewPrfCollectedFrom = null;
    this.viewPrfCompany = "";
    this.viewPrfOperation = "";
    this.viewPrfTradeCode = "";
    this.viewPrfCreator = "";
    this.viewPrfCreatorEmail = "";  
    this.viewPrfTransactionValuesList = []; 
    this.viewPrfDeletedTransactionValuesList = [];
    this.viewPrfAddedTransactionValuesList = [];
    this.viewClaimSupplierPayment = { 
      checked: false, 
      paymentMethods: {checks:[false,false,false,false,false,false,false], 
      methods: ["Natwest Faster","Natwest CHAPS","Barclays","Corpay","Monteith","Amex Forex Transfer","Other"]}};
    this.viewClaimCommission = { checked: false, paymentMethod: "" };
    this.viewClaimReimbursement = { checked: false, paymentMethod: "" };
    this.viewClaimClientRefund = { checked: false, paymentMethod: "" };
    this.viewPrfDocumentsData = new MatTableDataSource<any>();
    this.viewPrfAuditListData = new MatTableDataSource<any>();   
    // Used for Create and View Below 
    this.invoiceFilesAttr = [];
    this.scheduleFilesAttr = [];
    this.confirmationOfPaymentFilesAttr = [];
    this.rbsBankFileAttr = [];
    this.newInvoiceFilesLoaded = "";
    this.newScheduleFilesLoaded = "";
    this.newRbsFilesLoaded = "";
    this.newCoPFilesLoaded = "";
    // Used for Create and View Above
    this.viewPrfOfficeNotes = ""; 
    this.viewPrfEmailText = "";
    this.reAssignTo = "0";
    this.selectedAction = 0;
    this.listOfActions = []
    this.emailTo = [];
    // SMART PRF VARS 
    this.viewBookingReference = "";
    this.viewMatchedBookingReference = "";
    this.viewMatchedBookingData = [];
    this.viewCustomerData = {};
    this.viewListOfElementData = [];
    this.clearAllFromViewListOfCustomers();
    this.attachedDocs.data = [];
    this.viewMultipleBookingReference = false;
    this.viewPrfLinkedBookings = [];
    this.viewPrfBookingReference = null;
    this.viewListOfPaymentMethods = [];
    this.viewPrfAmountOneTrustee = 0.00;
    this.viewPrfAmountTwoTrustee = 0.00;
    this.viewPrfCurrencyOneTrustee = "GBP";
    this.viewPrfCurrencyTwoTrustee = "GBP";
    this.viewNotMatchingSuppliers = true;
    this.viewPrfBranchName = "";
    this.receiptData.data = [];
    this.sfcAvailableToReGenerate = false;
  }

  // When 'VIEW' PRF this will be used to reset all PRF's data when 'Refresh' Booking or Supplier
  clearBookingPRFView() {
    this.firstViewCompleted = false;
    this.thirdViewCompleted = false;
    this.exTransactionViewChanged = false;
    this.viewPrfIsClientFundsReceived = 2;
    this.viewPrfHasClientReturned = 2;
    this.viewPrfSupplierName = "";
    this.viewPrfSupplierNoneIsAvailable = false;
    this.viewClaimSupplierPayment = { 
      checked: false, paymentMethods: {checks:[false,false,false,false,false,false,false], 
      methods: ["Natwest Faster","Natwest CHAPS","Barclays","Corpay","Monteith","Amex Forex Transfer","Other"]
    }};
    this.viewClaimCommission = { checked: false, paymentMethod: "" };
    this.viewClaimReimbursement = { checked: false, paymentMethod: "" };
    this.viewClaimClientRefund = { checked: false, paymentMethod: "" };
    this.viewPrfFinancialProtection = {  
      checks:[false,false,false,false,false], 
      methods: ["None","ATOL","PSA","SFC / SAFI","TTA Member"]
    };
    this.viewPrfFinancialProtectionEnable = false;
    this.viewPrfClientReturnDate = null;
    this.viewPrfClientReturnDateEnable = false;
    this.viewBookingReference = "";
    this.viewMatchedBookingReference = "";
    this.viewMatchedBookingData = [];
    this.viewCustomerData = {};
    this.viewListOfElementData = [];
    this.viewListOfReceiptData = [];
    this.viewPrfCollectedFrom = "";
    this.clearAllFromViewListOfCustomers();
  }

  // Clears all the data from the currently creating PRF
  async clearCreatePrf() {
    this.firstCompleted = false;
    this.secondCompleted = false;
    this.thirdCompleted = false;
    this.createIsOneTouchBooking = 2;
    this.preAttachOfBooking = true;
    this.createIsClientFundsReceived = 2;
    this.createHasClientReturned = 2;
    this.createSupplierName = "";
    this.createSupplierNoneIsAvailable = false;
    this.createTypesOfClaim = "";
    this.createClaimSupplierPayment = { 
      checked: false, 
      paymentMethods: {
        checks:[false,false,false,false,false,false,false], 
        methods: ["Natwest Faster","Natwest CHAPS","Barclays","Corpay","Monteith","Amex Forex Transfer","Other"]
    }};
    this.createClaimCommission = { checked: false, paymentMethod: "" };
    this.createClaimReimbursement = { checked: false, paymentMethod: "" };
    this.createClaimClientRefund = { checked: false, paymentMethod: "" };
    this.createAssignedTo = "";
    this.createDateSubmitted = "";
    this.createPassengerName = "";
    this.createAmount = "";
    this.createFinancialProtection = {  
      checks:[false,false,false,false,false], 
      methods: ["None","ATOL","PSA","SFC / SAFI","TTA Member"]
    };
    this.createFinancialProtectionEnable = false;
    this.createClientReturnDate = null;
    this.createClientReturnDateEnable = false;
    this.createNotesToTrustee = "";
    this.createTransactionValuesList = [
      {
      createBookingReference: "",
      createClientSurname: "", 
      createDateFundsBanked: null, 
      createPaymentMethod: "",
      createGrossClientFunds: { currency: "", amount: null }, 
      createGuaranteeDeducted: { currency: "", amount: null },
      createSupplierAmount: { currency: "", amount: null }, 
      createCommission: { currency: "", amount: null },
      createClientRefund: { currency: "", amount: null }, 
      createReimbursement: { currency: "", amount: null },
      createListOfSurnames: [], 
      createReceiptData: new MatTableDataSource<any>(), 
      createShowReceipt: false,
      createIsNotComplete: true
      }
    ];
    this.createCollectedFrom = "";
    this.invoiceFilesAttr = [];
    this.scheduleFilesAttr = [];
    this.confirmationOfPaymentFilesAttr = [];
    this.rbsBankFileAttr = [];
    this.newInvoiceFilesLoaded = "";
    this.newScheduleFilesLoaded = "";
    this.newRbsFilesLoaded = "";
    this.newCoPFilesLoaded = "";
    // New 
    this.createBookingReference = "";
    this.createMatchedBookingReference = "";
    this.createMatchedBookingData = {};
    this.createCustomerData = {};
    this.createListOfElementData = [];
    this.clearAllFromCreateListOfCustomers();
    this.uploadedPrfDocuments.data = [];
    this.attachedDocs.data = [];
    this.createMultipleBookingReference = false;
    this.createListOfPaymentMethods = [];
    this.createListOfSuggestedBookings = [];
    this.createToogleSuggestedBookings = false;
    this.createSelectedSuggestedBooking = {};
    this.createSelectedSuggestions = [];
    this.filterBookingReference = "";
    this.filterClientName = "";
    this.filterBookingGroup = "";
    this.filterDepartureDate = null;
    this.createNotMatchingSuppliers = false;
    this.receiptData.data = [];
  }

  // Calls API :: /listMembersBookingReferences to receive all available booking references
  // Maybe filter this list in the future
  listAvailableBookingReferences(){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token,
    }
    this.prfService.listMembersBookingReferences(request).then((res: any) => {
      if (res["status"] == "OK"){
        if (res["res"] == "No Bookings Found"){
          this.createMatchedBookingReference = null;
        } else {
          this.originalListOfAvailableBookings = res["res"];
          this.listOfAvailableBookings = this.originalListOfAvailableBookings;
          if (this.listOfAvailableBookings.length == 0 || Session.mySession.getBranch().isSmartPrf == "no"){
            this.createMatchedBookingReference = null;
          }
        }
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (' + res["res"] + ')', "", "");
      }
      this.pageLoaded = true;
    }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2016S)', error, request);
    });
  }

  // Returns 'True' if the user is on the booking platform (only 'memberStaff' and 'memberManager')
  // userIsOnBookingPlatform(){
  //   if (Session.mySession.getUser().userType == "memberStaff" || Session.mySession.getUser().userType == "memberManager"){
  //     return true;
  //   }
  //   else {
  //     this.createMatchedBookingReference = null;
  //     return false;
  //   }
  // }

  // Used to switch the views between the tabs
  // Check's if the view is the List / New / Last Viewed PRF / Viewed PRF
  async switchView(view: any) {
    if (view == 'list') {
      this.prfsEditMode = false;
      this.filterPrfData();
      this.prfList = true;
      this.prfNew = false;
      this.prfView = false;
      this.prfReports = false;
      this.prfNoView = Array(this.prfNoView.length).fill(false);
    } else if (view == 'new') {
      this.prfsEditMode = false;
      this.checkIfTestingMember();
      await this.clearCreatePrf();
      this.setSuppliersList();
      this.listAvailableBookingReferences();
      this.prfList = false;
      this.prfNew = true;
      this.prfView = false;
      this.prfReports = false;
      this.prfNoView = Array(this.prfNoView.length).fill(false);
    } else if (view == -1) {
      this.prfsEditMode = false;
      this.filterPrfData();
      this.prfList = true;
      this.prfNew = false;
      this.prfView = false;
      this.prfReports = false;
    } else if (view == 'reports') {
      this.prfsEditMode = false;      
      this.setSuppliersList();
      this.prfList = false;
      this.prfNew = false;
      this.prfView = false;
      this.prfReports = true;      
      this.prfNoView = Array(this.prfNoView.length).fill(false);
    } else if (view == 'popup') {
      this.prfsEditMode = false;
      this.filterPrfData();
      this.prfList = true;
      this.prfNew = false;
      this.prfView = false;
      this.prfReports = false;
      this.prfNoView = Array(this.prfNoView.length).fill(false);
    } else {
      this.prfsEditMode = false;
      await this.clearViewedPrf();
      this.setSuppliersList();
      this.pageLoaded = false;
      this.prfView = false;
      this.prfList = false;
      this.prfNew = false;
      this.prfReports = false;
      this.prfNoView = Array(this.prfNoView.length).fill(false);
      this.prfNoView[view] = true;
      this.loadingViewPrfData(this.prfNoView.indexOf(true));
    }
  }

  // Check's if their one more PRF in the listed PRFs
  // If not then it will switch back to the list of PRFs -> switchView(view == -1)
  async showLastPrf() {
    if (this.listedViewPrfs.length > 0) {
      await this.switchView(this.prfNoView.length - 1)
    } else {
      this.switchView('list')
    }
  }

  // This is the server request where we get the currently viewed PRF data
  // We then get their response and add it into the prfBreakdown in operPrfTab()
  getPrfFromServer(givenRequest: any) {
    return new Promise((resolve, reject) => {
      this.prfService.getPrf(givenRequest).then((aPrf: any) => {
        if (aPrf.status != "OK") {
          this.sendMessageToDialog("", "Error with request status: " + aPrf.res, "", "");
        } else if (aPrf != null && aPrf.res.length != 0) {
          resolve(aPrf.res);
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2004S)', error, givenRequest);
        reject("Promise rejected");
      });
    });
  }

  // Setup the status format and generate a sign request before giving to signPrf()
  // Also checks for re-assign user and status change
  async trusteeSignPrf() {
    if (confirm("Are you sure you want to sign this PRF." + this.viewPrfNumber + "?")) {
      if (Session.mySession.getUser().userType == 'trustee') {
        this.pageLoaded = false;
        let signPrfRequest: any = {
          prfNumber: this.viewPrfNumber,
          status: this.viewPrfStatus,
          assignedTo: "",
          clientFundsBanked: this.viewPrfClientFundsBanked,
          thirdPartyPaperwork: this.viewPrfThirdPartyPaperwork,
          memberAccountBalanceAmount: this.viewPrfMemberAccountBalanceAmount,
          memberAccountBalanceCurrency: this.viewPrfMemberAccountBalanceCurrency,
          comCheckForAtol: this.viewPrfComCheckForAtol,
          comCheckForPsa: this.viewPrfComCheckForPsa,
          comCheckForClientsReturned: this.viewPrfComCheckForClientsReturned,
          comCheckForSfc: this.viewPrfComCheckForSfc,
          actionRequiredMessage: this.viewPrfActionRequiredMessage,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          company: Session.mySession.getUser().company,
          token: Session.mySession.get('user').token
        };
        if (this.reAssignTo != "0") {
          signPrfRequest.assignedTo = this.reAssignTo;
        } else {
          signPrfRequest.assignedTo = this.viewPrfAssignedTo;
        }
        if (this.viewPrfStatus == "Action Required") {
          if (this.viewPrfActionRequiredMessage != "Select a reason..." && this.viewPrfActionRequiredMessage != "" && this.viewPrfActionRequiredMessage != null) {
            this.viewPrfOfficeNotes = this.viewPrfOfficeNotes + '\n\n(' + this.viewPrfActionRequiredMessage +')' + '\n\n' + this.viewPrfEmailText;
            let fixedEmailText = this.viewPrfEmailText.replaceAll('&', 'and');
            fixedEmailText = fixedEmailText.replaceAll('\n','<br />');
            signPrfRequest.emailText = fixedEmailText;
            signPrfRequest.emailSubject = this.listOfActions[this.selectedAction].text +  " (PRF-"+ this.viewPrfNumber + ")";
            if (this.emailTo.length > 0){              
              signPrfRequest.emailTo = this.emailTo;
            } else {
              signPrfRequest.emailTo = this.viewPrfCreatorEmail;
            }            
          }
        }
        // Sign PRF with the help of PrfService (Also update prfBreakdown)
        this.prfService.signPrf(signPrfRequest).then((res: any) => {
          if (res.status != "OK") {
            this.pageLoaded = true;
            this.sendMessageToDialog("", "Error with request status: " + res.res, "", "");
          } else {
            this.pageLoaded = true;
            let fixedNotes = this.viewPrfOfficeNotes.replaceAll('&', 'and');
            fixedNotes = fixedNotes.replaceAll('\n','<br />');
            let auditRequest: any = {
              company: Session.mySession.getUser().company,
              tradeCode: Session.mySession.getUser().tradeCode,
              operation: Session.mySession.getUser().operation,
              token: Session.mySession.get('user').token,
              prfNumber: signPrfRequest.prfNumber,
              trusteeName: this.userName,
              assignedTo: signPrfRequest.assignedTo,
              status: this.viewPrfStatus,
              officeNotes: fixedNotes
            };
            this.createPrfsAudit(auditRequest,false);
            this.sendMessageToDialog("PRF-" + this.viewPrfNumber + " successfully signed", "", "", "");
            this.listedViewPrfs = [];
            this.prfNoView = [];
            this.prfBreakdown = [];
            this.clearViewedPrf();
            this.switchView("list");
          }
        });
      } else {
        this.sendMessageToDialog("", "Only Trustee(s) and Trustee Executive(s) users can sign a PRF", "", "");
      }
    }
  }

  checkCreateSupplierAmountAvailable(){
    if (this.createClaimSupplierPayment.checked == true)
    {
      return true;
    } else {
      if (this.createClaimCommission.checked == true && this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false && this.createClaimClientRefund.checked == false){
        return true;
      } else {
        return false;
      }
    }
  }

  checkViewSupplierAmountAvailable(){
    if (this.viewClaimSupplierPayment.checked == true)
    {
      return true;
    } else {
      if (this.viewClaimCommission.checked == true && this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false && this.viewClaimClientRefund.checked == false){
        return true;
      } else {
        return false;
      }
    }
  }

  // This function will change only the status of a PRF,
  // It can be just when a Trustee will view it for the first time ...
  //    ... This means the PRF will change from "Ready for signing" to "Processing"
  signPrf(givenRequest: any) {
    return new Promise((resolve, reject) => {
      this.prfService.signPrf(givenRequest).then((aPrf: any) => {
        if (aPrf.status != "OK") {
          this.sendMessageToDialog("", "Error with request status: " + aPrf.res, "", "");
        } else if (aPrf != null && aPrf.res.length != 0) {
          resolve(aPrf.res);
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2005S)', error, givenRequest);
        reject("Promise rejected");
      });
    });
  }

  // This function will change only the status of a PRF,
  // It can be just when a Trustee will view it for the first time ...
  //    ... This means the PRF will change from "Ready for signing" to "Processing"
  createPrfsAudit(givenRequest: any,bulkEdit: any) {
    return new Promise<void>((resolve, reject) => {
      this.prfService.createPrfsAudit(givenRequest).then((aPrfAudit: any) => {
        if (aPrfAudit.status != "OK") {
          this.sendMessageToDialog("", "Error with request status: " + aPrfAudit.res, "", "");
          resolve(aPrfAudit.res);
        } else if (aPrfAudit != null && aPrfAudit.res.length != 0) {
          resolve(aPrfAudit.res);
          if (bulkEdit == false){
            this.reloadPrfsAuditList(givenRequest);
          }
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2006S)', error, givenRequest);
        reject("Promise rejected");
      });
    });
  }

  // Reload Audit after opening or signing
  reloadPrfsAuditList(givenRequest:any) {
    this.prfService.listPrfsAudit(givenRequest).then((res:any)=>{
      if (res.status != "OK") {
        this.sendMessageToDialog("", "Error with request status: " + res.res, "", "");
      } else {
        let tempAuditList = new MatTableDataSource<any>();
        res.res.forEach((audit:any)=>{
          tempAuditList.data.push(audit);
        });
        this.viewPrfAuditListData.data = tempAuditList.data;
        if (tempAuditList.data.length > 0){
          if (this.viewPrfStatus != tempAuditList.data[tempAuditList.data.length-1].status){
            this.viewPrfStatus = tempAuditList.data[tempAuditList.data.length-1].status
          }
        }
      }
    });
  }

  // Reload Transactions after Viewing or Updating a PRF
  reloadPrfsTransactionValues(givenRequest:any, givenBookingReference:any, initialReload:boolean) {
    this.pageLoaded = false;
    this.prfService.listPrfsTransaction(givenRequest).then((res:any)=>{
      if (res.status != "OK") {
        this.pageLoaded = true;
        this.sendMessageToDialog("", "Error with request status (listPrfsTransaction): " + res.res, "", "");
      } else {
        let tempTransactionList:any = [];
        res.res.forEach((transaction:any)=>{
          let tempTransactionValue: any = {
            transactionID: transaction["id"],
            viewBookingReference: transaction["bookingReference"], 
            viewClientSurname: transaction["clientSurname"], 
            viewDateFundsBanked: transaction["dateFundsBanked"], 
            viewPaymentMethod: transaction["paymentMethod"], 
            viewGrossClientFunds: { currency: transaction["grossCurrency"], amount: transaction["grossAmount"] }, 
            viewGuaranteeDeducted: { currency: transaction["guaranteeDeductedCurrency"], amount: transaction["guaranteeDeductedAmount"] },
            viewSupplierAmount: { currency: transaction["supplierAmountCurrency"], amount: transaction["supplierAmount"] }, 
            viewCommission: { currency: transaction["commissionCurrency"], amount: transaction["commissionAmount"] },
            viewClientRefund: { currency: transaction["clientRefundCurrency"], amount: transaction["clientRefundAmount"] }, 
            viewReimbursement: { currency: transaction["reimbursementCurrency"], amount: transaction["reimbursementAmount"] },
            viewListOfClientSurnames: [],
            viewReceiptData: new MatTableDataSource<any>(), 
            viewShowReceipt: false,
            viewIsNotComplete: true,
            viewPrfChanged: false
          };
          // Check in case of old data model of Payment Methods [Solo,Maestro,Switch,Debit,Credit] are now grouped to [Card]
          if (tempTransactionValue.viewPaymentMethod == "Solo" || tempTransactionValue.viewPaymentMethod == "Maestro" || tempTransactionValue.viewPaymentMethod == "Switch" || tempTransactionValue.viewPaymentMethod == "Debit" || tempTransactionValue.viewPaymentMethod == "Credit") {
            tempTransactionValue.viewPaymentMethod = "Card";
          }
          tempTransactionList.push(tempTransactionValue);
        });
        this.viewPrfTransactionValuesList = tempTransactionList;
        this.checkViewTransactionValues(true,false);
        // Booking Reference Check & Initial load up check
        if (givenBookingReference != null && givenBookingReference != "" && givenBookingReference != "null"){
          if (givenBookingReference == "Multiple") {
            this.viewMatchedBookingReference = givenBookingReference
            if (initialReload == true){
              this.setInitialMultipleViewBookingRefForPrf();
            } else {
              this.loadInitialMultipleReceipts();
              this.pageLoaded = true
            }
          } else {
            this.viewMatchedBookingReference = givenBookingReference;
            if (initialReload == true){
              this.setInitialViewBookingRefForPrf();
            }else {
              this.reloadReceiptsList("View Single",0);
              this.pageLoaded = true
            }
          }
        } else {
          this.viewMatchedBookingReference = null;
          this.viewMultipleBookingReference = false;
          this.pageLoaded = true;
        }
      }
    });
  }

  // Used to change string next line to html break line
  replaceLineBreak(s:string) {
    return s && s.replace('\n','<br />');
  }

  // Trustee's functionality to add new emails to EmaiTo
  addNewEmail() {
    var emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (emailReg.test(this.addEmail) == true) {
      this.emailOptions.push({ value: this.addEmail, key: this.addEmail });
      this.addEmail = "";
      this.sendMessageToDialog("Email added successfully", "", "", "");
    } else {
      this.sendMessageToDialog("", "Invalid email address format", "", "");
    }
  }

  // When a use clicks on 'VIEW' button of a PRF, it will open it's tab
  // Check's if the PRF is already viewed or not (to not reload it)
  // If it's not listed then firstly set all listed prf's view to false
  // Push the clicked prf into the view array as true to then focus UI view on that prf
  // And finally, request prf and set it to the prfBreakdown list
  openPrfTab(selectedPrf: any) {
    let checkIfListed = false;
    if (this.listedViewPrfs.length > 0) {
      this.listedViewPrfs.forEach((prf: any) => {
        if (prf == selectedPrf.id) {
          checkIfListed = true;
          this.switchView(this.listedViewPrfs.indexOf(prf));
        }
      });
    }
    if (!checkIfListed) {
      this.pageLoaded = false;
      let prfRequest = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        token: Session.mySession.get('user').token,
        id: selectedPrf.id
      };
      this.getPrfFromServer(prfRequest).then((response :any)=> {
        let tempStatus = response[0]['status'];
        let tempPrfNumber = response[0]['prfNumber'];
        let tempAssignedTo = response[0]['assignedTo'];
        let checkAccess:any = this.checkUserAccess(tempStatus, tempPrfNumber, tempAssignedTo);
        if (checkAccess == true) {
          this.listedViewPrfs.push(selectedPrf.id)
          this.prfNoView.fill(false);
          this.prfNoView.push(true);
          this.prfBreakdown.push(response);
          this.showLastPrf();
        } else if (checkAccess == "Processing") {
          response[0]["status"] = "Processing";
          this.listedViewPrfs.push(selectedPrf.id)
          this.prfNoView.fill(false);
          this.prfNoView.push(true);
          this.prfBreakdown.push(response);
          this.showLastPrf();
        } else {
          this.setViewPrfPopUpData(response);
          this.switchView("popup");
          this.openPopUpPrfDialog();
        }
      }).then(()=>{
        if (this.viewMultipleBookingReference == false){
          this.pageLoaded = true
        }
      });
    }
  }

  // Can be used only when a viewed PRF's is available and it removes the last one
  // Remove last prf from view array
  // Remove current prf from breakdown
  closePrfTab(): void {
    if (this.listedViewPrfs.length > 0) {
      if (this.listedViewPrfs.length > this.initialPrfNo) {
        this.listedViewPrfs.pop();
        if (this.prfNoView[this.prfNoView.length - 1] == true) {
          this.prfNoView[this.prfNoView.length - 2] = true;
        }
        this.prfNoView.pop();
        this.prfBreakdown.pop();
        this.showLastPrf();
      } else {
        this.listedViewPrfs = [];
        this.switchView('list')
      }
    }
  }

  // Used in Step-2 of Creating a PRF to upload documents for that PRF
  uploadCreatePrfDocument(fileInput: any, whatFile: String) {
    if (fileInput.target.files && fileInput.target.files.length > 0){
      Array.from(fileInput.target.files).forEach((actualFile: any) => {
        if (actualFile.size > 31457280) {
          this.sendMessageToDialog('', 'This file is too big. Maximum file upload is 30MB (' + actualFile.name + ')', '', '');
        } else {
          let tempFileExt = "";
          let tempFileName = "";
          let tempFileType:any = "Other";
          if (whatFile == 'invoice') {
            tempFileType = 'Invoice';
            tempFileExt = actualFile.name.split('.').pop();
            tempFileName = actualFile.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_Invoice." + tempFileExt;
          } else if (whatFile == 'schedule') {
            tempFileType = 'Schedule';
            tempFileExt = actualFile.name.split('.').pop();
            tempFileName = actualFile.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_Schedule." + tempFileExt;
          } else if (whatFile == 'confirmationOfPayment') {
            tempFileType = 'Confirmation Of Payment';
            tempFileExt = actualFile.name.split('.').pop();
            tempFileName = actualFile.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_ConfOfPayment." + tempFileExt;
          } else {
            tempFileType = whatFile;
            tempFileExt = actualFile.name.split('.').pop();
            tempFileName = actualFile.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_" + whatFile + "." + tempFileExt;
          }
          if (tempFileExt == "csv" || tempFileExt == 'doc' || tempFileExt == 'docx' || tempFileExt == 'pdf' || tempFileExt == 'xls'|| tempFileExt == 'xlsx' || tempFileExt == 'eml' || tempFileExt == 'png' || tempFileExt == 'jpeg' || tempFileExt == 'jpg' || tempFileExt == 'msg') {
            let tempNewFile = new File([actualFile], tempFileName, { type: actualFile.type });
            let uploadDocumentRequest: any = {
              company: Session.mySession.getUser().company,
              tradeCode: Session.mySession.getUser().tradeCode,
              operation: Session.mySession.getUser().operation,
              prfNumber: "Pending",
              token: Session.mySession.get('user').token
            }
            let currentIndex:any = this.uploadedPrfDocuments.data.length;  
            let newDoc = {
              fileName: tempFileName,
              dateAttached : this.convertDate(new Date()),
              documentType : tempFileType,
              deleteDocument : currentIndex,
              downloadDocument : tempNewFile,
              uploadRequest : uploadDocumentRequest
            }
            let newTempUploadDocList:any = new MatTableDataSource<any>();
            this.uploadedPrfDocuments.data.forEach((doc:any)=>{
              newTempUploadDocList.data.push(doc)
            })
            newTempUploadDocList.data.push(newDoc);
            this.uploadedPrfDocuments.data = newTempUploadDocList.data;
            this.checkSupportingDocumentation()
          } else {
            this.sendMessageToDialog('', 'Invalid file format (Valid formats are .csv .doc .docx .pdf, .xls .xlsx .eml .png .jpg .jpeg .msg)', '', '');
          }
        }
      })
    }
  }

  // Used in Step-2 of Creating a PRF to download an uploaded document 
  downloadCreatePrfDocument(file:any){
      try {
        // Get file's extension and prepare for assigning MIME type below..
        const extension = file.downloadDocument.name.substr(file.downloadDocument.name.lastIndexOf('.') + 1); let type = '';
        // Depending on the extension, we'll assign the right MIME type below..
        if (extension === 'csv') { type = 'text/csv'; }
        else if (extension === 'doc') { type = 'application/msword'; }
        else if (extension === 'docx') { type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; }
        else if (extension === 'pdf') { type = 'application/pdf'; }
        else if (extension === 'xls') { type = 'application/vnd.ms-excel'; }
        else if (extension === 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
        else if (extension === 'eml') { type = 'message/rfc822'; }
        else if (extension === 'msg') { type = 'application/octet-stream'; }
        else if (extension === 'jpeg' || extension === 'jpg' ) { type = 'image/jpeg'; }
        else if (extension === 'png') { type = 'image/png'; }
        const reader = new FileReader();
        reader.readAsDataURL(file.downloadDocument);
        reader.onload = () => {
          if (reader.result){
            // setup base64 raw from file
            const base64String = reader.result;

            // Remove the "data:application/octet-stream;base64," part
            let base64StringToAtob = String(base64String).split(',')[1];

            // convert base64 to raw binary data held in a string
            const byteString = atob(base64StringToAtob);

            // write the bytes of the string to an ArrayBuffer
            const ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            const ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }

            // Output BLOB needs to be transformed into an excel application file
            const data = new Blob([ab], { type });
            saveAs(data, file.downloadDocument.name); // Call this function which opens browser's 'Save As..' window
          } else {
            console.error('Error: reader.result is null or undefined.');
            this.sendMessageToDialog('', 'An error occurred while downloading the file. Please try again.', '', '');
          }
        };
      } catch (error) {
        console.error(error);
        this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
      }
  }

  // Used in Step-2 of Creating a PRF to remove an uploaded document
  removeCreatePrfDocument(file:any){
      if (confirm('Are you sure you want to remove ' + file.fileName + '?')) {
        let newTempUploadDocList:any = new MatTableDataSource<any>();
        this.uploadedPrfDocuments.data.forEach((doc:any)=>{
          if (doc != file){
            newTempUploadDocList.data.push(doc)
          }
        });
        this.uploadedPrfDocuments.data = newTempUploadDocList.data;
        this.checkSupportingDocumentation();
      }
  }

  uploadViewPrfDocument(fileInput: any, whatFile: string) {
    const uploadDocumentRequest = {
      company: this.viewPrfCompany.toLowerCase(),
      tradeCode: this.viewPrfTradeCode,
      operation: this.viewPrfOperation,
      prfNumber: this.viewPrfNumber,
      token: Session.mySession.get('user').token,
    };
  
    if (fileInput.target.files && fileInput.target.files.length > 0) {
      const allowedExtensions = ['csv', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'eml', 'png', 'jpeg', 'jpg', 'msg'];
  
      const files = Array.from(fileInput.target.files);
      const validFiles = files.filter((file: any) => {
        const tempFileExt = file.name.split('.').pop().toLowerCase();
  
        // Check for file size (maximum 30MB) and valid file extension
        if (file.size > 31457280) {
          this.sendMessageToDialog('', 'This file is too big. Maximum file size is 30MB.', '', '');
          return false; // Exclude large files
        }
  
        if (!allowedExtensions.includes(tempFileExt)) {
          this.sendMessageToDialog('', `Invalid file type: ${tempFileExt}. Allowed types: ${allowedExtensions.join(', ')}.`, '', '');
          return false; // Exclude invalid file types
        }
  
        return true; // Include valid files
      });
  
      if (validFiles.length > 0) {
        const fileUploadPromises = validFiles.map((actualFile: any) => {
          if (actualFile.size > 4194304) {
            // Handle large files (greater than 4MB)
            console.log("Uploading large file ...")
            return this.uploadLargeFile(uploadDocumentRequest, actualFile, whatFile);
          } else {
            // Handle small files (less than 4MB)
            console.log("Uploading Small file ...")
            return this.uploadSmallFile(uploadDocumentRequest, actualFile, whatFile);
          }
        });
        this.pageLoaded = false;
        // Upload valid files concurrently
        Promise.all(fileUploadPromises)
          .then(() => {
            // Reload PRF documents after all uploads complete
            this.reloadS3prfDocs(false).then(() => {
              this.pageLoaded = true;
              console.log("Uploading Finished ✅")
            });
          })
          .catch((error: any) => {
            this.sendMessageToDialog(
              '',
              'There was a problem with uploading your file(s)',
              error,
              uploadDocumentRequest
            );
          });
      } else {
        this.sendMessageToDialog('', 'No valid files to upload.', '', '');
      }
    }
  }
  

  async uploadSmallFile(uploadRequest: any, file: any, whatFile: string): Promise<any> {
    const tempFileName = this.createFileName(file, whatFile);
    const tempNewFile = new File([file], tempFileName, { type: file.type });
    const result: any = await this.prfService.uploadS3prfDoc(uploadRequest, tempNewFile);
    return result;
  }
  
  // Upload large file in chunks (multipart upload)
  async uploadLargeFile(uploadRequest: any, file: any, whatFile: string): Promise<any> {
    const tempFileName = this.createFileName(file, whatFile);
    const tempNewFile = new File([file], tempFileName, { type: file.type });
    const result: any = await this.prfService.uploadS3prfLargeDoc(uploadRequest, tempNewFile);
    return result;
  }
  
  createFileName(file: any, whatFile: string): string {
    let tempFileExt = "";
    let tempFileName = "";
    let tempFileType: any = "Other";
    // Get file extension
    tempFileExt = file.name.split('.').pop().toLowerCase();
    // Create file name based on the `whatFile` type
    if (whatFile === 'invoice') {
      tempFileType = 'Invoice';
      tempFileName = file.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_Invoice." + tempFileExt;
    } else if (whatFile === 'schedule') {
      tempFileType = 'Schedule';
      tempFileName = file.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_Schedule." + tempFileExt;
    } else if (whatFile === 'confirmationOfPayment') {
      tempFileType = 'Confirmation Of Payment';
      tempFileName = file.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_ConfOfPayment." + tempFileExt;
    } else {
      tempFileType = whatFile;
      tempFileName = file.name.split('.').slice(0, -1).join('.') + "_" + this.convertDate(new Date()) + "_" + whatFile + "." + tempFileExt;
    }
    return tempFileName;
  }

  // Used to reload PRFs documents
  // When you open a PRF or when you upload/delete documents to update the viewed table in Step-2
  reloadS3prfDocs(initialReload:boolean) {
    return new Promise<void>((resolve, reject) => {
      let listRequst = {
        company: this.viewPrfCompany.toLowerCase(),
        operation: this.viewPrfOperation,
        tradeCode: this.viewPrfTradeCode,
        prfNumber: this.viewPrfNumber,
        token: Session.mySession.get('user').token
      }
      this.prfService.listS3prfDoc(listRequst).then((output: any) => {
        if (output.status == "OK") {
          if (output.data.contents != undefined) {
            let newDocumentsList = new MatTableDataSource<any>();
            let fileDetailsRegex = new RegExp('(_[0-9]{4}-[0-9]{2}-[0-9]{1,2}_(Invoice|Schedule|ConfOfPayment|RBSBankline|Natwest Faster|Natwest CHAPS|Barclays|Corpay|Monteith|Amex Forex Transfer|Other)[.])(?!.*(_[0-9]{4}-[0-9]{2}-[0-9]{1,2}_(Invoice|Schedule|ConfOfPayment|Natwest Faster|Natwest CHAPS|Barclays|Corpay|Monteith|Amex Forex Transfer|Other)[.]))');
            output.data.contents.forEach((file: any) => {
              let tempFileName = file.key.split('/')[4];
              if (fileDetailsRegex.test(tempFileName) == true) {
                let tempFileAttrDetails = tempFileName.match(fileDetailsRegex);
                let tempDateAttached = tempFileAttrDetails[0].split("_")[1]
                let tempDocumentType = tempFileAttrDetails[0].split("_")[2].slice(0, -1);
                if (tempDocumentType == "ConfOfPayment") { tempDocumentType = "Confirmation of Payment" };
                if (tempDocumentType == "RBSBankline") { tempDocumentType = "RBS Bankline" };
                let doc = { fileName: tempFileName, dateAttached: tempDateAttached, documentType: tempDocumentType }
                newDocumentsList.data.push(doc);
              } else {
                let doc = { fileName: tempFileName, dateAttached: 'N/A', documentType: 'N/A'}
                newDocumentsList.data.push(doc);
              }
              this.viewPrfDocumentsData.data = newDocumentsList.data;
            });
          } else {
            this.viewPrfDocumentsData.data = [];
          }
          if (initialReload == false){
            this.pageLoaded = true;
          }
          resolve();
        } else {
          this.pageLoaded = true;
          this.sendMessageToDialog('', output.status, '', '');
          resolve();
        }
      }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2008S)', error, listRequst);
        resolve(); // Print error..
      });
    }).catch((err: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '', ''); // Display error message here..
    });
  }

  //
  // BOOKING DOCUMENTATION BELOW
  //

  // For Single Booking
  reloadS3documents(): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      const uploadRequest = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        bookingReference: this.viewMatchedBookingReference, 
        pathType: 'attachedBookDocs', 
        token: Session.mySession.get('user').token
      };
      if (this.viewPrfTradeCode != "" && this.viewPrfOperation != "" && this.viewPrfCompany != "" && 
          this.viewPrfTradeCode != null && this.viewPrfOperation != null && this.viewPrfCompany != null){
        uploadRequest.tradeCode = this.viewPrfTradeCode;
        uploadRequest.company = this.viewPrfCompany.toLowerCase();
        uploadRequest.operation = this.viewPrfOperation;
      }
      if (this.createMatchedBookingReference != null && this.createMatchedBookingReference != ""){
        uploadRequest.bookingReference = this.createMatchedBookingReference
      }
      if (uploadRequest.bookingReference != "" && uploadRequest.bookingReference != null && 
          uploadRequest.bookingReference != "null" && uploadRequest.bookingReference != undefined){
            this.reportsService.getS3files(uploadRequest).then((output: any) => {
              if (output.status === 'OK') {  
                if (output.data.contents !== undefined) {
                  // Sort files by date
                  output.data.contents = output.data.contents.sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
                  this.attachedDocs.data = output.data.contents; // If contents exist within S3 buckets, put them into data property
                  this.attachedDocs.data.forEach((file: any) => {
                    file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
                  });
                } else {
                  this.attachedDocs.data = []; // Contents not found - set empty array in the data
                }  
                this.pageLoaded = true; resolve('');
              } else {
                this.sendMessageToDialog('', output.status, '', ''); 
                this.pageLoaded = true; 
                resolve(''); // Print error..
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2017S)', error, uploadRequest); 
              this.pageLoaded = true; 
              resolve(''); // Print error..
            });
      } else {
        this.pageLoaded = true; 
        resolve('');
      }
    }).catch((err: any) => {
      this.pageLoaded = true; 
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '', ''); // Display error message here..
    });
  }

  // For Multiple Bookings
  reloadS3document(bookingData:any): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      const uploadRequest = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        bookingReference: bookingData.bookingReference, 
        pathType: 'attachedBookDocs', 
        token: Session.mySession.get('user').token
      };
      if (this.viewPrfTradeCode != "" && this.viewPrfOperation != "" && this.viewPrfCompany != "" && 
          this.viewPrfTradeCode != null && this.viewPrfOperation != null && this.viewPrfCompany != null){
        uploadRequest.tradeCode = this.viewPrfTradeCode;
        uploadRequest.company = this.viewPrfCompany.toLowerCase();
        uploadRequest.operation = this.viewPrfOperation;
      }
      if (uploadRequest.bookingReference != "" && uploadRequest.bookingReference != null && 
          uploadRequest.bookingReference != "null" && uploadRequest.bookingReference != undefined){
            this.reportsService.getS3files(uploadRequest).then((output: any) => {
              if (output.status === 'OK') {  
                if (output.data.contents !== undefined) {
                  output.data.contents = output.data.contents.sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
                  bookingData.listOfDocumentsData.data = output.data.contents; // If contents exist within S3 buckets, put them into data property
                  bookingData.listOfDocumentsData.data.forEach((file: any) => {
                    file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
                  });
                } else {
                  bookingData.listOfDocumentsData.data = []; // Contents not found - set empty array in the data
                }  
                this.pageLoaded = true; resolve('');
              } else {
                this.sendMessageToDialog('', output.status, '', ''); 
                this.pageLoaded = true; 
                resolve(''); // Print error..
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2017S)', error, uploadRequest); 
              this.pageLoaded = true; 
              resolve(''); // Print error..
            });
      } else {
        this.pageLoaded = true; 
        resolve('');
      }
    }).catch((err: any) => {
      this.pageLoaded = true; 
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '', ''); // Display error message here..
    });
  }

  // For Sinlge Booking
  downloadDocument(file: any): void {
    const downloadRequest = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        bookingReference: this.viewMatchedBookingReference,
        pathType: 'attachedBookDocs',
        fileName: file.name,
        token: Session.mySession.get('user').token
    };
    if (this.createMatchedBookingReference != null && this.createMatchedBookingReference != ""){
      downloadRequest.bookingReference = this.createMatchedBookingReference
    }
    if (this.viewPrfTradeCode != "" && this.viewPrfOperation != "" && this.viewPrfCompany != "" &&
        this.viewPrfTradeCode != null && this.viewPrfOperation != null && this.viewPrfCompany != null){
            downloadRequest.tradeCode = this.viewPrfTradeCode;
            downloadRequest.company = this.viewPrfCompany.toLowerCase();
            downloadRequest.operation = this.viewPrfOperation;
    }

    // Get file's extension and prepare for assigning MIME type below..
    const extension = file.name.substr(file.name.lastIndexOf('.') + 1); let type = '';
    // Depending on the extension, we'll assign the right MIME type below..
    if (extension === 'csv') { type = 'text/csv'; }
    else if (extension === 'doc') { type = 'application/msword'; }
    else if (extension === 'docx') { type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; }
    else if (extension === 'pdf') { type = 'application/pdf'; }
    else if (extension === 'xls') { type = 'application/vnd.ms-excel'; }
    else if (extension === 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
    else if (extension === 'eml') { type = 'message/rfc822'; }
    else if (extension === 'msg') { type = 'application/octet-stream'; }
    else if (extension === 'jpeg' || extension === 'jpg') { type = 'image/jpeg'; }
    else if (extension === 'png') { type = 'image/png'; }

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportsService.downloadS3file(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
          // convert base64 to raw binary data held in a string
          const byteString = atob(output.fileContent);

          // write the bytes of the string to an ArrayBuffer
          const ab = new ArrayBuffer(byteString.length);

          // create a view into the buffer
          const ia = new Uint8Array(ab);

          // set the bytes of the buffer to the correct values
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          // Output BLOB needs to be transformed into an excel application file
          const data = new Blob([ab], { type });
          saveAs(data, file.name); // Call this function which opens browser's 'Save As..' window
          this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2018S)', error, downloadRequest);
    });
  }

  // For Multiple Booking
  downloadDocumentFromBooking(file:any,bookingReference:any): void {
    const downloadRequest = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      bookingReference: bookingReference,
      pathType: 'attachedBookDocs',
      fileName: file.name,
      token: Session.mySession.get('user').token
    };
    if (this.viewPrfTradeCode != "" && this.viewPrfOperation != "" && this.viewPrfCompany != "" &&
        this.viewPrfTradeCode != null && this.viewPrfOperation != null && this.viewPrfCompany != null){
            downloadRequest.tradeCode = this.viewPrfTradeCode;
            downloadRequest.company = this.viewPrfCompany.toLowerCase();
            downloadRequest.operation = this.viewPrfOperation;
    }
    // Get file's extension and prepare for assigning MIME type below..
    const extension = file.name.substr(file.name.lastIndexOf('.') + 1); let type = '';
    // Depending on the extension, we'll assign the right MIME type below..
    if (extension === 'csv') { type = 'text/csv'; }
    else if (extension === 'doc') { type = 'application/msword'; }
    else if (extension === 'docx') { type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; }
    else if (extension === 'pdf') { type = 'application/pdf'; }
    else if (extension === 'xls') { type = 'application/vnd.ms-excel'; }
    else if (extension === 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
    else if (extension === 'eml') { type = 'message/rfc822'; }
    else if (extension === 'msg') { type = 'application/octet-stream'; }
    else if (extension === 'jpeg' || extension === 'jpg') { type = 'image/jpeg'; }
    else if (extension === 'png') { type = 'image/png'; }

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportsService.downloadS3file(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
          // convert base64 to raw binary data held in a string
          const byteString = atob(output.fileContent);

          // write the bytes of the string to an ArrayBuffer
          const ab = new ArrayBuffer(byteString.length);

          // create a view into the buffer
          const ia = new Uint8Array(ab);

          // set the bytes of the buffer to the correct values
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          // Output BLOB needs to be transformed into an excel application file
          const data = new Blob([ab], { type });
          saveAs(data, file.name); // Call this function which opens browser's 'Save As..' window
          this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2018S)', error, downloadRequest);
    });
  }

  // For Multiple Booking (VIEW)
  reloadS3Multipledocuments() {
    this.viewMatchedBookingData.forEach((tempBooking:any)=>{
      tempBooking.listOfDocumentsData = new MatTableDataSource<any>();
      if (tempBooking.listOfDocuments.contents !== undefined && tempBooking.listOfDocuments.contents.length > 0) {
        tempBooking.listOfDocumentsData.data = tempBooking.listOfDocuments.contents; 
        tempBooking.listOfDocumentsData.data.forEach((file: any) => {
          file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
        });
      }
    })
  }

  //
  // BOOKING DOCUMENTATION ABOVE
  //

  // Add another transaction
  addTransactionValue(type: String) {
    if (type == "Create") {
      let newCreateTransactionValue = {
        createBookingReference: "", createClientSurname: "", createDateFundsBanked: null, createPaymentMethod: "",
        createGrossClientFunds: { currency: "", amount: null }, createGuaranteeDeducted: { currency: "", amount: null },
        createSupplierAmount: { currency: "", amount: null }, createCommission: { currency: "", amount: null },
        createClientRefund: { currency: "", amount: null }, createReimbursement: { currency: "", amount: null },
        createListOfSurnames: [], createReceiptData: new MatTableDataSource<any>(), createShowReceipt: false,
        createIsNotComplete: true
      }
      this.createTransactionValuesList.push(newCreateTransactionValue);
      this.checkTransactionValues(true);
    } else {
      let newViewTransactionValue = {
        viewBookingReference: "", viewClientSurname: "", viewDateFundsBanked: null, viewPaymentMethod: "",
        viewGrossClientFunds: { currency: "", amount: null }, viewGuaranteeDeducted: { currency: "", amount: null },
        viewSupplierAmount: { currency: "", amount: null }, viewCommission: { currency: "", amount: null },
        viewClientRefund: { currency: "", amount: null }, viewReimbursement: { currency: "", amount: null },
        viewListOfClientSurnames: [], viewReceiptData: new MatTableDataSource<any>(), viewShowReceipt: false,
        viewIsNotComplete: true
      }
      this.viewPrfAddedTransactionValuesList.push(newViewTransactionValue);
      this.checkViewTransactionValues(true,false);
    }
  }

  // Remove or Clear last Transaction
  deleteTransactionValue(id: any, type: String) {
    if (confirm("Are you sure you want to delete this transaction?")) {
      if (type == "Create") {
        let indexOf = this.createTransactionValuesList.indexOf(this.createTransactionValuesList[id]);
        if (indexOf == 0 && this.createTransactionValuesList.length == 1) {
          this.createTransactionValuesList[indexOf] = {
            createBookingReference: "", createClientSurname: "", createDateFundsBanked: null, createPaymentMethod: "",
            createGrossClientFunds: { currency: "", amount: null }, createGuaranteeDeducted: { currency: "", amount: null },
            createSupplierAmount: { currency: "", amount: null }, createCommission: { currency: "", amount: null },
            createClientRefund: { currency: "", amount: null }, createReimbursement: { currency: "", amount: null },
            createListOfSurnames: [], createReceiptData: new MatTableDataSource<any>(), createShowReceipt: false, 
            createIsNotComplete: true
          }
        } else {
          this.createTransactionValuesList.splice(indexOf, 1);
        }
        this.checkTransactionValues(true);
      } else {
        if (type == "View Added") {
          let indexOf = this.viewPrfAddedTransactionValuesList.indexOf(this.viewPrfAddedTransactionValuesList[id]);
          if (indexOf == 0 && this.viewPrfAddedTransactionValuesList.length == 1) {
            if (this.viewPrfTransactionValuesList.length == 0){
              this.viewPrfAddedTransactionValuesList[indexOf] = {
                transactionID : "",
                viewBookingReference: "", viewClientSurname: "", viewDateFundsBanked: null, viewPaymentMethod: "",
                viewGrossClientFunds: { currency: "", amount: null }, viewGuaranteeDeducted: { currency: "", amount: null },
                viewSupplierAmount: { currency: "", amount: null }, viewCommission: { currency: "", amount: null },
                viewClientRefund: { currency: "", amount: null }, viewReimbursement: { currency: "", amount: null },
                viewListOfClientSurnames: [], viewReceiptData: new MatTableDataSource<any>(), viewShowReceipt: false,
                viewIsNotComplete: true
              }
            } else {
              this.viewPrfAddedTransactionValuesList = [];
            }
          } else {
            this.viewPrfAddedTransactionValuesList.splice(indexOf, 1);
          }
        } else {
          this.viewPrfDeletedTransactionValuesList.push(this.viewPrfTransactionValuesList[id]);
          let indexOf = this.viewPrfTransactionValuesList.indexOf(this.viewPrfTransactionValuesList[id]);
          if (indexOf == 0 && this.viewPrfTransactionValuesList.length == 1) {
            if (this.viewPrfAddedTransactionValuesList.length == 0){
              this.viewPrfAddedTransactionValuesList[indexOf] = {
                transactionID : "",
                viewBookingReference: "", viewClientSurname: "", viewDateFundsBanked: null, viewPaymentMethod: "",
                viewGrossClientFunds: { currency: "", amount: null }, viewGuaranteeDeducted: { currency: "", amount: null },
                viewSupplierAmount: { currency: "", amount: null }, viewCommission: { currency: "", amount: null },
                viewClientRefund: { currency: "", amount: null }, viewReimbursement: { currency: "", amount: null },
                viewListOfClientSurnames: [], viewReceiptData: new MatTableDataSource<any>(), viewShowReceipt: false,
                viewIsNotComplete: true
              }
            }
            this.viewPrfTransactionValuesList = [];
          } else {
            this.viewPrfTransactionValuesList.splice(indexOf, 1);
          }
        }        
        this.checkViewTransactionValues(true,false);
      }
    }
  }

  // Check what are the new claims and their payment methods
  // Then create their structure which it will be saved in the DB
  // Returns {claimOne, paymentMethodOne, claimTwo,  paymentMethodTwo}
  getNewClaimsOfPRF(type: String) {
    let claimOne : String = "" 
    let paymentMethodOne : String = "" 
    let claimTwo : any = null
    let paymentMethodTwo : any = null
    if (type == 'Create') {
      // Create Commission Check
      if (this.createClaimCommission.checked == true) {
        if (claimOne == "") {
          claimOne = "Commission";
          paymentMethodOne = this.createClaimCommission.paymentMethod;
        }
      }
      // Create Supplier Payment Check
      if (this.createClaimSupplierPayment.checked == true) {
        if (claimOne == "") {
          claimOne = "Supplier Payment";
          let tempSupplierPaymentMethods = "";
          this.createClaimSupplierPayment.paymentMethods.checks.forEach((checked,index)=>{
            if (checked == true){
              if (tempSupplierPaymentMethods == "") {
                tempSupplierPaymentMethods = this.createClaimSupplierPayment.paymentMethods.methods[index];
              } else {
                tempSupplierPaymentMethods = tempSupplierPaymentMethods + "/" + this.createClaimSupplierPayment.paymentMethods.methods[index];
              }
            }
          });
          paymentMethodOne = tempSupplierPaymentMethods;
        } else {
          claimTwo = "Supplier Payment";
          let tempSupplierPaymentMethods = "";
          this.createClaimSupplierPayment.paymentMethods.checks.forEach((checked,index)=>{
            if (checked == true){
              if (tempSupplierPaymentMethods == ""){
                tempSupplierPaymentMethods = this.createClaimSupplierPayment.paymentMethods.methods[index];
              } else {
                tempSupplierPaymentMethods = tempSupplierPaymentMethods + "/" + this.createClaimSupplierPayment.paymentMethods.methods[index];
              }
            }
          });
          paymentMethodTwo = tempSupplierPaymentMethods;
        }
      }
      // Create Reimbursement Check
      if (this.createClaimReimbursement.checked == true) {
        if (claimOne == "") {
          claimOne = "Reimbursement";
          paymentMethodOne = this.createClaimReimbursement.paymentMethod;
        } else {
          claimTwo = "Reimbursement";
          paymentMethodTwo = this.createClaimReimbursement.paymentMethod;
        }
      }
      // Create Client Refund Check
      if (this.createClaimClientRefund.checked == true) {
        if (claimOne == "") {
          claimOne = "Client Refund";
          paymentMethodOne = this.createClaimClientRefund.paymentMethod;
        } else {
          claimTwo = "Client Refund";
          paymentMethodTwo = this.createClaimClientRefund.paymentMethod;
        }
      }
    } else {
      // View Commission Check
      if (this.viewClaimCommission.checked == true) {
        if (claimOne == "") {
          claimOne = "Commission";
          paymentMethodOne = this.viewClaimCommission.paymentMethod;
        } 
      }
      // View Supplier Payment Check
      if (this.viewClaimSupplierPayment.checked == true) {
        if (claimOne == "") {
          claimOne = "Supplier Payment";
          let tempSupplierPaymentMethods = "";
          this.viewClaimSupplierPayment.paymentMethods.checks.forEach((checked,index)=>{
            if (checked == true){
              if (tempSupplierPaymentMethods == ""){
                tempSupplierPaymentMethods = this.viewClaimSupplierPayment.paymentMethods.methods[index];
              } else {
                tempSupplierPaymentMethods = tempSupplierPaymentMethods + "/" + this.viewClaimSupplierPayment.paymentMethods.methods[index];
              }
            }
          });
          paymentMethodOne = tempSupplierPaymentMethods;
        } else {
          claimTwo = "Supplier Payment";
          let tempSupplierPaymentMethods = "";
          this.viewClaimSupplierPayment.paymentMethods.checks.forEach((checked,index)=>{
            if (checked == true){
              if (tempSupplierPaymentMethods == ""){
                tempSupplierPaymentMethods = this.viewClaimSupplierPayment.paymentMethods.methods[index];
              } else {
                tempSupplierPaymentMethods = tempSupplierPaymentMethods + "/" + this.viewClaimSupplierPayment.paymentMethods.methods[index];
              }
            }
          });
          paymentMethodTwo = tempSupplierPaymentMethods;
        }
      }
      // View Reimbursement Check
      if (this.viewClaimReimbursement.checked == true) {
        if (claimOne == "") {
          claimOne = "Reimbursement";
          paymentMethodOne = this.viewClaimReimbursement.paymentMethod;
        } else {
          claimTwo = "Reimbursement";
          paymentMethodTwo = this.viewClaimReimbursement.paymentMethod;
        }
      }
      // View Client Refund Check
      if (this.viewClaimClientRefund.checked == true) {
        if (claimOne == "") {
          claimOne = "Client Refund";
          paymentMethodOne = this.viewClaimClientRefund.paymentMethod;
        } else {
          claimTwo = "Client Refund";
          paymentMethodTwo = this.viewClaimClientRefund.paymentMethod;
        }
      }
      // View Above
    }
    return {claimOne, paymentMethodOne, claimTwo, paymentMethodTwo};
  }

  setAuthenticationAction(type:any){
    this.actionType = type;
  }

  // Two scenarios of authentication
  // 1. When the user will update a viewed PRF (Step - 4)
  // 2. When a user will create a new PRF (Step - 4)
  authenticate(form: NgForm, type: string) {
    // Use login funcitonality (copied from login) to authenticate the user
    this.pageLoaded = false;
    const request = { email: form.value.email, password: form.value.password };
    this.userService.login(request).then(async (output: any) => {
      if (output.status === 'OK') {
        // Re-do sesson variables as we've just logged in
        this.session.set('user', { id: output.data.id, token: output.data.token });
        Session.mySession.setToken(output.data.token);
        if (type == "Update") {
          await this.updatePRFobject("None");
        } else if (type == "SaveUpdate") {
          await this.updatePRFobject("Not Submitted");
        } else if (type == "Create") {
          await this.createPRFobject("Ready for signing");
        } else if (type == "Send") {
          await this.createPRFobject("Not Submitted");       
        } else if (type == "Delete"){
          await this.deletePRFobject();
        }
      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog('', output.status, '', ''); // Display error status from the back
      }
    }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2009S) - please try again', error, request);
    })
  }

  // Initialise the Create PRF Request
  // And go through the neccessary checks before sending it to the prfService
  async createPRFobject(passedStatus:any) {
    let getNewClaims = this.getNewClaimsOfPRF("Create");
    let createPRFRequest: any = {};
    let createPRFTransactionRequest: any = {};
    let createPRFBookingRequest: any = {};
    createPRFRequest = { 
                 assignedTo: "Pending",
                 supplierName: this.createSupplierName,
                 passengerName: "",
                 status: passedStatus,
                 amountOne: 0.00,
                 currencyOne: "",
                 claimOne: getNewClaims.claimOne,
                 paymentMethodOne: getNewClaims.paymentMethodOne,
                 amountTwo: null,
                 currencyTwo: null,
                 claimTwo: getNewClaims.claimTwo,
                 paymentMethodTwo: getNewClaims.paymentMethodTwo,
                 clientFundsBanked: false,
                 thirdPartyPaperwork: false,
                 memberAccountBalanceAmount: null,
                 memberAccountBalanceCurrency: null,
                 comCheckForAtol: null,
                 comCheckForPsa: null,
                 comCheckForClientsReturned: null,
                 comCheckForSfc: null,
                 actionRequiredMessage: null,
                 isClientFundsReceived: this.createIsClientFundsReceived,
                 hasClientReturned: this.createHasClientReturned,
                 financialProtection: null,
                 clientReturnDate: null,
                 notesToTrustee: this.createNotesToTrustee,
                 collectedFrom: null,
                 company: Session.mySession.getUser().company,
                 operation: Session.mySession.getUser().operation,
                 tradeCode: Session.mySession.getUser().tradeCode,
                 prfCreator: Session.mySession.getUser().fullName,
                 prfCreatorEmail: Session.mySession.getUser().email,
                 bookingReference: null,
                 token: Session.mySession.get('user').token
    }
    // Financial Protection and Client Return Date Check
    if (this.createHasClientReturned == 0) {
      let tempfinancialProtection = "";
      this.createFinancialProtection.checks.forEach((checked,index)=>{
        if (checked == true){
          if (tempfinancialProtection == "") {
            tempfinancialProtection = this.createFinancialProtection.methods[index];
          } else {
            tempfinancialProtection = tempfinancialProtection + "@" + this.createFinancialProtection.methods[index];
          }
        }
      });
      createPRFRequest.financialProtection = tempfinancialProtection;
      if (this.createFinancialProtection.checks[0] == true && this.createClaimCommission.checked == false && this.createClaimSupplierPayment.checked == false && this.createClaimReimbursement.checked == false  && this.createClaimClientRefund.checked == true) {
        createPRFRequest.clientReturnDate = null;
      } else {
        if (this.createFinancialProtectionEnable == true && this.createFinancialProtection.checks[0] == true){
          createPRFRequest.clientReturnDate = this.convertDate(this.createClientReturnDate);
        }
      }
    }
    // Collected From Check
    if (this.createCollectedFrom != "" && this.createClaimCommission.checked == true) {
      createPRFRequest.collectedFrom = this.createCollectedFrom;
    }
    // Passenger Name 
    if (this.createTransactionValuesList.length > 0){
      createPRFRequest.passengerName = this.createTransactionValuesList[0].createClientSurname;
    }    
    // Add a single Transaction Value (prfs_transactions)
    if (this.createTransactionValuesList.length == 1){
      createPRFBookingRequest = {
        company: Session.mySession.getUser().company,
        operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode,
        prfNumber: "Pending",
        token: Session.mySession.get('user').token
      }
      if (this.createMatchedBookingReference != null && this.createMatchedBookingReference != "" && this.createMultipleBookingReference == true){
        createPRFBookingRequest.listOfBookingData = [];
      } else {
        createPRFBookingRequest.bookingReference = this.createBookingReference;
      }
      createPRFTransactionRequest = {
        tradeCode: Session.mySession.getUser().tradeCode, 
        company: Session.mySession.getUser().company, 
        operation: Session.mySession.getUser().operation,
        prfNumber: "Pending", 
        clientSurname: "",
        dateFundsBanked: null,
        paymentMethod: "",
        grossAmount: 0.00,
        grossCurrency: "",
        guaranteeDeductedAmount: null,
        guaranteeDeductedCurrency: null,
        supplierAmount: null,
        supplierAmountCurrency: null,
        commissionAmount: null,
        commissionCurrency: null,
        reimbursementAmount: null,
        reimbursementCurrency: null,
        clientRefundAmount: null,
        clientRefundCurrency: null,
        token: Session.mySession.get('user').token
      }    
      this.createTransactionValuesList.forEach((transaction) => { 

        // Client Surname
        createPRFTransactionRequest.clientSurname = transaction.createClientSurname;

        // Payment Method
        createPRFTransactionRequest.paymentMethod = transaction.createPaymentMethod;

        // Gross Amount and Currency
        createPRFTransactionRequest.grossAmount = Number(transaction.createGrossClientFunds.amount);
        createPRFTransactionRequest.grossCurrency = transaction.createGrossClientFunds.currency

        // Check dateFundsBanked
        if (transaction.createDateFundsBanked != null && transaction.createDateFundsBanked != ""){
          createPRFTransactionRequest.dateFundsBanked = this.convertDate(transaction.createDateFundsBanked);
        } else {
          createPRFTransactionRequest.dateFundsBanked = null;
        }

        // Guarantee Deducted Check
        if (this.createCollectedFrom == "Trust Account") {
          createPRFTransactionRequest.guaranteeDeductedAmount = Number(transaction.createGuaranteeDeducted.amount);
          createPRFTransactionRequest.guaranteeDeductedCurrency = transaction.createGuaranteeDeducted.currency;
        }

        // Supplier Payment Check
        if (transaction.createSupplierAmount.amount != null && (this.createClaimCommission.checked == true || this.createClaimSupplierPayment.checked == true)) {
          createPRFTransactionRequest.supplierAmount = Number(transaction.createSupplierAmount.amount);
          createPRFTransactionRequest.supplierAmountCurrency = transaction.createSupplierAmount.currency;
          if (createPRFRequest.claimOne == "Supplier Payment") {
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createSupplierAmount.amount);
            createPRFRequest.currencyOne = createPRFTransactionRequest.supplierAmountCurrency;
          } 
          if (createPRFRequest.claimTwo == "Supplier Payment") {
            createPRFRequest.currencyTwo = createPRFTransactionRequest.supplierAmountCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createSupplierAmount.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createSupplierAmount.amount);
            }
          }          
        }

        // Client Refund Check
        if (transaction.createClientRefund.amount != null && this.createClaimClientRefund.checked == true) { 
          createPRFTransactionRequest.clientRefundAmount = Number(transaction.createClientRefund.amount);
          createPRFTransactionRequest.clientRefundCurrency = transaction.createClientRefund.currency;
          if (createPRFRequest.claimOne == "Client Refund") {
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createClientRefund.amount);
            createPRFRequest.currencyOne = createPRFTransactionRequest.clientRefundCurrency;
          } 
          if (createPRFRequest.claimTwo == "Client Refund") {
            createPRFRequest.currencyTwo = createPRFTransactionRequest.clientRefundCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createClientRefund.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createClientRefund.amount);
            }
          } 
        }

        // Commission Check
        if (transaction.createCommission.amount != null && this.createClaimCommission.checked == true) {
          createPRFTransactionRequest.commissionAmount = Number(transaction.createCommission.amount);
          createPRFTransactionRequest.commissionCurrency = transaction.createCommission.currency;
          if (createPRFRequest.claimOne == "Commission") {
            createPRFRequest.currencyOne = createPRFTransactionRequest.commissionCurrency;
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createCommission.amount);
          } 
          if (createPRFRequest.claimTwo == "Commission") {
            createPRFRequest.currencyTwo = createPRFTransactionRequest.commissionCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createCommission.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createCommission.amount);
            }
          } 
        }

        // Reimbursement Check
        if (transaction.createReimbursement.amount != null && this.createClaimReimbursement.checked == true) {
          createPRFTransactionRequest.reimbursementAmount = Number(transaction.createReimbursement.amount);
          createPRFTransactionRequest.reimbursementCurrency = transaction.createReimbursement.currency;
          if (createPRFRequest.claimOne == "Reimbursement") {
            createPRFRequest.currencyOne = createPRFTransactionRequest.reimbursementCurrency;
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createReimbursement.amount);
          } 
          if (createPRFRequest.claimTwo == "Reimbursement") {
            createPRFRequest.currencyTwo = createPRFTransactionRequest.reimbursementCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createReimbursement.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createReimbursement.amount);
            }
          } 
        }   
        
        // Booking Reference Check
        if (transaction.createBookingReference != null && transaction.createBookingReference != ""){
          createPRFTransactionRequest.bookingReference = transaction.createBookingReference;
        }

      });

    } 
    // Add multiple Transaction Values (prfs_transactions) & (prfs_booking)
    if (this.createTransactionValuesList.length > 1) {
      
      let tempListOfTransactions:any = [];
      let tempListOfBookingData:any = [];

      createPRFBookingRequest = {
        company: Session.mySession.getUser().company,
        operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode,
        listOfBookingData: [],
        prfNumber: "Pending",
        token: Session.mySession.get('user').token
      }

      createPRFTransactionRequest = {
        tradeCode: Session.mySession.getUser().tradeCode, 
        company: Session.mySession.getUser().company, 
        operation: Session.mySession.getUser().operation,
        prfNumber: "", 
        listOfTransactions: [],
        token: Session.mySession.get('user').token
      }

      // Transaction Values List
      this.createTransactionValuesList.forEach((transaction) => {
        // Generate temp record of (prfs_transaction)
        let tempTransactionRequest : any = {
          bookingReference: transaction.createBookingReference,
          clientSurname: transaction.createClientSurname,
          dateFundsBanked: null,
          paymentMethod: transaction.createPaymentMethod,
          grossAmount: Number(transaction.createGrossClientFunds.amount),
          grossCurrency: transaction.createGrossClientFunds.currency,
          guaranteeDeductedAmount: null,
          guaranteeDeductedCurrency: null,
          supplierAmount: null,
          supplierAmountCurrency: null,
          commissionAmount: null,
          commissionCurrency: null,
          reimbursementAmount: null,
          reimbursementCurrency: null,
          clientRefundAmount: null,
          clientRefundCurrency: null,
        } 

        // Check if in 'tempListOfBookingData' already added that 'Booking Reference'
        let tempIfAlreadyAdded:boolean = false
        tempListOfBookingData.forEach((record:any) => {
          if (record.bookingReference == transaction.createBookingReference){
            tempIfAlreadyAdded = true
          }
        })

        // Now check if a record was found or not for this 'Booking Reference'
        if (tempIfAlreadyAdded == false){
          // Generate temp record of (prfs_booking)
          let tempPrfBookingData:any = {
            bookingReference: transaction.createBookingReference,
            supplierName: null,
            clientSurname: transaction.createClientSurname,
          }
          // Check if Supplier Name exists
          if (this.createSupplierName != 'None' && this.createSupplierName != 'Various Suppliers'){
            tempPrfBookingData.supplierName = this.createSupplierName
          }
          // Add to listOfBookingData
          tempListOfBookingData.push(tempPrfBookingData);
        }
        

        // Check dateFundsBanked
        if (transaction.createDateFundsBanked != null && transaction.createDateFundsBanked != ""){
          tempTransactionRequest.dateFundsBanked = this.convertDate(transaction.createDateFundsBanked);
        } else {
          tempTransactionRequest.dateFundsBanked = null;
        }

        // Guarantee Deducted Check
        if (this.createCollectedFrom == "Trust Account") {
          tempTransactionRequest.guaranteeDeductedAmount = Number(transaction.createGuaranteeDeducted.amount);
          tempTransactionRequest.guaranteeDeductedCurrency = transaction.createGuaranteeDeducted.currency;
        }

        // Supplier Payment Check
        if (transaction.createSupplierAmount.amount != null && (this.createClaimCommission.checked == true || this.createClaimSupplierPayment.checked == true)) {
          tempTransactionRequest.supplierAmount = Number(transaction.createSupplierAmount.amount);
          tempTransactionRequest.supplierAmountCurrency = transaction.createSupplierAmount.currency;
          if (createPRFRequest.claimOne == "Supplier Payment") {
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createSupplierAmount.amount);
            createPRFRequest.currencyOne = tempTransactionRequest.supplierAmountCurrency;
          } 
          if (createPRFRequest.claimTwo == "Supplier Payment") {
            createPRFRequest.currencyTwo = tempTransactionRequest.supplierAmountCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createSupplierAmount.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createSupplierAmount.amount);
            }
          }          
        }

        // Client Refund Check
        if (transaction.createClientRefund.amount != null && this.createClaimClientRefund.checked == true) {
          tempTransactionRequest.clientRefundAmount = Number(transaction.createClientRefund.amount);
          tempTransactionRequest.clientRefundCurrency = transaction.createClientRefund.currency;
          if (createPRFRequest.claimOne == "Client Refund") {
            createPRFRequest.currencyOne = tempTransactionRequest.clientRefundCurrency;
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createClientRefund.amount);
          } 
          if (createPRFRequest.claimTwo == "Client Refund") {
            createPRFRequest.currencyTwo = tempTransactionRequest.clientRefundCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createClientRefund.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createClientRefund.amount);
            }
          } 
        }
        // Commission Check
        if (transaction.createCommission.amount != null && this.createClaimCommission.checked == true) {
          tempTransactionRequest.commissionAmount = Number(transaction.createCommission.amount);
          tempTransactionRequest.commissionCurrency = transaction.createCommission.currency;
          if (createPRFRequest.claimOne == "Commission") {
            createPRFRequest.currencyOne = tempTransactionRequest.commissionCurrency;
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createCommission.amount);
          } 
          if (createPRFRequest.claimTwo == "Commission") {
            createPRFRequest.currencyTwo = tempTransactionRequest.commissionCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createCommission.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createCommission.amount);
            }
          } 
        }
        // Reimbursement Check
        if (transaction.createReimbursement.amount != null && this.createClaimReimbursement.checked == true) {
          tempTransactionRequest.reimbursementAmount = Number(transaction.createReimbursement.amount);
          tempTransactionRequest.reimbursementCurrency = transaction.createReimbursement.currency;
          if (createPRFRequest.claimOne == "Reimbursement") {
            createPRFRequest.currencyOne = tempTransactionRequest.reimbursementCurrency;
            createPRFRequest.amountOne = Number(createPRFRequest.amountOne) + Number(transaction.createReimbursement.amount);
          } 
          if (createPRFRequest.claimTwo == "Reimbursement") {
            createPRFRequest.currencyTwo = tempTransactionRequest.reimbursementCurrency;
            if (createPRFRequest.amountTwo == null){
              createPRFRequest.amountTwo = Number(transaction.createReimbursement.amount);
            } else {
              createPRFRequest.amountTwo = Number(createPRFRequest.amountTwo) + Number(transaction.createReimbursement.amount);
            }
          } 
        }
        // Add to listOfTransactions
        tempListOfTransactions.push(tempTransactionRequest);
      });
      createPRFTransactionRequest.listOfTransactions = tempListOfTransactions;
      createPRFBookingRequest.listOfBookingData = tempListOfBookingData;
    } 
    // Checking to see if at least one (prfs_transactions) and then move on to create PRF
    if (this.createTransactionValuesList.length > 0) {
      // Add Booking Ref (Single or Multiple way)
      if (this.createMultipleBookingReference == true)
      {
        createPRFRequest.bookingReference = "Multiple"
        // Check if createPRFBookingRequest has all linked bookings
        // This is to ensure that all matching bookings are linked with the PRF even if they dont have transaction
         let finalListOfBookingReference:any = []
         finalListOfBookingReference = createPRFBookingRequest.listOfBookingData
         this.createMatchedBookingReference.forEach((mainBookingRef:any)=>{
           let tempCheck:any = false;
           createPRFBookingRequest.listOfBookingData.forEach((tempRecord:any)=>{
             if (tempRecord.bookingReference == mainBookingRef){
               tempCheck = true;           
             }
           })
           if (tempCheck == false) {
             let tempAddedBooking = {
                 bookingReference: mainBookingRef,
                 supplierName: null,
                 clientSurname: null,
             }
             finalListOfBookingReference.push(tempAddedBooking);
           }
         })
         createPRFBookingRequest.listOfBookingData = finalListOfBookingReference;
      } else {
        createPRFRequest.bookingReference = this.createMatchedBookingReference;
      }
      // Create PRF as well as the PRFsTransaction(s)
      this.prfService.createPrf(createPRFRequest).then((res: any) => {
        if (res.status != "OK") {
          this.pageLoaded = true;
          this.sendMessageToDialog("", "Error with request status (createPrf): " + res.res, "", "");
        } else {
          createPRFTransactionRequest.prfNumber = res.res;
          createPRFBookingRequest.prfNumber = res.res;
          let bunchOfPromises:any = []
          let bunchCounter = 0;
          // Create PRF Transactions
          this.prfService.createPrfsTransaction(createPRFTransactionRequest).then((tranRes:any)=>{
            if (tranRes.status != "OK"){
              this.pageLoaded = true;
              this.sendMessageToDialog("", "Error with request status (createPrfsTransaction): " + tranRes.res, "", "");
            } else {
              if (this.createMultipleBookingReference == false){
                this.pageLoaded = true;
                if (passedStatus == "Not Submitted"){
                  this.sendMessageToDialog("PRF-" + res.res + " successfully created, not with TTA Trustees", "", "", "");
                } else {
                  this.sendMessageToDialog("PRF-" + res.res + " successfully created, with TTA Trustees", "", "", "");
                }
                this.clearCreatePrf();
                this.switchView("list");
              } else {
                this.prfService.createPrfsBooking(createPRFBookingRequest).then((bookingRes:any)=>{
                  if (bookingRes.status != "OK"){
                    this.pageLoaded = true;
                    this.sendMessageToDialog("", "Error with request status (createPrfsBooking): " + bookingRes.res, "", "");
                  } else {
                    this.pageLoaded = true;
                    if (passedStatus == "Not Submitted"){
                      this.sendMessageToDialog("PRF-" + res.res + " successfully created, not with TTA Trustees", "", "", "");
                    } else {
                      this.sendMessageToDialog("PRF-" + res.res + " successfully created, with TTA Trustees", "", "", "");
                    }
                    this.clearCreatePrf();
                    this.switchView("list");
                  }
                })
              }
            }
          }) 
          // Upload Documents if found 
          if (this.uploadedPrfDocuments.data.length > 0){
            console.log("We will upload " + this.uploadedPrfDocuments.data.length + " files.")
            if (this.uploadedPrfDocuments.data.length > 5){       
              console.log("More than 5")
              for (let docCounter = 0; docCounter < this.uploadedPrfDocuments.data.length; docCounter++){ 
                this.uploadedPrfDocuments.data[docCounter].uploadRequest.prfNumber = res.res;
                this.uploadedPrfDocuments.data[docCounter].uploadRequest.token = Session.mySession.get('user').token;
                if (this.uploadedPrfDocuments.data[docCounter].downloadDocument.size > 4194304){
                  const tempPromise:any = this.prfService.uploadS3prfLargeDoc(this.uploadedPrfDocuments.data[docCounter].uploadRequest, this.uploadedPrfDocuments.data[docCounter].downloadDocument)
                  .then((output: any) => {
                    // console.log(output);
                  })
                  .catch((error: any) => {
                    this.sendMessageToDialog('', 'SinGS could not complete your request at this time (uploadS3prfDoc)', error, this.uploadedPrfDocuments.data[docCounter]);
                  });
                  bunchCounter = (docCounter + 1 - (docCounter + 1 % 5)) / 5;
                  console.log("Uploading for bunchCounter[" + bunchCounter + "]");
                  bunchOfPromises[bunchCounter].push(tempPromise);
                } else {
                  const tempPromise:any = this.prfService.uploadS3prfDoc(this.uploadedPrfDocuments.data[docCounter].uploadRequest, this.uploadedPrfDocuments.data[docCounter].downloadDocument)
                  .then((output: any) => {
                    console.log(output);
                  })
                  .catch((error: any) => {
                    this.sendMessageToDialog('', 'SinGS could not complete your request at this time (uploadS3prfDoc)', error, this.uploadedPrfDocuments.data[docCounter]);
                  });
                  bunchCounter = (docCounter + 1 - (docCounter + 1 % 5)) / 5;
                  console.log("Uploading for bunchCounter[" + bunchCounter + "]");
                  bunchOfPromises[bunchCounter].push(tempPromise);
                }
              }
              bunchOfPromises.forEach((listOfPromises:any)=>{
                Promise.all(listOfPromises)
                .then(() => {
                  // All promises completed successfully
                })
                .catch((error) => {
                  this.sendMessageToDialog('', 'SinGS could not upload "Supporting Documentation" files. Please try again.', error, '');
                  console.error('An error occurred during uploading the "Supporting Documentation" files:', error);
                });
              })  
            } else {   
              console.log("Less than 5")
              const uploadPromises = this.uploadedPrfDocuments.data.map((documentToUpload: any) => {
                documentToUpload.uploadRequest.prfNumber = res.res;
                documentToUpload.uploadRequest.token = Session.mySession.get('user').token;
                if (documentToUpload.downloadDocument.size > 4194304){
                  return this.prfService.uploadS3prfLargeDoc(documentToUpload.uploadRequest, documentToUpload.downloadDocument)
                  .then((output: any) => {
                    console.log(output);
                  })
                  .catch((error: any) => {
                    this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error, documentToUpload.uploadRequest);
                  });
                } else {
                  return this.prfService.uploadS3prfDoc(documentToUpload.uploadRequest, documentToUpload.downloadDocument)
                  .then((output: any) => {
                    console.log(output);
                  })
                  .catch((error: any) => {
                    this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error, documentToUpload.uploadRequest);
                  });
                }
              });            
              Promise.all(uploadPromises)
                .then(() => {
                  // All promises completed successfully
                })
                .catch((error) => {
                  this.sendMessageToDialog('', 'SinGS could not upload "Supporting Documentation" files. Please try again.', error, '');
                  console.error('An error occurred during uploading the "Supporting Documentation" files:', error);
              });
            }
          }  
        }
      });
    } else {
      this.pageLoaded = true;
      this.sendMessageToDialog("", "No transactions found in the 'PRF Transaction Values'","","");
    }
  }

  // Initialise the Update PRF Request
  // And go through the neccessary checks before sending it to the prfService
  async updatePRFobject(prfStatusToUpdate:any) {
    if (confirm("Are you sure you want to update the current PRF?")) {
      this.pageLoaded = false;
      let updatePRFRequest: any = {};
      let updatePRFTransactionsRequest:any = 0;
      let deletePrfsTransactionsRequest:any = 0;
      let createPrfsTransactionsRequest:any = 0;

      // New Claims 
      let newClaims = this.getNewClaimsOfPRF("View"); 
      let newOverallAmountOne:any = 0.00
      let newCurrencyOne:any = ""
      let newClaimOne:any = ""
      let newPaymentMethodOne:any = ""
      let newOverallAmountTwo:any = null
      let newCurrencyTwo:any = null
      let newClaimTwo:any = null
      let newPaymentMethodTwo:any = null

      // PRF'S Update Request (updatePRFRequest)     
      updatePRFRequest = {   
        prfNumber: this.viewPrfNumber,
        assignedTo: this.viewPrfAssignedTo,
        supplierName: this.viewPrfSupplierName,
        passengerName: this.viewPrfPassengerName, 
        amountOne: 0.00, 
        currencyOne: "",  
        claimOne: "", 
        paymentMethodOne: "",  
        amountTwo: null, 
        currencyTwo: null, 
        claimTwo: null,  
        paymentMethodTwo: null, 
        isClientFundsReceived: this.viewPrfIsClientFundsReceived,
        hasClientReturned: this.viewPrfHasClientReturned,
        notesToTrustee: this.viewPrfNotesToTrustee,
        collectedFrom: this.viewPrfCollectedFrom,
        company: Session.mySession.getUser().company,
        operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode,
        username: Session.mySession.getUser().fullName,
        userType: Session.mySession.getUser().userType,       
        token: Session.mySession.get('user').token
      }

      // PRF Status Check
      // If Manager = status (Ready for signing) / prfCreator / prfCreatorEmail
      // If Simple User = status (Not Submitted)
      if (prfStatusToUpdate == "Not Submitted" && this.viewPrfStatus == 'Not Submitted'){
        updatePRFRequest.status = 'Not Submitted';
      } else {
        if (this.checkIfManager() && this.viewPrfStatus == 'Not Submitted'){
          updatePRFRequest.status = 'Ready for signing';
          updatePRFRequest.prfCreator = Session.mySession.getUser().fullName
          updatePRFRequest.prfCreatorEmail = Session.mySession.getUser().email;
        } else if(this.viewPrfStatus == 'Not Submitted' && !this.checkIfManager()) {
          updatePRFRequest.status = 'Not Submitted';
        } 
      }

      // Financial Protection and Client Return Date Check
      if (this.viewPrfHasClientReturned == 0) {
        let tempfinancialProtection = ""
        this.viewPrfFinancialProtection.checks.forEach((checked,index)=>{
          if (checked == true){
            if (tempfinancialProtection == "") {
              tempfinancialProtection = this.viewPrfFinancialProtection.methods[index];
            } else {
              tempfinancialProtection = tempfinancialProtection + "@" + this.viewPrfFinancialProtection.methods[index];
            }
          }
        });
        updatePRFRequest.financialProtection = tempfinancialProtection;
        if (this.viewPrfFinancialProtection.checks[0] == true) {
          if (this.viewPrfClaimOne == "Client Refund" && this.viewPrfClaimTwo == null){
            updatePRFRequest.clientReturnDate = ""
          } else {
            updatePRFRequest.clientReturnDate = this.convertDate(this.viewPrfClientReturnDate);
          }
        } 
      } else {
        updatePRFRequest.financialProtection = "";
        updatePRFRequest.clientReturnDate = "";
      }    
      
      // Setup leading passenger
      if (this.viewPrfTransactionValuesList.length > 0) {
        updatePRFRequest.passengerName = this.viewPrfTransactionValuesList[0].viewClientSurname;
      } else {
        if (this.viewPrfAddedTransactionValuesList.length > 0) {
          updatePRFRequest.passengerName = this.viewPrfAddedTransactionValuesList[0].viewClientSurname;
        } else {
          updatePRFRequest.passengerName = "N/A";
        }
      }     

      // PRF'S Transaction Values Update Request (updatePRFTransactionsRequest - Optional - Single)          
      if (this.viewPrfTransactionValuesList.length == 1){
        updatePRFTransactionsRequest = {
          transactionID: this.viewPrfTransactionValuesList[0].transactionID,
          prfNumber: this.viewPrfNumber,
          company: Session.mySession.getUser().company,
          operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode,
          clientSurname: this.viewPrfTransactionValuesList[0].viewClientSurname,
          dateFundsBanked: null,
          paymentMethod: this.viewPrfTransactionValuesList[0].viewPaymentMethod,
          grossAmount: Number(this.viewPrfTransactionValuesList[0].viewGrossClientFunds.amount),
          grossCurrency: this.viewPrfTransactionValuesList[0].viewGrossClientFunds.currency,
          guaranteeDeductedAmount: null,
          guaranteeDeductedCurrency: null,
          supplierAmount: null,
          supplierAmountCurrency: null,
          commissionAmount: null,
          commissionCurrency: null,
          reimbursementAmount: null,
          reimbursementCurrency: null,
          clientRefundAmount: null,
          clientRefundCurrency: null,
          token: Session.mySession.get('user').token
        }

        if (this.viewMultipleBookingReference == true){
            updatePRFTransactionsRequest.bookingReference = this.viewPrfTransactionValuesList[0].viewBookingReference
        }

        // DATE FUNDS BANKED CHECK
        if (this.viewPrfTransactionValuesList[0].viewDateFundsBanked != null && this.viewPrfTransactionValuesList[0].viewDateFundsBanked != ""){
          updatePRFTransactionsRequest.dateFundsBanked = this.convertDate(this.viewPrfTransactionValuesList[0].viewDateFundsBanked)
        }

        // GUARANTEE DEDUCTED CHECK
        if ((newClaims.claimOne == "Commission" || newClaims.claimTwo == "Commission") && this.viewPrfCollectedFrom == "Trust Account") {
          updatePRFTransactionsRequest.guaranteeDeductedAmount = Number(this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount);
          updatePRFTransactionsRequest.guaranteeDeductedCurrency = this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.currency;
        } 
          

        // SUP CLAIM ONE CHECK 
        if (newClaims.claimOne == "Supplier Payment"){
          updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
          updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.supplierAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = updatePRFTransactionsRequest.supplierAmountCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // SUP CLAIM TWO CHECK 
        if(newClaims.claimTwo == "Supplier Payment"){
          updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
          updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(updatePRFTransactionsRequest.supplierAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.supplierAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = updatePRFTransactionsRequest.supplierAmountCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }

        // REI CLAIM ONE CHECK 
        if (newClaims.claimOne == "Reimbursement"){
          updatePRFTransactionsRequest.reimbursementAmount = Number(this.viewPrfTransactionValuesList[0].viewReimbursement.amount);
          updatePRFTransactionsRequest.reimbursementCurrency = this.viewPrfTransactionValuesList[0].viewReimbursement.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.reimbursementAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = updatePRFTransactionsRequest.reimbursementCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // REI CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Reimbursement"){
          updatePRFTransactionsRequest.reimbursementAmount = Number(this.viewPrfTransactionValuesList[0].viewReimbursement.amount);
          updatePRFTransactionsRequest.reimbursementCurrency = this.viewPrfTransactionValuesList[0].viewReimbursement.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(updatePRFTransactionsRequest.reimbursementAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.reimbursementAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = updatePRFTransactionsRequest.reimbursementCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }

        // COM CLAIM ONE CHECK 
        if (newClaims.claimOne == "Commission"){
          updatePRFTransactionsRequest.commissionAmount = Number(this.viewPrfTransactionValuesList[0].viewCommission.amount);
          updatePRFTransactionsRequest.commissionCurrency = this.viewPrfTransactionValuesList[0].viewCommission.currency;          
          updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
          updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.commissionAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = updatePRFTransactionsRequest.commissionCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // COM CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Commission"){
          updatePRFTransactionsRequest.commissionAmount = Number(this.viewPrfTransactionValuesList[0].viewCommission.amount);
          updatePRFTransactionsRequest.commissionCurrency = this.viewPrfTransactionValuesList[0].viewCommission.currency;
          updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
          updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(updatePRFTransactionsRequest.commissionAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.commissionAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = updatePRFTransactionsRequest.commissionCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }

        // CR CLAIM ONE CHECK 
        if (newClaims.claimOne == "Client Refund"){
          updatePRFTransactionsRequest.clientRefundAmount = Number(this.viewPrfTransactionValuesList[0].viewClientRefund.amount);
          updatePRFTransactionsRequest.clientRefundCurrency = this.viewPrfTransactionValuesList[0].viewClientRefund.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.clientRefundAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = updatePRFTransactionsRequest.clientRefundCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // CR CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Client Refund"){
          updatePRFTransactionsRequest.clientRefundAmount = Number(this.viewPrfTransactionValuesList[0].viewClientRefund.amount);
          updatePRFTransactionsRequest.clientRefundCurrency = this.viewPrfTransactionValuesList[0].viewClientRefund.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(updatePRFTransactionsRequest.clientRefundAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.clientRefundAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = updatePRFTransactionsRequest.clientRefundCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }

      } 

      // PRF'S Transaction Values Update Request (updatePRFTransactionsRequestRequest - Optional - Multiple)     
      if (this.viewPrfTransactionValuesList.length > 1){
        updatePRFTransactionsRequest = {
          prfNumber: this.viewPrfNumber,
          listOfTransactions: [],
          company: Session.mySession.getUser().company,
          operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode,
          token: Session.mySession.get('user').token
        }
        let tempListOfTransactions:any = [];
        this.viewPrfTransactionValuesList.forEach((transaction:any) => {
          
            let tempPrfTransactionRequest:any = {
              transactionID: transaction.transactionID,
              bookingReference: transaction.viewBookingReference,
              clientSurname: transaction.viewClientSurname,
              dateFundsBanked: null,
              paymentMethod: transaction.viewPaymentMethod,
              grossAmount: Number(transaction.viewGrossClientFunds.amount),
              grossCurrency: transaction.viewGrossClientFunds.currency,
              guaranteeDeductedAmount: null,
              guaranteeDeductedCurrency: null,
              supplierAmount: null,
              supplierAmountCurrency: null,
              commissionAmount: null,
              commissionCurrency: null,
              reimbursementAmount: null,
              reimbursementCurrency: null,
              clientRefundAmount: null,
              clientRefundCurrency: null
            }
  
            // DATE FUNDS BANKED CHECK
            if (transaction.viewDateFundsBanked != null && transaction.viewDateFundsBanked != ""){
              tempPrfTransactionRequest.dateFundsBanked = this.convertDate(transaction.viewDateFundsBanked)
            }
  
            // GUARANTEE DEDUCTED CHECK
            if ((newClaims.claimOne == "Commission" || newClaims.claimTwo == "Commission") && this.viewPrfCollectedFrom == "Trust Account") {
              tempPrfTransactionRequest.guaranteeDeductedAmount = Number(transaction.viewGuaranteeDeducted.amount);
              tempPrfTransactionRequest.guaranteeDeductedCurrency = transaction.viewGuaranteeDeducted.currency;
            } 
              
            // SUP CLAIM ONE CHECK 
            if (newClaims.claimOne == "Supplier Payment"){
              tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
              tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
              newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.supplierAmount);
              if (newCurrencyOne == ""){
                newCurrencyOne = tempPrfTransactionRequest.supplierAmountCurrency;
              }
              if (newClaimOne == ""){
                newClaimOne = newClaims.claimOne
              }
              if (newPaymentMethodOne == ""){
                newPaymentMethodOne = newClaims.paymentMethodOne;
              }
            }
          
            // SUP CLAIM TWO CHECK 
            if(newClaims.claimTwo == "Supplier Payment"){
              tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
              tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
              if (newOverallAmountTwo == null){
                newOverallAmountTwo = Number(tempPrfTransactionRequest.supplierAmount);
              } else {
                newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.supplierAmount);
              }
              if (newCurrencyTwo == null){
                newCurrencyTwo = tempPrfTransactionRequest.supplierAmountCurrency;
              }
              if (newClaimTwo == null){
                newClaimTwo = newClaims.claimTwo
              }
              if (newPaymentMethodTwo == null){
                newPaymentMethodTwo = newClaims.paymentMethodTwo;
              }
            }
          
            // REI CLAIM ONE CHECK 
            if (newClaims.claimOne == "Reimbursement"){
              tempPrfTransactionRequest.reimbursementAmount = Number(transaction.viewReimbursement.amount);
              tempPrfTransactionRequest.reimbursementCurrency = transaction.viewReimbursement.currency;
              newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.reimbursementAmount);
              if (newCurrencyOne == ""){
                newCurrencyOne = tempPrfTransactionRequest.reimbursementCurrency;
              }
              if (newClaimOne == ""){
                newClaimOne = newClaims.claimOne
              }
              if (newPaymentMethodOne == ""){
                newPaymentMethodOne = newClaims.paymentMethodOne;
              }
            }
          
            // REI CLAIM TWO CHECK 
            if (newClaims.claimTwo == "Reimbursement"){
              tempPrfTransactionRequest.reimbursementAmount = Number(transaction.viewReimbursement.amount);
              tempPrfTransactionRequest.reimbursementCurrency = transaction.viewReimbursement.currency;
              if (newOverallAmountTwo == null){
                newOverallAmountTwo = Number(tempPrfTransactionRequest.reimbursementAmount);
              } else {
                newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.reimbursementAmount);
              }
              if (newCurrencyTwo == null){
                newCurrencyTwo = tempPrfTransactionRequest.reimbursementCurrency;
              }
              if (newClaimTwo == null){
                newClaimTwo = newClaims.claimTwo
              }
              if (newPaymentMethodTwo == null){
                newPaymentMethodTwo = newClaims.paymentMethodTwo;
              }
            }
          
            // COM CLAIM ONE CHECK 
            if (newClaims.claimOne == "Commission"){
              tempPrfTransactionRequest.commissionAmount = Number(transaction.viewCommission.amount);
              tempPrfTransactionRequest.commissionCurrency = transaction.viewCommission.currency;            
              tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
              tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
              newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.commissionAmount);
              if (newCurrencyOne == ""){
                newCurrencyOne = tempPrfTransactionRequest.commissionCurrency;
              }
              if (newClaimOne == ""){
                newClaimOne = newClaims.claimOne
              }
              if (newPaymentMethodOne == ""){
                newPaymentMethodOne = newClaims.paymentMethodOne;
              }
            }
          
            // COM CLAIM TWO CHECK 
            if (newClaims.claimTwo == "Commission"){
              tempPrfTransactionRequest.commissionAmount = Number(transaction.viewCommission.amount);
              tempPrfTransactionRequest.commissionCurrency = transaction.viewCommission.currency;            
              tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
              tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
              if (newOverallAmountTwo == null){
                newOverallAmountTwo = Number(tempPrfTransactionRequest.commissionAmount);
              } else {
                newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.commissionAmount);
              }
              if (newCurrencyTwo == null){
                newCurrencyTwo = tempPrfTransactionRequest.commissionCurrency;
              }
              if (newClaimTwo == null){
                newClaimTwo = newClaims.claimTwo
              }
              if (newPaymentMethodTwo == null){
                newPaymentMethodTwo = newClaims.paymentMethodTwo;
              }
            }
          
            // CR CLAIM ONE CHECK 
            if (newClaims.claimOne == "Client Refund"){
              tempPrfTransactionRequest.clientRefundAmount = Number(transaction.viewClientRefund.amount);
              tempPrfTransactionRequest.clientRefundCurrency = transaction.viewClientRefund.currency;
              newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.clientRefundAmount);
              if (newCurrencyOne == ""){
                newCurrencyOne = tempPrfTransactionRequest.clientRefundCurrency;
              }
              if (newClaimOne == ""){
                newClaimOne = newClaims.claimOne
              }
              if (newPaymentMethodOne == ""){
                newPaymentMethodOne = newClaims.paymentMethodOne;
              }
            }
          
            // CR CLAIM TWO CHECK 
            if (newClaims.claimTwo == "Client Refund"){
              tempPrfTransactionRequest.clientRefundAmount = Number(transaction.viewClientRefund.amount);
              tempPrfTransactionRequest.clientRefundCurrency = transaction.viewClientRefund.currency;
              if (newOverallAmountTwo == null){
                newOverallAmountTwo = Number(tempPrfTransactionRequest.clientRefundAmount);
              } else {
                newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.clientRefundAmount);
              }
              if (newCurrencyTwo == null){
                newCurrencyTwo = tempPrfTransactionRequest.clientRefundCurrency;
              }
              if (newClaimTwo == null){
                newClaimTwo = newClaims.claimTwo
              }
              if (newPaymentMethodTwo == null){
                newPaymentMethodTwo = newClaims.paymentMethodTwo;
              }
            }
  
            tempListOfTransactions.push(tempPrfTransactionRequest);
          
        });
        updatePRFTransactionsRequest.listOfTransactions = tempListOfTransactions;
      }

      // PRF'S Transaction Values Delete Request (deletePrfsTransactionsRequest - Optional - Single)
      if (this.viewPrfDeletedTransactionValuesList.length == 1){
        deletePrfsTransactionsRequest = {
          transactionID: this.viewPrfDeletedTransactionValuesList[0].transactionID,
          prfNumber: this.viewPrfNumber,
          company: Session.mySession.getUser().company,
          operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode,
          token: Session.mySession.get('user').token
        }
      } 

      // PRF'S Transaction Values Delete Request (deletePrfsTransactionsRequest - Optional - Multiple)
      if (this.viewPrfDeletedTransactionValuesList.length > 1){
        let tempListOfDeletedTransactionIDs:any = [];
        this.viewPrfDeletedTransactionValuesList.forEach((transaction:any) => {
          tempListOfDeletedTransactionIDs.push(transaction.transactionID);
        });
        deletePrfsTransactionsRequest = {
          listOfTransactionIDs: tempListOfDeletedTransactionIDs,
          prfNumber: this.viewPrfNumber,
          company: Session.mySession.getUser().company,
          operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode,
          token: Session.mySession.get('user').token
        }
      } 

      // PRF'S Transaction Values Create Request (createPrfsTransactionsRequest - Optional - Single) 
      if (this.viewPrfAddedTransactionValuesList.length == 1){
        createPrfsTransactionsRequest = { 
          prfNumber: this.viewPrfNumber,
          bookingReference: this.viewPrfAddedTransactionValuesList[0].viewBookingReference,
          clientSurname: this.viewPrfAddedTransactionValuesList[0].viewClientSurname,
          dateFundsBanked: null,
          paymentMethod: this.viewPrfAddedTransactionValuesList[0].viewPaymentMethod,
          grossAmount: Number(this.viewPrfAddedTransactionValuesList[0].viewGrossClientFunds.amount),
          grossCurrency: this.viewPrfAddedTransactionValuesList[0].viewGrossClientFunds.currency,
          guaranteeDeductedAmount: null,
          guaranteeDeductedCurrency: null,
          supplierAmount: null,
          supplierAmountCurrency: null,
          commissionAmount: null,
          commissionCurrency: null,
          reimbursementAmount: null,
          reimbursementCurrency: null,
          clientRefundAmount: null,
          clientRefundCurrency: null,
          company: Session.mySession.getUser().company,
          operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode,
          token: Session.mySession.get('user').token
        }

        // DATE FUNDS BANKED CHECK
        if (this.viewPrfAddedTransactionValuesList[0].viewDateFundsBanked != null && this.viewPrfAddedTransactionValuesList[0].viewDateFundsBanked != ""){
          createPrfsTransactionsRequest.dateFundsBanked = this.convertDate(this.viewPrfAddedTransactionValuesList[0].viewDateFundsBanked)
        }

        // GUARANTEE DEDUCTED CHECK
        if ((newClaims.claimOne == "Commission" || newClaims.claimTwo == "Commission") && this.viewPrfCollectedFrom == "Trust Account") {
          createPrfsTransactionsRequest.guaranteeDeductedAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewGuaranteeDeducted.amount);
          createPrfsTransactionsRequest.guaranteeDeductedCurrency = this.viewPrfAddedTransactionValuesList[0].viewGuaranteeDeducted.currency;
        } 

        // SUP CLAIM ONE CHECK 
        if (newClaims.claimOne == "Supplier Payment"){          
          createPrfsTransactionsRequest.supplierAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.amount);
          createPrfsTransactionsRequest.supplierAmountCurrency = this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(createPrfsTransactionsRequest.supplierAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = createPrfsTransactionsRequest.supplierAmountCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // SUP CLAIM TWO CHECK 
        if(newClaims.claimTwo == "Supplier Payment"){
          createPrfsTransactionsRequest.supplierAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.amount);
          createPrfsTransactionsRequest.supplierAmountCurrency = this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(createPrfsTransactionsRequest.supplierAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(createPrfsTransactionsRequest.supplierAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = createPrfsTransactionsRequest.supplierAmountCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }

        // REI CLAIM ONE CHECK 
        if (newClaims.claimOne == "Reimbursement"){
          createPrfsTransactionsRequest.reimbursementAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewReimbursement.amount);
          createPrfsTransactionsRequest.reimbursementCurrency = this.viewPrfAddedTransactionValuesList[0].viewReimbursement.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(createPrfsTransactionsRequest.reimbursementAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = createPrfsTransactionsRequest.reimbursementCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // REI CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Reimbursement"){
          createPrfsTransactionsRequest.reimbursementAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewReimbursement.amount);
          createPrfsTransactionsRequest.reimbursementCurrency = this.viewPrfAddedTransactionValuesList[0].viewReimbursement.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(createPrfsTransactionsRequest.reimbursementAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(createPrfsTransactionsRequest.reimbursementAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = createPrfsTransactionsRequest.reimbursementCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }

        // COM CLAIM ONE CHECK 
        if (newClaims.claimOne == "Commission"){
          createPrfsTransactionsRequest.commissionAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewCommission.amount);
          createPrfsTransactionsRequest.commissionCurrency = this.viewPrfAddedTransactionValuesList[0].viewCommission.currency;          
          createPrfsTransactionsRequest.supplierAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.amount);
          createPrfsTransactionsRequest.supplierAmountCurrency = this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(createPrfsTransactionsRequest.commissionAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = createPrfsTransactionsRequest.commissionCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // COM CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Commission"){
          createPrfsTransactionsRequest.commissionAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewCommission.amount);
          createPrfsTransactionsRequest.commissionCurrency = this.viewPrfAddedTransactionValuesList[0].viewCommission.currency;          
          createPrfsTransactionsRequest.supplierAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.amount);
          createPrfsTransactionsRequest.supplierAmountCurrency = this.viewPrfAddedTransactionValuesList[0].viewSupplierAmount.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(createPrfsTransactionsRequest.commissionAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(createPrfsTransactionsRequest.commissionAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = createPrfsTransactionsRequest.commissionCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }

        // CR CLAIM ONE CHECK 
        if (newClaims.claimOne == "Client Refund"){
          createPrfsTransactionsRequest.clientRefundAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewClientRefund.amount);
          createPrfsTransactionsRequest.clientRefundCurrency = this.viewPrfAddedTransactionValuesList[0].viewClientRefund.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(createPrfsTransactionsRequest.clientRefundAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = createPrfsTransactionsRequest.clientRefundCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }

        // CR CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Client Refund"){
          createPrfsTransactionsRequest.clientRefundAmount = Number(this.viewPrfAddedTransactionValuesList[0].viewClientRefund.amount);
          createPrfsTransactionsRequest.clientRefundCurrency = this.viewPrfAddedTransactionValuesList[0].viewClientRefund.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(createPrfsTransactionsRequest.clientRefundAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(createPrfsTransactionsRequest.clientRefundAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = createPrfsTransactionsRequest.clientRefundCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }
      }

      // PRF'S Transaction Values Create Request (createPrfsTransactionsRequest - Optional - Multiple) 
      if (this.viewPrfAddedTransactionValuesList.length > 1){
        createPrfsTransactionsRequest = { 
          prfNumber: this.viewPrfNumber,
          listOfTransactions: [],
          company: Session.mySession.getUser().company,
          operation: Session.mySession.getUser().operation,
          tradeCode: Session.mySession.getUser().tradeCode,
          token: Session.mySession.get('user').token
        }
        let tempListOfAddedTransactionValues:any = [];
        this.viewPrfAddedTransactionValuesList.forEach((transaction:any) => {
          let tempAddedTransactionValueRequest:any = {
            clientSurname: transaction.viewClientSurname,
            bookingReference: transaction.viewBookingReference,
            dateFundsBanked: null,
            paymentMethod: transaction.viewPaymentMethod,
            grossAmount: Number(transaction.viewGrossClientFunds.amount),
            grossCurrency: transaction.viewGrossClientFunds.currency,
            guaranteeDeductedAmount: null,
            guaranteeDeductedCurrency: null,
            supplierAmount: null,
            supplierAmountCurrency: null,
            commissionAmount: null,
            commissionCurrency: null,
            reimbursementAmount: null,
            reimbursementCurrency: null,
            clientRefundAmount: null,
            clientRefundCurrency: null
          }

          // DATE FUNDS BANKED CHECK
          if (transaction.viewDateFundsBanked != null &&  transaction.viewDateFundsBanked != ""){
            tempAddedTransactionValueRequest.dateFundsBanked = this.convertDate(transaction.viewDateFundsBanked)
          }

          // GUARANTEE DEDUCTED CHECK
          if ((newClaims.claimOne == "Commission" || newClaims.claimTwo == "Commission") && this.viewPrfCollectedFrom == "Trust Account") {
            tempAddedTransactionValueRequest.guaranteeDeductedAmount = Number(transaction.viewGuaranteeDeducted.amount);
            tempAddedTransactionValueRequest.guaranteeDeductedCurrency = transaction.viewGuaranteeDeducted.currency;
          }

          // SUP CLAIM ONE CHECK 
          if (newClaims.claimOne == "Supplier Payment"){
            tempAddedTransactionValueRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
            tempAddedTransactionValueRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
            newOverallAmountOne = Number(newOverallAmountOne) + Number(tempAddedTransactionValueRequest.supplierAmount);
            if (newCurrencyOne == ""){
              newCurrencyOne = tempAddedTransactionValueRequest.supplierAmountCurrency;
            }
            if (newClaimOne == ""){
              newClaimOne = newClaims.claimOne
            }
            if (newPaymentMethodOne == ""){
              newPaymentMethodOne = newClaims.paymentMethodOne;
            }
          }
          
          // SUP CLAIM TWO CHECK 
          if(newClaims.claimTwo == "Supplier Payment"){
            tempAddedTransactionValueRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
            tempAddedTransactionValueRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
            if (newOverallAmountTwo == null){
              newOverallAmountTwo = Number(tempAddedTransactionValueRequest.supplierAmount);
            } else {
              newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempAddedTransactionValueRequest.supplierAmount);
            }
            if (newCurrencyTwo == null){
              newCurrencyTwo = tempAddedTransactionValueRequest.supplierAmountCurrency;
            }
            if (newClaimTwo == null){
              newClaimTwo = newClaims.claimTwo
            }
            if (newPaymentMethodTwo == null){
              newPaymentMethodTwo = newClaims.paymentMethodTwo;
            }
          }
          
          // REI CLAIM ONE CHECK 
          if (newClaims.claimOne == "Reimbursement"){
            tempAddedTransactionValueRequest.reimbursementAmount = Number(transaction.viewReimbursement.amount);
            tempAddedTransactionValueRequest.reimbursementCurrency = transaction.viewReimbursement.currency;
            newOverallAmountOne = Number(newOverallAmountOne) + Number(tempAddedTransactionValueRequest.reimbursementAmount);
            if (newCurrencyOne == ""){
              newCurrencyOne = tempAddedTransactionValueRequest.reimbursementCurrency;
            }
            if (newClaimOne == ""){
              newClaimOne = newClaims.claimOne
            }
            if (newPaymentMethodOne == ""){
              newPaymentMethodOne = newClaims.paymentMethodOne;
            }
          }
          
          // REI CLAIM TWO CHECK 
          if (newClaims.claimTwo == "Reimbursement"){
            tempAddedTransactionValueRequest.reimbursementAmount = Number(transaction.viewReimbursement.amount);
            tempAddedTransactionValueRequest.reimbursementCurrency = transaction.viewReimbursement.currency;
            if (newOverallAmountTwo == null){
              newOverallAmountTwo = Number(tempAddedTransactionValueRequest.reimbursementAmount);
            } else {
              newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempAddedTransactionValueRequest.reimbursementAmount);
            }
            if (newCurrencyTwo == null){
              newCurrencyTwo = tempAddedTransactionValueRequest.reimbursementCurrency;
            }
            if (newClaimTwo == null){
              newClaimTwo = newClaims.claimTwo
            }
            if (newPaymentMethodTwo == null){
              newPaymentMethodTwo = newClaims.paymentMethodTwo;
            }
          }
          
          // COM CLAIM ONE CHECK 
          if (newClaims.claimOne == "Commission"){
            tempAddedTransactionValueRequest.commissionAmount = Number(transaction.viewCommission.amount);
            tempAddedTransactionValueRequest.commissionCurrency = transaction.viewCommission.currency;
            tempAddedTransactionValueRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
            tempAddedTransactionValueRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
            newOverallAmountOne = Number(newOverallAmountOne) + Number(tempAddedTransactionValueRequest.commissionAmount);
            if (newCurrencyOne == ""){
              newCurrencyOne = tempAddedTransactionValueRequest.commissionCurrency;
            }
            if (newClaimOne == ""){
              newClaimOne = newClaims.claimOne
            }
            if (newPaymentMethodOne == ""){
              newPaymentMethodOne = newClaims.paymentMethodOne;
            }
          }
          
          // COM CLAIM TWO CHECK 
          if (newClaims.claimTwo == "Commission"){
            tempAddedTransactionValueRequest.commissionAmount = Number(transaction.viewCommission.amount);
            tempAddedTransactionValueRequest.commissionCurrency = transaction.viewCommission.currency;
            tempAddedTransactionValueRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
            tempAddedTransactionValueRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
            if (newOverallAmountTwo == null){
              newOverallAmountTwo = Number(tempAddedTransactionValueRequest.commissionAmount);
            } else {
              newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempAddedTransactionValueRequest.commissionAmount);
            }
            if (newCurrencyTwo == null){
              newCurrencyTwo = tempAddedTransactionValueRequest.commissionCurrency;
            }
            if (newClaimTwo == null){
              newClaimTwo = newClaims.claimTwo
            }
            if (newPaymentMethodTwo == null){
              newPaymentMethodTwo = newClaims.paymentMethodTwo;
            }
          }
          
          // CR CLAIM ONE CHECK 
          if (newClaims.claimOne == "Client Refund"){
            tempAddedTransactionValueRequest.clientRefundAmount = Number(transaction.viewClientRefund.amount);
            tempAddedTransactionValueRequest.clientRefundCurrency = transaction.viewClientRefund.currency;
            newOverallAmountOne = Number(newOverallAmountOne) + Number(tempAddedTransactionValueRequest.clientRefundAmount);
            if (newCurrencyOne == ""){
              newCurrencyOne = tempAddedTransactionValueRequest.clientRefundCurrency;
            }
            if (newClaimOne == ""){
              newClaimOne = newClaims.claimOne
            }
            if (newPaymentMethodOne == ""){
              newPaymentMethodOne = newClaims.paymentMethodOne;
            }
          }
          
          // CR CLAIM TWO CHECK 
          if (newClaims.claimTwo == "Client Refund"){
            tempAddedTransactionValueRequest.clientRefundAmount = Number(transaction.viewClientRefund.amount);
            tempAddedTransactionValueRequest.clientRefundCurrency = transaction.viewClientRefund.currency;
            if (newOverallAmountTwo == null){
              newOverallAmountTwo = Number(tempAddedTransactionValueRequest.clientRefundAmount);
            } else {
              newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempAddedTransactionValueRequest.clientRefundAmount);
            }
            if (newCurrencyTwo == null){
              newCurrencyTwo = tempAddedTransactionValueRequest.clientRefundCurrency;
            }
            if (newClaimTwo == null){
              newClaimTwo = newClaims.claimTwo
            }
            if (newPaymentMethodTwo == null){
              newPaymentMethodTwo = newClaims.paymentMethodTwo;
            }
          }

          tempListOfAddedTransactionValues.push(tempAddedTransactionValueRequest);

        });
        
        createPrfsTransactionsRequest.listOfTransactions = tempListOfAddedTransactionValues;
      }
      
      // Setup amountOne / currencyOne / claimOne / paymentMethodOne
      updatePRFRequest.amountOne = newOverallAmountOne;
      updatePRFRequest.currencyOne = newCurrencyOne;
      updatePRFRequest.claimOne = newClaimOne;
      updatePRFRequest.paymentMethodOne = newPaymentMethodOne;

      // Setup amountTwo / currencyTwo / claimTwo / paymentMethodTwo (Optional)
      if (newOverallAmountTwo != null && newCurrencyTwo != null && newClaimTwo != null && newPaymentMethodTwo != null){
        updatePRFRequest.amountTwo = Number(newOverallAmountTwo);
        updatePRFRequest.currencyTwo = String(newCurrencyTwo);
        updatePRFRequest.claimTwo = String(newClaimTwo);
        updatePRFRequest.paymentMethodTwo = String(newPaymentMethodTwo);
      } 

      // Booking Reference
      if (this.viewMatchedBookingReference != "" && this.viewMatchedBookingReference != null){
        updatePRFRequest.bookingReference = this.viewMatchedBookingReference;
      }

      this.updatePRF(updatePRFRequest,createPrfsTransactionsRequest,updatePRFTransactionsRequest,deletePrfsTransactionsRequest);
    } else {
      this.pageLoaded = true;
    }
  }

  // Initialise the Update PRF Request for Trustee's updates
  updatePrfForTrustee(){
    this.pageLoaded = false;

    // New Data 
    let newClaims = this.getNewClaimsOfPRF("View");
    let newOverallAmountOne:any = 0.00
    let newCurrencyOne:any = ""
    let newClaimOne:any = ""
    let newPaymentMethodOne:any = ""
    let newOverallAmountTwo:any = null
    let newCurrencyTwo:any = null
    let newClaimTwo:any = null
    let newPaymentMethodTwo:any = null
    let updatePRFTransactionsRequest:any = {}
    let updatePRFRequest:any = {}

    // PRF'S Transaction Values Update Request (updatePRFTransactionsRequest - Optional - Single)          
    if (this.viewPrfTransactionValuesList.length == 1){
      updatePRFTransactionsRequest = {
        transactionID: this.viewPrfTransactionValuesList[0].transactionID,
        prfNumber: this.viewPrfNumber,
        company: Session.mySession.getUser().company,
        operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode,
        clientSurname: this.viewPrfTransactionValuesList[0].viewClientSurname,
        dateFundsBanked: null,
        paymentMethod: this.viewPrfTransactionValuesList[0].viewPaymentMethod,
        grossAmount: Number(this.viewPrfTransactionValuesList[0].viewGrossClientFunds.amount),
        grossCurrency: this.viewPrfTransactionValuesList[0].viewGrossClientFunds.currency,
        guaranteeDeductedAmount: null,
        guaranteeDeductedCurrency: null,
        supplierAmount: null,
        supplierAmountCurrency: null,
        commissionAmount: null,
        commissionCurrency: null,
        reimbursementAmount: null,
        reimbursementCurrency: null,
        clientRefundAmount: null,
        clientRefundCurrency: null,
        token: Session.mySession.get('user').token
      }

      // DATE FUNDS BANKED CHECK
      if (this.viewPrfTransactionValuesList[0].viewDateFundsBanked != null && this.viewPrfTransactionValuesList[0].viewDateFundsBanked != ""){
        updatePRFTransactionsRequest.dateFundsBanked = this.convertDate(this.viewPrfTransactionValuesList[0].viewDateFundsBanked)
      }

      // GUARANTEE DEDUCTED CHECK
      if ((newClaims.claimOne == "Commission" || newClaims.claimTwo == "Commission") && this.viewPrfCollectedFrom == "Trust Account") {
        updatePRFTransactionsRequest.guaranteeDeductedAmount = Number(this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.amount);
        updatePRFTransactionsRequest.guaranteeDeductedCurrency = this.viewPrfTransactionValuesList[0].viewGuaranteeDeducted.currency;
      }

      // SUP CLAIM ONE CHECK 
      if (newClaims.claimOne == "Supplier Payment"){
        updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
        updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
        newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.supplierAmount);
        if (newCurrencyOne == ""){
          newCurrencyOne = updatePRFTransactionsRequest.supplierAmountCurrency;
        }
        if (newClaimOne == ""){
          newClaimOne = newClaims.claimOne
        }
        if (newPaymentMethodOne == ""){
          newPaymentMethodOne = newClaims.paymentMethodOne;
        }
      }

      // SUP CLAIM TWO CHECK 
      if(newClaims.claimTwo == "Supplier Payment"){
        updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
        updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
        if (newOverallAmountTwo == null){
          newOverallAmountTwo = Number(updatePRFTransactionsRequest.supplierAmount);
        } else {
          newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.supplierAmount);
        }
        if (newCurrencyTwo == null){
          newCurrencyTwo = updatePRFTransactionsRequest.supplierAmountCurrency;
        }
        if (newClaimTwo == null){
          newClaimTwo = newClaims.claimTwo
        }
        if (newPaymentMethodTwo == null){
          newPaymentMethodTwo = newClaims.paymentMethodTwo;
        }
      }

      // REI CLAIM ONE CHECK 
      if (newClaims.claimOne == "Reimbursement"){
        updatePRFTransactionsRequest.reimbursementAmount = Number(this.viewPrfTransactionValuesList[0].viewReimbursement.amount);
        updatePRFTransactionsRequest.reimbursementCurrency = this.viewPrfTransactionValuesList[0].viewReimbursement.currency;
        newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.reimbursementAmount);
        if (newCurrencyOne == ""){
          newCurrencyOne = updatePRFTransactionsRequest.reimbursementCurrency;
        }
        if (newClaimOne == ""){
          newClaimOne = newClaims.claimOne
        }
        if (newPaymentMethodOne == ""){
          newPaymentMethodOne = newClaims.paymentMethodOne;
        }
      }

      // REI CLAIM TWO CHECK 
      if (newClaims.claimTwo == "Reimbursement"){
        updatePRFTransactionsRequest.reimbursementAmount = Number(this.viewPrfTransactionValuesList[0].viewReimbursement.amount);
        updatePRFTransactionsRequest.reimbursementCurrency = this.viewPrfTransactionValuesList[0].viewReimbursement.currency;
        if (newOverallAmountTwo == null){
          newOverallAmountTwo = Number(updatePRFTransactionsRequest.reimbursementAmount);
        } else {
          newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.reimbursementAmount);
        }
        if (newCurrencyTwo == null){
          newCurrencyTwo = updatePRFTransactionsRequest.reimbursementCurrency;
        }
        if (newClaimTwo == null){
          newClaimTwo = newClaims.claimTwo
        }
        if (newPaymentMethodTwo == null){
          newPaymentMethodTwo = newClaims.paymentMethodTwo;
        }
      }

      // COM CLAIM ONE CHECK 
      if (newClaims.claimOne == "Commission"){
        updatePRFTransactionsRequest.commissionAmount = Number(this.viewPrfTransactionValuesList[0].viewCommission.amount);
        updatePRFTransactionsRequest.commissionCurrency = this.viewPrfTransactionValuesList[0].viewCommission.currency;          
        updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
        updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
        newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.commissionAmount);
        if (newCurrencyOne == ""){
          newCurrencyOne = updatePRFTransactionsRequest.commissionCurrency;
        }
        if (newClaimOne == ""){
          newClaimOne = newClaims.claimOne
        }
        if (newPaymentMethodOne == ""){
          newPaymentMethodOne = newClaims.paymentMethodOne;
        }
      }

      // COM CLAIM TWO CHECK 
      if (newClaims.claimTwo == "Commission"){
        updatePRFTransactionsRequest.commissionAmount = Number(this.viewPrfTransactionValuesList[0].viewCommission.amount);
        updatePRFTransactionsRequest.commissionCurrency = this.viewPrfTransactionValuesList[0].viewCommission.currency;
        updatePRFTransactionsRequest.supplierAmount = Number(this.viewPrfTransactionValuesList[0].viewSupplierAmount.amount);
        updatePRFTransactionsRequest.supplierAmountCurrency = this.viewPrfTransactionValuesList[0].viewSupplierAmount.currency;
        if (newOverallAmountTwo == null){
          newOverallAmountTwo = Number(updatePRFTransactionsRequest.commissionAmount);
        } else {
          newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.commissionAmount);
        }
        if (newCurrencyTwo == null){
          newCurrencyTwo = updatePRFTransactionsRequest.commissionCurrency;
        }
        if (newClaimTwo == null){
          newClaimTwo = newClaims.claimTwo
        }
        if (newPaymentMethodTwo == null){
          newPaymentMethodTwo = newClaims.paymentMethodTwo;
        }
      }

      // CR CLAIM ONE CHECK 
      if (newClaims.claimOne == "Client Refund"){
        updatePRFTransactionsRequest.clientRefundAmount = Number(this.viewPrfTransactionValuesList[0].viewClientRefund.amount);
        updatePRFTransactionsRequest.clientRefundCurrency = this.viewPrfTransactionValuesList[0].viewClientRefund.currency;
        newOverallAmountOne = Number(newOverallAmountOne) + Number(updatePRFTransactionsRequest.clientRefundAmount);
        if (newCurrencyOne == ""){
          newCurrencyOne = updatePRFTransactionsRequest.clientRefundCurrency;
        }
        if (newClaimOne == ""){
          newClaimOne = newClaims.claimOne
        }
        if (newPaymentMethodOne == ""){
          newPaymentMethodOne = newClaims.paymentMethodOne;
        }
      }

      // CR CLAIM TWO CHECK 
      if (newClaims.claimTwo == "Client Refund"){
        updatePRFTransactionsRequest.clientRefundAmount = Number(this.viewPrfTransactionValuesList[0].viewClientRefund.amount);
        updatePRFTransactionsRequest.clientRefundCurrency = this.viewPrfTransactionValuesList[0].viewClientRefund.currency;
        if (newOverallAmountTwo == null){
          newOverallAmountTwo = Number(updatePRFTransactionsRequest.clientRefundAmount);
        } else {
          newOverallAmountTwo = Number(newOverallAmountTwo) + Number(updatePRFTransactionsRequest.clientRefundAmount);
        }
        if (newCurrencyTwo == null){
          newCurrencyTwo = updatePRFTransactionsRequest.clientRefundCurrency;
        }
        if (newClaimTwo == null){
          newClaimTwo = newClaims.claimTwo
        }
        if (newPaymentMethodTwo == null){
          newPaymentMethodTwo = newClaims.paymentMethodTwo;
        }
      }

    } 

    // PRF'S Transaction Values Update Request (updatePRFTransactionsRequestRequest - Optional - Multiple)     
    if (this.viewPrfTransactionValuesList.length > 1){
      updatePRFTransactionsRequest = {
        prfNumber: this.viewPrfNumber,
        listOfTransactions: [],
        company: Session.mySession.getUser().company,
        operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode,
        token: Session.mySession.get('user').token
      }
      let tempListOfTransactions:any = [];
      this.viewPrfTransactionValuesList.forEach((transaction:any) => {
        let tempPrfTransactionRequest:any = {
          transactionID: transaction.transactionID,
          bookingReference: transaction.viewBookingReference,
          clientSurname: transaction.viewClientSurname,
          dateFundsBanked: null,
          paymentMethod: transaction.viewPaymentMethod,
          grossAmount: Number(transaction.viewGrossClientFunds.amount),
          grossCurrency: transaction.viewGrossClientFunds.currency,
          guaranteeDeductedAmount: null,
          guaranteeDeductedCurrency: null,
          supplierAmount: null,
          supplierAmountCurrency: null,
          commissionAmount: null,
          commissionCurrency: null,
          reimbursementAmount: null,
          reimbursementCurrency: null,
          clientRefundAmount: null,
          clientRefundCurrency: null
        }

        // DATE FUNDS BANKED CHECK
        if (transaction.viewDateFundsBanked != null && transaction.viewDateFundsBanked != ""){
          tempPrfTransactionRequest.dateFundsBanked = this.convertDate(transaction.viewDateFundsBanked)
        }

        // GUARANTEE DEDUCTED CHECK
        if ((newClaims.claimOne == "Commission" || newClaims.claimTwo == "Commission") && this.viewPrfCollectedFrom == "Trust Account") {
          tempPrfTransactionRequest.guaranteeDeductedAmount = Number(transaction.viewGuaranteeDeducted.amount);
          tempPrfTransactionRequest.guaranteeDeductedCurrency = transaction.viewGuaranteeDeducted.currency;
        }
          
        // SUP CLAIM ONE CHECK 
        if (newClaims.claimOne == "Supplier Payment"){
          tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
          tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.supplierAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = tempPrfTransactionRequest.supplierAmountCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }
      
        // SUP CLAIM TWO CHECK 
        if(newClaims.claimTwo == "Supplier Payment"){
          tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
          tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(tempPrfTransactionRequest.supplierAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.supplierAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = tempPrfTransactionRequest.supplierAmountCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }
      
        // REI CLAIM ONE CHECK 
        if (newClaims.claimOne == "Reimbursement"){
          tempPrfTransactionRequest.reimbursementAmount = Number(transaction.viewReimbursement.amount);
          tempPrfTransactionRequest.reimbursementCurrency = transaction.viewReimbursement.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.reimbursementAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = tempPrfTransactionRequest.reimbursementCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }
      
        // REI CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Reimbursement"){
          tempPrfTransactionRequest.reimbursementAmount = Number(transaction.viewReimbursement.amount);
          tempPrfTransactionRequest.reimbursementCurrency = transaction.viewReimbursement.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(tempPrfTransactionRequest.reimbursementAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.reimbursementAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = tempPrfTransactionRequest.reimbursementCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }
      
        // COM CLAIM ONE CHECK 
        if (newClaims.claimOne == "Commission"){
          tempPrfTransactionRequest.commissionAmount = Number(transaction.viewCommission.amount);
          tempPrfTransactionRequest.commissionCurrency = transaction.viewCommission.currency;            
          tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
          tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.commissionAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = tempPrfTransactionRequest.commissionCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }
      
        // COM CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Commission"){
          tempPrfTransactionRequest.commissionAmount = Number(transaction.viewCommission.amount);
          tempPrfTransactionRequest.commissionCurrency = transaction.viewCommission.currency;            
          tempPrfTransactionRequest.supplierAmount = Number(transaction.viewSupplierAmount.amount);
          tempPrfTransactionRequest.supplierAmountCurrency = transaction.viewSupplierAmount.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(tempPrfTransactionRequest.commissionAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.commissionAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = tempPrfTransactionRequest.commissionCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }
      
        // CR CLAIM ONE CHECK 
        if (newClaims.claimOne == "Client Refund"){
          tempPrfTransactionRequest.clientRefundAmount = Number(transaction.viewClientRefund.amount);
          tempPrfTransactionRequest.clientRefundCurrency = transaction.viewClientRefund.currency;
          newOverallAmountOne = Number(newOverallAmountOne) + Number(tempPrfTransactionRequest.clientRefundAmount);
          if (newCurrencyOne == ""){
            newCurrencyOne = tempPrfTransactionRequest.clientRefundCurrency;
          }
          if (newClaimOne == ""){
            newClaimOne = newClaims.claimOne
          }
          if (newPaymentMethodOne == ""){
            newPaymentMethodOne = newClaims.paymentMethodOne;
          }
        }
      
        // CR CLAIM TWO CHECK 
        if (newClaims.claimTwo == "Client Refund"){
          tempPrfTransactionRequest.clientRefundAmount = Number(transaction.viewClientRefund.amount);
          tempPrfTransactionRequest.clientRefundCurrency = transaction.viewClientRefund.currency;
          if (newOverallAmountTwo == null){
            newOverallAmountTwo = Number(tempPrfTransactionRequest.clientRefundAmount);
          } else {
            newOverallAmountTwo = Number(newOverallAmountTwo) + Number(tempPrfTransactionRequest.clientRefundAmount);
          }
          if (newCurrencyTwo == null){
            newCurrencyTwo = tempPrfTransactionRequest.clientRefundCurrency;
          }
          if (newClaimTwo == null){
            newClaimTwo = newClaims.claimTwo
          }
          if (newPaymentMethodTwo == null){
            newPaymentMethodTwo = newClaims.paymentMethodTwo;
          }
        }
        tempListOfTransactions.push(tempPrfTransactionRequest);
      });
      updatePRFTransactionsRequest.listOfTransactions = tempListOfTransactions;
    }
    
    // PRF Update Request
    updatePRFRequest = {
      prfNumber: this.viewPrfNumber,
      amountOne: newOverallAmountOne,         
      currencyOne: newCurrencyOne,     
      amountTwo: newOverallAmountTwo,         
      currencyTwo: newCurrencyTwo,     
      amountOneTrustee: this.viewPrfAmountOneTrustee,
      currencyOneTrustee: this.viewPrfCurrencyOneTrustee,
      amountTwoTrustee: this.viewPrfAmountTwoTrustee,
      currencyTwoTrustee: this.viewPrfCurrencyTwoTrustee,
      company: Session.mySession.getUser().company,
      operation: Session.mySession.getUser().operation,
      tradeCode: Session.mySession.getUser().tradeCode,
      username: Session.mySession.getUser().fullName,
      userType: Session.mySession.getUser().userType,       
      token: Session.mySession.get('user').token
    }
    
    this.updatePRF(updatePRFRequest,0,updatePRFTransactionsRequest,0)
  }

  // Update PRF with the help of PrfService (Also update prfBreakdown)
  updatePRF(request: any, createPRFTransactionRequest: any, updatePRFTransactionsRequest:any, deletePrfsTransactionsRequest:any) {
    return new Promise<void>((resolve, reject) => {
      this.prfService.updatePrf(request).then((res: any) => {
        this.pageLoaded = true;
        if (res.status != "OK") {
          this.sendMessageToDialog("", "Error with request status: " + res.res, "", "");
          if (res.res == "PRF update denied, reload PRF."){
            this.clearViewedPrf();
            this.closePrfTab();
          }
        } else {
          // Check for Updated Transactions
          if (updatePRFTransactionsRequest != 0){
            this.prfService.updatePrfsTransaction(updatePRFTransactionsRequest).then((updateRes:any)=>{
              if (updateRes.status != "OK") {
                this.sendMessageToDialog("", "Error with update transactions request status: " + updateRes.res, "", "");
              } else {
                console.log("Update PRF-Transactions Status : " + updateRes.status)
                console.log(updateRes)       
              } 
            });
          }
          // Check for Deleted Transactions
          if (deletePrfsTransactionsRequest != 0){
            this.prfService.deletePrfsTransaction(deletePrfsTransactionsRequest).then((deleteRes:any)=>{
              if (deleteRes.status != "OK") {
                this.sendMessageToDialog("", "Error with update transactions request status: " + deleteRes.res, "", "");
              } else {
                console.log("Delete PRF-Transactions Status : " + deleteRes.status)
                console.log(deleteRes)
              }
              this.viewPrfDeletedTransactionValuesList = [];
            });
          }
          // Check for Created Transactions
          if (createPRFTransactionRequest != 0){
            this.prfService.createPrfsTransaction(createPRFTransactionRequest).then((createRes:any)=>{
              if (createRes.status != "OK") {
                this.sendMessageToDialog("", "Error with create transactions request status: " + createRes.res, "", "");
              } else {
                console.log("Create PRF-Transactions Status : " + createRes.status)
                console.log(createRes)
              }              
              this.viewPrfAddedTransactionValuesList = [];  
            });
          }
          // RELOAD PRF VIEW          
          this.exTransactionViewChanged = true;
          if (request.status == "Not Submitted"){
            this.sendMessageToDialog("PRF-" + this.viewPrfNumber + " successfully updated, not with TTA Trustees", "", "", "");
          } else {
            this.sendMessageToDialog("PRF-" + this.viewPrfNumber + " successfully updated, with TTA Trustees", "", "", "");
          }
          this.listedViewPrfs = [];
          this.prfNoView = [];
          this.prfBreakdown = [];
          this.clearViewedPrf();
          this.switchView("list");
        }
        resolve();
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2010S)', error, request);
        reject("Promise rejected");
      })
    });
  }

  // Delete method (Only used for emergency by the SinGS Admin)
  async deletePRFobject(){
    if (this.checkIfSingsAdmin()){
      let deletePRFRequest:any = {   
        prfNumber: this.viewPrfNumber,
        company: Session.mySession.getUser().company,
        operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode,
        userType: Session.mySession.getUser().userType,       
        token: Session.mySession.get('user').token
      }
      this.prfService.deletePrf(deletePRFRequest).then((deleteRes:any)=>{
        this.pageLoaded = true;
        if (deleteRes.status != "OK") {
          this.sendMessageToDialog("", "Error with delete transactions request status: " + deleteRes.res, "", "");
        } else {
          console.log("Deleted PRF : " + deleteRes.status)
          console.log(deleteRes)
          this.sendMessageToDialog("PRF-" + this.viewPrfNumber + " successfully deleted", "", "", ""); 
          this.listedViewPrfs = [];
          this.clearViewedPrf();
          this.switchView("list"); 
        } 
      }).catch((error:any)=>{
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2019S)', error, deletePRFRequest);
      })
    } else {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'Invalid user type', "", "");
    }
  }

  // Reload PRF Transactions - View PRF / Transaction Values Page
  onPrfTransactionsReload(){
    if (confirm("Are you sure you want to reload the original 'PRF Transaction Values'?")) {
      let reloadRequest:any = {
        company: Session.mySession.getUser().company, 
        operation: Session.mySession.getUser().operation, 
        tradeCode: Session.mySession.getUser().tradeCode,  
        token: Session.mySession.get('user').token,
        prfNumber: this.viewPrfNumber
      }
      this.viewPrfDeletedTransactionValuesList = [];
      this.viewPrfAddedTransactionValuesList = [];
      this.reloadPrfsTransactionValues(reloadRequest,this.viewMatchedBookingReference,false);
    } 
  }

  // Style Supporting Documentation title of documents (Based on selected claims)
  getCreateSupportingDocumentationTitle() {
      let title: String = "";
      if (this.createClaimCommission.checked){
        title = 'Commission';
      }
      if (this.createClaimSupplierPayment.checked){
        if (this.createClaimCommission.checked){
          title = 'Commission & Supplier Payment';
        } else {
          title = 'Supplier Payment';
        }
      }
      if (this.createClaimReimbursement.checked){
        if (this.createClaimCommission.checked){
          title = 'Commission & Reimbursement';
        } else {
          title = 'Reimbursement';
        }
      }
      if (this.createClaimClientRefund.checked){
        if (this.createClaimCommission.checked){
          title = 'Commission & Client Refund';
        } else {
          title = 'Client Refund';
        }
      }
      return title;    
  }

  // Style Supporting Documentation title of documents (Based on selected claims)
  getSupportingDocumentationTitle(claimOne: String, claimTwo: any) {
    let title: String = "";
    if (claimTwo != null) {
      title = claimOne + " & " + claimTwo;
    } else {
      title = claimOne;
    }
    return title;    
  }

  // Delete's PRF documents and then the table (Step-2) reloads.
  removePrfDocument(fileName: any) {
    if (confirm("Are you sure you want to remove " + fileName + '?')) {
      let removeRequest = {
        company: this.viewPrfCompany.toLowerCase(),
        operation: this.viewPrfOperation,
        tradeCode: this.viewPrfTradeCode,
        bookingReference: this.viewPrfNumber.toString(),
        fileName: fileName,
        pathType: 'prf',
        token: Session.mySession.get('user').token
      };
      this.pageLoaded = false;
      this.prfService.removeS3prfDoc(removeRequest).then((output: any) => {
        if (output.status == "OK") {
          this.pageLoaded = true;
          this.viewPrfDocumentsData.data.splice(this.viewPrfDocumentsData.data.findIndex((doc: any) => doc.fileName == fileName), 1)
          if (this.viewPrfDocumentsData.data.length == 0 || this.viewPrfDocumentsData.data.length == -1) {
            this.viewPrfDocumentsData.data = [];
          } else {
            let newDocumentsList = new MatTableDataSource<any>();
            this.viewPrfDocumentsData.data.forEach((doc: any) => { newDocumentsList.data.push(doc); });
            this.viewPrfDocumentsData.data = newDocumentsList.data;
          }
          this.sendMessageToDialog('Successfully removed file (' + fileName + ')', '', '', ''); // File removal failed at the back-end
        } else {
          this.sendMessageToDialog('', output.status, '', ''); // File removal failed at the back-end
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2011S)', error, removeRequest);
      });
    }
  }

  // Sends request to AWS to download a specific document (Use of fileName)
  async downloadPrfDocument(fileName: any) {
    this.pageLoaded = false;
    let downloadRequest = {
      company: this.viewPrfCompany.toLowerCase(),
      operation: this.viewPrfOperation,
      tradeCode: this.viewPrfTradeCode,
      fileName: fileName,
      prfNumber: this.viewPrfNumber,
      token: Session.mySession.get('user').token
    }

    // Get file's extension and prepare for assigning MIME type below..
    let extension = fileName.substr(fileName.lastIndexOf('.') + 1); let type = '';
    if (extension == 'csv') { type = 'text/csv' }
    else if (extension == 'doc') { type = 'application/msword' }
    else if (extension == 'docx') { type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
    else if (extension == 'pdf') { type = 'application/pdf' }
    else if (extension == 'xls') { type = 'application/vnd.ms-excel' }
    else if (extension == 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    else if (extension === 'eml') { type = 'message/rfc822'; }
    else if (extension === 'msg') { type = 'application/octet-stream'; }
    else if (extension === 'jpeg' || extension === 'jpg') { type = 'image/jpeg'; }
    else if (extension === 'png') { type = 'image/png'; }

    await this.prfService.downloadS3prfDoc(downloadRequest).then((output: any) => {
      if (output.status == "OK") {
        try {
          var byteString = atob(output.fileContent);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          let data = new Blob([ab], { type: type });
          saveAs(data, fileName);
          this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', '');
        }
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2012S)', error, downloadRequest);
    });
  }

  // This is used to go forward (in Stepper) when you click the arrow button when you View/Create PRF
  goBack(){
    this.stepper.previous();
  }

  // This is used to go back (in Stepper) when you click the arrow button when you View/Create PRF
  goForward(){
    this.stepper.next();
  }

  // Filters available reports for Member
  filterReportsListForAgent(listOfReports:any){
    let newList:any = [];
    listOfReports.forEach((report:any) => { if (report.onlyForTrustees == false) {newList.push(report);}})
    return newList;
  }

  // Checks if the selected reports has all of their data field fill in with valid data
  // Then enables the search button
  // False means is ready - True means it's not ready
  isReportParametersReady(){
    if(this.selectedReport != null){
      if (this.selectedReport.parameters == "None"){
        return false;
      } else {
        if (this.selectedReport.name == "Member PRF Volume" || this.selectedReport.name == "Payment Search" || this.selectedReport.name == "Payment Search - Board Report" || this.selectedReport.name == "Payment Search - Grouped by Payment" || this.selectedReport.name == "Supplier Payment Summary"){
          if(!this.checkUserType()){
            this.selectedReport.parameters.ttaNumber = Session.mySession.getUser().tradeCode;
          }
          if((this.selectedReport.parameters.startDate != "" && this.selectedReport.parameters.endDate != "") && 
             (this.selectedReport.parameters.startDate <= this.selectedReport.parameters.endDate) && this.selectedReport.parameters.supplierName != "") {
              return false;
          } else {
            return true;
          }
        } else {
          if (this.selectedReport.name == "No Commission Claims By Members" || this.selectedReport.name == "PRF Email Sent" || this.selectedReport.name == "Trustee SLA Report - All Members" || this.selectedReport.name == "Trustee SLA Report - By Date"){
            if((this.selectedReport.parameters.startDate != "" && this.selectedReport.parameters.endDate != "") && 
               (this.selectedReport.parameters.startDate <= this.selectedReport.parameters.endDate)) {
                return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    } else {
      return true;
    }
  }

  // Backend call to generate the selected report 
  // API :: getPrfReport 
  // Requires - company, tradeCode, operation, reportType, format
  // Optional - supplierName, agentTradeCode, startDate, endDate
  // Report Type - Based on the type we need different params (For more at prfController.rb)
  // Will fill into 'this.selectedReport.data'
  // Latest update includes download 'CSV' file of the Report (Save as)
  // It's also store at the ReportPath of the user from the backend
  generatePrfReport(format:String){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token,
      reportType: this.selectedReport.name,
      format: format
    }
    if (this.selectedReport.parameters != "None"){
      request.startDate = this.convertDate(this.selectedReport.parameters.startDate);
      request.endDate = this.convertDate(this.selectedReport.parameters.endDate);
      if (this.selectedReport.parameters.ttaNumber != "" && this.selectedReport.parameters.ttaNumber != null){
        request.agentTradeCode = this.selectedReport.parameters.ttaNumber;
      } else {
        if (!this.checkUserType()){
          request.agentTradeCode = this.selectedReport.parameters.ttaNumber;
        }
      }      
      request.supplierName = this.selectedReport.parameters.supplierName;
    }
    this.pageLoaded = false;
      this.prfService.getPrfReport(request).then((output: any) => {
        if (output.status == "OK") {
          this.pageLoaded = true;
          if (format == "csv"){ 
            if (output.reportName != "emptyReport") {       
              try {  
                const csvString = output.res.map((row: any) => 
                  row.map((value: any) => value === null || value === undefined || value === "null" ? '' : `"${value}"`).join(',')
                ).join('\n');
                const encoder = new TextEncoder();
                const csvBuffer = encoder.encode(csvString);
                const blob = new Blob([csvBuffer], {type: "text/csv"});
                const downloadedCsv = new File([blob], output.reportName, {type: "text/csv"});
                this.downloadReportFile(downloadedCsv, csvBuffer);                
              } catch (error) {
                this.sendMessageToDialog('', error, '', '');
              }
            } else {
              this.sendMessageToDialog('', output.res, '', '');
            }
          } else {
            this.selectedReport.table.data = output.res;
          }
        } else {
          this.pageLoaded = true;
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2020S)', error, request);
      });
  }

  // Download any PRF Report File (CSV)
  downloadReportFile(fileToDownload: any, encodedCsv: Uint8Array){
    if (fileToDownload != "" && fileToDownload != null){
      try {        
        this.pageLoaded = false;
        let data = new Blob([encodedCsv], { type: fileToDownload.type });
        saveAs(data, fileToDownload.name);
        this.pageLoaded = true;
      } catch (error) {
        this.pageLoaded = true;
        this.sendMessageToDialog('', error, '', '');
      }
    }
  }

  // Check if this column is part of the retrieved report (from selectedReport.columns)
  columnExists(givenColumn:String){
    if (this.selectedReport!= null){
      return this.selectedReport.columns.includes(givenColumn)
    } else {
      return false;
    }
  }

  // Types of sorting : String / Date / Number / Timestamp (givenType)
  // Key of sorting   : selectedReport.sortedBy.givenKey (givenKey)
  sortPrfReportBy(givenColumn:String, givenKey:String,givenType: String){
    switch (givenType) {
      case "String":
          if (this.selectedReport.sortedBy[`${givenKey}`] == false){
            this.selectedReport.sortedBy[`${givenKey}`] = true;
            let sorted = this.selectedReport.table.data.sort((a: any, b: any) => {
              let firstValue =  String(a[`${givenColumn}`]); let secondValue = String(b[`${givenColumn}`]);
              if(firstValue < secondValue){
                return 1
              } else if (firstValue > secondValue) {
                return -1
              } else {
                return 0
              }}); 
            this.selectedReport.table.data = sorted; 
          } else {
            this.selectedReport.sortedBy[`${givenKey}`] = false;
            let sorted = this.selectedReport.table.data.sort((a: any, b: any) => { 
              let firstValue = String(a[`${givenColumn}`]); let secondValue = String(b[`${givenColumn}`]);
              if(firstValue > secondValue){
                return 1
              } else if (firstValue < secondValue) {
                return -1
              } else {
                return 0
              }});  
            this.selectedReport.table.data = sorted; 
          }
        break;
      case "Date":
          if (this.selectedReport.sortedBy[`${givenKey}`] == false){ 
            this.selectedReport.sortedBy[`${givenKey}`] = true;
            let sorted = this.selectedReport.table.data.sort((a: any, b: any) => {
              let date1 = null;
              let aDate:any = null;
              if (a[`${givenColumn}`] != null){
                date1 = String(a[`${givenColumn}`]).split('-');
                aDate = new Date(parseInt(date1[0]), parseInt(date1[1]), parseInt(date1[2])).getTime()
              }
              let date2 = null;
              let bDate:any = null;
              if (b[`${givenColumn}`] != null){
                date2 = String(b[`${givenColumn}`]).split('-');
                bDate = new Date(parseInt(date2[0]), parseInt(date2[1]), parseInt(date2[2])).getTime()
              }
              if (aDate < bDate) {
                return -1;
              } else if (aDate > bDate) {
                return 1;
              } else {
                return 0;
              }
            });
            this.selectedReport.table.data = sorted; 
          } else {
            this.selectedReport.sortedBy[`${givenKey}`] = false;
            let sortedByDepart = this.selectedReport.table.data.sort((a: any, b: any) => {
              let date1 = null;
              let aDate:any = null;
              if (a[`${givenColumn}`] != null){
                date1 = String(a[`${givenColumn}`]).split('-');
                aDate = new Date(parseInt(date1[0]), parseInt(date1[1]), parseInt(date1[2])).getTime()
              }
              let date2 = null;
              let bDate:any = null;
              if (b[`${givenColumn}`] != null){
                date2 = String(b[`${givenColumn}`]).split('-');
                bDate = new Date(parseInt(date2[0]), parseInt(date2[1]), parseInt(date2[2])).getTime()
              }
              if (aDate < bDate) {
                return 1;
              } else if (aDate > bDate) {
                return -1;
              } else {
                return 0;
              }
            }); 
            this.selectedReport.table.data = sortedByDepart; 
          }   
        break;
      case "Number":
          if (this.selectedReport.sortedBy[`${givenKey}`] == false){
            this.selectedReport.sortedBy[`${givenKey}`] = true;
            let sorted = this.selectedReport.table.data.sort((a: any, b: any) => {               
              let firstValue = Number(a[`${givenColumn}`]); let secondValue = Number(b[`${givenColumn}`]);
              if(firstValue < secondValue){
                return 1
              } else if (firstValue > secondValue) {
                return -1
              } else {
                return 0
              }}); 
            this.selectedReport.table.data = sorted; 
          } else {
            this.selectedReport.sortedBy[`${givenKey}`] = false;
            let sorted = this.selectedReport.table.data.sort((a: any, b: any) => { 
              let firstValue = Number(a[`${givenColumn}`]); let secondValue = Number(b[`${givenColumn}`]);
              if(firstValue > secondValue){
                return 1
              } else if (firstValue < secondValue) {
                return -1
              } else {
                return 0
              }});  
            this.selectedReport.table.data = sorted; 
          }
        break;
      case "Timestamp":
          if (this.selectedReport.sortedBy[`${givenKey}`] == false){ 
            this.selectedReport.sortedBy[`${givenKey}`] = true;
            let sorted = this.selectedReport.table.data.sort((a: any, b: any) => {
              let date1 = null;
              let time1 = null;
              let aDate:any = null;
              if (a[`${givenColumn}`] != null){
                date1 = String(a[`${givenColumn}`].split('T')[0]).split('-');
                time1 = String(a[`${givenColumn}`].split('T')[1].split('Z').join('').split('.000').slice(0, -1)).split(':');
                aDate = new Date(parseInt(date1[0]), parseInt(date1[1]), parseInt(date1[2]), 
                                 parseInt(time1[0]), parseInt(time1[1]), parseInt(time1[2])).getTime()
              }
              let date2 = null;
              let time2 = null;
              let bDate:any = null;
              if (b[`${givenColumn}`] != null){
                date2 = String(b[`${givenColumn}`].split('T')[0]).split('-');
                time2 = String(b[`${givenColumn}`].split('T')[1].split('Z').join('').split('.000').slice(0, -1)).split(':');
                bDate = new Date(parseInt(date2[0]), parseInt(date2[1]), parseInt(date2[2]), 
                                 parseInt(time2[0]), parseInt(time2[1]), parseInt(time2[2])).getTime()
              }
              if (aDate < bDate) {
                return -1;
              } else if (aDate > bDate) {
                return 1;
              } else {
                return 0;
              }
            });
            this.selectedReport.table.data = sorted; 
          } else {
            this.selectedReport.sortedBy[`${givenKey}`] = false;
            let sortedByDepart = this.selectedReport.table.data.sort((a: any, b: any) => {
              let date1 = null;
              let time1 = null;
              let aDate:any = null;
              if (a[`${givenColumn}`] != null){
                date1 = String(a[`${givenColumn}`].split('T')[0]).split('-');
                time1 = String(a[`${givenColumn}`].split('T')[1].split('Z').join('').split('.000').slice(0, -1)).split(':');
                aDate = new Date(parseInt(date1[0]), parseInt(date1[1]), parseInt(date1[2]), 
                                 parseInt(time1[0]), parseInt(time1[1]), parseInt(time1[2])).getTime()
              }
              let date2 = null;
              let time2 = null;
              let bDate:any = null;
              if (b[`${givenColumn}`] != null){
                date2 = String(b[`${givenColumn}`].split('T')[0]).split('-');
                time2 = String(b[`${givenColumn}`].split('T')[1].split('Z').join('').split('.000').slice(0, -1)).split(':');
                bDate = new Date(parseInt(date2[0]), parseInt(date2[1]), parseInt(date2[2]), 
                                 parseInt(time2[0]), parseInt(time2[1]), parseInt(time2[2])).getTime()
              }
              if (aDate < bDate) {
                return 1;
              } else if (aDate > bDate) {
                return -1;
              } else {
                return 0;
              }
            }); 
            this.selectedReport.table.data = sortedByDepart; 
          }   
        break;
      default:
        console.log(givenType + " can't be sorted.")       
        break; 
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  // Convert date to this format --> YYYY-MM-DD (Create/Update purposes)
  pad(s: any) {
    return (s < 10) ? '0' + s : s;
  }
  convertDate(inputFormat: Date) {
    var d = new Date(inputFormat)
    return [this.pad(d.getFullYear()), this.pad(d.getMonth() + 1), d.getDate()].join('-')
  }

  // Get true or false values to boolean variables in the following cases
  getBoolean(value: any) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  // Get 0 or 1 values from boolean variables in the following cases
  getNumberFromBool(value: any) {
    switch (value) {
      case true:
        return "1";
      default:
        return "0";
    }
  }

  createUnapprovedSupplier(form: NgForm): void {
    const format = /[`!@#$%^*_+\=\[\]{}"\\|,<>?~]/; // These characters are not allowed in the fields..
    if (format.test(form.value.supplierNameM)) {
      this.sendMessageToDialog('', 'Please remove invalid characters from Supplier Name', '', ''); // Please do!
    } else if (form.valid) {
      form.value.company = Session.mySession.getUser().company
      form.value.operation = Session.mySession.getUser().operation
      form.value.tradeCode = Session.mySession.getUser().tradeCode;
      form.value.requestedName = Session.mySession.getUser().fullName;
      form.value.requestedEmail = Session.mySession.getUser().email;
      form.value.type = 'supplier';
      form.value.status = 'Pending';
      form.value.requestSource = 'PRF System'; // This will be appended in the email
      form.value.token = Session.mySession.get('user').token; // Get users details for back-end

      this.pageLoaded = false;
      this.supplierService.createSupplierRequest(form.value).then((result: any) => {
        // We don't really care if the supplier exists - it may exists, but its unapproved
        // In the meantime, we want them to be able to select 'TBC' supplier
        if (result.status === 'OK' || result.status === 'Supplier already exists') {
          this.sendMessageToDialog('Request has been sent!', '', '', ''); // And then show the message to the user
          this.createSupplierName = 'TBC';
          this.suppliersList.unshift({ supplierNameM: 'TBC' });
          this.newSupplierDiv = false; // Close the new supplier div box at the end
        } else {
          this.sendMessageToDialog('', result.status, '', ''); // Show error message to the user
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1101S)', error, form.value);
      });
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp() {
    this.dialog.open(this.helpDialog);
  }

  // Summary Report - Create PRF
  // showCreateReport(){
  //  this.dialog.open(this.createReportDialog);
  // }

  // Summary Report - View PRF
  // showViewReport(){
  //  this.dialog.open(this.viewReportDialog);
  // }

  // Suggested Booking REF - TBD
  getSuggestedBookingReferences(){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token,
    }
    this.prfService.getSuggestNonTAPStoPay(request).then((output: any) => {
      if (output.status == "OK"){
        this.createToogleSuggestedBookings = true
        let tempListOfSuggestedBookings:any = []
        output["data"].forEach((tempSuggested:any)=>{
          tempSuggested.selected = false;
          tempListOfSuggestedBookings.push(tempSuggested)
        })
        this.createListOfSuggestedBookings = tempListOfSuggestedBookings
      } else {
        this.createToogleSuggestedBookings = false
        this.createListOfSuggestedBookings = []
      }
    }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2020S)', error, request);
    });
  }

  // Function to toggle suggestion selection
  toggleSuggestedBooking(suggestion: any): void {
    suggestion.selected = !suggestion.selected;
    if (suggestion.selected == true){
      // Add to list
      if (this.createSelectedSuggestions.length == 0){
        this.createSelectedSuggestions = [suggestion.bookingReference]
      } else {
        let tempListOfSelectedSuggestions:any = this.createSelectedSuggestions;
        let tempCheckIfExist = false;
        let numberOfBookings = tempListOfSelectedSuggestions.length;
        for (let counter=0; counter < numberOfBookings; counter++){
          if (this.createSelectedSuggestions[counter] == suggestion.bookingReference){
            tempCheckIfExist = true
          }
        }
        if (tempCheckIfExist == false){
          tempListOfSelectedSuggestions.push(suggestion.bookingReference)
        }
        this.createSelectedSuggestions = tempListOfSelectedSuggestions;
      }
    } else {
      // Remove from list
      if (this.createSelectedSuggestions.length > 0){
          let tempListOfSelectedSuggestions:any = this.createSelectedSuggestions;
          let tempCheckIfExist = false;
          let indexIfFound = 0;
          let numberOfBookings = tempListOfSelectedSuggestions.length;
          for (let counter=0; counter < numberOfBookings; counter++){
            if (this.createSelectedSuggestions[counter] == suggestion.bookingReference){
              tempCheckIfExist = true;
              indexIfFound = counter;
            }
          }
          if (tempCheckIfExist == true){
            tempListOfSelectedSuggestions.splice(indexIfFound, 1);
          }
          this.createSelectedSuggestions = tempListOfSelectedSuggestions
      }
    }
  }

  openSuggestedBookingDialog(suggestion: any): void {
    this.createSelectedSuggestedBooking = suggestion;
    this.dialog.open(this.suggestedBookingDialog);
  }

  checkIfNatWestChapsIsChecked(type:String){
    if (type == "Create") {
      if (this.createClaimClientRefund.paymentMethod == "Natwest CHAPS" || 
          this.createClaimReimbursement.paymentMethod == "Natwest CHAPS" ||
          this.createClaimCommission.paymentMethod == "Natwest CHAPS" || 
          this.createClaimSupplierPayment.paymentMethods.checks[1] == true){
            return true;
      } else {
        return false;
      }
    } else {
      if (this.viewClaimClientRefund.paymentMethod == "Natwest CHAPS" || 
          this.viewClaimReimbursement.paymentMethod == "Natwest CHAPS" ||
          this.viewClaimCommission.paymentMethod == "Natwest CHAPS" || 
          this.viewClaimSupplierPayment.paymentMethods.checks[1] == true){
            return true;
      } else {
        return false;
      }
    }
  }

  reloadReceiptsList(type:String, passedBookingReference:any){

    // CREATE SINGLE BOOKING PRF
    if (type == "Create Single") { 
      this.receiptData.data = [];
      this.pageLoaded = false;
      const request = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          bookingReference: this.createMatchedBookingReference,
          token: Session.mySession.get('user').token
      };
        this.receiptService.getReceiptList(request).then((output: any) => {
          if (output["status"] == "OK") {
            this.pageLoaded = true; 
            if (output["receiptList"].length > 0){
              this.receiptData.data = output["receiptList"];
              this.receiptData.data.forEach((receipt: any) => {
                const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
                let value = '£0'; // Begin with £0 value and format positive/non-positive value below
                if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
                // Assign free-text value visible in UI below..
                receipt.freeText = 'Date: ' + receiptDate + '\n' +
                  'Method: ' + receipt.paymentMethod + '\n' +
                  'Value: ' + value;
              });
            }  
          } else {
            this.pageLoaded = true; 
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', output["status"], request);
          }
        }).catch((error: any) => {
          this.pageLoaded = true; 
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', error, request);
        });
    } 

    // CREATE MULTIPLE BOOKING REF
    if (type == "Create Multiple"){
      this.pageLoaded = false;
      const request = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          bookingReference: passedBookingReference,
          token: Session.mySession.get('user').token
      };
      this.receiptService.getReceiptList(request).then((output: any) => {
          if (output["status"] == "OK") {
            this.pageLoaded = true; 
            if (output["receiptList"].length > 0){
              this.createTransactionValuesList.forEach((tempTransaction:any)=>{
                if (tempTransaction.createBookingReference == passedBookingReference){
                  tempTransaction.createReceiptData.data = output["receiptList"]
                  tempTransaction.createReceiptData.data.forEach((receipt: any) => {
                    const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
                    let value = '£0'; // Begin with £0 value and format positive/non-positive value below
                    if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
                    // Assign free-text value visible in UI below..
                    receipt.freeText = 'Date: ' + receiptDate + '\n' +
                      'Method: ' + receipt.paymentMethod + '\n' +
                      'Value: ' + value;
                  });
                } 
              })
            }  
          } else {
            this.pageLoaded = true; 
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', output["status"], request);
          }
      }).catch((error: any) => {
          this.pageLoaded = true; 
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', error, request);
      });
    }

    // VIEW SINGLE BOOKING PRF
    if (type == "View Single"){
      this.receiptData.data = [];
      this.pageLoaded = false;
      let request = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        bookingReference: this.viewMatchedBookingReference,
        token: Session.mySession.get('user').token
      };
      if (Session.mySession.getUser().tradeCode != this.viewPrfTradeCode){
        request = {
          company: this.viewPrfCompany,
          tradeCode: this.viewPrfTradeCode,
          operation: this.viewPrfOperation,
          bookingReference: this.viewMatchedBookingReference,
          token: Session.mySession.get('user').token
        };
      }
        this.receiptService.getReceiptList(request).then((output: any) => {
          if (output["status"] == "OK") {
            this.pageLoaded = true; 
            if (output["receiptList"].length > 0){
              this.receiptData.data = output["receiptList"];
              this.receiptData.data.forEach((receipt: any) => {
                const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
                let value = '£0'; // Begin with £0 value and format positive/non-positive value below
                if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
                // Assign free-text value visible in UI below..
                receipt.freeText = 'Date: ' + receiptDate + '\n' +
                  'Method: ' + receipt.paymentMethod + '\n' +
                  'Value: ' + value;
              });
            }  
          } else {
            this.pageLoaded = true; 
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', output["status"], request);
          }
        }).catch((error: any) => {
          this.pageLoaded = true; 
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', error, request);
        });
    }

    // VIEW MULTIPLE BOOKING PRF
    if (type == "View Multiple"){
      this.pageLoaded = false;
      let request = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        bookingReference: passedBookingReference,
        token: Session.mySession.get('user').token
      };
      if (Session.mySession.getUser().tradeCode != this.viewPrfTradeCode){
        request = {
          company: this.viewPrfCompany,
          tradeCode: this.viewPrfTradeCode,
          operation: this.viewPrfOperation,
          bookingReference: passedBookingReference,
          token: Session.mySession.get('user').token
        };
      }
      this.receiptService.getReceiptList(request).then((output: any) => {
          if (output["status"] == "OK") {
            this.pageLoaded = true; 
            if (output["receiptList"].length > 0){
              this.viewPrfTransactionValuesList.forEach((tempTransaction:any)=>{
                if (tempTransaction.createBookingReference == passedBookingReference){
                  tempTransaction.createReceiptData.data = output["receiptList"]
                  tempTransaction.createReceiptData.data.forEach((receipt: any) => {
                    const receiptDate = this.constants.convertDateDdMmYyyy(receipt.receiptDate); // Convert date to dd.mm.yyyy
                    let value = '£0'; // Begin with £0 value and format positive/non-positive value below
                    if (receipt.creditValue < 0) { value = '-£' + (receipt.creditValue * -1); } else { value = '£' + receipt.creditValue; }
                    // Assign free-text value visible in UI below..
                    receipt.freeText = 'Date: ' + receiptDate + '\n' +
                      'Method: ' + receipt.paymentMethod + '\n' +
                      'Value: ' + value;
                  });
                } 
              })
            }  
          } else {
            this.pageLoaded = true; 
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', output["status"], request);
          }
      }).catch((error: any) => {
          this.pageLoaded = true; 
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', error, request);
      });
      }
  }

  openPopUpPrfDialog(): void {
    this.dialog.open(this.popUpPrfDialog);
  }

  setViewPrfPopUpData(passedPrf: any) {
    this.clearViewedPrf();
    this.viewPrfBranchName = passedPrf[0]["branchName"];   
    this.viewPrfNumber = passedPrf[0]["prfNumber"];   
    this.viewPrfAssignedTo = passedPrf[0]["assignedTo"];
    this.viewPrfDateSubmitted = passedPrf[0]["dateSubmitted"];
    this.viewPrfSupplierName = passedPrf[0]["supplierName"];
    this.viewPrfPassengerName = passedPrf[0]["passengerName"];
    this.viewPrfStatus = passedPrf[0]["status"];
    this.viewPrfAmountOne = passedPrf[0]["amountOne"];
    this.viewPrfCurrencyOne = passedPrf[0]["currencyOne"];
    this.viewPrfClaimOne = passedPrf[0]["claimOne"];
    this.viewPrfPaymentMethodOne = passedPrf[0]["paymentMethodOne"];
    this.viewPrfAmountTwo = passedPrf[0]["amountTwo"];
    this.viewPrfCurrencyTwo = passedPrf[0]["currencyTwo"];
    this.viewPrfClaimTwo = passedPrf[0]["claimTwo"];
    this.viewPrfPaymentMethodTwo = passedPrf[0]["paymentMethodTwo"];
    this.viewPrfActionRequiredMessage = passedPrf[0]["actionRequiredMessage"];
    this.viewPrfIsClientFundsReceived = passedPrf[0]["isClientFundsReceived"] ? 1 : 0;
    this.viewPrfHasClientReturned = passedPrf[0]["hasClientReturned"] ? 1 : 0;
    this.viewPrfNotesToTrustee = passedPrf[0]["notesToTrustee"];
    this.viewPrfCollectedFrom = passedPrf[0]["collectedFrom"];
    this.viewPrfCompany = passedPrf[0]["company"].toUpperCase();
    this.viewPrfOperation = passedPrf[0]["operation"];
    this.viewPrfTradeCode = passedPrf[0]["tradeCode"];
    this.viewPrfCreator = passedPrf[0]["prfCreator"];
    this.viewPrfCreatorEmail = passedPrf[0]["prfCreatorEmail"];
    this.viewPrfBookingReference = passedPrf[0]["bookingReference"];
    if (passedPrf[0]["financialProtection"] != null && passedPrf[0]["financialProtection"] != ""){
      const givenFP = passedPrf[0]["financialProtection"];
      this.viewPrfFinancialProtection.methods.forEach((method,index)=>{
        if (givenFP.indexOf(method) !== -1) {
          this.viewPrfFinancialProtectionText = this.viewPrfFinancialProtection.methods[index]
          this.viewPrfFinancialProtection.checks[index] = true
        } 
      })
    }

    if (this.viewPrfBookingReference != null){
      if (this.viewPrfBookingReference == "Multiple"){
        this.viewMultipleBookingReference = true;
        this.reloadS3Multipledocuments()
      } else {
        this.viewMultipleBookingReference = false;
      }
    }

    // Financial Protection Check
    if (this.viewPrfHasClientReturned == 0) {
      this.viewPrfFinancialProtectionEnable = true;
    }

    // None Supplier Check
    if (this.viewPrfClaimOne == "Commission" && this.viewPrfClaimTwo == null){
      this.viewPrfSupplierNoneIsAvailable = true;
    } else {
      this.viewPrfSupplierNoneIsAvailable = false;
    }

    // Supplier Payment Checks
    if (this.viewPrfClaimOne == "Supplier Payment"){
      this.viewNotMatchingSuppliers = false;
      this.viewClaimSupplierPayment.checked = true;
      let tempPaymentMethods = String(this.viewPrfPaymentMethodOne);
      if (tempPaymentMethods.includes("/")){
        tempPaymentMethods.split("/").forEach((method)=>{
          if (method == "Natwest Faster")
          {
            this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
          } else if(method == "Natwest CHAPS") {
            this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
          } else if(method == "Barclays") {
            this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
          } else if(method == "Corpay") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Monteith") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Amex Forex Transfer") {
            this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
          } else if(method == "Other") {
            this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
          }
        });
      } else {
        if (tempPaymentMethods == "Natwest Faster")
        {
          this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
        } else if(tempPaymentMethods == "Natwest CHAPS") {
          this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
        } else if(tempPaymentMethods == "Barclays") {
          this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
        } else if(tempPaymentMethods == "Corpay") {
          this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
        } else if(tempPaymentMethods == "Monteith") {
          this.viewClaimSupplierPayment.paymentMethods.checks[4] = true;
        } else if(tempPaymentMethods == "Amex Forex Transfer") {
          this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
        } else if(tempPaymentMethods == "Other") {
          this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
        }
      }      
    }
    if (this.viewPrfClaimTwo == "Supplier Payment"){
      this.viewNotMatchingSuppliers = false;
      this.viewClaimSupplierPayment.checked = true;
      let tempPaymentMethods = String(this.viewPrfPaymentMethodTwo);
      if (tempPaymentMethods.includes("/")){
        tempPaymentMethods.split("/").forEach((method)=>{
          if (method == "Natwest Faster")
          {
            this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
          } else if(method == "Natwest CHAPS") {
            this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
          } else if(method == "Barclays") {
            this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
          } else if(method == "Corpay") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Monteith") {
            this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
          } else if(method == "Amex Forex Transfer") {
            this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
          } else if(method == "Other") {
            this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
          }
        });
      } else {
        if (tempPaymentMethods == "Natwest Faster")
        {
          this.viewClaimSupplierPayment.paymentMethods.checks[0] = true;
        } else if(tempPaymentMethods == "Natwest CHAPS") {
          this.viewClaimSupplierPayment.paymentMethods.checks[1] = true;
        } else if(tempPaymentMethods == "Barclays") {
          this.viewClaimSupplierPayment.paymentMethods.checks[2] = true;
        } else if(tempPaymentMethods == "Corpay") {
          this.viewClaimSupplierPayment.paymentMethods.checks[3] = true;
        } else if(tempPaymentMethods == "Monteith") {
          this.viewClaimSupplierPayment.paymentMethods.checks[4] = true;
        } else if(tempPaymentMethods == "Amex Forex Transfer") {
          this.viewClaimSupplierPayment.paymentMethods.checks[5] = true;
        } else if(tempPaymentMethods == "Other") {
          this.viewClaimSupplierPayment.paymentMethods.checks[6] = true;
        }
      }      
    }

    // Commission Checks
    if (this.viewPrfClaimOne == "Commission"){
      this.viewClaimCommission.checked = true;
      this.viewClaimCommission.paymentMethod = String(this.viewPrfPaymentMethodOne);
    }
    if (this.viewPrfClaimTwo == "Commission"){
      this.viewClaimCommission.checked = true;
      this.viewClaimCommission.paymentMethod = String(this.viewPrfPaymentMethodTwo);
    }

    // Reimbursement Checks
    if (this.viewPrfClaimOne == "Reimbursement"){
      this.viewClaimReimbursement.checked = true;
      this.viewClaimReimbursement.paymentMethod = String(this.viewPrfPaymentMethodOne);
    }
    if (this.viewPrfClaimTwo == "Reimbursement"){
      this.viewClaimReimbursement.checked = true;
      this.viewClaimReimbursement.paymentMethod = String(this.viewPrfPaymentMethodTwo);
    }

    // Client Refund Checks
    if (this.viewPrfClaimOne == "Client Refund"){
      this.viewClaimClientRefund.checked = true;
      this.viewClaimClientRefund.paymentMethod = String(this.viewPrfPaymentMethodOne);
    }
    if (this.viewPrfClaimTwo == "Client Refund"){
      this.viewClaimClientRefund.checked = true;
      this.viewClaimClientRefund.paymentMethod = String(this.viewPrfPaymentMethodTwo);
    }

    // Client Return Date Check
    if (this.viewPrfFinancialProtection.checks[0] == true && this.viewClaimCommission.checked == false && this.viewClaimSupplierPayment.checked == false && this.viewClaimReimbursement.checked == false  && this.viewClaimClientRefund.checked == true) {
      this.viewPrfClientReturnDateEnable = false;
    } else {
      if (this.viewPrfFinancialProtectionEnable == true && this.viewPrfFinancialProtection.checks[0] == true){
        this.viewPrfClientReturnDateEnable = true;
        if (passedPrf[0]["clientReturnDate"] != null) {
          this.viewPrfClientReturnDate = new Date(passedPrf[0]["clientReturnDate"]);
        } else {
          this.viewPrfClientReturnDate = new Date();
        }
      }
    }

    // Setup PRF's Transaction Values & Booking Reference (If not null)
    let transactionsRequest:any = {
      company: Session.mySession.getUser().company, 
      operation: Session.mySession.getUser().operation, 
      tradeCode: Session.mySession.getUser().tradeCode,  
      token: Session.mySession.get('user').token,
      prfNumber: this.viewPrfNumber
    }
    this.reloadPrfsTransactionValues(transactionsRequest,this.viewPrfBookingReference,true);

    // Setup PRF's Audit
    if (this.checkUserType()){
      let auditRequest:any = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        token: Session.mySession.get('user').token,
        prfNumber: this.viewPrfNumber
      }
      this.reloadPrfsAuditList(auditRequest);
    }
    
    // Setup PRF's Supporting Documentation
    this.reloadS3prfDocs(true);
    
  }


  matchUploadedDocumentWithTransactionValues(givenFile: any) {
  
      const file = givenFile.target.files[0]; 
      if (!file) {
        this.sendMessageToDialog('', 'Please select a file to upload', "No file selected.", file);
        return;
      }

      if (file.size > 31457280) {
        this.sendMessageToDialog('', 'This file is too big. Maximum file upload is 30MB', '', '');
        return;
      }
      
      this.uploadCreatePrfDocument(givenFile, "schedule");

      this.pageLoaded = false;
      const reader = new FileReader();

      // Helper function to check if the value is a valid Excel serial date
      const isExcelDate = (value: any) => !isNaN(value) && parseInt(value) > 30000 && parseInt(value) < 60000;

      // Convert Excel serial date to a JS Date object
      const excelSerialToDate = (serial: number) => {
        const startDate = new Date(1900, 0, 1); // January 1, 1900
        return new Date(startDate.getTime() + (serial - 1) * 24 * 60 * 60 * 1000);
      };

      let startFromZeroPoint = false;
      let addedTransactions = 0;

      if (this.createTransactionValuesList.length == 1){
        if (this.createTransactionValuesList[0].createClientSurname == "" && 
          this.createTransactionValuesList[0].createDateFundsBanked == null && 
          this.createTransactionValuesList[0].createPaymentMethod == "" && 
          this.createTransactionValuesList[0].createGrossClientFunds.amount == null && 
          this.createTransactionValuesList[0].createGrossClientFunds.currency == "" && 
          this.createTransactionValuesList[0].createSupplierAmount.amount == null && 
          this.createTransactionValuesList[0].createSupplierAmount.currency == "") {
            startFromZeroPoint = true;
        }
      }
      
      // Read the file as a binary string
      reader.onload = (e: any) => {

        try { 

          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          
          // Assuming that the first sheet contains the data
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          
          // Convert the sheet to JSON to make it easier to process
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          
          // Iterate through the data rows (jsonData)
          jsonData.forEach((row: any, rowIndex: number) => {
            if (rowIndex === 0) {
              return;
            }

            // Check to skip the last row where the overall amount is listed
            const nonEmptyCells = row.filter((cell:any) => cell !== null && cell !== "");
            const numericValues = nonEmptyCells.filter((cell:any) => !isNaN(parseFloat(cell)));
            if (numericValues.length === nonEmptyCells.length && nonEmptyCells.length <= 2) {
              return;  
            }
          
            let claimAmount = null;
            let invoiceDate:any = null;
            let dueDate:any = null;
            let leadPaxName = "";

            row.forEach((cellValue: any, cellIndex: number) => {

              // Attempt to identify the claim amount (number formatted as digits)
              if (typeof cellValue === 'string' || typeof cellValue === 'number') {
                const formattedValue = cellValue.toString().replace(/[^0-9,.]/g, ''); 
                if (!isNaN(parseFloat(formattedValue))) {
                  claimAmount = parseFloat(formattedValue).toFixed(2); 
                }
              }
            
              // Capture Lead Pax Name for surname extraction
              if (typeof cellValue === 'string') {
                const cleanedValue = cellValue.replace(/[^\w\s]/g, '').trim();
                if (/^[a-zA-Z\s]+$/.test(cleanedValue)) {
                    leadPaxName = cleanedValue;  
                }
              }

              const possibleDate = moment(cellValue, moment.ISO_8601, true);
              if (possibleDate.isValid()) {
                if (!invoiceDate) {
                  invoiceDate = possibleDate.toDate();  // Assign as Invoice Date
                } else if (!dueDate) {
                  dueDate = possibleDate.toDate();      // Assign as Due Date
                }
              }

              // Use moment.js to check for date-like strings or check for Excel serial dates
              if (moment(cellValue, moment.ISO_8601, true).isValid()) {
                const possibleDate = moment(cellValue, moment.ISO_8601, true);
                if (!invoiceDate) {
                  invoiceDate = possibleDate.toDate();  // Assign as Invoice Date
                } else if (!dueDate) {
                  dueDate = possibleDate.toDate();      // Assign as Due Date
                }
              } else if (isExcelDate(cellValue)) {
                const dateFromSerial = excelSerialToDate(parseInt(cellValue));
                if (!invoiceDate) {
                  invoiceDate = dateFromSerial;  // Assign as Invoice Date
                } else if (!dueDate) {
                  dueDate = dateFromSerial;      // Assign as Due Date
                }
              }

            });
          
            // Extract the surname from Lead Pax Name
            const nameParts = leadPaxName.split(' ');
            const surname = nameParts.length > 1 ? nameParts.slice(-1)[0] : leadPaxName;
          
            // TBD - Currently we keep it empty but if we will setup the invoice or due date here is the code!
            // Assign Invoice Date if available, else Due Date, otherwise skip
            // const dateFundsBanked = invoiceDate || dueDate || null;
            const dateFundsBanked = null;

            // If we found a claim amount, add it to the transaction values list
            if (claimAmount) {
              let newCreateTransactionValue = {
                createBookingReference: "",   
                createClientSurname: surname,   
                createDateFundsBanked: dateFundsBanked,   
                createPaymentMethod: "Card",      
                createGrossClientFunds: { currency: "GBP", amount: claimAmount },   
                createGuaranteeDeducted: { currency: "GBP", amount: null },  
                createSupplierAmount: { currency: "GBP", amount: claimAmount },   
                createCommission: { currency: "GBP", amount: null },        
                createClientRefund: { currency: "GBP", amount: null },      
                createReimbursement: { currency: "GBP", amount: null },     
                createListOfSurnames: [], 
                createReceiptData: new MatTableDataSource<any>(), 
                createShowReceipt: false,
                createIsNotComplete: true
              };
              if (startFromZeroPoint == true){
                startFromZeroPoint = false;
                this.createTransactionValuesList[0] = newCreateTransactionValue
                addedTransactions += 1;
              } else {
                this.createTransactionValuesList.push(newCreateTransactionValue);
                addedTransactions += 1;
              }
            }
        });
        this.pageLoaded = true;

        this.checkTransactionValues(true);

        this.sendMessageToDialog('The Schedule file was successfully uploaded. Please review the (' + addedTransactions + ') new records.', '', '', '');

        } catch (error:any) {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'Error processing the file', error.message, file);
        }
    };
    
    // Read the file
    reader.readAsArrayBuffer(file);
    
  }

  setGBPCurrencyToAllRecords(){
    if (confirm("Are you sure you want to set all the records currency to GBP (£)?")){
      if (this.createTransactionValuesList.length > 0){
        this.createTransactionValuesList.forEach((tempRecord:any)=> {
          tempRecord.createGrossClientFunds.currency = "GBP";
          tempRecord.createGuaranteeDeducted.currency = "GBP";
          tempRecord.createSupplierAmount.currency = "GBP";
          tempRecord.createCommission.currency = "GBP";
          tempRecord.createClientRefund.currency = "GBP";
          tempRecord.createReimbursement.currency = "GBP";
        })
        this.checkTransactionValues(false);
      } 
      if (this.viewPrfTransactionValuesList.length > 0){
        this.viewPrfTransactionValuesList.forEach((tempRecord:any)=> {
          tempRecord.viewGrossClientFunds.currency = "GBP";
          tempRecord.viewGuaranteeDeducted.currency = "GBP";
          tempRecord.viewSupplierAmount.currency = "GBP";
          tempRecord.viewCommission.currency = "GBP";
          tempRecord.viewClientRefund.currency = "GBP";
          tempRecord.viewReimbursement.currency = "GBP";
        })
        this.checkViewTransactionValues(false,false);
      }
      if (this.viewPrfAddedTransactionValuesList.length > 0){
        this.viewPrfAddedTransactionValuesList.forEach((tempRecord:any)=> {
          tempRecord.viewGrossClientFunds.currency = "GBP";
          tempRecord.viewGuaranteeDeducted.currency = "GBP";
          tempRecord.viewSupplierAmount.currency = "GBP";
          tempRecord.viewCommission.currency = "GBP";
          tempRecord.viewClientRefund.currency = "GBP";
          tempRecord.viewReimbursement.currency = "GBP";
        })
        this.checkViewTransactionValues(false,false);
      }
    }
  }

  checkIfTestingMember():void{
    if (Session.mySession.getBranch().isSmartPrf == "no") {
      this.isATestingMember = false;
    } else {
      if (this.testingMembersForOneTouchPRF.includes(Session.mySession.getUser().tradeCode)){
        this.isATestingMember = true;
      } else {
        this.isATestingMember = false;
      }
    }
  }

  checkIfOneToutchBooking(){
    if(this.createIsOneTouchBooking == 1){
      this.createBookingReference = null;
      this.createMatchedBookingReference = null;
    } else {
      this.createBookingReference = "";
      this.createMatchedBookingReference = "";
      if (this.listOfAvailableBookings.length == 0){
        this.listAvailableBookingReferences();
      }
    }
    this.checkOnlinePrfInformation();
  }

  downloadBookingCertificateForTrustees(givenBookingReference:any, givenSupplementCost:any): void {
    if (givenBookingReference == null){
      // SINGLE BOOKING PRF
      let supplementCount:any = null
      this.viewListOfElementData.forEach((tempElement:any)=>{
        if (tempElement.archive == 'no' && tempElement.elementType == 'supplement' && tempElement.elementStatus == 'booking' && tempElement.supplierName == "SFC"){
          supplementCount = tempElement.supplements[0].supplementCount;
        }
      });
      // We need a request variable which will contain all necessary req. info
      const request = {
        company: this.viewPrfCompany.toLowerCase(), 
        operation: this.viewPrfOperation,
        tradeCode: this.viewPrfTradeCode,
        bookingReference: this.viewPrfBookingReference, 
        actionedBy: Session.mySession.getUser().fullName,
        supplementCount: supplementCount,
        type: 'sfc', 
        token: Session.mySession.get('user').token
      };
      this.pageLoaded = false;
      this.reportsService.generateSAG(request).then((output: any) => {
        if (output.status === 'OK') {
          try {
            window.open(output.presignedUrl, '_blank'); this.pageLoaded = true;
          } catch (error) {
            this.sendMessageToDialog('', error, '', ''); 
          }
        } else {
          this.sendMessageToDialog('', output.status, '', ''); 
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2121S)', error, request);
      });
    } else {
      // MULTIPLE BOOKING PRF - TBD
      const request = {
        company: this.viewPrfCompany.toLowerCase(), 
        operation: this.viewPrfOperation,
        tradeCode: this.viewPrfTradeCode,
        bookingReference: givenBookingReference, 
        actionedBy: Session.mySession.getUser().fullName,
        supplementCount: givenSupplementCost,
        type: 'sfc', 
        token: Session.mySession.get('user').token
      };
      this.pageLoaded = false;
      this.reportsService.generateSAG(request).then((output: any) => {
        if (output.status === 'OK') {
          try {
            window.open(output.presignedUrl, '_blank'); this.pageLoaded = true;
          } catch (error) {
            this.sendMessageToDialog('', error, '', ''); 
          }
        } else {
          this.sendMessageToDialog('', output.status, '', ''); 
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2121S)', error, request);
      });
    }  
  }

}
