<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('supplierList')" mat-button [disabled]="suppListDiv">
          <mat-icon>list</mat-icon> Supplier List
        </button>
        <button class="navButtons" (click)="switchView('supplierRequests')" mat-button [disabled]="suppReqDiv" *ngIf="['sinGSAdmin', 'wcManager', 'memberManager'].includes(userType)">
          <mat-icon>quiz</mat-icon> Supplier Requests
        </button>
        <button class="navButtons" (click)="switchView('sfcList')" mat-button [disabled]="sfcListDiv" *ngIf="['sinGSAdmin', 'wcManager', 'memberManager'].includes(userType)">
          <mat-icon style="margin-top: -2px;">security</mat-icon> SFC Requests
        </button>
        <button class="navButtons" (click)="switchView('createSupplier')" mat-button [disabled]="createSuppDiv" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
          <mat-icon>add</mat-icon> Create Supplier
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" mat-button (click)="showHelp()" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('supplierList')" mat-button [disabled]="suppListDiv">
          <mat-icon>list</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('supplierRequests')" mat-button [disabled]="suppReqDiv" *ngIf="['sinGSAdmin', 'wcManager', 'memberManager'].includes(userType)">
          <mat-icon>quiz</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('sfcList')" mat-button [disabled]="sfcListDiv" *ngIf="['sinGSAdmin', 'wcManager', 'memberManager'].includes(userType)">
          <mat-icon>security</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('createSupplier')" mat-button [disabled]="createSuppDiv" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
          <mat-icon>add</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" mat-button (click)="showHelp()" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] *ngIf="haveAccess" class="sideNavContent">

          <div [@inAnimation] *ngIf="suppListDiv">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Supplier List
            </h1>
            <mat-divider></mat-divider>

            <div style="display: flex;">
              <div style="display: flex;">
                <div>
                  <mat-form-field class="filterInput">
                    <input matInput type="text" (keyup)="doFilter($event, supplierData)" id="filterSuppliers" placeholder="Filter Suppliers">
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field *ngIf="branchData.length > 1" class="branchEdit">
                <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentTradeCode" placeholder="Branch" (selectionChange)="changeGroupBranch($event.value)"
                            (click)="filterBranch = ''; filterSelect('branch');">
                  <div class="sticky-search-container" style="margin-bottom: unset !important;">
                    <mat-form-field appearance="fill" class="filterSelectList">
                      <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                    </mat-form-field>

                    <div style="text-align: left; display: flex; justify-content: center;" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
                      <button mat-button (click)="filterEOD()" class="sticky-button-search">
                        <span *ngIf="!branchShowEOD">Include EOD Members</span>
                        <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                      </button>
                    </div>
                  </div>

                  <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                    <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div style="display: flex; align-items: center;" *ngIf="!['sinGSAdmin', 'tapsAdmin'].includes(userType) && memberLive">
                <button class="butt onToRight" color="primary" matTooltip="Request New Supplier" matTooltipClass="line-normal-tooltip"
                        mat-stroked-button (click)="openRequestDialog('supplier')">
                        <mat-icon>add</mat-icon>
                </button>
              </div>

              <div class="checkboxContainer checkboxContainerTop" style="margin-left: 25px;" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
                <div class="checkboxElement">
                  <label style="display: flex;">
                    <input type="checkbox" (change)="filterUnapproved = false; checkboxFilter($event, 'hidden')" [(ngModel)]="filterHidden">
                    <span>Hidden</span>
                  </label>
                </div>
              </div>

              <div class="paginatorFooter">
                <div *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
                  <button class="buttonToRight" color="primary" style="margin-right: 20px; margin-bottom: -7.5px;"
                          mat-icon-button (click)="exportToExcel('suppliers')">
                        <mat-icon>file_download</mat-icon>
                  </button>
                </div>
                <div>
                  <mat-paginator #paginatorSupp [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
            </div>

            <mat-table multiTemplateDataRows [dataSource]="supplierData" style="clear: both;" *ngIf="['supplier', 'tapsAdmin'].includes(userType)">
              <ng-container matColumnDef="supplierName">
                <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                <mat-cell *matCellDef="let supplier"> {{ supplier[0].supplierNameM }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="emailAddress">
                <mat-header-cell *matHeaderCellDef> Email Commercial </mat-header-cell>
                <mat-cell *matCellDef="let supplier"> {{ supplier[0].email }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="emailAddressTAPS">
                <mat-header-cell *matHeaderCellDef> Email TAPS </mat-header-cell>
                <mat-cell *matCellDef="let supplier"> {{ supplier[0].emailTAPS }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="addressDetails">
                <mat-header-cell *matHeaderCellDef> Address Details </mat-header-cell>
                <mat-cell *matCellDef="let supplier">
                  <div>
                    <span *ngIf="supplier[0]?.addressLine1">{{ supplier[0].addressLine1 }}, </span>
                    <span *ngIf="supplier[0]?.addressLine2">{{ supplier[0].addressLine2 }}, </span>
                    <span *ngIf="supplier[0]?.addressLine3">{{ supplier[0].addressLine3 }}, </span>
                    <span *ngIf="supplier[0]?.addressLine4">{{ supplier[0].addressLine4 }}, </span>
                    <span *ngIf="supplier[0]?.postCode">{{ supplier[0].postCode }}, </span>
                    <span *ngIf="supplier[0]?.country">{{ supplier[0].country }}</span>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expand">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let supplier">
                  <mat-icon>expand_more</mat-icon>
                </mat-cell>
              </ng-container>

              <!-- Expansion text -->
              <ng-container matColumnDef="expandedDetail">
                <mat-cell class="expandedDetail" *matCellDef="let supplier" [attr.colspan]="5" style="padding: 0px;">
                  <div style="width: 100%;">
                    <div style="padding-left: 24px; padding-right: 24px;">
                      <form [@inOutAnimation] *ngIf="supplier == expandedElement" ngNativeValidate #editSupplierForm="ngForm" (ngSubmit)="editSupplier(editSupplierForm, supplier)">
                        <h3 class="narrowH3" style="margin-top: 16px;">Address Details</h3>
                        <mat-form-field class="addressLine1Edit">
                          <input matInput ngModel placeholder="Address Line 1" maxlength="50" name="addressLine1" #addressLine1="ngModel" [(ngModel)]="supplier[0].addressLine1">
                        </mat-form-field>

                        <mat-form-field class="addressLine2Edit">
                          <input matInput ngModel placeholder="Address Line 2" maxlength="50" name="addressLine2" #addressLine2="ngModel" [(ngModel)]="supplier[0].addressLine2">
                        </mat-form-field>

                        <mat-form-field class="addressLine3Edit">
                          <input matInput ngModel placeholder="Address Line 3" maxlength="50" name="addressLine3" #addressLine3="ngModel" [(ngModel)]="supplier[0].addressLine3">
                        </mat-form-field>

                        <mat-form-field class="addressLine4Edit">
                          <input matInput ngModel placeholder="Address Line 4" maxlength="50" name="addressLine4" #addressLine4="ngModel" [(ngModel)]="supplier[0].addressLine4">
                        </mat-form-field>

                        <br>

                        <mat-form-field class="postCodeEdit">
                          <input matInput ngModel placeholder="Post Code" maxlength="10" name="postCode" #postCode="ngModel" [(ngModel)]="supplier[0].postCode">
                        </mat-form-field>

                        <mat-form-field class="countryEdit">
                          <mat-select ngModel [(ngModel)]="supplier[0].country" name="country" #country="ngModel" placeholder="Country">
                            <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                              <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterCountry" (keyup)="filterSelect('country')">
                            </mat-form-field>
                            <mat-option *ngFor="let country of countryFiltered" [value]="country" (click)="filterCountry = ''; filterSelect('country');">
                              {{country}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput ngModel placeholder="Website" maxlength="100" name="website" #website="ngModel" [(ngModel)]="supplier[0].website">
                        </mat-form-field>

                        <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);" *ngIf="userType == 'supplier'">
                          <div class="checkboxElement">
                            <label style="display: flex;">
                              <h3>Contact Details</h3>
                              <button mat-button color="primary" type="button" (click)="openContactDetails()"
                              style="margin-left: 42.5px;">
                                <mat-icon style="margin: auto;">open_in_new</mat-icon>
                              </button>
                            </label>
                          </div>
                        </div>

                        <div *ngIf="['tapsAdmin'].includes(userType)" class="financialDiv">
                          
                          <h3 class="narrowH3">Financial Details</h3>
                          <mat-form-field class="abtaEdit">
                            <input matInput ngModel placeholder="ABTA" maxlength="10" name="abtaNo" #abtaNo="ngModel" [(ngModel)]="supplier[0].abtaNo">
                          </mat-form-field>
    
                          <mat-form-field class="atolEdit">
                            <input matInput ngModel placeholder="ATOL" maxlength="10" name="atolNo" #atolNo="ngModel" [(ngModel)]="supplier[0].atolNo">
                          </mat-form-field>

                          <mat-form-field class="regNoEdit">
                            <input matInput ngModel placeholder="Registration No." maxlength="50" name="regNo" #regNo="ngModel" [(ngModel)]="supplier[0].regNo">
                          </mat-form-field>

                          <mat-form-field class="vatReqEdit">
                            <mat-select ngModel required [(ngModel)]="supplier[0].vatRequirement" name="vatRequirement" #vatRequirement="ngModel" placeholder="VAT Requirement">
                              <mat-option *ngFor="let vat of vatRequirements" [value]="vat['value']">
                                {{vat['viewValue']}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <br>

                          <mat-form-field class="confirmationDaysEdit">
                            <input matInput required ngModel title="The number of days after the booking is made the confirmations should be received. If this supplier confirms bookings automatically then set this to zero." placeholder="Confirmation Days"
                              name="confirmationDays" type="number" min="0" max="365" #confirmationDays="ngModel" [(ngModel)]="supplier[0].confirmationDays">
                          </mat-form-field>
  
                          <mat-form-field class="paymentDueEdit">
                            <input matInput required ngModel title="The number of days before departure the supplier requires payment." placeholder="Payment Due Days" name="paymentDueDays" type="number" min="0" max="365" #paymentDueDays="ngModel"
                              [(ngModel)]="supplier[0].paymentDueDays">
                          </mat-form-field>
  
                          <mat-form-field class="paymentDueEdit">
                            <input matInput required ngModel placeholder="Deposit Rate (%)" name="depositRate" type="number" step="0.01" min="0" max="100" #depositRate="ngModel" [(ngModel)]="supplier[0].depositRate">
                          </mat-form-field>

                          <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                            <div class="checkboxElement">
                              <label style="display: flex;">
                                <h3>Contact Details</h3>
                                <button mat-button color="primary" type="button" (click)="openContactDetails()"
                                style="margin-left: 42.5px;">
                                  <mat-icon style="margin: auto;">open_in_new</mat-icon>
                                </button>
                              </label>
                            </div>
                            
                            <div class="checkboxElement">
                              <label style="display: flex;">
                                <h3>Bank Accounts</h3>
                                <button mat-button color="primary" type="button" (click)="openBankingAccounts()" class="bankAccBtn"
                                matTooltip="Available only if Supplier is assigned to MISTTA code" matTooltipClass="line-normal-tooltip">
                                  <mat-icon style="margin: auto;">open_in_new</mat-icon>
                                </button>
                              </label>
                            </div>

                            <div class="checkboxElement">
                              <label style="display: flex; margin-left: 15px;">
                                <h3>Hidden (Booking System)</h3>
                                <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'hidden'); hideUnhideSupplier(supplier)"
                                [checked]="supplier[0].hidden == 'yes'" style="margin-left: 59px;"
                                value="{{supplier[0].hidden}}">
                              </label>
                            </div>
                          </div>
                          
                          <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                            <div class="checkboxElement">
                              <label style="display: flex;">
                                <h3>Under SAFI</h3>
                                <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'underSafi')"
                                [checked]="supplier[0].underSafi == 'yes'" style="margin-left: 100px;" value="{{supplier[0].underSafi}}">
                              </label>
                            </div>

                            <div class="checkboxElement">
                              <label style="display: flex; margin-left: 17px;">
                                <h3>TAPS Registered</h3>
                                <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'isTapsReg')"
                                [checked]="supplier[0].isTapsReg == 'yes'" style="margin-left: 55.5px;"
                                value="{{supplier[0].isTapsReg}}">
                              </label>
                            </div>
                          </div>
    
                          <div *ngIf="supplier[0].isTapsReg == 'yes'">

                            <div class="checkboxContainer" style="margin-top: 10px; margin-bottom: 25px;">
                              <div class="checkboxElement" style="margin-right: 69px;">
                                <label style="display: flex;">
                                  <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'tapsSuspended')"
                                  [checked]="supplier[0].tapsSuspended == 'yes'"
                                  value="{{supplier[0].tapsSuspended}}">
                                  <span>Suspended</span>
                                </label>
                              </div>

                              <div class="checkboxElement" style="margin-right: 68.5px;">
                                <label style="display: flex;">
                                  <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'tapsSynchOverride')"
                                  [checked]="supplier[0].tapsSynchOverride == 'yes'"
                                  value="{{supplier[0].tapsSynchOverride}}">
                                  <span matTooltip="MISTTA will override some of the Supplier details on a weekly basis" matTooltipClass="line-normal-tooltip">MISTTA Override</span>
                                </label>
                              </div>

                              <div class="checkboxElement">
                                <label style="display: flex;">
                                  <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'tapsDenyAmendments')"
                                  [checked]="supplier[0].tapsDenyAmendments == 'yes'"
                                  value="{{supplier[0].tapsDenyAmendments}}">
                                  <span>Deny Amendments</span>
                                </label>
                              </div>
                            </div>

                            <mat-form-field style="width: 90px;">
                              <input ngModel required [(ngModel)]="supplier[0].tapsTransactionFee" name="tapsTransactionFee" #tapsTransactionFee="ngModel" type="number" step="0.01" matInput placeholder="Transaction Fee">
                            </mat-form-field>
    
                            <mat-form-field style="width: 90px;">
                              <mat-select ngModel required [(ngModel)]="supplier[0].tapsPaymentDays" name="tapsPaymentDays" #tapsPaymentDays="ngModel" placeholder="Payment Days">
                                <mat-option [value]="7">7</mat-option>
                                <mat-option [value]="14">14</mat-option>
                                <mat-option [value]="28">28</mat-option>
                                <mat-option [value]="39">39</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field class="misttaEdit">
                              <input matInput ngModel placeholder="MISTTA Code" maxlength="10" name="misttaCode" #misttaCode="ngModel" [(ngModel)]="supplier[0].misttaCode">
                            </mat-form-field>

                            <mat-form-field style="max-width: unset; width: 370px;">
                              <input ngModel required [(ngModel)]="supplier[0].tapsSuspensionReason" name="tapsSuspensionReason" #tapsSuspensionReason="ngModel" placeholder="Suspension Reason" maxlength="255"
                               matInput [disabled]="supplier[0].tapsSuspended == 'no'">
                            </mat-form-field>

                            <h3 style="display: flex;">
                              <span style="margin-right: 35px; padding-top: 1px;" matTooltip="Availability on TAPS" matTooltipClass="line-normal-tooltip">Available Members</span>
                              <mat-radio-group color="primary" style="display: flex;" ndModel name="tapsExcludeFlag" #tapsExcludeFlag="ngModel" [(ngModel)]="expandedElement[0].tapsExcludeFlag">
                                <mat-radio-button value="yes" [checked]="expandedElement[0].tapsExcludeFlag == 'yes'">All Excluding</mat-radio-button>
                                <mat-radio-button style="margin-left: 55.5%;" value="no" [checked]="expandedElement[0].tapsExcludeFlag == 'no'">Selected Only</mat-radio-button>
                              </mat-radio-group>
                            </h3>

                            <mat-form-field class="example-chip-list" [floatLabel]="'always'">
                              <mat-label>Member List</mat-label>
                              <mat-chip-list #chipListBranches aria-label="Member List">
                                <mat-chip *ngFor="let tradeCode of supplier[0].tapsTradeCodes" [selectable]="false" [removable]="true" (removed)="editTAPSsuppGroup(expandedElement[0], tradeCode, 'remove')">
                                  {{tradeCode}}
                                  <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>

                                <mat-select ngModel placeholder="Add New" name="tradeCode" id="tradeCode" #tradeCode="ngModel" class="addNewChip"
                                            (selectionChange)="editTAPSsuppGroup(expandedElement[0], $event.value, 'add')" (click)="filterBranch = ''; filterSelect('branch');">

                                  <div class="sticky-search-container" style="margin-bottom: unset !important;">
                                    <mat-form-field appearance="fill" class="filterSelectList">
                                      <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                                    </mat-form-field>
              
                                    <div style="text-align: left; display: flex; justify-content: center;">
                                      <button mat-button (click)="filterEOD()" class="sticky-button-search">
                                        <span *ngIf="!branchShowEOD">Include EOD Members</span>
                                        <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                                      </button>
                                    </div>
                                  </div>

                                  <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                                    <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                                  </mat-option>
                                </mat-select>
                              </mat-chip-list>
                            </mat-form-field>
                          </div>
                        </div>

                        <div *ngIf="['tapsAdmin'].includes(userType)" class="pseudoDiv">
                          <h3>External Names / Pseudonyms</h3>
                          <button type="button" mat-icon-button style="margin-left: 59px;" color="primary" *ngIf="supplier.externalExpanded"
                            (click)="supplier.externalExpanded = false;">
                            <mat-icon>expand_less</mat-icon>
                          </button>
                          <button type="button" mat-icon-button style="margin-left: 59px;" color="primary" *ngIf="!supplier.externalExpanded"
                            (click)="supplier.externalExpanded = true; loadSupplierX(supplier)">
                            <mat-icon>expand_more</mat-icon>
                          </button>
                        </div>

                        <mat-form-field class="example-chip-list" *ngIf="supplier.externalExpanded">
                          <mat-label>External / Pseudonym Names</mat-label>
                          <mat-chip-list #chipList aria-label="External Names">
                            <mat-chip *ngFor="let exName of supplier.externalNames" [selectable]="false" [removable]="true" (removed)="removeExternalName(exName, supplier)">
                              {{exName}}
                              <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="New External Name.." [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="addExternalName($event, supplier)">
                          </mat-chip-list>
                        </mat-form-field>

                        <button class="boxSubmitButtons" mat-raised-button color="primary" type="submit" [disabled]="editSupplierForm.invalid">
                          <mat-icon class="iconInButton">edit</mat-icon>Save
                        </button>
                      </form>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="columnsTAPS"></mat-header-row>
              <mat-row *matRowDef="let supplier; let row; columns : columnsTAPS;" (click)="expandedElement = expandedElement === row ? null : row; changeSuppAvailability('')"
              [ngClass]="'trueRow'" matTooltipClass="line-normal-tooltip"></mat-row>
              <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
              [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
            </mat-table>

            <mat-table multiTemplateDataRows [dataSource]="supplierData" style="clear: both;" *ngIf="['sinGSAdmin', 'wcManager', 'memberManager'].includes(userType)">
              <ng-container matColumnDef="suppName">
                <mat-header-cell *matHeaderCellDef>Supplier Name</mat-header-cell>
                <mat-cell *matCellDef="let supplier">{{ supplier[0].supplierNameM }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="abta">
                <mat-header-cell *matHeaderCellDef>ABTA</mat-header-cell>
                <mat-cell *matCellDef="let supplier">
                  <mat-icon *ngIf="supplier[0].abtaNo" style="color: green;" [matTooltip]="supplier[0].abtaNo">check</mat-icon>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="atol">
                <mat-header-cell *matHeaderCellDef>ATOL</mat-header-cell>
                <mat-cell *matCellDef="let supplier">
                  <mat-icon *ngIf="supplier[0].atolNo" style="color: green;" [matTooltip]="supplier[0].atolNo">check</mat-icon>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="safi">
                <mat-header-cell *matHeaderCellDef>SAFI</mat-header-cell>
                <mat-cell *matCellDef="let supplier">
                  <mat-icon *ngIf="supplier[0].underSafi == 'yes'" style="color: green;">check</mat-icon>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sfc">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Under SFC</mat-header-cell>
                <mat-cell *matCellDef="let supplier" class="centeredColumn">
                  <mat-icon *ngIf="currentTradeCode !== 'Q0000' && supplier.isUnderSFC" style="color: green;">check</mat-icon>

                  <span class="custom-tooltip" *ngIf="currentTradeCode === 'Q0000'">
                    <mat-icon>question_mark</mat-icon>
                    <span class="tooltip-text">Distinct for every branch</span>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="na">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">N/A</mat-header-cell>
                <mat-cell *matCellDef="let supplier" class="centeredColumn">
                  <mat-icon *ngIf="currentTradeCode !== 'Q0000' && !supplier[0].abtaNo && !supplier[0].atolNo && !supplier.isUnderSFC && supplier[0].underSafi == 'no'" style="color: green;">check</mat-icon>

                  <span class="custom-tooltip" *ngIf="currentTradeCode === 'Q0000' && !supplier[0].abtaNo && !supplier[0].atolNo">
                    <mat-icon>question_mark</mat-icon>
                    <span class="tooltip-text">Distinct for every branch</span>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="hidden" *ngIf="userType == 'sinGSAdmin'">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Visible</mat-header-cell>
                <mat-cell *matCellDef="let supplier" class="centeredColumn">
                  <mat-icon *ngIf="supplier.allUnhidden" style="color: green;">check</mat-icon>

                  <span class="custom-tooltip" *ngIf="!supplier.allUnhidden && !supplier.noUnhidden">
                    <mat-icon style="color: orange;">radio_button_unchecked</mat-icon>
                    <span class="tooltip-text">Some are not visible</span>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <mat-cell class="expandedDetail" *matCellDef="let supplier" [attr.colspan]="5" style="padding: 0px;">
                  <div style="width: 100%;" *ngIf="['sinGSAdmin'].includes(userType)">
                     <div style="padding-left: 24px; padding-right: 24px;">

                      <form [@inOutAnimation] *ngIf="supplier == expandedElement" ngNativeValidate #editSupplierForm="ngForm" (ngSubmit)="editSupplier(editSupplierForm, supplier)">
                        <h3 class="narrowH3" style="margin-top: 16px;">Address Details</h3>
                        <mat-form-field class="addressLine1Edit">
                          <input matInput ngModel placeholder="Address Line 1" maxlength="50" name="addressLine1" #addressLine1="ngModel" [(ngModel)]="supplier[0].addressLine1">
                        </mat-form-field>

                        <mat-form-field class="addressLine2Edit">
                          <input matInput ngModel placeholder="Address Line 2" maxlength="50" name="addressLine2" #addressLine2="ngModel" [(ngModel)]="supplier[0].addressLine2">
                        </mat-form-field>

                        <mat-form-field class="addressLine3Edit">
                          <input matInput ngModel placeholder="Address Line 3" maxlength="50" name="addressLine3" #addressLine3="ngModel" [(ngModel)]="supplier[0].addressLine3">
                        </mat-form-field>

                        <mat-form-field class="addressLine4Edit">
                          <input matInput ngModel placeholder="Address Line 4" maxlength="50" name="addressLine4" #addressLine4="ngModel" [(ngModel)]="supplier[0].addressLine4">
                        </mat-form-field>

                        <br>

                        <mat-form-field class="postCodeEdit">
                          <input matInput ngModel placeholder="Post Code" maxlength="10" name="postCode" #postCode="ngModel" [(ngModel)]="supplier[0].postCode">
                        </mat-form-field>

                        <mat-form-field class="countryEdit">
                          <mat-select ngModel [(ngModel)]="supplier[0].country" name="country" #country="ngModel" placeholder="Country">
                            <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                              <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterCountry" (keyup)="filterSelect('country')">
                            </mat-form-field>
                            <mat-option *ngFor="let country of countryFiltered" [value]="country" (click)="filterCountry = ''; filterSelect('country');">
                              {{country}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput ngModel placeholder="Website" maxlength="100" name="website" #website="ngModel" [(ngModel)]="supplier[0].website">
                        </mat-form-field>

                        <div class="financialDiv">
                          
                          <h3 class="narrowH3">Financial Details</h3>
                          <mat-form-field class="abtaEdit">
                            <input matInput ngModel placeholder="ABTA" maxlength="10" name="abtaNo" #abtaNo="ngModel" [(ngModel)]="supplier[0].abtaNo">
                          </mat-form-field>
    
                          <mat-form-field class="atolEdit">
                            <input matInput ngModel placeholder="ATOL" maxlength="10" name="atolNo" #atolNo="ngModel" [(ngModel)]="supplier[0].atolNo">
                          </mat-form-field>

                          <mat-form-field class="regNoEdit">
                            <input matInput ngModel placeholder="Registration No." maxlength="50" name="regNo" #regNo="ngModel" [(ngModel)]="supplier[0].regNo">
                          </mat-form-field>

                          <mat-form-field class="vatReqEdit">
                            <mat-select ngModel required [(ngModel)]="supplier[0].vatRequirement" name="vatRequirement" #vatRequirement="ngModel" placeholder="VAT Requirement">
                              <mat-option *ngFor="let vat of vatRequirements" [value]="vat['value']">
                                {{vat['viewValue']}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <br>

                          <mat-form-field class="confirmationDaysEdit">
                            <input matInput required ngModel title="The number of days after the booking is made the confirmations should be received. If this supplier confirms bookings automatically then set this to zero." placeholder="Confirmation Days"
                              name="confirmationDays" type="number" min="0" max="365" #confirmationDays="ngModel" [(ngModel)]="supplier[0].confirmationDays">
                          </mat-form-field>
  
                          <mat-form-field class="paymentDueEdit">
                            <input matInput required ngModel title="The number of days before departure the supplier requires payment." placeholder="Payment Due Days" name="paymentDueDays" type="number" min="0" max="365" #paymentDueDays="ngModel"
                              [(ngModel)]="supplier[0].paymentDueDays">
                          </mat-form-field>
  
                          <mat-form-field class="paymentDueEdit">
                            <input matInput required ngModel placeholder="Deposit Rate (%)" name="depositRate" type="number" step="0.01" min="0" max="100" #depositRate="ngModel" [(ngModel)]="supplier[0].depositRate">
                          </mat-form-field>

                          <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                            <div class="checkboxElement">
                              <label style="display: flex;">
                                <h3>Contact Details</h3>
                                <button mat-button color="primary" type="button" (click)="openContactDetails()"
                                style="margin-left: 42.5px;">
                                  <mat-icon style="margin: auto;">open_in_new</mat-icon>
                                </button>
                              </label>
                            </div>
                            
                            <div class="checkboxElement">
                              <label style="display: flex;">
                                <h3>Bank Accounts</h3>
                                <button mat-button color="primary" type="button" (click)="openBankingAccounts()" class="bankAccBtn"
                                matTooltip="Available only if Supplier is assigned to MISTTA code" matTooltipClass="line-normal-tooltip">
                                  <mat-icon style="margin: auto;">open_in_new</mat-icon>
                                </button>
                              </label>
                            </div>

                            <div class="checkboxElement">
                              <label style="display: flex; margin-left: 15px;">
                                <h3>Hidden (Booking System)</h3>
                                <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'hidden'); hideUnhideSupplier(supplier)"
                                [checked]="supplier[0].hidden == 'yes'" style="margin-left: 59px;"
                                value="{{supplier[0].hidden}}">
                              </label>
                            </div>
                          </div>
                          
                          <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                            <div class="checkboxElement">
                              <label style="display: flex;">
                                <h3>Under SAFI</h3>
                                <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'underSafi')"
                                [checked]="supplier[0].underSafi == 'yes'" style="margin-left: 100px;" value="{{supplier[0].underSafi}}">
                              </label>
                            </div>

                            <div class="checkboxElement">
                              <label style="display: flex; margin-left: 17px;">
                                <h3>TAPS Registered</h3>
                                <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'isTapsReg')"
                                [checked]="supplier[0].isTapsReg == 'yes'" style="margin-left: 55.5px;"
                                value="{{supplier[0].isTapsReg}}">
                              </label>
                            </div>
                          </div>
    
                          <div *ngIf="supplier[0].isTapsReg == 'yes'">

                            <div class="checkboxContainer" style="margin-top: 10px; margin-bottom: 25px;">
                              <div class="checkboxElement" style="margin-right: 69px;">
                                <label style="display: flex;">
                                  <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'tapsSuspended')"
                                  [checked]="supplier[0].tapsSuspended == 'yes'"
                                  value="{{supplier[0].tapsSuspended}}">
                                  <span>Suspended</span>
                                </label>
                              </div>

                              <div class="checkboxElement" style="margin-right: 68.5px;">
                                <label style="display: flex;">
                                  <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'tapsSynchOverride')"
                                  [checked]="supplier[0].tapsSynchOverride == 'yes'"
                                  value="{{supplier[0].tapsSynchOverride}}">
                                  <span matTooltip="MISTTA will override some of the Supplier details on a weekly basis" matTooltipClass="line-normal-tooltip">MISTTA Override</span>
                                </label>
                              </div>

                              <div class="checkboxElement">
                                <label style="display: flex;">
                                  <input type="checkbox" (change)="checkboxYesNo($event, supplier[0], 'tapsDenyAmendments')"
                                  [checked]="supplier[0].tapsDenyAmendments == 'yes'"
                                  value="{{supplier[0].tapsDenyAmendments}}">
                                  <span>Deny Amendments</span>
                                </label>
                              </div>
                            </div>

                            <mat-form-field style="width: 90px;">
                              <input ngModel required [(ngModel)]="supplier[0].tapsTransactionFee" name="tapsTransactionFee" #tapsTransactionFee="ngModel" type="number" step="0.01" matInput placeholder="Transaction Fee">
                            </mat-form-field>
    
                            <mat-form-field style="width: 90px;">
                              <mat-select ngModel required [(ngModel)]="supplier[0].tapsPaymentDays" name="tapsPaymentDays" #tapsPaymentDays="ngModel" placeholder="Payment Days">
                                <mat-option [value]="7">7</mat-option>
                                <mat-option [value]="14">14</mat-option>
                                <mat-option [value]="28">28</mat-option>
                                <mat-option [value]="39">39</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field class="misttaEdit">
                              <input matInput ngModel placeholder="MISTTA Code" maxlength="10" name="misttaCode" #misttaCode="ngModel" [(ngModel)]="supplier[0].misttaCode">
                            </mat-form-field>

                            <mat-form-field style="max-width: unset; width: 370px;">
                              <input ngModel required [(ngModel)]="supplier[0].tapsSuspensionReason" name="tapsSuspensionReason" #tapsSuspensionReason="ngModel" placeholder="Suspension Reason" maxlength="255"
                               matInput [disabled]="supplier[0].tapsSuspended == 'no'">
                            </mat-form-field>

                            <h3 style="display: flex;">
                              <span style="margin-right: 35px; padding-top: 1px;" matTooltip="Availability on TAPS" matTooltipClass="line-normal-tooltip">Available Members</span>
                              <mat-radio-group color="primary" style="display: flex;" ndModel name="tapsExcludeFlag" #tapsExcludeFlag="ngModel" [(ngModel)]="expandedElement[0].tapsExcludeFlag">
                                <mat-radio-button value="yes" [checked]="expandedElement[0].tapsExcludeFlag == 'yes'">All Excluding</mat-radio-button>
                                <mat-radio-button style="margin-left: 55.5%;" value="no" [checked]="expandedElement[0].tapsExcludeFlag == 'no'">Selected Only</mat-radio-button>
                              </mat-radio-group>
                            </h3>

                            <mat-form-field class="example-chip-list" [floatLabel]="'always'">
                              <mat-label>Member List</mat-label>
                              <mat-chip-list #chipListBranches aria-label="Member List">
                                <mat-chip *ngFor="let tradeCode of supplier[0].tapsTradeCodes" [selectable]="false" [removable]="true" (removed)="editTAPSsuppGroup(expandedElement[0], tradeCode, 'remove')">
                                  {{tradeCode}}
                                  <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
  
                                <mat-select ngModel placeholder="Add New" name="tradeCode" id="tradeCode" #tradeCode="ngModel" class="addNewChip"
                                  (selectionChange)="editTAPSsuppGroup(expandedElement[0], $event.value, 'add')" (click)="filterBranch = ''; filterSelect('branch');">
                                  <div class="sticky-search-container" style="margin-bottom: unset !important;">
                                    <mat-form-field appearance="fill" class="filterSelectList">
                                      <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                                    </mat-form-field>
              
                                    <div style="text-align: left; display: flex; justify-content: center;">
                                      <button mat-button (click)="filterEOD()" class="sticky-button-search">
                                        <span *ngIf="!branchShowEOD">Include EOD Members</span>
                                        <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                                      </button>
                                    </div>
                                  </div>

                                  <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                                    <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                                  </mat-option>
                                </mat-select>
                              </mat-chip-list>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="availableToDiv">
                          <h3 style="display: flex;">
                            <span style="margin-right: 21px; padding-top: 1px;" matTooltip="Availability on Booking System" matTooltipClass="line-normal-tooltip">Supplier Available To</span>
                            <mat-radio-group color="primary" (change)="changeSuppAvailability($event.value)" style="display: flex;">
                              <mat-radio-button value="everyone" [checked]="expandedElement[0].displayAvailability == 'everyone'">Everyone</mat-radio-button>
                              <mat-radio-button style="margin-left: 6%;" value="membership" [checked]="expandedElement[0].displayAvailability == 'membership'">Membership</mat-radio-button>
                              <mat-radio-button style="margin-left: 6%;" value="selected" [checked]="expandedElement[0].displayAvailability == 'selected'">Selected Members</mat-radio-button>
                            </mat-radio-group>
                          </h3>

                          <mat-form-field *ngIf="expandedElement[0].displayAvailability == 'membership'" style="max-width: unset; width: 370px;">
                            <mat-select ngModel required [(ngModel)]="supplier[0].company" name="company" #company="ngModel" placeholder="Membership">
                              <mat-option [value]="'gtg'">Global Members</mat-option>
                              <mat-option [value]="'tta'">TTA Members</mat-option>
                              <mat-option [value]="'ttng'">Worldchoice Members</mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field *ngIf="expandedElement[0].displayAvailability == 'selected'" class="example-chip-list" [floatLabel]="'always'">
                            <mat-label>Member List</mat-label>
                            <mat-chip-list #chipListBranches aria-label="Member List">
                              <mat-chip *ngFor="let tradeCode of supplier[0].tradeCodes" [selectable]="false" [removable]="true" (removed)="editSuppGroup(expandedElement[0], tradeCode, 'remove')">
                                <mat-icon class="emailMember" (click)="emailMemberApproved(tradeCode, expandedElement[0].supplierNameM)" matTooltip="Inform about availability" matTooltipClass="line-normal-tooltip">email</mat-icon>
                                {{tradeCode}}
                                <mat-icon matChipRemove class="chipRemove">cancel</mat-icon>
                              </mat-chip>

                              <mat-select ngModel placeholder="Add New" name="tradeCode" id="tradeCode" #tradeCode="ngModel" class="addNewChip"
                                (selectionChange)="editSuppGroup(expandedElement[0], $event.value, 'add')" (click)="filterBranch = ''; filterSelect('branch');">
                                <div class="sticky-search-container" style="margin-bottom: unset !important;">
                                  <mat-form-field appearance="fill" class="filterSelectList">
                                    <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                                  </mat-form-field>
            
                                  <div style="text-align: left; display: flex; justify-content: center;">
                                    <button mat-button (click)="filterEOD()" class="sticky-button-search">
                                      <span *ngIf="!branchShowEOD">Include EOD Members</span>
                                      <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                                    </button>
                                  </div>
                                </div>

                                <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                                  <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                                </mat-option>
                              </mat-select>
                            </mat-chip-list>
                          </mat-form-field>
                        </div>

                        <div class="pseudoDiv">
                          <h3>External Names / Pseudonyms</h3>
                          <button type="button" mat-icon-button style="margin-left: 59px;" color="primary" *ngIf="supplier.externalExpanded"
                            (click)="supplier.externalExpanded = false;">
                            <mat-icon>expand_less</mat-icon>
                          </button>
                          <button type="button" mat-icon-button style="margin-left: 59px;" color="primary" *ngIf="!supplier.externalExpanded"
                            (click)="supplier.externalExpanded = true; loadSupplierX(supplier)">
                            <mat-icon>expand_more</mat-icon>
                          </button>
                        </div>

                        <mat-form-field class="example-chip-list" *ngIf="supplier.externalExpanded">
                          <mat-label>External / Pseudonym Names</mat-label>
                          <mat-chip-list #chipList aria-label="External Names">
                            <mat-chip *ngFor="let exName of supplier.externalNames" [selectable]="false" [removable]="true" (removed)="removeExternalName(exName, supplier)">
                              {{exName}}
                              <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="New External Name.." [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="addExternalName($event, supplier)">
                          </mat-chip-list>
                        </mat-form-field>

                        <button class="boxSubmitButtons" mat-raised-button color="primary" type="submit" [disabled]="editSupplierForm.invalid">
                          <mat-icon class="iconInButton">edit</mat-icon>Save
                        </button>

                        <button class="boxSubmitButtons" *ngIf="userEmail == 'greg@gmail.com'" mat-raised-button color="warn" type="button" (click)="removeSupplier(supplier[0])" style="margin-left: 90px;">
                          <mat-icon class="iconInButton">delete</mat-icon>Remove
                        </button>
                      </form>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="userType !== 'sinGSAdmin'">
                <mat-header-row *matHeaderRowDef="columnsAgent"></mat-header-row>
                <mat-row *matRowDef="let supplier; let row; columns : columnsAgent;" (click)="expandedElement = expandedElement === row ? null : row; changeSuppAvailability('')" [ngClass]="'trueRow'"></mat-row>
              </ng-container>

              <ng-container *ngIf="userType === 'sinGSAdmin'">
                <mat-header-row *matHeaderRowDef="columnsAdmin"></mat-header-row>
                <mat-row *matRowDef="let supplier; let row; columns : columnsAdmin;" (click)="expandedElement = expandedElement === row ? null : row; changeSuppAvailability('')" [ngClass]="'trueRow'"></mat-row>
                <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
              </ng-container>

            </mat-table>
          </div>

          <div [@inAnimation] *ngIf="createSuppDiv">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Create Supplier
            </h1>
            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="uploadSuppliersBox"
                    (click)="uploadSuppliersBox = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!uploadSuppliersBox"
                    (click)="uploadSuppliersBox = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Upload Supplier Data
                </div>
              </div>

              <div [@customExpansionDetails]="uploadSuppliersBox == true ? 'expanded' : 'collapsed'"
                class="ownExpansionBody">
                <button color="primary" mat-raised-button (click)="getCSVtemplate()"
                  style="margin-top: 15px; width: 155px;">
                  <mat-icon class="iconInButton">file_download</mat-icon>
                  Get Template
                </button>

                <h5 style="margin-top: 15px; margin-bottom: 20px;">
                  <i>We need to validate your file. Click the button and choose your file - we'll let you know
                    if there are any problems
                  </i>
                </h5>

                <button mat-raised-button type="button" (click)="csvInput1.click()" color="primary"
                  style="width: 155px;">
                  <mat-icon class="iconInButton">fact_check</mat-icon>Validate File
                </button>
                <br>
                <input matInput ngModel placeholder="File Name" name="uploadedFile" #uploadedFile="ngModel"
                  maxlength="50" [(ngModel)]="uploadedFileName" readonly="true" style="display: none;">
                <input #csvInput1 hidden="true" type="file" onclick="this.value=null"
                  (change)="validateSupplierFile($event)" accept=".csv" multiple="false" />

                <h5 style="margin-top: 15px; margin-bottom: 20px;"><i>
                  If the file was validated, a button will appear
                    below. Press it to load validated supplier data.</i></h5>
                <button *ngIf="csvSupplierData.length > 0" color="accent"
                  style="width: 155px;" mat-raised-button
                  (click)="uploadSupplierData()">
                  <mat-icon class="iconInButton">file_upload</mat-icon>
                  Upload Data
                </button>

                <h5 style="margin-bottom: 10px;">
                  <i>Supported categories:
                  Accommodation, Attraction, CarHire, CarParking, Cruise, Flight, Miscellaneous, Package, Train, Transfer</i>
                </h5>
              </div>

              <div [@customExpansionDetails]="uploadSuppliersBox == false ? 'expanded' : 'collapsed'"
                class="ownExpansionBodyEmpty" (click)="uploadSuppliersBox = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 100px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="createSupplierBox"
                    (click)="createSupplierBox = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!createSupplierBox"
                    (click)="createSupplierBox = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Create New Supplier
                </div>
              </div>

              <div [@customExpansionDetails]="createSupplierBox == true ? 'expanded' : 'collapsed'"
                class="ownExpansionBody">
                <h5><i>Fill in all fields marked with an asterisk - you can come back to the Supplier and do the rest at
                  any time.</i></h5>
                <form ngNativeValidate #createSupplierForm="ngForm" (ngSubmit)="createSupplier(createSupplierForm)">
                  <div>

                    <h3 class="narrowH3">General Information</h3>
                    <div class="checkboxContainer" style="align-items: center;">
                      <mat-form-field class="supplierNameEdit" style="max-width: 370px; width: 100%;">
                        <input matInput ngModel required placeholder="Supplier Name" name="supplierNameM" #supplierNameM="ngModel" minlength="3" maxlength="100">
                      </mat-form-field>
      
                      <!--<mat-form-field class="supplierTypeEdit">
                        <mat-select ngModel required name="supplierType" #supplierType="ngModel" placeholder="Supplier Type">
                          <mat-option *ngFor="let type of supplierTypes" [value]="type['value']">
                            {{type['viewValue']}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>-->
                    </div>
    
                    <h3 class="narrowH3">Address Details</h3>
                    <mat-form-field class="addressLine1Edit">
                      <input matInput ngModel placeholder="Address Line 1" name="addressLine1" #addressLine1="ngModel" maxlength="50">
                    </mat-form-field>
    
                    <mat-form-field class="addressLine2Edit">
                      <input matInput ngModel placeholder="Address Line 2" name="addressLine2" #addressLine2="ngModel" maxlength="50">
                    </mat-form-field>
    
                    <mat-form-field class="addressLine3Edit">
                      <input matInput ngModel placeholder="Address Line 3" name="addressLine3" #addressLine3="ngModel" maxlength="50">
                    </mat-form-field>
    
                    <mat-form-field class="addressLine4Edit">
                      <input matInput ngModel placeholder="Address Line 4" name="addressLine4" #addressLine4="ngModel" maxlength="50">
                    </mat-form-field>
    
                    <br>
    
                    <mat-form-field class="postCodeEdit">
                      <input matInput ngModel placeholder="Post Code" name="postCode" #postCode="ngModel" maxlength="10">
                    </mat-form-field>
    
                    <mat-form-field class="countryEdit">
                      <mat-select ngModel name="country" #country="ngModel" placeholder="Country">
                        <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterCountry" (keyup)="filterSelect('country')">
                        </mat-form-field>
                        <mat-option *ngFor="let country of countryFiltered" [value]="country" (click)="filterCountry = ''; filterSelect('country');">
                          {{country}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput ngModel placeholder="Website" maxlength="100" name="website" #website="ngModel">
                    </mat-form-field>
    
                    <h3 class="narrowH3">Financial Details</h3>
                    <mat-form-field class="confirmationDaysEdit">
                      <input matInput ngModel title="The number of days after the booking is made the confirmations should be received. If this supplier confirms bookings automatically then set this to zero." placeholder="Confirmation Days"
                      required name="confirmationDays" type="number" min="0" max="365" #confirmationDays="ngModel">
                    </mat-form-field>
    
                    <mat-form-field class="paymentDueEdit">
                      <input matInput ngModel title="The number of days before departure the supplier requires payment." placeholder="Payment Due Days"
                      required name="paymentDueDays" type="number" min="0" max="365" #paymentDueDays="ngModel">
                    </mat-form-field>
    
                    <mat-form-field class="paymentDueEdit">
                      <input matInput ngModel placeholder="Deposit Rate (%)" required name="depositRate" type="number" step="0.01" min="0" max="100" #depositRate="ngModel">
                    </mat-form-field>
    
                    <mat-form-field class="vatReqEdit">
                      <mat-select ngModel required name="vatRequirement" #vatRequirement="ngModel" placeholder="VAT Requirement">
                        <mat-option *ngFor="let vat of vatRequirements" [value]="vat['value']">
                          {{vat['viewValue']}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
    
                    <br>
    
                    <mat-form-field class="abtaEdit">
                      <input matInput ngModel placeholder="ABTA" name="abtaNo" #abtaNo="ngModel" maxlength="10">
                    </mat-form-field>
    
                    <mat-form-field class="atolEdit">
                      <input matInput ngModel placeholder="ATOL" name="atolNo" #atolNo="ngModel" maxlength="10">
                    </mat-form-field>

                    <mat-form-field class="regNoEdit">
                      <input matInput ngModel placeholder="Registration No." name="regNo" #regNo="ngModel" maxlength="50">
                    </mat-form-field>
    
                    <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                      <div class="checkboxElement">
                        <label style="display: flex;">
                          <h3>Under SAFI</h3>
                          <input type="checkbox" (change)="checkboxYesNo($event, newSupplier, 'underSafi')"
                          [checked]="newSupplier.underSafi == 'yes'" style="margin-left: 100.5px;" value="{{newSupplier.underSafi}}">
                        </label>
                      </div>

                      <div class="checkboxElement">
                        <label style="display: flex; margin-left: 17px;">
                          <h3>TAPS Registered</h3>
                          <input type="checkbox" (change)="checkboxYesNo($event, newSupplier, 'isTapsReg')"
                          [checked]="newSupplier.isTapsReg == 'yes'" style="margin-left: 55.5px;"
                          value="{{newSupplier.isTapsReg}}">
                        </label>
                      </div>
                    </div>
    
                    <div *ngIf="newSupplier.isTapsReg == 'yes'" style="border-bottom: 2px solid rgb(219, 219, 219)">
    
                      <div class="checkboxContainer">
                        <div class="checkboxElement" style="margin-right: 69.5px;">
                          <label style="display: flex;">
                            <input type="checkbox" (change)="checkboxYesNo($event, newSupplier, 'tapsSuspended')"
                            [checked]="newSupplier.tapsSuspended == 'yes'"
                            value="{{newSupplier.tapsSuspended}}">
                            <span>Suspended</span>
                          </label>
                        </div>
    
                        <div class="checkboxElement" style="margin-right: 40px;">
                          <label style="display: flex;">
                            <input type="checkbox" (change)="checkboxYesNo($event, newSupplier, 'tapsSynchOverride')"
                            [checked]="newSupplier.tapsSynchOverride == 'yes'"
                            value="{{newSupplier.tapsSynchOverride}}">
                            <span matTooltip="MISTTA will override some of the Supplier details on a weekly basis" matTooltipClass="line-normal-tooltip">MISTTA Override</span>
                          </label>
                        </div>
    
                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <input type="checkbox" (change)="checkboxYesNo($event, newSupplier, 'tapsDenyAmendments')"
                            [checked]="newSupplier.tapsDenyAmendments == 'yes'"
                            value="{{newSupplier.tapsDenyAmendments}}">
                            <span>Deny Amendments</span>
                          </label>
                        </div>
                      </div>
    
                      <mat-form-field style="width: 90px;">
                        <input ngModel required name="tapsTransactionFee" #tapsTransactionFee="ngModel" type="number" step="0.01" matInput placeholder="Transaction Fee">
                      </mat-form-field>
    
                      <mat-form-field style="width: 90px;">
                        <mat-select ngModel required name="tapsPaymentDays" #tapsPaymentDays="ngModel" placeholder="Payment Days">
                          <mat-option [value]="7">7</mat-option>
                          <mat-option [value]="14">14</mat-option>
                          <mat-option [value]="28">28</mat-option>
                          <mat-option [value]="39">39</mat-option>
                        </mat-select>
                      </mat-form-field>
    
                      <mat-form-field class="misttaEdit">
                        <input matInput ngModel placeholder="MISTTA Code" maxlength="10" name="misttaCode" #misttaCode="ngModel">
                      </mat-form-field>
    
                      <mat-form-field style="max-width: unset; width: 370px;">
                        <input ngModel required name="tapsSuspensionReason" #tapsSuspensionReason="ngModel" placeholder="Suspension Reason" maxlength="255"
                         matInput [disabled]="newSupplier.tapsSuspended == 'no'">
                      </mat-form-field>

                      <h3 style="display: flex;">
                        <span style="margin-right: 33px; padding-top: 1px;" matTooltip="Availability on TAPS" matTooltipClass="line-normal-tooltip">Available Members</span>
                        <mat-radio-group color="primary" style="display: flex;" ndModel name="tapsExcludeFlag" #tapsExcludeFlag="ngModel" [(ngModel)]="newSupplier.tapsExcludeFlag">
                          <mat-radio-button value="yes" [checked]="newSupplier.tapsExcludeFlag == 'yes'">All Excluding</mat-radio-button>
                          <mat-radio-button style="margin-left: 21%;" value="no" [checked]="newSupplier.tapsExcludeFlag == 'no'">Selected Only</mat-radio-button>
                        </mat-radio-group>
                      </h3>

                      <mat-form-field class="example-chip-list" [floatLabel]="'always'">
                        <mat-label>Member List</mat-label>
                        <mat-chip-list #chipListBranches aria-label="Member List">
                          <mat-chip *ngFor="let tradeCode of newSupplier.tapsTradeCodes" [selectable]="false" [removable]="true" (removed)="editTAPSsuppGroup(newSupplier, tradeCode, 'remove')">
                            {{tradeCode}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>

                          <mat-select ngModel placeholder="Add New" name="tradeCode" id="tradeCode" #tradeCode="ngModel" class="addNewChip"
                            (selectionChange)="editTAPSsuppGroup(newSupplier, $event.value, 'add')" (click)="filterBranch = ''; filterSelect('branch');">
                            <div class="sticky-search-container" style="margin-bottom: unset !important;">
                              <mat-form-field appearance="fill" class="filterSelectList">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                              </mat-form-field>
        
                              <div style="text-align: left; display: flex; justify-content: center;">
                                <button mat-button (click)="filterEOD()" class="sticky-button-search">
                                  <span *ngIf="!branchShowEOD">Include EOD Members</span>
                                  <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                                </button>
                              </div>
                            </div>

                            <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                              <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                            </mat-option>
                          </mat-select>

                        </mat-chip-list>
                      </mat-form-field>
                    </div>
    
                    <div *ngIf="['sinGSAdmin'].includes(userType)" class="availableToDiv" style="border-top: 1px solid rgb(219, 219, 219);">
                      <h3 style="display: flex;">
                        <span style="margin-right: 21px; padding-top: 1px;" matTooltip="Availability on Booking System" matTooltipClass="line-normal-tooltip">Supplier Available To</span>
                        <mat-radio-group style="display: flex;" [(ngModel)]="newSupplier.displayAvailability" name="displayAvailability" #displayAvailability="ngModel" color="primary">
                          <mat-radio-button value="everyone">Everyone</mat-radio-button>
                          <mat-radio-button style="margin-left: 6%;" value="membership">Membership</mat-radio-button>
                          <mat-radio-button style="margin-left: 6%;" value="selected">Selected Members</mat-radio-button>
                        </mat-radio-group>
                      </h3>
    
                      <mat-form-field *ngIf="newSupplier.displayAvailability == 'membership'" style="max-width: unset; width: 370px;">
                        <mat-select ngModel required name="company" #company="ngModel" placeholder="Membership">
                          <mat-option [value]="'gtg'">Global Members</mat-option>
                          <mat-option [value]="'tta'">TTA Members</mat-option>
                          <mat-option [value]="'ttng'">Worldchoice Members</mat-option>
                        </mat-select>
                      </mat-form-field>
    
                      <mat-form-field *ngIf="newSupplier.displayAvailability == 'selected'" class="example-chip-list-2" [floatLabel]="'always'">
                        <mat-label>Member List</mat-label>
                        <mat-chip-list #chipListBranches aria-label="Member List">
                          <mat-chip *ngFor="let tradeCode of newSupplier.tradeCodes" [selectable]="false" [removable]="true" (removed)="editSuppGroup(newSupplier, tradeCode, 'remove')">
                            {{tradeCode}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
    
                          <mat-select ngModel placeholder="Add New" name="tradeCode" id="tradeCode" #tradeCode="ngModel" class="addNewChip"
                            (selectionChange)="editSuppGroup(newSupplier, $event.value, 'add')" (click)="filterBranch = ''; filterSelect('branch');">
                            <div class="sticky-search-container" style="margin-bottom: unset !important;">
                              <mat-form-field appearance="fill" class="filterSelectList">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                              </mat-form-field>
        
                              <div style="text-align: left; display: flex; justify-content: center;">
                                <button mat-button (click)="filterEOD()" class="sticky-button-search">
                                  <span *ngIf="!branchShowEOD">Include EOD Members</span>
                                  <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                                </button>
                              </div>
                            </div>

                            <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                              <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                            </mat-option>
                          </mat-select>

                        </mat-chip-list>
                      </mat-form-field>
                    </div>
    
                    <button type="submit" class="boxSubmitButtons" color="primary" mat-raised-button [disabled]="createSupplierForm.invalid">
                      <mat-icon class="iconInButton">add</mat-icon>Create
                    </button>
                  </div>
                </form>
              </div>

              <div [@customExpansionDetails]="createSupplierBox == false ? 'expanded' : 'collapsed'"
                class="ownExpansionBodyEmpty" (click)="createSupplierBox = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="suppReqDiv">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Supplier Requests
            </h1>
            <mat-divider></mat-divider>

            <div style="display: flex;">
              <div style="display: flex;">
                <div>
                  <mat-form-field class="filterInput">
                    <input matInput type="text" (keyup)="doFilter($event, suppReqData)" id="filterSuppliers" placeholder="Filter Suppliers">
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field *ngIf="branchData.length > 1" class="branchEdit">
                <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentTradeCode" placeholder="Branch" (selectionChange)="changeGroupBranch($event.value)"
                    (click)="filterBranch = ''; filterSelect('branch');">
                    <div class="sticky-search-container" style="margin-bottom: unset !important;">
                      <mat-form-field appearance="fill" class="filterSelectList">
                        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                      </mat-form-field>

                      <div style="text-align: left; display: flex; justify-content: center;" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
                        <button mat-button (click)="filterEOD()" class="sticky-button-search">
                          <span *ngIf="!branchShowEOD">Include EOD Members</span>
                          <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                        </button>
                      </div>
                    </div>

                    <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                      <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                    </mat-option>
                  </mat-select>
              </mat-form-field>

              <div class="paginatorFooter">
                <div>
                  <button class="buttonToRight" color="primary" style="margin-right: 20px; margin-bottom: -7.5px;"
                          mat-icon-button (click)="exportToExcel('supReq')">
                        <mat-icon>file_download</mat-icon>
                  </button>
                </div>
                <div>
                  <mat-paginator #paginatorReq [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
            </div>

            <mat-table multiTemplateDataRows [dataSource]="suppReqData" style="clear: both;" *ngIf="pageLoaded">
              <ng-container matColumnDef="supplierName">
                <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                <mat-cell *matCellDef="let supReq"> {{ supReq.supplierNameM }} </mat-cell>
              </ng-container>

              <!--<ng-container matColumnDef="supplierType">
                <mat-header-cell *matHeaderCellDef class="centeredColumn"> Supplier Type </mat-header-cell>
                <mat-cell *matCellDef="let supReq" class="centeredColumn"> {{ supReq.supplierType }} </mat-cell>
              </ng-container>-->

              <ng-container matColumnDef="branchName" *ngIf="currentTradeCode === 'Q0000'">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Branch</mat-header-cell>
                <mat-cell *matCellDef="let supReq" class="centeredColumn" matTooltipClass="line-normal-tooltip" [matTooltip]="supReq.branchName"> {{ supReq.tradeCode }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="reference">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Reference</mat-header-cell>
                <mat-cell *matCellDef="let supReq" class="centeredColumn"> {{ supReq.reference }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="createTS">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Created</mat-header-cell>
                <mat-cell *matCellDef="let supReq" class="centeredColumn"> {{ supReq.createTS | date : "dd.MM.y hh:mm a" }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="completeTS">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Reviewed</mat-header-cell>
                <mat-cell *matCellDef="let supReq" class="centeredColumn"> {{ supReq.completeTS | date : "dd.MM.y hh:mm a" }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Status</mat-header-cell>
                <mat-cell *matCellDef="let supReq" class="centeredColumn">
                  <span *ngIf="supReq.status == 'Pending'" style="color: rgb(128,180,221); font-weight: bold;">{{ supReq.status }}</span>
                  <span *ngIf="supReq.status == 'Approved'" style="color: rgb(129,199,132); font-weight: bold;">{{ supReq.status }}</span>
                  <span *ngIf="supReq.status != 'Pending' && supReq.status != 'Approved'" style="color: rgb(244,67,54); font-weight: bold;">{{ supReq.status }}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="currentTradeCode === 'Q0000'">
                <mat-header-row *matHeaderRowDef="columnsReqAll"></mat-header-row>
                <mat-row *matRowDef="let supReq; let row; columns : columnsReqAll;" [ngClass]="'trueRow'" (click)="changeGroupBranch(supReq.tradeCode); openExistingReqDialog(supReq)"></mat-row>
              </ng-container>

              <ng-container *ngIf="currentTradeCode !== 'Q0000'">
                <mat-header-row *matHeaderRowDef="columnsReq"></mat-header-row>
                <mat-row *matRowDef="let supReq; let row; columns : columnsReq;" [ngClass]="'trueRow'" (click)="openExistingReqDialog(supReq)"></mat-row>
              </ng-container>
            </mat-table>
          </div>

          <div [@inAnimation] *ngIf="sfcListDiv">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>SFC Requests
            </h1>
            <mat-divider></mat-divider>

            <div style="display: flex;">
              <div style="display: flex;">
                <div>
                  <mat-form-field class="filterInput">
                    <input matInput type="text" (keyup)="doFilter($event, sfcData)" id="filterSuppliers" placeholder="Filter Suppliers">
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field *ngIf="branchData.length > 1" class="branchEdit">
                <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentTradeCode" placeholder="Branch" (selectionChange)="changeGroupBranch($event.value)"
                  (click)="filterBranch = ''; filterSelect('branch');">
                  <div class="sticky-search-container" style="margin-bottom: unset !important;">
                    <mat-form-field appearance="fill" class="filterSelectList">
                      <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterBranch" (keyup)="filterSelect('branch')">
                    </mat-form-field>

                    <div style="text-align: left; display: flex; justify-content: center;" *ngIf="['sinGSAdmin', 'tapsAdmin'].includes(userType)">
                      <button mat-button (click)="filterEOD()" class="sticky-button-search">
                        <span *ngIf="!branchShowEOD">Include EOD Members</span>
                        <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                      </button>
                    </div>
                  </div>

                  <mat-option *ngFor="let branch of branchDataFiltered" [value]="branch['tradeCode']" (click)="filterBranch = ''; filterSelect('branch');">
                    <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div style="display: flex; align-items: center;" *ngIf="currentTradeCode != 'Q0000' && memberLive">
                <button class="butt onToRight" color="primary" matTooltip="Request New SFC" matTooltipClass="line-normal-tooltip"
                        mat-stroked-button (click)="openRequestDialog('sfc')">
                        <mat-icon>add</mat-icon>
                </button>
              </div>

              <div class="paginatorFooter">
                <div>
                  <button class="buttonToRight" color="primary" style="margin-right: 20px; margin-bottom: -7.5px;"
                          mat-icon-button (click)="exportToExcel('sfc')">
                        <mat-icon>file_download</mat-icon>
                  </button>
                </div>
                <div>
                  <mat-paginator #paginatorSfc [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
            </div>

            <mat-table multiTemplateDataRows [dataSource]="sfcData" style="clear: both;" *ngIf="pageLoaded">
              <ng-container matColumnDef="supplierName">
                <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                <mat-cell *matCellDef="let sfc"> {{ sfc.supplierNameM }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="branchName" *ngIf="currentTradeCode === 'Q0000'">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Branch</mat-header-cell>
                <mat-cell *matCellDef="let sfc" class="centeredColumn" matTooltipClass="line-normal-tooltip" [matTooltip]="sfc.branchName"> {{ sfc.tradeCode }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="reference">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Reference</mat-header-cell>
                <mat-cell *matCellDef="let sfc" class="centeredColumn"> {{ sfc.reference }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="createTS">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Created</mat-header-cell>
                <mat-cell *matCellDef="let sfc" class="centeredColumn"> {{ sfc.createTS | date : "dd.MM.y hh:mm a" }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="completeTS">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Reviewed</mat-header-cell>
                <mat-cell *matCellDef="let sfc" class="centeredColumn"> {{ sfc.completeTS | date : "dd.MM.y hh:mm a" }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef class="centeredColumn">Status</mat-header-cell>
                <mat-cell *matCellDef="let sfc" class="centeredColumn">
                  <span *ngIf="sfc.status == 'Pending'" style="color: rgb(128,180,221); font-weight: bold;">{{ sfc.status }}</span>
                  <span *ngIf="sfc.status == 'Approved'" style="color: rgb(129,199,132); font-weight: bold;">{{ sfc.status }}</span>
                  <span *ngIf="sfc.status != 'Pending' && sfc.status != 'Approved'" style="color: rgb(244,67,54); font-weight: bold;">{{ sfc.status }}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="currentTradeCode === 'Q0000'">
                <mat-header-row *matHeaderRowDef="columnsSfcAll"></mat-header-row>
                <mat-row *matRowDef="let sfc; let row; columns : columnsSfcAll;" [ngClass]="'trueRow'" (click)="changeGroupBranch(sfc.tradeCode); openExistingSfcDialog(sfc)"></mat-row>
              </ng-container>

              <ng-container *ngIf="currentTradeCode !== 'Q0000'">
                <mat-header-row *matHeaderRowDef="columnsSfc"></mat-header-row>
                <mat-row *matRowDef="let sfc; let row; columns : columnsSfc;" [ngClass]="'trueRow'" (click)="openExistingSfcDialog(sfc)"></mat-row>
              </ng-container>
            </mat-table>
          </div>
        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>


<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p style="white-space: pre-line;">{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Suppliers</u></h1>
    <h2>Names & Types</h2>
    <p>One <b>Supplier Name</b> can hold many <b>Supplier Types</b>.<br>
      Supplier will be 'multi type' as long as each <b>type</b> will be entered separately.<br>
      For example: Jet2Holidays has two rows in the table, one for <i>Flight</i> and one for <i>Hotel</i>.<br>
    <p>

    <h2>External Names</h2>
    <p>
      The table contains a list of unique Supplier Names within SinGS.<br>
      These names are representing 'pseduo names' used in other systems.<br>
      For example: Jet2Holidays (SinGS) is linked to Jet2Holidays - Flight and Jet2Holidays - Hotel (iSell)</p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>

<ng-template #existingDialog>
  <div class="mainDiv">
    <div class="chosenDiv">
      
      <div class="row-wrapper">
        <div class="row">
          <div class="description">Supplier Name</div>
          <div>{{ selectedRequest.supplierNameM }}</div>
        </div>
        <div class="row">
          <div class="description">Member Name</div>
          <div>{{ selectedRequest.tradeCode }} {{ selectedRequest.branchName }}</div>
        </div>
      </div>

      <div class="row-wrapper">
        <div class="row">
          <div class="description">Reference</div>
          <div>{{ selectedRequest.reference }}</div>
        </div>
        <div class="row">
          <div class="description">Status</div>
          <div>{{ selectedRequest.status }}</div>
        </div>
      </div>

      <div class="row-wrapper">
        <div class="row">
          <div class="description">Requested Name</div>
          <div>{{ selectedRequest.requestedName }}</div>
        </div>
        <div class="row">
          <div class="description">Requested Email</div>
          <div>{{ selectedRequest.requestedEmail }}</div>
        </div>
      </div>

      <div class="row-wrapper">
        <div class="row">
          <div class="description">Created At</div>
          <div>{{ selectedRequest.createTS | date : "dd.MM.y hh:mm a" }}</div>
        </div>
        <div class="row">
          <div class="description">Completed At</div>
          <div>{{ selectedRequest.completeTS | date : "dd.MM.y hh:mm a" }}</div>
        </div>
      </div>

      <div class="row-wrapper" *ngIf="selectedType == 'sfc'">
        <div class="row">
          <div class="description" style="text-decoration: underline;">Supporting Documents</div>
          <div *ngFor="let sfcDoc of sfcDocumentation">
            <a (click)="openSfcDoc(sfcDoc)">{{sfcDoc.name}}</a>
          </div>
        </div>

        <div class="row" *ngIf="selectedType == 'sfc'">
          <div class="description">
            <button mat-button class="sfcInfoBtn" type="button" (click)="infoOpenClose()" [disableRipple]="true">
              Additional Information
              <mat-icon *ngIf="!sfcInfoOpen">expand_more</mat-icon>
              <mat-icon *ngIf="sfcInfoOpen">expand_less</mat-icon>
            </button>
          </div>
          <div></div>
        </div>
      </div>

      <div *ngIf="sfcInfoOpen">
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Trading Name</div>
            <div>{{ selectedRequest.tradingName }}</div>
          </div>
          <div class="row">
            <div class="description">Address Line 1</div>
            <div>{{ selectedRequest.address1 }}</div>
          </div>
          <div class="row">
            <div class="description">Website</div>
            <div>{{ selectedRequest.website }}</div>
          </div>
        </div>
  
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Contact Name</div>
            <div>{{ selectedRequest.contactName }}</div>
          </div>
          <div class="row">
            <div class="description">Contact Role</div>
            <div>{{ selectedRequest.contactRole }}</div>
          </div>
        </div>
  
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Email Address</div>
            <div>{{ selectedRequest.email }}</div>
          </div>
          <div class="row">
            <div class="description">Telephone</div>
            <div>{{ selectedRequest.telephone }}</div>
          </div>
        </div>
  
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Bank Name</div>
            <div>{{ selectedRequest.bankName }}</div>
          </div>
          <div class="row">
            <div class="description">Account Name</div>
            <div>{{ selectedRequest.accountName }}</div>
          </div>
        </div>
  
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Account Number</div>
            <div>{{ selectedRequest.accountNo }}</div>
          </div>
          <div class="row">
            <div class="description">Sort Code</div>
            <div>{{ selectedRequest.sortCode }}</div>
          </div>
        </div>
  
        <div class="row-wrapper">
          <div class="row">
            <div class="description">IBAN</div>
            <div>{{ selectedRequest.iban }}</div>
          </div>
          <div class="row">
            <div class="description">SWIFT/BIC</div>
            <div>{{ selectedRequest.swift }}</div>
          </div>
          <div class="row">
            <div class="description">Currency</div>
            <div>{{ selectedRequest.currency }}</div>
          </div>
        </div>
      </div>

      <div style="margin-top: 20px; margin-bottom: -20px;" *ngIf="selectedRequest.status == 'Pending' && userType == 'sinGSAdmin'">
        <mat-form-field style="max-width: unset; width: 100%;">
          <input matInput ngModel placeholder="Comments" name="comments" #comments="ngModel" [(ngModel)]="selectedRequest.comments">
        </mat-form-field>

        <button class="buttonToRight requestButton" mat-button [disableRipple]="true" color="primary" (click)="updateRequestStatus(selectedRequest, 'Approved')" matDialogClose>
          <mat-icon>done</mat-icon> Accept
        </button>

        <button class="buttonToRight requestButton" mat-button [disableRipple]="true" color="warn" (click)="updateRequestStatus(selectedRequest, 'Rejected')" matDialogClose>
          <mat-icon>close</mat-icon> Reject
        </button>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #requestDialog>
  <app-request-supplier [requestMode]="requestMode" [requestSource]="'Booking System'" 
  [requestCompany]="currentCompany" [requestOperation]="currentOperation" [requestTradeCode]="currentTradeCode" (sfcReqDone)="reloadRequests()"></app-request-supplier>
</ng-template>

<ng-template #bankingExternalBox style="padding: 0px !important;">
  <app-bank-accounts [ownerCode]="expandedElement.supplierNameM" [ownerType]="'supplier'"></app-bank-accounts>
</ng-template>

<ng-template #contactExternalBox style="padding: 0px !important;">
  <app-contacts (notifyParent)="loadPage()" [ownerName]="expandedElement.supplierNameM" [ownerType]="'supplier'"></app-contacts>
</ng-template>