import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  async getCustomer(customer: any): Promise<object> {
    let url = environment.apiURL + 'customer?tradeCode=' + customer.tradeCode + '&email=' + customer.email + '&lastName=' +
    encodeURIComponent(customer.lastName) + '&telNo=' + customer.telNo + '&token=' + customer.token;

    if (customer.exclusiveToId) { url += '&exclusiveToId=' + customer.exclusiveToId; }

    return this.apiService.makeHttpRequest('get', url);
  }

  async getCustomerListFiltered(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'getCustomerListFiltered', body, { headers }).toPromise();
    return output;
  }

  async getPostcodeAddress(request: any): Promise<object> {
    const output = await this.http.post('https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=' + environment.postCodeKey + '&Text=' +
    request.postcode + '&IsMiddleware=false&Container=' + request.container + '&Origin=&Countries=GBR&Language=en-gb', request).toPromise();
    return output;
  }

  async updateCustomer(customer: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(customer);
    const output = await this.http.post(environment.apiURL + 'updateCustomer', body, { headers }).toPromise();
    return output;
  }

  async removeCustomer(customer: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(customer);
    const output = await this.http.post(environment.apiURL + 'removeCustomer', body, { headers }).toPromise();
    return output;
  }

  async createCustomer(customer: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(customer);
    const output = await this.http.post(environment.apiURL + 'createCustomer', body, { headers }).toPromise();
    return output;
  }

  async createBookCustLink(customer: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(customer);
    const output = await this.http.post(environment.apiURL + 'createBookCustLink', body, { headers }).toPromise();
    return output;
  }

  async getBookCustList(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'getBookCustList', body, { headers }).toPromise();
    return output;
  }

  async getBookCustCompanions(request: any): Promise<object> {
    let url = environment.apiURL + 'getBookCustCompanions?company=' + request.company + '&operation=' + request.operation +
    '&tradeCode=' + request.tradeCode + '&customerID=' + request.customerID + '&token=' + request.token;

    if (request.exclusiveToId) { url += '&exclusiveToId=' + request.exclusiveToId; }

    return await this.http.get(url).toPromise();
  }

  async updateIsLead(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'updateIsLead', body, { headers }).toPromise();
    return output;
  }

  async changePaxActive(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'changePaxActive', body, { headers }).toPromise();
    return output;
  }

  async changeShowOnDocs(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'changeShowOnDocs', body, { headers }).toPromise();
    return output;
  }

  async removeBookCustLink(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'removeBookCustLink', body, { headers }).toPromise();
    return output;
  }

  async updateGhostBookCust(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'updateGhostBookCust', body, { headers }).toPromise();
    return output;
  }

  async validateCustCSV(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'validateCustCSV', body, { headers }).toPromise();
    return output;
  }

  async uploadCustCSVasAoA(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'uploadCustCSVasAoA', body, { headers }).toPromise();
    return output;
  }
}
