import { Component, OnInit, Input, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { BranchService } from '../../services/branch.service';
import { UserService } from '../../services/user.service';
import { GlobalConstants } from '../../common/global-constants';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class ContactsComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;
  memberLive = AppComponent.myapp.memberLive;

  // Variables controlling user access
  pageLoaded = false;

  // List of the table columns which are displayed in .html file
  displayedColumns = ['contactType', 'contactName', 'emailAddress', 'phoneNumber1', 'phoneNumber2', 'faxNumber', 'active'];

  // Data will be dumped into this varaible below
  contactData: any = new MatTableDataSource<any>();

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  newContact: any = {};

  // Variables taken from the child component
  @Input() ownerName: any;
  @Input() ownerType: any;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(public dialog: MatDialog, private userService: UserService, private branchService: BranchService) { }

  ngOnInit(): void {
    this.getContactDetails(); // Initial load of all bank accounts for a supplier / branch
    this.resetNewContact(); // Set the new account 'form' fields
  }

  getContactDetails(): void {
    const request = { ownerName: this.ownerName, ownerType: this.ownerType, token: Session.mySession.get('user').token };
    this.contactData.data = [];
    this.pageLoaded = false; // Create a request variable and set as 'loading..'

    this.branchService.getContactDetails(request).then((contactDetails: any) => {
      if (contactDetails.status === 'OK') {
        this.contactData.data = contactDetails.data;
        this.pageLoaded = true; // Looks like everything went well - stop 'loading..' and assign data to a var.
      } else {
        this.sendMessageToDialog('', contactDetails.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2701S)', error, request);
    });
  }

  createContactDetails(): void {
    if (!this.newContact.contactName || !this.newContact.emailAddress) {
      this.sendMessageToDialog('', 'Contact Name and Email Address are mandatory', '', '');
    } else if (!this.newContact.emailAddress.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.sendMessageToDialog('', 'Please enter a valid email address', '', '');
    } else {
      const request: any = Object.assign({}, this.newContact);
      request.token = Session.mySession.get('user').token;

      this.pageLoaded = false; // Duplicate object and use it to create new account

      this.branchService.createContactDetails(request).then((output: any) => {
        if (output.status === 'OK') {
          // Reset new account fields and reload account list
          this.resetNewContact(); this.getContactDetails();
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2702S)', error, request);
      });
    }
  }

  updateContactDetails(contactDt: any, active: any): void {
    if (!contactDt.contactName || !contactDt.emailAddress) {
      this.sendMessageToDialog('', 'Contact Name and Email Address are mandatory', '', '');
    } else if (!contactDt.emailAddress.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.sendMessageToDialog('', 'Please enter a valid email address', '', '');
    } else {
      const request: any = Object.assign({}, contactDt);
      request.token = Session.mySession.get('user').token;
      this.pageLoaded = false; // Duplicate object and change its active state..

      if (active === 'yes' || active === 'no') { request.active = active; }
      else { request.active = contactDt.active; }

      this.branchService.updateContactDetails(request).then((output: any) => {
        if (output.status === 'OK') {
          this.getContactDetails(); // Reload contacts..
          if (active === 'yes' || active === 'no') { this.notifyParent.emit(); }
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2703S)', error, request);
      });
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  resetNewContact(): void {
    this.newContact = { ownerName: this.ownerName, ownerType: this.ownerType, contactType: 'commercial',
                        contactName: '', emailAddress: '', phoneNumber1: '', phoneNumber2: '', faxNumber: '' };
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}
