<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('edit')" mat-button [disabled]="editView">
          <mat-icon>list</mat-icon> Group List
        </button>
        <button class="navButtons" (click)="switchView('create')" mat-button [disabled]="createView">
          <mat-icon>add</mat-icon> Create Group
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('edit')" mat-button [disabled]="editView">
          <mat-icon>list</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('create')" mat-button [disabled]="createView">
          <mat-icon>add</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>


  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed>

    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="haveAccess">
          <div [@inAnimation] *ngIf="editView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Group List
            </h1>
            <mat-divider></mat-divider>

            <mat-form-field style="padding-top: 2px; min-width: unset; max-width: 400px; width: calc(98% - 515px) !important; float: left; margin-left: 22.5px;">
              <input matInput type="text" (keyup)="doFilter($event)" id="filterUsers" placeholder="Filter Groups">
            </mat-form-field>

            <div class="paginatorFooter">
              <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>

            <mat-table multiTemplateDataRows [dataSource]="userGroups">
              <ng-container matColumnDef="groupName">
                <mat-header-cell *matHeaderCellDef> Group Name </mat-header-cell>
                <mat-cell *matCellDef="let group"> {{ group.groupName }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
                <mat-cell *matCellDef="let group"> {{ group.active | titlecase }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expand">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let group">
                  <mat-icon>expand_more</mat-icon>
                </mat-cell>
              </ng-container>

              <!-- Expansion text -->
              <ng-container matColumnDef="expandedDetail" class="expandedDetail">
                <mat-cell class="expandedDetail" *matCellDef="let group" [attr.colspan]="3">
                  <form [@inOutAnimation] *ngIf="group == expandedElement" ngNativeValidate #editUserForm="ngForm">
                    <mat-form-field class="example-chip-list" [floatLabel]="'always'">
                      <mat-label>Member List</mat-label>
                      <mat-chip-list #chipList aria-label="Member List">
                        <mat-chip *ngFor="let tradeCode of group.tradeCodes" [selectable]="false" [removable]="true" (removed)="editGroup(group, tradeCode, 'remove')">
                          {{tradeCode}}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>

                        <mat-select ngModel required placeholder="Add New" name="tradeCode" id="tradeCode" #tradeCode="ngModel" class="customSelect"
                          (selectionChange)="editGroup(group, $event.value, 'add')" (click)="filterString = ''; filterSelect()">
                          
                          <div class="sticky-search-container" style="margin-bottom: unset !important;">
                            <mat-form-field appearance="fill" class="filterSelectList">
                              <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                            </mat-form-field>

                            <div style="text-align: left; display: flex; justify-content: center;">
                              <button mat-button (click)="filterEOD()" class="sticky-button-search">
                                <span *ngIf="!branchShowEOD">Include EOD Members</span>
                                <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                              </button>
                            </div>
                          </div>

                          <mat-option *ngFor="let branch of filteredBranches" [value]="branch['tradeCode']" (click)="filterString = ''; filterSelect();">
                            <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-chip-list>
                    </mat-form-field>

                    <h5><i><u>Please note:</u> You can only group branches with the same <b>membership type</b>.</i></h5>

                    <button class="buttons" *ngIf="group.active == 'yes'" mat-raised-button color="primary" type="button" (click)="editGroup(group, 'no', 'activate')">
                      <mat-icon class="iconInButton">lock</mat-icon>Block
                    </button>
                    <button class="buttons" *ngIf="group.active == 'no'" mat-raised-button color="primary" type="button" (click)="editGroup(group, 'yes', 'activate')">
                      <mat-icon class="iconInButton">lock_open</mat-icon>Unblock
                    </button>
                    <button class="buttons" *ngIf="userEmail == 'greg@gmail.com'" mat-raised-button color="warn" type="button" (click)="removeGroup(group)">
                      <mat-icon class="iconInButton">delete</mat-icon>Remove
                    </button>
                  </form>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns : displayedColumns;" class="trueRow " (click)="expandedElement = expandedElement === row ? null : row;"></mat-row>
              <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
            </mat-table>
          </div>

          <div [@inAnimation] *ngIf="createView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Create User
            </h1>
            <mat-divider></mat-divider>

            <form ngNativeValidate #createGroupForm="ngForm" (ngSubmit)="createGroup(createGroupForm)">
              <div class="withinMatCard">
                <h5><i>Please fill in all mandatory fields</i></h5>
                <h3>Group Information</h3>
                <mat-form-field>
                  <input matInput ngModel required placeholder="Group Name" maxlength="50" pattern="[a-zA-z0-9 ]*" name="groupName" #groupName="ngModel">
                </mat-form-field>

                <mat-form-field>
                  <mat-select ngModel required name="active" #active="ngModel" placeholder="Active">
                    <mat-option [value]="'no'">No</mat-option>
                    <mat-option [value]="'yes'">Yes</mat-option>
                  </mat-select>
                </mat-form-field>

                <p></p>

                <mat-form-field class="example-chip-list" [floatLabel]="'always'">
                  <mat-label>Member List</mat-label>
                  <mat-chip-list #chipList aria-label="Member List" style="height: 50px;">
                    <mat-chip *ngFor="let tradeCode of newBranches" [selectable]="false" [removable]="true" (removed)="editNewGroup(tradeCode, 'remove')">
                      {{tradeCode}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <mat-select ngModel required placeholder="Add New" name="tradeCode" id="tradeCode" #tradeCode="ngModel" class="customSelect"
                      (selectionChange)="editNewGroup($event.value, 'add')" (click)="filterString = ''; filterSelect()">
                      <div class="sticky-search-container" style="margin-bottom: unset !important;">
                        <mat-form-field appearance="fill" class="filterSelectList">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                        </mat-form-field>
  
                        <div style="text-align: left; display: flex; justify-content: center;">
                          <button mat-button (click)="filterEOD()" class="sticky-button-search">
                            <span *ngIf="!branchShowEOD">Include EOD Members</span>
                            <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                          </button>
                        </div>
                      </div>
                      
                      <mat-option *ngFor="let branch of filteredBranches" [value]="branch" (click)="filterString = ''; filterSelect();">
                        <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                      </mat-option>
                    </mat-select>
                  </mat-chip-list>
                </mat-form-field>

                <h5><i><u>Please note:</u> You can only group branches with the same <b>membership type</b>.</i></h5>

                <button type="submit" class="buttons" mat-raised-button color="accent">
                  <mat-icon class="iconInButton">add</mat-icon>Create
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>User Groups</u></h1>
    <p>This page allows you to manage User Groups.</p>

    <p>User Groups allow to perform operations in multiple branches from one account.</p>

    <p>In order to do so, you need to create a group and then assign user(s) to it.</p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
