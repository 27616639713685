import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private http: HttpClient) { }

  async getBranches(user: any): Promise<object> {
    let output = null;
    if (user.userType === 'sinGSAdmin' || user.userType === 'sinGSstaff' || user.userType === 'trustee' || user.userType === 'tapsAdmin' ) {
      output = await this.http.get(environment.apiURL + 'branches/tradecode=%25&token=' + user.token).toPromise();
    } else {
      output = await this.http.get(environment.apiURL + 'branches/tradecode=' + user.tradeCode + '&token=' + user.token).toPromise();
    }
    return output;
  }

  async getBranch(user: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'branchData/tradecode=' + user.tradeCode + '/token=' + user.token).toPromise();
    return output;
  }

  async updateBranch(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'updateBranch', body, { headers }).toPromise();
    return output;
  }

  async removeBranch(branch: any): Promise<object> {
    const output = await this.http.post(environment.apiURL + 'branchDeleteData?tradeCode=' + branch.tradeCode + '&token=' + branch.token, branch).pipe(map(data => data)).toPromise();
    return output;
  }

  async createBranch(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'createBranch', body, { headers }).toPromise();
    return output;
  }

  async updateBranchMedia(request: any): Promise<object> {
    const formData = new FormData();
    formData.append('file', request.file, request.fileName);
    const output = await this.http.post(environment.apiURL + 'uploadBranchImage?type=' + request.whatFile + '&token=' + request.token, formData).toPromise();
    return output;
  }

  /* User Groups below */

  async getUserGroupList(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getUserGroupList?token=' + request.token).toPromise();
    return output;
  }

  async getUserGroup(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getUserGroup?groupName=' + request.groupName + '&token=' + request.token).toPromise();
    return output;
  }

  async createUserGroup(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'createUserGroup', body, { headers }).toPromise();
    return output;
  }

  async editUserGroup(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'editUserGroup', body, { headers }).toPromise();
    return output;
  }

  async removeUserGroup(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'removeUserGroup', body, { headers }).toPromise();
    return output;
  }

  /* Bank Accounts below */
  async getBankAccounts(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getBankAccounts?ownerCode=' + encodeURIComponent(request.ownerCode) +
    '&ownerType=' + request.ownerType + '&token=' + request.token).toPromise();
    return output;
  }

  async createBankAccount(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'createBankAccount', body, { headers }).toPromise();
    return output;
  }

  async updateBankAccount(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'updateBankAccount', body, { headers }).toPromise();
    return output;
  }

  /* Contact Details below */
  async getContactDetails(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getContactDetails?ownerName=' + encodeURIComponent(request.ownerName) +
    '&ownerType=' + request.ownerType + '&token=' + request.token).toPromise();
    return output;
  }

  async createContactDetails(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'createContactDetails', body, { headers }).toPromise();
    return output;
  }

  async updateContactDetails(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'updateContactDetails', body, { headers }).toPromise();
    return output;
  }

  /* Trading names below */
  async getTradingNames(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getTradingNames?company=' + request.company + '&operation=' + request.operation +
      '&tradeCode=' + request.tradeCode + '&token=' + request.token).toPromise();
    return output;
  }

  async addTradingName(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'addTradingName', body, { headers }).toPromise();
    return output;
  }

  async amendTradingName(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'amendTradingName', body, { headers }).toPromise();
    return output;
  }

    /* Felloh configs below */
    async getFellohConfig(request: any): Promise<object> {
      const output = await this.http.get(environment.apiURL + 'getFellohConfig?company=' + request.company + '&operation=' + request.operation +
        '&tradeCode=' + request.tradeCode + '&token=' + request.token).toPromise();
      return output;
    }
  
    async addFellohConfig(request: any): Promise<object> {
      const headers = { 'content-type': 'application/json' };
      const body = JSON.stringify(request);
      const output = this.http.post(environment.apiURL + 'addFellohConfig', body, { headers }).toPromise();
      return output;
    }
  
    async amendFellohConfig(request: any): Promise<object> {
      const headers = { 'content-type': 'application/json' };
      const body = JSON.stringify(request);
      const output = this.http.post(environment.apiURL + 'amendFellohConfig', body, { headers }).toPromise();
      return output;
    }
}
