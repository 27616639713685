import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ElementService {

  constructor(private http: HttpClient) { }

  async createElement(element: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(element);
    const output = await this.http.post(environment.apiURL + 'createElement', body, { headers }).toPromise();
    return output;
  }

  async getAirportCodesAPI(airCode: any): Promise<object> {
    const output = await this.http.get('http://airlabs.co/api/v6/airports?api_key=49007a83-4ccd-4010-872b-0c719e9f3012&name=' + airCode).toPromise();
    return output;
  }

  async updateElement(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'updateElementSeq', body, { headers }).toPromise();
    return output;
  }
}
