import { Injectable } from '@angular/core';
import { Session } from './session';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DialogService } from './custom-dialog';

const MAX_INTERVAL = 60000; // 1 minute
const INI_INTERVAL = 6000; // 6 seconds
const INC_INTERVAL = 2000; // 2 second

@Injectable()
export class AsynchRequests {
    static myAsynchReq: any; // Variable used in all components which 'saves' current data
    private intervalIds: { [jobId: string]: number } = {};

    currentJobs: any = []; // This is where we'll dump jobs we''ll be watching

    // Add a new job to the array which will be 'watched'
    public addJobToWatch(request: any, jobId: any): void {
        const jobToAdd = {
            job: { id: jobId, company: request.company, operation: request.operation, tradeCode: request.tradeCode, userGroup: request.userGroup, hideDialog: request.hideDialog },
            interval: INI_INTERVAL
        };
        this.currentJobs.push(jobToAdd); // We're adding above job to our array
        // Additionally, we're saving it in session manager (in case user refreshes the page)
        Session.mySession.set('currentJobs', this.currentJobs);
        this.startInterval(jobToAdd); // Start the interval of selected job
    }

    startAllIntervals(): void {
        this.currentJobs?.forEach((currJob: any) => {
            this.startInterval(currJob);
        });
    }

    stopAllIntervals(): void {
        // tslint:disable-next-line:forin
        for (const jobId in this.intervalIds) {
            this.stopInterval(jobId);
        }
    }

    startInterval(currJob: any): void {
        const intervalFunction = () => {
            // A method which will call /getJobStatus API non-stop until agreed status is returned
            this.getJobStatus(currJob.job).then((res: any) => {
                // The job is still running, but the interval can still be increased
                if (res?.data?.status === 'running' && currJob.interval < MAX_INTERVAL) {
                    currJob.interval += INC_INTERVAL; // Increase the interval by INC_INTERVAL
                    Session.mySession.set('currentJobs', this.currentJobs); // Save the new internal in session var
                    this.stopInterval(currJob.job.id); // We're stopping the interval and starting new one with a new delay / wait
                    this.intervalIds[currJob.job.id] = Number(setInterval(intervalFunction, currJob.interval));
                } else if (res?.data?.status !== 'running') {
                    // The job is not running anymore - we need to filter it out from the array list..
                    const filteredJobs = this.currentJobs.filter((job: any) => job.job.id !== currJob.job.id);
                    // Make sure the session is cleared out, and 'currentJobs' array updated accordingly
                    this.currentJobs = filteredJobs; Session.mySession.set('currentJobs', filteredJobs);
                    // Stop the interval compeletely
                    this.stopInterval(currJob.job.id);
                    // Return message back to the user
                    if (res?.data?.status === 'OK') { if (currJob.job.hideDialog !== true) { this.dialogService.openMessageDialog(res?.data?.description + ' completed'); } }
                    else if (res?.status === 'Argument issue') { this.dialogService.openMessageDialog('We were unable to verify whether the job was completed this time'); }
                    else { this.dialogService.openMessageDialog(res?.data?.description + ' returned with ' + res?.data?.status); }
                }
            });
        };
        // We're calling above method over here. We're also adding Interval ID to the array (so we can stop it)
        const intervalId = setInterval(intervalFunction, currJob.interval);
        this.intervalIds[currJob.job.id] = Number(intervalId);
    }

    stopInterval(jobId: string): void {
        const intervalId = this.intervalIds[jobId];
        if (intervalId) {
            clearInterval(intervalId);
            delete this.intervalIds[jobId];
        }
    }

    async getJobStatus(jobRequest: any): Promise<object> {
        let apiURL = ''; // To be filled in below..
        // Append finished url below - it will differ depending on what's been entered
        if (jobRequest.tradeCode === 'groupAll') {
            apiURL = environment.apiURL + 'getJobStatus?company=' + jobRequest.company + '&operation=' +
                    jobRequest.operation + '&userGroup=' + encodeURIComponent(jobRequest.userGroup) + '&id=' + jobRequest.id;
        } else {
            apiURL = environment.apiURL + 'getJobStatus?company=' + jobRequest.company + '&operation=' +
                    jobRequest.operation + '&tradeCode=' + jobRequest.tradeCode + '&id=' + jobRequest.id;
        }
        const output = await this.http.get(apiURL).toPromise();
        return output;
    }

    // Initialise mySession variable (starts at the beginning of the app)
    public initialise(): void {
        AsynchRequests.myAsynchReq = this;
        // Retrieve current jobs stored in session
        const sessionJobs = Session.mySession.get('currentJobs');
        if (sessionJobs) { this.currentJobs = sessionJobs; }
        this.startAllIntervals(); // Start all intervals (if they exist..)
    }

    constructor(private http: HttpClient, private dialogService: DialogService) {
        AsynchRequests.myAsynchReq = this;
    }
}
