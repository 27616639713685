<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('all')" mat-button [disabled]="allUsersView">
          <mat-icon>list</mat-icon> Customer List
        </button>
        <button class="navButtons" *ngIf="['memberManager', 'memberStaff', 'wcMember', 'wcManager'].includes(userType) && memberLive"
          (click)="switchView('create')" mat-button [disabled]="createView">
          <mat-icon>person_add</mat-icon> Add Customer
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('all')" mat-button [disabled]="allUsersView">
          <mat-icon>list</mat-icon>
        </button>
        <button class="navButtons" *ngIf="['memberManager', 'memberStaff', 'wcMember', 'wcManager'].includes(userType) && memberLive"
          (click)="switchView('create')" mat-button [disabled]="createView">
          <mat-icon>person_add</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div class="sideNavContent">

          <div [@inAnimation] *ngIf="createView && haveAccess">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Add Customer
            </h1>
            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="addCustomer"
                    (click)="addCustomer = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!addCustomer"
                    (click)="addCustomer = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Create Customer
                </div>
              </div>

              <div [@customExpansionDetails]="addCustomer == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <h5><i>Fill in all fields marked with an asterisk - you can come back to the Customer and do the rest at
                    any time.</i></h5>
                <form ngNativeValidate class="addCustomer" #createCustomerForm="ngForm"
                  (ngSubmit)="createCustomer(createCustomerForm)">
                  <h3>Customer Name</h3>
                  <mat-form-field>
                    <mat-select placeholder="Title" ngModel name="title" #title="ngModel">
                      <mat-option *ngFor="let title of titles" [value]="title['value']">
                        {{title['value']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="First Name" matInput ngModel required name="firstName" #firstName="ngModel"
                      maxlength="50">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Middle Name" matInput ngModel name="middleName" #middleName="ngModel"
                      maxlength="50">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Last Name" matInput required ngModel name="lastName" #lastName="ngModel"
                      maxlength="50">
                  </mat-form-field>

                  <h3>Address</h3>
                  <mat-form-field>
                    <mat-autocomplete #auto="matAutocomplete" style="margin-top: -25px;" [panelWidth]="'auto'"
                      (optionSelected)='selectPostcode($event.option.value, "new", "")'>
                      <mat-option *ngFor="let address of addressList; let i = index" [value]="i">
                        {{ address }}
                      </mat-option>
                    </mat-autocomplete>
                    <input placeholder="Post Code" matInput id="postCode" ngModel name="postcode" #postcode="ngModel"
                      maxlength="10" (keyup)="postCodeLookup($event)" [matAutocomplete]="auto">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Address Line 1" [(ngModel)]="addressLookup.address1" matInput ngModel
                      name="addressLine1" #addressLine1="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Address Line 2" [(ngModel)]="addressLookup.address2" matInput ngModel
                      name="addressLine2" #addressLine2="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Address Line 3" [(ngModel)]="addressLookup.address3" matInput ngModel
                      name="addressLine3" #addressLine3="ngModel" maxlength="50">
                  </mat-form-field>

                  <br>

                  <mat-form-field>
                    <input placeholder="Address Line 4" [(ngModel)]="addressLookup.address4" matInput ngModel
                      name="addressLine4" #addressLine4="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="County" [(ngModel)]="addressLookup.countyLine" matInput ngModel name="county"
                      #county="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-select placeholder="Country" ngModel [(ngModel)]="addressLookup.countryLine" name="country"
                      #country="ngModel">
                      <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel"
                          [(ngModel)]="filterString" (keyup)="filterSelect()">
                      </mat-form-field>
                      <mat-option *ngFor="let country of countryFiltered" [value]="country"
                        (click)="filterString = ''; filterSelect();">
                        {{country}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <h3>DOB & Contact Details</h3>
                  <mat-form-field style="max-width: 180px;">
                    <input placeholder="Date of Birth" ngModel matInput name="dob" [matDatepicker]="dob"
                      (dateChange)="valiDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                    <mat-datepicker #dob></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Mobile Number" matInput ngModel name="telNo" #telNo="ngModel" type="tel" pattern="[0-9 ]+"
                      maxlength="15">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Home Number" matInput ngModel name="homeNo" #homeNo="ngModel" type="tel" pattern="[0-9 ]+"
                      maxlength="15">
                  </mat-form-field>

                  <mat-form-field>
                    <input placeholder="Email Address" matInput ngModel name="email" #email="ngModel" maxlength="100"
                    pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                  </mat-form-field>

                  <h3>Holiday Interests</h3>

                  <div class="checkboxContainer">
                    <div class="checkboxElement" *ngFor="let interest of holidayInterests">
                      <label>
                        <input type="checkbox" (change)="addRemoveHolInterest($event)"
                            [checked]="selHolidayInterests.indexOf(interest.value) > -1"
                            value="{{interest.value}}">
                            {{interest.viewValue}}
                    </label>
                    </div>
                  </div>

                  <h3>Company Name & Notes</h3>

                  <mat-form-field>
                    <input placeholder="Company Name" matInput ngModel name="companyName" #companyName="ngModel"
                      maxlength="100">
                  </mat-form-field>

                  <mat-form-field style="width: 560px; max-width: unset;">
                    <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500" cdkTextareaAutosize
                      ngModel name="customerNotes" #customerNotes="ngModel" #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" placeholder="Customer Notes"></textarea>
                  </mat-form-field>

                  <span *ngIf="branches.length > 1">
                    <h3>Branch Selection</h3>
                    <mat-form-field class="branchEdit" style="max-width: unset; width: 322.5px;">
                      <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentRequest.tradeCode"
                        placeholder="Branch">
                        <mat-option *ngFor="let branch of branches" [value]="branch.tradeCode">
                          {{branch.fullName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>

                  <input *ngIf="branches.length == 1" type="hidden" ngModel #tradeCode="ngModel" name="tradeCode"
                    [(ngModel)]="branches[0]['tradeCode']" />

                  <br>

                  <h5 *ngIf="showDupliBox">
                    <mat-checkbox [checked]="createConfirm" color="accent" (change)="createConfirm = !createConfirm">
                      It seems like this customer already exist. Do you want to create it anyway?
                    </mat-checkbox>
                  </h5>

                  <button type="submit" mat-raised-button color="accent" class="buttons"
                    [disabled]="!createConfirm || createCustomerForm.invalid">
                    <mat-icon class="iconInButton">add</mat-icon>Create
                  </button>
                </form>
              </div>

              <div [@customExpansionDetails]="addCustomer == false ? 'expanded' : 'collapsed'"
                class="ownExpansionBodyEmpty" (click)="addCustomer = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 20px;" *ngIf="userType == 'wcManager' || userType == 'memberManager'">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="uploadCustomers"
                    (click)="uploadCustomers = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!uploadCustomers"
                    (click)="uploadCustomers = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Upload Customer Data
                </div>
              </div>

              <div [@customExpansionDetails]="uploadCustomers == true ? 'expanded' : 'collapsed'"
                class="ownExpansionBody">
                <button color="primary" mat-raised-button (click)="getCSVtemplate()"
                  style="margin-top: 15px; width: 155px;">
                  <mat-icon class="iconInButton">file_download</mat-icon>
                  Get Template
                </button>

                <div *ngIf="branches.length > 1">
                  <h5 style="margin-top: 15px; margin-bottom: 20px;">
                    <i>Before doing anyting else, please select the branch you would like to import customers to.</i>
                  </h5>
                  <mat-form-field class="branchEdit" style="max-width: unset; width: 322.5px;">
                    <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentRequest.tradeCode"
                      placeholder="Branch">
                      <mat-option *ngFor="let branch of branches" [value]="branch.tradeCode">
                        {{branch.fullName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <br>
                </div>

                <h5 style="margin-top: 15px; margin-bottom: 20px;">
                  <i>We need to validate your file. Click the button and choose your file - we'll let you know
                    if there are any problems.<br>
                    <u>Please remember:</u> Each customer MUST have a unique email address.</i>
                </h5>

                <button mat-raised-button type="button" (click)="csvInput1.click()" color="primary"
                  style="width: 155px;">
                  <mat-icon class="iconInButton">fact_check</mat-icon>Validate File
                </button>
                <br>
                <input matInput ngModel placeholder="File Name" name="uploadedFile" #uploadedFile="ngModel"
                  maxlength="50" [(ngModel)]="uploadedFileName" readonly="true" style="display: none;">
                <input #csvInput1 hidden="true" type="file" onclick="this.value=null"
                  (change)="validateCustomerFile($event)" accept=".csv" multiple="false" />

                <h5 style="margin-top: 15px; margin-bottom: 20px;"><i>If the file was validated, a button will appear
                    below. Press it to load validated customer data.</i></h5>
                <button *ngIf="csvCustomerData.length > 0" color="accent"
                  style="margin-top: -10px; margin-bottom: 15px; width: 155px;" mat-raised-button
                  (click)="uploadCustomerData()">
                  <mat-icon class="iconInButton">file_upload</mat-icon>
                  Upload Data
                </button>
              </div>

              <div [@customExpansionDetails]="uploadCustomers == false ? 'expanded' : 'collapsed'"
                class="ownExpansionBodyEmpty" (click)="uploadCustomers = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="allUsersView && haveAccess">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Customer List
            </h1>
            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionSearch"
                    (click)="expansionSearch = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionSearch"
                    (click)="expansionSearch = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Search Criteria
                </div>
              </div>

              <div [@customExpansionDetails]="expansionSearch == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <form ngNativeValidate #getCustomerListForm="ngForm" (ngSubmit)="getCustomers(getCustomerListForm)" style="margin-top: 4px; margin-bottom: -11px;">

                  <mat-form-field class="branchEdit" *ngIf="branches.length > 1" style="max-width: unset; width: 322.5px; margin-bottom: -15px;">
                    <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentRequest.tradeCode" placeholder="Branch" (click)="branchString = ''; filterBranches()">
                      <div class="sticky-search-container" style="margin-bottom: unset !important;">
                        <mat-form-field appearance="fill" class="filterSelectList">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="branchString" (keyup)="filterBranches()">
                        </mat-form-field>
  
                        <div style="text-align: left; display: flex; justify-content: center;" *ngIf="['sinGSAdmin', 'sinGSstaff'].includes(userType)">
                          <button mat-button (click)="filterEOD()" class="sticky-button-search">
                            <span *ngIf="!branchShowEOD">Include EOD Members</span>
                            <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                          </button>
                        </div>
                      </div>
                      
                      <mat-option *ngFor="let branch of filteredBranches" [value]="branch['tradeCode']" (click)="filterString = ''; filterBranches();">
                        <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <input *ngIf="branches.length == 1" type="hidden" ngModel #tradeCode="ngModel" name="tradeCode"
                    [(ngModel)]="branches[0]['tradeCode']" />

                  <mat-form-field color="secondary" class="bookingCustomerSearch">
                    <input matInput ngModel placeholder="Client First Name" name="firstName" #surname="ngModel">
                  </mat-form-field>

                  <mat-form-field color="secondary" class="bookingCustomerSearch">
                    <input matInput ngModel placeholder="Client Surname" name="surname" #surname="ngModel">
                  </mat-form-field>

                  <mat-form-field color="secondary" class="bookingCustomerSearch">
                    <input matInput ngModel placeholder="Client Email" name="email" #surname="ngModel">
                  </mat-form-field>

                  <mat-form-field color="secondary" class="bookingCustomerSearch">
                    <mat-select ngModel name="holidayInterest" #holidayInterest="ngModel" placeholder="Holiday Interest">
                      <mat-option [value]=""></mat-option>
                      <mat-option *ngFor="let interest of holidayInterests" [value]="interest['value']">
                        {{interest['value']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div style="display: inline-block; height: 50px;">
                    <button style="width: 93.21px; margin-top: -5px !important;" mat-raised-button color="primary"
                    type="submit" [disabled]="getCustomerListForm.invalid">Search</button>
                  </div>
                </form>
              </div>

              <div [@customExpansionDetails]="expansionSearch == false ? 'expanded' : 'collapsed'"
                class="ownExpansionBodyEmpty" (click)="expansionSearch = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 100px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionList"
                    (click)="expansionList = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionList"
                    (click)="expansionList = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Customer List
                </div>

                <div style="height: 56px; float: right; display: flex; align-items: center;">

                  <button class="buttonToRight" style="margin-right: 20px; margin-bottom: -7.5px;" mat-icon-button
                    [disabled]="customerData.data.length == 0" (click)="exportToExcel()">
                    <mat-icon>file_download</mat-icon>
                  </button>

                  <mat-form-field class="pageSize" style="width: 65px; margin-top: 11px;">
                    <mat-label style="color: white; font-size: 16px;">Page Size</mat-label>
                    <mat-select ngModel [(ngModel)]="pageSize" name="pageSizee" #pageSizee="ngModel"
                      (selectionChange)="changePageSize($event.value)">
                      <mat-option *ngFor="let size of pageSizeOptions" [value]="size">
                        {{size}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <button class="buttonToRight" style="margin-right: 10px; margin-bottom: -5px;" mat-icon-button
                    [disabled]="firstRec == 1" (click)="changePageNumber('previous')">
                    <mat-icon>arrow_back</mat-icon>
                  </button>

                  <button class="buttonToRight" style="margin-right: 20px; margin-bottom: -5px;" mat-icon-button
                    [disabled]="customerData.data.length < pageSize" (click)="changePageNumber('next')">
                    <mat-icon>arrow_forward</mat-icon>
                  </button>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionList == true ? 'expanded' : 'collapsed'"
                class="ownExpansionBody">
                <div *ngIf="customerData.data.length === 0">
                  <h3 style="margin-top: 16px; margin-bottom: 16px;" *ngIf="pageLoaded">Could not find any customers
                  </h3>
                  <h3 style="margin-top: 16px; margin-bottom: 16px;" *ngIf="!pageLoaded">Please wait..</h3>
                </div>

                <mat-table *ngIf="customerData.data.length > 0" class="tableWithinExpansV2" multiTemplateDataRows
                  [dataSource]="customerData">

                  <ng-container matColumnDef="fullName">
                    <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.title }} {{ customer.firstName }} {{ customer.middleName }}
                      {{customer.lastName }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.email }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="fullAddress">
                    <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                    <mat-cell *matCellDef="let customer">
                      <div>
                        <span *ngFor="let line of getAddressLines(customer)">
                          {{ line }}<span class="addresSpan" *ngIf="line !== getAddressLines(customer)[getAddressLines(customer).length - 1]">, </span>
                        </span>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <mat-header-cell *matHeaderCellDef> Post Code </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.postcode }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="tradeCode">
                    <mat-header-cell *matHeaderCellDef> Trade Code </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.tradeCode }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expand">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let customer">
                      <mat-icon style="float: right;">expand_more</mat-icon>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="expandedDetail" mat-cell *matCellDef="let customer" [attr.colspan]="7">
                      <div [@inOutAnimation] *ngIf="customer == expandedElement">
                        <form *ngIf="userType == 'wcMember' || userType == 'memberManager' || userType == 'memberStaff' || userType == 'wcManager'"
                          ngNativeValidate class="editCustomer" #editCustomerForm="ngForm" (ngSubmit)="editCustomer(editCustomerForm, customer)">
                          <h3>Customer Name</h3>

                          <mat-form-field>
                            <mat-select ngModel [(ngModel)]="customer.title" name="title" #title="ngModel" placeholder="Title">
                              <mat-option *ngFor="let title of titles" [value]="title['value']">
                                {{title['value']}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel required placeholder="First Name" maxlength="50" name="firstName"
                              #firstName="ngModel" [(ngModel)]="customer.firstName">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Middle Name" maxlength="50" name="middleName"
                              #middleName="ngModel" [(ngModel)]="customer.middleName">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput required ngModel placeholder="Last Name" maxlength="50" name="lastName"
                              #lastName="ngModel" [(ngModel)]="customer.lastName">
                          </mat-form-field>

                          <h3>Address</h3>
                          <mat-form-field>
                            <input matInput ngModel placeholder="Post Code" maxlength="10"
                              (keyup)="postCodeLookup($event)" [matAutocomplete]="auto" name="postcode"
                              #postcode="ngModel" [(ngModel)]="customer.postcode" id="postCode{{customer.id}}">
                            <mat-autocomplete #auto="matAutocomplete" style="margin-top: -25px;" [panelWidth]="'auto'"
                              (optionSelected)='selectPostcode($event.option.value, "existing", customer)'>
                              <mat-option *ngFor="let address of addressList; let i = index" [value]="i">
                                {{ address }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 1" maxlength="50" name="addressLine1"
                              #addressLine1="ngModel" [(ngModel)]="customer.addressLine1">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 2" maxlength="50" name="addressLine2"
                              #addressLine2="ngModel" [(ngModel)]="customer.addressLine2">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 3" maxlength="50" name="addressLine3"
                              #addressLine3="ngModel" [(ngModel)]="customer.addressLine3">
                          </mat-form-field>

                          <br>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 4" maxlength="50" name="addressLine4"
                              #addressLine4="ngModel" [(ngModel)]="customer.addressLine4">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="County" maxlength="50" name="county" #county="ngModel"
                              [(ngModel)]="customer.county">
                          </mat-form-field>

                          <mat-form-field>
                            <mat-select ngModel [(ngModel)]="customer.country" name="country" #country="ngModel"
                              placeholder="Country">
                              <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel"
                                  [(ngModel)]="filterString" (keyup)="filterSelect()">
                              </mat-form-field>
                              <mat-option *ngFor="let country of countryFiltered" [value]="country"
                                (click)="filterString = ''; filterSelect();">
                                {{country}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <div *ngIf="userType == 'wcMember' || userType == 'memberManager'">
                            <h3>Availability Restrictions</h3>
                            <mat-form-field style="width: 100%; max-width: unset;">
                              <mat-select panelClass="customerList" ngModel name="exclusiveToId" [(ngModel)]="customer.exclusiveToId" #exclusiveToId="ngModel"
                                          placeholder="Customer available to" matTooltipClass="line-normal-tooltip"
                                          matTooltip="Choose restricted Team Member for them to access this customer">
                                <mat-option *ngFor="let user of userList" [value]="user.id">{{user.fullName}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <h3>DOB & Contact Details</h3>
                          <mat-form-field style="max-width: 180px;">
                            <mat-label>Date of Birth</mat-label>
                            <input matInput [(ngModel)]="customer.dob" name="dob" [matDatepicker]="dob"
                              (dateChange)="valiDate($event)">
                            <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                            <mat-datepicker #dob></mat-datepicker>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Mobile Number" maxlength="15" name="telNo"
                              #telNo="ngModel" type="tel" pattern="[0-9 ]+" [(ngModel)]="customer.telNo">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Home Number" maxlength="15" name="homeNo"
                              #homeNo="ngModel" type="tel" pattern="[0-9 ]+" [(ngModel)]="customer.homeNo">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Email Address" name="email"
                              #email="ngModel" [(ngModel)]="customer.email" maxlength="100"
                              pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                          </mat-form-field>

                          <h3>Holiday Interests</h3>

                          <div class="checkboxContainer">
                            <div class="checkboxElement" *ngFor="let interest of holidayInterests">
                              <label>
                                <input type="checkbox" (change)="addRemoveHolInterest($event)"
                                    [checked]="selHolidayInterests.indexOf(interest.value) > -1"
                                    value="{{interest.value}}">
                                    {{interest.viewValue}}
                            </label>
                            </div>
                          </div>

                          <h3>Company Name & Notes</h3>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Company Name" maxlength="100" name="companyName"
                              #companyName="ngModel" [(ngModel)]="customer.companyName">
                          </mat-form-field>

                          <mat-form-field style="width: 560px; max-width: unset;">
                            <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500"
                              cdkTextareaAutosize ngModel name="customerNotes" #customerNotes="ngModel"
                              [(ngModel)]="customer.customerNotes" #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" placeholder="Customer Notes"></textarea>
                          </mat-form-field>

                          <input matInput hidden="true" ngModel [(ngModel)]="customer.id" name="id" #id="ngModel">

                          <br>
                          <button style="margin-bottom: 10px; width: 120px; margin-right: 20px;" type="submit" *ngIf="memberLive"
                            [disabled]="editCustomerForm.invalid" mat-raised-button color="accent">
                            <mat-icon class="iconInButton">edit</mat-icon>Save
                          </button>
                          <button style="margin-bottom: 10px; width: 120px;" type="button" mat-raised-button
                            color="warn" (click)="removeCustomer(customer)" *ngIf="['memberManager', 'wcManager'].includes(userType) && memberLive">
                            <mat-icon class="iconInButton">delete</mat-icon>Remove
                          </button>
                        </form>

                        <form *ngIf="userType == 'sinGSAdmin' || userType == 'sinGSstaff'">
                          <h3>Customer Name</h3>
                          <mat-form-field>
                            <input matInput ngModel placeholder="Title" [readonly]="true" name="title" #title="ngModel"
                              [(ngModel)]="customer.title">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="First Name" [readonly]="true" name="firstName"
                              #firstName="ngModel" [(ngModel)]="customer.firstName">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Middle Name" [readonly]="true" name="middleName"
                              #middleName="ngModel" [(ngModel)]="customer.middleName">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Last Name" [readonly]="true" name="lastName"
                              #lastName="ngModel" [(ngModel)]="customer.lastName">
                          </mat-form-field>

                          <h3>Address</h3>
                          <mat-form-field>
                            <input matInput ngModel placeholder="Post Code" [readonly]="true" name="postcode"
                              #addressLine1="ngModel" [(ngModel)]="customer.postcode">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 1" [readonly]="true" name="addressLine1"
                              #addressLine1="ngModel" [(ngModel)]="customer.addressLine1">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 2" [readonly]="true" name="addressLine2"
                              #addressLine2="ngModel" [(ngModel)]="customer.addressLine2">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 3" [readonly]="true" name="addressLine3"
                              #addressLine3="ngModel" [(ngModel)]="customer.addressLine3">
                          </mat-form-field>
                          <p></p>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 4" [readonly]="true" name="addressLine4"
                              #addressLine4="ngModel" [(ngModel)]="customer.addressLine4">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="County" [readonly]="true" name="county"
                              #county="ngModel" [(ngModel)]="customer.county">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Country" [readonly]="true" name="country"
                              #county="ngModel" [(ngModel)]="customer.country">
                          </mat-form-field>

                          <h3>DOB & Contact Details</h3>
                          <mat-form-field>
                            <input matInput ngModel placeholder="Date Of Birth" [readonly]="true" name="dob"
                              #telNo="ngModel" type="date" [(ngModel)]="customer.dob">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Mobile Number" [readonly]="true" name="telNo"
                              #telNo="ngModel" [(ngModel)]="customer.telNo">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Home Number" [readonly]="true" name="homeNo"
                              #homeNo="ngModel" [(ngModel)]="customer.homeNo">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Email Address" [readonly]="true" name="email"
                              #email="ngModel" [(ngModel)]="customer.email">
                          </mat-form-field>

                          <h3 *ngIf="selHolidayInterests.length > 0">Holiday Interests</h3>

                          <div class="checkboxContainer" *ngIf="selHolidayInterests.length > 0">
                            <div class="checkboxElement" *ngFor="let interest of selHolidayInterests">
                              <li>{{ interest }}</li>
                            </div>
                          </div>

                          <h3>Company Name & Notes</h3>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Company Name" [readonly]="true" name="companyName"
                              #companyName="ngModel" [(ngModel)]="customer.companyName">
                          </mat-form-field>

                          <mat-form-field style="width: 560px; max-width: unset;">
                            <textarea [readonly]="true" style="min-width: 50%; overflow: hidden;" matInput
                              maxlength="500" cdkTextareaAutosize ngModel name="customerNotes" #customerNotes="ngModel"
                              [(ngModel)]="customer.customerNotes" #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" placeholder="Customer Notes"></textarea>
                          </mat-form-field>
                        </form>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : displayedColumns;"
                    (click)="expandedElement = expandedElement === row ? null : row; clearPostCodeString(); setHolidayInterests(row);" class="trueRow"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                    [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
                  </mat-row>
                </mat-table>
              </div>

              <div [@customExpansionDetails]="expansionList == false ? 'expanded' : 'collapsed'"
                class="ownExpansionBodyEmpty" (click)="expansionList = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
            <div class="errorMessage">
              <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
            </div>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>

</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button>
    </p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button>
    </p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>CRM</u></h1>
    <p>This page allows you to manage your Customers<br><br>
      Please remember - every customer must have <b>unique</b> email address</p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary"
        matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>