<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('supplementList')" mat-button [disabled]="supplementListDiv">
          <mat-icon>list</mat-icon> Supplement List
        </button>
        <button class="navButtons" (click)="switchView('createSupplement')" mat-button [disabled]="createSupplementDiv">
          <mat-icon>add</mat-icon> Create Supplement
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('supplementList')" mat-button [disabled]="supplementListDiv">
          <mat-icon>list</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('createSupplement')" mat-button [disabled]="createSupplementDiv">
          <mat-icon>add</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="haveAccess">

          <div [@inAnimation] *ngIf="supplementListDiv">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Supplement List
            </h1>
            <mat-divider></mat-divider>

            <mat-form-field style="padding-top: 5px; margin-bottom: -10px; min-width: unset; max-width: 200px; width: calc(98% - 515px) !important; float: left; margin-left: 22.5px;">
              <mat-select ngModel name="company" [(ngModel)]="selectedCompany" placeholder="Company" (selectionChange)="loadSupplements()">
                <mat-option [value]="'gtg'">GTG</mat-option>
                <mat-option [value]="'tta'">TTA</mat-option>
                <mat-option [value]="'ttng'">TTNG</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="paginatorFooter">
              <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>

            <mat-table multiTemplateDataRows [dataSource]="supplementData" style="clear: both;" *ngIf="supplementData.data.length > 0">
              <ng-container matColumnDef="supplementName">
                <mat-header-cell *matHeaderCellDef> Supplement Name </mat-header-cell>
                <mat-cell *matCellDef="let supplement"> {{ supplement.supName }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="autoPricing">
                <mat-header-cell *matHeaderCellDef> Auto Pricing </mat-header-cell>
                <mat-cell *matCellDef="let supplement">
                  <span *ngIf="supplement.autoPricing"> Yes </span>
                  <span *ngIf="!supplement.autoPricing"> No </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="hidden">
                <mat-header-cell *matHeaderCellDef> Hidden </mat-header-cell>
                <mat-cell *matCellDef="let supplement"> {{ supplement.hidden | titlecase }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expand">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let customer">
                  <mat-icon>expand_more</mat-icon>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <mat-cell class="expandedDetail" *matCellDef="let supplement" [attr.colspan]="3" style="height: 200px; display: flex; flex-direction: row; align-items: center;">
                  <div style="margin-top: 5px; width: 100%">
                    
                    <div *ngIf="pageLoaded && chosenSupplement.autoPricing" fxLayout="column" fxLayoutAlign="center stretch">
                      <h5>Last updated {{ chosenSupplement.activeDate | date: 'dd.MM.yyyy' }}</h5>
                      <div fxFlex="80">
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>Cost Per Passenger</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="costFixPerPx">
                            <h4>{{chosenSupplement.costFixPerPx | currency:'GBP'}}</h4>
                          </div>
                        </div>
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>Net</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="netPercOfGross">
                            <h4><span matTooltip="Based on the Supplement price" matTooltipClass="line-normal-tooltip">{{chosenSupplement.netPercOfGross | number : '1.2-2'}}%</span></h4>
                          </div>
                        </div>
                      </div>

                      <div fxFlex="80">
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>Cost Per Booking</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="costFixPerBk">
                            <h4>{{chosenSupplement.costFixPerBk | currency:'GBP'}}</h4>
                          </div>
                        </div>
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>Commission</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="commissionPercOfGross">
                            <h4><span matTooltip="Based on the Supplement price" matTooltipClass="line-normal-tooltip">{{chosenSupplement.commissionPercOfGross | number : '1.2-2'}}%</span></h4>
                          </div>
                        </div>
                      </div>

                      <div fxFlex="80">
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>Booking's Gross Rate</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="costPercPerBkGross">
                            <h4>{{chosenSupplement.costPercPerBkGross | number : '1.2-2'}}%</h4>
                          </div>
                        </div>
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>VAT</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="taxPercOfGross">
                            <h4><span matTooltip="Based on the Supplement price" matTooltipClass="line-normal-tooltip">{{chosenSupplement.taxPercOfGross | number : '1.2-2'}}%</span></h4>
                          </div>
                        </div>
                      </div>

                      <div fxFlex="80">
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>Cost Per Member</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="costFixPerMx">
                            <h4>{{chosenSupplement.costFixPerMx | currency:'GBP'}}</h4>
                          </div>
                        </div>
                        <div fxFlex="50">
                          <div fxFlex="40" style="margin: auto;">
                            <h4>Discount</h4>
                          </div>
                          <div fxFlex="60" style="margin: auto;" class="discountPercOfGross">
                            <h4><span matTooltip="Based on the Supplement price" matTooltipClass="line-normal-tooltip">{{chosenSupplement.discountPercOfGross | number : '1.2-2'}}%</span></h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="pageLoaded && chosenSupplement.autoPricing == false">
                      <h5>Last updated {{ chosenSupplement.activeDate | date: 'dd.MM.yyyy' }}</h5>
                      <h4>This supplement allows maunal cost input</h4>
                    </div>

                    <div *ngIf="pageLoaded && chosenSupplement.autoPricing != undefined" style="text-align: right; margin-top: 5px;">
                      <button class="buttons" mat-button color="primary" type="button" (click)="unHideSupplmPrice()">
                        <span *ngIf="chosenSupplement.hidden == 'yes'"><mat-icon class="iconInButton">lock_open</mat-icon>Display</span>
                        <span *ngIf="chosenSupplement.hidden == 'no'"><mat-icon class="iconInButton">lock</mat-icon>Hide</span>
                      </button>

                      <button class="buttons" *ngIf="userEmail == 'greg@gmail.com'" mat-button color="warn" type="button" (click)="removeSupplement()">
                        <mat-icon class="iconInButton">delete</mat-icon>Remove
                      </button>
                    </div>

                    <div *ngIf="pageLoaded && chosenSupplement.autoPricing == undefined">
                      <h4>This supplement is not active yet - come back later</h4>

                      <button class="buttons" style="float: right;" *ngIf="userEmail == 'greg@gmail.com'" mat-button color="warn" type="button" (click)="removeSupplement()">
                        <mat-icon class="iconInButton">delete</mat-icon>Remove
                      </button>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let supplement; let row; columns : displayedColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row; getSupplmPrice(supplement)"></mat-row>
              <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
            </mat-table>
          </div>

          <div [@inAnimation] *ngIf="createSupplementDiv">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Create Supplement
            </h1>
            <mat-divider></mat-divider>

            <form ngNativeValidate #createSupplementForm="ngForm" (ngSubmit)="createSupplementPricing(createSupplementForm)">
              <div class="withinMatCard">
                <h5><i>Please fill in all mandatory fields</i></h5>
                <h3>General Information</h3>
                <mat-form-field>
                  <mat-select ngModel required name="company" [(ngModel)]="selectedCompany" placeholder="Company">
                    <mat-option [value]="'gtg'">GTG</mat-option>
                    <mat-option [value]="'tta'">TTA</mat-option>
                    <mat-option [value]="'ttng'">TTNG</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Supplement Name" name="supName" #supName="ngModel" maxlength="100">
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Active Date</mat-label>
                  <input ngModel required maxlength="50" matInput name="activeDate" [matDatepicker]="activeDate" (dateChange)="valiDate($event)">
                  <mat-datepicker-toggle matSuffix [for]="activeDate"></mat-datepicker-toggle>
                  <mat-datepicker #activeDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                  <mat-select ngModel required name="autoPricing" [(ngModel)]="autoPricing" placeholder="Auto Pricing" (selectionChange)="autoPriceChange()">
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                </mat-form-field>

                <h3>Supplement Costs</h3>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Cost Per Passenger" name="costFixPerPx" [(ngModel)]="supplementCosts.costPerPax" type="number" min="0" step="0.01" [disabled]="!autoPricing">
                </mat-form-field>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Cost Per Booking" name="costFixPerBk" [(ngModel)]="supplementCosts.costPerBook" type="number" min="0" step="0.01" [disabled]="!autoPricing">
                </mat-form-field>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Booking Gross Rate (%)" name="costPercPerBkGross" [(ngModel)]="supplementCosts.costPerGross" type="number" min="0" step="0.01" max="100" [disabled]="!autoPricing">
                </mat-form-field>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Cost Per Member" name="costFixPerMx" [(ngModel)]="supplementCosts.costPerMember" type="number" min="0" step="0.01" [disabled]="!autoPricing">
                </mat-form-field>

                <h3>Supplement's Breakdown</h3>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Net Per Gross (%)" name="netPercOfGross" [(ngModel)]="supplementCosts.netPercOfGross" type="number" min="0" step="0.01" max="100" [disabled]="!autoPricing">
                </mat-form-field>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Comm Per Gross (%)" name="commissionPercOfGross" [(ngModel)]="supplementCosts.commissionPercOfGross" type="number" min="0" step="0.01" max="100" [disabled]="!autoPricing">
                </mat-form-field>

                <mat-form-field>
                  <input matInput required ngModel placeholder="VAT Per Gross Rate (%)" name="taxPercOfGross" [(ngModel)]="supplementCosts.taxPercOfGross" type="number" min="0" step="0.01" max="100" [disabled]="!autoPricing">
                </mat-form-field>

                <mat-form-field>
                  <input matInput required ngModel placeholder="Discount Per Gross (%)" name="discountPercOfGross" [(ngModel)]="supplementCosts.discountPercOfGross" type="number" min="0" step="0.01" max="100" [disabled]="!autoPricing">
                </mat-form-field>

                <p></p>
                <button type="submit" class="buttons" mat-raised-button color="primary">
                  <mat-icon class="iconInButton">add</mat-icon>Create
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Supplements</u></h1>
    <h2>Supplement List</h2>
    <p>Choose a company to pull a list of Supplements available to the shops.<br>
      Each supplement will display either its pricing table or appropriate message.
    <p>

    <h2>Create Supplement</h2>
    <p>First, please select a company you wish to create a supplement for.<br>
      If you would like to update existing supplement's pricing details, provide the same<br>supplement name with a new <b>Active Date</b>*.<br>
      If you would like supplement to have fixed costs - please select <b>Auto Pricing</b> to <i>Yes</i>.<br>
      Otherwise select <i>No</i> so members can input gross price <b>manually</b>.
    </p>
    <br><i>*New pricing will be used from the day of the <b>Activation Date</b>.</i>
    <br><br>
    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
