<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="openedBooking != null && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="openedBooking != null && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="pageLoaded && openedBooking != null">
          <div>
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>New Supplier
            </h1>
          </div>

          <div class="centerOrLeft">
            <mat-form-field style="width: 50%;" *ngIf="openedBooking.freshElement == 'yes'">
              <mat-select ngModel required name="chosenSupplier" #chosenSupplier="ngModel" placeholder="Supplier" (selectionChange)="changeSupplier(chosenSupplier.value)">
                <mat-select-trigger *ngIf="supplierTypesData.length > 0">
                  {{supplierTypesData[0].supplierNameM}}
                  <mat-icon *ngIf="supplierTypesData[0].isUnderSFC" matTooltip="Under SFC" style="margin-left: 5px;" class="suppOptionIcon">security</mat-icon>
                  <mat-icon *ngIf="supplierTypesData[0].underSafi === 'yes'" matTooltip="Under SAFI" style="margin-left: 5px;" class="suppOptionIcon">airlines</mat-icon>
                </mat-select-trigger>
                
                <mat-form-field appearance="fill" class="filterSelectList sticky-search-container">
                  <input placeholder="Search.." matInput #filterInput [(ngModel)]="filterString" (keyup)="filterSelect()">
                </mat-form-field>
                <mat-option *ngFor="let supplier of supplierFilteredData" [value]="supplier" (click)="filterString = ''; filterSelect();">
                  {{supplier[0].supplierNameM}} 
                  <mat-icon *ngIf="supplier[0].isUnderSFC" matTooltip="Under SFC" class="iconInSelect" style="margin-right: 5px;">security</mat-icon>
                  <mat-icon *ngIf="supplier[0].underSafi === 'yes'" matTooltip="Under SAFI" class="iconInSelect">airlines</mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="openedBooking.freshElement == 'no'">
              <h2>{{ openedBooking.supplierName }} ( {{ openedBooking.supplierReference }} )</h2>
            </div>
          </div>
        </div>

        <!-- <div style="position: relative;">
            <img usemap="#singsLogo" src="assets/singsType2.png" style="display: block; margin-left: auto; margin-right: auto;">
              <map name="singsLogo">
                <area title="Flight" coords="207,156,232,485" shape="rect" (mouseover)="divSelected('flight')" (mouseout)="divCancel('flight')">
              </map>
            <p class="textAboveMap">1</p>
          </div> -->

        <div [@inAnimation] class="buttonContainer" *ngIf="pageLoaded && openedBooking != null">
          <h2 class="centerOrLeft">Please choose the type of the element below</h2>
          <div fxLayout="column" fxLayoutAlign="space-evenly">
            <form ngNativeValidate #supplierTypeSelect="ngForm" (ngSubmit)="selectSuppType(supplierTypeSelect)">
              <mat-radio-group ngModel required name="type" #type="ngModel" [(ngModel)]="chosenSupplierType">
                <div fxFlex="50" class="radioButtons">
                  <h2>
                    <mat-radio-button value="accommodation" [disabled]="!hotelOn || openedBooking.accomsDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">hotel</mat-icon>Accommodation
                      <!--<mat-icon *ngIf="sfc.hotel" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="carHire" [disabled]="!carHireOn || openedBooking.carHiresDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">drive_eta</mat-icon>Car Hire
                      <!--<mat-icon *ngIf="sfc.carHire" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="cruise" [disabled]="!cruiseOn || openedBooking.cruisesDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">directions_boat</mat-icon>Cruise
                      <!--<mat-icon *ngIf="sfc.cruise" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="miscellaneous" [disabled]="!miscOn || openedBooking.miscsDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">miscellaneous_services</mat-icon>Miscellaneous
                      <!--<mat-icon *ngIf="sfc.misc" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="train" [disabled]="!trainOn || openedBooking.trainsDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">train</mat-icon>Train
                     <!-- <mat-icon *ngIf="sfc.train" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                </div>

                <div fxFlex="50" class="radioButtons">
                  <h2>
                    <mat-radio-button value="attraction" [disabled]="!attractionOn || openedBooking.attractionsDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">attractions</mat-icon>Attraction
                      <!--<mat-icon *ngIf="sfc.attra" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="carParking" [disabled]="!carParkOn || openedBooking.carParksDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">local_parking</mat-icon>Car Parking
                      <!--<mat-icon *ngIf="sfc.carPark" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="flight" [disabled]="!flightOn || openedBooking.flightsDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">flight</mat-icon>Flight
                      <!--<mat-icon *ngIf="sfc.flight" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="package" [disabled]="!packageOn || openedBooking.packageDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">inventory_2</mat-icon>Package
                      <!--<mat-icon *ngIf="sfc.package" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                  <h2>
                    <mat-radio-button value="transfer" [disabled]="!transferOn || openedBooking.transfersDone == 'yes'">
                      <mat-icon style="margin-right: 5px;">airport_shuttle</mat-icon>Transfer
                      <!--<mat-icon *ngIf="sfc.transfer" class="suppOptionIcon" matTooltip="Under SFC">security</mat-icon>-->
                    </mat-radio-button>
                  </h2><br>
                </div>
              </mat-radio-group>

              <h4 *ngIf="externalElement" style="text-align: center;">
                It seems that the element you selected was added using our booking upload feature, but we don't have any record of this supplier in our system
              </h4>

              <div style="text-align: center;">
                <button style="width: 140px;" mat-raised-button color="primary" type="submit" [disabled]="supplierTypeSelect.invalid">Next</button>
              </div>
            </form>
          </div>

          <div class="newSupplierDiv">
            <h4>Can't find your supplier / supplier does not offer required holiday type?</h4>

            <button type="submit" color="accent" mat-raised-button (click)="openRequestDialog()">
              Request New Supplier
            </button>
          </div>
        </div>

        <div class="accessDenied" *ngIf="openedBooking == null">
          <p style="text-align: center; margin-top: 2.5%;">You need to select booking you wish to add element to first.</p>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>New Supplier Reference</u></h1>
    <p>If the supplier you're adding is new to the booking (has a new, unique supplier reference), select it from the dropdown list.<br>
      After clicking on the desired name, you will see that some of the buttons will become available to you.</p>
    <br>
    <h2>Same Supplier Reference</h2>
    <p>In case you're adding a new element to existing supplier, the suppier name will be 'locked'.<br>
      You may also notice that you won't be able to click on the type which already exists within it.<br><br>

      For example:<br>
      J2H0001 has one flight in it. You would like to add another element to that reference. You may add a accommodation to it, but the flight won't be available.
      Additional flights in this case should be done by 'Editing Flights' in Booking Portfolio
    <p>
      <br>
    <p><i>We're trying to keep the suppliers and holiday types they offer up to date. However please let us know if you'll spot something we've missed</i></p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>

<ng-template #requestDialog>
  <app-request-supplier [requestMode]="'supplier'" [requestSource]="'Booking System'" 
  [requestCompany]="currentCompany" [requestOperation]="currentOperation" [requestTradeCode]="currentTradeCode"></app-request-supplier>
</ng-template>