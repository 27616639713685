<div *ngIf="!pageLoaded" class="loading-indicator" style="margin:0 auto;">
    <mat-spinner></mat-spinner>
  </div>
  
  <mat-card-content>
    <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
      <div [@inAnimation] *ngIf="innerWidth > 1024 && haveAccess" style="width: 100%;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button class="navButtons" (click)="switchView('overwiev')" mat-button [disabled]="overView">
            <mat-icon>visibility</mat-icon> Overview
          </button>
          <button class="navButtons" (click)="switchView('transaction')" mat-button [disabled]="transactionView">
            <mat-icon matBadge="{{fellohData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">reorder</mat-icon> Transactions
          </button>
          <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
            <mat-icon class="helpIcon">help</mat-icon> Help
          </button>
        </div>
      </div>
  
      <div [@inAnimation] *ngIf="innerWidth < 1025 && haveAccess" style="width: 100%">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button class="navButtons" (click)="switchView('overwiev')" mat-button [disabled]="overView">
            <mat-icon>visibility</mat-icon>
          </button>
          <button class="navButtons" (click)="switchView('transaction')" mat-button [disabled]="transactionView">
            <mat-icon matBadge="{{fellohData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">reorder</mat-icon>
          </button>
          <button class="navButtons" (click)="showHelp()" mat-button>
            <mat-icon class="helpIcon">help</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>
  
    <mat-sidenav-container>
      <mat-sidenav mode="side" #drawer closed>
  
      </mat-sidenav>
  
      <mat-sidenav-content>
        <mat-card [style.overflow]="'auto'">
          <div [@inAnimation] class="sideNavContent" *ngIf="haveAccess">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div class="headerArrow" *ngIf="overView">
                <h1 fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Overview
                </h1>
              </div>
              <div class="headerArrow" *ngIf="transactionView">
                <h1 fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Transactions
                </h1>
              </div>

              <div *ngIf="selectedBranch?.fellohConfig?.length > 1" class="fellohConfigDiv">
                <mat-select ngModel placeholder="Trading Name" name="fellohConfig" [(ngModel)]="selectedFellohAccount" id="fellohAccount" #fellohConfig="ngModel"
                  (ngModelChange)="this.pageLoaded = false; prepareForFellohTxns()">
                    <mat-option *ngFor="let fellohConfig of selectedBranch?.fellohConfig" [value]="fellohConfig">
                      {{fellohConfig['accountName']}} | {{fellohConfig['accountCode']}}
                    </mat-option>
                </mat-select>
              </div>
            </div>
            <mat-divider></mat-divider>
  
            <div class="dates">
              <div [ngClass]="branchListData.length > 1 ? 'datesAndDownload' : 'datesAndDownloadLong'">
                <mat-form-field style="width: 110px;">
                  <input placeholder="From" matInput [(ngModel)]="fromDate" [matDatepicker]="fromDateSet" (dateChange)="changeDate('fromDate', $event)">
                  <mat-datepicker-toggle matSuffix [for]="fromDateSet"></mat-datepicker-toggle>
                  <mat-datepicker #fromDateSet></mat-datepicker>
                </mat-form-field>
  
                <mat-form-field style="max-width: 110px;">
                  <input placeholder="To" matInput [(ngModel)]="toDate" [matDatepicker]="toDateSet" (dateChange)="changeDate('toDate', $event)">
                  <mat-datepicker-toggle matSuffix [for]="toDateSet"></mat-datepicker-toggle>
                  <mat-datepicker #toDateSet></mat-datepicker>
                </mat-form-field>
  
                <mat-form-field style="max-width: unset; width: 240px;" *ngIf="branchListData.length > 1">
                  <mat-select ngModel placeholder="Branch" name="branch" [(ngModel)]="selectedBranch" id="branch" #branch="ngModel" (ngModelChange)="changeBranch($event, 'yes')">
                    <mat-form-field appearance="fill" class="filterSelectList sticky-search-container">
                      <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                    </mat-form-field>
                    <mat-option *ngFor="let branch of branchListFiltered" [value]="branch" (click)="filterString = ''; filterSelect();">
                      {{branch['fullName']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
  
                <mat-form-field [hidden]="overView" class="filterFelloh">
                  <input matInput (keyup)="filterData($event)" placeholder="Filter Transactions" [(ngModel)]="filterValue">
                </mat-form-field>
  
                <button *ngIf="fellohData.data.length > 0" mat-stroked-button color="primary" (click)="exportToExcel()" style="margin-top: -4px;" matTooltip="Download XLSX" matTooltipClass="line-normal-tooltip">
                  <mat-icon>file_download</mat-icon>
                </button>
  
                <button style="margin-top: -4px; margin-left: 10px" (click)="switchView('reloadView')" mat-stroked-button color="primary" matTooltip="Reload Transactions" matTooltipClass="line-normal-tooltip">
                  <mat-icon>sync</mat-icon>
                </button>
              </div>
  
              <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'"  style="padding-top: 7.5px !important;" [hidden]="!transactionView">
                <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
              </div>
  
              <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'"  style="padding-top: 7.5px !important;" [hidden]="!temporaryView">
                <mat-paginator #paginatorOverdue [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
              </div>
  
              <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'"  style="padding-top: 7.5px !important;" [hidden]="!pendingView">
                <mat-paginator #paginatorDue [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
              </div>
            </div>
  
            <div *ngIf="overView && chartCurrencies.length > 1" style="clear: both; display: flex; justify-content: center;">
              <div *ngFor="let currency of chartCurrencies">
                <button style="margin-top: -4px; margin-left: 10px; margin-right: 10px;"
                color="primary" mat-raised-button [disabled]="currency == selectedCurrency"
                (click)="changeChartCurrency(currency)">
                  {{currency}}
                </button>
              </div>
            </div>
  
            <div [@inAnimation] *ngIf="overView">
              <div style="clear: both;" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="98.5">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="topBoxes">
                      <h1>Payments<br>{{ paymentNumber }}</h1>
                    </div>
                    <div class="topBoxes">
                      <h1>Average Payment<br>{{ totalBalance / paymentNumber | currency:selectedCurrency }}</h1>
                    </div>
                    <div class="topBoxes" style="float: left;">
                      <h1>Total Amount<br>{{ totalBalance | currency:selectedCurrency }}</h1>
                    </div>
                  </div>
  
                  <div class="apexChartZoom">
                    <apx-chart id="chart" [series]="chartOptions.series" [chart]="chartOptions.chart" [fill]="chartOptions.fill" [grid]="chartOptions.grid" [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                      [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [colors]="chartOptions.colors"></apx-chart>
                  </div>
                </div>
              </div>
  
              <img src="../../../assets/singsLogoMinix75.png" style="margin-top: -2.5px; margin-right: -10px; opacity: 0.25; float: right;">
            </div>
  
            <div [@inAnimation] *ngIf="transactionView" style="clear: both; margin-bottom: 100px;">
              <div *ngIf="fellohData.data.length > 0">
                <div class="table-responsive">
                  <mat-table class="tableClass" multiTemplateDataRows [dataSource]="fellohData">
                    <ng-container matColumnDef="custName">
                      <mat-header-cell *matHeaderCellDef>Customer</mat-header-cell>
                      <mat-cell *matCellDef="let data"> {{ data.payment_link?.customer_name || data.booking?.customer_name }} </mat-cell>
                    </ng-container>
  
                    <ng-container matColumnDef="bookingReference">
                      <mat-header-cell *matHeaderCellDef>Reference No.</mat-header-cell>
                      <mat-cell *matCellDef="let data"> {{ data.booking?.booking_reference }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                      <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                      <mat-cell *matCellDef="let data">
                        <span *ngIf="data.type === 'OPEN_BANKING'">Open Banking</span>
                        <span *ngIf="data.type !== 'OPEN_BANKING'">
                          {{ data?.metadata?.card_type | titlecase }} {{ data.type | titlecase }}
                        </span>
                      </mat-cell>
                    </ng-container>
  
                    <ng-container matColumnDef="method">
                      <mat-header-cell *matHeaderCellDef>Method</mat-header-cell>
                      <mat-cell *matCellDef="let data">
                        <span *ngIf="data.method == 'MOTO_IN_PERSON'">Moto <span matTooltip="In Person" matTooltipClass="line-normal-tooltip">(IP)</span></span>
                        <span *ngIf="data.method == 'MOTO_OTHER'">Moto</span>
                        <span *ngIf="data.method == 'ONLINE'">Online</span>
  
                        <span *ngIf="data.method != 'MOTO_IN_PERSON' && data.method != 'MOTO_OTHER' && data.method != 'ONLINE'">{{ data.method }}</span>
                      </mat-cell>
                    </ng-container>
  
                    <ng-container matColumnDef="createdDate">
                      <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                      <mat-cell *matCellDef="let data"> {{ data.created_at | date : "dd.MM.y hh:mm a" }} </mat-cell>
                    </ng-container>
  
                    <ng-container matColumnDef="amount">
                      <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
                      <mat-cell *matCellDef="let data"><b>{{ data.amount | currency:data.currency }}</b></mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="surcharge" *ngIf="selectedFellohAccount.surcharging == 'yes'">
                      <mat-header-cell *matHeaderCellDef>Surcharge</mat-header-cell>
                      <mat-cell *matCellDef="let data"><b>{{ data?.surcharge?.amount | currency:data?.surcharge?.currency }}</b></mat-cell>
                    </ng-container>
  
                    <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                      <mat-cell *matCellDef="let data">
                        <span *ngIf="data.status == 'COMPLETE'" style="color: rgb(129,199,132); font-weight: bold;"> Complete </span>
                        <span *ngIf="data.status == 'DECLINED'" style="color: rgb(244,67,54); font-weight: bold;"> Declined </span>
                        <span *ngIf="data.status != 'COMPLETE' && data.status != 'DECLINED'" style="color: rgb(128,180,221); font-weight: bold;">{{ data.status | titlecase }}</span>
                      </mat-cell>
                    </ng-container>
  
                    <ng-container *ngIf="selectedFellohAccount.surcharging == 'no'">
                      <mat-header-row *matHeaderRowDef="fellohColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns : fellohColumns;" class="trueRow" (click)="showTxnDetails(row)"></mat-row>
                    </ng-container>

                    <ng-container *ngIf="selectedFellohAccount.surcharging == 'yes'">
                      <mat-header-row *matHeaderRowDef="fellohColumnsSurcharge"></mat-header-row>
                      <mat-row *matRowDef="let row; columns : fellohColumnsSurcharge;" class="trueRow" (click)="showTxnDetails(row)"></mat-row>
                    </ng-container>
                  </mat-table>
                </div>
              </div>
  
              <div *ngIf="pageLoaded" style="margin-left: 22.5px; margin-top: 22.5px;">
                <h3 *ngIf="adminJustOpened">Please click <i>Reload Transactions</i> button to pull the data</h3>
                <h3 *ngIf="!adminJustOpened && fellohData.data.length == 0">There are no payments for this date range</h3>
              </div>
            </div>
          </div>
  
          <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
            <div class="errorMessage">
              <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
            </div>
          </div>
        </mat-card>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-card-content>

  <ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
      <p>{{errorMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  
    <div *ngIf="successMessage != ''" class="successMessage">
      <p>{{successMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  </ng-template>
  
  <ng-template #paymentDialog>
    <div class="fellohTxnDialog">
      <div class="column-one">
        <h1 class="description section">Transaction Details</h1>
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Transaction Amount</div>
            <div>{{ selectedPayment.amount |  currency: selectedPayment.currency }}</div>
          </div>
          <div class="row" *ngIf="selectedPayment.surcharge">
            <div class="description">Surchage Amount</div>
            <div>{{ selectedPayment.surcharge.amount | currency: selectedPayment.surcharge.currency }}</div>
          </div>
          <div class="row" *ngIf="selectedPayment.surcharge">
            <div class="description">Total Amount</div>
            <div>{{ selectedPayment.amount + selectedPayment.surcharge.amount | currency: selectedPayment.surcharge.currency }}</div>
          </div>
          <div class="row">
            <div class="description">Type</div>
            <div>
              <span *ngIf="selectedPayment.type.id != 'OPEN_BANKING'">
                {{ selectedPayment.type.id | titlecase }}
              </span>
              <span *ngIf="selectedPayment.type.id == 'OPEN_BANKING'">
                Open Banking
              </span>
            </div>
          </div>
        </div>
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Method</div>
            <div>
              <span *ngIf="selectedPayment.method.id == 'MOTO_IN_PERSON'">Moto</span>
              <span *ngIf="selectedPayment.method.id == 'MOTO_OTHER'">Moto</span>
              <span *ngIf="selectedPayment.method.id == 'ONLINE'">Online</span>

              <span *ngIf="selectedPayment.method.id != 'MOTO_IN_PERSON' && selectedPayment.method.id != 'MOTO_OTHER' && selectedPayment.method.id != 'ONLINE'">
                {{ selectedPayment.method.id }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="description">Initiated At</div>
            <div>{{ selectedPayment.created_at | date : "dd.MM.y hh:mm a" }}</div>
          </div>
        </div>
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Completed At</div>
            <div>{{ selectedPayment.completed_at | date : "dd.MM.y hh:mm a" }}</div>
          </div>
        </div>

        <div *ngIf="selectedPayment.refund.length > 0">
          <h1 class="description section">Refund Details</h1>
          <div *ngFor="let refund of selectedPayment.refund; index as i" [ngClass]="{'not-last': i !== selectedPayment.refund.length - 1}">
            <h3>Refund {{ i + 1 }}</h3>
            <div class="row-wrapper">
              <div class="row">
                <div class="description">Refund Status</div>
                <div *ngIf="refund.status.id == 'PENDING_AUTHORISATION'">Pending Authorisation</div>
                <div *ngIf="refund.status.id != 'PENDING_AUTHORISATION'">{{ refund.status.id | titlecase }}</div>
              </div>
              <div class="row">
                <div class="description">Refund Amount</div>
                <div>{{ refund.amount | currency: selectedPayment.currency }}</div>
              </div>
            </div>
            <div class="row-wrapper">
              <div class="row">
                <div class="description">Created At</div>
                <div>{{ refund.created_at | date : "dd.MM.y hh:mm a" }}</div>
              </div>
              <div class="row">
                <div class="description">Authorised At</div>
                <div>{{ refund.authorised_at | date : "dd.MM.y hh:mm a" }}</div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedPayment.metadata">
          <h1 class="description section">Card Details</h1>
          <div class="row-wrapper">
            <div class="row">
              <div class="description">Cardholder Name</div>
              <div>{{ selectedPayment?.metadata?.cardholder_name | titlecase }}</div>
            </div>
            <div class="row">
              <div class="description">Card Type</div>
              <div>{{ selectedPayment?.metadata?.bin_type | titlecase }}</div>
            </div>
          </div>
          <div class="row-wrapper">
            <div class="row">
              <div class="description">Card Brand</div>
              <div>{{ selectedPayment?.metadata?.card_type | titlecase }} </div>
            </div>
            <div class="row">
              <div class="description">Issuing Country</div>
              <div>{{ selectedPayment?.metadata?.issuing_country }}</div>
            </div>
          </div>
          <div class="row-wrapper">
            <div class="row">
              <div class="description">Card Number</div>
              <div>**** **** **** {{ selectedPayment?.metadata?.last_four_digits | titlecase }}</div>
            </div>
            <div class="row">
              <div class="description">Card Network</div>
              <div>{{ selectedPayment?.metadata?.payment_brand | titlecase }}</div>
            </div>
          </div>
        </div>

        <h1 class="description section">Payment Provider</h1>
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Reference</div>
            <div>{{ selectedPayment.provider_reference }}</div>
          </div>
          <div class="row">
            <div class="description">Message</div>
            <div>{{ selectedPayment.message }}</div>
          </div>
        </div>

        <h1 class="description section">Payment Link</h1>
        <div class="row-wrapper">
          <div class="row">
            <div class="description">Payment Link ID</div>
            <div>{{ selectedPayment?.payment_link?.id }}</div>
          </div>
          <div class="row">
            <div class="description">Description</div>
            <div>{{ selectedPayment?.payment_link?.description }}</div>
          </div>
        </div>

      </div>
    </div>
  </ng-template>