import { Component, OnInit } from '@angular/core';
import { NavigationStart, ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { Session } from '../../common/session';
import { CustomerService } from '../../services/customer.service';
import { BjornService } from '../../services/bjorn.service';

@Component({
  selector: 'app-config-marketing',
  templateUrl: './config-marketing.component.html',
  styleUrls: ['./config-marketing.component.css']
})
export class ConfigMarketingComponent implements OnInit {

  pageLoaded:boolean = false;
  constants = new GlobalConstants();

  public chartOptions: Partial<any> = {
    series: [],
      chart: {
        width: 380,
        type: "pie"
      },
      labels: ["18yo - 25yo", "26yo - 35yo", "36yo - 45yo", "46yo - 55yo", "56yo+"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };

  constructor(private router: Router, private route: ActivatedRoute,
  private bjornService: BjornService, private customerService: CustomerService,
  private session: Session) {
  }

  ngOnInit(): void {
    //let request: any = {apiKey: 'c631e0d2d5ed224c761531570d586aff-us6', id: '7e87b6aec5', email: 'kubalaagrzegorz@gmail.com', fname: 'Grzegorz', lname: 'Kubala', phone: '012345678'};
    //this.bjornService.testChimp(request).subscribe((res: any) => {
      //console.log(res);
    //})
    /*this.customerService.getCustomerList(this.session.get('user'), this.session.getUser().tradeCode).then((customers: any) => {
      this.feedTheAge(customers).then((output: any) => {
        this.pageLoaded = true;
      })
    })*/
  }


  feedTheAge(customers: any) {
    return new Promise((resolve, reject) => {
      let earlyTwenties = 0;
      let earlyThirties = 0;
      let earlyFourties = 0;
      let earlyFifties = 0;
      let sixtiesBeyond = 0;
      let todayDate = Date.now();

      customers.forEach((cust: any) => {
        let dob = new Date(cust.dob);
        let timeDiff = Math.abs(todayDate - dob.getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);

        if (age > 17 && age < 26) {
          earlyTwenties += 1;
        } else if (age > 25 && age < 36) {
          earlyThirties += 1;
        } else if (age > 35 && age < 46) {
          earlyFourties += 1;
        } else if (age > 45 && age < 56) {
          earlyFifties += 1;
        } else if (age > 55) {
          sixtiesBeyond += 1;
        }
      });

      this.chartOptions.series = [earlyTwenties, earlyThirties, earlyFourties, earlyFifties, sixtiesBeyond];

      resolve();
    })
  }

}
