import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { BranchService } from '../../services/branch.service';
import { AppComponent } from '../../app.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { GlobalConstants } from '../../common/global-constants';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0 }),
            animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 230 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300 }),
            animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 0 }))
          ]
        )
      ]
    ),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})

export class UserGroupsComponent implements OnInit {
  // Boolean deciding whether user has access or not
  userType = '';
  userEmail = '';
  haveAccess = false;
  pageLoaded = false;
  createView = false;
  editView = true;

  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // The userData from API will be assign to this variable. The MatTableDataSource class
  // is used for to the whole Component to work (+ it has its own built-in functions)
  userGroups: any = new MatTableDataSource<any>();
  branchData: any = [];
  filteredBranches: any = [];
  newBranches: any = [];
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  branchShowEOD = false;

  // List of the table columns which are displayed in .html file
  displayedColumns = ['groupName', 'active', 'expand'];

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(private router: Router, private userService: UserService,
              private branchService: BranchService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType & userCompany and load the page
      this.userType = Session.mySession.getUser().userType;
      this.userEmail = Session.mySession.getUser().email;
      this.loadPage();
    }
  }

  loadPage(): void {
    if (this.userType === 'sinGSAdmin') {
      this.haveAccess = true; // Page available only to admins..

      this.branchService.getUserGroupList(Session.mySession.getUser()).then((userGroups: any) => {
        if (userGroups.status === 'OK') {
          // In order to make the table 'expandable', we need to add a new property to each User Group object
          userGroups.data.forEach((element: any) => {
            element.tradeCodes = element.tradeCodes.split(';'); // Transalte string to array here..
            element.detailRow = true;
          });
          // Sort users by full name and assign paginator to the data table
          const userGroupsSorted = userGroups.data.sort((a: any, b: any) => (a.groupName > b.groupName) ? 1 : -1);
          this.userGroups.data = userGroupsSorted;
          this.userGroups.paginator = this.paginator;

          // Call getBranches API and assign its output to 'branches'
          if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
            this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
              if (branches.status === 'OK') {
                // Assign sorted to global variable and session varaible (for later use) and call loadBookings()
                const sorted = branches.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1);
                this.branchData = sorted; this.filteredBranches = sorted;
                Session.mySession.setBranchList(sorted);
                this.pageLoaded = true;
              } else {
                // Status was not OK meaning something went wrong with it.. display it to the user
                this.sendMessageToDialog('', branches.status, '', '');
                this.haveAccess = false;
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1602S)', error, Session.mySession.getUser());
            });
          } else {
            // Get branch list from the session varaible - no need to call API
            this.branchData = Session.mySession.getBranchList().branchList;
            this.filteredBranches = Session.mySession.getBranchList().branchList;
            this.pageLoaded = true;
          }

        } else {
          this.sendMessageToDialog('', userGroups.status, '', '');
          this.haveAccess = false; // No access for the user - sorry!
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1601S)', error, Session.mySession.getUser());
      });
    } else {
      this.pageLoaded = true; // Page loaded but no access - sorry
    }
  }

  doFilter(event: Event): void {
    // Function used to Filter the table by the user's input
    const filterValue = (event.target as HTMLInputElement).value;
    this.userGroups.filter = filterValue.trim().toLowerCase();
  }

  createGroup(form: NgForm): void {
    if (this.newBranches.length === 0) {
      this.sendMessageToDialog('', 'Group must have at least one Trade Code', '', '');
    } else if (this.constants.validateVariableRegex(form.value.groupName, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      form.value.token = Session.mySession.get('user').token; // Assign token to our object
      form.value.tradeCodes = this.newBranches; // Assign branch list to the object..

      this.pageLoaded = false;
      this.branchService.createUserGroup(form.value).then((result: any) => {
        if (result.status === 'OK') {
          this.sendMessageToDialog('User Group has been created', '', '', '');
          this.switchView('edit'); // Switch to 'list' view
        } else {
          this.sendMessageToDialog('', result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1605S)', error, form.value);
      });
    }
  }

  removeGroup(group: any): void {
    // User needs to confirm twice before sending removeUser call
    if (confirm('Are you sure you want to remove ' + group.groupName + '?')) {
      if (confirm('Still sure you want to remove ' + group.groupName + '?')) {
        this.pageLoaded = false;
        group.token = Session.mySession.get('user').token;
        this.branchService.removeUserGroup(group).then((result: any) => {
          if (result.status === 'OK') {
            // If user removed successfully - filter it out from the data table
            this.userGroups.data = this.userGroups.filteredData.filter((item: any) => item.groupName !== group.groupName);
            this.sendMessageToDialog('User Group has been removed', '', '', '');
          } else {
            this.sendMessageToDialog('', result.status, '', '');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1603S)', error, group);
        });
      }
    }
  }

  editGroup(group: any, tradeCode: any, operation: any): void {
    const index = group.tradeCodes.indexOf(tradeCode);
    group.token = Session.mySession.get('user').token; // Assign the token..

    if (operation === 'remove' && group.tradeCodes.length === 1) {
      this.sendMessageToDialog('', 'Group must have at least one Trade Code', '', '');
    } else {
      if (operation === 'remove') { group.tradeCodes.splice(index, 1); } // Remove selected trade code from the array..
      else if (operation === 'add') { group.tradeCodes.push(tradeCode); } // Add typed in trade code to the array..
      else if (operation === 'activate') { group.active = tradeCode; } // Activate / deactivate group..

      this.pageLoaded = false;
      this.branchService.editUserGroup(group).then((output: any) => {
        if (output.status === 'OK') {
          this.sendMessageToDialog('User Group has been updated', '', '', '');
        } else {
          if (operation === 'remove') { group.tradeCodes.push(tradeCode); }
          else if (operation === 'add') { group.tradeCodes.pop(); }
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1604S)', error, group);
      });
    }

  }

  editNewGroup(branch: any, operation: any): void {
    const index = this.newBranches.indexOf(branch.tradeCode); // Get the index of trade code in the array

    if (operation === 'remove') { this.newBranches.splice(index, 1); } // Remove selected trade code from the array..
    else if (operation === 'add') { this.newBranches.push(branch.tradeCode); } // Add typed in trade code to the array..

    // If we selected a branch, make sure to filter out all branches with different membership type
    // We don't want anyone to be able to access branches accross different companies etc. (it won't even work..)
    if (operation === 'add' && this.newBranches.length >= 1) {
      this.filteredBranches = this.branchData.filter((item: any) => item.membershipType === branch.membershipType);
    } else if (this.newBranches.length === 0) {
      this.filteredBranches = this.branchData;
    }
  }

  filterSelect(): void {
    this.filteredBranches = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.branchData.length; i++) {
      const option = this.branchData[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        if (this.branchShowEOD) { this.filteredBranches.push(option); }
        else if (option.isLive === 'yes') { this.filteredBranches.push(option); }
      }
    }
  }

  filterEOD(): void {
    this.branchShowEOD = !this.branchShowEOD;
    this.filterSelect();
  }

  switchView(view: any): void {
    this.pageLoaded = false;
    this.expandedElement = null;
    this.newBranches = [];
    this.filteredBranches = this.branchData;

    if (view === 'create') {
      this.createView = true;
      this.editView = false;
      this.pageLoaded = true;
    } else if (view === 'edit') {
      this.createView = false;
      this.editView = true;
      this.loadPage();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}
