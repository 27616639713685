<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('bookingList')" mat-button [disabled]="bookingList">
          <mat-icon>library_books</mat-icon> Booking List
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('bookingList')" mat-button [disabled]="bookingList">
          <mat-icon>library_books</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] *ngIf="haveAccess && bookingList">
          <h1 fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Booking List
          </h1>
          <div class="divider">
            <mat-divider></mat-divider>
          </div>

          <div style="width: 100%; margin-bottom: 20px;">
            <div class="ownExpansionHeader">
              <div class="headerTopLeft">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionSearch" (click)="expansionSearch = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionSearch" (click)="expansionSearch = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Search Criteria
              </div>
            </div>

            <div [@customExpansionDetails]="expansionSearch == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
              <form ngNativeValidate #getBookingListForm="ngForm" (ngSubmit)="getBookingList(getBookingListForm)" style="margin-top: 4px; margin-bottom: -11px;">

                <mat-form-field class="bookingCustomerSearch">
                  <input matInput ngModel placeholder="Client Surname" name="surname" #surname="ngModel">
                </mat-form-field>

                <mat-form-field class="bookingGroup">
                  <input matInput ngModel placeholder="Booking Group" name="bookingGroup" #bookingGroup="ngModel">
                </mat-form-field>

                <mat-form-field class="dateType">
                  <mat-select ngModel name="dateType" #dateType="ngModel" placeholder="Date Type" (selectionChange)="dateTypeChange(dateType)">
                    <mat-option [value]="null"></mat-option>
                    <mat-option [value]="'balanceDueDate'">Balance Due Date</mat-option>
                    <mat-option [value]="'bookingDate'">Booking Date</mat-option>
                    <mat-option [value]="'deptDate'">Departure Date</mat-option>
                    <mat-option [value]="'depositDueDate'">Deposit Due Date</mat-option>
                    <mat-option [value]="'dueDate'">Due Date</mat-option>
                    <mat-option [value]="'returnDate'">Return Date</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="startDate">
                  <mat-label>Date From</mat-label>
                  <input ngModel required matInput name="dateFrom" [matDatepicker]="dateFrom" [disabled]="dateRangeBlock" (dateChange)="valiDate($event)">
                  <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                  <mat-datepicker #dateFrom></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="endDate">
                  <mat-label>Date To</mat-label>
                  <input ngModel required matInput name="dateTo" [matDatepicker]="dateTo" [disabled]="dateRangeBlock" (dateChange)="valiDate($event)">
                  <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                  <mat-datepicker #dateTo></mat-datepicker>
                </mat-form-field>

                <br *ngIf="companies.length == 1">

                <span>
                  <mat-form-field class="bookingStatus">
                    <mat-select ngModel [(ngModel)]="currentRequest.statusFilter" name="statusFilter" #statusFilter="ngModel" placeholder="Status">
                      <mat-option [value]="'all'">All</mat-option>
                      <mat-option [value]="'booking'">Booking</mat-option>
                      <mat-option [value]="'cancelled'">Cancelled</mat-option>
                      <mat-option [value]="'enquiry'">Enquiry</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>

                <br *ngIf="companies.length > 1">

                <mat-form-field class="companyEdit" *ngIf="companies.length > 1" style="margin-bottom: -15px;">
                  <mat-select ngModel required name="company" #company="ngModel" placeholder="Company" (selectionChange)="filterBranches($event)">
                    <mat-option *ngFor="let company of companies" [value]="company['value']">
                      {{company['viewValue']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="branchEdit" *ngIf="branches.length > 1" style="max-width: unset; width: 309.5px; margin-bottom: -15px;">
                  <mat-select ngModel required name="tradeCode" [(ngModel)]="selectedBranch" placeholder="Branch" [disabled]="filteredBranches.length == 0" (click)="filterString = ''; filterSelect()">

                    <div class="sticky-search-container" style="margin-bottom: unset !important;">
                      <mat-form-field appearance="fill" class="filterSelectList">
                        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                      </mat-form-field>

                      <div style="text-align: left; display: flex; justify-content: center;" *ngIf="['sinGSAdmin', 'sinGSstaff'].includes(userType)">
                        <button mat-button (click)="filterEOD()" class="sticky-button-search">
                          <span *ngIf="!branchShowEOD">Include EOD Members</span>
                          <span *ngIf="branchShowEOD">Exlucde EOD Members</span>
                        </button>
                      </div>
                    </div>
                    
                    <mat-option *ngFor="let branch of filterInBranches" [value]="branch['tradeCode']" (click)="filterString = ''; filterSelect();">
                      <span [ngClass]="{'live-branch': branch.isLive === 'yes', 'non-live-branch': branch.isLive === 'no'}">{{branch['fullName']}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <input *ngIf="companies.length == 1" type="hidden" ngModel #company="ngModel" name="company" [(ngModel)]="companies[0]['value']" />
                <input *ngIf="branches.length == 1" type="hidden" ngModel #tradeCode="ngModel" name="tradeCode" [(ngModel)]="branches[0]['tradeCode']" />

                <span>
                  <mat-form-field class="orderColumn">
                    <mat-select ngModel [(ngModel)]="currentRequest.orderColumn" name="orderColumn" #orderColumn="ngModel" placeholder="Order By">
                      <mat-option [value]="'bookingDate'">Booking Date</mat-option>
                      <mat-option [value]="'bookingReference'">Booking Ref.</mat-option>
                      <mat-option [value]="'deptDate'">Departure Date</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>

                <span>
                  <mat-form-field class="orderHow">
                    <mat-select ngModel [(ngModel)]="currentRequest.orderHow" name="orderHow" #orderHow="ngModel" placeholder="Direction">
                      <mat-option [value]="'ASC'">Ascending</mat-option>
                      <mat-option [value]="'DESC'">Descending</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>

                <button style="width: 93.21px; margin-top: -5px !important; margin-left: 28.5px;" mat-raised-button color="primary" type="submit" [disabled]="getBookingListForm.invalid">Search</button>
              </form>
            </div>

            <div [@customExpansionDetails]="expansionSearch == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionSearch = true;">
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>

          <div style="width: 100%; margin-bottom: 20px;">
            <div class="ownExpansionHeader">
              <div class="headerTopLeft">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionList" (click)="expansionList = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionList" (click)="expansionList = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Search Result
              </div>

              <div style="height: 56px; float: right; display: flex; align-items: center;">
                <mat-form-field class="pageSize" style="width: 65px; margin-top: 11px;">
                  <mat-label style="color: white; font-size: 15px;">Page Size</mat-label>
                  <mat-select ngModel [(ngModel)]="pageSize" name="pageSizee" #pageSizee="ngModel" (selectionChange)="changePageSize($event.value)">
                    <mat-option *ngFor="let size of pageSizeOptions" [value]="size">
                      {{size}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <button class="buttonToRight" style="margin-right: 10px; margin-bottom: -5px;" mat-icon-button [disabled]="firstRes == 1" (click)="changePageNumber('previous')">
                  <mat-icon>arrow_back</mat-icon>
                </button>

                <button class="buttonToRight" style="margin-right: 20px; margin-bottom: -5px;" mat-icon-button [disabled]="bookingsData.data.length < pageSize" (click)="changePageNumber('next')">
                  <mat-icon>arrow_forward</mat-icon>
                </button>
              </div>
            </div>

            <div [@customExpansionDetails]="expansionList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
              <div *ngIf="bookingsData.data.length === 0">
                <h3 *ngIf="pageLoaded">Could not find any bookings</h3>
                <h3 *ngIf="!pageLoaded">Please wait..</h3>
              </div>

              <mat-table *ngIf="bookingsData.data.length > 0" class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="bookingsData">
                <ng-container matColumnDef="bookingReference">
                  <mat-header-cell *matHeaderCellDef>
                    <span class="clickBkgRef" (click)="showExtRefSwitch()" [matTooltip]="!showExtReference ? 'Switch to your references' : 'Switch to Sings references'">
                      Booking Reference
                    </span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let booking">
                    <a (click)="goToBooking(booking.bookingReference, 'normal')" style="font-family: Azeret Mono;">
                      <span *ngIf="!showExtReference">{{ booking.bookingReference | uppercase }}</span>
                      <span *ngIf="showExtReference">{{ booking.extBookingSource }}</span>
                    </a>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="tradeCode">
                  <mat-header-cell *matHeaderCellDef class="centerTable">Trade Code</mat-header-cell>
                  <mat-cell *matCellDef="let booking" class="centerTable">{{ booking.tradeCode }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="tradingName">
                  <mat-header-cell *matHeaderCellDef class="centerTable">Trading Name</mat-header-cell>
                  <mat-cell *matCellDef="let booking" class="centerTable">{{ booking.branchName }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="bookingDate">
                  <mat-header-cell *matHeaderCellDef class="centerTable">Booked</mat-header-cell>
                  <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.bookingDate | date:'dd.MM.yyyy'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="departureDate">
                  <mat-header-cell *matHeaderCellDef class="centerTable">Departure</mat-header-cell>
                  <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.deptDate | date:'dd.MM.yyyy'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="customerName">
                  <mat-header-cell *matHeaderCellDef class="centerTable">Client Name</mat-header-cell>
                  <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.leadName }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="totalGross">
                  <mat-header-cell *matHeaderCellDef class="centerTable">Selling Price</mat-header-cell>
                  <mat-cell *matCellDef="let booking" class="centerTable"> <b> {{ booking.custPrice | currency: 'GBP' }}</b> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="bookingStatus">
                  <mat-header-cell *matHeaderCellDef class="centerTable">Booking Status</mat-header-cell>
                  <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.bookingStatus | titlecase }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row class="rowNotClick" *matRowDef="let row; columns : displayedColumns;"></mat-row>
              </mat-table>
            </div>

            <div [@customExpansionDetails]="expansionList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionList = true;">
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>


        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Booking List</u></h1>
    <p>This page allows you to browse bookings within SinGS</p>

    <p>Please use the search criteria on the top of the page.<br>
      Client surname and date type will narrow down your search.</p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
