import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  template: `
    <div class="successMessage" style="white-space: pre-line; text-align: center;">
      <p>{{data}}</p>
    </div>
  `,
})

export class MessageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openMessageDialog(message: any): void {
    this.dialog.open(MessageDialogComponent, { data: message });
  }
}
