import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  async login(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'login', body, { headers }).toPromise();
    return output;
  }

  async authenticate(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'authUser', body, { headers }).toPromise();
    return output;
  }

  async getUserList(user: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'userList?tradeCode=' + user.tradeCode + '&token=' + user.token).toPromise();
    return output;
  }

  async updateUser(user: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(user);
    const output = await this.http.post(environment.apiURL + 'updateUser', body, { headers }).toPromise();
    return output;
  }

  async createUser(user: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(user);
    const output = await this.http.post(environment.apiURL + 'registerUser', body, { headers }).toPromise();
    return output;
  }

  async removeUser(user: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(user);
    const output = await this.http.post(environment.apiURL + 'removeUser', body, { headers }).toPromise();
    return output;
  }

  async getUser(userID: any, token: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'user?id=' + userID + '&token=' + token).toPromise();
    return output;
  }

  async switchBlockUser(user: any): Promise<object> {
    const output = await this.http.post(environment.apiURL + 'switchBlockUser?id=' + user.id + '&token=' + user.token, user).toPromise();
    return output;
  }

  async setOtherUserPassword(user: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(user);
    const output = await this.http.post(environment.apiURL + 'setOtherUserPassword', body, { headers }).toPromise();
    return output;
  }

  async setSelfTradeCode(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'setSelfTradeCode', body, { headers }).toPromise();
    return output;
  }

  async setSelfSignature(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'setSelfSignature', body, { headers }).toPromise();
    return output;
  }

  async generateRemindLink(request: any): Promise<object> {
    const output = await this.http.post(environment.apiURL + 'generateRemindLink?email=' + request.email + '&envProd=' + environment.production, request).toPromise();
    return output;
  }

  async writeError(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'logFrontError', body, { headers }).toPromise();
    return output;
  }

  /* Google calendar below */

  async setSelfGoogleAccount(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'setSelfGoogleAccount', body, { headers }).toPromise();
    return output;
  }

  async getGoogleEvents(request: any): Promise<object> {
    const calendarID = request.googleCalID;
    const publicKey = request.googleCalAPIkey;
    const dataUrl = 'https://www.googleapis.com/calendar/v3/calendars/' + calendarID + '/events?key=' + publicKey;

    const output = await this.http.get(dataUrl).toPromise();
    return output;
  }
}
