<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div fxLayout="row" fxLayoutAlign="start center" [@inAnimation] *ngIf="openedBooking != null && innerWidth > 1024" class="buttonBar">
      <div *ngFor="let div of elementDivs" id="div{{div}}">
        <button class="navButtonsElement" mat-button [disabled]="elementNoView[div]" (click)="switchView(div)">
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'flight'">flight</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'accommodation'">hotel</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'carParking'">local_parking</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'carHire'">drive_eta</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'attraction'">attractions</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'cruise'">directions_boat</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'miscellaneous'">miscellaneous_services</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'train'">train</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'transfer'">airport_shuttle</mat-icon>
          {{elementTypeNiceName}} {{div+1}}
        </button>
      </div>
      <button class="navButtons" style="width: 64px;" mat-button matTooltip="Remove Last" matTooltipClass="line-normal-tooltip" (click)="removeDiv()">
        <mat-icon>remove</mat-icon>
      </button>
      <button class="navButtons" style="width: 64px;" mat-button matTooltip="Add {{elementTypeNiceName}}" matTooltipClass="line-normal-tooltip" (click)="addDiv('view')">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" [@inAnimation] *ngIf="openedBooking != null && innerWidth < 1025" class="buttonBar">
      <div *ngFor="let div of elementDivs" id="div{{div}}">
        <button class="navButtonsElement" mat-button [disabled]="elementNoView[div]" (click)="switchView(div)">
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'flight'">flight</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'accommodation'">hotel</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'carParking'">local_parking</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'carHire'">drive_eta</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'attraction'">attractions</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'cruise'">directions_boat</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'miscellaneous'">miscellaneous_services</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'train'">train</mat-icon>
          <mat-icon class="topButtonIcons" *ngIf="elementType == 'transfer'">airport_shuttle</mat-icon>
        </button>
      </div>
      <button class="navButtons" style="width: 64px;" mat-button matTooltip="Remove Last" matTooltipClass="line-normal-tooltip" (click)="removeDiv()">
        <mat-icon>remove</mat-icon>
      </button>
      <button class="navButtons" style="width: 64px;" mat-button matTooltip="Add {{elementTypeNiceName}}" matTooltipClass="line-normal-tooltip" (click)="addDiv('view')">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="elementType != 'unknown' && openedBooking != null">

          <h1 fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>{{elementTypeNiceName}} Details
          </h1>

          <div class="elementCreateDiv" fxLayout="row" fxLayoutAlign="center none">
            <div fxFlex="50">
              <h3>Reservation Information</h3>
              <mat-form-field class="supplierType">
                <input matInput required ngModel placeholder="Supplier" name="supplierName" type="text" #supplierName="ngModel" readonly="true" [(ngModel)]="supplier.supplierNameM">
              </mat-form-field>

              <mat-form-field class="supplierReference" *ngIf="openedBooking.freshElement == 'yes'">
                <input matInput required ngModel placeholder="Supplier Reference" [(ngModel)]="supplierReference" minlength="3" maxlength="50" name="suppRef" type="text" #suppRef="ngModel">
              </mat-form-field>

              <mat-form-field class="supplierReference" *ngIf="openedBooking.freshElement == 'no'">
                <input matInput required ngModel placeholder="Supplier Reference" [(ngModel)]="openedBooking.supplierReference" minlength="3" readonly="true" name="suppRef" type="text" #suppRef="ngModel" maxlength="50">
              </mat-form-field>

              <mat-form-field style="max-width: 180px;" *ngIf="openedBooking.freshElement == 'yes'">
                <mat-label>Booking Date</mat-label>
                <input ngModel required maxlength="50" matInput name="bookingDate" [matDatepicker]="bookingDate" [(ngModel)]="today" (dateChange)="valiDate($event)">
                <mat-datepicker-toggle matSuffix [for]="bookingDate"></mat-datepicker-toggle>
                <mat-datepicker #bookingDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: 180px;" *ngIf="openedBooking.freshElement == 'no'">
                <mat-label>Booking Date</mat-label>
                <input ngModel required maxlength="50" matInput name="bookingDate" [matDatepicker]="bookingDate" [(ngModel)]="today" readonly="true" (dateChange)="valiDate($event)">
                <mat-datepicker #bookingDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex="50">
              <h3>Notes</h3>
              <mat-form-field style="width: 530px; max-width: unset;" *ngIf="openedBooking.freshElement == 'yes'">
                <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500" cdkTextareaAutosize ngModel name="comment" #comment="ngModel" [(ngModel)]="elementNotes" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
              </mat-form-field>

              <mat-form-field style="width: 530px; max-width: unset;" *ngIf="openedBooking.freshElement == 'no'">
                <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500" cdkTextareaAutosize ngModel name="comment" #comment="ngModel" [(ngModel)]="element.comment" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="elementCreateDiv" fxLayout="row" fxLayoutAlign="space-around stretch" style="margin-bottom: 150px;">
            <div fxFlex="50">
              <form *ngIf="elementType == 'flight'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createFlight(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="paxNo">
                      <mat-select ngModel required name="paxNo{{div}}" #paxNo{{div}}="ngModel" placeholder="Adults">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxNoInfants">
                      <mat-select ngModel name="paxNoInfants{{div}}" #paxNoInfants{{div}}="ngModel" placeholder="Children">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxInfants">
                      <mat-select ngModel name="paxInfants{{div}}" #paxInfants{{div}}="ngModel" placeholder="Infants">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="carrier">
                      <input matInput required ngModel placeholder="Carrier" id="carrier{{div}}" name="carrier{{div}}" maxlength="50" #carrier{{div}}="ngModel">
                    </mat-form-field>

                    <mat-form-field class="flightNo">
                      <input matInput required ngModel placeholder="Flight No." id="flightNo{{div}}" name="flightNo{{div}}" maxlength="30" #flightNo{{div}}="ngModel">
                    </mat-form-field>

                    <mat-form-field class="flightClass">
                      <mat-select ngModel name="flightClass{{div}}" #flightClass{{div}}="ngModel" placeholder="Flight Class" id="flightClass{{div}}">
                        <mat-option *ngFor="let class of flightClass" [value]="class">
                          {{class}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="departAirName">
                      <input matInput required ngModel placeholder="Dep. Airport Name" id="departAirName{{div}}" name="departAirName{{div}}" maxlength="100" #departAirName{{div}}="ngModel">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Depart Date</mat-label>
                      <input ngModel required matInput name="departDate{{div}}" [matDatepicker]="departDate" id="departDate{{div}}" (dateChange)="valiDate($event); setEndDate('departDate' + div, 'arriveDate' + div)">
                      <mat-datepicker-toggle matSuffix [for]="departDate"></mat-datepicker-toggle>
                      <mat-datepicker #departDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="departAirCode" style="width: 40px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="departHrs{{div}}" (change)="leadingZeroCheck('departHrs'+div)" name="departHrs{{div}}" min="0" max="23" #departHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="departAirCode" style="width: 40px;">
                      <input matInput ngModel placeholder="Min" type="number" id="departMin{{div}}" (change)="leadingZeroCheck('departMin'+div)" name="departMin{{div}}" min="0" max="59" #departMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="departAirCode" style="width: 80px;">
                      <input matInput ngModel placeholder="IATA Code" id="departAirCode{{div}}" name="departAirCode{{div}}" minlength="3" maxlength="3" #departAirCode{{div}}="ngModel">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="arriveAirName">
                      <input matInput required ngModel placeholder="Arr. Airport Name" id="arriveAirName{{div}}" name="arriveAirName{{div}}" maxlength="100" #arriveAirName{{div}}="ngModel">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Arrive Date</mat-label>
                      <input ngModel required matInput name="arriveDate{{div}}" [matDatepicker]="arriveDate" id="arriveDate{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="arriveDate"></mat-datepicker-toggle>
                      <mat-datepicker #arriveDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="departAirCode" style="width: 40px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="arriveHrs{{div}}" (change)="leadingZeroCheck('arriveHrs'+div)" name="arriveHrs{{div}}" min="0" max="23" #arriveHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="departAirCode" style="width: 40px;">
                      <input matInput ngModel placeholder="Min" type="number" id="arriveMin{{div}}" (change)="leadingZeroCheck('arriveMin'+div)" name="arriveMin{{div}}" min="0" max="59" #arriveMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="arriveAirCode" style="width: 80px;">
                      <input matInput ngModel placeholder="IATA Code" id="arriveAirCode{{div}}" name="arriveAirCode{{div}}" minlength="3" maxlength="3" #arriveAirCode{{div}}="ngModel">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="flightDirection">
                      <mat-select required ngModel name="direction{{div}}" #direction{{div}}="ngModel" placeholder="Direction" id="direction{{div}}">
                        <mat-option required [value]="'inbound'">Inbound</mat-option>
                        <mat-option required [value]="'internal'">Internal</mat-option>
                        <mat-option required [value]="'outbound'">Outbound</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'accommodation'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createHotel(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="accomName">
                      <input matInput required ngModel placeholder="Accommodation Name" maxlength="100" name="accomName{{div}}" type="text" #accomName{{div}}="ngModel" id="accomName{{div}}">
                    </mat-form-field>

                    <mat-form-field class="cityResort">
                      <input required matInput ngModel placeholder="City/Resort" id="city{{div}}" name="city{{div}}" type="text" #city{{div}}="ngModel" maxlength="100">
                    </mat-form-field>

                    <mat-form-field class="country">
                      <mat-select ngModel required name="country{{div}}" #country{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                        <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                        </mat-form-field>
                        <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                          {{country}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="min-width: 370px;" class="address">
                      <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="200" id="address{{div}}" name="address{{div}}" #address{{div}}="ngModel" ngModel ngModel cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" placeholder="Address" name="address" #address="ngModel"></textarea>
                    </mat-form-field>

                    <mat-form-field class="rating">
                      <mat-select ngModel name="rating{{div}}" #rating{{div}}="ngModel" placeholder="Rating" id="rating{{div}}">
                        <mat-option *ngFor="let rate of ratings" [value]="rate">
                          {{rate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Check-In Date</mat-label>
                      <input ngModel required matInput name="checkinDate{{div}}" [matDatepicker]="checkinDate" id="checkinDate{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="checkinDate"></mat-datepicker-toggle>
                      <mat-datepicker #checkinDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="nights">
                      <input required matInput ngModel placeholder="Nights" name="nights{{div}}" type="text" #nights{{div}}="ngModel" type="number" min="1" id="nights{{div}}">
                    </mat-form-field>

                    <p></p>

                    <span *ngFor="let roomNo of addnDataDiv[div]; let first = first;">
                      <h3>Room {{roomNo + 1}} Details</h3>

                      <mat-form-field class="roomTypes">
                        <input required matInput ngModel placeholder="Room Type" maxlength="100" name="roomType{{div}}R{{roomNo}}" type="text" #roomType{{div}}R{{roomNo}}="ngModel" id="roomType{{div}}R{{roomNo}}">
                      </mat-form-field>

                      <mat-form-field class="roomViews">
                        <input matInput ngModel placeholder="Room Views" maxlength="100" name="roomView{{div}}R{{roomNo}}" type="text" #roomView{{div}}R{{roomNo}}="ngModel" id="roomView{{div}}R{{roomNo}}">
                      </mat-form-field>

                      <mat-form-field class="boardBasis">
                        <mat-select required ngModel name="boardBasis{{div}}R{{roomNo}}" #boardBasis{{div}}R{{roomNo}}="ngModel" placeholder="Board Basis" id="boardBasis{{div}}R{{roomNo}}">
                          <mat-option *ngFor="let board of boardBasiss" [value]="board">
                            {{board}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <span *ngIf="first">
                        <button class="addRemoveButtons" mat-raised-button matTooltip="Remove Last" matTooltipClass="line-normal-tooltip" color="accent" (click)="removeAddnDiv(div)" type="button">
                          <mat-icon>remove</mat-icon>
                        </button>
                        <button class="addRemoveButtons" mat-raised-button matTooltip="Add Room" matTooltipClass="line-normal-tooltip" color="primary" (click)="addAddnDiv(div)" type="button">
                          <mat-icon>add</mat-icon>
                        </button>
                      </span>
                      <br />
                    </span>
                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'carParking'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createCarPark(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Start Date</mat-label>
                      <input ngModel required matInput name="startDate{{div}}" [matDatepicker]="startDate" id="startDate{{div}}" (dateChange)="valiDate($event); setEndDate('startDate' + div, 'endDate' + div)">
                      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                      <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="startHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="startDateHrs{{div}}" (change)="leadingZeroCheck('startDateHrs'+div)" name="startDateHrs{{div}}" min="0" max="23" #startDateHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="startMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="startDateMin{{div}}" (change)="leadingZeroCheck('startDateMin'+div)" name="startDateMin{{div}}" min="0" max="59" #startDateMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>End Date</mat-label>
                      <input ngModel required matInput name="endDate{{div}}" [matDatepicker]="endDate" id="endDate{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                      <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="endHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="endDateHrs{{div}}" (change)="leadingZeroCheck('endDateHrs'+div)" name="endDateHrs{{div}}" min="0" max="23" #endDateHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="endMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="endDateMin{{div}}" (change)="leadingZeroCheck('endDateMin'+div)" name="endDateMin{{div}}" min="0" max="59" #endDateMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="location">
                      <input matInput ngModel placeholder="Location" name="location{{div}}" maxlength="100" #location{{div}}="ngModel" type="text" id="location{{div}}">
                    </mat-form-field>

                    <mat-form-field class="carParkName">
                      <input matInput ngModel placeholder="Car Park Name" name="carParkName{{div}}" maxlength="100" #carParkName{{div}}="ngModel" type="text" id="carParkName{{div}}">
                    </mat-form-field>

                    <mat-form-field class="carParkCode">
                      <input matInput ngModel placeholder="Car Park Code" name="carParkCode{{div}}" maxlength="50" #carParkCode{{div}}="ngModel" type="text" id="carParkCode{{div}}">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="carMake">
                      <input matInput ngModel placeholder="Car Make" name="carMake{{div}}" maxlength="50" #carMake{{div}}="ngModel" type="text" id="carMake{{div}}">
                    </mat-form-field>

                    <mat-form-field class="carModel">
                      <input matInput ngModel placeholder="Car Model" name="carModel{{div}}" maxlength="50" #carModel{{div}}="ngModel" type="text" id="carModel{{div}}">
                    </mat-form-field>

                    <mat-form-field class="carReg">
                      <input matInput ngModel placeholder="Car Registration" name="carReg{{div}}" maxlength="10" #carReg{{div}}="ngModel" type="text" id="carReg{{div}}">
                    </mat-form-field>
                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'carHire'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createCarHire(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="paxNo">
                      <mat-select ngModel required name="paxNo{{div}}" #paxNo{{div}}="ngModel" placeholder="Adults">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxNoInfants">
                      <mat-select ngModel name="paxNoInfants{{div}}" #paxNoInfants{{div}}="ngModel" placeholder="Children">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="driver">
                      <mat-select ngModel required name="driver{{div}}" #driver{{div}}="ngModel" placeholder="Driver" id="driver{{div}}">
                        <mat-option *ngFor="let driver of driverArray" [value]="driver.id">
                          {{ driver.firstName }} {{ driver.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="childSeats">
                      <input matInput ngModel placeholder="Child Seats" name="childSeats{{div}}" min="0" max="10" #childSeats{{div}}="ngModel" type="number" id="childSeats{{div}}">
                    </mat-form-field>

                    <mat-form-field class="carType">
                      <input matInput ngModel placeholder="Car Type" name="carType{{div}}" maxlength="255" #carType{{div}}="ngModel" type="text" id="carType{{div}}">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="pickUpLocation">
                      <input matInput ngModel placeholder="Pick Up Location" name="pickUpLocation{{div}}" maxlength="50" #pickUpLocation{{div}}="ngModel" id="pickUpLocation{{div}}">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Pick Up Date</mat-label>
                      <input ngModel required matInput name="pickUpDate{{div}}" [matDatepicker]="pickUpDate" id="pickUpDate{{div}}" (dateChange)="valiDate($event); setEndDate('pickUpDate' + div, 'dropOffDate' + div)">
                      <mat-datepicker-toggle matSuffix [for]="pickUpDate"></mat-datepicker-toggle>
                      <mat-datepicker #pickUpDate></mat-datepicker>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="dropOffLocation">
                      <input matInput ngModel placeholder="Drop Off Location" name="dropOffLocation{{div}}" maxlength="50" #dropOffLocation{{div}}="ngModel" id="dropOffLocation{{div}}">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Drop Off Date</mat-label>
                      <input ngModel required matInput name="dropOffDate{{div}}" [matDatepicker]="dropOffDate" id="dropOffDate{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="dropOffDate"></mat-datepicker-toggle>
                      <mat-datepicker #dropOffDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'attraction'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createAttraction(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="paxNo">
                      <mat-select ngModel required name="paxNo{{div}}" #paxNo{{div}}="ngModel" placeholder="Adults">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxNoInfants">
                      <mat-select ngModel name="paxNoInfants{{div}}" #paxNoInfants{{div}}="ngModel" placeholder="Children">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxInfants">
                      <mat-select ngModel name="paxInfants{{div}}" #paxInfants{{div}}="ngModel" placeholder="Infants">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="eventName">
                      <input matInput required ngModel placeholder="Event Name" name="name{{div}}" maxlength="50" #name{{div}}="ngModel" id="eventName{{div}}">
                    </mat-form-field>

                    <mat-form-field class="location">
                      <input matInput ngModel placeholder="Location" name="location{{div}}" maxlength="50" #location{{div}}="ngModel" id="location{{div}}">
                    </mat-form-field>

                    <mat-form-field class="country">
                      <mat-select ngModel required name="country{{div}}" #country{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                        <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                        </mat-form-field>
                        <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                          {{country}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Start Date</mat-label>
                      <input ngModel required matInput name="startDateTime{{div}}" [matDatepicker]="startDateTime" id="startDateTime{{div}}" (dateChange)="valiDate($event); setEndDate('startDateTime' + div, 'endDateTime' + div)">
                      <mat-datepicker-toggle matSuffix [for]="startDateTime"></mat-datepicker-toggle>
                      <mat-datepicker #startDateTime></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="startHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="startDateTimeHrs{{div}}" (change)="leadingZeroCheck('startDateTimeHrs'+div)" name="startDateTimeHrs{{div}}" min="0" max="23" #startDateTimeHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="startMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="startDateTimeMin{{div}}" (change)="leadingZeroCheck('startDateTimeMin'+div)" name="startDateTimeMin{{div}}" min="0" max="59" #startDateTimeMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>End Date</mat-label>
                      <input ngModel required matInput name="endDateTime{{div}}" [matDatepicker]="endDateTime" id="endDateTime{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="endDateTime"></mat-datepicker-toggle>
                      <mat-datepicker #endDateTime></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="endHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="endDateTimeHrs{{div}}" (change)="leadingZeroCheck('endDateTimeHrs'+div)" name="endDateTimeHrs{{div}}" min="0" max="23" #endDateTimeHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="endMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="endDateTimeMin{{div}}" (change)="leadingZeroCheck('endDateTimeMin'+div)" name="endDateTimeMin{{div}}" min="0" max="59" #endDateTimeMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>
                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'cruise'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createCruise(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="paxNo">
                      <mat-select ngModel required name="paxNo{{div}}" #paxNo{{div}}="ngModel" placeholder="Adults">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxNoInfants">
                      <mat-select ngModel name="paxNoInfants{{div}}" #paxNoInfants{{div}}="ngModel" placeholder="Children">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxInfants">
                      <mat-select ngModel name="paxInfants{{div}}" #paxInfants{{div}}="ngModel" placeholder="Infants">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="cruiseLine">
                      <input matInput required ngModel placeholder="Cruise Line" name="cruiseLine{{div}}" maxlength="50" #cruiseLine{{div}}="ngModel" id="cruiseLine{{div}}">
                    </mat-form-field>

                    <mat-form-field class="cruiseName">
                      <input matInput required ngModel placeholder="Cruise Name" name="cruiseName{{div}}" maxlength="50" #cruiseName{{div}}="ngModel" id="cruiseName{{div}}">
                    </mat-form-field>

                    <mat-form-field class="cruiseShip">
                      <input matInput ngModel placeholder="Cruise Ship" name="cruiseShip{{div}}" maxlength="50" #cruiseShip{{div}}="ngModel" id="cruiseShip{{div}}">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="region">
                      <mat-select ngModel name="region{{div}}" #region{{div}}="ngModel" placeholder="Region" id="region{{div}}">
                        <mat-option *ngFor="let region of cruiseRegions" [value]="region">
                          {{region}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="visitingPort">
                      <input matInput ngModel placeholder="Visiting Port" name="visitingPort{{div}}" maxlength="50" #visitingPort{{div}}="ngModel" id="visitingPort{{div}}">
                    </mat-form-field>

                    <mat-form-field class="voyageCode">
                      <input matInput ngModel placeholder="Voyage Code" name="voyageCode{{div}}" maxlength="50" #voyageCode{{div}}="ngModel" id="voyageCode{{div}}">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Departure Date</mat-label>
                      <input ngModel required matInput name="departureDate{{div}}" [matDatepicker]="departureDate" id="departureDate{{div}}" (dateChange)="valiDate($event); setEndDate('departureDate' + div, 'returnDate' + div)">
                      <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                      <mat-datepicker #departureDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="departurePort">
                      <input matInput required ngModel placeholder="Departure Port" name="departurePort{{div}}" maxlength="50" #departurePort{{div}}="ngModel" id="departurePort{{div}}">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Return Date</mat-label>
                      <input ngModel required matInput name="returnDate{{div}}" [matDatepicker]="returnDate" id="returnDate{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="returnDate"></mat-datepicker-toggle>
                      <mat-datepicker #returnDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="disembarkPort">
                      <input matInput required ngModel placeholder="Disembark Port" name="disembarkPort{{div}}" maxlength="50" #disembarkPort{{div}}="ngModel" id="disembarkPort{{div}}">
                    </mat-form-field>

                    <h3>On Board Credit</h3>

                    <mat-form-field class="creditValue">
                      <input matInput ngModel placeholder="Credit Value" id="onBoardCreditVal{{div}}" name="onBoardCreditVal{{div}}" type="number" step="0.01" #onBoardCreditVal{{div}}="ngModel">
                    </mat-form-field>

                    <mat-form-field class="creditCurrency">
                      <mat-select ngModel name="onBoardCreditCur{{div}}" #onBoardCreditCur{{div}}="ngModel" placeholder="Currency">
                        <mat-option *ngFor="let currency of cruiseCurrencies" [value]="currency">
                          {{currency}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <span *ngFor="let cabinNo of addnDataDiv[div]; let first = first;">
                      <h3>Cabin {{cabinNo + 1}} Details</h3>

                      <mat-form-field class="cabinName">
                        <input matInput ngModel placeholder="Cabin Name" name="cabinName{{div}}C{{cabinNo}}" maxlength="100" #cabinName{{div}}C{{cabinNo}}="ngModel" id="cabinName{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <mat-form-field class="cabinGrade">
                        <input matInput ngModel placeholder="Cabin Grade" name="cabinGrade{{div}}C{{cabinNo}}" maxlength="100" #cabinGrade{{div}}C{{cabinNo}}="ngModel" id="cabinGrade{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <mat-form-field class="cabinNumber">
                        <input matInput ngModel placeholder="Cabin Number" name="cabinNumber{{div}}C{{cabinNo}}" maxlength="100" #cabinNumber{{div}}C{{cabinNo}}="ngModel" id="cabinNumber{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <span *ngIf="first">
                        <button class="addRemoveButtons" mat-raised-button matTooltip="Remove Last" matTooltipClass="line-normal-tooltip" color="accent" (click)="removeAddnDiv(div)" type="button">
                          <mat-icon>remove</mat-icon>
                        </button>
                        <button class="addRemoveButtons" mat-raised-button matTooltip="Add Cabin" matTooltipClass="line-normal-tooltip" color="primary" (click)="addAddnDiv(div)" type="button">
                          <mat-icon>add</mat-icon>
                        </button>
                      </span>

                      <p></p>

                      <mat-form-field class="cabinDeck">
                        <input matInput ngModel placeholder="Cabin Deck" name="cabinDeck{{div}}C{{cabinNo}}" maxlength="100" #cabinDeck{{div}}C{{cabinNo}}="ngModel" id="cabinDeck{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <mat-form-field class="shipSide">
                        <input matInput ngModel placeholder="Ship Side" name="shipSide{{div}}C{{cabinNo}}" maxlength="100" #shipSide{{div}}C{{cabinNo}}="ngModel" id="shipSide{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <mat-form-field class="cabinPosition">
                        <input matInput ngModel placeholder="Cabin Position" name="cabinPosition{{div}}C{{cabinNo}}" maxlength="100" #cabinPosition{{div}}C{{cabinNo}}="ngModel" id="cabinPosition{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <p></p>

                      <mat-form-field class="bedType">
                        <input matInput ngModel placeholder="Bed Type" name="bedType{{div}}C{{cabinNo}}" maxlength="100" #bedType{{div}}C{{cabinNo}}="ngModel" id="bedType{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <mat-form-field class="seating">
                        <input matInput ngModel placeholder="Seating" name="seating{{div}}C{{cabinNo}}" maxlength="100" #seating{{div}}C{{cabinNo}}="ngModel" id="seating{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <mat-form-field class="tableSize">
                        <input matInput ngModel placeholder="Table Size" name="tableSize{{div}}C{{cabinNo}}" maxlength="100" #tableSize{{div}}C{{cabinNo}}="ngModel" id="tableSize{{div}}C{{cabinNo}}">
                      </mat-form-field>

                      <br />
                    </span>

                    <p></p>
                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'miscellaneous'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createMiscellaneous(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="paxNo">
                      <mat-select ngModel required name="paxNo{{div}}" #paxNo{{div}}="ngModel" placeholder="Adults">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxNoInfants">
                      <mat-select ngModel name="paxNoInfants{{div}}" #paxNoInfants{{div}}="ngModel" placeholder="Children">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxInfants">
                      <mat-select ngModel name="paxInfants{{div}}" #paxInfants{{div}}="ngModel" placeholder="Infants">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="eventName">
                      <input matInput required ngModel placeholder="Misc Name" name="name{{div}}" maxlength="50" #name{{div}}="ngModel" id="eventName{{div}}">
                    </mat-form-field>

                    <mat-form-field class="location">
                      <input matInput ngModel placeholder="Location" name="location{{div}}" maxlength="50" #location{{div}}="ngModel" id="location{{div}}">
                    </mat-form-field>

                    <mat-form-field class="country">
                      <mat-select ngModel required name="country{{div}}" #country{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                        <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                        </mat-form-field>
                        <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                          {{country}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Start Date</mat-label>
                      <input ngModel required matInput name="startDateTime{{div}}" [matDatepicker]="startDateTime" id="startDateTime{{div}}" (dateChange)="valiDate($event); setEndDate('startDateTime' + div, 'endDateTime' + div)">
                      <mat-datepicker-toggle matSuffix [for]="startDateTime"></mat-datepicker-toggle>
                      <mat-datepicker #startDateTime></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="startHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="startDateTimeHrs{{div}}" (change)="leadingZeroCheck('startDateTimeHrs'+div)" name="startDateTimeHrs{{div}}" min="0" max="23" #startDateTimeHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="startMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="startDateTimeMin{{div}}" (change)="leadingZeroCheck('startDateTimeMin'+div)" name="startDateTimeMin{{div}}" min="0" max="59" #startDateTimeMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>End Date</mat-label>
                      <input ngModel required matInput name="endDateTime{{div}}" [matDatepicker]="endDateTime" id="endDateTime{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="endDateTime"></mat-datepicker-toggle>
                      <mat-datepicker #endDateTime></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="endHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="endDateTimeHrs{{div}}" (change)="leadingZeroCheck('endDateTimeHrs'+div)" name="endDateTimeHrs{{div}}" min="0" max="23" #endDateTimeHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="endMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="endDateTimeMin{{div}}" (change)="leadingZeroCheck('endDateTimeMin'+div)" name="endDateTimeMin{{div}}" min="0" max="59" #endDateTimeMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>
                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'train'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createTrain(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="paxNo">
                      <mat-select ngModel required name="paxNo{{div}}" #paxNo{{div}}="ngModel" placeholder="Adults">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxNoInfants">
                      <mat-select ngModel name="paxNoInfants{{div}}" #paxNoInfants{{div}}="ngModel" placeholder="Children">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxInfants">
                      <mat-select ngModel name="paxInfants{{div}}" #paxInfants{{div}}="ngModel" placeholder="Infants">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <h3>Departure</h3>

                    <mat-form-field class="departStation">
                      <input matInput required ngModel placeholder="Station Name" name="departStation{{div}}" maxlength="100" #departStation{{div}}="ngModel" id="departStation{{div}}">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Departure Date</mat-label>
                      <input ngModel required matInput name="departDateTime{{div}}" [matDatepicker]="departureDate" id="departDateTime{{div}}" (dateChange)="valiDate($event); setEndDate('departDateTime' + div, 'arriveDateTime' + div)">
                      <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                      <mat-datepicker #departureDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="departHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="departHrs{{div}}" (change)="leadingZeroCheck('departHrs'+div)" name="departHrs{{div}}" min="0" max="23" #departHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="departMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="departMin{{div}}" (change)="leadingZeroCheck('departMin'+div)" name="departMin{{div}}" min="0" max="59" #departMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <h3>Arrival</h3>

                    <mat-form-field class="arriveStation">
                      <input matInput required ngModel placeholder="Station Name" name="arriveStation{{div}}" maxlength="100" #arriveStation{{div}}="ngModel" id="arriveStation{{div}}">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Arrival Date</mat-label>
                      <input ngModel required matInput name="arriveDateTime{{div}}" [matDatepicker]="dropDate" id="arriveDateTime{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="dropDate"></mat-datepicker-toggle>
                      <mat-datepicker #dropDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="arriveHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="arriveHrs{{div}}" (change)="leadingZeroCheck('arriveHrs'+div)" name="arriveHrs{{div}}" min="0" max="23" #arriveHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="arriveMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="arriveMin{{div}}" (change)="leadingZeroCheck('arriveMin'+div)" name="arriveMin{{div}}" min="0" max="59" #arriveMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <h3>Extras</h3>

                    <mat-form-field class="trainNameNo">
                      <input matInput ngModel placeholder="Train Name / Number" name="trainNameNo{{div}}" maxlength="50" #trainNameNo{{div}}="ngModel" id="trainNameNo{{div}}">
                    </mat-form-field>

                    <mat-form-field class="carriageClass">
                      <input matInput ngModel placeholder="Carriage Class(es)" name="carriageClass{{div}}" maxlength="50" #carriageClass{{div}}="ngModel" id="carriageClass{{div}}">
                    </mat-form-field>

                    <mat-form-field class="seat">
                      <input matInput ngModel placeholder="Seat Number(s)" name="seat{{div}}" maxlength="10" #seat{{div}}="ngModel" id="seat{{div}}">
                    </mat-form-field>

                  </div>
                </div>
              </form>

              <form *ngIf="elementType == 'transfer'" class="withinMatCard" #createForm="ngForm" id="ngForm" (ngSubmit)="createTransfer(createForm)">
                <div *ngFor="let div of elementDivs" id="div{{div}}">
                  <div [hidden]="!elementNoView[div]">
                    <h3>Details</h3>
                    <mat-form-field class="paxNo">
                      <mat-select ngModel required name="paxNo{{div}}" #paxNo{{div}}="ngModel" placeholder="Adults">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxNoInfants">
                      <mat-select ngModel name="paxNoInfants{{div}}" #paxNoInfants{{div}}="ngModel" placeholder="Children">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="paxInfants">
                      <mat-select ngModel name="paxInfants{{div}}" #paxInfants{{div}}="ngModel" placeholder="Infants">
                        <mat-option *ngFor="let number of paxNumber" [value]="number">
                          {{number}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="country">
                      <mat-select ngModel required name="country{{div}}" #country{{div}}="ngModel" placeholder="Country" id="country{{div}}">
                        <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                        </mat-form-field>
                        <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                          {{country}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="vehicleType">
                      <input matInput ngModel placeholder="Vehicle Type" name="VehicleType{{div}}" maxlength="50" #VehicleType{{div}}="ngModel" id="vehicleType{{div}}">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="pickUpLocation">
                      <input matInput required ngModel placeholder="Pick Up Location" name="pickUpLocation{{div}}" maxlength="50" #pickUpLocation{{div}}="ngModel" id="pickUpLocation{{div}}">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Pick Up Date</mat-label>
                      <input ngModel required matInput name="pickUpDate{{div}}" [matDatepicker]="departureDate" id="pickUpDateTime{{div}}" (dateChange)="valiDate($event); setEndDate('pickUpDateTime' + div, 'dropOffDate' + div)">
                      <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
                      <mat-datepicker #departureDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="pickUpHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="pickUpHrs{{div}}" (change)="leadingZeroCheck('pickUpHrs'+div)" name="pickUpHrs{{div}}" min="0" max="23" #pickUpHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="pickUpMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="pickUpMin{{div}}" (change)="leadingZeroCheck('pickUpMin'+div)" name="pickUpMin{{div}}" min="0" max="59" #pickUpMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="dropOffLocation">
                      <input matInput required ngModel placeholder="Drop Off Location" name="dropOffLocation{{div}}" maxlength="50" #dropOffLocation{{div}}="ngModel" id="dropOffLocation{{div}}">
                    </mat-form-field>

                    <mat-form-field style="max-width: 180px;">
                      <mat-label>Drop Off Date</mat-label>
                      <input ngModel required matInput name="dropOffDate{{div}}" [matDatepicker]="dropDate" id="dropOffDate{{div}}" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="dropDate"></mat-datepicker-toggle>
                      <mat-datepicker #dropDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="dropHrs" style="width: 85px;">
                      <input matInput ngModel placeholder="Hrs" type="number" id="dropHrs{{div}}" (change)="leadingZeroCheck('dropHrs'+div)" name="dropHrs{{div}}" min="0" max="23" #dropHrs{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <mat-form-field class="dropMin" style="width: 85px;">
                      <input matInput ngModel placeholder="Min" type="number" id="dropMin{{div}}" (change)="leadingZeroCheck('dropMin'+div)" name="dropMin{{div}}" min="0" max="59" #dropMin{{div}}="ngModel" style="width: 85px;">
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="pickUpInstructions">
                      <input matInput ngModel placeholder="Pick Up Instructions" name="pickUpInstructions{{div}}" maxlength="200" #pickUpInstructions{{div}}="ngModel" id="pickUpInstructions{{div}}">
                    </mat-form-field>

                    <mat-form-field class="dropOffInstructions">
                      <input matInput ngModel placeholder="Drop Off Instructions" name="dropOffInstructions{{div}}" maxlength="200" #dropOffInstructions{{div}}="ngModel" id="dropOffInstructions{{div}}">
                    </mat-form-field>

                    <h3>Emergency Details</h3>

                    <mat-form-field class="emergencyResortContact">
                      <input matInput ngModel placeholder="Resort Name" name="emergencyResortContact{{div}}" maxlength="50" #emergencyResortContact{{div}}="ngModel" id="emergencyResortContact{{div}}">
                    </mat-form-field>

                    <mat-form-field class="emergencyPhoneNo">
                      <input matInput ngModel placeholder="Phone Number" name="emergencyPhoneNo{{div}}" maxlength="50" #emergencyPhoneNo{{div}}="ngModel" id="emergencyPhoneNo{{div}}">
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>

            <div fxFlex="50">
              <div *ngFor="let div of elementDivs" id="div{{div}}">
                <div [hidden]="!elementNoView[div]">
                  <h3>Cost</h3>
                  <mat-form-field style="width: 530px; max-width: unset;">
                    <input matInput ngModel placeholder="Description" [(ngModel)]="costingBreakdown[div].description" id="description{{div}}" maxlength="200" name="description{{div}}" type="text" #description{{div}}="ngModel">
                  </mat-form-field>

                  <p></p>

                  <mat-form-field class="supplierPrice">
                    <input matInput required ngModel placeholder="Gross" [(ngModel)]="costingBreakdown[div].supplierPrice" (ngModelChange)="changeSuppPrice($event, div)" id="supplierPrice{{div}}" name="supplierPrice{{div}}" type="number" min="0.01"
                      step="0.01" #supplierPrice{{div}}="ngModel">
                    <mat-hint matTooltip="Actual Gross (applied discount)" matTooltipClass="line-normal-tooltip">{{ costingBreakdown[div].realGross | currency: 'GBP' }}</mat-hint>
                  </mat-form-field>

                  <mat-form-field class="net">
                    <input required matInput ngModel placeholder="Net" [(ngModel)]="costingBreakdown[div].netPrice" id="net{{div}}" name="net{{div}}" type="number" step="0.01" #net{{div}}="ngModel" (ngModelChange)="changeNet($event, div)">
                  </mat-form-field>

                  <mat-form-field class="commission">
                    <input matInput ngModel placeholder="Commission" [(ngModel)]="costingBreakdown[div].commissionPrice" id="commission{{div}}" name="commission{{div}}" type="number" step="0.01" #commission{{div}}="ngModel" readonly="true">
                  </mat-form-field>

                  <mat-form-field class="vat">
                    <input required matInput ngModel placeholder="VAT" [(ngModel)]="costingBreakdown[div].vatPrice" id="vat{{div}}" name="vat{{div}}" type="number" step="0.01" #vat{{div}}="ngModel" (ngModelChange)="changeVAT($event, div)">
                  </mat-form-field>

                  <mat-form-field class="discount">
                    <input required matInput ngModel placeholder="Discount" [(ngModel)]="costingBreakdown[div].discountPrice" id="discount{{div}}" name="discount{{div}}" type="number" step="0.01" #discount{{div}}="ngModel"
                      (ngModelChange)="changeDiscount($event, div)">
                  </mat-form-field>

                  <mat-form-field class="deposit">
                    <input required matInput ngModel placeholder="Deposit" [(ngModel)]="costingBreakdown[div].depositPrice" id="deposit{{div}}" name="deposit{{div}}" type="number" step="0.01" #deposit{{div}}="ngModel" (ngModelChange)="addUpCostings()">
                  </mat-form-field>
                </div>
              </div>
              <h3>Total</h3>
              <mat-form-field class="supplierPrice">
                <input matInput ngModel placeholder="Gross" [(ngModel)]="totalCosts.supplierPrice" name="supplierPricee" type="number" step="0.01" #supplierPricee="ngModel" readonly="true">
                <mat-hint matTooltip="Actual Gross (applied discount)" matTooltipClass="line-normal-tooltip">{{ totalCosts.realGross | currency: 'GBP' }}</mat-hint>
              </mat-form-field>

              <mat-form-field class="net">
                <input matInput ngModel placeholder="Net" [(ngModel)]="totalCosts.netPrice" name="net" type="number" step="0.01" #net="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="commission">
                <input matInput ngModel placeholder="Commission" [(ngModel)]="totalCosts.commissionPrice" name="commission" type="number" step="0.01" #commission="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="vat">
                <input matInput ngModel placeholder="VAT" [(ngModel)]="totalCosts.vatPrice" name="vat" type="number" step="0.01" #vat="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="discount">
                <input matInput ngModel placeholder="Discount" [(ngModel)]="totalCosts.discountPrice" name="discount" type="number" step="0.01" #discount="ngModel" readonly="true">
              </mat-form-field>

              <mat-form-field class="deposit">
                <input matInput ngModel placeholder="Deposit" [(ngModel)]="totalCosts.depositPrice" name="deposit" type="number" step="0.01" #deposit="ngModel" readonly="true">
              </mat-form-field>

              <h5>Please make sure you've filled in all mandatory fields marked with a start (*) in all {{elementTypeNiceName}}s</h5>
              <h5><button class="buttonClass" mat-raised-button color="primary" type="submit" id="create" form="ngForm" *ngIf="memberLive">Confirm</button></h5>
            </div>
          </div>
        </div>

        <div class="accessDenied" *ngIf="elementType == 'unknown'">
          <h2 style="text-align: center; margin-top: 2.5%;">Sorry - how did you get here?</h2>
        </div>

        <div class="accessDenied" *ngIf="openedBooking == null">
          <h2 style="text-align: center; margin-top: 2.5%;">You need to select booking you wish to add element to first.</h2>
        </div>
      </mat-card>
    </mat-sidenav-content>

  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Create Element</u></h1>
    <p>This is where you can add the first element to a new supplier reference.</p>

    <p>You'll find there are 2 main sections to fill in:<br>
      <b>1. Details</b> contains basic information about the element<br>
      <b>2. Costing Details</b> is being used for the element pricing
    </p>

    <p>Please remember - number of the costing will reflect the number of the element.<br>
      For example: <b>Flight 1 Details</b> costings will be under <b>Costing 1</b></p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
