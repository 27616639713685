import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class EmailTemplates {
  static myEmails: any;

  // Variable used in all components which 'saves' current data. Needs to be initialised at the beginning of app.component
  public initialise(): void {
    EmailTemplates.myEmails = this;
  }

  customEmails = [
    { tradeCode: 'Q6238', type: 'link' }, { tradeCode: '6238Q', type: 'link' },
];

  public getFellohEmailBody(urlLink: any, paymentData: any, branchData: any, userData: any, type: any): void {
    if (this.customEmails.find((customEmail: any) => customEmail.tradeCode === branchData.tradeCode && customEmail.type === type)) {
        this.getCustomFellohEmailBody(urlLink, paymentData, branchData, userData, type);
    } else {
        this.getDefaultFellohEmailBody(urlLink, paymentData, branchData, userData, type);
    }
  }

  private getCustomFellohEmailBody(urlLink: any, paymentData: any, branchData: any, userData: any, type: any): void {
    let branchName = encodeURIComponent(branchData.branchName);
    let telephone = encodeURIComponent(branchData.telephone);
    let email = encodeURIComponent(branchData.email);

    if (['Q6238', '6238Q'].includes(branchData.tradeCode) && type === 'link') {
        const hyperlink = encodeURIComponent('https://www.bestonlineholidays.co.uk/terms.aspx');

        if (branchData.tradeCode === 'Q6238') {
            branchName = encodeURIComponent('Premier Leisure');
        } else if (branchData.tradeCode === '6238Q') {
            telephone = encodeURIComponent('02038831747');
            email = encodeURIComponent('customer.support@bestonlineholidays.co.uk');
        }

        window.open(`mailto:${paymentData.customer.email}?subject=Payment link for booking ${paymentData.merchantRequestId}&body=` +
        `Dear ${paymentData.customer.name}%2c%0d%0a%0d%0a${branchName} are requesting you to make a payment based on your chosen travel request.%0d` +
        `${urlLink}%0d%0d` + 
        `By completing this payment%2c you agree to our terms and conditions. Please follow the below link.%0d` +
        `${hyperlink}%0d%0dIf you have any concerns about this payment please contact ${branchName}.%0d` +
        `Email: ${email}%0dTelephone: ${telephone}` +
        `%0d%0a%0d%0aKind regards%2c%0d ${branchName}`);
    }
  }

  private getDefaultFellohEmailBody(urlLink: any, paymentData: any, branchData: any, userData: any, type: any): void {
    // Encode below data, so any 'out of ordinary' characters are passed without a problem
    const branchName = encodeURIComponent(branchData.branchName);
    const telephone = encodeURIComponent(branchData.telephone);
    const fullName = encodeURIComponent(userData.fullName);
    
    // Depending on selected button, we'll fill these in - see below for more details..
    let subject = ''; let intro = ''; let midWrd = '';
    if (type === 'link') {
        subject = `Payment link for booking ${paymentData.merchantRequestId}`;
    
        if (paymentData.additionalProperties.supplierName.length > 0) {
            intro = `The payment link for your booking with ${encodeURIComponent(paymentData.additionalProperties.supplierName[0])} is ready.`;
        } else {
            intro = `The payment link for your booking is ready.`;
        }
        midWrd = `This link is valid for a maximum of 30 days.%0d%0a%0d%0a`;
    } else if (type === 'reminder') {
        subject = `Payment reminder for booking ${paymentData.merchantRequestId}`;
        intro = `This is a kind reminder that your payment link is due for booking ${paymentData.merchantRequestId}.`;
        midWrd = ``;
    }
    // Open new window which will force default emailer to pre-populate email text
    window.open(`mailto:${paymentData.customer.email}?subject=${subject}&body=${moment().format('Do MMMM YYYY')}` +
    `%0d%0a%0d%0aDear ${paymentData.customer.name}%2c%0d%0a%0d%0a${intro}%0d%0a%0d%0a` +
    `To make your payment%2c please click on the link: ${urlLink}%0d%0a%0d%0a${midWrd}` +
    `If you have any questions%2c please contact us at ${branchName}` +
    `%2c our phone number is: ${telephone}.%0d%0a%0d%0aThank you in advance.%0d%0a%0d%0a` +
    `Kind regards%2c%0D${fullName}%0D`);
  }

  // BELOW ARE METHODS WHICH WILL BE USED IN A V2 FELLOH PAYMENT LINKS PAGES
  public getFellohEmailBodyV2(urlLink: any, paymentData: any, branchData: any, userData: any, type: any): void {
    if (this.customEmails.find((customEmail: any) => customEmail.tradeCode === branchData.tradeCode && customEmail.type === type)) {
        this.getCustomFellohEmailBodyV2(urlLink, paymentData, branchData, userData, type);
    } else {
        this.getDefaultFellohEmailBodyV2(urlLink, paymentData, branchData, userData, type);
    }
  }

  private getCustomFellohEmailBodyV2(urlLink: any, paymentData: any, branchData: any, userData: any, type: any): void {
    let branchName = encodeURIComponent(branchData.branchName);
    let telephone = encodeURIComponent(branchData.telephone);
    let email = encodeURIComponent(branchData.email);

    if (['Q6238', '6238Q'].includes(branchData.tradeCode) && type === 'link') {
        const hyperlink = encodeURIComponent('https://www.bestonlineholidays.co.uk/terms.aspx');

        if (branchData.tradeCode === 'Q6238') {
            branchName = encodeURIComponent('Premier Leisure');
        } else if (branchData.tradeCode === '6238Q') {
            telephone = encodeURIComponent('02038831747');
            email = encodeURIComponent('customer.support@bestonlineholidays.co.uk');
        }

        window.open(`mailto:${paymentData.email}?subject=Payment link for booking ${paymentData.bookingReference}&body=` +
        `Dear ${paymentData.customer_name}%2c%0d%0a%0d%0a${branchName} are requesting you to make a payment based on your chosen travel request.%0d` +
        `${urlLink}%0d%0d` + 
        `By completing this payment%2c you agree to our terms and conditions. Please follow the below link.%0d` +
        `${hyperlink}%0d%0dIf you have any concerns about this payment please contact ${branchName}.%0d` +
        `Email: ${email}%0dTelephone: ${telephone}` +
        `%0d%0a%0d%0aKind regards%2c%0d ${branchName}`);
    }
  }

  private getDefaultFellohEmailBodyV2(urlLink: any, paymentData: any, branchData: any, userData: any, type: any): void {
    // Encode below data, so any 'out of ordinary' characters are passed without a problem
    const branchName = encodeURIComponent(branchData.branchName);
    const telephone = encodeURIComponent(branchData.telephone);
    const fullName = encodeURIComponent(userData.fullName);
    
    // Depending on selected button, we'll fill these in - see below for more details..
    let subject = ''; let intro = ''; let midWrd = '';
    if (type === 'link') {
        subject = `Payment link for booking ${paymentData.bookingReference}`;
    
        if (paymentData.metadata?.supplierName?.length > 0) {
            intro = `The payment link for your booking with ${encodeURIComponent(paymentData.metadata.supplierName[0])} is ready.`;
        } else {
            intro = `The payment link for your booking is ready.`;
        }
        midWrd = `This link is valid for a maximum of 30 days.%0d%0a%0d%0a`;
    } else if (type === 'reminder') {
        subject = `Payment reminder for booking ${paymentData.bookingReference}`;
        intro = `This is a kind reminder that your payment link is due for booking ${paymentData.bookingReference}.`;
        midWrd = ``;
    }
    // Open new window which will force default emailer to pre-populate email text
    window.open(`mailto:${paymentData.email}?subject=${subject}&body=${moment().format('Do MMMM YYYY')}` +
    `%0d%0a%0d%0aDear ${paymentData.customer_name}%2c%0d%0a%0d%0a${intro}%0d%0a%0d%0a` +
    `To make your payment%2c please click on the link: ${urlLink}%0d%0a%0d%0a${midWrd}` +
    `If you have any questions%2c please contact us at ${branchName}` +
    `%2c our phone number is: ${telephone}.%0d%0a%0d%0aThank you in advance.%0d%0a%0d%0a` +
    `Kind regards%2c%0D${fullName}%0D`);
  }
}
