import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from '../../common/session';
import { ElementService } from '../../services/element.service';
import { UserService } from '../../services/user.service';
import { BookingService } from '../../services/booking.service';
import { GlobalConstants } from '../../common/global-constants';
import { AppComponent } from '../../app.component';
import { HostListener, ElementRef } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';

@Component({
  selector: 'app-element-package',
  templateUrl: './element-package.component.html',
  styleUrls: ['./element-package.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class ElementPackageComponent implements OnInit {
  // Boolean deciding whether user has access or not
  pageLoaded = false;
  operation: any = '';
  userType = '';

  // Element and booking related variables
  element: any = []; // Contains information about the element
  elementData: any = {}; // Contains data which we'll create/edit element with
  elementType: any = ''; // Type of an element we're editing
  openedBooking: any; // Contains information about the booking (basic info needed for CORE stuff)
  supplier: any = {}; // Contains supplier info (number of days etc) (needed when creating new element)
  constDeptDate: any = ''; // This is the dept date we will come back to in case of an error
  constReturnDate: any = ''; // this is the return date we will come back to in case of an error

  // Package-specific variables
  selectedEleType = 'Flight'; // As a default, show flights first on the screen
  flightDivs: number[] = []; flightNoView: boolean[] = []; flightInfo: any = []; initialFlightNo: any = 0; // Needed for sub-element breakdown
  hotelDivs: number[] = []; hotelNoView: boolean[] = []; hotelInfo: any = []; initialHotelNo: any = 0; initialRoomNo: any = []; // Needed for sub-element breakdown
  hotelRoomDivs: number[][] = []; // Array containing additonal data such as cruise-cabins or hotel-rooms (one to many)
  carParkDivs: number[] = []; carParksNoView: boolean[] = []; carParkInfo: any = []; initialCarParkNo: any = 0; // Needed for sub-element breakdown
  carHireDivs: number[] = []; carHireNoView: boolean[] = []; carHireInfo: any = []; initialCarHireNo: any = 0; // Needed for sub-element breakdown
  attraDivs: number[] = []; attraNoView: boolean[] = []; attraInfo: any = []; initialAttraNo: any = 0; // Needed for sub-element breakdown
  cruiseDivs: number[] = []; cruiseNoView: boolean[] = []; cruiseInfo: any = []; initialCruiseNo: any = 0; initialCabinNo: any = []; // Needed for sub-element breakdown
  cruiseCabinDivs: number[][] = []; // Array containing additonal data such as cruise-cabins or hotel-rooms (one to many)
  miscDivs: number[] = []; miscNoView: boolean[] = []; miscInfo: any = []; initialMiscNo: any = 0; // Needed for sub-element breakdown
  transferDivs: number[] = []; transferNoView: boolean[] = []; transferInfo: any = []; initialTransferNo: any = 0; // Needed for sub-element breakdown
  trainDivs: number[] = []; trainNoView: boolean[] = []; trainInfo: any = []; initialTrainNo: any = 0; // Needed for sub-element breakdown
  subElementHashNo = 0;

  // Other variables
  costingBreakdown: any = { description: '', supplierPrice: 0, realGross: 0, vatPrice: 0, discountPrice: 0, netPrice: 0, commissionPrice: 0, depositPrice: 0 }; // Holds costing info
  totalCosts: any = { supplierPrice: 0, realGross: 0, commissionPrice: 0, vatPrice: 0, discountPrice: 0, netPrice: 0, depositPrice: 0 };
  driverArray: any = []; // Needed for assigning the driver to the element
  errorMessage: any = '';
  successMessage: any = '';
  filterString: any = ''; // Used to filter country list (and maybe more in the future..)
  destCountry: any = ''; // Used to set the destination country..

  // Imported variables from outside
  constants = new GlobalConstants();
  countries = GlobalConstants.countryList;
  countriesFiltered = GlobalConstants.countryList;
  flightCategories = GlobalConstants.flightCategories;
  hotelCategories = GlobalConstants.hotelCategories;
  cruiseRegions = GlobalConstants.cruiseRegions;
  cruiseCurrencies = GlobalConstants.cruiseCurrencies;
  paxNumber = GlobalConstants.paxNumber;
  ratings = GlobalConstants.ratings;
  innerWidth = AppComponent.myapp.innerWidth;
  memberLive = AppComponent.myapp.memberLive;

  // Pretty static variables
  today: any = new Date(); // Needed for booking date
  supplierReference: any = ''; // Outside of form hence need for this variable..
  elementNotes: any = ''; // Main element notes - outside of form hence need for this variable..
  boardBasiss = ['All Inclusive', 'As Brochure', 'Bed Breakfast', 'Chalet Board', 'Flight Only', 'Full Board', 'Half Board', 'Room Only', 'Self Catering'];
  flightClass: string[] = ['Business', 'Economy', 'First', 'Premium Economy'];

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('createForm') createForm!: NgForm;

  constructor(private route: ActivatedRoute, private router: Router, private el: ElementRef,
              private userService: UserService, private bookingService: BookingService,
              private elementService: ElementService, public dialog: MatDialog,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      this.router.navigate(['/']);
    } else {
      this.route.params.subscribe(params => {
        this.userType = Session.mySession.getUser().userType; // Assign user type immediately
        this.openedBooking = Session.mySession.getOpenedBooking(); // Get booking's details here (TBD this will change)
        this.supplier = Session.mySession.getElementSupplier();
        this.element = window.history.state; // Holds element (supplier) data and sub-elements (types)
        this.constDeptDate = this.element.deptDate; // Assign const variable of dept date
        this.constReturnDate = this.element.returnDate; // Assign const variable of return date
        this.driverArray = Session.mySession.getLatestPax(); // Get the passenger list from session - used for Car Hire only (for now?)
        this.today = this.constants.convertDateNotMoment(this.today); // Convert today's date here
        this.operation = params.operation; // Assign operation on the package ( create / edit )

        if (this.operation === 'edit') {
          if (this.element.accoms.length > 0) {
            this.element.accoms.forEach((value: any, index: any) => {
              const objectValues: any = value;
              objectValues.rating = Number(objectValues.rating); // Rating needs to be converted to number for select list
              this.hotelInfo.push(objectValues); // Add element to costings array
              this.initialHotelNo += 1; // Will be used to block removing flights from array
              this.addDiv('Accommodation', null);
              this.initialRoomNo.push(objectValues.addndata.length); // This will block removing existing cabins / rooms
              objectValues.addndata.forEach(() => { this.addAddnDiv(index, 'Accommodation'); }); // Add addnDiv to the variable
            });
          }
          if (this.element.flights.length > 0) {
            this.element.flights.forEach((value: any) => {
              const objectValues: any = value;
              objectValues.departHrs = objectValues.departDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.departMin = objectValues.departDateTime.substr(14, 2); // Date time not supported by mat date picker
              objectValues.arriveHrs = objectValues.arriveDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.arriveMin = objectValues.arriveDateTime.substr(14, 2); // Date time not supported by mat date picker
              this.flightInfo.push(objectValues); // Add element to costings array
              this.initialFlightNo += 1; // Will be used to block removing flights from array
              this.addDiv('Flight', null);
            });
          }
          if (this.element.carhires.length > 0) {
            this.driverArray = Session.mySession.getLatestPax(); // Get the passenger list from session - used for Car Hire only (for now?)
            this.element.carhires.forEach((value: any) => {
              const objectValues: any = value;
              this.carHireInfo.push(objectValues); // Add element to costings array
              this.initialCarHireNo += 1; // Will be used to block removing flights from array
              this.addDiv('Car Hire', null);
            });
          }
          if (this.element.carparks.length > 0) {
            this.element.carparks.forEach((value: any) => {
              const objectValues: any = value;
              objectValues.startDateHrs = objectValues.startDate.substr(11, 2); // Date time not supported by mat date picker
              objectValues.startDateMin = objectValues.startDate.substr(14, 2); // Date time not supported by mat date picker
              objectValues.endDateHrs = objectValues.endDate.substr(11, 2); // Date time not supported by mat date picker
              objectValues.endDateMin = objectValues.endDate.substr(14, 2); // Date time not supported by mat date picker
              this.carParkInfo.push(objectValues); // Add element to costings array
              this.initialCarParkNo += 1; // Will be used to block removing flights from array
              this.addDiv('Car Parking', null);
            });
          }
          if (this.element.attractions.length > 0) {
            this.element.attractions.forEach((value: any) => {
              const objectValues: any = value;
              objectValues.startDateTimeHrs = objectValues.startDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.startDateTimeMin = objectValues.startDateTime.substr(14, 2); // Date time not supported by mat date picker
              objectValues.endDateTimeHrs = objectValues.endDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.endDateTimeMin = objectValues.endDateTime.substr(14, 2); // Date time not supported by mat date picker
              this.attraInfo.push(objectValues); // Add element to costings array
              this.initialAttraNo += 1; // Will be used to block removing flights from array
              this.addDiv('Attraction', null);
            });
          }
          if (this.element.cruises.length > 0) {
            this.element.cruises.forEach((value: any, index: any) => {
              const objectValues: any = value;
              this.cruiseInfo.push(objectValues); // Add element to costings array
              this.initialCruiseNo += 1; // Will be used to block removing flights from array
              this.addDiv('Cruise', null);
              this.initialCabinNo.push(objectValues.addndata.length); // This will block removing existing cabins / rooms
              objectValues.addndata.forEach(() => { this.addAddnDiv(index, 'Cruise'); }); // Add addnDiv to the variable
            });
          }
          if (this.element.miscs.length > 0) {
            this.element.miscs.forEach((value: any) => {
              const objectValues: any = value;
              objectValues.startDateTimeHrs = objectValues.startDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.startDateTimeMin = objectValues.startDateTime.substr(14, 2); // Date time not supported by mat date picker
              objectValues.endDateTimeHrs = objectValues.endDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.endDateTimeMin = objectValues.endDateTime.substr(14, 2); // Date time not supported by mat date picker
              this.miscInfo.push(objectValues); // Add element to costings array
              this.initialMiscNo += 1; // Will be used to block removing flights from array
              this.addDiv('Miscellaneous', null);
            });
          }
          if (this.element.trains.length > 0) {
            this.element.trains.forEach((value: any) => {
              const objectValues: any = value;
              objectValues.departHrs = objectValues.departDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.departMin = objectValues.departDateTime.substr(14, 2); // Date time not supported by mat date picker
              objectValues.arriveHrs = objectValues.arriveDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.arriveMin = objectValues.arriveDateTime.substr(14, 2); // Date time not supported by mat date picker
              this.trainInfo.push(objectValues); // Add element to costings array
              this.initialTrainNo += 1; // Will be used to block removing flights from array
              this.addDiv('Train', null);
            });
          }
          if (this.element.transfers.length > 0) {
            this.element.transfers.forEach((value: any) => {
              const objectValues: any = value;
              objectValues.pickUpHrs = objectValues.pickUpDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.pickUpMin = objectValues.pickUpDateTime.substr(14, 2); // Date time not supported by mat date picker
              objectValues.dropHrs = objectValues.dropOffDateTime.substr(11, 2); // Date time not supported by mat date picker
              objectValues.dropMin = objectValues.dropOffDateTime.substr(14, 2); // Date time not supported by mat date picker
              this.transferInfo.push(objectValues); // Add element to costings array
              this.initialTransferNo += 1; // Will be used to block removing flights from array
              this.addDiv('Transfer', null);
            });
          }

          const supplierPrice = (Number(this.element.packages[0].grossCost) + Number(this.element.packages[0].discount)).toFixed(2);

          this.today = this.element.bookingDate; // Set booking date from the supplier element
          this.costingBreakdown.description = this.element.packages[0].description; // Set package costs (one for all)
          this.costingBreakdown.supplierPrice = supplierPrice; // Set package costs (one for all)
          this.costingBreakdown.realGross = this.element.packages[0].grossCost; // Set package costs (one for all)
          this.costingBreakdown.netPrice = this.element.packages[0].netCost; // Set package costs (one for all)
          this.costingBreakdown.commissionPrice = this.element.packages[0].commission; // Set package costs (one for all)
          this.costingBreakdown.vatPrice = this.element.packages[0].tax; // Set package costs (one for all)
          this.costingBreakdown.discountPrice = this.element.packages[0].discount; // Set package costs (one for all)
          this.costingBreakdown.depositPrice = this.element.packages[0].depositAmount; // Set package costs (one for all)
          this.addUpCostings();
        }
        this.pageLoaded = true;
      });
    }
  }

  createCoreElementData(form: NgForm): void {
    // Validate characters entered in the form and costing breakdown + costing comment..
    const validate1 = this.constants.validateFormCharacters(form);
    const validate2 = this.constants.validateVariableRegex(this.costingBreakdown.description, 'variable');
    const validate3 = this.constants.validateVariableRegex(this.element.comment, 'variable');
    const validate4 = this.constants.validateVariableRegex(this.elementNotes, 'variable');
    const validate5 = this.constants.validateVariableRegex(this.supplierReference, 'variable');
    // Check and pop-up wrong validations (if true)
    if (validate1 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate1, '', ''); throw new Error(('Invalid string value')); }
    else if (validate2 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate2, '', ''); throw new Error(('Invalid string value')); }
    else if (validate3 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate3, '', ''); throw new Error(('Invalid string value')); }
    else if (validate4 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate4, '', ''); throw new Error(('Invalid string value')); }
    else if (validate5 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate5, '', ''); throw new Error(('Invalid string value')); }
    // We want to check whether the supplier reference provided by the user (FOR FRESH ELEMENTS ONLY) is valid
    // That is check if it's provided at all..
    if ((this.operation === 'add' && this.supplierReference.length < 3) || (this.operation === 'edit' && this.element.supplierReference.length < 3)) {
      this.sendMessageToDialog('', 'Supplier Reference must be at least 3 characters long', '', '');
      throw new Error(('Invalid Supplier Reference'));
    }
    // That is check if it doesn't exist already in other elements..
    if ((this.operation === 'add' && this.openedBooking.blockSuppRefs.some((ref: any) => ref === this.supplierReference)) ||
          (this.operation === 'edit' && this.openedBooking.blockSuppRefs.some((ref: any) => ref === this.element.supplierReference))) {
      this.sendMessageToDialog('', 'This supplier reference already exists in this booking', '', '');
      throw new Error(('Invalid Supplier Reference'));
    }
    // That is check if booking date has been applied at all..
    if (this.today === '' || this.today === undefined || this.today === null) {
      this.sendMessageToDialog('', 'Booking Date is in the wrong format', '', '');
      throw new Error(('Invalid Booking Date'));
    }
    // Convert today's date first..
    if (this.today._i !== null) { this.today = this.constants.convertDateMoment(this.today); }
    else { this.today = this.today; }

    // Create coreElementData containing all necessary stuff (tradeCode, operation etc..)
    this.elementData.coreElementData = {};
    this.elementData.token = Session.mySession.get('user').token;
    this.elementData.coreElementData.company = this.openedBooking.company;
    this.elementData.coreElementData.operation = this.openedBooking.operation;
    this.elementData.coreElementData.tradeCode = this.openedBooking.tradeCode;
    this.elementData.coreElementData.bookingReference = this.openedBooking.bookingReference;
    this.elementData.coreElementData.leadName = this.openedBooking.leadName;
    this.elementData.coreElementData.elementType = 'package';

    if (this.operation === 'edit') {
      this.elementData.coreElementData.elementCount = this.element.elementCount; // It's needed so it 'connects' to the main Element
      this.elementData.coreElementData.bookingDate = this.element.bookingDate; // Booking date is static - just get it from element
      this.elementData.coreElementData.supplierID = this.element.supplierID;
      this.elementData.coreElementData.supplierName = this.element.supplierName;
      this.elementData.coreElementData.supplierReference = this.element.supplierReference;
      this.elementData.coreElementData.comment = this.element.comment;
      this.elementData.coreElementData.elementStatus = this.element.elementStatus;
    } else if (this.operation === 'add') {
      this.elementData.coreElementData.supplierID = this.supplier.id;
      this.elementData.coreElementData.supplierName = this.supplier.supplierNameM;
      this.elementData.coreElementData.supplierReference = this.supplierReference;
      this.elementData.coreElementData.bookingDate = this.today;
      this.elementData.coreElementData.comment = this.elementNotes;
      this.elementData.coreElementData.elementStatus = 'enquiry';
    }
  }

  createFlightSubElement(form: NgForm): void {
    if (this.flightDivs.length > 0) {
      this.elementData.coreElementData.deptAir = form.value.departAirCode0; // Save first flight's deptAir code in element table
      this.elementData.coreElementData.destAir = form.value.arriveAirCode0; // Save first flight's destAir code in element table
      this.flightDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'flight'; // Sub element type is flight.. no?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierRef = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].bookingDate = this.today; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          // Flights which were just added will not have flightCount[number] - it will be null
          if (form.value['flightCount' + index as keyof typeof form.value] === null || form.value['flightCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].flightCount = form.value['flightCount' + index as keyof typeof form.value]; // We need to pass in flightCount we want to update
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.flights[index].flightStatus; // Don't change its flight status (done in UI..?)
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierRef = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].bookingDate = this.element.bookingDate; // Not sure if this column is necessary really.. (TBD)
        }

        miniHashName[Object.keys(miniHashName)[0]].departAirCode = form.value['departAirCode' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].destinAirCode = form.value['arriveAirCode' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].departAirName = form.value['departAirName' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].arriveAirName = form.value['arriveAirName' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].carrier = form.value['carrier' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].number = form.value['flightNo' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].flightClass = form.value['flightClass' + index as keyof typeof form.value]; // Usuall stuff..

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].deptDate = this.calculateDeptArrDates('departure', form.value['departDate' + index as keyof typeof form.value]);
        miniHashName[Object.keys(miniHashName)[0]].returnDate = this.calculateDeptArrDates('return', form.value['arriveDate' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['departHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['departMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['arriveHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['arriveMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].departDateTime = miniHashName[Object.keys(miniHashName)[0]].deptDate.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].arriveDateTime = miniHashName[Object.keys(miniHashName)[0]].returnDate.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNoFlight' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfantsFlight' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfantsFlight' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].direction = form.value['direction' + index as keyof typeof form.value]; // Usuall stuff..

        // Check if any of the flight's arrive date is before departure date..
        const arriveDateTime = new Date(miniHashName[Object.keys(miniHashName)[0]].arriveDateTime); // Get the arrival date from miniHashName and add one day
        arriveDateTime.setDate(arriveDateTime.getDate() + 1);  // Adding one day
        const departDateTime = new Date(miniHashName[Object.keys(miniHashName)[0]].departDateTime); // Get the departure date from miniHashName
        // We are allowing one day earlier in arriveDateTime..
        if (arriveDateTime < departDateTime) {
          this.sendMessageToDialog('', 'Flight number ' + (index + 1).toString() + ': the departure date cannot be more than 24 hours after the arrival date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createHotelSubElement(form: NgForm): void {
    if (this.hotelDivs.length > 0) {
      this.elementData.coreElementData.hotelName = form.value.accomName0; // Main element's accommodation will be the first one
      this.elementData.coreElementData.destCountry = form.value.countryHotel0; // Main element's accommodation will be the first one
      this.hotelDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'accom'; // Sub element is accom - hotel!

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['accomCount' + index as keyof typeof form.value] === null || form.value['accomCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].accomCount = form.value['accomCount' + index as keyof typeof form.value]; // We need to tell what accommodation we're editing here
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.accoms[index].accomStatus; // Copy the element's status. We don't want to do it here..?
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        miniHashName[Object.keys(miniHashName)[0]].country = form.value['countryHotel' + index as keyof typeof form.value]; // Usual stuff..
        this.destCountry = form.value['countryHotel' + index as keyof typeof form.value]; // Set global country to update booking with later on..
        miniHashName[Object.keys(miniHashName)[0]].city = form.value['city' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].numNights = form.value['nights' + index as keyof typeof form.value]; // Usual stuff..
        // miniHashName[Object.keys(miniHashName)[0]].numRooms = this.hotelRoomDivs[index].length; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].numRooms = form.value['rooms' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].accomName = form.value['accomName' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].rating = form.value['rating' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].address = form.value['address' + index as keyof typeof form.value]; // Usual stuff..

        // Multiple rooms stuff below (addndata table)
        const addnData: any = []; // Create addnData array object within the mini hash first..
        this.hotelRoomDivs[index].forEach((indexRoom: any) => {
          const addnDataObject: any = {}; // Create addnData object within the mini hash first..
          addnDataObject.company = this.element.company; // Usual stuff..
          addnDataObject.operation = this.element.operation; // Usual stuff..
          addnDataObject.tradeCode = this.element.tradeCode; // Usual stuff..
          addnDataObject.rowType = 'accomRoom'; // Describe addnData object
          addnDataObject.subElementType = 'accom'; // Describe addnData object

          addnDataObject.labelStr1 = 'roomType'; // That's a name of the property below..
          addnDataObject.valueStr1 = form.value['roomType' + index + 'R' + indexRoom as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr2 = 'roomView'; // That's a name of the property below..
          addnDataObject.valueStr2 = form.value['roomView' + index + 'R' + indexRoom as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr3 = 'boardBasis'; // That's a name of the property below..
          addnDataObject.valueStr3 = form.value['boardBasis' + index + 'R' + indexRoom as keyof typeof form.value]; // Get above's property value here..
          addnData.push(addnDataObject); // Push addnData object itno the array at the end
        });
        miniHashName[Object.keys(miniHashName)[0]].addnData = addnData; // Assign addnData variable to miniHash object at the end

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].checkInDate = this.calculateDeptArrDates('departure', form.value['checkinDate' + index as keyof typeof form.value]);

        const checkInDate = new Date(miniHashName[Object.keys(miniHashName)[0]].checkInDate); // Get the date when checks in to hotel
        checkInDate.setDate(checkInDate.getDate() + Number(miniHashName[Object.keys(miniHashName)[0]].numNights)); // Add number of nights to the date
        const lastDate = this.constants.convertDateNotMoment(checkInDate); // Convert the date here
        this.calculateDeptArrDates('return', lastDate); // Insert converted date to the funciton from 27.01.2022

        // Check if any of the car parkings's end date is before start date
        if (Number(miniHashName[Object.keys(miniHashName)[0]].numNights) < 0) {
          this.sendMessageToDialog('', 'Accommodation number ' + (index + 1).toString() + ': the number of nights cannot be negative', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createCarParkingSubElement(form: NgForm): void {
    if (this.carParkDivs.length > 0) {
      this.carParkDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'carpark'; // It is a Car Park, no..?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['carparkCount' + index as keyof typeof form.value] === null || form.value['carparkCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].carparkCount = form.value['carparkCount' + index as keyof typeof form.value]; // Tell Ruby what sub-element to update
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.carparks[index].carparkStatus; // Copy the status - we're not changing it here..
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        // miniHashName[Object.keys(miniHashName)[0]].durationDays = form.value['durationCarPark' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].location = form.value['locationPark' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carParkName = form.value['carParkName' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carParkCode = form.value['carParkCode' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carMake = form.value['carMake' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carModel = form.value['carModel' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carReg = form.value['carReg' + index as keyof typeof form.value]; // Usual stuff..

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const startDateNoTime = this.calculateDeptArrDates('departure', form.value['startDate' + index as keyof typeof form.value]);
        const endDateNoTime = this.calculateDeptArrDates('return', form.value['endDate' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['startDateHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['startDateMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['endDateHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['endDateMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].startDate = startDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].endDate = endDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        // Check if any of the car parkings's end date is before start date
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].endDate) < new Date(miniHashName[Object.keys(miniHashName)[0]].startDate)) {
          this.sendMessageToDialog('', 'Car parking number ' + (index + 1).toString() + ': the start date must be set before the end date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createCarHireSubElement(form: NgForm): void {
    if (this.carHireDivs.length > 0) {
      this.carHireDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'carhire'; // It is a Car Hire.. no?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['carHireCount' + index as keyof typeof form.value] === null || form.value['carHireCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].carHireCount = form.value['carHireCount' + index as keyof typeof form.value]; // Tell Ruby what sub-element to update
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.carhires[index].carHireStatus; // Copy the status - we're not changing it here..
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].pickUpDate = this.calculateDeptArrDates('departure', form.value['pickUpDateCarHire' + index as keyof typeof form.value]);
        miniHashName[Object.keys(miniHashName)[0]].dropOffDate = this.calculateDeptArrDates('return', form.value['dropOffDate' + index as keyof typeof form.value]);

        miniHashName[Object.keys(miniHashName)[0]].pickUpLocation = form.value['pickUpPlace' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].dropOffLocation = form.value['dropOffPlace' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].carType = form.value['carType' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].driver = form.value['driver' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].driverAddn = form.value['driver' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].NumNoInfants = form.value['paxNoCarH' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].numInfants = form.value['paxNoInfantsCarH' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].childseats = form.value['childSeats' + index as keyof typeof form.value]; // Usual stuff..

        // Check if any of the car hires' drop-off date is before pick-up date..
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].dropOffDate) < new Date(miniHashName[Object.keys(miniHashName)[0]].pickUpDate)) {
          this.sendMessageToDialog('', 'Car hire number ' + (index + 1).toString() + ': the pick up date must be set before the drop off date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createAttractionSubElement(form: NgForm): void {
    if (this.attraDivs.length > 0) {
      this.attraDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'attraction'; // It is attraction element.. no?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['attractionCount' + index as keyof typeof form.value] === null || form.value['attractionCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].attractionCount = form.value['attractionCount' + index as keyof typeof form.value]; // Tell Ruby what sub-element to update
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.attractions[index].attractionStatus; // Copy the status - we're not changing it here..
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        miniHashName[Object.keys(miniHashName)[0]].country = form.value['countryAttra' + index as keyof typeof form.value]; // Usual stuff
        this.destCountry = form.value['countryAttra' + index as keyof typeof form.value]; // Set global country to update booking with later on..
        miniHashName[Object.keys(miniHashName)[0]].location = form.value['locationAttra' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].name = form.value['nameAttra' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNoAttra' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfantsAttra' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfantsAttra' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].duration = form.value['durationAttra' + index as keyof typeof form.value]; // Usual stuff

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const startDateNoTime = this.calculateDeptArrDates('departure', form.value['startDateTimeAttra' + index as keyof typeof form.value]);
        const endDateNoTime = this.calculateDeptArrDates('return', form.value['endDateTimeAttra' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['startDateTimeHrsAttra' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['startDateTimeMinAttra' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['endDateTimeHrsAttra' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['endDateTimeMinAttra' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].startDateTime = startDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].endDateTime = endDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        // Check if any of the attraction end date is before start date
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].endDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].startDateTime)) {
          this.sendMessageToDialog('', 'Attraction number ' + (index + 1).toString() + ': the start date must be set before the end date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createCruiseSubElement(form: NgForm): void {
    if (this.cruiseDivs.length > 0) {
      this.cruiseDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'cruise'; // It is a cruise element, no..?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['cruiseCount' + index as keyof typeof form.value] === null || form.value['cruiseCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].cruiseCount = form.value['cruiseCount' + index as keyof typeof form.value]; // Tell Ruby what sub-element to update
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.cruises[index].cruiseStatus; // Copy the status - we're not changing it here
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNoCruise' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfantsCruise' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfantsCruise' + index as keyof typeof form.value]; // Usual stuff..

        // Calculate total numberf of passengers - Done only here I think..
        miniHashName[Object.keys(miniHashName)[0]].passengers = (miniHashName[Object.keys(miniHashName)[0]].adults +
          miniHashName[Object.keys(miniHashName)[0]].children + miniHashName[Object.keys(miniHashName)[0]].infants).toString();

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].deptDate = this.calculateDeptArrDates('departure', form.value['deptDate' + index as keyof typeof form.value]);
        miniHashName[Object.keys(miniHashName)[0]].returnDate = this.calculateDeptArrDates('return', form.value['returnDateCruise' + index as keyof typeof form.value]);

        // Calculate the duration here.. I think this is done only once..
        const differenceDate = new Date(miniHashName[Object.keys(miniHashName)[0]].returnDate).getTime() - new Date(miniHashName[Object.keys(miniHashName)[0]].deptDate).getTime();
        const diffInTime = differenceDate / (1000 * 3600 * 24); miniHashName[Object.keys(miniHashName)[0]].duration = diffInTime.toString();

        // Cruise stuff below..
        miniHashName[Object.keys(miniHashName)[0]].cruiseLine = form.value['cruiseLine' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].cruiseShip = form.value['cruiseShip' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].cruiseName = form.value['cruiseName' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].region = form.value['region' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].departurePort = form.value['departurePort' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].visitingPort = form.value['visitingPort' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].disembarkPort = form.value['disembarkPort' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].voyageCode = form.value['voyageCode' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].onBoardCreditVal = form.value['onBoardCreditVal' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].onBoardCreditCur = form.value['onBoardCreditCur' + index as keyof typeof form.value]; // Usual stuff..

        // Cabin stuff below (addndata table)
        const addnData: any = []; // Create addnData array object within the mini hash first..
        this.cruiseCabinDivs[index].forEach((indexCabin: any) => {
          const addnDataObject: any = {}; // Create addnData object within the mini hash first..
          addnDataObject.company = this.element.company; // Usual stuff..
          addnDataObject.operation = this.element.operation; // Usual stuff..
          addnDataObject.tradeCode = this.element.tradeCode; // Usual stuff..
          addnDataObject.rowType = 'cruiseCabin'; // Describe addnData object
          addnDataObject.subElementType = 'cruise'; // Describe addnData object

          addnDataObject.labelStr1 = 'cabinName'; // That's a name of the property below..
          addnDataObject.valueStr1 = form.value['cabinName' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr2 = 'cabinGrade'; // That's a name of the property below..
          addnDataObject.valueStr2 = form.value['cabinGrade' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr3 = 'cabinNumber'; // That's a name of the property below..
          addnDataObject.valueStr3 = form.value['cabinNumber' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr4 = 'cabinDeck'; // That's a name of the property below..
          addnDataObject.valueStr4 = form.value['cabinDeck' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr5 = 'shipSide'; // That's a name of the property below..
          addnDataObject.valueStr5 = form.value['shipSide' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr6 = 'cabinPosition'; // That's a name of the property below..
          addnDataObject.valueStr6 = form.value['cabinPosition' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr7 = 'bedType'; // That's a name of the property below..
          addnDataObject.valueStr7 = form.value['bedType' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr8 = 'seating'; // That's a name of the property below..
          addnDataObject.valueStr8 = form.value['seating' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr9 = 'tableSize'; // That's a name of the property below..
          addnDataObject.valueStr9 = form.value['tableSize' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnData.push(addnDataObject); // Push addnData object itno the array at the end
        });
        miniHashName[Object.keys(miniHashName)[0]].addnData = addnData; // Assign addnData variable to miniHash object at the end

        // Return date cannot be before dept date in any of the cruise
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].returnDate) <= new Date(miniHashName[Object.keys(miniHashName)[0]].deptDate)) {
          this.sendMessageToDialog('', 'Cruise number ' + (index + 1).toString() + ': the departure date must be set before the return date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createMiscSubElement(form: NgForm): void {
    if (this.miscDivs.length > 0) {
      this.miscDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'misc'; // It is attraction element.. no?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['miscCount' + index as keyof typeof form.value] === null || form.value['miscCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].miscCount = form.value['miscCount' + index as keyof typeof form.value]; // Tell Ruby what sub-element to update
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.miscs[index].miscStatus; // Copy the status - we're not changing it here..
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        miniHashName[Object.keys(miniHashName)[0]].country = form.value['countryMisc' + index as keyof typeof form.value]; // Usual stuff
        this.destCountry = form.value['countryMisc' + index as keyof typeof form.value]; // Set global country to update booking with later on..
        miniHashName[Object.keys(miniHashName)[0]].location = form.value['locationMisc' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].name = form.value['nameMisc' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNoMisc' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfantsMisc' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfantsMisc' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].duration = form.value['durationMisc' + index as keyof typeof form.value]; // Usual stuff

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const startDateNoTime = this.calculateDeptArrDates('departure', form.value['startDateTimeMisc' + index as keyof typeof form.value]);
        const endDateNoTime = this.calculateDeptArrDates('return', form.value['endDateTimeMisc' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['startDateTimeHrsMisc' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['startDateTimeMinMisc' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['endDateTimeHrsMisc' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['endDateTimeMinMisc' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].startDateTime = startDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].endDateTime = endDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        // Check if any of the attraction end date is before start date
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].endDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].startDateTime)) {
          this.sendMessageToDialog('', 'Miscellaneous number ' + (index + 1).toString() + ': the start date must be set before the end date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createTrainSubElement(form: NgForm): void {
    if (this.trainDivs.length > 0) {
      this.trainDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'train'; // It is a train element.. no?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['trainCount' + index as keyof typeof form.value] === null || form.value['trainCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].trainCount = form.value['trainCount' + index as keyof typeof form.value]; // Tell Ruby what sub-element we're editing
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.trains[index].trainStatus; // Copy the status.. we're not changing it here..
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const departDate = this.calculateDeptArrDates('departure', form.value['departDateTime' + index as keyof typeof form.value]);
        const arriveDate = this.calculateDeptArrDates('return', form.value['arriveDateTime' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['deptHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['deptMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['arrHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['arrMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].departDateTime = departDate.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].arriveDateTime = arriveDate.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].departStation = form.value['departStation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].arriveStation = form.value['arriveStation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].trainNameNo = form.value['trainNameNo' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].carriageClass = form.value['carriageClass' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].seat = form.value['seat' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNoTrain' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfantsTrain' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfantsTrain' + index as keyof typeof form.value]; // Usual stuff here

        // Make sure each element's return date is after pick up date here
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].arriveDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].departDateTime)) {
          this.sendMessageToDialog('', 'Train number ' + (index + 1).toString() + ': the departrue date must be set before the arrival date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createTransferSubElement(form: NgForm): void {
    if (this.transferDivs.length > 0) {
      this.transferDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
        this.subElementHashNo += 1; // Add hash number
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'transfer'; // It is a transfer element.. no?

        if (this.operation === 'add') {
          miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Not sure if this column is necessary really.. (TBD)
        } else if (this.operation === 'edit') {
          if (form.value['transferCount' + index as keyof typeof form.value] === null || form.value['transferCount' + index as keyof typeof form.value] === undefined) {
            miniHashName[Object.keys(miniHashName)[0]].action = 'create'; // Tell Ruby to create new element
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
          } else {
            miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
            miniHashName[Object.keys(miniHashName)[0]].transferCount = form.value['transferCount' + index as keyof typeof form.value]; // Tell Ruby what sub-element we're editing
            miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.transfers[index].transferStatus; // Copy the status.. we're not changing it here..
          }

          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
          miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
        }

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const pickUpDate = this.calculateDeptArrDates('departure', form.value['pickUpDateTransfer' + index as keyof typeof form.value]);
        const returnDate = this.calculateDeptArrDates('return', form.value['dropOffDateTransfer' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['pickUpHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['pickUpMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['dropHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['dropMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].pickUpDateTime = pickUpDate.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].dropOffDateTime = returnDate.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].pickUpLocation = form.value['pickUpLocation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].dropOffLocation = form.value['dropOffLocation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].country = form.value['countryTransfer' + index as keyof typeof form.value]; // Usual stuff here
        this.destCountry = form.value['countryTransfer' + index as keyof typeof form.value]; // Set global country to update booking with later on..
        miniHashName[Object.keys(miniHashName)[0]].pickUpInstructions = form.value['pickUpInstructions' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].dropOffInstructions = form.value['dropOffInstructions' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].VehicleType = form.value['VehicleType' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].emergencyResortContact = form.value['emergencyResortContact' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].emergencyPhoneNo = form.value['emergencyPhoneNo' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNoTransfer' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfantsTransfer' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfantsTransfer' + index as keyof typeof form.value]; // Usual stuff here

        // Make sure each element's return date is after pick up date here
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].dropOffDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].pickUpDateTime)) {
          this.sendMessageToDialog('', 'Transfer number ' + (index + 1).toString() + ': the pick up date must be set before the drop off date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });
    }
  }

  createPackageSubElement(form: NgForm): void {
    const miniHashName = JSON.parse('{"elmentDataMiniHash' + this.subElementHashNo + '" : {}}'); // Very clever way of adding a suffix to property name
    this.subElementHashNo += 1; // Add hash number
    miniHashName[Object.keys(miniHashName)[0]].subElementType = 'package'; // It is attraction element.. no?

    if (this.operation === 'add') {
      miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
      miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference; // Supplier reference will always be equal to first element's suppRef
      miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
    } else if (this.operation === 'edit') {
      miniHashName[Object.keys(miniHashName)[0]].action = 'update'; // Tel Ruby to update this element
      miniHashName[Object.keys(miniHashName)[0]].packageCount = this.element.packages[0].packageCount; // Tell Ruby what sub-element to update
      miniHashName[Object.keys(miniHashName)[0]].subElementStatus = this.element.packages[0].packageStatus; // Copy the status - we're not changing it here..
      miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.element.supplierReference; // Supplier reference will always be equal to first element's suppRef
      miniHashName[Object.keys(miniHashName)[0]].supplier = this.element.supplierName; // We're not setting supplier anywhere. It's been set already so copy it from main supplier element
    }

    miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown.description; // Usuall stuff..
    miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown.realGross; // Usuall stuff..
    miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown.netPrice; // Usuall stuff..
    miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown.vatPrice; // Usuall stuff..
    miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown.discountPrice; // Usuall stuff..
    miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown.commissionPrice; // Usuall stuff..
    miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown.depositPrice; // Usuall stuff..

    Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
  }

  createPackage(form: NgForm): void {
    if (this.flightDivs.length === 0 && this.hotelDivs.length === 0 && this.carParkDivs.length === 0 &&
      this.carHireDivs.length === 0 && this.attraDivs.length === 0 && this.cruiseDivs.length === 0 &&
      this.miscDivs.length === 0 && this.transferDivs.length === 0 && this.trainDivs.length === 0 &&
      this.userType != 'sinGSAdmin') {
      this.sendMessageToDialog('', 'You need to add at least one element in the package', '', '');
    } else if (form.valid || (this.userType == 'sinGSAdmin' && this.element.externalElement)) {
      this.subElementHashNo = 0; // Reset hash number..
      this.createCoreElementData(form); // Create core which contains most basic element info
      this.createFlightSubElement(form); // Create flight sub elements (if exist)
      this.createHotelSubElement(form); // Create hotel sub elements (if exist)
      this.createCarParkingSubElement(form); // Create car parking sub elements (if exist)
      this.createCarHireSubElement(form); // Create car hire sub elements (if exist)
      this.createAttractionSubElement(form); // Create attraction sub elements (if exist)
      this.createCruiseSubElement(form); // Create cruise sub elements (if exist)
      this.createMiscSubElement(form); // Create misc sub elements (if exist)
      this.createTrainSubElement(form); // Create train sub elements (if exist)
      this.createTransferSubElement(form); // Create transfer sub elements (if exist)

      this.createPackageSubElement(form); // Create package sub element (must exist)
      this.pageLoaded = false;
      if (this.operation === 'add') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'SinGS failed to process Package element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + Session.mySession.getOpenedBooking().bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1701S)', error, this.elementData);
        });
      } else if (this.operation === 'edit') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'SinGS failed to process Package element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + Session.mySession.getOpenedBooking().bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1702S)', error, this.elementData);
        });
      }

    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  updateBookingData(): Promise<any>  {
    return new Promise((resolve, reject) => {
      const bookingData: any = this.openedBooking; // Get the booking data from session
      const request = {
        company: bookingData.company, operation: bookingData.operation, tradeCode: bookingData.tradeCode,
        bookingReference: bookingData.bookingReference, token: Session.mySession.get('user').token
      };

      this.bookingService.autoCalcBookingDates(request).then((output: any) => {
        if (output.status !== 'OK') { this.sendMessageToDialog('', 'Booking dates have not been updated properly (' + output.status + ')', '', ''); }
        this.unblockNewBooking(); resolve(null);
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1703S)', error, request);
        this.unblockNewBooking(); resolve(null);
      });
    });
  }

  calculateDeptArrDates(dateType: any, dateIn: any): any {
    if (dateIn._i === undefined) { } else { dateIn = this.constants.convertDateMoment(dateIn); } // Convert if selected from mat-datepicker
    // Validate the date format first here (no more 01/01/22) - raised on 28/02/2022
    if (moment(dateIn, 'YYYY-MM-DD', true).isValid() === false && moment(dateIn, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid() === false) {
      this.sendMessageToDialog('', 'One of your dates is in the wrong format', '', '');
      this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
      this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
      throw new Error('Bad dates!');
    }
    // Calculate departure date below
    if (dateType === 'departure') {
      // Work out ELEMENT's departure date below - change ONLY if updated element's dept date is earlier than current one
      if (new Date(dateIn) <= new Date(this.element.deptDate) || this.element.deptDate === undefined) {
        this.elementData.coreElementData.deptDate = dateIn.substring(0, 10); // Core element deptDate pushed back
        this.element.deptDate = dateIn; // Update global element's deptDate for next element's calculations
        // Work out supplier due date of the main element below
        if (this.element.bookingDate !== undefined) {
          if (new Date(this.element.bookingDate) < new Date(this.calculateSuppDueDate(dateIn))) {
            this.elementData.coreElementData.suppDueDate = this.calculateSuppDueDate(dateIn);
          } else {
            this.elementData.coreElementData.suppDueDate = this.element.bookingDate;
          }
        } else {
          if (new Date(this.elementData.coreElementData.bookingDate) < new Date(this.calculateSuppDueDate(dateIn))) {
            this.elementData.coreElementData.suppDueDate = this.calculateSuppDueDate(dateIn);
          } else {
            this.elementData.coreElementData.suppDueDate = this.elementData.coreElementData.bookingDate;
          }
        }
      }
      // Calculate return date below
    } else if (dateType === 'return') {
      // Work out ELEMENT's return date below - change ONLY if updated element's return date is after the current one
      if (new Date(dateIn) >= new Date(this.element.returnDate) || this.element.returnDate === undefined) {
        this.elementData.coreElementData.returnDate = dateIn.substring(0, 10); // Core element returnDate moved forward
        this.element.returnDate = dateIn; // Update global element's reutrnDate for next element's calculations
      }
    }
    return dateIn.substring(0, 10); // Return back to create controllers..
  }

  calculateSuppDueDate(dateIn: any): any {
    // Calculate supplier due date (deptDate - paymentDueDays[from supplier])
    const suppDueDate: any = new Date(dateIn);
    if (Session.mySession.getElementSupplier() === undefined) {
      suppDueDate.setDate(suppDueDate.getDate() - Number(84)); // If the supplier doesn't exist anymore or its hidden - set it to 84 as a default
    } else {
      suppDueDate.setDate(suppDueDate.getDate() - Number(Session.mySession.getElementSupplier().paymentDueDays)); // Supplier still visible - use it
    }
    return this.constants.convertDateNotMoment(suppDueDate).substring(0, 10); // Convert the date here
  }

  changeSuppPrice(event: any): void {
    this.costingBreakdown.supplierPrice = event; // Assign the input value to elements supplier price

    // Calculate the real gross below
    this.costingBreakdown.realGross = (this.costingBreakdown.supplierPrice - this.costingBreakdown.discountPrice).toFixed(2);
    // Calculate objects commission without the VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross -
      this.costingBreakdown.netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (this.openedBooking.vatReg === 'yes') { this.costingBreakdown.vatPrice = (this.costingBreakdown.commissionPrice * 0.16666667).toFixed(2); }
    else { this.costingBreakdown.vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross - this.costingBreakdown.netPrice -
      this.costingBreakdown.vatPrice).toFixed(2);

    // Work out the deposit from supplier.depositRate (if exists and non-£0)
    if (Session.mySession.getElementSupplier() !== undefined && Session.mySession.getElementSupplier().depositRate !== '0.0') {
      this.costingBreakdown.depositPrice = (this.costingBreakdown.supplierPrice * (Number(Session.mySession.getElementSupplier().depositRate) / 100)).toFixed(2);
    }

    this.addUpCostings(); // Add up total costings
  }

  changeNet(event: any): void {
    this.costingBreakdown.netPrice = event; // Assign input to netPrice

    // Calculate objects commission without the VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross -
      this.costingBreakdown.netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (this.openedBooking.vatReg === 'yes') { this.costingBreakdown.vatPrice = (this.costingBreakdown.commissionPrice * 0.16666667).toFixed(2); }
    else { this.costingBreakdown.vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross - this.costingBreakdown.netPrice -
      this.costingBreakdown.vatPrice).toFixed(2);

    this.addUpCostings(); // Add up total costings
  }

  changeVAT(event: any): void {
    this.costingBreakdown.vatPrice = event; // Assign input to vatPrice

    // Calculate objects commission without the VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross -
      this.costingBreakdown.netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    // if (this.openedBooking.vatReg === 'yes') { this.costingBreakdown.vatPrice = (this.costingBreakdown.commissionPrice * 0.16666667).toFixed(2); }
    // else { this.costingBreakdown.vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross - this.costingBreakdown.netPrice -
      this.costingBreakdown.vatPrice).toFixed(2);

    this.addUpCostings(); // Add up total costings
  }

  changeDiscount(event: any): void {
    this.costingBreakdown.discountPrice = event; // Assign input to discountPrice

    // Calculate the real gross below
    this.costingBreakdown.realGross = (this.costingBreakdown.supplierPrice - this.costingBreakdown.discountPrice).toFixed(2);
    // Calculate objects commission without the VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross -
      this.costingBreakdown.netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (this.openedBooking.vatReg === 'yes') { this.costingBreakdown.vatPrice = (this.costingBreakdown.commissionPrice * 0.16666667).toFixed(2); }
    else { this.costingBreakdown.vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown.commissionPrice = (this.costingBreakdown.realGross - this.costingBreakdown.netPrice -
      this.costingBreakdown.vatPrice).toFixed(2);

    this.addUpCostings(); // Add up total costings
  }

  addUpCostings(): void {
    this.totalCosts.supplierPrice = this.costingBreakdown.supplierPrice; // Assign the value
    this.totalCosts.realGross = this.costingBreakdown.realGross; // Assign the value
    this.totalCosts.commissionPrice = this.costingBreakdown.commissionPrice; // Assign the value
    this.totalCosts.vatPrice = this.costingBreakdown.vatPrice; // Assign the value
    this.totalCosts.discountPrice = this.costingBreakdown.discountPrice; // Assign the value
    this.totalCosts.netPrice = this.costingBreakdown.netPrice; // Assign the value
    this.totalCosts.depositPrice = this.costingBreakdown.depositPrice; // Assign the value
  }

  addDiv(type: any, form: any): void {
    if (form == null || form.valid) {
      if (type === 'Flight') { this.addDivLogic(this.flightDivs, this.flightNoView); }
      else if (type === 'Accommodation') {
        this.addDivLogic(this.hotelDivs, this.hotelNoView);
        if (form === null) { this.hotelRoomDivs.push([]); } else { this.hotelRoomDivs.push([0]); this.initialRoomNo[this.hotelDivs.length - 1] = 1; }
      }
      else if (type === 'Car Parking') { this.addDivLogic(this.carParkDivs, this.carParksNoView); }
      else if (type === 'Car Hire') { this.addDivLogic(this.carHireDivs, this.carHireNoView); }
      else if (type === 'Attraction') { this.addDivLogic(this.attraDivs, this.attraNoView); }
      else if (type === 'Cruise') {
        this.addDivLogic(this.cruiseDivs, this.cruiseNoView);
        if (form === null) { this.cruiseCabinDivs.push([]); } else { this.cruiseCabinDivs.push([0]); this.initialCabinNo[this.cruiseDivs.length - 1] = 1; }
      }
      else if (type === 'Miscellaneous') { this.addDivLogic(this.miscDivs, this.miscNoView); }
      else if (type === 'Train') { this.addDivLogic(this.trainDivs, this.trainNoView); }
      else if (type === 'Transfer') { this.addDivLogic(this.transferDivs, this.transferNoView); }
    } else {
      form.ngSubmit.emit(form.value); // Prompt a message and highlight invalid fields
      const invalidControl = this.el.nativeElement.querySelectorAll('.ng-invalid');
      if (invalidControl) {
        invalidControl.forEach((control: any) => {
          control.focus();
        });
      }
    }
  }

  removeDiv(type: any): void {
    if (type === 'Flight' && this.flightDivs.length > 0) { this.removeDivLogic(this.flightDivs, this.flightNoView, this.initialFlightNo, type); }
    else if (type === 'Accommodation' && this.hotelDivs.length > 0) { this.removeDivLogic(this.hotelDivs, this.hotelNoView, this.initialHotelNo, type); }
    else if (type === 'Car Parking' && this.carParkDivs.length > 0) { this.removeDivLogic(this.carParkDivs, this.carParksNoView, this.initialCarParkNo, type); }
    else if (type === 'Car Hire' && this.carHireDivs.length > 0) { this.removeDivLogic(this.carHireDivs, this.carHireNoView, this.initialCarHireNo, type); }
    else if (type === 'Attraction' && this.attraDivs.length > 0) { this.removeDivLogic(this.attraDivs, this.attraNoView, this.initialAttraNo, type); }
    else if (type === 'Cruise' && this.cruiseDivs.length > 0) { this.removeDivLogic(this.cruiseDivs, this.cruiseNoView, this.initialCruiseNo, type); }
    else if (type === 'Miscellaneous' && this.miscDivs.length > 0) { this.removeDivLogic(this.miscDivs, this.miscNoView, this.initialMiscNo, type); }
    else if (type === 'Train' && this.trainDivs.length > 0) { this.removeDivLogic(this.trainDivs, this.trainNoView, this.initialTrainNo, type); }
    else if (type === 'Transfer' && this.transferDivs.length > 0) { this.removeDivLogic(this.transferDivs, this.transferNoView, this.initialTransferNo, type); }
  }

  addDivLogic(elementDiv: any, elementNoView: any): void {
    elementDiv.push(elementDiv.length);
    elementNoView.fill(false);
    elementNoView.push(true);
  }

  removeDivLogic(elementDiv: any, elementNoView: any, initialElementNo: any, type: any): void {
    if (elementDiv.length > initialElementNo) {
      elementDiv.pop(); // Remove the last element from array (removes last element in UI)
      // Code below ensures that removing latest element won't affect users current view
      // UNLESS UI was focues on to-be-deleted element. If so, switch to previous one
      if (elementNoView[elementNoView.length - 1] === true) {
        elementNoView[elementNoView.length - 2] = true;
      }
      elementNoView.pop(); // Remove last element from view array
      // Remove addnData elements below
      if (type === 'Accommodation') { this.hotelRoomDivs.pop(); }
      else if (type === 'Cruise') { this.cruiseCabinDivs.pop(); }
    } else {
      this.sendMessageToDialog('', 'You cannot remove more elements', '', '');
    }
  }

  addAddnDiv(divNo: any, type: any): void {
    // Add another addnData to the element of type type
    if (type === 'Accommodation') { this.hotelRoomDivs[divNo].push(this.hotelRoomDivs[divNo].length); }
    else if (type === 'Cruise') { this.cruiseCabinDivs[divNo].push(this.cruiseCabinDivs[divNo].length); }
  }

  removeAddnDiv(divNo: any, type: any): void {
    // Remove the last element from array (removes last element in UI)
    if (type === 'Accommodation' && this.hotelRoomDivs[divNo].length > 1) { this.hotelRoomDivs[divNo].pop(); }
    else if (type === 'Cruise' && this.cruiseCabinDivs[divNo].length > 1) { this.cruiseCabinDivs[divNo].pop(); }
    else { this.sendMessageToDialog('', 'You cannot remove more elements', '', ''); }
  }

  leadingZeroCheck(divId: any): void {
    const divValue = (document.getElementById(divId) as HTMLInputElement).value;
    // Get the value from HTML by the div ID and make sure there is a leading 0
    // When single value is entered AND check if Hrs and Min are in the correct range
    if (divValue.includes('.') || divValue === 'e' || divValue.length == 0) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    } else if (divValue.length === 1) {
      (document.getElementById(divId) as HTMLInputElement).value = '0' + divValue;
    } else if (divId.includes('Hrs') && (Number(divValue) < 0 || Number(divValue) > 23)) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    } else if (divId.includes('Min') && (Number(divValue) < 0 || Number(divValue) > 59)) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    }
  }

  unblockNewBooking(): void {
    // This is getting called any time a create API is done. Unlocks creating new bookings..
    if (Session.mySession.get('blockNewBooking') != null &&
      Session.mySession.get('blockNewBooking') !== false) {
      Session.mySession.set('blockNewBooking', false);
    }
  }

  changeElementType(type: any): void {
    if (this.createForm.valid) {
      this.selectedEleType = type; // Allow to change visible element type if form is valid
    } else {
      this.createForm.ngSubmit.emit(); // Otherwise prompt a message and highlight invalid fields
      const invalidControl = this.el.nativeElement.querySelectorAll('.ng-invalid');
      if (invalidControl) {
        invalidControl.forEach((control: any) => {
          control.focus();
        });
      }
    }
  }

  switchView(divNo: any, type: any): void {
    if (type === 'Flight') {
      this.flightNoView = Array(this.flightDivs.length).fill(false);
      this.flightNoView[divNo] = true;
    } else if (type === 'Accommodation') {
      this.hotelNoView = Array(this.hotelDivs.length).fill(false);
      this.hotelNoView[divNo] = true;
    } else if (type === 'Car Parking') {
      this.carParksNoView = Array(this.carParkDivs.length).fill(false);
      this.carParksNoView[divNo] = true;
    } else if (type === 'Car Hire') {
      this.carHireNoView = Array(this.carHireDivs.length).fill(false);
      this.carHireNoView[divNo] = true;
    } else if (type === 'Attraction') {
      this.attraNoView = Array(this.attraDivs.length).fill(false);
      this.attraNoView[divNo] = true;
    } else if (type === 'Cruise') {
      this.cruiseNoView = Array(this.cruiseDivs.length).fill(false);
      this.cruiseNoView[divNo] = true;
    } else if (type === 'Miscellaneous') {
      this.miscNoView = Array(this.miscDivs.length).fill(false);
      this.miscNoView[divNo] = true;
    } else if (type === 'Train') {
      this.trainNoView = Array(this.trainDivs.length).fill(false);
      this.trainNoView[divNo] = true;
    } else if (type === 'Transfer') {
      this.transferNoView = Array(this.transferDivs.length).fill(false);
      this.transferNoView[divNo] = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  setEndDate(fromId: any, toId: any): void {
    const divValue = (document.getElementById(fromId) as HTMLInputElement).value;
    (document.getElementById(toId) as HTMLInputElement).value = divValue;

    // Trigger Angular's change detection to update the UI
    this.ngZone.run(() => {
      (document.getElementById(toId) as HTMLInputElement).dispatchEvent(new Event('input'));
    });
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  filterSelect(): void {
    this.countriesFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.countries.length; i++) {
      const option = this.countries[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.countriesFiltered.push(option);
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}
