<div class="mainDiv">
    <select ngModel [(ngModel)]="branchDetails" name="selectedBranch" #selectedBranch="ngModel" placeholder="Selected Branch" class="customInputSelect branchSelect"
            *ngIf="(userTypeOut === 'wcManager' || userTypeOut === 'wcMember') && branchList.length > 1" [disabled]="bookingOperation == 'View'" (ngModelChange)="onBranchChange()">
        <option *ngFor="let branch of branchList" [ngValue]="branch">{{branch.branchName}}</option>
    </select>

    <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
        <div *ngIf="innerWidth > 1024" style="width: 100%;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button class="navButtons" (click)="switchView('Add')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'Add'" *ngIf="['wcManager', 'wcMember'].includes(userTypeOut) && memberLive">
                    <mat-icon>note_add</mat-icon> Add Booking
                </button>
                <button class="navButtons" (click)="switchView('View')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'View'">
                    <mat-icon>sell</mat-icon> Booking Details
                </button>
                <button class="navButtons" (click)="switchView('List')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'List'" *ngIf="['wcManager', 'wcMember'].includes(userTypeOut)">
                    <mat-icon>library_books</mat-icon> Booking List
                </button>
                <button class="navButtons" (click)="switchView('Returns')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'Returns'" *ngIf="userTypeOut === 'wcManager'">
                    <mat-icon *ngIf="monthlyReturnSummary?.status == 'New'" matBadge="!" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">event_repeat</mat-icon>
                    <mat-icon *ngIf="monthlyReturnSummary?.status != 'New'">event_repeat</mat-icon> Monthly Returns
                </button>
                <button class="navButtons" (click)="switchView('Upload')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'Upload'" *ngIf="['wcManager', 'wcMember'].includes(userTypeOut) && borderauxCodes.includes(currentRequest.tradeCode) && memberLive">
                    <mat-icon>upload_file</mat-icon> Upload Data
                </button>
            </div>
        </div>

        <div *ngIf="innerWidth < 1025" style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button class="navButtons" (click)="switchView('Add')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'Add'" *ngIf="['wcManager', 'wcMember'].includes(userTypeOut) && memberLive">
                    <mat-icon>note_add</mat-icon>
                </button>
                <button class="navButtons" (click)="switchView('View')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'View'">
                    <mat-icon>sell</mat-icon>
                </button>
                <button class="navButtons" (click)="switchView('List')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'List'" *ngIf="['wcManager', 'wcMember'].includes(userTypeOut)">
                    <mat-icon>library_books</mat-icon>
                </button>
                <button class="navButtons" (click)="switchView('Returns')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'Returns'" *ngIf="userTypeOut === 'wcManager'">
                    <mat-icon *ngIf="monthlyReturnSummary?.status == 'New'" matBadge="!" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">event_repeat</mat-icon>
                    <mat-icon *ngIf="monthlyReturnSummary?.status != 'New'">event_repeat</mat-icon>
                </button>
                <button class="navButtons" (click)="switchView('Upload')" mat-button [disableRipple]="true"
                    [disabled]="bookingOperation == 'Upload'" *ngIf="['wcManager', 'wcMember'].includes(userTypeOut) && borderauxCodes.includes(currentRequest.tradeCode) && memberLive">
                    <mat-icon>upload_file</mat-icon>
                </button>
            </div>
        </div>
    </mat-toolbar>

    <div *ngIf="bookingOperation == 'Add'" class="chosenDiv" [ngStyle]="{'height': branchList.length > 1 ? 'calc(100% - 93px)' : 'calc(100% - 60px)'}">
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
            <div fxFlex="10">
                <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button [disableRipple]="true"
                    [disabled]="!previewOn || bookingViewNo == 0" (click)="changePageNumber('previous')"
                    matTooltip="Previous booking">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div fxFlex="80"
                style="display: flex; flex-direction: row; align-items: center; justify-content: space-around;">
                <div>
                    <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button [disableRipple]="true"
                        (click)="changeAddMode('blank')" [disabled]="!previewOn" matTooltip="Close preview">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div>
                    <h1>Add Booking</h1>
                </div>
                <div style="text-align: right;">
                    <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button [disableRipple]="true"
                        (click)="changeAddMode('previewUI')" [disabled]="previewOn || uploadedBookingsData.length == 0"
                        matTooltip="Open preview">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </div>
            </div>
            <div fxFlex="10" style="text-align: right;">
                <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button [disableRipple]="true"
                    [disabled]="!previewOn || uploadedBookingsData.length == (bookingViewNo + 1)" (click)="changePageNumber('next')"
                    matTooltip="Next booking">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </div>
        </div>

        <div class="divider">
            <mat-divider></mat-divider>
        </div>

        <div *ngIf="pageLoaded" style="display: flex; flex-direction: column; justify-content: space-between; height: calc(100% - 55px);">
            <div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="50" style="margin: auto;"><b>Your Reference</b></div>
                        <div fxFlex="50" id="BookingRefSource" style="margin: auto;">
                            <input matInput ngModel name="BookingRefSource"
                            [(ngModel)]="bookingHashData[0].BookingRefSource" maxlength="25" class="customInput">
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Group Name</b></div>
                        <div fxFlex="62.5" id="bookingGroup">
                            <input matInput ngModel name="bookingGroup" [(ngModel)]="bookingHashData[0].bookingGroup" maxlength="100" class="customInput">
                        </div>
                    </div>
                    <!--<div fxFlex="50" style="height: 40px;" *ngIf="previewOn">
                        <div fxFlex="100" style="margin: auto; color: #4D5FD1; text-align: center;">
                            <b>CSV Preview</b>
                        </div>
                    </div>
                    -->
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="50" style="margin: auto;"><b>Booking Date</b></div>
                        <div fxFlex="50" id="bookingDate" class="dateDivs">
                            <div><input [matDatepicker]="bookingDate" class="customInput" matInput
                                [(ngModel)]="bookingHashData[0].bookingDate" (dateChange)="editDate($event, 'Booking Date', bookingHashData[0])"/></div>
                            <div><mat-datepicker-toggle matSuffix [for]="bookingDate" [disableRipple]="true"></mat-datepicker-toggle>
                            <mat-datepicker #bookingDate [startAt]="bookingHashData[0].bookingDate"></mat-datepicker></div>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Passengers</b></div>
                        <div fxFlex="62.5" id="bookingStatus" style="margin: auto;">{{ passengers[bookingViewNo].data.length }}</div>
                    </div>
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="50" style="margin: auto;"><b>Departure Date</b></div>
                        <div fxFlex="50" id="deptDate" class="dateDivs">
                            <div><input [matDatepicker]="deptDate" class="customInput" matInput [(ngModel)]="bookingHashData[0].deptDate" id="bkDeptDate"
                                (dateChange)="editDate($event, 'Departure Date', bookingHashData[0]); setEndDate('bkDeptDate', 'bkRtnDate')"/></div>
                            <div><mat-datepicker-toggle matSuffix [for]="deptDate" [disableRipple]="true"></mat-datepicker-toggle>
                            <mat-datepicker #deptDate [startAt]="bookingHashData[0].deptDate"></mat-datepicker></div>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;">
                            <b>Deposit Paid</b>
                        </div>
                        <div fxFlex="62.5" style="margin: auto;">
                            <input type="checkbox" [(ngModel)]="bookingHashData[0].depositPaid" class="customCheckbox" style="transform: scale(1.5)">
                        </div>
                    </div>
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="50" style="margin: auto;"><b>Return Date</b></div>
                        <div fxFlex="50" id="returnDate" class="dateDivs">
                            <div><input [matDatepicker]="returnDate" class="customInput" matInput [(ngModel)]="bookingHashData[0].returnDate" id="bkRtnDate"
                                (dateChange)="editDate($event, 'Return Date', bookingHashData[0])"/></div>
                            <div><mat-datepicker-toggle matSuffix [for]="returnDate" [disableRipple]="true"></mat-datepicker-toggle>
                            <mat-datepicker #returnDate [startAt]="bookingHashData[0].returnDate"></mat-datepicker></div>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Balance Due Date</b></div>
                        <div fxFlex="62.5" id="balanceDueDate" class="dateDivs">
                            <div><input [matDatepicker]="balanceDueDate" class="customInput" matInput [(ngModel)]="bookingHashData[0].balanceDueDate" id="balanceDueDate"
                                (dateChange)="editDate($event, 'Balance Due Date', bookingHashData[0]);"/></div>
                            <div><mat-datepicker-toggle matSuffix [for]="balanceDueDate" [disableRipple]="true"></mat-datepicker-toggle>
                            <mat-datepicker #balanceDueDate [startAt]="bookingHashData[0].balanceDueDate"></mat-datepicker></div>
                        </div>
                    </div>
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="50" style="margin: auto;"><b>Destination</b></div>
                        <div fxFlex="50" id="destCountry">
                            <mat-select ngModel required name="country" #country="ngModel" class="customInput customInputSelect customInputSelectShort" [(ngModel)]="bookingHashData[0].destCountry">
                                <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                                  <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterStrCountry" (keyup)="filterCountries()">
                                </mat-form-field>
                                <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterStrCountry = ''; filterCountries();">
                                  {{country}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Mark Up/Down</b></div>
                        <div fxFlex="62.5" id="markUpDown">
                            <input matInput ngModel name="markUpDown" type="number" class="customInput" [(ngModel)]="bookingHashData[0].markUpDown">
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 10px;">
                <div class="divider">
                    <mat-divider></mat-divider>
                </div>

                <div>
                <div style="display: flex; align-items: center; justify-content: center;">
                    <h1 class="h1headers">Supplier Details</h1>
                </div>

                <div class="divider">
                    <mat-divider></mat-divider>
                </div>

                <mat-table #tableNew class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="newElementData">
                    <ng-container matColumnDef="supplierName">
                        <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                        <mat-cell *matCellDef="let costing">
                            <div class="costing-supplier-remove">
                                <div style="width: 40px; margin-right: 5px;">
                                    <button mat-icon-button [disableRipple]="true" color="warn"
                                            (click)="removeMainElement(newElementData.data.indexOf(costing))">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div style="width: 100%;">
                                    <span *ngIf="!costing.suppNotFound">
                                        <span *ngIf="costing?.isUnderSFC || costing?.isUnderSafi" class="underSFC"
                                        [matTooltip]="costing?.isUnderSFC && costing?.isUnderSafi ? 'Under SFC and SAFI' : (costing?.isUnderSFC ? 'Under SFC' : 'Under SAFI')">
                                            {{ costing.supplierName }}
                                        </span>
                                        <span *ngIf="!costing?.isUnderSFC && !costing?.isUnderSafi">
                                            {{ costing.supplierName }}
                                        </span>
                                    </span>
                                    <span *ngIf="costing.suppNotFound">
                                        <a style="color: #FA0E62;" (click)="getPseudoMasterName(costing)">
                                            <span *ngIf="costing?.isUnderSFC || costing?.isUnderSafi" class="underSFC"
                                            [matTooltip]="costing?.isUnderSFC && costing?.isUnderSafi ? 'Under SFC and SAFI' : (costing?.isUnderSFC ? 'Under SFC' : 'Under SAFI')">
                                                {{ costing.supplierName }}
                                            </span>
                                            <span *ngIf="!costing?.isUnderSFC && !costing?.isUnderSafi">
                                                {{ costing.supplierName }}
                                            </span>
                                            <mat-icon style="zoom: 0.8; margin-left: 5px; margin-top: -4px;">priority_high</mat-icon>
                                        </a>
                                    </span>

                                    <mat-icon color="warn" *ngIf="costing.supplierName == 'SAFI' && flightsOnly.length == 0" style="margin-left: 5px;"
                                    matTooltip="There are no 'Flight Only' elements with a SAFI supplier in the booking">priority_high</mat-icon>

                                    <mat-icon color="warn" *ngIf="costing.supplierName == 'SFC' && !isUnderSFC()"
                                    matTooltip="There are no suppliers covered under SFC in the booking" style="margin-left: 5px;">priority_high</mat-icon>
                                </div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button type="button" mat-button class="addElementButton" [disableRipple]="true"
                                (click)="openSupplDialog('view', null); changeSuppType('no')" style="font-size: 11px;">
                                Add Supplier
                            </button>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="ref">
                        <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            <b>{{ costing.supplierReference }}</b>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button type="button" mat-button class="addElementButton" [disableRipple]="true"
                                (click)="openSupplDialog('view', null); changeSuppType('yes')" style="font-size: 11px;">
                                Add Supplement
                            </button>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="gross">
                        <mat-header-cell *matHeaderCellDef> Gross </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.gross | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="net">
                        <mat-header-cell *matHeaderCellDef> Net </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.net | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="commission">
                        <mat-header-cell *matHeaderCellDef> Commission </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.commission | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="vat">
                        <mat-header-cell *matHeaderCellDef> VAT </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.tax | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                        <mat-header-cell *matHeaderCellDef> Discount </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.discount | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="deposit">
                        <mat-header-cell *matHeaderCellDef> Deposit </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.depositAmount | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="issueDate">
                        <mat-header-cell *matHeaderCellDef> Issue Date </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.createTS | date }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <!--<ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let costing"></mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>-->

                    <ng-container matColumnDef="expandedDetail">
                        <mat-cell class="expandedDetail" *matCellDef="let costing" [attr.colspan]="7">
                            <div class="costings-breakdown-main">
                                <div class="costings-breakdown-row-header">
                                    <div fxFlex="11.11"></div>
                                    <div fxFlex="11.11" matTooltip="Gross + Discount">Supplier Gross*</div>
                                    <div fxFlex="11.11">Gross</div>
                                    <div fxFlex="11.11">Net</div>
                                    <div fxFlex="11.11">Commission</div>
                                    <div fxFlex="11.11">VAT</div>
                                    <div fxFlex="11.11">Discount</div>
                                    <div fxFlex="11.11">Deposit</div>
                                    <div fxFlex="11.11"></div>
                                </div>

                                <div *ngIf="costing.attractions.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let attraction of costing.attractions; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="attraction.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.attractions)"
                                                    color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">attractions</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ attraction.grossCost*1 + attraction.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="attraction.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="attraction.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="attraction.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="attraction.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="attraction.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="attraction.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="attraction.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.carhires.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let carHire of costing.carhires; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="carHire.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.carhires)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">drive_eta</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ carHire.grossCost*1 + carHire.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="carHire.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="carHire.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="carHire.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="carHire.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="carHire.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="carHire.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="carHire.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.carparks.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let carParking of costing.carparks; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="carParking.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.carparks)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">local_parking</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ carParking.grossCost*1 + carParking.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="carParking.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="carParking.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="carParking.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="carParking.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="carParking.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="carParking.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="carParking.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.cruises.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let cruise of costing.cruises; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="cruise.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.cruises)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon
                                                    style="width:100%; margin-top: -5px;">directions_boat</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ cruise.grossCost*1 + cruise.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="cruise.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="cruise.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="cruise.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="cruise.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="cruise.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="cruise.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="cruise.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.flights.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let flight of costing.flights; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="flight.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.flights)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">flight</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ flight.grossCost*1 + flight.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="flight.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="flight.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="flight.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="flight.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="flight.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="flight.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="flight.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.accoms.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let hotel of costing.accoms; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="hotel.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.accoms)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">hotel</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ hotel.grossCost*1 + hotel.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="hotel.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="hotel.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="hotel.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="hotel.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="hotel.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="hotel.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="hotel.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.miscs.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let misc of costing.miscs; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="misc.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.miscs)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon
                                                    style="width:100%; margin-top: -5px;">miscellaneous_services</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ misc.grossCost*1 + misc.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="misc.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="misc.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="misc.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="misc.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="misc.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="misc.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="misc.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.trains.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let train of costing.trains; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="train.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.trains)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon
                                                    style="width:100%; margin-top: -5px;">train</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ train.grossCost*1 + train.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="train.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="train.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="train.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="train.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="train.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="train.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="train.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.transfers.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let transfer of costing.transfers; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="transfer.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.transfers)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon
                                                    style="width:100%; margin-top: -5px;">directions_bus</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ transfer.grossCost*1 + transfer.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="transfer.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="transfer.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="transfer.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="transfer.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="transfer.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="transfer.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="transfer.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.supplements.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let supplement of costing.supplements; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="supplement.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.supplements)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon
                                                    style="width:100%; margin-top: -5px;">attach_money</mat-icon>
                                            </div>
                                        </div>
                                        <div class="costings-breakdown-column" *ngIf="costing.autoPricing" style="width: 100%; padding-right: 14.2857%; min-height: 100px;">
                                            <div>The costs for this supplement are calculated automatically
                                                <span *ngIf="costing.supplierName === 'SFC'"><a (click)="getS3template('SFCrates.pdf')"> based on the rates available here</a></span>
                                                <br><br>Please save your changes first before making any adjustments
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            {{ supplement.grossCost*1 + supplement.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="supplement.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="supplement.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="supplement.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="supplement.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="supplement.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="supplement.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="supplement.createTS">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.packages.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let package of costing.packages; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="package.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.packages)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">inventory_2</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ package.grossCost*1 + package.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="package.grossCost" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="package.netCost" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="package.commission" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="package.tax" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="package.discount" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <input matInput ngModel name="depositAmount" type="number" class="customInput"
                                                [(ngModel)]="package.depositAmount" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <input matInput ngModel name="createTS" type="number" class="customInput"
                                                [(ngModel)]="package.createTS">
                                        </div>
                                    </div>
                                </div>

                                <mat-select ngModel name="status" class="customInputSelectLong" panelClass="supplierTypePanel"
                                    floatLabel="never" placeholder="New Element" *ngIf="costing.packages.length == 0 && costing.supplements.length == 0"
                                    (selectionChange)="addElementType($event, costing)">
                                    <mat-select-trigger>New Element</mat-select-trigger>
                                    <mat-option value="accom" [disabled]="costing.isSupplement == 'yes'">Accommodation</mat-option>
                                    <mat-option value="attraction" [disabled]="costing.isSupplement == 'yes'">Attraction</mat-option>
                                    <mat-option value="carhire" [disabled]="costing.isSupplement == 'yes'">Car Hire</mat-option>
                                    <mat-option value="carpark" [disabled]="costing.isSupplement == 'yes'">Car Park</mat-option>
                                    <mat-option value="cruise" [disabled]="costing.isSupplement == 'yes'">Cruise</mat-option>
                                    <mat-option value="flight" [disabled]="costing.isSupplement == 'yes'">Flight</mat-option>
                                    <mat-option value="misc" [disabled]="costing.isSupplement == 'yes'">Miscellaneous</mat-option>
                                    <mat-option value="package"
                                    [disabled]="costing.attractions.length > 0 || costing.carhires.length > 0 || costing.carparks.length > 0 ||
                                    costing.cruises.length > 0 || costing.flights.length > 0 || costing.accoms.length > 0 || costing.trains.length > 0 ||
                                    costing.miscs.length > 0 || costing.transfers.length > 0 || costing.isSupplement == 'yes'">
                                        Package
                                    </mat-option>
                                    <mat-option value="supplement" [disabled]="costing.isSupplement == 'no'">Supplement</mat-option>
                                    <mat-option value="train" [disabled]="costing.isSupplement == 'yes'">Train</mat-option>
                                    <mat-option value="transfer" [disabled]="costing.isSupplement == 'yes'">Transfer</mat-option>
                                </mat-select>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="extElementColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : extElementColumns;" class="trueRow"></mat-row>
                    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                        [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
                    </mat-row>
                    <mat-footer-row class="rowNotClick"
                        *matFooterRowDef="['supplierName', 'ref', 'gross', 'net', 'commission', 'vat', 'discount', 'deposit', 'issueDate']">
                    </mat-footer-row>
                </mat-table>
                </div>

                <div>
                    <div style="display: flex; align-items: center; justify-content: center;">
                        <h1 class="h1headers">Passenger List</h1>
                    </div>
      
                    <div class="divider">
                        <mat-divider></mat-divider>
                    </div>
      
                      <mat-table #tableNew class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="passengers[bookingViewNo]">
                          <ng-container matColumnDef="removeButton">
                              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                              <mat-cell *matCellDef="let passenger">
                                  <div class="costing-supplier-remove">
                                      <div style="width: 40px; margin-right: 5px;">
                                          <button mat-icon-button [disableRipple]="true" color="warn"
                                                  (click)="removePassenger(passenger)">
                                              <mat-icon>remove</mat-icon>
                                          </button>
                                      </div>
                                  </div>
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef>
                                  <button type="button" mat-button class="addElementButton" [disableRipple]="true"
                                      (click)="openAddPassengerDialog()">
                                      Add Passenger
                                  </button>
                              </mat-footer-cell>
                          </ng-container>
      
                        <ng-container matColumnDef="fullName">
                            <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                            <mat-cell *matCellDef="let passenger">
                                <b>{{ passenger.title }} {{ passenger.firstName }} {{ passenger.lastName }}</b>
                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>
      
                          <ng-container matColumnDef="telNo">
                              <mat-header-cell *matHeaderCellDef> Telephone </mat-header-cell>
                              <mat-cell *matCellDef="let passenger">
                                  {{ passenger.telNo }}
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                          </ng-container>
      
                          <ng-container matColumnDef="email">
                              <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                              <mat-cell *matCellDef="let passenger">
                                  {{ passenger.email }}
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                          </ng-container>
      
                         <ng-container matColumnDef="postcode">
                              <mat-header-cell *matHeaderCellDef> Postcode </mat-header-cell>
                              <mat-cell *matCellDef="let passenger">
                                  {{ passenger.postcode }}
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                          </ng-container>
      
                          <ng-container matColumnDef="county">
                              <mat-header-cell *matHeaderCellDef> County </mat-header-cell>
                              <mat-cell *matCellDef="let passenger">
                                  {{ passenger.county }}
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                          </ng-container>
      
                          <ng-container matColumnDef="leadPassenger">
                              <mat-header-cell *matHeaderCellDef> Lead Passenger </mat-header-cell>
                              <mat-cell *matCellDef="let passenger">
                                <input type="checkbox" [(ngModel)]="passenger.isLead" class="customCheckbox" style="transform: scale(1.5)">
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                          </ng-container>
      
                          <mat-header-row *matHeaderRowDef="passengerColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns : passengerColumns;" class="trueRow"></mat-row>
                          <mat-footer-row class="rowNotClick" *matFooterRowDef="passengerColumns"></mat-footer-row>
                      </mat-table>
                  </div>

            </div>

            <div style="margin-bottom: -20px; margin-top: 5px; height: 100%; display: flex; align-items: flex-end;">
                <button *ngIf="!previewOn" class="buttonToRight" mat-button [disableRipple]="true"
                    style="width: calc(100% + 10px); margin-left: -5px; height: 78px; margin-bottom: 1px;"
                    (click)="createBooking()">
                    <mat-icon style="margin-top: unset;">done</mat-icon> Create Booking
                </button>

                <button *ngIf="previewOn" class="buttonToRight" mat-button [disableRipple]="true"
                    style="width: calc(100% + 10px); margin-left: -5px; height: 78px; margin-bottom: 1px;"
                    (click)="createManyBookings()">
                    <mat-icon>file_upload</mat-icon> Upload All
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="bookingOperation == 'View'" class="chosenDiv" [ngStyle]="{'height': branchList.length > 1 ? 'calc(100% - 93px)' : 'calc(100% - 60px)'}">
        <div class="centerWithTwoSides">
            <div style="margin-left: 20px; width: 138px;" [style.visibility]="['wcManager', 'wcMember'].includes(userTypeOut) && memberLive ? 'visible' : 'hidden'">
                <button mat-stroked-button color="primary" [disableRipple]="true" (click)="editToggleOnOff()" [disabled]="editToggleDisable" class="normalDisabled" 
                        [matTooltip]="editToggleDisable ? 'You can no longer edit this booking.\n\nPlease use the button on the right to email the operations team.' : 'Edit Mode'"
                        matTooltipClass="line-normal-tooltip multi-line-tooltip">
                    <mat-icon *ngIf="editToggle">toggle_on</mat-icon>
                    <mat-icon style="color: black;" *ngIf="!editToggle">toggle_off</mat-icon>
                </button>
                
                <button mat-stroked-button color="primary" [disableRipple]="true" (click)="reloadBooking(bookingReference)" matTooltip="Reload Booking"
                        matTooltipClass="line-normal-tooltip" style="margin-left: 10px;">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>

            <div>
                <h1 class="h1headers">Booking Details</h1>
            </div>

            <div style="margin-right: 20px; width: 138px; text-align: right;">
                <button mat-stroked-button color="primary" [disableRipple]="true" (click)="openEmailToOps(extBookingData.bookingReference)"
                *ngIf="['wcManager', 'wcMember'].includes(userTypeOut) && editToggleDisable" matTooltip="Email operations team" matTooltipClass="line-normal-tooltip">
                    <mat-icon>email</mat-icon>
                </button>
                <button mat-stroked-button color="primary" [disableRipple]="true" (click)="goToBooking(extBookingData.bookingReference)" matDialogClose
                *ngIf="['sinGSstaff', 'sinGSAdmin', 'memberManager', 'memberStaff'].includes(userTypeOut) && bookingAccess" matTooltip="Open booking" matTooltipClass="line-normal-tooltip">
                    <mat-icon>launch</mat-icon>
                </button>
            </div>
        </div>

        <div class="divider">
            <mat-divider></mat-divider>
        </div>

        <div *ngIf="bookingReference != ''" style="text-align: center;">
            <h3 *ngIf="pageLoaded && !bookingAccess">You cannot access this booking</h3>
            <h3 *ngIf="!pageLoaded">Please wait..</h3>
        </div>

        <div *ngIf="pageLoaded && bookingAccess" style="display: flex; flex-direction: column; justify-content: flex-start; height: calc(100% - 55px);">
            <div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Branch Name</b></div>
                        <div fxFlex="62.5" id="branchName" style="margin: auto;">
                            {{extBookingData.branchName}}
                            <!--<span *ngIf="bookingAccess && (!editToggle || branchDetails?.tradingNames?.length <= 1)">{{extBookingData.branchName}}</span>
                            <span *ngIf="bookingAccess && (editToggle && branchDetails?.tradingNames?.length > 1)">
                                <mat-select style="width: calc(100% - 15px)" ngModel name="tradingNameId" [(ngModel)]="extBookingData.tradingNameId" #tradingNameId="ngModel" (selectionChange)="updateBookingSeq('Branch name')"
                                            panelClass="atolProtected">
                                    <mat-option *ngFor="let tradingName of branchDetails?.tradingNames" [value]="tradingName.id">
                                      {{tradingName.tradingName}}
                                    </mat-option>
                                  </mat-select>
                            </span>-->
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Group Name</b></div>
                        <div fxFlex="62.5" id="bookingGroup" style="margin: auto;">
                             <span *ngIf="bookingAccess && editToggle">
                                <input matInput ngModel name="bookingGroup" [(ngModel)]="extBookingData.bookingGroup" maxlength="100" class="customInput">
                            </span>
                            <span *ngIf="bookingAccess && !editToggle">
                                <a (click)="showGroupBookings()">{{extBookingData.bookingGroup}}</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Sings Ref.</b></div>
                        <div fxFlex="62.5" id="bookingReference" style="margin: auto;">
                            <span *ngIf="bookingAccess">
                                {{extBookingData.bookingReference | uppercase}}
                            </span>
                            <mat-icon *ngIf="extBookingData.bookingStatus == 'enquiry'" class="statusIcon" style="color: orange;" matTooltip="Enquiry">question_mark</mat-icon>
                            <mat-icon *ngIf="extBookingData.bookingStatus == 'booking'" class="statusIcon" style="color: green;" matTooltip="Booking">check</mat-icon>
                            <mat-icon *ngIf="extBookingData.bookingStatus == 'cancelled'" class="statusIcon" style="color: grey;"matTooltip="Cancelled">close</mat-icon>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Your Reference</b></div>
                        <div fxFlex="62.5" id="extBookRef" style="margin: auto;">
                            <span *ngIf="bookingAccess">
                                {{extBookingData.extBookingSource}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Booking Date</b></div>
                        <div fxFlex="62.5" id="bookingDate" style="margin: auto;">
                            <span class="editSpans" *ngIf="bookingAccess" style="width: 80px; display: inline-block;">
                                {{extBookingData.bookingDate | date:'dd.MM.yyyy'}}
                            </span>

                            <span *ngIf="bookingAccess && editToggle && !bookingDateDisable">
                                <input [matDatepicker]="bookingDate" style="width: 1px; height: 1px; visibility: hidden;"
                                    (dateChange)="editDate($event, 'Booking Date', extBookingData)" />
                                <mat-datepicker-toggle matSuffix [for]="bookingDate" [disableRipple]="true"></mat-datepicker-toggle>
                                <mat-datepicker #bookingDate [startAt]="extBookingData.bookingDate"></mat-datepicker>
                            </span>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Lead Passenger</b></div>
                        <div fxFlex="62.5" id="leadName" style="margin: auto;">
                            <span>{{extBookingData.leadName}}</span>
                        </div>
                    </div>
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Departure Date</b></div>
                        <div fxFlex="62.5" id="deptDate" style="margin: auto;">
                            <span class="editSpans" *ngIf="bookingAccess" style="width: 80px; display: inline-block;">
                                {{extBookingData.deptDate | date:'dd.MM.yyyy'}}
                            </span>
                            <span *ngIf="bookingAccess && editToggle">
                                <input [matDatepicker]="deptDate" style="width: 1px; height: 1px; visibility: hidden;"
                                    (dateChange)="editDate($event, 'Departure Date', extBookingData)" />
                                <mat-datepicker-toggle matSuffix [for]="deptDate" [disableRipple]="true"></mat-datepicker-toggle>
                                <mat-datepicker #deptDate [startAt]="extBookingData.deptDate"></mat-datepicker>
                            </span>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Passengers</b></div>
                        <div fxFlex="62.5" id="paxNo" style="margin: auto;">
                            <span>{{extBookingData.paxNo}}</span>
                        </div>
                    </div>
                </div>
                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Return Date</b></div>
                        <div fxFlex="62.5" id="returnDate" style="margin: auto;">
                            <span class="editSpans" *ngIf="bookingAccess" style="width: 80px; display: inline-block;">
                                {{extBookingData.returnDate | date:'dd.MM.yyyy'}}
                            </span>
                            <span *ngIf="bookingAccess && editToggle">
                                <input [matDatepicker]="returnDate" style="width: 1px; height: 1px; visibility: hidden;"
                                    (dateChange)="editDate($event, 'Return Date', extBookingData)" />
                                <mat-datepicker-toggle matSuffix [for]="returnDate" [disableRipple]="true"></mat-datepicker-toggle>
                                <mat-datepicker #returnDate [startAt]="extBookingData.returnDate"></mat-datepicker>
                            </span>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>ATOL Protected</b></div>
                        <div fxFlex="62.5" id="atolProtected" style="margin: auto;">
                            <span class="editSpans" *ngIf="bookingAccess && !editToggle">{{extBookingData.underATOL}}</span>
                            <span *ngIf="bookingAccess && editToggle">
                                <mat-select style="width: calc(100% - 15px)" ngModel name="underATOL" [(ngModel)]="extBookingData.underATOL" #underATOL="ngModel" (selectionChange)="updateBookingSeq('ATOL coverage')"
                                    matTooltipClass="line-normal-tooltip" matTooltip="Note: Changing 'No' to any other value will create an ATOL supplement in the booking if it doesn't already exist"
                                    panelClass="atolProtected">
                                    <mat-option value="No">No</mat-option>
                                    <mat-option value="Retail Agent of ATOL" [disabled]="(!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly)" matTooltipClass="line-normal-tooltip"
                                    [matTooltip]="!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly ? 'Your booking does not meet the ATOL criteria' : 'Choose this option if you are acting as a retail agent of an ATOL holder. The ATOL holder will provide ATOL protection to customers, and you will not issue ATOL certificates directly'">
                                    Retail Agent of ATOL</mat-option>
                                    <mat-option value="T-ATOL Protection" [disabled]="(!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly) || branchDetails.isTAtol == 'no'" matTooltipClass="line-normal-tooltip"
                                    [matTooltip]="!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly ? 'Your booking does not meet the ATOL criteria' : (branchDetails.isTAtol == 'no' ? 'You are not registered as T-ATOL holder in the system' : 'Select this option if you are an ATOL holder and will provide ATOL protection to customers under your own ATOL number. You will issue ATOL certificates directly to customers')">
                                    T-ATOL Protection</mat-option>
                                </mat-select>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Balance Due Date</b></div>
                        <div fxFlex="62.5" id="balanceDueDate" style="margin: auto;">
                            <span class="editSpans" *ngIf="bookingAccess" style="width: 80px; display: inline-block;">
                                {{extBookingData.balanceDueDate | date:'dd.MM.yyyy'}}
                            </span>
                            <span *ngIf="bookingAccess && editToggle">
                                <input [matDatepicker]="balanceDueDate" style="width: 1px; height: 1px; visibility: hidden;"
                                    (dateChange)="editDate($event, 'Balance Due Date', extBookingData)" />
                                <mat-datepicker-toggle matSuffix [for]="balanceDueDate" [disableRipple]="true"></mat-datepicker-toggle>
                                <mat-datepicker #balanceDueDate [startAt]="extBookingData.balanceDueDate"></mat-datepicker>
                            </span>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Deposit Paid</b></div>
                        <div fxFlex="62.5" id="depositPaid" style="margin: auto;">
                            <input type="checkbox" [(ngModel)]="extBookingData.depositPaid" class="customCheckbox" style="transform: scale(1.5)"
                                    [disabled]="!bookingAccess || !editToggle">
                        </div>
                    </div>
                </div>

                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Destination</b></div>
                        <div fxFlex="62.5" id="destCountry" style="margin: auto;">
                            <span *ngIf="bookingAccess && !editToggle">
                                {{ extBookingData.destCountry }}
                            </span>
                            <span class="editSpans" *ngIf="bookingAccess && editToggle">
                                <mat-select ngModel required name="country" #country="ngModel" class="customInput customInputSelect customInputSelectShort" [(ngModel)]="extBookingData.destCountry">
                                    <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                                      <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterStrCountry" (keyup)="filterCountries()">
                                    </mat-form-field>
                                    <mat-option *ngFor="let country of countriesFiltered" [value]="country" (click)="filterStrCountry = ''; filterCountries();">
                                      {{country}}
                                    </mat-option>
                                </mat-select>
                            </span>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Mark Up/Down</b></div>
                        <div fxFlex="62.5" id="markUpDown" style="margin: auto;">
                            <span *ngIf="bookingAccess && !editToggle">
                                {{ extBookingData.markUpDown | currency: 'GBP' }}
                            </span>
                            <span class="editSpans" *ngIf="bookingAccess && editToggle">
                                <input matInput ngModel name="markUpDown" type="number" class="customInput" [(ngModel)]="extBookingData.markUpDown">
                            </span>
                        </div>
                    </div>
                </div>

                <div class="generalInfoRow">
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Paid by Customers</b></div>
                        <div fxFlex="62.5" id="receipted" style="margin: auto;">
                            <span *ngIf="bookingAccess">
                                {{extBookingData.totalReceipted | currency:'GBP'}} / {{ extBookingData.custPrice | currency:'GBP' }} ({{ customerBalanceDue | currency:'GBP' }})
                            </span>
                        </div>
                    </div>
                    <div fxFlex="50" style="height: 40px;">
                        <div fxFlex="37.5" style="margin: auto;"><b>Paid To Suppliers</b></div>
                        <div fxFlex="62.5" id="paidToSupp" style="margin: auto;">
                            <span *ngIf="bookingAccess">
                                {{extBookingData.totalSuppPayments | currency:'GBP'}} / {{ extBookingData.net | currency:'GBP' }} ({{ dueToSuppliers | currency:'GBP' }})
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="divider">
                    <mat-divider></mat-divider>
                </div>

                <div class="centerWithTwoSides">
                    <div style="margin-left: 20px;">
                        <button mat-icon-button color="accent" [disableRipple]="true" class="normalDisabled"
                        [disabled]="bookSelOptionView == 0" (click)="bookSelOptionView = bookSelOptionView - 1">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                    </div>
        
                    <div>
                        <h1 class="h1headers">{{bookViewOptions[bookSelOptionView]}}</h1>
                    </div>
        
                    <div style="margin-right: 20px;">
                        <button mat-icon-button color="accent" [disableRipple]="true" class="normalDisabled"
                        [disabled]="bookSelOptionView == 3" (click)="bookSelOptionView = bookSelOptionView + 1">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="divider">
                    <mat-divider></mat-divider>
                </div>

                <mat-table *ngIf="extElementData.data.length > 0 && bookSelOptionView == 0"
                    #tableOld class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="extElementData">
                    <ng-container matColumnDef="supplierName">
                        <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                        <mat-cell *matCellDef="let costing">
                            <div [class.costing-supplier-remove]="costing.id == undefined">
                                <div style="width: 40px; margin-right: 5px;">
                                    <button mat-icon-button [disableRipple]="true" color="warn" *ngIf="costing.id == undefined"
                                    (click)="removeMainElement(extElementData.data.indexOf(costing))">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div style="width: 100%;">
                                    <span *ngIf="costing?.isUnderSFC || costing?.isUnderSafi" class="underSFC"
                                    [matTooltip]="costing?.isUnderSFC && costing?.isUnderSafi ? 'Under SFC and SAFI' : (costing?.isUnderSFC ? 'Under SFC' : 'Under SAFI')">
                                        {{ costing.supplierName }}
                                    </span>
                                    <span *ngIf="!costing?.isUnderSFC && !costing?.isUnderSafi">
                                        {{ costing.supplierName }}
                                    </span>
                                </div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button type="button" mat-button class="addElementButton" [disableRipple]="true"
                                (click)="openSupplDialog('view', null); changeSuppType('no')" *ngIf="!supplementDisable && memberLive" style="font-size: 11px;">
                                Add Supplier
                            </button>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="ref">
                        <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                        <mat-cell *matCellDef="let costing">
                            <span *ngIf="costing.elementType != 'supplement'"><b>{{ costing.supplierReference }}</b></span>

                            <span *ngIf="costing.elementType == 'supplement'">
                                <button mat-button color="primary" (click)="downloadCertificate(costing, 'standalone')" *ngIf="costing.supplierName == 'Safe Seat Plan Guarantee - Standalone'" [disableRipple]="true"
                                        [matTooltip]="extBookingData.bookingStatus == 'booking' ? 'Get certificate' : 'Enquiries and cancelled bookings cannot generate SASSPG certificates'"
                                        matTooltipClass="line-normal-tooltip" class="normalDisabled" [disabled]="extBookingData.bookingStatus != 'booking'">
                                    <mat-icon>description</mat-icon>
                                </button>
                                <button mat-button color="primary" (click)="downloadCertificate(costing, 'sfc')" *ngIf="costing.supplierName == 'SFC' && isUnderSFC()" [disableRipple]="true"
                                        [matTooltip]="extBookingData.bookingStatus == 'booking' ? 'Get certificate for Legacy PRF' : 'Enquiries and cancelled bookings cannot generate SFC certificates'"
                                        matTooltipClass="line-normal-tooltip" class="normalDisabled" [disabled]="extBookingData.bookingStatus != 'booking'">
                                    <mat-icon>description</mat-icon>
                                </button>

                                <mat-icon color="warn" *ngIf="costing.supplierName == 'SFC' && !isUnderSFC()"
                                matTooltip="There are no suppliers covered under SFC in the booking" style="margin-left: 5px;">priority_high</mat-icon>

                                <mat-icon color="warn" *ngIf="costing.supplierName == 'SAFI' && flightsOnly.length == 0" style="margin-left: 5px;"
                                matTooltip="There are no 'Flight Only' elements with a SAFI supplier in the booking">priority_high</mat-icon>
                            </span>
                            
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button type="button" mat-button class="addElementButton" [disableRipple]="true"
                                (click)="openSupplDialog('view', null); changeSuppType('yes')" *ngIf="!supplementDisable && memberLive" style="font-size: 11px;">
                                Add Supplement
                            </button>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="gross">
                        <mat-header-cell *matHeaderCellDef> Gross </mat-header-cell>
                        <mat-cell *matCellDef="let costing" (click)="expandedElement = expandedElement === costing ? null : costing;">
                            <span *ngIf="costing.supplierName != 'SFC'">{{ costing.gross | currency:'GBP' }}</span>
                            <span *ngIf="costing.supplierName == 'SFC'" [matTooltip]="'Based on ' + costing.supplements[0].costBasedOn + ' values'">{{ costing.gross | currency:'GBP' }}</span>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.grossCost | currency:'GBP'
                                }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="net">
                        <mat-header-cell *matHeaderCellDef> Net </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.net | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.netCost | currency:'GBP'
                                }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="commission">
                        <mat-header-cell *matHeaderCellDef> Commission </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.commission | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.commission | currency:'GBP'
                                }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="vat">
                        <mat-header-cell *matHeaderCellDef> VAT </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.tax | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.tax | currency:'GBP'
                                }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                        <mat-header-cell *matHeaderCellDef> Discount </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.discount | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.discount | currency:'GBP'
                                }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="deposit">
                        <mat-header-cell *matHeaderCellDef> Deposit </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.depositAmount | currency:'GBP' }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.totalDeposit | currency:'GBP'
                        }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="issueDate">
                        <mat-header-cell *matHeaderCellDef> Issue Date </mat-header-cell>
                        <mat-cell *matCellDef="let costing"
                            (click)="expandedElement = expandedElement === costing ? null : costing;">
                            {{ costing.createTS | date }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <mat-cell class="expandedDetail" *matCellDef="let costing" [attr.colspan]="7">
                            <div class="costings-breakdown-main">
                                <div class="costings-breakdown-row-header">
                                    <div fxFlex="14.2857"></div>
                                    <div fxFlex="14.2857" matTooltip="Gross + Discount">Supplier Gross*</div>
                                    <div fxFlex="14.2857">Gross</div>
                                    <div fxFlex="14.2857">Net</div>
                                    <div fxFlex="14.2857">Commission</div>
                                    <div fxFlex="14.2857">VAT</div>
                                    <div fxFlex="14.2857">Discount</div>
                                    <div fxFlex="14.2857">Deposit</div>
                                    <div fxFlex="14.2857"></div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.attractions.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let attraction of costing.attractions; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="attraction.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.attractions)"
                                                    color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">attractions</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ attraction.grossCost*1 + attraction.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ attraction.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="attraction.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ attraction.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="attraction.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ attraction.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="attraction.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ attraction.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="attraction.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ attraction.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="attraction.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ attraction.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="attraction.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ attraction.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="attraction.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.carhires.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let carHire of costing.carhires; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="carHire.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.carhires)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">drive_eta</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ carHire.grossCost*1 + carHire.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carHire.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="carHire.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carHire.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="carHire.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carHire.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="carHire.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carHire.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="carHire.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carHire.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="carHire.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carHire.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="carHire.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ carHire.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="carHire.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.carparks.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let carParking of costing.carparks; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="carParking.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.carparks)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">local_parking</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ carParking.grossCost*1 + carParking.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carParking.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="carParking.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carParking.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="carParking.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carParking.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="carParking.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carParking.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="carParking.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carParking.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="carParking.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ carParking.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="carParking.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ carParking.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="carParking.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.cruises.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let cruise of costing.cruises; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="cruise.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.cruises)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">directions_boat</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ cruise.grossCost*1 + cruise.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ cruise.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="cruise.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ cruise.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="cruise.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ cruise.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="cruise.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ cruise.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="cruise.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ cruise.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="cruise.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ cruise.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="cruise.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ cruise.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="cruise.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.flights.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let flight of costing.flights; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="flight.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.flights)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">flight</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ flight.grossCost*1 + flight.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ flight.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="flight.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ flight.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="flight.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ flight.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="flight.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ flight.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="flight.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ flight.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="flight.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ flight.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="flight.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ flight.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="flight.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.accoms.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let hotel of costing.accoms; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="hotel.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.accoms)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">hotel</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ hotel.grossCost*1 + hotel.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ hotel.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="hotel.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ hotel.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="hotel.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ hotel.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="hotel.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ hotel.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="hotel.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ hotel.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="hotel.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ hotel.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="hotel.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ hotel.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="hotel.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.miscs.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let misc of costing.miscs; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="misc.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.miscs)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">miscellaneous_services</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ misc.grossCost*1 + misc.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ misc.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="misc.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ misc.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="misc.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ misc.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="misc.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ misc.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="misc.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ misc.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="misc.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ misc.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="misc.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ misc.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="misc.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.trains.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let train of costing.trains; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="train.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.trains)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">train</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ train.grossCost*1 + train.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ train.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="train.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ train.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="train.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ train.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="train.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ train.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="train.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ train.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="train.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ train.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="train.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ train.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="train.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.transfers.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let transfer of costing.transfers; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="transfer.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.transfers)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">directions_bus</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ transfer.grossCost*1 + transfer.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ transfer.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="transfer.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ transfer.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="transfer.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ transfer.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="transfer.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ transfer.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="transfer.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ transfer.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="transfer.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ transfer.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="transfer.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ transfer.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="transfer.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.elementType != 'package' && costing.supplements.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let supplement of costing.supplements; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="supplement.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.supplements)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">attach_money</mat-icon>
                                            </div>
                                        </div>
                                        <div class="costings-breakdown-column" *ngIf="costing.autoPricing" style="width: 100%; padding-right: 14.2857%; min-height: 100px;">
                                            <div *ngIf="supplement.id == undefined" [ngStyle]="{'margin-top.px': !supplementDisable && supplement.id != undefined ? 17 : 0}">
                                                The costs for this supplement are calculated automatically
                                                <span *ngIf="costing.supplierName === 'SFC'"><a (click)="getS3template('SFCrates.pdf')"> based on the rates available here</a></span>
                                                <br><br>Please save your changes first before making any adjustments
                                            </div>
                                            
                                            <span *ngIf="supplement.id != undefined" style="margin-top: 20px; margin-bottom: 10px;">
                                                <span *ngIf="costing.supplierName == 'SAFI' && !oldSafi">
                                                    <ng-container *ngIf="!supplementDisable">
                                                        <div class="warningSafi" *ngIf="flightsOnly.length > 0">Please remember to select the flights you wish to be covered by SAFI</div>
                                                        <div *ngFor="let flight of flightsOnly; index as i" style="display: flex; justify-content: center; align-items: center;">
                                                            <div class="sfcMainDiv">
                                                                <div class="sfcSubDiv" [style.visibility]="i > 0 ? 'hidden' : 'visible'" style="margin-bottom: 10px;">
                                                                    <div style="flex: 0 0 33.33%;">
                                                                        Departure
                                                                    </div>
                                                                    <div style="flex: 0 0 33.33%;">
                                                                        Arrival
                                                                    </div>
                                                                    <div style="flex: 0 0 33.33%;">
                                                                        Gross Cost
                                                                    </div>
                                                                </div>
                                                                <div class="sfcSubDiv">
                                                                    <div style="flex: 0 0 33.33%;">
                                                                        {{ flight.departDateTime | date:"dd.MM.yyyy" }}
                                                                    </div>
                                                                    <div style="flex: 0 0 33.33%;">
                                                                        {{ flight.arriveDateTime | date:"dd.MM.yyyy" }}
                                                                    </div>
                                                                    <div style="flex: 0 0 33.33%;">
                                                                        {{ flight.grossCost | currency: "GBP" }}
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div style="text-align: center; margin-left: 50px;">
                                                                <div style="margin-bottom: 7.5px;"><span [style.visibility]="i > 0 ? 'hidden' : 'visible'">Covered</span></div>
                                                                <input type="checkbox" [checked]="flight.underSafi == 'yes'" value="{{flight.underSafi}}" (change)="changeFlightSafi($event, flight)">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    
                                                    <div [style.margin-top.px]="!supplementDisable ? 15 : 10">Cost Per Passenger: <b>{{ newSafiInfo.safiBookGrossPrice | currency: 'GBP' }}</b></div>
                                                </span>
    
                                                <span *ngIf="costing.supplierName == 'SFC'">
                                                    <span *ngIf="costing.supplements[0].costBasedOn == 'gross'">Cost Per Passenger: <b>{{ newSfcInfo.sfcBookGrossPrice | currency: 'GBP' }}</b></span>
                                                    <span *ngIf="costing.supplements[0].costBasedOn == 'net'">Cost Per Passenger: <b>{{ newSfcInfo.sfcBookNetPrice | currency: 'GBP' }}</b></span>
                                                    (based on {{ supplement.costBasedOn}} values)
                                                </span>
    
                                                <span *ngIf="costing.supplierName != 'SFC' && (costing.supplierName != 'SAFI' || oldSafi)">
                                                    <span *ngIf="selectedSupplement.costFixPerPx > 0">Cost Per Passenger: <b>{{ selectedSupplement.costFixPerPx | currency: 'GBP' }}</b><br></span>
                                                  
                                                    <span *ngIf="selectedSupplement.costFixPerBk > 0">One-Off Cost: <b>{{ selectedSupplement.costFixPerBk | currency: 'GBP' }}</b><br></span>
                                                    
                                                    <span *ngIf="selectedSupplement.costPercPerBkGross > 0">Bookings Gross Rate: <b>{{ selectedSupplement.costPercPerBkGross  | number : '1.2-2'}}% (
                                                        {{ extBookingData.custPrice * (selectedSupplement.costPercPerBkGross / 100) | currency: 'GBP' }} )</b><br></span>
                                                    
                                                    <span *ngIf="selectedSupplement.costFixPerMx > 0">One-Off Cost: <b>{{ selectedSupplement.costFixPerMx | currency: 'GBP' }}</b><br></span>
                                                </span>
                                            </span>

                                            <div style="display: flex; justify-content: space-around;" [ngStyle]="{'margin-top.px': supplement.id != undefined ? 10 : 0}" *ngIf="!supplementDisable && supplementLoaded && memberLive">
                                                <button type="button" mat-button color="primary" *ngIf="costing.autoPricing && !costing.supplements[0].applyDiscount && supplement.id != undefined"
                                                        [disableRipple]="true" (click)="changeAppliedDiscount(costing.supplements[0], costing.elementCount, true)" class="supplementButtons">
                                                    <mat-icon>redeem</mat-icon> Apply discount
                                                </button>

                                                <button type="button" mat-button color="primary" *ngIf="costing.autoPricing && costing.supplements[0].applyDiscount && supplement.id != undefined && memberLive"
                                                        [disableRipple]="true" (click)="changeAppliedDiscount(costing.supplements[0], costing.elementCount, false)" class="supplementButtons">
                                                    <mat-icon>money_off</mat-icon> Remove discount
                                                </button>
                                                
                                                <!--<button type="button" mat-button color="primary" *ngIf="costing.supplierName == 'SFC' && supplement.id != undefined"
                                                        [disableRipple]="true" (click)="changeSFCbasis(costing.supplements[0], costing.elementCount)" class="supplementButtons">
                                                    <mat-icon>autorenew</mat-icon> Change SFC basis
                                                </button>-->

                                                <button type="button" mat-button color="primary" *ngIf="costing.supplierName == 'SAFI' && supplement.id != undefined && !oldSafi && memberLive"
                                                        [disableRipple]="true" (click)="updateSafiCoverage()" class="supplementButtons">
                                                    <mat-icon>update</mat-icon> Update SAFI coverage
                                                </button>

                                                <button type="button" mat-button color="primary" *ngIf="costing.supplierName == 'SFC' && supplement.id"
                                                        [disableRipple]="true" (click)="getS3template('SFCrates.pdf')" class="supplementButtons">
                                                    <mat-icon class="iconInButton">file_download</mat-icon>Rates
                                                </button>
                                            </div>

                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            {{ supplement.grossCost*1 + supplement.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <span *ngIf="supplementDisable">{{ supplement.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="supplement.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <span *ngIf="supplementDisable">{{ supplement.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="supplement.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <span *ngIf="supplementDisable">{{ supplement.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="supplement.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <span *ngIf="supplementDisable">{{ supplement.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="supplement.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <span *ngIf="supplementDisable">{{ supplement.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="supplement.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing">
                                            <span *ngIf="supplementDisable">{{ supplement.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="supplement.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" *ngIf="!costing.autoPricing" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ supplement.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="supplement.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="costing.packages.length > 0">
                                    <div class="costings-breakdown-row"
                                        *ngFor="let package of costing.packages; index as index">
                                        <div fxFlex="14.2857" class="costings-breakdown-column-first">
                                            <div *ngIf="package.id == undefined">
                                                <button mat-icon-button [disableRipple]="true"
                                                    (click)="removeElementType(index, costing, costing.packages)" color="warn">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-icon style="width:100%; margin-top: -5px;">inventory_2</mat-icon>
                                            </div>
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            {{ package.grossCost*1 + package.discount*1 | currency: 'GBP' }}</div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ package.grossCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="grossCost" type="number" class="customInput"
                                                [(ngModel)]="package.grossCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'grossCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ package.netCost | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="netCost" type="number" class="customInput"
                                                [(ngModel)]="package.netCost" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'netCost')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ package.commission | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="commission" type="number" class="customInput"
                                                [(ngModel)]="package.commission" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'commission')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ package.tax | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="tax" type="number" class="customInput"
                                                [(ngModel)]="package.tax" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'tax')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ package.discount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="discount" type="number" class="customInput"
                                                [(ngModel)]="package.discount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'discount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column">
                                            <span *ngIf="supplementDisable">{{ package.depositAmount | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="deposit" type="number" class="customInput"
                                                [(ngModel)]="package.depositAmount" *ngIf="!supplementDisable" (change)="addUpCosts(costing, 'depositAmount')">
                                        </div>
                                        <div fxFlex="14.2857" class="costings-breakdown-column" style="visibility: hidden;">
                                            <span *ngIf="supplementDisable">{{ package.issueDate | currency: 'GBP' }}</span>
                                            <input matInput ngModel name="issueDate" type="number" class="customInput"
                                                [(ngModel)]="package.issueDate" *ngIf="!supplementDisable">
                                        </div>
                                    </div>
                                </div>

                                <mat-select ngModel name="status" class="customInputSelectLong" *ngIf="!supplementDisable && costing.packages.length == 0 && costing.supplements.length == 0 && memberLive"
                                    floatLabel="never" placeholder="New Element" panelClass="supplierTypePanel"
                                    (selectionChange)="addElementType($event, costing)">
                                    <mat-select-trigger>New Element</mat-select-trigger>
                                    <mat-option value="accom" [disabled]="costing.isSupplement == 'yes'">Accommodation</mat-option>
                                    <mat-option value="attraction" [disabled]="costing.isSupplement == 'yes'">Attraction</mat-option>
                                    <mat-option value="carhire" [disabled]="costing.isSupplement == 'yes'">Car Hire</mat-option>
                                    <mat-option value="carpark" [disabled]="costing.isSupplement == 'yes'">Car Park</mat-option>
                                    <mat-option value="cruise" [disabled]="costing.isSupplement == 'yes'">Cruise</mat-option>
                                    <mat-option value="flight" [disabled]="costing.isSupplement == 'yes'">Flight</mat-option>
                                    <mat-option value="misc" [disabled]="costing.isSupplement == 'yes'">Miscellaneous</mat-option>
                                    <mat-option value="package"
                                    [disabled]="costing.attractions.length > 0 || costing.carhires.length > 0 || costing.carparks.length > 0 ||
                                    costing.cruises.length > 0 || costing.flights.length > 0 || costing.accoms.length > 0 || costing.trains.length > 0 ||
                                    costing.miscs.length > 0 || costing.transfers.length > 0 || costing.isSupplement == 'yes'">
                                        Package
                                    </mat-option>
                                    <mat-option value="supplement" [disabled]="costing.isSupplement == 'no'">Supplement</mat-option>
                                    <mat-option value="train" [disabled]="costing.isSupplement == 'yes'">Train</mat-option>
                                    <mat-option value="transfer" [disabled]="costing.isSupplement == 'yes'">Transfer</mat-option>
                                </mat-select>

                                <div class="costErrPopupContainer" *ngIf="costing.costErr" [@inOutAnimationSupplement]>
                                    <span class="costErrPopup">You are no longer allowed to reduce the costs for this booking</span>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="extElementColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : extElementColumns;" class="trueRow" (click)="calcSupplementCosts(row)">
                    </mat-row>
                    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                        [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
                    </mat-row>
                    <mat-footer-row class="rowNotClick" [ngStyle]="editToggle ? {'border-bottom': '0px solid black'} : {}"
                        *matFooterRowDef="['supplierName', 'ref', 'gross', 'net', 'commission', 'vat', 'discount', 'deposit', 'issueDate']">
                    </mat-footer-row>
                </mat-table>

                <div *ngIf="bookSelOptionView == 1">
                    <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="extPassengersData">
  
                      <ng-container matColumnDef="removeButton">
                        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                        <mat-cell *matCellDef="let passenger">
                          <div class="costing-supplier-remove">
                            <div>
                              <button matTooltip="Update passenger" mat-icon-button color="primary" matTooltipClass="line-normal-tooltip"
                                    (click)="openEditPassengerDialog(passenger)" [disableRipple]="true" *ngIf="(!passenger.isLead || (passenger.isLead && editToggle)) && memberLive">
                                <mat-icon>manage_accounts</mat-icon>
                              </button>

                              <button matTooltip="Bring passenger back" mat-icon-button color="accent" matTooltipClass="line-normal-tooltip" style="margin-right: 25px;"
                                    (click)="changePaxActive(passenger, 'yes')" *ngIf="passenger.active == 'no' && !passenger.isLead && memberLive" [disableRipple]="true">
                                <mat-icon>person_add</mat-icon>
                              </button>
          
                              <button matTooltip="Cancel passenger" mat-icon-button color="accent" matTooltipClass="line-normal-tooltip"
                                    (click)="changePaxActive(passenger, 'no')" *ngIf="passenger.active == 'yes' && !passenger.isLead && memberLive" [disableRipple]="true">
                                <mat-icon>person_remove</mat-icon>
                              </button>

                              <button mat-icon-button [disableRipple]="true" color="warn" matTooltipClass="line-normal-tooltip" matTooltip="Remove passenger"
                                      (click)="removeLivePassenger(passenger)" *ngIf="editToggle && !passenger.isLead && memberLive" style="margin-right: 25px;">
                                  <mat-icon>remove</mat-icon>
                              </button>
                            </div>
                          </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button type="button" mat-button class="addElementButton" [disableRipple]="true" (click)="openAddPassengerDialog()" *ngIf="memberLive">
                                Add Passenger
                            </button>
                        </mat-footer-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="fullName">
                          <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                          <mat-cell *matCellDef="let passenger">
                              <b>{{ passenger.title }} {{ passenger.firstName }} {{ passenger.lastName }}</b>
                          </mat-cell>
                          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="telNo">
                          <mat-header-cell *matHeaderCellDef> Telephone </mat-header-cell>
                          <mat-cell *matCellDef="let passenger">
                              {{ passenger.telNo }}
                          </mat-cell>
                          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="email">
                          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                          <mat-cell *matCellDef="let passenger">
                              {{ passenger.email }}
                          </mat-cell>
                          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>
  
                     <ng-container matColumnDef="postcode">
                          <mat-header-cell *matHeaderCellDef> Postcode </mat-header-cell>
                          <mat-cell *matCellDef="let passenger">
                              {{ passenger.postcode }}
                          </mat-cell>
                          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="county">
                          <mat-header-cell *matHeaderCellDef> County </mat-header-cell>
                          <mat-cell *matCellDef="let passenger">
                              {{ passenger.county }}
                          </mat-cell>
                          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="leadPassenger">
                        <mat-header-cell *matHeaderCellDef> Lead Passenger </mat-header-cell>
                        <mat-cell *matCellDef="let passenger">
                            <input type="checkbox" [(ngModel)]="passenger.isLead" class="customCheckbox" style="transform: scale(1.5)" [disabled]="!editToggle || passenger.isLead"
                                (click)="setLeadPassenger(passenger)" *ngIf="passenger.active == 'yes' && memberLive">

                            <mat-icon matTooltip="Cancelled passenger" matTooltipClass="line-normal-tooltip" color="accent" *ngIf="passenger.active == 'no'">person_off</mat-icon>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>
  
                      <mat-header-row *matHeaderRowDef="passengerColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: passengerColumns;"></mat-row>
                      <mat-footer-row class="rowNotClick" *matFooterRowDef="passengerColumns"></mat-footer-row>
                    </mat-table>
                </div>

                <mat-table *ngIf="bookSelOptionView == 2" class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="receiptsData">

                    <ng-container matColumnDef="receiptDate">
                      <mat-header-cell *matHeaderCellDef> Receipt Date </mat-header-cell>
                      <mat-cell *matCellDef="let receipt"> {{receipt.receiptDate | date : "dd.MM.y" }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel placeholder="Receipt Date" name="receiptDate" type="date" class="date-input"
                                #receiptDate="ngModel" [(ngModel)]="newReceipt.receiptDate">
                      </mat-footer-cell>
                    </ng-container>
    
                    <ng-container matColumnDef="totalCharge">
                      <mat-header-cell *matHeaderCellDef> Total Charge </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.totalCharge | currency:'GBP' }}</span>
                        <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.totalCharge | currency:'GBP' }}</s></span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel step="0.01" type="number" name="totalCharge" (change)="newReceiptCalc('totalCharge')"
                                [(ngModel)]="newReceipt.totalCharge">
                      </mat-footer-cell>
                    </ng-container>
    
                    <ng-container matColumnDef="merchantFee">
                      <mat-header-cell *matHeaderCellDef> Merchant Fee </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.merchantFee | currency:'GBP' }}</span>
                        <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.merchantFee | currency:'GBP' }}</s></span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel min="0.00" step="0.01" type="number" name="merchantFee" (change)="newReceiptCalc('merchantFee')"
                            [(ngModel)]="newReceipt.merchantFee" [disabled]="!['Amex', 'Credit Card', 'Debit Card'].includes(newReceipt.paymentMethod)">
                      </mat-footer-cell>
                    </ng-container>
    
                    <ng-container matColumnDef="creditValue">
                      <mat-header-cell *matHeaderCellDef> Receipt Value </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.creditValue | currency:'GBP' }}</span>
                        <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.creditValue | currency:'GBP' }}</s></span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel step="0.01" type="number" name="creditValue" (change)="newReceiptCalc('creditValue')"
                                [(ngModel)]="newReceipt.creditValue">
                      </mat-footer-cell>
                    </ng-container>
    
                    <ng-container matColumnDef="paymentMethod">
                      <mat-header-cell *matHeaderCellDef> Payment Method </mat-header-cell>
                      <mat-cell *matCellDef="let receipt"> {{ receipt.paymentMethod | titlecase }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <select [(ngModel)]="newReceipt.paymentMethod" name="paymentMethod" (change)="newReceiptCalc('')" class="custom-select">
                            <option value="Amex">Amex</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Cash">Cash</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Credit Card">Credit Card</option>
                            <option value="Debit Card">Debit Card</option>
                            <option value="Direct To Op">Direct To Op</option>
                            <option value="Felloh - Manual">Felloh - Manual</option>
                        </select>
                      </mat-footer-cell>
                    </ng-container>
    
                    <ng-container matColumnDef="reference">
                      <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                      <mat-cell *matCellDef="let receipt"> {{ receipt.reference }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel name="reference" maxlength="50" [(ngModel)]="newReceipt.reference" placeholder="Reference">
                      </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="options">
                        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
                        <mat-cell *matCellDef="let receipt">
                            <div *ngIf="canMatchBanking && !matchCodeMismatch && viewFromMatch == 'receipts'">
                                <button *ngIf="receipt.matchStatus == 'TBD'" color="accent" mat-button matDialogClose
                                (click)="linkToStmntLine(receipt)" [disableRipple]="true">
                                    <mat-icon>link</mat-icon> Link
                                </button>
                            </div>

                            <div *ngIf="canMatchBanking && matchCodeMismatch && viewFromMatch == 'receipts'">
                                <button *ngIf="receipt.matchStatus == 'TBD'" color="warn" mat-button
                                matTooltip="Wrong member" matTooltipClass="wrong-member-tip" [disableRipple]="true">
                                    <mat-icon>block</mat-icon>
                                </button>
                            </div>

                            <div *ngIf="!canMatchBanking">
                                <button class="matIcon" type="button" mat-button color="warn" (click)="cancelReceipt(receipt)" [disableRipple]="true">
                                    <mat-icon matTooltip="Cancel Receipt">undo</mat-icon>
                                </button>
                            </div>                            
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button color="primary" mat-button (click)="addNewReceipt()" [disableRipple]="true">
                                <mat-icon matTooltip="Add Receipt">add</mat-icon>
                            </button>
                        </mat-footer-cell>
                    </ng-container>
    
                    <mat-header-row *matHeaderRowDef="receiptColumns"></mat-header-row>
                    <mat-row [ngClass]="row.highlight ? 'rowNotClick highlitRow' : 'rowNotClick'"
                    *matRowDef="let row; columns : receiptColumns;"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="receiptColumns"></mat-footer-row>
                </mat-table>

                <mat-table *ngIf="bookSelOptionView == 3" class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="paymentsData">

                    <ng-container matColumnDef="paymentDate">
                      <mat-header-cell *matHeaderCellDef> Payment Date </mat-header-cell>
                      <mat-cell *matCellDef="let payment"> {{payment.paymentDate | date : "dd.MM.y" }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel placeholder="Payment Date" name="paymentDate" type="date" class="date-input"
                                #paymentDate="ngModel" [(ngModel)]="newPayment.paymentDate">
                      </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentAmount">
                      <mat-header-cell *matHeaderCellDef> Payment Amount </mat-header-cell>
                      <mat-cell *matCellDef="let payment">
                        <span *ngIf="payment.paymentCategory != 'mistake' && payment.paymentCategory != 'cancelled'">{{ payment.paymentAmount | currency:'GBP' }}</span>
                        <span *ngIf="payment.paymentCategory == 'mistake' || payment.paymentCategory == 'cancelled'"><s>{{ payment.paymentAmount | currency:'GBP' }}</s></span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel step="0.01" type="number" name="paymentAmount" [(ngModel)]="newPayment.paymentAmount">
                      </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentMethod">
                        <mat-header-cell *matHeaderCellDef> Payment Method </mat-header-cell>
                        <mat-cell *matCellDef="let payment"> {{ payment.paymentMethod }} </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <select [(ngModel)]="newPayment.paymentMethod" name="paymentMethod" class="custom-select">
                                <option value="ACAS">ACAS</option>
                                <option value="BACS">BACS</option>
                                <option value="Credit Card">Credit Card</option>
                                <option value="Direct">Direct</option>
                                <option value="Manual Bank Transfer">Manual Transfer</option>
                                <!--<option value="Manual Commission" [disabled]="true">Manual Commission</option>-->
                                <option value="TAPS">TAPS</option>
                            </select>
                        </mat-footer-cell>
                      </ng-container>

                    <ng-container matColumnDef="reference">
                      <mat-header-cell *matHeaderCellDef> Payment Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let payment"> {{ payment.paymentReference }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef>
                        <input matInput ngModel name="paymentReference" maxlength="50" [(ngModel)]="newPayment.paymentReference" placeholder="Reference">
                      </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="supplier">
                        <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                        <mat-cell *matCellDef="let payment">
                            <div *ngIf="payment.supplierID">
                                <i *ngIf="payment.supplierName">{{ payment.supplierName }}<br></i>
                                {{ payment.supplierRef }}
                            </div>

                            <div *ngIf="!payment.supplierID">
                                <select [(ngModel)]="supplierExisting" name="supplierTo" class="custom-select" style="width: 100%;"
                                (change)="changePaymentLink('link', payment, supplierExisting)">
                                    <ng-container *ngFor="let element of extElementData.data">
                                        <option *ngIf="element.elementType != 'supplement'" [ngValue]="element">
                                            {{ element.supplierName }} ({{ element.supplierReference }})
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef style="flex: 2;">
                            <select [(ngModel)]="selectedSupplier" (change)="payToSuppSel()" name="supplierTo" class="custom-select" style="width: 100%;">
                                <ng-container *ngFor="let element of extElementData.data">
                                    <option *ngIf="element.elementType != 'supplement'" [ngValue]="element">
                                        {{ element.supplierName }} ({{ element.supplierReference }})
                                    </option>
                                </ng-container>
                            </select>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentStatus">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let payment">
                          <span *ngIf="payment.paymentCategory != 'clientRefund'">{{ payment.paymentCategory | titlecase }}</span>
                          <span *ngIf="payment.paymentCategory == 'clientRefund'">Client Refund</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="options">
                        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
                        <mat-cell *matCellDef="let payment">
                            <div *ngIf="canMatchBanking && !matchCodeMismatch && viewFromMatch == 'payments'">
                                <button *ngIf="payment.matchStatus == 'TBD'" color="accent" mat-button matDialogClose
                                (click)="linkToStmntLine(payment)">
                                    <mat-icon>link</mat-icon> Link
                                </button>
                            </div>
                            <div *ngIf="canMatchBanking && matchCodeMismatch && viewFromMatch == 'payments'">
                                <button *ngIf="payment.matchStatus == 'TBD'" color="warn" mat-button
                                matTooltip="Wrong member" matTooltipClass="wrong-member-tip">
                                    <mat-icon>block</mat-icon>
                                </button>
                            </div>

                            <div *ngIf="!canMatchBanking">
                                <button class="matIcon" type="button" mat-button color="warn" (click)="changePaymentLink('unlink', payment, null)" [disableRipple]="true"
                                    *ngIf="payment.supplierID">
                                    <mat-icon style="color: #ce983a;" matTooltip="Unlink Supplier">link_off</mat-icon>
                                </button>

                                <button class="matIcon" type="button" mat-button color="warn" (click)="cancelPayment(payment)" [disableRipple]="true">
                                    <mat-icon matTooltip="Cancel Payment">undo</mat-icon>
                                </button>
                            </div> 
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button color="primary" mat-button (click)="addNewPayment()" [disableRipple]="true">
                                <mat-icon matTooltip="Add Payment">add</mat-icon>
                            </button>
                        </mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="paymentColumns"></mat-header-row>
                    <mat-row [ngClass]="row.highlight ? 'rowNotClick highlitRow' : 'rowNotClick'"
                    *matRowDef="let row; columns : paymentColumns;"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="paymentColumnsFooter"></mat-footer-row>
                </mat-table>
            </div>

            <div *ngIf="bookingAccess && ['wcManager', 'wcMember'].includes(userTypeOut) && bookSelOptionView == 0 && memberLive"
                style="padding-top: 5px; margin-bottom: -20px; height: 100%; display: flex; align-items: flex-end;">
                <button class="buttonToRight" mat-button [disableRipple]="true" style="width: calc(100% + 10px); margin-left: -5px; height: 78px; margin-bottom: 1px;"
                    (click)="updateBooking()">
                    <mat-icon style="margin-top: 0px;">done</mat-icon> Update Booking
                </button>
            </div>

            <div *ngIf="bookSelOptionView != 0" [ngStyle]="editToggle ? {'padding-top': '45px'} : {'padding-top': '0px'}"></div>
        </div>
    </div>

    <div *ngIf="bookingOperation == 'List'" class="chosenDiv" [ngStyle]="{'height': branchList.length > 1 ? 'calc(100% - 93px)' : 'calc(100% - 60px)'}">
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
            <div fxFlex="10">
                <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button [disabled]="firstRes == 1"
                    [disableRipple]="true" (click)="changePageNumber('previous')">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div fxFlex="80"
                style="display: flex; flex-direction: row; align-items: center; justify-content: space-around;">
                <div>
                    <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button *ngIf="!listSearchBox"
                        [disableRipple]="true" (click)="listSearchBox = true;"><b>More..</b>
                    </button>
                    <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button *ngIf="listSearchBox"
                        [disableRipple]="true" (click)="listSearchBox = false;"><b>More..</b>
                    </button>
                </div>
                <div>
                    <h1>Booking List</h1>
                </div>
                <div style="text-align: right;">
                    <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button [disableRipple]="true"
                        (click)="resetBookingSearch(); loadBookingList()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>
            <div fxFlex="10" style="text-align: right;">
                <button class="buttonToRight" style="margin-bottom: -5px;" mat-icon-button [disableRipple]="true"
                    [disabled]="bookingsData.data.length < pageSize" (click)="changePageNumber('next')">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="listSearchBox" class="listSearchBox" style="margin-bottom: 5px">
            <div>
                <mat-form-field class="dateType">
                    <mat-select ngModel name="dateType" #dateType="ngModel" placeholder="Date Type" (selectionChange)="dateTypeChange(dateType)" [(ngModel)]="bookingListRange.dateType">
                    <mat-option [value]="null"></mat-option>
                    <mat-option [value]="'balanceDueDate'">Balance Due Date</mat-option>
                    <mat-option [value]="'bookingDate'">Booking Date</mat-option>
                    <mat-option [value]="'deptDate'">Departure Date</mat-option>
                    <mat-option [value]="'returnDate'">Return Date</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="startDate">
                    <mat-label>Date From</mat-label>
                    <input ngModel required matInput name="dateFrom" [matDatepicker]="dateFrom" [disabled]="dateRangeBlock" (dateChange)="valiDate($event)" [(ngModel)]="bookingListRange.startDate">
                    <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                    <mat-datepicker #dateFrom></mat-datepicker>
                  </mat-form-field>
            </div>

            <div>
                <mat-form-field class="endDate">
                    <mat-label>Date To</mat-label>
                    <input ngModel required matInput name="dateTo" [matDatepicker]="dateTo" [disabled]="dateRangeBlock" (dateChange)="valiDate($event)" [(ngModel)]="bookingListRange.endDate">
                    <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                    <mat-datepicker #dateTo></mat-datepicker>
                  </mat-form-field>
            </div>
        </div>

        <div *ngIf="listSearchBox" class="listSearchBox">
            <div style="text-align: left;">
                <mat-form-field class="dialogMatFields">
                    <input matInput ngModel placeholder="Lead Passenger" name="leadPax" #leadPax="ngModel" [(ngModel)]="currentRequest.leadNameFilter" (change)="resetBookingSearch(); loadBookingList()">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="dialogMatFields">
                    <input matInput ngModel placeholder="Booking Group" name="bookingGroup" #bookingGroup="ngModel" [(ngModel)]="currentRequest.bookingGroup" (change)="resetBookingSearch(); loadBookingList()">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="orderColumn">
                    <mat-select ngModel [(ngModel)]="currentRequest.orderColumn" name="orderColumn"
                        #orderColumn="ngModel" placeholder="Order By" (selectionChange)="changeOrderBy($event.value)">
                        <mat-option [value]="'bookingDate'">Booking Date</mat-option>
                        <mat-option [value]="'bookingReference'">Booking Ref.</mat-option>
                        <mat-option [value]="'deptDate'">Departure Date</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="pageSize" style="width: 65px;">
                    <mat-select ngModel [(ngModel)]="pageSize" name="pageSizee" #pageSizee="ngModel"
                        placeholder="Page Size" (selectionChange)="changePageSize($event.value)">
                        <mat-option *ngFor="let size of pageSizeOptions" [value]="size">
                            {{size}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="orderHow">
                    <mat-select ngModel [(ngModel)]="currentRequest.orderHow" name="orderHow" #orderHow="ngModel"
                        (selectionChange)="changeOrderHow($event.value)" placeholder="Direction">
                        <mat-option [value]="'ASC'">Ascending</mat-option>
                        <mat-option [value]="'DESC'">Descending</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!listSearchBox" class="divider">
            <mat-divider></mat-divider>
        </div>

        <div *ngIf="bookingsData.data.length === 0" style="text-align: center;">
            <h3 *ngIf="pageLoaded">There are no bookings to show</h3>
            <h3 *ngIf="!pageLoaded">Please wait..</h3>
        </div>

        <div *ngIf="bookingsData.data.length > 0">
            <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="bookingsData">
                <ng-container matColumnDef="bookingReference">
                    <mat-header-cell *matHeaderCellDef>Sings Ref.</mat-header-cell>
                    <mat-cell *matCellDef="let booking"><a (click)="openExternalBooking(booking.bookingReference)"
                            style="font-family: Azeret Mono;">{{booking.bookingReference
                            | uppercase}}</a></mat-cell>
                </ng-container>

                <ng-container matColumnDef="externalReference">
                    <mat-header-cell *matHeaderCellDef>Your Reference</mat-header-cell>
                    <mat-cell *matCellDef="let booking"> {{ booking.extBookingSource }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="bookingDate">
                    <mat-header-cell *matHeaderCellDef>Booking Date</mat-header-cell>
                    <mat-cell *matCellDef="let booking"> {{ booking.bookingDate | date:'dd.MM.yyyy'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="departureDate">
                    <mat-header-cell *matHeaderCellDef>Dept. Date</mat-header-cell>
                    <mat-cell *matCellDef="let booking"> {{ booking.deptDate | date:'dd.MM.yyyy'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="customerName">
                    <mat-header-cell *matHeaderCellDef>Lead Pax</mat-header-cell>
                    <mat-cell *matCellDef="let booking"> {{ booking.leadName }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row class="rowNotClick" *matRowDef="let row; columns : displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>

    <div *ngIf="bookingOperation == 'Returns'" class="chosenDiv" [ngStyle]="{'height': branchList.length > 1 ? 'calc(100% - 93px)' : 'calc(100% - 60px)'}">
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
            <div fxFlex="100" style="display: flex; flex-direction: row; align-items: center; justify-content: space-around;">
                <div>
                    <label for="month" style="display: inline-block; padding-right: 15px;">Month:</label>
                    <input id="month" type="number" [(ngModel)]="monthlyMonth" min="1" max="12" class="customInputMonthly" (change)="getMonthlyReturns(true)">
                  </div>
                <div>
                    <h1>Monthly Returns</h1>
                </div>
                <div>
                    <label for="year" style="display: inline-block; padding-right: 15px;">Year:</label>
                    <input id="year" type="number" [(ngModel)]="monthlyYear" class="customInputMonthly" (change)="getMonthlyReturns(true)">
                  </div>
            </div>
        </div>

        <div *ngIf="!listSearchBox" class="divider">
            <mat-divider></mat-divider>
        </div>

        <div *ngIf="pageLoaded">
            <div *ngIf="monthlyReturnSummary?.tradeCode" style="border: 2px solid black; width: 80%; max-width: 1000px; margin: auto; margin-top: 20px; padding: 10px; padding-bottom: 5px;">
                <div style="margin-top: 10px; margin-bottom: 30px; text-align: center;">
                  <u><b>TRAVEL TRUST ASSOCIATION<br>
                  MEMBERS CERTIFICATE OF COMPLIANCE</b></u>
                </div>
    
                <div style="text-align: center;">
                  Status: 
                  <span *ngIf="monthlyReturnSummary.status != 'Approved'" style="color: #ce983a; font-weight: bold;">{{ monthlyReturnSummary.status }}</span>
                  <span *ngIf="monthlyReturnSummary.status == 'Approved'" style="color: rgb(129,199,132); font-weight: bold;">{{ monthlyReturnSummary.status }}</span>
                </div>
    
                <div style="margin-top: 20px; margin-bottom: 20px; text-align: center;">
                  We hereby certify the following during the period of <b>{{ monthlyPeriod }}</b>; the company has operated the Trust Account correctly and in accordance with
                    the Trust Deed and the Code of Conduct during this period; all monies received from customers of the business have been paid into the Trust Account; a
                    Stand Alone Safe Seat Plan Guarantee has been issued to every passenger; and that the subsequent premiums have been collected.
                </div>
    
                <div style="margin-top: 60px; margin-bottom: 20px;">
                  Company Name & Address
                </div>
    
                <div style="margin-bottom: 40px;">
                  <div *ngIf="motnhlyReturnBranch.branchName">{{ motnhlyReturnBranch.branchName }}</div>
                  <div *ngIf="motnhlyReturnBranch.address1">{{ motnhlyReturnBranch.address1 }}</div>
                  <div *ngIf="motnhlyReturnBranch.address2">{{ motnhlyReturnBranch.address2 }}</div>
                  <div *ngIf="motnhlyReturnBranch.city">{{ motnhlyReturnBranch.city }}</div>
                  <div *ngIf="motnhlyReturnBranch.county">{{ motnhlyReturnBranch.county }}</div>
                  <div *ngIf="motnhlyReturnBranch.postcode">{{ motnhlyReturnBranch.postcode }}</div>
                  <div *ngIf="motnhlyReturnBranch.country">{{ motnhlyReturnBranch.country }}</div>
                </div>
    
                <div *ngIf="monthlyReturnSummary.status == 'New'">Date: {{ currentDate | date: 'dd.MM.yyyy' }}</div>
    
                <div style="margin-top: 40px; margin-bottom: 20px;">
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of New Bookings made during the period:
                      <div *ngIf="monthlyReturnSummary.status == 'New'">
                          <span style="color: red; margin-right: 10px;" *ngIf="monthlyReturnSummary.bookingNo != monthlyReportStats.bookings">
                              <span *ngIf="monthlyReturnSummary.bookingNo - monthlyReportStats.bookings > 0">
                                  +</span>{{ monthlyReturnSummary.bookingNo - monthlyReportStats.bookings }}
                              </span>
                          <input id="bookingNo" type="number" min="0" class="customInputMonthly" [(ngModel)]="monthlyReturnSummary.bookingNo">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New'">{{ monthlyReturnSummary.bookingNo }}</div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of Passengers for All bookings made during the period:
                      <div *ngIf="monthlyReturnSummary.status == 'New'">
                          <span style="color: red; margin-right: 10px;" *ngIf="monthlyReturnSummary.paxNo != monthlyReportStats.paxNo">
                          <span *ngIf="monthlyReturnSummary.paxNo - monthlyReportStats.paxNo > 0">
                              +</span>{{ monthlyReturnSummary.paxNo - monthlyReportStats.paxNo }}
                          </span>
                          <input id="paxNo" type="number" min="0" class="customInputMonthly" [(ngModel)]="monthlyReturnSummary.paxNo">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New'">{{ monthlyReturnSummary.paxNo }}</div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of Passengers for Flight Based Package bookings made under your T-ATOL:
                      <div *ngIf="monthlyReturnSummary.status == 'New'">
                          <span style="color: red; margin-right: 10px;" *ngIf="monthlyReturnSummary.tAtolPaxPkg != monthlyReportStats.paxNoTAtolPackage">
                          <span *ngIf="monthlyReturnSummary.paxNoTAtolPackage - monthlyReportStats.tAtolPaxPkg > 0">
                              +</span>{{ monthlyReturnSummary.paxNoTAtolPackage - monthlyReportStats.tAtolPaxPkg }}
                          </span>
                          <input id="tAtolPaxPkg" type="number" min="0" class="customInputMonthly" [(ngModel)]="monthlyReturnSummary.tAtolPaxPkg"
                                  [disabled]="motnhlyReturnBranch.isTAtol == 'no'">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New'">{{ monthlyReturnSummary.tAtolPaxPkg }}</div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of Passengers for Flight Only bookings made under your T-ATOL:
                      <div *ngIf="monthlyReturnSummary.status == 'New'">
                          <span style="color: red; margin-right: 10px;" *ngIf="monthlyReturnSummary.tAtolPaxFlOnly != monthlyReportStats.paxNoTAtolFlOnly">
                          <span *ngIf="monthlyReturnSummary.paxNoTAtolFlOnly - monthlyReportStats.tAtolPaxFlOnly > 0">
                              +</span>{{ monthlyReturnSummary.paxNoTAtolFlOnly - monthlyReportStats.tAtolPaxFlOnly }}
                          </span>
                          <input id="tAtolPaxFlOnly" type="number" min="0" class="customInputMonthly" [(ngModel)]="monthlyReturnSummary.tAtolPaxFlOnly"
                                  [disabled]="motnhlyReturnBranch.isTAtol == 'no'">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New'">{{ monthlyReturnSummary.tAtolPaxFlOnly }}</div>
                    </div>
                  </div>
    
                <div style="margin-top: 30px; margin-bottom: 10px;" *ngIf="monthlyReturnSummary.status != 'New'">
                  <span *ngIf="monthlyReturnSummary.status != 'New'">
                    Signed By: {{ monthlyReturnSummary.signeeName }}<br>
                    Date: {{ monthlyReturnSummary.signedTS | date: 'dd.MM.yyyy hh:mm a' }}
                  </span>
    
                  <span *ngIf="monthlyReturnSummary.status == 'Approved'">
                    <br><br>Approved By: {{ monthlyReturnSummary.approvedName }}<br>
                    Date: {{ monthlyReturnSummary.approvedTS | date: 'dd.MM.yyyy hh:mm a' }}
                  </span>
                </div>
    
                <div style="padding: 2px; border-bottom: 2px solid black; border-left: 2px solid black; border-right: 2px solid black;" *ngIf="monthlyReturnSummary.status == 'New' && memberLive">
                  <div style="background: black; margin: -2px; padding: 2px; color: white;">Electronic Signature</div>
    
                  <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticate(authForm)">
                    <div style="margin-bottom: 20px; margin-top: 20px; display: flex; flex-direction: row; justify-content: space-around; align-items: center;">
                      <div>Email:
                        <input id="email" required class="customInputMonthly" ngModel name="email" #email="ngModel">
                      </div>
                      <div>Password:
                        <input id="password" type="password" required class="customInputMonthly" ngModel name="password" #password="ngModel">
                      </div>
                      <div>
                        <button mat-stroked-button color="primary" [disableRipple]="true">Submit</button>
                      </div>
                    </div>
                  </form>
    
                  <div style="margin-bottom: 10px;">
                    <b>Note: I have read the Compliance Certificate and have entered the correct information on to the certificate.</b>
                  </div>
    
                  <div style="margin-bottom: 10px;">
                    <b>Please enter your credentials once this has been submitted you will not be allowed to make any modifications.<br>
                      If you have made an error please email <a href="mailto:Operations@thetravelnetworkgroup.co.uk">Operations@thetravelnetworkgroup.co.uk</a></b>
                  </div>
                </div>
              </div>
    
              <div *ngIf="!monthlyReturnSummary?.tradeCode" style="text-align: center;">
                <h3>Monthly return for this period is not available</h3>
              </div>
    
              <div *ngIf="monthlyReturnSummary?.tradeCode" (click)="expansionMonthlyBreakdown = !expansionMonthlyBreakdown;" style="padding: 15px; text-align: center;">
                <span *ngIf="!expansionMonthlyBreakdown"><mat-icon>expand_more</mat-icon>Monthly Return Breakdown<mat-icon>expand_more</mat-icon></span>
                <span *ngIf="expansionMonthlyBreakdown"><mat-icon>expand_less</mat-icon>Monthly Return Breakdown<mat-icon>expand_less</mat-icon></span>
              </div>
    
              <div [style.display]="(monthlyReturnSummary?.tradeCode && expansionMonthlyBreakdown) ? 'inline' : 'none'">
                <div style="display: flex; justify-content: center;">
                    <div>
                        <mat-paginator #paginatorReturns [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                    </div>
                    <div *ngIf="monthlyReturnData.data.length > 0">
                        <button mat-icon-button style="transform: scale(0.7); margin-left: 25px; margin-top: 5.5px;" (click)="exportMonthlyBreakdown()">
                            <svg-icon src="assets/icons/xlsx-file-format-extension.svg" [svgStyle]="{'width.px':40,'height.px':40,'vertical-align':'middle','margin-top.px':'0','fill':'rgb(0, 0, 0)'}"></svg-icon>
                        </button>
                    </div>
                </div>

                <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="monthlyReturnData">
                  <ng-container matColumnDef="bookingReferenceMonthly">
                    <mat-header-cell *matHeaderCellDef>
                        <span class="clickBkgRef" (click)="showExtRefSwitch()" [matTooltip]="!showExtReference ? 'Switch to your references' : 'Switch to Sings references'">
                          Booking Reference
                        </span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let data">
                        <a (click)="openExternalBooking(data.bookingReference)" style="font-family: Azeret Mono;">
                          <span *ngIf="!showExtReference">{{ data.bookingReference | uppercase }}</span>
                          <span *ngIf="showExtReference">{{ data.extBookingSource }}</span>
                          <mat-icon style="margin-left: 10px;" *ngIf="data.oldBooking" matTooltip="Amended booking">history</mat-icon>
                        </a>
                      </mat-cell>
                    <mat-footer-cell *matFooterCellDef><b>Total</b></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="leadName">
                    <mat-header-cell *matHeaderCellDef> Lead Name </mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.leadName }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="paxNo">
                    <mat-header-cell *matHeaderCellDef> Pax. No. </mat-header-cell>
                    <mat-cell *matCellDef="let data">
                          <span *ngIf="monthlyReturnSummary.status != 'New'">{{ data.paxNoSbmt }}</span>
                          <span *ngIf="monthlyReturnSummary.status == 'New'">
                              <input id="bookingNo" type="number" [(ngModel)]="data.paxNoSbmt" min="0" (change)="recalcMonthlyReport()" class="customInputMonthly monthly-mini-input">
                              <span *ngIf="data.paxNoSbmt != data.paxNo" style="color: red; margin-left: 15px;">{{ data.paxNo }}</span>
                          </span>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{ monthlyReportStats.paxNo }} </mat-footer-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="paxNoTatolPackage">
                      <mat-header-cell *matHeaderCellDef> T-ATOL (Pkg) </mat-header-cell>
                      <mat-cell *matCellDef="let data">
                          <span *ngIf="monthlyReturnSummary.status != 'New'">{{ data.tAtolPaxPkgSbmt }}</span>
                          <span *ngIf="monthlyReturnSummary.status == 'New'">
                            <input id="bookingNo" type="number" [(ngModel)]="data.tAtolPaxPkgSbmt" min="0" (change)="recalcMonthlyReport()" class="customInputMonthly monthly-mini-input" [disabled]="motnhlyReturnBranch.isTAtol == 'no'">
                            <span *ngIf="data.tAtolPaxPkgSbmt != data.tAtolPaxPkg" style="color: red; margin-left: 15px;">{{ data.tAtolPaxPkg }}</span>
                          </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef>{{ monthlyReportStats.paxNoTAtolPackage }} </mat-footer-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="paxNoTatolFlOnly">
                      <mat-header-cell *matHeaderCellDef> T-ATOL (FO) </mat-header-cell>
                      <mat-cell *matCellDef="let data">
                          <span *ngIf="monthlyReturnSummary.status != 'New'">{{ data.tAtolPaxFlOnlySbmt }}</span>
                          <span *ngIf="monthlyReturnSummary.status == 'New'">
                              <input id="bookingNo" type="number" [(ngModel)]="data.tAtolPaxFlOnlySbmt" min="0" (change)="recalcMonthlyReport()" class="customInputMonthly monthly-mini-input" [disabled]="motnhlyReturnBranch.isTAtol == 'no'">
                              <span *ngIf="data.tAtolPaxFlOnlySbmt != data.tAtolPaxFlOnly" style="color: red; margin-left: 15px;">{{ data.tAtolPaxFlOnly }}</span>
                          </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef>{{ monthlyReportStats.paxNoTAtolFlOnly }} </mat-footer-cell>
                  </ng-container>
      
                    <ng-container matColumnDef="bookingDate">
                      <mat-header-cell *matHeaderCellDef> Booking Date </mat-header-cell>
                      <mat-cell *matCellDef="let data">{{ data.bookingDate | date : "dd.MM.y" }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="bookedTS">
                      <mat-header-cell *matHeaderCellDef> Booked On </mat-header-cell>
                      <mat-cell *matCellDef="let data">{{ data.bookedTS | date : "dd.MM.y hh:mm a" }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef>{{ monthlyReportStats.bookings }}</mat-footer-cell>
                    </ng-container>
      
                  <mat-header-row *matHeaderRowDef="monthlyRetColumns"></mat-header-row>
                  <mat-row class="rowNotClick" *matRowDef="let row; columns : monthlyRetColumns;"></mat-row>
                  <mat-footer-row class="rowNotClick" *matFooterRowDef="monthlyRetColumns"></mat-footer-row>
                </mat-table>   
              </div>
        </div>

        <div *ngIf="!pageLoaded" style="text-align: center;">
            <h3>Please wait..</h3>
        </div>
    </div>

    <div *ngIf="bookingOperation == 'Upload'" class="chosenDiv" [ngStyle]="{'height': branchList.length > 1 ? 'calc(100% - 93px)' : 'calc(100% - 60px)'}">
        <h1 class="h1headers">Upload Data</h1>
        <div class="divider">
            <mat-divider></mat-divider>
        </div>

        <div class="uploadDescription">
            <div>Your booking data can be uploaded into our system using our CSV templates</div>
            <div>There are three templates, each designed to add different types of data:</div>
            <div><b>Bookings</b> - this template should be used first as it sets up the necessary records for transactions</div>
            <div><b>Payments In</b> and <b>Payments Out</b> - these templates should be used after the bookings data has been uploaded, as they require existing booking records to successfully record transactions</div>
            <div>Please ensure that you read the instructions provided with each template to understand how to use them effectively</div>
        </div>

        <div class="divider">
            <mat-divider></mat-divider>
        </div>

        <div class="uploadMain">
            <div class="uploadColumn">
                <div><h1>Bookings</h1></div>
                <div style="margin-bottom: 14px;">
                    <a href="https://docs.google.com/document/d/1Ef1UTSaY1O98eKFk9K7oJ6qIwlgyV3poDww5SJL3jjE/edit?usp=sharing" target="_blank" *ngIf="!borderauxCodes.includes(currentRequest.tradeCode)">
                        Instructions</a>
                    <span *ngIf="borderauxCodes.includes(currentRequest.tradeCode)">Instructions TBD</span>
                </div>
                <div style="margin-bottom: 14px;" *ngIf="borderauxCodes.includes(currentRequest.tradeCode)">
                    <button color="accent" mat-raised-button (click)="getS3template('Bordereau.xlsx')" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">file_download</mat-icon>Get Template
                    </button>
                </div>
                <div style="margin-bottom: 14px;" *ngIf="!borderauxCodes.includes(currentRequest.tradeCode)">
                    <button color="accent" mat-raised-button (click)="getCSVtemplate('bookingUploadTemplate.csv')" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">file_download</mat-icon>Get Template
                    </button>
                </div>
                <div *ngIf="!borderauxCodes.includes(currentRequest.tradeCode)">
                    <button mat-raised-button type="button" (click)="csvInput1.click()" color="accent" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">fact_check</mat-icon>Validate File
                    </button>

                    <input #csvInput1 hidden="true" type="file" onclick="this.value=null"
                    (change)="validateBookingFile($event)" accept=".csv" multiple="false" />
                </div>
                <div *ngIf="borderauxCodes.includes(currentRequest.tradeCode)">
                    <button mat-raised-button type="button" (click)="csvInput1.click()" color="accent" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">fact_check</mat-icon>Upload File
                    </button>

                    <input #csvInput1 hidden="true" type="file" onclick="this.value=null"
                    (change)="uploadBorderauFile($event)" accept=".xlsx" multiple="false" />
                </div>
            </div>

            <div class="uploadColumn">
                <div><h1>Payments In</h1></div>
                <div style="margin-bottom: 14px;">
                    <a href="https://docs.google.com/document/d/1IfABbPKg38h0RNNlQVZNeHzeADOQS2HZX4prO6p4oOw/edit?usp=sharing" target="_blank">Instructions</a>
                </div>
                <div style="margin-bottom: 14px;">
                    <button color="accent" mat-raised-button (click)="getCSVtemplate('paymentUploadTemplate.csv')" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">file_download</mat-icon>Get Template
                    </button>
                </div>
                <div>
                    <button mat-raised-button type="button" (click)="csvInput2.click()" color="accent" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">fact_check</mat-icon>Validate File
                    </button>

                    <input #csvInput2 hidden="true" type="file" onclick="this.value=null"
                    (change)="validatePaymentFile($event, 'paymentsIn')" accept=".csv" multiple="false" />
                </div>
            </div>

            <div class="uploadColumn">
                <div><h1>Payments Out</h1></div>
                <div style="margin-bottom: 14px;">
                    <a href="https://docs.google.com/document/d/1FMPjdNkFvLONNoQNenpaXxlfJ1fYeaarsax-NVghxkU/edit?usp=sharing" target="_blank">Instructions</a>
                </div>
                <div style="margin-bottom: 14px;">
                    <button color="accent" mat-raised-button (click)="getCSVtemplate('paymentUploadTemplate.csv')" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">file_download</mat-icon>Get Template
                    </button>
                </div>
                <div>
                    <button mat-raised-button type="button" (click)="csvInput3.click()" color="accent" style="width: 155px;" [disableRipple]="true">
                        <mat-icon class="iconInButton">fact_check</mat-icon>Validate File
                    </button>

                    <input #csvInput3 hidden="true" type="file" onclick="this.value=null"
                    (change)="validatePaymentFile($event, 'paymentsOut')" accept=".csv" multiple="false" />
                </div>
            </div>
        </div>

        <div class="divider">
            <mat-divider></mat-divider>
        </div>

        <div class="uploadResults" *ngIf="uploadDetails.validated && uploadMessages.length == 0" style="text-align: center;">
            <div class="upResRow"><h1 style="margin: 0px;">File has been validated</h1></div>
            <div class="upResRow">
                <button color="primary" mat-raised-button (click)="uploadPaymentFile()" style="width: 166px;" [disableRipple]="true">Upload Payments</button>
            </div>
        </div>

        <div class="uploadResults" *ngIf="!uploadDetails.validated && uploadMessages.length > 0">
            <div class="upResRow" style="text-align: center;"><h1 style="margin: 0px;">Upload errors</h1></div>
            <div class="upResRow" *ngFor="let message of uploadMessages">
                {{ message }}
            </div>
        </div>

        <div class="uploadResults" *ngIf="uploadDetails.uploaded">
            <div class="upResRow" style="text-align: center;"><h1 style="margin: 0px;">Payments have been uploaded</h1></div>
        </div>

        <div class="uploadResults" *ngIf="failedUpload" style="text-align: center;">
            <div class="upResRow"><h1 style="margin: 0px;">Preview failed to generate</h1></div>
            <div class="upResRow">
                <button mat-raised-button type="button" [disableRipple]="true" color="primary" style="width: 155px;"
                    (click)="uploadBookingData(bookingCsvDataUpload, 'csv')">Upload Anyway</button>
            </div>
        </div>
</div>

<ng-template #addSupplDialog>
    <h3 style="text-align: center;">
        <span *ngIf="isSupplementStr == 'no'">New Supplier Details</span>
        <span *ngIf="isSupplementStr == 'yes'">New Supplement Details</span>
    </h3>
    <div>
        <form ngNativeValidate #newSupplForm="ngForm" (ngSubmit)="addSupplier(newSupplForm, 'ui')">            
            <div>
                <mat-form-field class="dialogMatFields" *ngIf="isSupplementStr == 'no'">
                    <mat-select ngModel name="supplier" #supplier="ngModel" required placeholder="Supplier Name" panelClass="supplierList" (selectionChange)="changeSupplier(supplier.value)">
                        <mat-select-trigger *ngIf="chosenSupplier">
                            {{chosenSupplier.supplierNameM}}
                            <mat-icon *ngIf="chosenSupplier.isUnderSFC" matTooltip="Under SFC" style="margin-left: 5px; transform: scale(0.7); margin-top: -4px;" class="suppOptionIcon">security</mat-icon>
                            <mat-icon *ngIf="chosenSupplier.underSafi === 'yes'" matTooltip="Under SAFI" style="margin-left: 5px; transform: scale(0.7); margin-top: -4px;" class="suppOptionIcon">airlines</mat-icon>
                        </mat-select-trigger>
                      
                        <mat-form-field appearance="fill" class="filterSelectList sticky-search-container">
                            <input placeholder="Search.." matInput #filterInput name="supplierFilter" [(ngModel)]="filterString" (keyup)="filterSuppliers()">
                        </mat-form-field>

                      <mat-option value="newSupplDialog" (click)="openRequestDialog()">== Request New Supplier ==</mat-option>
                      <mat-option *ngFor="let supplier of supplierFilteredData" [value]="supplier" (click)="filterString = ''; filterSuppliers();">
                        {{supplier.supplierNameM}}
                        <mat-icon *ngIf="supplier.isUnderSFC" matTooltip="Under SFC" style="margin-left: 5px; transform: scale(0.7); margin-right: 0px;">security</mat-icon>
                        <mat-icon *ngIf="supplier.underSafi === 'yes'" matTooltip="Under SAFI" style="margin-left: 5px; transform: scale(0.7);">airlines</mat-icon>
                      </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="dialogMatFields" *ngIf="isSupplementStr == 'yes'">
                    <mat-select ngModel name="supplier" #supplier="ngModel" required placeholder="Supplement Name" panelClass="supplierList">
                        <ng-container *ngFor="let supplement of supplementList">
                            <mat-option *ngIf="['APC - ATOL Protection Contribution', 'Booking Fee', 'SAFI', 'SFC', 'ZZ - ATOL'].includes(supplement.supName)" [value]="supplement"
                            [disabled]="(supplement.supName == 'SAFI' && (flightsOnly.length == 0 || safiExists)) || (supplement.supName == 'SFC' && (!isUnderSFC() || sfcExists)) || supplement.supName == 'APC - ATOL Protection Contribution'"
                            [matTooltip]="supplement.supName === 'SAFI' && flightsOnly.length === 0 ? 'There are no \'Flight Only\' elements with a SAFI supplier in the booking' :
                              supplement.supName === 'SFC' && !isUnderSFC() ? 'There are no suppliers covered under SFC in the booking' :
                              supplement.supName == 'APC - ATOL Protection Contribution' ? 'You can create an ATOL by changing the ATOL Protected value in the Booking Details tab' : null">
                            {{supplement.supName}}
                          </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="dialogMatFields" *ngIf="isSupplementStr == 'no'">
                    <input matInput ngModel name="supplierReference" minlength="3" maxlength="50" [required]="isSupplementStr == 'no'"
                        placeholder="Supplier Booking Reference">
                </mat-form-field>
            </div>

            <div style="margin-top: -15px;">
                <button class="buttonToRight" mat-button [disableRipple]="true" [disabled]="newSupplForm.invalid"
                    type="submit" style="width: calc(100% + 5px); margin-left: -5px; height: 50px; margin-bottom: 1px;"
                    matDialogClose>
                    <mat-icon>done</mat-icon> Create
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #passengerDialog>
    <div style="width: 100%; height: 100%;">
        <h1 style="padding-bottom: 14px; border-bottom: 1px solid rgb(219, 219, 219);">Add Passenger</h1>

        <mat-radio-group style="display: flex; flex-direction: row; justify-content: space-evenly; margin-bottom: 20px; margin-top: 14px;" color="primary" [(ngModel)]="newPaxRadioValue" (change)="radioChangeCustomer($event.value)" [disableRipple]="true">
            <mat-radio-button value="new" [checked]="newPaxRadioValue === 'new'" [disableRipple]="true">New Customer</mat-radio-button>
            <mat-radio-button style="margin-left: 1%;" value="existing" [checked]="newPaxRadioValue === 'existing'" [disableRipple]="true">Existing Customer</mat-radio-button>
            <mat-radio-button style="margin-left: 1%;" value="ghost" [checked]="newPaxRadioValue === 'ghost'" [disableRipple]="true">Unknown Passenger</mat-radio-button>
        </mat-radio-group>

        <form ngNativeValidate #getPassengerForm="ngForm" (ngSubmit)="getPassenger(getPassengerForm)" *ngIf="newPaxRadioValue === 'existing'">
            <h4 style="border-bottom: 0.5px solid rgb(219, 219, 219);">
                <i>Fill in the surname or email below and click 'Search'. If the customer exists, you will be able to select them from the list</i>
            </h4>
            <div style="display: flex; justify-content: space-evenly; align-items: baseline; margin-top: 10px;">
                <mat-form-field>
                <input matInput ngModel placeholder="Surname" maxlength="50" name="lastName" #lastName="ngModel">
                </mat-form-field>

                <mat-form-field>
                <input matInput ngModel placeholder="Email Address" minlength="5" type="email" name="email" #email="ngModel" maxlength="100">
                </mat-form-field>

                <button class="buttonClass" mat-raised-button color="primary" type="submit" id="searchPax" [disableRipple]="true">Search</button>
            </div>
        </form>

        <mat-table class="tableWithinExpansV2" multiTemplateDataRows *ngIf="newPaxRadioValue === 'existing' && customerData.data.length > 0" [dataSource]="customerData">
            <!-- Username Column -->
            <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
            <mat-cell *matCellDef="let customer"> {{ customer.title }} {{ customer.firstName }} {{ customer.middleName }} {{customer.lastName }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="telephone">
            <mat-header-cell *matHeaderCellDef> Mobile Number </mat-header-cell>
            <mat-cell *matCellDef="let customer"> {{ customer.telNo }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
            <mat-cell *matCellDef="let customer"> {{ customer.email }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="addElement">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let customer">
                <button type="button" mat-button color="primary" class="addElementButton" style="margin: auto; width: 125px;"
                (click)="addPassenger(customer, 'table')" *ngIf="!customer.disabled" [disableRipple]="true">
                    <mat-icon class="iconInButton">add</mat-icon>Add Passenger
                </button>
            </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="passengerNewColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns : passengerNewColumns;" class="trueRow"></mat-row>
        </mat-table>

        <form ngNativeValidate #passengerForm="ngForm" (ngSubmit)="addPassenger(passengerForm, 'form')" *ngIf="newPaxRadioValue === 'new'">
            <h4><i>Fill in the fields below to create a new customer and add them to your booking</i></h4>
            
            <h3>Customer Name</h3>
            <div style="display: flex; justify-content: space-evenly;">
                <mat-form-field class="passengerExpandField">
                <mat-select ngModel name="title" #title="ngModel" placeholder="Title">
                    <mat-option *ngFor="let title of titles" [value]="title['value']">
                    {{title['value']}}
                    </mat-option>
                </mat-select>
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                <input matInput ngModel required placeholder="First Name" maxlength="50" name="firstName" #firstName="ngModel">
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                <input matInput ngModel required placeholder="Last Name" maxlength="50" name="lastName" #lastName="ngModel">
                </mat-form-field>
            </div>

            <h3>Contact Details</h3>
            <div style="display: flex; justify-content: space-evenly;">
                <mat-form-field class="passengerExpandField">
                <input placeholder="Mobile Number" matInput ngModel name="telNo" #telNo="ngModel" type="number" maxlength="15">
                </mat-form-field>

                <mat-form-field class="customerLastEdit">
                <input matInput ngModel placeholder="Email Address" minlength="5" maxlength="100" name="email" #email="ngModel"
                        pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                </mat-form-field>
            </div>

            <h3>Address Details</h3>
            <div style="display: flex; justify-content: space-evenly;">
                <mat-form-field class="passengerExpandField">
                <input placeholder="Post Code" matInput ngModel name="postcode"
                #postcode="ngModel" maxlength="10">
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                    <input placeholder="County" matInput ngModel name="county" #county="ngModel" maxlength="50">
                </mat-form-field>
            </div>

            <button class="buttonClass" mat-raised-button color="primary" type="submit" [disabled]="passengerForm.invalid" [disableRipple]="true"
                    style="width: 100%; margin: 0; margin-top: 15px; border-radius: 0px;">
                Add
            </button>
        </form>

        <form ngNativeValidate #ghostPaxForm="ngForm" (ngSubmit)="addGhostPassengers(ghostPaxForm)" *ngIf="newPaxRadioValue === 'ghost'">
            <h4 style="border-bottom: 0.5px solid rgb(219, 219, 219);">
                <i>
                    Unknown passengers are like any other passengers but without any details. They are useful when passenger details are not known at the time<br><br>
                    You can only add a maximum of 50 unknown passengers in one go<br>
                    Please await confirmation of the passengers being added, then click add again to enter the next amount
                </i>
            </h4>
            <div style="display: flex; justify-content: space-evenly; align-items: baseline; margin-top: 10px;">
                <mat-form-field>
                <input matInput ngModel required placeholder="No of unknown passengers" type="number" min="1" name="ghostPaxNo" #ghostPaxNo="ngModel">
                </mat-form-field>

                <button class="buttonClass" mat-raised-button color="primary" type="submit" id="addGhostPax" [disableRipple]="true" [disabled]="!pageLoaded">Add</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #editPaxDialog>
    <div style="width: 100%; height: 100%;">
        <h1 style="padding-bottom: 14px; border-bottom: 1px solid rgb(219, 219, 219);">Update Passenger</h1>

        <form ngNativeValidate #passengerForm="ngForm" (ngSubmit)="editPassenger(passengerForm, paxToEdit)">
            <h4><i>Note: Passengers are linked to CRM data. Any changes to passengers will update the CRM</i></h4>
            
            <h3>Customer Name</h3>
            <div style="display: flex; justify-content: space-evenly;">
                <mat-form-field class="passengerExpandField">
                <mat-select ngModel name="title" #title="ngModel" placeholder="Title" [(ngModel)]="paxToEdit.title">
                    <mat-option *ngFor="let title of titles" [value]="title['value']">
                    {{title['value']}}
                    </mat-option>
                </mat-select>
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                <input matInput ngModel required placeholder="First Name" maxlength="50" name="firstName" #firstName="ngModel" [(ngModel)]="paxToEdit.firstName">
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                <input matInput ngModel required placeholder="Last Name" maxlength="50" name="lastName" #lastName="ngModel" [(ngModel)]="paxToEdit.lastName">
                </mat-form-field>
            </div>

            <h3>Contact Details</h3>
            <div style="display: flex; justify-content: space-evenly;">
                <mat-form-field class="passengerExpandField">
                <input placeholder="Mobile Number" matInput ngModel name="telNo" #telNo="ngModel" type="number" maxlength="15" [(ngModel)]="paxToEdit.telNo">
                </mat-form-field>

                <mat-form-field class="customerLastEdit">
                <input matInput ngModel placeholder="Email Address" minlength="5" maxlength="100" name="email" #email="ngModel" [(ngModel)]="paxToEdit.email"
                        pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                </mat-form-field>
            </div>

            <h3>Address Details</h3>
            <div style="display: flex; justify-content: space-evenly;">
                <mat-form-field class="passengerExpandField">
                <input placeholder="Post Code" matInput ngModel name="postcode" [(ngModel)]="paxToEdit.postcode"
                #postcode="ngModel" maxlength="10">
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                    <input placeholder="County" matInput ngModel name="county" #county="ngModel" maxlength="50" [(ngModel)]="paxToEdit.county">
                </mat-form-field>
            </div>

            <button class="buttonClass" mat-raised-button color="primary" type="submit" [disabled]="passengerForm.invalid" [disableRipple]="true"
                    style="width: 100%; margin: 0; margin-top: 15px; border-radius: 0px;">
                Update
            </button>
        </form>
    </div>
</ng-template>

<ng-template #requestDialog>
    <app-request-supplier [requestMode]="'supplier'" [requestSource]="'Booking System'" [requestCompany]="currentRequest.company"
    [requestOperation]="currentRequest.operation" [requestTradeCode]="currentRequest.tradeCode"></app-request-supplier>
  </ng-template>

<ng-template #masterNameDialog let-data>
    <h3 style="text-align: center;">
        Supplier Name Suggestions
    </h3>
    <div>
        <button class="buttonToRight" mat-button [disableRipple]="true" type="button" matDialogClose (click)="openRequestDialog()"
                style="width: calc(100% + 5px); margin-left: -5px; height: 50px; margin-bottom: 1px; border-bottom: 1px solid #ccc;">
            Can't find your supplier?
        </button>
    </div>

    <div *ngIf="data.masterData.length > 0" style="text-align: center; display: flex; flex-direction: row; justify-content: space-around;">
        <div *ngFor="let master of data.masterData" style="margin-left: 10px; margin-right: 10px;">
            <div><h4>{{ master.suggestion }}</h4></div>
            <div><h5><i>Similairty: {{ master.similarity | percent }}</i></h5></div>
            <div style="margin-top: 10px;">
                <button class="buttonToRight" mat-button [disableRipple]="true" (click)="updateElementPseudoName(data.element, master.suggestion)"
                    type="submit" style="width: calc(100% + 5px); margin-left: -5px; height: 50px; margin-bottom: 1px;" matDialogClose>
                    <mat-icon>done</mat-icon>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #groupedBookingsDialog let-data>
    <mat-dialog-content>
      <div style="display: flex; flex-direction: column; align-items: center; margin: 10px;">
        <div>
          <div>
            <h2>{{ extBookingData.bookingGroup}}</h2>
          </div>
        </div>
        
        <div class="groupTable" style="width: 100%;">
          <div class="groupHeader" style="display: flex; justify-content: space-around; flex-direction: row; margin: 10px; font-weight: bold;">
            <div style="width: 200px;">Booking Reference</div>
            <div style="width: 250px;">Lead Name</div>
            <div style="width: 150px;">Departure Date</div>
            <div style="width: 150px;">Return Date</div>
          </div>
          <div class="groupRow" style="display: flex; justify-content: space-around; flex-direction: row; margin: 10px;" *ngFor="let bkgData of data">
            <div style="width: 200px;">
                <a matDialogClose (click)="loadGroupBooking(bkgData.bookingReference)" style="font-family: Azeret Mono;">{{bkgData.bookingReference | uppercase}}</a>
            </div>
            <div style="width: 250px;"><span *ngIf="bkgData.leadName">{{bkgData.leadName}}</span><span *ngIf="!bkgData.leadName">N/A</span></div>
            <div style="width: 150px;">{{bkgData.deptDate | date:'dd.MM.yyyy' }}</div>
            <div style="width: 150px;">{{bkgData.returnDate | date:'dd.MM.yyyy'}}</div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </ng-template>

<ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
        <p style="white-space: pre-line; text-align: center;">{{errorMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>

    <div *ngIf="successMessage != ''" class="successMessage">
        <p>{{successMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>
</ng-template>