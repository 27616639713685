import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrfService {
  constructor(private http: HttpClient) { }

  // PRFS BELOW

  async getPrf(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'getPrf?' + body).toPromise();
    return output;
  }

  async getAllPrfs(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'getAllPrfs?' + body).toPromise();
    return output;
  }

  async createPrf(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'createPrf', body, { headers }).toPromise();
    return output;
  }

  async updatePrf(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'updatePrf', body, { headers }).toPromise();
    return output;
  }

  async signPrf(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'signPrf', body, { headers }).toPromise();
    return output;
  }

  async deletePrf(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'deletePrf', body, { headers }).toPromise();
    return output;
  }

  // AWS BELOW

  async uploadS3prfDoc(request: any, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const output = await this.http.post(environment.apiURL + 'uploadS3prfDoc?company=' + request.company + '&operation=' +
    request.operation + '&tradeCode=' + request.tradeCode + '&prfNumber=' + request.prfNumber + '&token=' + request.token, formData).toPromise();
    return output;
  }

  async uploadS3prfLargeDoc(request: any, file: any): Promise<any> {
    const chunkSize = 5242880; // 5MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    let upload_id = null; 
    let parts = [];

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const chunk = file.slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize);

        // Make a request to get the presigned URL and upload_id if it's the first chunk
        const queryParams = `company=${request.company}&operation=${request.operation}&tradeCode=${request.tradeCode}&prfNumber=${request.prfNumber}&part_number=${chunkIndex + 1}&token=${request.token}` +
                            `&fileName=${file.name}&fileType=${file.type}` +  // Pass fileName and fileType
                            (upload_id ? `&upload_id=${upload_id}` : '');

        // Request only the presigned URL without sending the file data
        const uploadResponse: any = await this.http.post(environment.apiURL + 'uploadS3prfLargeDoc?' + queryParams, {}).toPromise();

        // Store the upload_id returned from the backend after the first chunk
        if (upload_id == null && uploadResponse.upload_id) {
            upload_id = uploadResponse.upload_id;
        }

        // Upload the chunk to the presigned URL
        const presignedUrl = uploadResponse.presigned_url;
        const response = await fetch(presignedUrl, {
            method: 'PUT',
            body: chunk,
            headers: {
                'Content-Type': file.type
            }
        });

        if (!response.ok) {
            console.log(response)
            console.error(`Failed to upload chunk ${chunkIndex + 1}`);
            return; 
        }

        // Retrieve ETag and store it in parts array
        const etag = response.headers.get('ETag');
        if (etag) {
            parts.push({
                etag: etag,
                part_number: chunkIndex + 1
            });
        } else {
            console.error(`Missing ETag in response for chunk ${chunkIndex + 1}`);
        }
    }

    // Finalize the multipart upload once all chunks are uploaded
    await this.completeMultipartUpload(file.name, upload_id, parts, request);
  }

  private async completeMultipartUpload(fileName: string, upload_id: any, parts: any, request: any) {
    const headers = { 'content-type': 'application/json' };
    const payload = {
      company: request.company,
      operation: request.operation,
      tradeCode: request.tradeCode,
      prfNumber: request.prfNumber,
      fileName: fileName,
      upload_id: upload_id,
      parts: parts, 
      token: request.token
    };
    const result: any = await this.http.post(environment.apiURL + 'completeS3MultipartUpload', payload, { headers }).toPromise();
    return result;
  }


  async removeS3prfDoc(request: any): Promise<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'removeS3file', body, { headers }).toPromise();
    return output;
  }

  async listS3prfDoc(request: any): Promise<any> {
    let output;
    if (request.tradeCode === 'groupAll') {
      output = this.http.get(environment.apiURL + 'getS3prfDoc?company=' + request.company +
      '&operation=' + request.operation + '&prfNumber=' + request.prfNumber +
      '&token=' + request.token).toPromise();
    } else {
      output = this.http.get(environment.apiURL + 'getS3prfDoc?company=' + request.company +
      '&operation=' + request.operation + '&tradeCode=' + request.tradeCode +
      '&prfNumber=' + request.prfNumber + '&token=' + request.token).toPromise();
    }
    return output;
  }

  async downloadS3prfDoc(request: any): Promise<any> {
    let output;
    if (request.tradeCode === 'groupAll') {
      output = this.http.get(environment.apiURL + 'downloadS3prfDoc?company=' + request.company +
      '&operation=' + request.operation + '&userGroup=' + request.userGroup + '&prfNumber=' + request.prfNumber +
      '&fileName=' + encodeURIComponent(request.fileName) + '&token=' + request.token).toPromise();
    } else {
      output = this.http.get(environment.apiURL + 'downloadS3prfDoc?company=' + request.company +
      '&operation=' + request.operation + '&tradeCode=' + request.tradeCode +
      '&prfNumber=' + request.prfNumber + '&fileName=' + encodeURIComponent(request.fileName) + '&token=' + request.token).toPromise();
    }
    return output;
  }

  // PRFS AUDITS BELOW

  async listPrfsAudit(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'listPrfsAudit?' + body).toPromise();
    return output;
  }

  async createPrfsAudit(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'createPrfsAudit', body, { headers }).toPromise();
    return output;
  }

  async deletePrfsAudit(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'deletePrfsAudit', body, { headers }).toPromise();
    return output;
  }


  // SMART PRF ROUTES

  // SMART PRF 4.2
  async getBookingRefForPrf(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'getBookingRefForPrf?' + body).toPromise();
    return output;
  }

  async getMultipleBookingRefForCreatePrf(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'getMultipleBookingRefForCreatePrf?' + body).toPromise();
    return output;
  }

  async getMultipleBookingRefForViewPrf(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'getMultipleBookingRefForViewPrf?' + body).toPromise();
    return output;
  }
  

  async listMembersBookingReferences(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'listMembersBookingReferences?' + body).toPromise();
    return output;
  }


  // SMART PRF 4.4 
  async signMultiplePrfs(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'signMultiplePrfs', body, { headers }).toPromise();
    return output;
  }

  // SMART PRF 4.5 
  async getPrfReport(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'getPrfReport?' + body).toPromise();
    return output;
  }

  // PRF TRANSACTION ROUTES BELOW

  async createPrfsTransaction(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'createPrfsTransaction', body, { headers }).toPromise();
    return output;
  }

  async updatePrfsTransaction(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'updatePrfsTransaction', body, { headers }).toPromise();
    return output;
  }

  async deletePrfsTransaction(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'deletePrfsTransaction', body, { headers }).toPromise();
    return output;
  }

  async listPrfsTransaction(request: any): Promise<any> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'listPrfsTransaction?' + body).toPromise();
    return output;
  }

  // PRF BOOKING ROUTES BELOW

  async createPrfsBooking(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'createPrfsBooking', body, { headers }).toPromise();
    return output;
  }

  // PRF BOOKING REFERENCE SUGGESTION

  async getSuggestNonTAPStoPay(request: any): Promise<object> {
    let body = '';
    for (const [key, value] of Object.entries(request)) {
      body = body + '&' + key + '=' + value;
    }
    const output = await this.http.get(environment.apiURL + 'suggestNonTAPStoPay?' + body).toPromise();
    return output;
  }

}
