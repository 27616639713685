import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../../../common/global-constants';
import { environment } from '../../../../environments/environment';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Session } from '../../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';

import { CustomerService } from '../../../services/customer.service';
import { BookingService } from '../../../services/booking.service';
import { UserService } from '../../../services/user.service';
import { FellohService } from '../../../services/felloh.service';
import { BjornService } from '../../../services/bjorn.service';
import { SupplierService } from '../../../services/supplier.service';

import { AppComponent } from '../../../app.component';

import * as moment from 'moment';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-new-link',
  templateUrl: './new-link.component.html',
  styleUrls: ['./new-link.component.css', '../../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class NewLinkComponent implements OnInit {
  // Import stuff from outside
  constants = new GlobalConstants();
  titles = GlobalConstants.titles;
  singsCustomerURL = environment.singsCustomerURL;
  innerWidth = AppComponent.myapp.innerWidth;

  // Data tables below
  customerData: any = new MatTableDataSource<any>();
  customer: any = {};
  duplicatePayment: any = {};

  // Regex for dropping requirement for booking prefix
  noLongerIbos = /^(X5356|X2568)$/;

  // Global variables for this file only
  userType = '';
  errorMessage: any = '';
  successMessage: any = '';
  userCompany = '';
  bookingPrefix: any = '';
  customerErrorMessage: any = '';
  selectedCurrency: any = 'GBP';

  // Variables to do with autocomplete (TTA members only)
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  bookingRefString: any = '';
  bookRefList: any = [];
  bookingReference: any = '';
  supplierNames: any = [];
  departureDate: any = '';
  rtnDate: any = '';
  autocompleteClicked: any = false;
  branchListData: any = [];
  selectedBranch: any = {};
  selectedTradingName: any = {};
  selectedFellohAccount: any = {}; // Variable which will be used for Felloh-related activities
  fellohCurrencies: any = []; // Variable holding available currencies
  fellohOptions: any = {
    types: { amex: true, mastercard: true, visa: true },
    regions: { uk: true, europe: true, world: true },
    methods: { card: true, openBanking: true },
    surcharging: true,
    expiryDate: ''
  };

  // Access variables
  fxOpenView = false;
  haveAccess = false;
  pageLoaded = false;

  // View variables
  formCustomerDisable = false;
  newCustomer = false;
  existingCustomer = false;
  showSelectedCustomer = false;
  fellohView = true;
  fxView = false;

  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute, private bookingService: BookingService,
    private customerService: CustomerService, private userService: UserService, private supplierService: SupplierService,
    private fellohService: FellohService, private bjornService: BjornService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      AppComponent.myapp.fellohNav = true;
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType & userCompany and load the page
      this.userType = Session.mySession.getUser().userType;
      this.userCompany = Session.mySession.getUser().company;

      this.route.params.subscribe(params => {
        this.branchListData = Session.mySession.getUsersGroup(); // Get the userGroup array from session
        // Check the window history for parsed payment from Felloh home site
        if (window.history.state.payment !== undefined) {
          // Retrieve duplicate payment from window history
          this.duplicatePayment = window.history.state.payment;

          // Retrieve the branch from window history and filter it out from the branch list data
          if (this.branchListData.length > 0) {
            this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === window.history.state.selectedBranch.tradeCode)[0], 'script');
          } else {
            this.changeBranch(Session.mySession.getBranch(), 'script'); // Branch list array empty - meaning we need to use users' current branch details
          }

          // All payments must have transactionId - therefore if undefined, create an object and call getCustomer with it
          const request = { value: { email: this.duplicatePayment.email, lastName: '', telNo: '' } };
          this.loadDuplicate(request.value);
        } else {
          this.loadPage();
        }
      });
    }
  }

  loadPage(): void {
    if (this.userType === 'wcMember' || this.userType === 'wcManager' || this.userType === 'memberManager' || this.userType === 'memberStaff') {
      // This page is only reserved for wcMember users and admins
      // Logged in user is in the group and branch list already exists within the session variable.
      if (this.branchListData.length > 0) {
        this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode)[0], 'script');
      } else {
        this.changeBranch(Session.mySession.getBranch(), 'script'); // Load user's current branch
      }
      this.haveAccess = true;
    }
    this.pageLoaded = true;
  }

  getCustomer(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // Assign the trade code and token to form object
      form.value.tradeCode = this.selectedBranch.tradeCode;
      form.value.token = Session.mySession.get('user').token;

      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        form.value.exclusiveToId = Session.mySession.getUser().id.toString();
      }

      this.pageLoaded = false;
      // Call getCustomer API and assign its output to 'customers'
      this.customerService.getCustomer(form.value).then((customers: any) => {
        if (customers.status === 'OK') {
          // Assign the data then to data table variable
          // Update CRF75 - need to filter out all customers without emails
          this.customerData.data = customers.data.filter((cust: any) => cust.email != null && cust.email !== '');
          this.pageLoaded = true;
          if (customers.data.length === 0) {
            this.sendMessageToDialog('', 'No customers have been found', '', '');
          }
        } else {
          // Print out the message and clear out data table variable
          this.sendMessageToDialog('', customers.status, '', '');
          this.customerData.data = [];
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E3001S)', error, form.value);
      });
    }
  }

  loadDuplicate(form: any): void {
    this.pageLoaded = false;
    // Create new session object - easy getter and setter
    form.token = Session.mySession.get('user').token;
    form.tradeCode = this.selectedBranch.tradeCode;
    // Apply exclusiveToId filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      form.value.exclusiveToId = Session.mySession.getUser().id.toString();
    }
    // Call getCustomer API and assign its output to 'customers'
    this.customerService.getCustomer(form).then((customers: any) => {
      if (customers.status === 'OK' && customers.data.length > 0) {
        // Switch every view-based booleans so the page looks as if human selected / typed in all information
        const changeRadioBooking = { value: 'existing' };
        const changeRadioCustomer = { value: 'existing' };
        this.radioChangeCustomer(changeRadioCustomer);
        this.autocompleteClicked = true;
        // Select the first customer from an array. It will actually be the ONLY customer since the email
        // addresses are unique for all customers (within the trade code)
        // Update CRF75 - need to filter out all customers without emails
        this.customerData.data = customers.data.filter((cust: any) => cust.email != null && cust.email !== '');
        this.customerSelected(0);
        this.radioChangeBooking(changeRadioBooking);
      } else {
        // Print out the message and fill in all available fields
        this.sendMessageToDialog('', 'Please fill in missing details', '', '');
        // Switch every view-based booleans so the page looks as if human selected / typed in all information
        const changeRadioBooking = { value: 'existing' };
        const changeRadioCustomer = { value: 'new' };
        this.radioChangeCustomer(changeRadioCustomer);
        // Parse all attributes to the customer object which will be printed out in view page
        const custNameSplit = this.duplicatePayment.customer_name.split(' ');
        this.customer.firstName = custNameSplit[0];
        this.customer.lastName = custNameSplit[custNameSplit.length - 1];
        this.customer.email = this.duplicatePayment.email;
        this.radioChangeBooking(changeRadioBooking);
      }
      // Booking data auto-completed before the page loads
      this.duplicateAutocomplete();
      this.pageLoaded = true; this.haveAccess = true;
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E3002S)', error, form);
      this.haveAccess = true;
    });
  }

  customerSelected(index: any): void {
    // When selecting customer from the select list, index is used to map the object within the array list
    // That object is then assigned to the customer object and displayed to the user
    this.customer = this.customerData.data[index];
    this.showSelectedCustomer = true;
  }

  radioChangeBooking(event: any): void {
    if (event.value === 'existing' && Session.mySession.getUser().company === 'ttng') {
      // If booking existing and company = ttng -> Booking prefix will be NWG-
      this.bookingPrefix = 'NWG-';
    } else if (event.value === 'existing' && Session.mySession.getUser().company === 'gtg') {
      // If booking existing and company = gtg -> Booking prefix will be GTG-
      this.bookingPrefix = 'GTG-';
    } else if (event.value === 'new') {
      // If the booking doesn't exist in iSell just yet, the bookingPrefix will be set to temp-
      this.bookingPrefix = 'temp-';
    }
  }

  radioChangeCustomer(event: any): void {
    if (event.value === 'existing') {
      // If customer exists, set view controllers to show search functionality and make fields read-only
      this.existingCustomer = true;
      this.newCustomer = false;
      this.customerErrorMessage = '';
      this.formCustomerDisable = true;
      this.showSelectedCustomer = false;
    } else if (event.value === 'new') {
      // If customer is new, set view controllers to show fields which need to be filled in
      this.existingCustomer = false;
      this.newCustomer = true;
      this.customerErrorMessage = 'Customer has been created but ';
      this.formCustomerDisable = false;
      this.showSelectedCustomer = true;
    }
    // No matter what have been chosen, both customer object and customer data array are cleared up every time change is made
    this.customer = {};
    this.customerData.data = [];
    // Any time radio button is being changed, we need to reset below variables to empty string
    this.bookingPrefix = '';
  }

  duplicateAutocomplete(): void {
    if (this.duplicatePayment.bookingReference.substring(0, 4) === 'NWG-' || this.duplicatePayment.bookingReference.substring(0, 4) === 'GTG-') {
      // global bookingReference variable cannot have any prefix. That's where we're getting rid of it
      this.bookingReference = this.duplicatePayment.bookingReference.substring(4, this.duplicatePayment.bookingReference.length);
      this.bookingRefString = this.duplicatePayment.bookingReference.substring(4, this.duplicatePayment.bookingReference.length);
    } else {
      // tta members don't have prefixes hence we're just copying whole reference here
      this.bookingReference = this.duplicatePayment.bookingReference;
      this.bookingRefString = this.duplicatePayment.bookingReference;
    }
    if (this.duplicatePayment.booking.departure_date) { this.departureDate = this.duplicatePayment.booking.departure_date; }
    if (this.duplicatePayment.booking.return_date) { this.rtnDate = this.duplicatePayment.booking.return_date; }
    if (this.duplicatePayment.metadata.supplierName) { this.supplierNames = this.duplicatePayment.metadata.supplierName; }
  }

  validateFellohFields(form: NgForm, source: any): void {
    this.pageLoaded = false;
    if (source === 'ttngGtg' && this.bookingPrefix === '') {
      // In case any of the radio buttons haven't been selected - display it for the agent
      this.sendMessageToDialog('', 'Please select either New or Existing Booking', '', '');
    } else if (source === 'ttngGtg' && (this.isEmpty(form.value.firstName) || this.isEmpty(form.value.lastName) ||
                                        this.isEmpty(form.value.bookingReference) || this.isEmpty(form.value.amountDue) || this.isEmpty(form.value.notes))) {
      // In case any of the required fields are empty, throw an error message
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    } else if (source === 'fx' && (this.isEmpty(form.value.firstName) || this.isEmpty(form.value.lastName) ||
                                   this.isEmpty(form.value.bookingReference) || this.isEmpty(form.value.amountDue))) {
      // In case any of the required fields are empty, throw an error message
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    } else if (source === 'tta' && (this.isEmpty(form.value.firstName) || this.isEmpty(form.value.lastName) ||
                                    this.isEmpty(form.value.bookingReference) || this.isEmpty(form.value.amountDue) || this.isEmpty(form.value.notes) ||
                                    this.isEmpty(form.value.deptDate) || this.isEmpty(form.value.returnDate))) {
      // In case any of the required fields are empty, throw an error message
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    } else if (source === 'ttngGtg' && this.bookingPrefix !== 'temp-' && (form.value.bookingReference.match(/^-?\d+$/) == null || form.value.bookingReference <= 0)) {
      // Throw an error message if the booking reference is less than 0 or if it contains non-numeric character
      this.sendMessageToDialog('', 'Booking reference is in the wrong format', '', '');
    } else if (source === 'tta' && /TTAS|TTNG|GTGS/i.test(this.bookingReference)) {
      // Users from the booking system should still be able to pay for bookings from previous system (unless transfered?)
      // However, they won't be able to create a new temporary references. Nothing will stop them from paying for another booking ref
      // Though there will be validation so they cannot create payment links for SinGS bookings here
      this.sendMessageToDialog('', 'Payment links for SinGS bookings need to be created from Booking Portfolio', '', '');
    } else if (this.constants.validateFormCharacters(form) !== true) {
      // Validate characters entered in the form
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // In case the FX form was submitted, we are assigning appropriate prefix below
      if (source === 'fx') { this.bookingPrefix = 'FX-'; }

      // Assign booking prefix to the reference below
      form.value.bookingReference = this.bookingPrefix + form.value.bookingReference;

      if (this.formCustomerDisable) {
        // We're going straight to reference validation below
        this.validateFellohRef(form, source);
      } else {
        // Below happens if the 'New Customer' radio button have been selected.
        this.createCustomer(form, source);
      }
    }
  }

  validateFellohRef(form: NgForm, source: any): void {
    if (source === 'tta') { form.value.bookingReference = this.bookingRefString; }

    if ((source === 'tta' && !form.value.bookingReference) || (source === 'ttngGtg' && this.bookingPrefix === 'temp-')) {
      this.createFellohLink(form, null);
    } else {
      this.validateFellohBooking(form, source);
    }
  }

  validateFellohBooking(form: NgForm, source: any): void {
    // Check if the booking already exists within Felloh..
    this.getFellohBooking(form).then((bookingList: any) => {
        
      if (bookingList && bookingList?.meta?.reason === 'OK') {
        // Call method to create a payment link within that booking
        if (bookingList.data.length > 0) { this.createFellohLink(form, bookingList.data[0].id); }
        
        // Booking does not exist - we need to create one and get its ID
        else {
          this.createFellohBooking(form, source).then((bookingRef: any) => {
            // Call method to create a payment link within that booking ==== CREATE BELOW ==== 
            if (bookingRef && bookingRef?.meta?.reason === 'OK') { this.createFellohLink(form, bookingRef.data.id); }
            else { this.sendMessageToDialog('', bookingRef?.meta?.reason, '', ''); }
          });
        }

      } else { this.sendMessageToDialog('', bookingList?.meta?.reason, '', ''); }
    });
  }

  getFellohBooking(form: NgForm): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageLoaded = false;
      
      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
        // Create a Felloh request object with an organisation + booking reference pair
        const request: any = {
          organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
          booking_reference: form.value.bookingReference
         };

        // Below request will return Felloh booking (probably just one but who knows..?)
        this.fellohService.getFellohBookings(request, tokenOut).then((bookingList: any) => {
          resolve(bookingList);
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E3001F)', error, '');
          resolve(error?.error);
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E3002F)', error, '');
        resolve(error?.error);
      });
    });
  }

  createFellohBooking(form: NgForm, source: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageLoaded = false;

      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
        // Create a Felloh request object with an organisation + booking reference pair
        // Additionally parse in all optional values to make Felloh more happy
        const request: any = {
          organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
          customer_name: form.value.firstName + ' ' + form.value.lastName,
          email: form.value.email, booking_reference: form.value.bookingReference
        };

        if (source === 'tta') {
          request.departure_date = this.constants.convertDateMoment(form.value.deptDate);
          request.return_date = this.constants.convertDateMoment(form.value.returnDate);
        }

        // Below request will create a Felloh booking and return its ID to us
        this.fellohService.createFellohBooking(request, tokenOut).then((bookingRes: any) => {
          resolve(bookingRes);
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E3003F)', error, '');
          resolve(error?.error);
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E3004F)', error, '');
        resolve(error?.error);
      });
    });
  }

  createFellohLink(form: NgForm, bookingId: any): void {
    // Create Felloh request hash to request access token and call the API
    Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
      // Create new hash variable which hold all sorts of data required by Felloh before creating new payment link. Parameters worth noting are
      // cancelURL -> page which will display after payment cancelled(? TBD) | paymentStatusCallbackUrl -> API which will be called after successful payment (TBD)
      
      let logoURL = 'null'; // Set the logoRef here to null initially (as it is required anyway..)
      if (this.selectedTradingName.logoRef !== '') { logoURL = encodeURI(this.selectedTradingName.logoRef); }

      // Set the success url - where the Felloh will redirect user after payment (either failed or not failed - doesn't matter)
      const successURL = environment.apiURL + 'fellohRedirect/uid=' + form.value.customerID + '/u=' + Session.mySession.getEmail().emailU + '/p=' + Session.mySession.getEmail().emailP + '/';
      // Set the fail url - where the user will be redirected upon fail? (tbd)
      const cancelURL = this.singsCustomerURL + 'cancelFelloh/' + encodeURI(this.selectedTradingName.tradingName) + '/' + logoURL + '/error';

      // Work out the currency below
      let currency = 'GBX';
      if (this.selectedCurrency === 'USD') { currency = 'USX'; }
      else if (this.selectedCurrency === 'EUR') { currency = 'EUX'; }

      // Work out excluded card types / regions below
      const excludedRegions = []; const excludedTypes = [];
      if (!this.fellohOptions.regions.uk) { excludedRegions.push('DOMESTIC'); }
      if (!this.fellohOptions.regions.europe) { excludedRegions.push('INTER'); }
      if (!this.fellohOptions.regions.world) { excludedRegions.push('INTRA'); }
      if (!this.fellohOptions.types.amex) { excludedTypes.push('AMEX'); }
      if (!this.fellohOptions.types.mastercard) { excludedTypes.push('MASTERCARD'); }
      if (!this.fellohOptions.types.visa) { excludedTypes.push('VISA'); }

      // Convert moment / non-moment date to appropriate date below yyyy-mm-dd
      if (this.fellohOptions.expiryDate === '') { this.fellohOptions.expiryDate = ''; }
      else if (this.fellohOptions.expiryDate._i == null) { this.fellohOptions.expiryDate = this.constants.convertDateNotMoment(form.value.expiryDate); }
      else { this.fellohOptions.expiryDate = this.constants.convertDateMoment(this.fellohOptions.expiryDate); }
      
      let expiryDate = this.fellohOptions.expiryDate === "" ? null : this.fellohOptions.expiryDate;

      // Create a large payment request hash object which contains all information needed to create a Felloh link
      const newLinkRequest: any = {
        customer_name: form.value.firstName + ' ' + form.value.lastName, email: form.value.email,
        organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode), booking_id: bookingId,
        currency: currency, amount: Math.round(parseFloat(form.value.amountDue) * 100),
        description: form.value.notes,
        success_url: successURL, cancel_url: cancelURL, type: 'UNKNOWN',
        metadata: { requestCreator: Session.mySession.getUser().email, tradingNameId: this.selectedTradingName.id, tradingName: this.selectedTradingName.tradingName, logoURL: logoURL },
        open_banking_enabled: this.fellohOptions.methods.openBanking, card_enabled: this.fellohOptions.methods.card,
        excluded_card_regions: excludedRegions,
        excluded_card_types: excludedTypes,
        expires_at: expiryDate
      };

      if (this.selectedFellohAccount.surcharging === 'yes') { newLinkRequest.surcharging_enabled = this.fellohOptions.surcharging; }

      this.pageLoaded = false;
      this.fellohService.createFellohLink(newLinkRequest, tokenOut).then(() => {
        // If success, set session variable and navigate to the main page
        Session.mySession.set('createdPayment', true);
        Session.mySession.set('paymentBranch', this.selectedBranch.tradeCode);
        Session.mySession.set('fellohAccId', this.selectedFellohAccount.id);
        this.router.navigate(['/paymentLinks']);
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E3005F)', error, newLinkRequest);
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E3006F)', error, '');
    });
  }

  createCustomer(form: NgForm, source: any): void {
    // Apply exclusiveToId filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      form.value.exclusiveToId = Session.mySession.getUser().id.toString();
    }
    // If 'New Customer' selected BUT Customer did not provide email address
    // We are creating a 'fake' email address for them
    const fakeNumber = Math.floor(Math.random() * 9999999) + 1;
    if (form.value.email === undefined || form.value.email === '') {
      form.value.email = 'cust' + fakeNumber + '@thetravelnetworkgroup.co.uk';
    }

    // Below are object attributes needed for creating new customer in DB
    // If not provided, all of the below will be 'null' rather than empty string ''
    form.value.middleName = '';
    form.value.telNo = '';
    form.value.homeNo = '';
    form.value.postcode = '';
    form.value.addressLine1 = '';
    form.value.addressLine2 = '';
    form.value.addressLine3 = '';
    form.value.addressLine4 = '';
    form.value.county = '';
    form.value.country = '';

    form.value.token = Session.mySession.get('user').token;
    form.value.tradeCode = this.selectedBranch.tradeCode;

    this.customerService.createCustomer(form.value).then((output: any) => {
      if (output.status === 'OK') {
        // This happens only if the customer has been created successfully
        form.value.customerID = output.id;

        if (form.value.email === undefined || form.value.email === '') {
          this.customer.id = output.id;
          this.customer.email = 'cust' + fakeNumber + '@thetravelnetworkgroup.co.uk';
        }
        this.validateFellohRef(form, source); // We're going straight to reference validation below

      } else if (output.status === 'Email has already been taken') {
        // Customer haven't been created successfully - this is where the script ends
        this.sendMessageToDialog('', 'SinGS could not generate Customer email at this time - please try again', '', '');
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E3003S)', error, form.value);
    });
  }

  bookingRefLookup(event: any, whatCalling: any): void {
    // Any user input fills in the search string which is used later on
    if (whatCalling === 'input') {
      this.bookingRefString = event.target.value.trim();
      if (this.autocompleteClicked === true) { this.clearTTAboxes(); this.autocompleteClicked = false; }
    }
    // Only search button can invoke below if statement
    if (this.bookingRefString.length > 4 && whatCalling === 'button') {
      // Create Felloh request hash to request access token and call the API
      Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {

        const request: any = {
          organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode), keyword: this.bookingRefString
        };

        // Get the reference number from the user and look it up in Felloh
        this.fellohService.getFellohBookings(request, tokenOut).then((bookingList: any) => {
          if (bookingList.meta.reason === 'OK' && bookingList.data.length > 0) {
            this.bookRefList = bookingList.data;
          } else {
            this.bookRefList = []; this.sendMessageToDialog('', 'No results found', '', '');
          }
          
        }).catch((error: any) => {
          this.bookRefList = [];
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E3007F)', error, '');
        });
      });
    } else if (this.bookingRefString.length < 5 && whatCalling === 'button') {
      this.sendMessageToDialog('', 'Booking reference needs to have at least 5 characters', '', '');
    } else {
      this.bookRefList = [];
    }
  }

  selectBookRef(event: any): void {
    const selectedRow = this.bookRefList[event];

    this.departureDate = selectedRow.departure_date;
    this.rtnDate = selectedRow.return_date;
    this.bookingReference = selectedRow.booking_reference;

    // tslint:disable-next-line:no-non-null-assertion
    (document.getElementById('bookingReference')! as HTMLInputElement).value = this.bookingRefString;
    this.autocompleteClicked = true;
  }

  changeBranch(branch: any, source: any): void {
    this.selectedBranch = branch; // Assign new trade code to the 'global' request
    this.selectedTradingName = branch.tradingNames[0];
    this.selectedFellohAccount = branch?.fellohConfig[0];
    this.setFellohCurrenciesUp(); // Work out currency list for given Felloh account
    const changeRadioCustomer = { value: 'existing' };
    // Switch the radios again to restart process
    if (source === 'view') { this.radioChangeCustomer(changeRadioCustomer); }
    this.validateFXopeness(branch.tradeCode); // Call this to validate FX openess
  }

  setFellohCurrenciesUp(): void {
    this.selectedCurrency = 'GBP'; // As a default, we'll switch it to GBP
    this.fellohCurrencies = this.selectedFellohAccount?.currencies?.split(';');
  }

  validateFXopeness(tradeCode: any): void {
    const regex = /(Q0284|Q0655|Q0829|Q7211|Q7226|Q9039|Q9043|Q9058|Q9062|Q9077|Q9081|Q9096|Q9109|Q9113|Q9128|Q9166|Q9363)/;
    // Pretty straight forward - we're 'opening' the FX part for above members..
    if (regex.test(tradeCode)) { this.fxOpenView = true; }
  }

  toggleFellohOptions(): void {
    this.fellohOptions.opened = !this.fellohOptions.opened;
  }

  // Reset autocomplete form
  clearTTAboxes(): void {
    this.bookRefList = [];
    this.supplierNames = [];
    this.departureDate = '';
    this.rtnDate = '';
  }

  addChip(event: any, supplierNames: any): void {
    const value = (event.value || '').trim();
    if (value) { supplierNames.push(value); }
    event.input.value = '';
  }

  addChipV2(event: any, supplierNames: any): void {
    const value = (event.value || '').trim();
    const request = { supplierNameX: value, token: Session.mySession.get('user').token };

    this.supplierService.getSupplierMaster(request).then((output: any) => {
      if (output !== false && output.status === 'OK' && output.suppMapList.length > 0) {
        if (supplierNames.indexOf(output.suppMapList[0].supplierNameM) === -1) {
          supplierNames.push(output.suppMapList[0].supplierNameM);
        } else {
          this.sendMessageToDialog('', 'You\'ve already added ' + output.suppMapList[0].supplierNameM, '', '');
        }
      } else {
        this.sendMessageToDialog('', 'This supplier does not exist', '', '');
      }
      event.input.value = '';
    });
  }

  removeChip(name: any, supplierNames: any): void {
    const index = supplierNames.indexOf(name);
    supplierNames.splice(index, 1);
  }

  switchView(view: any): void {
    this.existingCustomer = false;
    this.newCustomer = false;
    this.formCustomerDisable = false;
    this.showSelectedCustomer = false;

    // Reset autocomplete form
    this.clearTTAboxes();
    this.bookingReference = '';

    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    if (view === 'felloh') {
      this.fellohView = true;
      this.fxView = false;
      this.bookingPrefix = '';
    } else if (view === 'fx') {
      this.fellohView = false;
      this.fxView = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  isEmpty(value: any): boolean {
    if (value) { return value === ''; }
    else { return false; }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // Check if the error comes from Felloh and if we can display it to the user
      // If we can then let's display it rather than give them weird code..
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        failureMessage = error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error.hasOwnProperty('message')) {
        failureMessage = error.error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors.length > 0) {
        failureMessage = error.error.errors[0].message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      }
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}