import { Injectable } from '@angular/core';
import { GlobalConstants } from './global-constants';

@Injectable({
  providedIn: 'root'
})

export class Session {
  static mySession: any; // Variable used in all components which 'saves' current data. Needs to be initialised at the beginning of app.component
  user: any = {}; // Variable holidng data of logged in users
  branch: any = {}; // Variable holidng data of the branch user is assigned to
  usersGroup: any = []; // Variable holding multiple branches (if exist) of the user..

  userList: any = {}; // Variable holding a list of availble users in SinGS. Updated every 30 minutes
  branchList: any = {}; // Variable holding a list of availble branches in SinGS. Updated every 30 minutes
  supplementList: any = {}; // Variable holding a list of availble supplements in SinGS. Updated every 5 minutes
  supplierList: any = {}; // Variable holding a list of availble suppliers in SinGS. Updated every 5 minutes

  // Variable holding data of opened booking. Updated every minute
  currentBooking: any = { skeleton: {}, passengers: {}, receipts: {}, payments: {}, insurances: {}, supplements: {} };

  // Current booking variables below
  newElementSupplier: any = {};
  openedBooking: any = {};
  latestPax: any = [];

  // List of reports user is waiting for
  awaitingReports: any = [];

  // Current 'big' reports saved in session - produced once a daa - hence no need to set EXPIRY_DATE on them
  reportsCalled = false;
  ytdReports = []; // Holding ytd figures..
  depRetCalendarReport = []; // Holding all booking + element dept + return dates

  // ============================================ //

  // Felloh variables below - V2 (felloh.com)
  publicKey = '';
  privateKey = '';
  // 'New' Felloh token generation
  fellohTokenNew: any = { token: '', type: '', expiry_time: '', accountCode: '' };

  // Email variables below (sending automated emails + more..)
  emailP = '';
  emailU = '';

  // Initialise mySession variable (starts at the beginning of the app)
  public initialise(): void {
    Session.mySession = this;
  }
  // User's getter + setter
  public setUser(value: any): void {
    this.user = value;
  }

  public setToken(value: any): void {
    this.user.token = value;
  }

  public getUser(): any {
    return this.user;
  }
  // Branch getter + setter
  public setBranch(branch: any): void {
    this.branch = branch;
  }

  public getBranch(): any {
    return this.branch;
  }
  // When a manager uploads their logo, below is called to update the URL (for Felloh links)
  public setBranchLogo(url: any): void {
    this.branch.logoRef = url;
  }
  // When a manager corrects any of the card fees, below is being called to update these fees
  public setCardFees(request: any): void {
    this.branch.cardFeePercAmex = request.feeAmex; // 1%
    this.branch.cardFeePercCredit = request.feeCredit; // 1.22%
    this.branch.cardFeePercDebit = request.feeDebit; // 0%
  }
  // User group getter + setter..
  public getUsersGroup(): any {
    return this.usersGroup;
  }

  public setUsersGroup(branchList: any): void {
    branchList = branchList.map((branch: any) => {
      // Return the updated branch object
      return branch;
    });
    const sorted = branchList.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1);
    this.usersGroup = sorted;
  }
  // Branch list getter + setter
  public setBranchList(branchList: any): void {
    let expiryTime = new Date();
    expiryTime.setMinutes(expiryTime.getMinutes() + 30); // timestamp
    expiryTime = new Date(expiryTime); // Date object
    this.branchList = { expiryTime, branchList };
  }

  public getBranchList(): any {
    const currentTime = new Date();
    if (this.branchList.expiryTime === undefined || (currentTime > this.branchList.expiryTime)) {
      return { expiryTime: 'EXPIRED' };
    } else {
      return this.branchList;
    }
  }
  // User list getter + setter
  public setUserList(tradeCode: any, userList: any): void {
    this.userList = { tradeCode, userList };
  }

  public getUserList(tradeCode: any): any {
    if (this.userList.tradeCode !== tradeCode) {
      return { expiryTime: 'EXPIRED' };
    } else {
      return this.userList;
    }
  }
  // Supplement getter + setter
  public setSupplementList(supplementList: any): void {
    let expiryTime = new Date();
    expiryTime.setMinutes(expiryTime.getMinutes() + 5); // timestamp
    expiryTime = new Date(expiryTime); // Date object
    this.supplementList = { expiryTime, supplementList };
  }

  public getSupplementList(): any {
    const currentTime = new Date();
    if (this.supplementList.expiryTime === undefined || (currentTime > this.supplementList.expiryTime)) {
      return { expiryTime: 'EXPIRED' };
    } else {
      return this.supplementList;
    }
  }
  // Supplier getter + setter
  public setSupplierList(tradeCode: any, supplierList: any): void {
    let expiryTime = new Date();
    expiryTime.setMinutes(expiryTime.getMinutes() + 5); // timestamp
    expiryTime = new Date(expiryTime); // Date object
    this.supplierList = { expiryTime, tradeCode, supplierList };
  }

  public getSupplierList(tradeCode: any): any {
    const currentTime = new Date();
    if (this.supplierList.expiryTime === undefined || (currentTime > this.supplierList.expiryTime)) {
      return { expiryTime: 'EXPIRED' };
    } else if (this.supplierList.tradeCode !== tradeCode) {
      return { expiryTime: 'EXPIRED' };
    } else {
      return this.supplierList;
    }
  }
  // Booking skeleton getter + setter
  public setCurrentBookingsValue(bookingRef: any, property: any, value: any): void {
    let expiryTime = new Date();
    expiryTime.setSeconds(expiryTime.getSeconds() + 20); // timestamp
    expiryTime = new Date(expiryTime); // Date object
    if (property === 'skeleton') {
      this.currentBooking.skeleton = { bookingRef, expiryTime, value };
    } else if (property === 'passengers') {
      this.currentBooking.passengers = { bookingRef, expiryTime, value };
    } else if (property === 'receipts') {
      this.currentBooking.receipts = { bookingRef, expiryTime, value };
    } else if (property === 'payments') {
      this.currentBooking.payments = { bookingRef, expiryTime, value };
    } else if (property === 'insurances') {
      this.currentBooking.insurances = { bookingRef, expiryTime, value };
    } else if (property === 'supplements') {
      this.currentBooking.supplements = { bookingRef, expiryTime, value };
    }
  }

  public getCurrentBookingsValue(bookingRef: any, property: any): any {
    const currentTime = new Date();
    if (property === 'skeleton' && (this.currentBooking.skeleton.bookingRef !== bookingRef || this.currentBooking.skeleton.expiryTime === undefined || (currentTime > this.currentBooking.skeleton.expiryTime))) {
      return { expiryTime: 'EXPIRED' };
    } else if (property === 'skeleton') {
      return this.currentBooking.skeleton;
    } else if (property === 'passengers' && (this.currentBooking.passengers.bookingRef !== bookingRef || this.currentBooking.passengers.expiryTime === undefined || (currentTime > this.currentBooking.passengers.expiryTime))) {
      return { expiryTime: 'EXPIRED' };
    } else if (property === 'passengers') {
      return this.currentBooking.passengers;
    } else if (property === 'receipts' && (this.currentBooking.receipts.bookingRef !== bookingRef || this.currentBooking.receipts.expiryTime === undefined || (currentTime > this.currentBooking.receipts.expiryTime))) {
      return { expiryTime: 'EXPIRED' };
    } else if (property === 'receipts') {
      return this.currentBooking.receipts;
    } else if (property === 'payments' && (this.currentBooking.payments.bookingRef !== bookingRef || this.currentBooking.payments.expiryTime === undefined || (currentTime > this.currentBooking.payments.expiryTime))) {
      return { expiryTime: 'EXPIRED' };
    } else if (property === 'payments') {
      return this.currentBooking.payments;
    } else if (property === 'insurances' && (this.currentBooking.insurances.bookingRef !== bookingRef || this.currentBooking.insurances.expiryTime === undefined || (currentTime > this.currentBooking.insurances.expiryTime))) {
      return { expiryTime: 'EXPIRED' };
    } else if (property === 'insurances') {
      return this.currentBooking.insurances;
    } else if (property === 'supplements' && (this.currentBooking.supplements.bookingRef !== bookingRef || this.currentBooking.supplements.expiryTime === undefined || (currentTime > this.currentBooking.supplements.expiryTime))) {
      return { expiryTime: 'EXPIRED' };
    } else if (property === 'supplements') {
      return this.currentBooking.supplements;
    }
  }
  // Reset booking skeleton timers
  public resetTimersOnBookingValues(): Promise<any> {
    return new Promise((resolve, reject) => {
      let expiryTime = new Date();
      expiryTime.setSeconds(expiryTime.getSeconds() - 20); // timestamp
      expiryTime = new Date(expiryTime); // Date object
      this.currentBooking.skeleton.expiryTime = expiryTime;
      this.currentBooking.passengers.expiryTime = expiryTime;
      this.currentBooking.receipts.expiryTime = expiryTime;
      this.currentBooking.payments.expiryTime = expiryTime;
      this.currentBooking.insurances.expiryTime = expiryTime;
      this.currentBooking.supplements.expiryTime = expiryTime;
      resolve('');
    });
  }

  // Getter + setter - V2 (felloh.com)
  public setFellohKeys(publicKey: any, privateKey: any): void {
    this.publicKey = publicKey;
    this.privateKey = privateKey;
  }

  public getFellohKeys(): any {
    return { public_key: this.publicKey, private_key: this.privateKey };
  }
  // Method which will re-generate Felloh token (if needed)
  public fetchFellohAuthorisation(fellohService: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const currentDateTime = new Date();
      // If Felloh token doesn't exist yet or if it expired - regenerate it
      // Otherwise - re-use current one (reduce lags and the number of API calls)
      if (this.fellohTokenNew.expiry_time === '' || currentDateTime > new Date(this.fellohTokenNew.expiry_time)) {
        // Call an API to re-generate Felloh token - save it to last for 9 minutes from current time
        fellohService.fetchAuthorizationToken(this.getFellohKeys()).then((tokenOut: any) => {
          this.fellohTokenNew = tokenOut.data; // Re-assign token object here
          const expiryTime = new Date(currentDateTime.setMinutes(currentDateTime.getMinutes() + 8));
          this.fellohTokenNew.expiry_time = expiryTime; // Set expiry time to 8 minutes (rather than 10)
          resolve(this.fellohTokenNew);
        }).catch((error: any) => {
          reject(error);
        });
      } else {
        resolve(this.fellohTokenNew); // Return current token object (re-use it!)
      }
    });
  }
  // Method which will re-generate Felloh token (if needed)
  public fetchFellohAuthorisationV2(fellohService: any, accountCode: any, userType: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const constants = new GlobalConstants(); const currentDateTime = new Date();
      const accCode = userType === 'sinGSAdmin' ? 'T0000' : constants.getFellohCodeMapV2(accountCode);
      // If Felloh token doesn't exist yet or if it expired - regenerate it
      // Otherwise - re-use current one (reduce lags and the number of API calls)
      if (this.fellohTokenNew.expiry_time === '' || currentDateTime > new Date(this.fellohTokenNew.expiry_time) || this.fellohTokenNew.accountCode !== accCode) {
        // Call an API to re-generate Felloh token - save it to last for 9 minutes from current time
        fellohService.fetchAuthorizationTokenV2(this.getFellohTokenObj(accCode)).then((tokenOut: any) => {
          if (tokenOut.meta.code === 200) {
            this.fellohTokenNew = tokenOut.data; // Re-assign token object here
            this.fellohTokenNew.accountCode = accCode;
            const expiryTime = new Date(currentDateTime.setMinutes(currentDateTime.getMinutes() + 8));
            this.fellohTokenNew.expiry_time = expiryTime; // Set expiry time to 8 minutes (rather than 10)
            resolve(this.fellohTokenNew);
          } else {
            reject(tokenOut.errors);
          }
        }).catch((error: any) => {
          reject(error);
        });
      } else {
        resolve(this.fellohTokenNew); // Return current token object (re-use it!)
      }
    });
  }
  public getFellohTokenObj(accountCode: any): any {
    const user: any = { ... this.user }; user.accountCode = accountCode;
    return user;
  }
  // Email-related getter + setter
  public setEmail(emailU: any, emailP: any): void {
    this.emailU = emailU;
    this.emailP = emailP;
  }

  public getEmail(): any {
    return { emailU: this.emailU, emailP: this.emailP };
  }
  // Booking related variables getter + setter
  public setElementSupplier(supplier: any): void {
    this.newElementSupplier = supplier;
  }

  public setOpenedBooking(booking: any): void {
    this.openedBooking = booking;
  }

  public setLatestPax(passengers: any): void {
    this.latestPax = passengers;
  }

  public getElementSupplier(): any {
    return this.newElementSupplier;
  }

  public getOpenedBooking(): any {
    return this.openedBooking;
  }

  public getLatestPax(): any {
    return this.latestPax;
  }
  // Report - related getter + setter (and other)
  public addAwaitingReport(keywords: any): void {
    this.awaitingReports.push(keywords);
  }

  public getAwaitingReports(): any {
    return this.awaitingReports;
  }

  public removeAwaitingReport(index: any): void {
    this.awaitingReports.splice(index, 1);
  }
  // YTD reports + calendar reports getter + setter
  public setYtdReportsValues(data: any): void {
    this.ytdReports = data.ytdReport;
    this.depRetCalendarReport = data.eventsCalendar;
    this.reportsCalled = true; // Make sure to set it to true - API won't be called again..
  }

  public getYtdReportsValues(): any {
    if (!this.reportsCalled) { return false; }
    else { return { ytdReport: this.ytdReports, eventsCalendar: this.depRetCalendarReport }; }
  }
  // Getter + setter - deal with browser's session
  set(id: any, value: any): void {
    value = JSON.stringify(value);
    sessionStorage.setItem(id, value);
  }

  get(id: any): any {
    const value: any = sessionStorage.getItem(id);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
}
