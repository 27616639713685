import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { GlobalConstants } from 'src/app/common/global-constants';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Session } from '../../../common/session';
import { ReportsService } from 'src/app/services/reports.service';
import { NgForm } from '@angular/forms';
import { BranchService } from 'src/app/services/branch.service';

@Component({
  selector: 'app-document-settings',
  templateUrl: './document-settings.component.html',
  styleUrls: ['./document-settings.component.css', '../../../../app/app.component.fellohStyles.css']
})
export class DocumentSettingsComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // Variables taken from the child component
  @Input() templateName: any;
  @Input() isNewTemplate: any;
  @Input() documentName: any;

  // Document variable
  documentConfig: any = {};

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  pageLoaded = true;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  constructor(public dialog: MatDialog, private userService: UserService, private reportService: ReportsService, private branchService: BranchService) { }

  ngOnInit(): void {
    this.documentConfig = {
      tradeCode: Session.mySession.getUser().tradeCode, templateName: this.templateName, displaySignatures: false, displayCancelled: false, formalWording: true,
      showQrCode: true, displayBigImage: false, bigImageUrl: 'https://images.pexels.com/photos/615060/pexels-photo-615060.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      backgroundColour: '#f9f9f9', foregroundColour: '#ffffff', headerColour: '#0b1560', headerTextColour: '#ffffff', footerTextColour: '#C0C0C0'
    }

    this.getDocumentSettings();
  }

  getDocumentSettings(): void {
    const request = {
      tradeCode: Session.mySession.getUser().tradeCode, templateName: this.templateName,
      token: Session.mySession.get('user').token
    }

    this.reportService.getDocumentConfiguration(request).then((output: any) => {
      if (output.status === 'OK') {
        // Assign the data output to a variable only if non-null
        if (output.data) { this.documentConfig = output.data; }
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2501S)', error, request);
    });
  }

  confirmDocSettings(): void {
    // We will be calling either create or edit current document setting via the API
    // This will depend on whether the id is appended to the object or not
    const request = { ... this.documentConfig };
    request.options = {
      showQrCode: request.showQrCode, displayBigImage: request.displayBigImage, bigImageUrl: request.bigImageUrl, formalWording: request.formalWording,
      displaySignatures: request.displaySignatures, displayCancelled: request.displayCancelled, backgroundColour: request.backgroundColour,
      foregroundColour: request.foregroundColour, headerColour: request.headerColour, headerTextColour: request.headerTextColour,
      footerTextColour: request.footerTextColour
    };
    request.token = Session.mySession.get('user').token;

    this.pageLoaded = false;
    if (!request.id) {
      this.reportService.createDocumentConfiguration(request).then((output: any) => {
        if (output.status === 'OK') { this.sendMessageToDialog('Document configuration has been updated', '', '', ''); }
        else { this.sendMessageToDialog('', output.status, '', ''); }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2502S)', error, request);
      });
    } else {
      this.reportService.editDocumentConfiguration(request).then((output: any) => {
        if (output.status === 'OK') { this.sendMessageToDialog('Document configuration has been updated', '', '', ''); }
        else { this.sendMessageToDialog('', output.status, '', ''); }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2503S)', error, request);
      });
    }
  }

  uploadBigImage(fileInputEvent: any): void {
    // Depending on what file is being uploaded, we create a variable name to be displayed in the inputs
    // The xxxFiles variables presumably are holding all selected files - will see if that's true
    const file = fileInputEvent.target.files[0];

    if (file.size > 5242880) {
      this.sendMessageToDialog('', 'File size limit exceeded - please allow maximum 5MB per file', '', ''); // Don't allow too big files in S3 I'm afraid..
    } else if (file.name.match(/[!@#+=$£%^&*(),?":;'{}|<>[\]/]/)) {
      this.sendMessageToDialog('', 'Invalid file name - please avoid using special characters', '', '');
    } else if (file.name.length > 150) {
      this.sendMessageToDialog('', 'File name limit exceeded - please allow a maximum of 150 characters', '', '');
    } else {
      const request = {
        fileName: Session.mySession.getUser().tradeCode + '-' + file.name,
        file, whatFile: 'docImages', token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      this.branchService.updateBranchMedia(request).then((output: any) => {
        if (output.status === 'OK') { // Everyting went good as stauts is OK..
          // Amend object's file name and display success message to the user!
          this.documentConfig.bigImageUrl = 'https://sings-media.s3.eu-west-2.amazonaws.com/docImages/' + Session.mySession.getUser().tradeCode + '-' + file.name;
          this.sendMessageToDialog('File has been uploaded. Please save your changes', '', '', '');
        } else {
          this.sendMessageToDialog('', output.status, '', ''); // Display error message
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2504S)', error, '');
      });
    }
  }

  bringDefaultColours(): void {
    this.documentConfig.backgroundColour = '#f9f9f9'; this.documentConfig.foregroundColour = '#ffffff';
    this.documentConfig.headerColour = '#0b1560'; this.documentConfig.headerTextColour = '#ffffff';
    this.documentConfig.footerTextColour = '#C0C0C0';
  }

  openMedia(imageUrl: any): void {
    window.open(imageUrl, '_blank');
  }

  openColourLegend(imageName: any): void {
    window.open('https://sings-media.s3.eu-west-2.amazonaws.com/docImages/' + imageName, '', 'height=700, width=1024'); // Open the link
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}
