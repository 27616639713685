import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const MINUTES_UNITL_AUTO_LOGOUT = 30; // in mins
const CHECK_INTERVAL = 60000; // in ms
const STORE_KEY = 'lastAction';
const LOGOUT_KEY = 'timeOut';

@Injectable()

export class AutoLogoutService {

    // Get the date & time of last performed action (click / press key etc)
    public getLastAction(): any {
        // tslint:disable-next-line:radix
        return Number(localStorage.getItem(STORE_KEY));
    }

    // Set the date & time of the last performed action (click / press key etc)
    public setLastAction(lastAction: number): void {
        localStorage.setItem(STORE_KEY, lastAction.toString());
    }

    // Set the boolean (string) to true - user was indeed logged out
    public setAutoLogoutDone(): void {
        localStorage.setItem(LOGOUT_KEY, 'true');
    }

    // Initialise all necessary variables / time values etc..
    constructor(private router: Router) {
        this.initListener(); this.initInterval();
        localStorage.setItem(STORE_KEY, Date.now().toString());
    }

    // Below are browser events Sings is resetting the timer with
    // I believe the click & keypress events should suffice for now
    initListener(): void {
        document.body.addEventListener('click', () => this.reset());
        // document.body.addEventListener('mouseover', () => this.reset());
        // document.body.addEventListener('mouseout', () => this.reset());
        // document.body.addEventListener('keydown', () => this.reset());
        // document.body.addEventListener('keyup', () => this.reset());
        document.body.addEventListener('keypress', () => this.reset());
    }

    // This method is called upon each browser event set above (initListener)
    reset(): void { this.setLastAction(Date.now()); }

    // Initialise interval - how often the browser will check if user needs logging out
    initInterval(): void { setInterval(() => { this.check(); }, CHECK_INTERVAL); }

    // Method called every X seconds (set on the top of the page)
    // This is where Sings will log users out if the idle time is greater than Y
    check(): void {
        const now = Date.now();
        const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout && this.router.url !== '/login') {
            localStorage.clear(); sessionStorage.clear(); // Clear browser's local and session storages
            this.setAutoLogoutDone(); // This will make the pop-up appear in the /login page
            window.location.reload(); // Force refresh, which will redirect back to /login
        }
    }
}
