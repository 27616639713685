import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { GlobalConstants } from '../../common/global-constants';
import { BookingService } from '../../services/booking.service';
import { BranchService } from '../../services/branch.service';
import { UserService } from '../../services/user.service';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class BookingListComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;
  showExtReference = AppComponent.myapp.showExternalRef;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  // Table data and table columns below
  bookingsData: any = new MatTableDataSource<any>();
  displayedColumns = ['bookingReference', 'tradeCode', 'tradingName', 'bookingDate', 'departureDate', 'customerName', 'totalGross', 'bookingStatus'];

  // Variables controlling user access
  userType = '';
  pageLoaded = false;
  haveAccess = false;

  // Variables controlling HTML view file
  bookingList = true;
  dateRangeBlock = true;

  // Other variables
  currentRequest: any = {};
  errorMessage: any = '';
  successMessage: any = '';

  // Custom mat expansion variables
  expansionSearch = true;
  expansionList = true;

  // Access varaibles
  companies: any = [];
  operations: any = [];
  branches: any = []; // Holds data of ALL branches from the system
  filteredBranches: any = []; // Holds data filtered by the company (TTNG/GTG etc..)
  filterInBranches: any = []; // Holds data filtered by user input ('worldchoice..')
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  selectedBranch: any = ''; // Currently selected branch - not used in single-branch users
  branchShowEOD = false;

  // Custom mat paginator varaibles
  pageSizeOptions = [10, 25, 50, 100];
  pageSize = 10;
  firstRes = 1;
  lastRes = 10;

  // Custom order by variables - CRF99
  orderColumn = 'bookingReference';
  orderHow = 'DESC';

  constructor(private router: Router, private userService: UserService,
              private bookingService: BookingService, private branchService: BranchService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // Usual stuff.. if session storage is empty or undefined then redirect to home
      // Otherwise loadPage()
      this.router.navigate(['/']);
    } else {
      this.userType = Session.mySession.getUser().userType;
      this.loadPage();
    }
  }

  loadPage(): void {
    if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff' || this.userType === 'memberManager' || this.userType === 'memberStaff') {
      this.haveAccess = true; // Page available to all non-Felloh users
      this.companies = this.constants.getCompanies(Session.mySession.getUser()); // Get list based on user type
      this.operations = this.constants.getOperations(Session.mySession.getUser()); // Get list based on user type
      this.currentRequest = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation, tradeCode: Session.mySession.getUser().tradeCode,
        token: Session.mySession.get('user').token, firstRes: this.firstRes.toString(), lastRes: this.lastRes.toString(), bookRefOnly: 'false', statusFilter: 'all',
        orderColumn: this.orderColumn, orderHow: this.orderHow
      };

      if (Session.mySession.getUsersGroup().length > 0) {
        // Logged in user is in the group and branch list already exists within the session variable..
        const allBranch = { tradeCode: 'groupAll', fullName: 'All Branches', isLive: 'yes' };
        // Assign branch list, filter another list to choose from and add 'All' to the group
        this.branches = Session.mySession.getUsersGroup();
        this.filteredBranches = this.branches.filter((branch: any) => branch.tradeCode !== 'Q0000');
        this.filteredBranches.unshift(allBranch); // Add 'All' to beginning of array
        this.filterInBranches = this.filteredBranches; // It needs to be the same list
        // Select default branch and load the page
        this.selectedBranch = Session.mySession.getUser().tradeCode;
        this.loadBookings();
      } else if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
        this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
          if (branches.status === 'OK') {
            // Assign sorted to global variable and session varaible (for later use) and call loadBookings()
            const sorted = branches.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1);
            this.branches = sorted;
            Session.mySession.setBranchList(sorted);
            this.loadBookings();
          } else {
            // Status was not OK meaning something went wrong with it.. display it to the user
            this.sendMessageToDialog('', branches.status, '', '');
            this.haveAccess = false;
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0701S)', error, Session.mySession.getUser());
        });
      } else {
        // Get branch list from the session varaible - no need to call API
        this.branches = Session.mySession.getBranchList().branchList;
        this.loadBookings();
      }

    } else {
      this.pageLoaded = true;
    }
  }

  getBookingList(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      this.firstRes = 1; // Reassign the value back to default one
      this.lastRes = this.pageSize; // Reassign the value back to default one

      // Below conditional statements allow us to hide operation at the front
      if (form.value.company === 'ttng') { form.value.operation = 'retail'; }
      else if (form.value.company === 'gtg') { form.value.operation = 'member'; }
      else if (form.value.company === 'tta') { form.value.operation = 'tta'; }

      // Request's core parameters set up here
      this.currentRequest = { company: form.value.company, operation: form.value.operation, bookRefOnly: 'false',
      orderColumn: form.value.orderColumn, orderHow: form.value.orderHow, statusFilter: form.value.statusFilter,
      bookingGroup: form.value.bookingGroup, token: Session.mySession.get('user').token };

      // User decided to search for bookings within a date range
      if (this.dateRangeBlock === false) {
        this.currentRequest.dateType = form.value.dateType;
        this.currentRequest.startDate = this.constants.convertDateMoment(form.value.dateFrom);
        this.currentRequest.endDate = this.constants.convertDateMoment(form.value.dateTo);
      }
      // User decided to search by customer surname
      if (form.value.surname !== '') {
        this.currentRequest.leadNameFilter = form.value.surname;
      }
      // Check if selected option was 'All' (for groups) or single branch..
      if (form.value.tradeCode === 'groupAll') {
        this.currentRequest.userGroup = Session.mySession.getUser().group;
      } else {
        this.currentRequest.tradeCode = form.value.tradeCode;
      }
      // Fire up loadBookings() function
      this.loadBookings();
    }
  }

  loadBookings(): void {
    this.currentRequest.firstRes = this.firstRes.toString(); // Needed to display on the page
    this.currentRequest.lastRes = this.lastRes.toString(); // Needed to display on the page

    // Apply agentEmail filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      this.currentRequest.agentEmail = Session.mySession.getUser().email;
    }

    this.pageLoaded = false;
    this.bookingService.getBookingList(this.currentRequest).then((output: any) => {
      if (output.status === 'OK') {
        // Status OK meaning everything went good - assign data to data variable
        this.bookingsData.data = output.data;
        this.pageLoaded = true;
      } else {
        // Something went wrong. Display error message to the user
        this.bookingsData.data = [];
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0702S)', error, this.currentRequest);
    });
  }

  goToBooking(bookingReference: any): void {
    AppComponent.myapp.closeBooking('fromScript').then((res: any) => {
      AppComponent.myapp.navigateToBooking(bookingReference, true);
    });
  }

  changePageSize(size: any): void {
    this.firstRes = 1; // We'll start from the 'bottom' again
    this.lastRes = size; // Last res will be the selected size
    this.pageSize = size; // Set the size requested by the user
    this.loadBookings();
  }

  changePageNumber(direction: any): void {
    // Depending on the arrow clicked, we must retrieve previous/next 'page'
    if (direction === 'previous') {
      this.firstRes -= this.pageSize;
      this.lastRes -= this.pageSize;
    } else if (direction === 'next') {
      this.firstRes += this.pageSize;
      this.lastRes += this.pageSize;
    }
    this.loadBookings();
  }

  filterBranches(event: any): void {
    // Depending on the selected company, show only branches within that company (SINGS STAFF ONLY)
    const allBranch = { tradeCode: 'Q0000', fullName: 'All Branches', isLive: 'yes' };
    if (event.value === 'ttng') {
      this.filteredBranches = this.branches.filter((branch: any) => ['worldchoicePlus', 'worldchoice'].includes(branch.membershipType));
      this.filteredBranches = this.filteredBranches.filter((branch: any) => branch.tradeCode !== 'Q0000');
      this.filteredBranches.unshift(allBranch); // Put 'All' branch in the first position
    } else if (event.value === 'gtg') {
      this.filteredBranches = this.branches.filter((branch: any) => branch.membershipType === 'globalTravel');
      this.filteredBranches.unshift(allBranch); // Put 'All' branch in the first position
    } else if (event.value === 'tta') {
      this.filteredBranches = this.branches.filter((branch: any) => branch.membershipType === 'tta');
      this.filteredBranches.unshift(allBranch); // Put 'All' branch in the first position
    }
    this.filterInBranches = this.filteredBranches; // It needs to be the same list
    this.selectedBranch = ''; this.filterString = ''; // Reset our strings here..
    this.filterSelect(); // Apply EOD filter immediately
  }

  dateTypeChange(selectValue: any): void {
    // If selected date type is blank, that means user does not want to search by date type anymore..
    if (selectValue.value == null) {
      this.dateRangeBlock = true;
    } else {
      this.dateRangeBlock = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    if (view === 'bookingList') {
      this.bookingList = true;
    }
  }

  filterSelect(): void {
    this.filterInBranches = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.filteredBranches.length; i++) {
      const option = this.filteredBranches[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        if (this.branchShowEOD) { this.filterInBranches.push(option); }
        else if (option.isLive === 'yes') { this.filterInBranches.push(option); }
      }
    }
  }

  filterEOD(): void {
    this.branchShowEOD = !this.branchShowEOD;
    this.filterSelect();
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }

  showExtRefSwitch(): void {
    AppComponent.myapp.showExtRefSwitch(!this.showExtReference);
    this.showExtReference = AppComponent.myapp.showExternalRef;
  }
}
