<div class="mainDiv">
    <div class="chosenDiv">
        <div *ngIf="pageLoaded" style="height: 100%;">
            <h1>Document Settings</h1>
            <h2>{{ documentName }}</h2>
            
            <h4>
                <p>Below are all the possible document configurations. Please note that we may add more customisations in the future.</p>
            </h4>
        </div>

        <div *ngIf="pageLoaded">
            <form ngNativeValidate #configureDocument="ngForm">
                <div class="flexSettings">
                    <div class="flexSlides">
                        <div class="colorDivContainer" *ngIf="isNewTemplate">
                            <div class="colorPickersDivs">
                                <div style="cursor: help;" (click)="openColourLegend('doc-background.jpg')">Background</div>
                                <div><input class="colorPickers" type="color" ngModel [(ngModel)]="documentConfig.backgroundColour" #background="ngModel" name="background"/>
                                </div>
                            </div>

                            <div class="colorPickersDivs">
                                <div style="cursor: help;" (click)="openColourLegend('doc-foreground.jpg')">Foreground</div>
                                <div><input class="colorPickers" type="color" [(ngModel)]="documentConfig.foregroundColour" #foreground="ngModel" name="foreground"/></div>
                            </div>

                            <div class="colorPickersDivs">
                                <div style="cursor: help;" (click)="openColourLegend('doc-header.jpg')">Header</div>
                                <div><input class="colorPickers" type="color" [(ngModel)]="documentConfig.headerColour" #header="ngModel" name="header"/></div>
                            </div>

                            <div class="colorPickersDivs">
                                <div style="cursor: help;" (click)="openColourLegend('doc-headerText.jpg')">Header Text</div>
                                <div><input class="colorPickers" type="color" [(ngModel)]="documentConfig.headerTextColour" #headerTxt="ngModel" name="headerTxt"/></div>
                            </div>

                            <div class="colorPickersDivs">
                                <div style="cursor: help;" (click)="openColourLegend('doc-footerText.jpg')">Footer Text</div>
                                <div><input class="colorPickers" type="color" [(ngModel)]="documentConfig.footerTextColour" #footerTxt="ngModel" name="footerTxt"/></div>
                            </div>
                        </div>

                        <div style="width: 100%;" *ngIf="isNewTemplate">
                            <button mat-button type="button" color="primary" style="float: right; margin: 0px;" (click)="bringDefaultColours()">
                                <mat-icon>settings_backup_restore</mat-icon> Reset Colours
                            </button>
                        </div>

                        <div style="border-top: 1px solid rgb(219, 219, 219); padding-top: 5px; width: 100%;">
                            <mat-slide-toggle ngModel [(ngModel)]="documentConfig.showQrCode" #showQrCode="ngModel" name="showQrCode"
                            [disableRipple]="true" [disabled]="documentName == 'Cancellation Letter' || documentName == 'Welcome Home'">
                                Show QR code
                            </mat-slide-toggle>
                            <div class="helpIcons">
                                <mat-icon matTooltip="The QR code will be displayed at the end of the document. Scanning or clicking the code will take the recipient to a page with their booking information."
                                          matTooltipClass="line-normal-tooltip">info_i</mat-icon>
                            </div>
                        </div>

                        <div style="border-top: 1px solid rgb(219, 219, 219); padding-top: 5px; width: 100%;">
                            <mat-slide-toggle ngModel [(ngModel)]="documentConfig.formalWording" #formalEnglish="ngModel" name="formalWording" [disableRipple]="true" [disabled]="!isNewTemplate">
                                Formal wording
                            </mat-slide-toggle>
                            <div class="helpIcons">
                                <mat-icon matTooltip="Switch between using 'Dear' and 'Hi' as the salutation at the beginning of the document based on the level of formality required for the recipient."
                                          matTooltipClass="line-normal-tooltip">info_i</mat-icon>
                            </div>
                        </div>

                        <div style="border-top: 1px solid rgb(219, 219, 219); padding-top: 5px; width: 100%;">
                            <mat-slide-toggle ngModel [(ngModel)]="documentConfig.displaySignatures" #displaySignatures="ngModel" name="displaySignatures" [disableRipple]="true">
                                Display signatures
                            </mat-slide-toggle>
                            <div class="helpIcons">
                                <mat-icon matTooltip="To upload your own signature, simply click the account settings icon in the top right corner. If you don't have a signature yet, you can create one at https://signaturely.com/online-signature/"
                                          matTooltipClass="line-normal-tooltip">info_i</mat-icon>
                            </div>
                        </div>

                        <div style="border-top: 1px solid rgb(219, 219, 219); padding-top: 5px; width: 100%;">
                            <mat-slide-toggle ngModel [(ngModel)]="documentConfig.displayCancelled" #displayCancelled="ngModel" name="displayCancelled" [disableRipple]="true"
                            [disabled]="documentName != 'Booking Confirmation - Balance Outstanding' && documentName != 'Booking Confirmation - Paid In Full'">
                                Display cancelled elements
                            </mat-slide-toggle>
                            <div class="helpIcons">
                                <mat-icon matTooltip="Switch it on if you wish to display cancelled elements in the document" matTooltipClass="line-normal-tooltip">info_i</mat-icon>
                            </div>
                        </div>
                        <!--
                        <div>
                            <mat-slide-toggle ngModel [(ngModel)]="documentConfig.displayAddress" #displayAddress="ngModel" name="displayAddress"
                            [disableRipple]="true">
                                Display customer address
                            </mat-slide-toggle>
                        </div>-->

                        <div style="border-top: 1px solid rgb(219, 219, 219); padding-top: 5px; width: 100%;">
                            <mat-slide-toggle ngModel [(ngModel)]="documentConfig.displayBigImage" #displayBigImage="ngModel" name="displayBigImage"
                            [disableRipple]="true" [disabled]="!isNewTemplate">
                                Display big images
                            </mat-slide-toggle>

                            <div class="helpIcons">
                                <mat-icon matTooltip="This image will appear at the beginning of the document if switched on."
                                          matTooltipClass="line-normal-tooltip">info_i</mat-icon>
                            </div>

                            <div *ngIf="!documentConfig.displayBigImage" style="padding-top: 10px; height: 66.93px;"></div>

                            <div *ngIf="documentConfig.displayBigImage" style="padding-top: 10px;">
                                <mat-form-field style="width: 100%;">
                                    <input required matInput ngModel placeholder="Big Image URL" maxlength="255" name="bigImageUrl" #bigImageUrl="ngModel" [(ngModel)]="documentConfig.bigImageUrl">
                                    <input #uploadInput hidden="true" type="file" onclick="this.value=null" (change)="uploadBigImage($event)" accept=".png,.jpeg,.jpg" multiple="false"/>
                                    <span matSuffix>
                                    <button mat-icon-button type="button" *ngIf="documentConfig.bigImageUrl != ''" matTooltip="View Image" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline; margin: 0px;"
                                                (click)="openMedia(documentConfig.bigImageUrl)"><mat-icon>preview</mat-icon>
                                    </button>
                                    <button mat-icon-button type="button" (click)="uploadInput.click()" matTooltip="Upload File" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline; margin: 0px;">
                                        <mat-icon>file_upload</mat-icon>
                                      </button>
                                    </span>
                                </mat-form-field>
                                <p style="font-size: 12px; color: grey; margin-top: -15px;">
                                    <i>We recommend that the images have a minimum width of 825 and a minimum height of 465 pixels.</i>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div [style.margin-top.px]="!isNewTemplate ? 125 : 0">
                        <button class="saveButton" mat-raised-button color="primary" [disableRipple]="true" (click)="confirmDocSettings()" [disabled]="configureDocument.invalid">
                            <mat-icon style="margin-right: 5px; margin-top: -1px;">save</mat-icon>Save Changes
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div style="display: flex; align-items: center; justify-content: center; max-height: 100vh; min-height: 480px;" *ngIf="!pageLoaded">
            <h1>Please wait..</h1>
        </div>
    </div>
</div>

<ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
      <p style="font-weight: 400;">{{errorMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  
    <div *ngIf="successMessage != ''" class="successMessage">
      <p style="font-weight: 400;">{{successMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  </ng-template>
