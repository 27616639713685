import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from './../../../environments/environment';
import { Session } from '../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { UserService } from '../../services/user.service';
import { FellohService } from '../../services/felloh.service';
import { BranchService } from '../../services/branch.service';

import { AppComponent } from '../../app.component';

import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';

@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class ChargesComponent implements OnInit {
  // Import stuff from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginatorFees') paginatorFees!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  // Table data and table columns below
  fellohFeesData: any = new MatTableDataSource<any>();
  fellohFeesDummyData: any = [];
  fellohFeesColumns = ['payer', 'txnAmount', 'txnDateCompleted', 'type', 'region', 'percentage', 'charges'];

  // Variables controlling user access
  userType = '';
  haveAccess = false;
  pageLoaded = false;

  // Variables controlling side-nav view
  fellohFeesOverview = false;
  fellohFeesDetails = true;

  // Variables controlling Felloh getPayoutList() and getBeneficiaries() API
  pageSize = 25;
  pageNumber = 0;
  fellohMetaLength = 0;
  fromDate: any = '';
  toDate: any = '';
  yesterDate: any = '';

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  paymentNumber: any = 0;
  totalBalance: any = 0;
  totalCharges: any = 0;

  // Variables dealing with the multi-currency behaviour
  selectedCurrency: any = 'GBP';
  chartCurrencies: any = [];

  // Variables used for Admin Only
  branchListData: any = []; // Holds data of all branches
  branchListFiltered: any = []; // Holds data filtered by the company (TTNG/GTG etc..)
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  selectedBranch: any = null;
  selectedFellohAccount: any = null;

  // Main chart options below - initially without any data
  public chartOptions: Partial<any> = {
    series: [{ name: '', data: [] },
    { name: '', data: [] }],
    chart: {
      height: 375,
      type: 'area',
      zoom: {
        enabled: false
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    colors: ['#4D5FD1', '#b0b0b0'],
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Charges',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return this.constants.getChartsValue(this.selectedCurrency, value);
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'rounded'
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
  };

  constructor(private router: Router, private userService: UserService,
              private fellohService: FellohService, private branchService: BranchService,
              public dialog: MatDialog) { }

  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      AppComponent.myapp.fellohNav = true;
      this.router.navigate(['/']);
    } else {
      // Browser wasn't refreshed but the button was clicked so the user could get here - no API need to be called
      this.userType = Session.mySession.getUser().userType;
      this.setStartingDates(); // Call funciton which sets fromDate <-> toDate values..
      const date: any = new Date(); date.setDate(date.getDate() - 1); // Get yesterday's date here
      this.yesterDate = moment(date).format('DD.MM.YYYY');  // Assign 'global' date here

      if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff' || this.userType === 'trustee') {
        // SinGS Admin and Staff are able to retrieve transactions from all of the branches.
        if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
          this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
            if (branches.status === 'OK') {
              // Assign branch list data and order by the name
              const sorted = branches.data.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
              Session.mySession.setBranchList(sorted);
              // We only want branches with felloh account id in them. Any other branches are not Felloh-compatibale (yet)
              this.branchListData = sorted.filter((branch: any) => branch.fellohSetup === 'yes');
              const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
              this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
              // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
              this.branchListFiltered = this.branchListData; // It needs to be the same list
              this.changeBranch(allBranch); // Call this to change current branch
            } else {
              this.sendMessageToDialog('', branches.status, '', '');
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1902S)', error, Session.mySession.getUser());
          });
        } else {
          // Filter out all branches with an empty Felloh account ID
          this.branchListData = Session.mySession.getBranchList().branchList.filter((branch: any) => branch.fellohSetup === 'yes');
          // Change Q0000 branch name to '-- All branches --' and sort once again..
          const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
          this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
          // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
          this.branchListFiltered = this.branchListData; // It needs to be the same list
          this.changeBranch(allBranch); // Call this to change current branch
        }

      } else {
        if (Session.mySession.getUsersGroup().length > 0) {
          // Logged in user is in the group and branch list already exists within the session variable..
          this.branchListData = Session.mySession.getUsersGroup();
          this.branchListFiltered = this.branchListData; // It needs to be the same list
          this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode)[0]);
        } else {
          this.changeBranch(Session.mySession.getBranch());
        }
      }
    }
  }

  setStartingDates(): void {
    this.haveAccess = true;
    // Calculate today's date for yyyy-mm-dd format
    const today: any = new Date();
    const monthEarlier = new Date(); monthEarlier.setDate(monthEarlier.getDate() - 7);
    // Assign dates to the global dates which will be changed later on by the user
    this.toDate = this.constants.convertDateNotMoment(today);
    this.fromDate = this.constants.convertDateNotMoment(monthEarlier);
  }

  changeBranch(branch: any): void {
    // Function called whenver SinGS Admin/Staff changes the branch from select list
    this.pageLoaded = false; this.selectedBranch = branch;
    this.selectedFellohAccount = branch?.fellohConfig[0];
    this.chartCurrencies = []; this.selectedCurrency = 'GBP';
    this.prepareForFellohFees();
  }

  prepareForFellohFees(): void {
    // Reset total balance & charges to £0 and recalculate it again
    this.totalBalance = 0; this.totalCharges = 0;
    this.paymentNumber = 0;
    // Reset dummy array to nothing - we'll need to re-calculate stuff once again
    this.pageNumber = 0; this.fellohFeesDummyData = [];
    // Call main function below (where there may be loop within the loop)
    if (this.selectedBranch.fellohSetup === 'yes' && this.selectedBranch?.fellohConfig?.length > 0) {
      this.loadFellohFees();
    } else { this.pageLoaded = true; }
  }

  loadFellohFees(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.selectedBranch.fullName !== '-- All Branches --') {
        // Function called whenever date range or branch has been changed in UI
        // Create Felloh request hash to request access token and call the API
        Session.mySession.fetchFellohAuthorisationV2(this.fellohService, this.selectedFellohAccount.accountCode, this.userType).then((tokenOut: any) => {
          // Create a Felloh charges request hash to which will be used to retrieve Felloh charges list
          const chargesRequest = {
            organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
            date_from: this.fromDate, date_to: this.toDate, skip: this.pageNumber * this.pageSize, take: this.pageSize
          };

          this.fellohService.fetchCharges(chargesRequest, tokenOut).then((fellohChargesList: any) => {
            const filteredList: any = fellohChargesList.data;

            filteredList.forEach((element: any) => {
              element.detailRow = true;  // Expandable rows..

              if (element.transaction.currency === 'GBX') { element.transaction.currency = 'GBP'; element.transaction.amount = element.transaction.amount / 100; }
              else if (element.transaction.currency === 'USX') { element.transaction.currency = 'USD'; element.transaction.amount = element.transaction.amount / 100; }
              else if (element.transaction.currency === 'EUX') { element.transaction.currency = 'EUR'; element.transaction.amount = element.transaction.amount / 100; }

              if (element.charges.currency === 'GBX') { element.charges.currency = 'GBP'; element.charges.amount = element.charges.amount / 100; }
              else if (element.charges.currency === 'USX') { element.charges.currency = 'USD'; element.charges.amount = element.charges.amount / 100; }
              else if (element.charges.currency === 'EUX') { element.charges.currency = 'EUR'; element.charges.amount = element.charges.amount / 100; }
            });

            // Merge fees output into one variable here (in case there's more than 200 charges within date range)
            this.fellohFeesDummyData = this.fellohFeesDummyData.concat(filteredList);

            // In case there's more payments than 'Rows' number - override from meta
            if (fellohChargesList.meta.count > (this.pageNumber + 1) * this.pageSize) {
              this.pageNumber = this.pageNumber + 1;
              this.loadFellohFees(); // Call yourself once again
            } else {
              // Assign transaction list and paginator to the data table variable
              this.fellohFeesData.data = this.fellohFeesDummyData;
              this.fellohFeesData.paginator = this.paginatorFees;
              // Set possible currencies to choose from in the chart tab - the selected currency will be the first one available
              const dataCurrencies: any = [...new Set(this.fellohFeesData.data.map((item: any) => item.charges.currency))];
              if (!dataCurrencies.includes('GBP')) { dataCurrencies.push('GBP'); }
              this.chartCurrencies = dataCurrencies.sort((a: any, b: any) =>  (a > b ? 1 : -1));

              this.feedChartWithFees().then(res => {
                this.pageLoaded = true;
                resolve(true);
              });
            }

          }).catch((error: any) => {
            this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E1901F)', error, chargesRequest);
          });
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E1902F)', error, '');
        });
      } else {
        this.pageLoaded = true;
        resolve(true);
      }
    });
  }

  feedChartWithFees(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Convert date range using 'moment' function
      const fromDate = moment(this.fromDate);
      const toDate = moment(this.toDate);
      let charges: any = [];

      // Get the number of payments with selected currency (GBP / USD..)
      this.paymentNumber = this.fellohFeesDummyData.filter((txn: any) => txn.charges.currency === this.selectedCurrency).length;
      // Calculate the total balance fellohFeesDummyData selected currency (GBP / USD..)
      this.totalBalance = this.fellohFeesDummyData.filter((txn: any) => txn.transaction.currency === this.selectedCurrency).reduce((a: any, b: any) => a + (b.transaction.amount || 0), 0);
      this.totalCharges = this.fellohFeesDummyData.filter((txn: any) => txn.charges.currency === this.selectedCurrency).reduce((a: any, b: any) => a + (b.charges.amount || 0), 0);

      // Empty array lists for series objects
      const updateSeriesCharged: any = [];
      // SinGS needs to go through each day within date range and check for the payment within it
      for (const day = moment(fromDate); day.diff(toDate, 'days') <= 0; day.add(1, 'days')) {
        // Create series object which will store x - date and y - total amount (££££)
        const seriesObjectCharged: any = { x: '', y: '' };
        seriesObjectCharged.x = day.format('YYYY-MM-DD');

        // Filter out/in temporary references from transaction list and assign results into variables below
        charges = this.fellohFeesData.data.filter((txn: any) => txn.charges.currency === this.selectedCurrency);

        // Add up all of the payments' values together and assign it to series y variable
        seriesObjectCharged.y = charges.filter((txn: any) => txn.transaction.completed_at.includes(day.format('YYYY-MM-DD'))).reduce((sum: any, current: any) => sum + current.charges.amount, 0).toFixed(2) * -1;

        // Add series object into array list
        updateSeriesCharged.push(seriesObjectCharged);
      }
      // Update chart series with series objects created earlier for temp/non-temp references
      this.chartOptions.series = [{ name: 'Charges', data: updateSeriesCharged }];
      resolve(true);
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Charges Component - feedChartWithFees() failed');
    });
  }

  changeChartCurrency(currency: any): void {
    this.selectedCurrency = currency; // Change global currency values
    this.feedChartWithFees();
  }

  changeDate(dateType: any, date: any): void {
    // Translate for Felloh-friendly date and reload Felloh data
    if (dateType === 'fromDate') {
      if (date.value != null) {
        this.fromDate = this.constants.convertDateMoment(date.value);
      }

      if (this.toDate._i !== undefined) {
        this.toDate = this.constants.convertDateMoment(this.toDate);
      }
    } else if (dateType === 'toDate') {
      if (date.value != null) {
        this.toDate = this.constants.convertDateMoment(date.value);

        if (this.fromDate._i !== undefined) {
          this.fromDate = this.constants.convertDateMoment(this.fromDate);
        }
      }
    }
    // Below we're checking the number of the days the request is made for
    // We don't to allow huge requests (e.g. a year worth of data) due to server limits
    const diffInDays = Math.ceil((new Date(this.toDate).getTime() - new Date(this.fromDate).getTime()) / (1000 * 60 * 60 * 24));

    // If the number is above the treshold, we'll set the other date type automatically
    // The new date will be !dateType (othertype) +/- threshold
    if (diffInDays > 30 && dateType === 'fromDate') {
      const toDate = new Date(this.fromDate); toDate.setDate(toDate.getDate() + 30);
      this.toDate = this.constants.convertDateNotMoment(toDate);
    } else if (diffInDays > 30 && dateType === 'toDate') {
      const fromDate = new Date(this.toDate); fromDate.setDate(fromDate.getDate() - 30);
      this.fromDate = this.constants.convertDateNotMoment(fromDate);
    }

    // Make sure none of these two are null (non-date value entered in the field)
    if (this.toDate != null && this.fromDate != null) {
      // In case the user writes the date in yyyy-mm-dd format, below will catch it and prevents from reloading transactions
      if (moment(this.fromDate, 'YYYY-MM-DD', true).isValid() && moment(this.toDate, 'YYYY-MM-DD', true).isValid()) {
        this.pageLoaded = false;
        this.prepareForFellohFees();
      } else {
        this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
        this.setStartingDates();
      }
    } else {
      this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
      this.setStartingDates();
    }
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    this.expandedElement = null;
    if (view === 'overview') {
      this.fellohFeesOverview = true;
      this.fellohFeesDetails = false;
    } else if (view === 'fellohFees') {
      this.fellohFeesOverview = false;
      this.fellohFeesDetails = true;
    }
  }

  exportToExcel(): void {
    const exportMe: any = [];
    let oneLine: any = {};

    this.fellohFeesData.data.forEach((data: any) => {
      oneLine = {};
      oneLine.transactionId = data.transaction.id;
      oneLine.bookingReference = data.booking.booking_reference;
      oneLine.transactionAmount = data.transaction.amount;
      oneLine.transactionCurrency = data.transaction.currency;
      oneLine.dateCompleted = data.transaction.completed_at;
      oneLine.type = data.metadata.payment_brand + ' ' + data.metadata.card_type;
      oneLine.region = data.metadata.country_type;
      oneLine.rate = data.charges.rate;
      oneLine.charges = data.charges.amount;
      oneLine.chargesCurrency = data.charges.currency;
      exportMe.push(oneLine);
    });
    this.constants.exportAsExcelFile(exportMe, this.selectedBranch.tradeCode + 'fellohCharges');
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  filterSelect(): void {
    this.branchListFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.branchListData.length; i++) {
      const option = this.branchListData[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.branchListFiltered.push(option);
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // Check if the error comes from Felloh and if we can display it to the user
      // If we can then let's display it rather than give them weird code..
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        failureMessage = error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error.hasOwnProperty('message')) {
        failureMessage = error.error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors.length > 0) {
        failureMessage = error.error.errors[0].message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      }
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }

  showHelp(): void {
    (window as any).Beacon('toggle'); // Open Help Scout
  }
}
