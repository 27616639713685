import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  async makeHttpRequest(method: string, url: string, body: any = null, headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' }), maxRetries: number = 3): Promise<any> {
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        if (!environment.production) { console.log(`Attempt ${attempt + 1}: ${method.toUpperCase()} ${url}`); }
        let request;
        switch (method.toLowerCase()) {
          case 'get':
            request = this.http.get(url, { headers });
            break;
          case 'post':
            request = this.http.post(url, body, { headers });
            break;
          case 'put':
            request = this.http.put(url, body, { headers });
            break;
          case 'delete':
            request = this.http.delete(url, { headers });
            break;
          default:
            throw new Error(`Unsupported HTTP method: ${method}`);
        }

        const response = await request.toPromise();

        // Check if the response is empty and handle it
        if (response && typeof response === 'object' && Object.keys(response).length === 0) {
          if (!environment.production) { console.error(`Attempt ${attempt + 1} received an empty response:`, response); }
          if (attempt < maxRetries - 1) {
            const backoffTime = Math.pow(2, attempt) * 1000;
            await delay(backoffTime);
            continue; // Retry the request
          } else {
            throw new Error('Max retries reached. Received empty response.');
          }
        }

        return response; // Return the valid response

      } catch (error) {
        if (!environment.production) { console.error(`Attempt ${attempt + 1} failed:`, error); }
        if (this.isRetriableError(error) && attempt < maxRetries - 1) {
          const backoffTime = Math.pow(2, attempt) * 1000;
          if (!environment.production) { console.log(`Retrying in ${backoffTime}ms...`); }
          await delay(backoffTime);
        } else {
          throw error;
        }
      }
    }
    throw new Error('Max retries reached. Unable to complete the request.');
  }

  private isRetriableError(error: any): boolean {
    return error instanceof HttpErrorResponse && (error.status === 504 || error.status === 0);
  }
}